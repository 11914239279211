

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Std$Bolt from "bolt/./std.bs.js";
import * as Root$SolvuuApi from "solvuu-api/./root.bs.js";
import * as VerticalLayout from "../components/Layout/VerticalLayout.bs.js";
import * as Layout from "components/Layout";
import * as PrincipalCoordinatesJs from "./PrincipalCoordinates.js";

var make = PrincipalCoordinatesJs.default;

var make$1 = PrincipalCoordinatesJs.ExplainedVarianceBarChart;

function ofRecord(record) {
  return Curry._2(Root$SolvuuApi.Principal_coordinates.T.of_record, record, (function (explainedVariance, vectors, labels) {
                return {
                        labels: labels,
                        vectors: vectors,
                        explainedVariance: explainedVariance
                      };
              }));
}

function ofTerm(term) {
  if (typeof term === "number" || term[0] !== 847309489) {
    return /* `Error */[
            106380200,
            /* Expected_record */-756637544
          ];
  } else {
    var res = ofRecord(term[1]);
    return Curry._2(Std$Bolt.Res.map_error, res, (function (e) {
                  return /* `Record */[
                          847309489,
                          e
                        ];
                }));
  }
}

var Props = {
  ofRecord: ofRecord,
  ofTerm: ofTerm
};

function make$2(props) {
  var plot = React.createElement(make, {
        labels: props.labels,
        vectors: props.vectors
      });
  var explainedVariance = React.createElement(make$1, {
        explainedVariance: props.explainedVariance
      });
  return React.createElement(Layout.VerticalLayout, VerticalLayout.makeProps(/* x4 */26812, null, /* () */0), React.createElement(Layout.VerticalLayoutItem, {
                  children: plot
                }), React.createElement(Layout.VerticalLayoutItem, {
                  children: explainedVariance
                }));
}

export {
  Props ,
  make$2 as make,
  
}
/* make Not a pure module */
