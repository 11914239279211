import AWS from "aws-sdk/global";
import { CognitoUserPool } from "amazon-cognito-identity-js";

const userPoolId = process.env.SOLVUU_COGNITO_USER_POOL_ID;
const clientId = process.env.SOLVUU_COGNITO_CLIENT_ID;
const region = "us-east-1";

export function getUserPool() {
  const poolData = {
    UserPoolId: userPoolId,
    ClientId: clientId
  };
  return new CognitoUserPool(poolData);
}

export function saveCognitoCredentials(token) {
  AWS.config.region = region;
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: userPoolId,
    Logins: {
      [`cognito-idp.${region}.amazonaws.com/${userPoolId}`]: token
    }
  });
}

export function clearCognitoCredentials() {
  const cognitoUser = getUserPool().getCurrentUser();
  if (cognitoUser) {
    cognitoUser.signOut();
  }
}

export function getAuthenticatedUser() {
  return new Promise((resolve, reject) => {
    const userPool = getUserPool();
    const cognitoUser = userPool.getCurrentUser();
    if (!cognitoUser) {
      reject(new Error("Current user not found"));
      return;
    }
    cognitoUser.getSession((err, session) => {
      if (err) {
        reject(err);
      } else if (!session.isValid()) {
        reject(new Error("Session invalid"));
      } else {
        resolve(cognitoUser);
      }
    });
  });
}
