

import * as Err from "../../../../components/Err.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Hooks from "../../../../Hooks.bs.js";
import * as Jobs0 from "../../Jobs0.bs.js";
import * as React from "react";
import * as Import from "../../../../features/import.bs.js";
import * as Graph from "./Graph";
import * as Loading from "../../../../components/Loading.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Caml_int64 from "bs-platform/lib/es6/caml_int64.js";
import * as Colors from "lib/colors";
import * as ReactIntl from "react-intl";
import * as StyleCss from "./style.css";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as JobDetails0 from "../JobDetails0.bs.js";
import * as ReactExtras from "../../../../lib/solvuu/ReactExtras.bs.js";
import * as Job_metrics$SolvuuApi from "solvuu-api/./job_metrics.bs.js";

var styles = StyleCss.default;

var Color = { };

var bytes = Graph.BYTES;

var fraction = Graph.FRACTION;

function toString(t) {
  if (t >= 1050694178) {
    return fraction;
  } else {
    return bytes;
  }
}

var ValueType = {
  bytes: bytes,
  fraction: fraction,
  toString: toString
};

function makeProps(series, limit, startAtTimestamp, valueType, color, param) {
  var series$1 = Curry._1(Import.List.toArray, Curry._2(Import.List.map, series, (function (param) {
              return {
                      timestamp: param[0],
                      value: param[1]
                    };
            })));
  var valueType$1 = toString(valueType);
  var tmp = {
    series: series$1,
    startAtTimestamp: startAtTimestamp,
    valueType: valueType$1,
    color: color
  };
  if (limit !== undefined) {
    tmp.limit = Caml_option.valFromOption(limit);
  }
  return tmp;
}

var make = Graph.default;

var Graph$1 = {
  Color: Color,
  ValueType: ValueType,
  makeProps: makeProps,
  make: make
};

var make$1 = Graph.EmptyGraph;

var EmptyGraph = {
  make: make$1
};

var Action = { };

function reducer(state, action) {
  var action$1 = action[1][1];
  var metricsFetch = Curry._2(Jobs0.Operations.Job.MetricsFetch.reducer, state.meta.job.metricsFetch, action$1);
  var jobMeta = {
    metricsFetch: metricsFetch
  };
  var meta = {
    job: jobMeta
  };
  var metrics = typeof action$1 === "number" || action$1[0] !== 94326179 ? state.metrics : action$1[1];
  return {
          metrics: metrics,
          meta: meta
        };
}

var initialState_meta = {
  job: {
    metricsFetch: Jobs0.Operations.Job.MetricsFetch.initialState
  }
};

var initialState = {
  metrics: undefined,
  meta: initialState_meta
};

var State = {
  reducer: reducer,
  initialState: initialState
};

function fetchMetrics(jobId, apiClient) {
  var $$let = Job_metrics$SolvuuApi.Get_by_id.$$Request;
  var $$let$1 = Job_metrics$SolvuuApi.Get_by_id.$$Response;
  return Import.$great$great$pipe(Curry._3(apiClient.f, /* GET */3546230, {
                  entity: Job_metrics$SolvuuApi.Get_by_id.entity,
                  service: Job_metrics$SolvuuApi.Get_by_id.service,
                  $$Request: {
                    path: $$let.path,
                    body: $$let.body,
                    info: $$let.info
                  },
                  $$Response: {
                    ok_of_json: $$let$1.ok_of_json,
                    json_of_ok: $$let$1.json_of_ok,
                    bad_request_of_json: $$let$1.bad_request_of_json,
                    json_of_bad_request: $$let$1.json_of_bad_request,
                    not_found_of_json: $$let$1.not_found_of_json,
                    json_of_not_found: $$let$1.json_of_not_found
                  }
                }, {
                  id: jobId
                }), (function (response) {
                if (typeof response === "number") {
                  return /* `Error */[
                          106380200,
                          /* Unauthorized */324098644
                        ];
                } else {
                  var info = response[1].info;
                  var variant = info[0];
                  if (variant !== -99004555) {
                    if (variant >= 17692) {
                      return /* `Ok */[
                              17724,
                              info[1]
                            ];
                    } else {
                      return /* `Error */[
                              106380200,
                              /* Not_found */-358247754
                            ];
                    }
                  } else {
                    return /* `Error */[
                            106380200,
                            /* Bad_request */-99004555
                          ];
                  }
                }
              }));
}

function fetchMetricsWithDispatch(jobId, dispatch, apiClient) {
  var start = function (x) {
    return Curry._1(Jobs0.Operations.Job.MetricsFetch.start, x);
  };
  var success = function (x) {
    return Curry._1(Jobs0.Operations.Job.MetricsFetch.success, x);
  };
  var failure = function (x) {
    return Curry._1(Jobs0.Operations.Job.MetricsFetch.failure, x);
  };
  Curry._1(dispatch, start(/* () */0));
  return Import.$great$great$pipe(fetchMetrics(jobId, apiClient), (function (response) {
                if (response[0] >= 106380200) {
                  return Curry._1(dispatch, failure(/* array */[response[1]]));
                } else {
                  return Curry._1(dispatch, success(response[1]));
                }
              }));
}

function JobMetrics(Props) {
  var job = Props.job;
  var apiClient = Props.apiClient;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var match$1 = match[0];
  var metrics = match$1.metrics;
  var fetchStatus = Curry._1(Jobs0.Operations.Job.MetricsFetch.Status.convertFromOperation, match$1.meta.job.metricsFetch);
  Hooks.useWithInterval(undefined, 5000, (function (param) {
          fetchMetricsWithDispatch(job.id, dispatch, apiClient);
          return /* () */0;
        }));
  var match$2 = job.spec;
  var limits = match$2[1].resources;
  var startAtTimestamp = job.created;
  var renderGraph = function (testId, series, limit, valueType, color) {
    if (series) {
      return ReactExtras.withTestId(testId, React.createElement(make, makeProps(series, limit, startAtTimestamp, valueType, color, /* () */0)));
    } else {
      return React.createElement(make$1, { });
    }
  };
  var renderTitle = function (title, $staropt$star, param) {
    var kind = $staropt$star !== undefined ? $staropt$star : /* Filled */969114050;
    var indicator;
    if (typeof fetchStatus === "number") {
      indicator = React.createElement(Loading.Dots.make, { });
    } else {
      switch (fetchStatus.tag | 0) {
        case /* Pending */0 :
            indicator = React.createElement(Loading.Dots.make, { });
            break;
        case /* Success */1 :
        case /* Failure */2 :
            indicator = null;
            break;
        
      }
    }
    var className = kind >= 969114050 ? styles.graphTitle : "";
    return React.createElement("div", {
                className: className
              }, title, indicator);
  };
  var contents;
  var exit = 0;
  var exit$1 = 0;
  if (typeof fetchStatus === "number") {
    exit$1 = 2;
  } else {
    switch (fetchStatus.tag | 0) {
      case /* Pending */0 :
          exit$1 = 2;
          break;
      case /* Success */1 :
      case /* Failure */2 :
          exit$1 = 3;
          break;
      
    }
  }
  switch (exit$1) {
    case 2 :
        if (metrics !== undefined) {
          exit = 1;
        } else {
          contents = React.createElement(Loading.make, { });
        }
        break;
    case 3 :
        if (metrics !== undefined) {
          exit = 1;
        } else {
          contents = React.createElement(Err.Operation.make, { });
        }
        break;
    
  }
  if (exit === 1) {
    var metrics$1 = metrics;
    contents = React.createElement(JobDetails0.Columns.make, {
          children: null
        }, React.createElement(JobDetails0.Column.make, {
              width: /* OneThird */160213825,
              children: React.createElement(JobDetails0.JobDetail.make, {
                    title: renderTitle(React.createElement(ReactIntl.FormattedMessage, {
                              id: "jobs.show.metrics.cpu"
                            }), undefined, /* () */0),
                    content: renderGraph("cpu", metrics$1.cpu, Belt_Option.map(limits, (function (limits) {
                                return limits.cpu;
                              })), /* Fraction */1050694178, Belt_Array.getExn(Colors.categorical, 0))
                  })
            }), React.createElement(JobDetails0.Column.make, {
              width: /* OneThird */160213825,
              children: React.createElement(JobDetails0.JobDetail.make, {
                    title: renderTitle(React.createElement(ReactIntl.FormattedMessage, {
                              id: "jobs.show.metrics.mem"
                            }), undefined, /* () */0),
                    content: renderGraph("mem", metrics$1.mem, Belt_Option.map(limits, (function (limits) {
                                return Caml_int64.to_float(limits.mem);
                              })), /* Bytes */-792364981, Belt_Array.getExn(Colors.categorical, 2))
                  })
            }), React.createElement(JobDetails0.Column.make, {
              width: /* OneThird */160213825,
              children: React.createElement(JobDetails0.JobDetail.make, {
                    title: renderTitle(React.createElement(ReactIntl.FormattedMessage, {
                              id: "jobs.show.metrics.disk"
                            }), /* Empty */50834029, /* () */0),
                    content: React.createElement(make$1, { })
                  })
            }));
  }
  var title = React.createElement(ReactIntl.FormattedMessage, {
        id: "jobs.show.metrics"
      });
  return React.createElement(JobDetails0.JobDetailCategory.make, {
              title: title,
              children: contents
            });
}

var make$2 = JobMetrics;

export {
  styles ,
  Graph$1 as Graph,
  EmptyGraph ,
  Action ,
  State ,
  fetchMetrics ,
  fetchMetricsWithDispatch ,
  make$2 as make,
  
}
/* styles Not a pure module */
