/**
 * Manages form state for files (blobs) pending upload.
 */
import createUUID from "uuid/v4";
import { any, omit, values, zip } from "ramda";

import { authenticationSelector } from "features/authentication";
import { extractErrorMessageFromCreateResponse } from "features/entities";
import { startUpload } from "features/upload";
import { formatExpressionOfUri } from "lib/solvuu/formatUtils";
import * as PathUtils from "lib/solvuu/pathUtils.bs";
import * as SolvuuApiWrapper from "lib/solvuu/solvuuApiWrapper.bs";
import { createEntityOperation } from "../operations";
import {
  entityCreatorFormChange,
  entityCreatorFormInit,
  creatableTypes
} from ".";
import { inferFileFormat, inferFormatSelector } from "./formats";

// ACTIONS

/**
 * Action fired when user drag-and-drops or selects the files for upload.
 *
 * @param {Array<File>} files
 * @param {String} root - the record path into which the files should be uploaded.
 *   Leave empty for user's top-level record.
 */
export function chooseFilesForUpload(files, root = null) {
  return function(dispatch, getState) {
    const entityRoot = root
      ? root
      : "/" + authenticationSelector(getState()).username;

    dispatch(entityCreatorFormInit(entityRoot, creatableTypes.BLOBS));
    files.map(file => dispatch(addBlob(file)));
  };
}

/**
 * Some characters, like slashes or whitespace, are disallowed in entity paths.
 */
export function sanitizeFileName(name) {
  return name.replace(/[/()\s]+/g, "_");
}

export function addBlob(file) {
  return async function(dispatch, getState) {
    const id = createUUID().toString();
    const name = sanitizeFileName(file.name);
    const blobForm = {
      id,
      name,
      file
    };
    dispatch(inferFileFormat(id, file));
    dispatch(formInitializeBlob(blobForm));
  };
}

export function dismissBlob(id) {
  return async function(dispatch, getState) {
    const state = getState();
    const blobs = omit([id], state.entities.creatorForm.blobs);
    dispatch(formReplaceBlobs(blobs));
  };
}

export function createAndBindBlobs(root) {
  const { start, success, failure } = createEntityOperation.actionCreators;

  return async function(dispatch, getState) {
    dispatch(start({ path: root }));

    // Mutable array to collect operation errors
    const errors = [];

    // Bind the file URIs to paths
    const blobsToBind = notBoundBlobsSelector(getState());
    const bindingPaths = blobsToBind.map(blob => `${root}/${blob.name}`);
    const blobsWithBindingPaths = zip(blobsToBind, bindingPaths);
    const bindingActions = blobsWithBindingPaths.map(([blob, bindingPath]) =>
      dispatch(bindFileToPath(bindingPath, blob.file, blob.format))
    );
    const bindResponses = await Promise.all(bindingActions);

    // Collect success/error statuses
    const blobsWithBindResponses = zip(blobsToBind, bindResponses);
    blobsWithBindResponses.forEach(([blob, response], idx) => {
      if (response.ok) {
        const concreteBlobId = SolvuuApiWrapper.Js.termCreateRequestToBlobId(
          response
        );
        dispatch(formAssignBlobPath(blob.id, bindingPaths[idx]));
        dispatch(formAssignConcreteBlobId(blob.id, concreteBlobId));
      } else {
        const message =
          extractErrorMessageFromCreateResponse(response) || "Failed to bind";
        errors.push({
          field: `blobs.${blob.id}.name`,
          message: message
        });
      }
    });

    // Prepare the uploads
    const blobsToUpload = notUploadingBlobsSelector(getState());
    const uploads = blobsToUpload.map(blob => ({
      id: blob.blobId,
      file: blob.file,
      name: blob.name
    }));

    // Start the uploads
    const uploadActions = uploads.map(upload => dispatch(startUpload(upload)));
    const uploadsStarted = await Promise.all(uploadActions);

    // Collect success/error statuses
    const blobsWithUploadStarted = zip(blobsToUpload, uploadsStarted);
    blobsWithUploadStarted.forEach(([blob, started]) => {
      if (started) {
        dispatch(formAssignStartedUploading(blob.id));
      } else {
        errors.push({
          field: `blobs.${blob.id}.name`,
          message: "Failed to upload"
        });
      }
    });

    if (errors.length === 0) {
      dispatch(success({ path: root }));
    } else {
      dispatch(failure(errors));
    }
  };
}

// Internal actions

function bindFileToPath(path, file, format) {
  return async function(dispatch, getState, { solvuuApi }) {
    const solvuuPath = PathUtils.pathToSolvuu(path);
    const fileUri = "file:///" + file.name;

    const response = await solvuuApi.createTerm(
      solvuuPath,
      formatExpressionOfUri(fileUri, format)
    );
    return response;
  };
}

function formInitializeBlob(blob) {
  return entityCreatorFormChange(["blobs", blob.id], blob);
}

function formReplaceBlobs(blobs) {
  return entityCreatorFormChange(["blobs"], blobs);
}

function formAssignBlobPath(id, path) {
  return entityCreatorFormChange(["blobs", id, "path"], path);
}

function formAssignConcreteBlobId(id, blobId) {
  return entityCreatorFormChange(["blobs", id, "blobId"], blobId);
}

function formAssignStartedUploading(id) {
  return entityCreatorFormChange(["blobs", id, "startedUploading"], true);
}

// SELECTORS
export function blobsSelector(state) {
  return values(state.entities.creatorForm.blobs).map(blob => {
    return {
      ...inferFormatSelector(state, blob.id),
      ...blob
    };
  });
}

export function inferenceInProgressSelector(state) {
  const blobs = blobsSelector(state);
  return any(blob => blob.meta.inferFormat.pending, blobs);
}

// Internal selectors

function notBoundBlobsSelector(state) {
  return blobsSelector(state).filter(blob => !blob.path);
}

function boundBlobsSelector(state) {
  return blobsSelector(state).filter(blob => blob.path);
}

function notUploadingBlobsSelector(state) {
  return boundBlobsSelector(state).filter(blob => !blob.startedUploading);
}
