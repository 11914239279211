

import * as FileDropReduxJs from "./FileDropRedux.js";

var make = FileDropReduxJs.default;

export {
  make ,
  
}
/* make Not a pure module */
