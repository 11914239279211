import { connect } from "react-redux";

import {
  confirmRegistration,
  resendConfirmation,
  registrationSelector
} from "features/registration";
import RegistrationConfirmation from "containers/Registration/Confirmation/Confirmation.js";

const mapStateToProps = registrationSelector;

const mapDispatchToProps = { confirmRegistration, resendConfirmation };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationConfirmation);
