

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Import from "../features/import.bs.js";
import * as Std$Bolt from "bolt/./std.bs.js";
import * as Pervasives from "bs-platform/lib/es6/pervasives.js";
import * as Belt_SetString from "bs-platform/lib/es6/belt_SetString.js";
import * as Lang$SolvuuApi from "solvuu-api/./lang.bs.js";
import * as Root$SolvuuApi from "solvuu-api/./root.bs.js";

function $$eval(f, t) {
  if (typeof t === "number" || t[0] !== 847309489) {
    return Promise.resolve(Curry._2(Lang$SolvuuApi.Term.eval_error, "Expected record", t));
  } else {
    var resResTerm = Curry._2(Root$SolvuuApi.Venn_diagram.Arg.of_record, t[1], (function (columnNames, data) {
            return Curry._2(Std$Bolt.Res.map, Curry._2(Std$Bolt.Res.map_error, Curry._1(Std$Bolt.Res.all, Curry._2(Import.List.map, columnNames, (function (name) {
                                      return Curry._2(Std$Bolt.Res.map, Curry._3(Lang$SolvuuApi.Table.project_column, data, /* `Named */[
                                                      699531161,
                                                      name
                                                    ], Curry._1(Lang$SolvuuApi.Record.Param.Arg_type.create, (function (term) {
                                                            if (typeof term === "number") {
                                                              return /* `Ok */[
                                                                      17724,
                                                                      undefined
                                                                    ];
                                                            } else {
                                                              var variant = term[0];
                                                              if (variant !== -976970511) {
                                                                if (variant !== 925978388) {
                                                                  return /* `Ok */[
                                                                          17724,
                                                                          undefined
                                                                        ];
                                                                } else {
                                                                  var match = term[1];
                                                                  if (typeof match === "number" || match[0] !== -976970511) {
                                                                    return /* `Ok */[
                                                                            17724,
                                                                            undefined
                                                                          ];
                                                                  } else {
                                                                    return /* `Ok */[
                                                                            17724,
                                                                            match[1]
                                                                          ];
                                                                  }
                                                                }
                                                              } else {
                                                                return /* `Ok */[
                                                                        17724,
                                                                        term[1]
                                                                      ];
                                                              }
                                                            }
                                                          }))), (function (series) {
                                                    var items = Belt_SetString.fromArray(Curry._1(Import.List.toArray, Curry._2(Import.List.keepMap, series, (function (x) {
                                                                    return x;
                                                                  }))));
                                                    return /* tuple */[
                                                            name,
                                                            items
                                                          ];
                                                  }));
                                    }))), (function (param) {
                              return /* :: */[
                                      /* tuple */[
                                        Root$SolvuuApi.Venn_diagram.Arg.Const.Label.data,
                                        /* Invalid */144245463
                                      ],
                                      /* [] */0
                                    ];
                            })), (function (columnsWithItems) {
                          var calculateSubsetsOf = function (input) {
                            if (input) {
                              var tail = input[1];
                              var head = input[0];
                              var items = head[1];
                              var name = head[0];
                              if (tail) {
                                var subsetsOfHead = calculateSubsetsOf(/* :: */[
                                      head,
                                      /* [] */0
                                    ]);
                                var subsetsOfTail = calculateSubsetsOf(tail);
                                var subsetsOfTailWithHead = Curry._2(Import.List.map, subsetsOfTail, (function (param) {
                                        return /* tuple */[
                                                /* :: */[
                                                  name,
                                                  param[0]
                                                ],
                                                Belt_SetString.intersect(items, param[1])
                                              ];
                                      }));
                                return Pervasives.$at(subsetsOfTailWithHead, Pervasives.$at(subsetsOfTail, subsetsOfHead));
                              } else {
                                return /* :: */[
                                        /* tuple */[
                                          /* :: */[
                                            name,
                                            /* [] */0
                                          ],
                                          items
                                        ],
                                        /* [] */0
                                      ];
                              }
                            } else {
                              return /* [] */0;
                            }
                          };
                          var subsets = Curry._2(Import.List.map, calculateSubsetsOf(columnsWithItems), (function (param) {
                                  var items = param[1];
                                  return /* `Record */[
                                          847309489,
                                          Curry._3(Root$SolvuuApi.Venn_diagram.T.Subset.to_record, param[0], Belt_SetString.toList(items), Belt_SetString.size(items))
                                        ];
                                }));
                          return /* `Viz */[
                                  4300231,
                                  /* `Record */[
                                    847309489,
                                    Curry._2(Lang$SolvuuApi.Record.tagged, f, /* `Record */[
                                          847309489,
                                          /* :: */[
                                            /* tuple */[
                                              Root$SolvuuApi.Venn_diagram.T.Const.Label.subsets,
                                              /* `List */[
                                                848054398,
                                                subsets
                                              ]
                                            ],
                                            /* [] */0
                                          ]
                                        ])
                                  ]
                                ];
                        }));
          }));
    var match = Curry._1(Std$Bolt.Res.join, resResTerm);
    var tmp;
    if (match[0] >= 106380200) {
      var message = Curry._1(Import.List.toArray, Curry._2(Import.List.map, match[1], (function (param) {
                    var label = Curry._1(Lang$SolvuuApi.Label.to_string, param[0]);
                    var reason = param[1] >= 144245463 ? "invalid" : "not_found";
                    return "(" + (label + (", " + (reason + ") ")));
                  }))).join(" ");
      tmp = Curry._2(Lang$SolvuuApi.Term.eval_error, message, t);
    } else {
      tmp = match[1];
    }
    return Promise.resolve(tmp);
  }
}

export {
  $$eval ,
  
}
/* Import Not a pure module */
