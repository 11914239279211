


function parent(context) {
  return context.parent;
}

function into(_context, parent) {
  return {
          meta: undefined,
          parent: parent
        };
}

function withMeta(context, meta) {
  return {
          meta: meta,
          parent: context.parent
        };
}

var root = {
  meta: undefined,
  parent: /* Root */914889282
};

export {
  root ,
  parent ,
  into ,
  withMeta ,
  
}
/* No side effect */
