import { union } from "ramda";

export const BLOGPOSTS = [
  require("blogposts/shelf-life-of-bioinformatics-software.md")
];

export const IMAGES = {
  "hello-world": require("blogposts/hello-world.png")
};

export const CATEGORIES = BLOGPOSTS.map(b => b.categories)
  .reduce(union, [])
  .sort();
