import randomColor from "randomcolor";
import { compose, flatten, repeat, zip } from "ramda";
import { schemeCategory10 } from "d3-scale-chromatic";

const repeatArrayTimes = (array, num) => flatten(repeat(array, num));
const interleave = compose(
  flatten,
  zip
);

export const sequential = repeatArrayTimes(
  randomColor({
    count: 85,
    seed: "solvuu",
    luminosity: "dark"
  }),
  60
);

export const categorical = interleave(
  randomColor({ count: 200, seed: "solvuu", luminosity: "dark" }),
  randomColor({ count: 200, seed: "s0lv88", luminosity: "dark" })
);

export const categoricalSimple = repeatArrayTimes(schemeCategory10, 50);

export const primary = categorical[0];

export const secondary = categorical[3];
