

import * as React from "react";

function WithTestId(Props) {
  var id = Props.id;
  var children = Props.children;
  return React.cloneElement(children, {
              "data-testid": id
            });
}

var make = WithTestId;

export {
  make ,
  
}
/* react Not a pure module */
