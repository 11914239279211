

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as StyleCss from "./style.css";

var styles = StyleCss.default;

function PrivacyPolicy(Props) {
  var match = React.useState((function () {
          return "";
        }));
  var setState = match[1];
  React.useEffect((function () {
          import("./privacy-policy.html").then((function (contents) {
                  Curry._1(setState, (function (param) {
                          return contents.default;
                        }));
                  return Promise.resolve(/* () */0);
                }));
          return ;
        }), /* array */[]);
  return React.createElement("div", {
              className: styles.root,
              dangerouslySetInnerHTML: {
                __html: match[0]
              }
            });
}

var make = PrivacyPolicy;

export {
  styles ,
  make ,
  
}
/* styles Not a pure module */
