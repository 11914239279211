

import * as Card from "../components/Card/Card.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Term0 from "./Term0.bs.js";
import * as React from "react";
import * as Import from "../features/import.bs.js";
import * as Caml_obj from "bs-platform/lib/es6/caml_obj.js";
import * as ReactExtras from "../lib/solvuu/ReactExtras.bs.js";
import * as Lang$SolvuuApi from "solvuu-api/./lang.bs.js";
import * as VerticalLayout from "../components/Layout/VerticalLayout.bs.js";
import * as TermRenderContext from "./TermRenderContext.bs.js";
import * as Layout from "components/Layout";

function Let$Button(Props) {
  var value = Props.value;
  if (value.startsWith("_")) {
    return React.createElement(React.Fragment, undefined, React.createElement("label", undefined, "Expression"), React.createElement("input", {
                    name: "name",
                    type: "hidden",
                    value: value
                  }));
  } else {
    return React.createElement(Card.Header.make, {
                children: React.createElement("input", {
                      name: "name",
                      readOnly: true,
                      type: "text",
                      value: value
                    })
              });
  }
}

var Button = {
  make: Let$Button
};

function Let(Props) {
  var apiClient = Props.apiClient;
  var renderTerm = Props.renderTerm;
  var showButtons = Props.showButtons;
  var term = Props.term;
  var context = Props.context;
  var match = Curry._1(Lang$SolvuuApi.Term.Let.of_term, term);
  var body = match[1];
  var l = match[0];
  var loop = function (_l) {
    while(true) {
      var l = _l;
      if (l) {
        var rest = l[1];
        if (rest) {
          _l = rest;
          continue ;
        } else {
          return l[0][1];
        }
      } else {
        return body;
      }
    };
  };
  var last = loop(l);
  var match$1 = Caml_obj.caml_equal(last, body);
  var bodyComponent = match$1 ? null : React.createElement("div", undefined, Curry._6(renderTerm, apiClient, body, /* Type_not_available */-883458824, TermRenderContext.root, undefined, /* () */0));
  var header = function (name) {
    if (showButtons) {
      return React.createElement(Let$Button, {
                  value: name
                });
    } else {
      return null;
    }
  };
  return React.createElement(Layout.VerticalLayout, VerticalLayout.makeProps(/* x3 */26811, null, /* () */0), ReactExtras.listToElement(Curry._2(Import.List.mapWithIndex, l, (function (i, param) {
                        return React.createElement(Layout.VerticalLayoutItem, {
                                    children: React.createElement(Term0.RootCard.make, {
                                          header: header(param[0]),
                                          children: Curry._6(renderTerm, apiClient, param[1], /* Type_not_available */-883458824, TermRenderContext.into(context, /* LetBinding */112487626), undefined, /* () */0)
                                        }),
                                    key: String(i)
                                  });
                      }))), bodyComponent);
}

var make = Let;

export {
  Button ,
  make ,
  
}
/* Card Not a pure module */
