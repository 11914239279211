

import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Import from "../../../../../features/import.bs.js";
import * as ReactIntl from "../../../../../lib/ReactIntl.bs.js";
import * as ReactIntl$1 from "react-intl";
import * as StyleCss from "./style.css";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as ReactExtras from "../../../../../lib/solvuu/ReactExtras.bs.js";
import * as TableElements from "../../../../../table/TableElements.bs.js";

var styles = StyleCss.default;

var JsProps = { };

function TaxonomySummaryTable(Props) {
  var taxonomies = Props.taxonomies;
  var sampleIds = Props.sampleIds;
  var hiddenTaxonomies = Props.hiddenTaxonomies;
  var selectedTaxonomy = Props.selectedTaxonomy;
  var onChangeHiddenTaxonomies = Props.onChangeHiddenTaxonomies;
  var getTaxonomyDistributionInDataset = Props.getTaxonomyDistributionInDataset;
  var getTaxonomyDistributionInSample = Props.getTaxonomyDistributionInSample;
  var getTaxonomyColor = Props.getTaxonomyColor;
  var send = function (action) {
    if (action.tag) {
      return Curry._1(onChangeHiddenTaxonomies, Curry._2(Import.List.add, hiddenTaxonomies, action[0]));
    } else {
      var taxonomy = action[0];
      return Curry._1(onChangeHiddenTaxonomies, Curry._2(Import.List.keep, hiddenTaxonomies, (function (t) {
                        return t !== taxonomy;
                      })));
    }
  };
  var visibleCheckboxChecked = function (taxonomy) {
    return !Curry._3(Import.List.has, hiddenTaxonomies, taxonomy, (function (prim, prim$1) {
                  return prim === prim$1;
                }));
  };
  var taxonomyHeaders_000 = React.createElement(TableElements.HeaderElement.make, {
        colSpan: 2,
        children: "Taxonomy",
        key: "$__taxonomy_"
      });
  var taxonomyHeaders_001 = /* :: */[
    React.createElement(TableElements.HeaderElement.make, {
          colSpan: 2,
          children: "Total",
          key: "$__taxonomy_distribution__"
        }),
    /* [] */0
  ];
  var taxonomyHeaders = /* :: */[
    taxonomyHeaders_000,
    taxonomyHeaders_001
  ];
  var sampleHeaders = Curry._2(Import.List.map, sampleIds, (function (sampleId) {
          return React.createElement(TableElements.HeaderElement.make, {
                      colSpan: 2,
                      children: sampleId,
                      key: sampleId
                    });
        }));
  var headers = ReactExtras.listToElement(Curry._2(Import.List.concat, taxonomyHeaders, sampleHeaders));
  var countSubHeader = React.createElement(TableElements.HeaderElement.make, {
        children: "count",
        key: "$__sub_count"
      });
  var percentSubHeader = React.createElement(TableElements.HeaderElement.make, {
        children: "%",
        key: "$__sub_percent"
      });
  var taxonomySubHeaders_000 = React.createElement(TableElements.HeaderElement.make, {
        variant: /* Narrow */0,
        children: "Visible",
        key: "$__sub_taxonomy_toggle"
      });
  var taxonomySubHeaders_001 = /* :: */[
    React.createElement(TableElements.HeaderElement.make, {
          children: "Name",
          key: "$__sub_taxonomy_name"
        }),
    /* :: */[
      countSubHeader,
      /* :: */[
        percentSubHeader,
        /* [] */0
      ]
    ]
  ];
  var taxonomySubHeaders = /* :: */[
    taxonomySubHeaders_000,
    taxonomySubHeaders_001
  ];
  var sampleSubHeaders = Curry._2(Import.List.map, sampleIds, (function (sampleId) {
          return React.createElement(React.Fragment, {
                      children: null,
                      key: sampleId
                    }, countSubHeader, percentSubHeader);
        }));
  var subHeaders = ReactExtras.listToElement(Curry._2(Import.List.concat, taxonomySubHeaders, sampleSubHeaders));
  var rowsWithHighlight = Curry._2(Import.List.map, taxonomies, (function (taxonomy) {
          var datasetDistribution = getTaxonomyDistributionInDataset(taxonomy);
          var color = getTaxonomyColor(taxonomy);
          var highlight = selectedTaxonomy !== undefined && selectedTaxonomy === taxonomy ? color : undefined;
          var taxonomyCells_000 = React.createElement(TableElements.CellElement.make, {
                testId: "toggle",
                children: React.createElement("div", {
                      className: styles.taxonomyToggle
                    }, React.createElement("input", {
                          checked: visibleCheckboxChecked(taxonomy),
                          type: "checkbox",
                          onChange: (function (param) {
                              var taxonomy$1 = taxonomy;
                              var $$event = param;
                              var match = ReactExtras.eventTargetChecked($$event);
                              if (match) {
                                return send(/* ShowTaxonomy */Block.__(0, [taxonomy$1]));
                              } else {
                                return send(/* HideTaxonomy */Block.__(1, [taxonomy$1]));
                              }
                            })
                        })),
                key: "$__taxonomy_toggle"
              });
          var taxonomyCells_001 = /* :: */[
            React.createElement(TableElements.CellElement.make, {
                  testId: "name",
                  title: taxonomy,
                  children: React.createElement("div", {
                        className: styles.taxonomyCell
                      }, React.createElement("div", {
                            className: styles.taxonomyColor,
                            style: {
                              background: color
                            }
                          }), React.createElement("div", {
                            className: styles.taxonomyName
                          }, taxonomy)),
                  key: "$__taxonomy_name"
                }),
            /* :: */[
              React.createElement(TableElements.CellElement.make, {
                    testId: "counts[$dataset]",
                    children: React.createElement(ReactIntl$1.FormattedNumber, ReactIntl.FormattedNumber.makeProps(datasetDistribution.value, undefined, undefined, undefined, undefined, /* () */0)),
                    key: "$__counts_dataset"
                  }),
              /* :: */[
                React.createElement(TableElements.CellElement.make, {
                      testId: "percentage[$dataset]",
                      children: React.createElement(ReactIntl$1.FormattedNumber, ReactIntl.FormattedNumber.makeProps(datasetDistribution.relative, /* percent */-119887163, 2, undefined, undefined, /* () */0)),
                      key: "$__percentage_dataset"
                    }),
                /* [] */0
              ]
            ]
          ];
          var taxonomyCells = /* :: */[
            taxonomyCells_000,
            taxonomyCells_001
          ];
          var sampleCells = Curry._2(Import.List.map, sampleIds, (function (sampleId) {
                  var sampleDistribution = getTaxonomyDistributionInSample(taxonomy, sampleId);
                  return React.createElement(React.Fragment, {
                              children: null,
                              key: sampleId
                            }, React.createElement(TableElements.CellElement.make, {
                                  testId: "counts[" + (String(sampleId) + "]"),
                                  children: React.createElement(ReactIntl$1.FormattedNumber, ReactIntl.FormattedNumber.makeProps(sampleDistribution.value, undefined, undefined, undefined, undefined, /* () */0))
                                }), React.createElement(TableElements.CellElement.make, {
                                  testId: "percentage[" + (String(sampleId) + "]"),
                                  children: React.createElement(ReactIntl$1.FormattedNumber, ReactIntl.FormattedNumber.makeProps(sampleDistribution.relative, /* percent */-119887163, 2, undefined, undefined, /* () */0))
                                }));
                }));
          var cells = ReactExtras.listToElement(Curry._2(Import.List.concat, taxonomyCells, sampleCells));
          var row = React.createElement(TableElements.RowElement.make, {
                testId: "distribution[" + (String(taxonomy) + "]"),
                children: cells,
                key: taxonomy
              });
          return /* tuple */[
                  row,
                  taxonomy,
                  highlight
                ];
        }));
  var rows = ReactExtras.listToElement(Curry._2(Import.List.map, rowsWithHighlight, (function (param) {
              var highlight = param[2];
              var style = highlight !== undefined ? ({
                    border: "2px solid " + (String(highlight) + "")
                  }) : undefined;
              var tmp = {
                key: param[1]
              };
              if (style !== undefined) {
                tmp.style = Caml_option.valFromOption(style);
              }
              return React.createElement("tbody", tmp, param[0]);
            })));
  return React.createElement(TableElements.TableElement.make, {
              children: null
            }, React.createElement("thead", undefined, React.createElement(TableElements.RowElement.make, {
                      children: headers
                    }), React.createElement(TableElements.RowElement.make, {
                      children: subHeaders
                    })), rows);
}

function mapProps(props) {
  return {
          taxonomies: Curry._1(Import.List.fromArray, props.taxonomies),
          sampleIds: Curry._1(Import.List.fromArray, props.sampleIds),
          hiddenTaxonomies: Curry._1(Import.List.fromArray, props.hiddenTaxonomies),
          selectedTaxonomy: Caml_option.nullable_to_opt(props.selectedTaxonomy),
          onChangeHiddenTaxonomies: (function (newHiddenTaxonomies) {
              return props.onChangeHiddenTaxonomies(Curry._1(Import.List.toArray, newHiddenTaxonomies));
            }),
          getTaxonomyDistributionInDataset: props.getTaxonomyDistributionInDataset,
          getTaxonomyDistributionInSample: props.getTaxonomyDistributionInSample,
          getTaxonomyColor: props.getTaxonomyColor
        };
}

var $$default = ReactExtras.jsComponent("TaxonomySummaryTable", TaxonomySummaryTable, mapProps);

var make = TaxonomySummaryTable;

export {
  styles ,
  JsProps ,
  make ,
  mapProps ,
  $$default ,
  $$default as default,
  
}
/* styles Not a pure module */
