

import * as Link from "../../Link.bs.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Button from "../../../components/Button.bs.js";
import * as Import from "../../../features/import.bs.js";
import * as BlobEvents from "../../BlobEvents.bs.js";
import * as Caml_int64 from "bs-platform/lib/es6/caml_int64.js";
import * as WithTestId from "../../WithTestId.bs.js";
import * as ReactIntl from "react-intl";
import * as StyleCss from "./style.css";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as FormattedBytes from "../../../components/FormattedBytes/FormattedBytes.bs.js";
import * as ReactBootstrap from "../../../lib/ReactBootstrap.bs.js";
import * as VerticalLayout from "../../../components/Layout/VerticalLayout.bs.js";
import * as HorizontalLayout from "../../../components/Layout/HorizontalLayout.bs.js";
import * as Layout from "components/Layout";
import * as Lib from "react-bootstrap/lib";

var styles = StyleCss.default;

function renderHeader(id, originalName, $$location, isUploaded, uploadError, details, onToggleDetails) {
  var toggleDetailsButton = React.createElement(WithTestId.make, {
        id: "upload-toggle-details-button",
        children: React.createElement(Button.make, Button.makeProps(onToggleDetails, undefined, /* `Basic */[
                  -1058563442,
                  Curry._4(Button.Type.Basic.make, undefined, /* Secondary */67972948, undefined, /* () */0)
                ], React.createElement(ReactIntl.FormattedMessage, {
                      id: "upload.uploads.actions." + (
                        details ? "hideDetails" : "showDetails"
                      )
                    }), /* () */0))
      });
  var showButton = React.createElement(Layout.HorizontalLayoutItem, {
        children: React.createElement(WithTestId.make, {
              id: "upload-show-button",
              children: React.createElement(Button.Link.make, Button.Link.makeProps(undefined, /* `Drive */[
                        -219555606,
                        /* `Id */[
                          16379,
                          id
                        ]
                      ], /* Secondary */67972948, undefined, undefined, React.createElement(ReactIntl.FormattedMessage, {
                            id: "upload.uploads.actions.show"
                          }), /* () */0))
            })
      });
  var identifierContents;
  if (originalName !== undefined) {
    var originalName$1 = originalName;
    identifierContents = originalName$1 === "" ? "#" + (String(id) + "") : "#" + (String(id) + (" | " + (String(originalName$1) + "")));
  } else {
    identifierContents = "#" + (String(id) + "");
  }
  var identifier;
  if ($$location !== undefined) {
    var match = $$location;
    identifier = React.createElement(WithTestId.make, {
          id: "entity-link",
          children: React.createElement(Link.make, {
                to: /* `Entity */[
                  967870275,
                  /* `Main */[
                    858743897,
                    match.path
                  ]
                ],
                children: match.name
              })
        });
  } else {
    identifier = React.createElement(WithTestId.make, {
          id: "upload-identifier",
          children: React.createElement("span", undefined, identifierContents)
        });
  }
  var uploadStatus = isUploaded ? React.createElement(Lib.Label, ReactBootstrap.Label.makeProps(/* success */944645571, styles.statusLabel, React.createElement(ReactIntl.FormattedMessage, {
                  id: "upload.uploads.success"
                }), /* () */0)) : (
      uploadError ? React.createElement(Lib.Label, ReactBootstrap.Label.makeProps(/* danger */512905955, styles.statusLabel, React.createElement(ReactIntl.FormattedMessage, {
                      id: "upload.uploads.error"
                    }), /* () */0)) : null
    );
  var aside = React.createElement(Layout.HorizontalLayout, HorizontalLayout.makeProps(/* x2 */26810, null, /* () */0), React.createElement(Layout.HorizontalLayoutItem, {
            children: React.createElement(WithTestId.make, {
                  id: "upload-status",
                  children: React.createElement("div", {
                        className: styles.status
                      }, uploadStatus)
                })
          }), React.createElement(Layout.HorizontalLayoutItem, {
            children: toggleDetailsButton
          }), showButton);
  return React.createElement(Layout.VerticalLayoutItem, {
              children: React.createElement("div", {
                    className: styles.header
                  }, identifier, aside)
            });
}

function progressBarLabel(progress, uploadedBytes) {
  if (progress === 0) {
    if (uploadedBytes > 0) {
      return React.createElement(FormattedBytes.make, {
                  value: Caml_int64.of_float(uploadedBytes)
                });
    } else {
      return React.createElement(ReactIntl.FormattedMessage, {
                  id: "upload.uploads.preparing"
                });
    }
  } else {
    return Curry._1(Import.sprintf(/* Format */[
                    /* Float */Block.__(8, [
                        /* Float_g */9,
                        /* No_padding */0,
                        /* Lit_precision */[4],
                        /* Char_literal */Block.__(12, [
                            /* "%" */37,
                            /* End_of_format */0
                          ])
                      ]),
                    "%.4g%%"
                  ]), progress);
  }
}

function progressBarActive(progress) {
  return progress === 0;
}

function progressBarStyle(uploadError) {
  if (uploadError) {
    return "danger";
  } else {
    return "info";
  }
}

function renderProgressBar(progress, uploadedBytes, isUploaded, uploadError) {
  if (isUploaded) {
    return null;
  } else {
    var match = uploadError && uploadedBytes <= 0;
    if (match) {
      return null;
    } else {
      var label = progressBarLabel(progress, uploadedBytes);
      var match$1 = progress > 0;
      var now = match$1 ? progress : 100;
      var active = progress === 0;
      var style = uploadError ? "danger" : "info";
      var key = active ? "active" : "inactive";
      return React.createElement(Layout.VerticalLayoutItem, {
                  children: React.createElement(Lib.ProgressBar, {
                        now: now,
                        label: label,
                        active: active,
                        bsClass: styles.progressBar,
                        bsStyle: style,
                        key: key
                      })
                });
    }
  }
}

function renderDetails(id, checksum, totalBytes, uploadedBytes, details, apiClient) {
  var match = !details;
  if (match) {
    return null;
  } else {
    var formattedTotalBytes = totalBytes !== undefined ? React.createElement(FormattedBytes.make, {
            value: Caml_int64.of_float(totalBytes)
          }) : "unknown";
    var match$1 = uploadedBytes > 0;
    var formattedUploadedBytes = match$1 ? React.createElement(FormattedBytes.make, {
            value: Caml_int64.of_float(uploadedBytes)
          }) : "unknown";
    return React.createElement(React.Fragment, undefined, React.createElement(Layout.VerticalLayoutItem, {
                    children: React.createElement("div", {
                          className: styles.divider
                        })
                  }), React.createElement(Layout.VerticalLayoutItem, {
                    children: React.createElement(Layout.VerticalLayout, VerticalLayout.makeProps(/* x2 */26810, null, /* () */0), checksum !== undefined ? React.createElement(Layout.VerticalLayoutItem, {
                                children: React.createElement(ReactIntl.FormattedMessage, {
                                      id: "upload.uploads.details.checksum",
                                      values: {
                                        checksum: Caml_option.valFromOption(checksum)
                                      }
                                    })
                              }) : null, React.createElement(Layout.VerticalLayoutItem, {
                              children: React.createElement(ReactIntl.FormattedMessage, {
                                    id: "upload.uploads.details.totalBytes",
                                    values: {
                                      bytes: formattedTotalBytes
                                    }
                                  })
                            }), React.createElement(Layout.VerticalLayoutItem, {
                              children: React.createElement(ReactIntl.FormattedMessage, {
                                    id: "upload.uploads.details.uploadedBytes",
                                    values: {
                                      bytes: formattedUploadedBytes
                                    }
                                  })
                            }), React.createElement(Layout.VerticalLayoutItem, {
                              children: null
                            }))
                  }), React.createElement(BlobEvents.make, {
                    blobId: id,
                    apiClient: apiClient
                  }));
  }
}

var initialState = {
  details: false
};

function UploadRow(Props) {
  var id = Props.id;
  var $$location = Props.location;
  var originalName = Props.originalName;
  var checksum = Props.checksum;
  var meta = Props.meta;
  var apiClient = Props.apiClient;
  var match = React.useState((function () {
          return initialState;
        }));
  var setState = match[1];
  var state = match[0];
  var onToggleDetails = function (_event) {
    return Curry._1(setState, (function (param) {
                  return {
                          details: !param.details
                        };
                }));
  };
  var children = React.createElement("div", {
        className: styles.root
      }, React.createElement(Layout.VerticalLayout, VerticalLayout.makeProps(/* x3 */26811, null, /* () */0), renderHeader(id, originalName, $$location, meta.isUploaded, meta.uploadError, state.details, onToggleDetails), renderProgressBar(meta.progressPercentage, meta.uploadedBytes, meta.isUploaded, meta.uploadError), renderDetails(id, checksum, meta.totalBytes, meta.uploadedBytes, state.details, apiClient)));
  return React.createElement(WithTestId.make, {
              id: "upload",
              children: React.cloneElement(children, {
                    "data-blobid": id
                  })
            });
}

var make = UploadRow;

export {
  styles ,
  renderHeader ,
  progressBarLabel ,
  progressBarActive ,
  progressBarStyle ,
  renderProgressBar ,
  renderDetails ,
  initialState ,
  make ,
  
}
/* styles Not a pure module */
