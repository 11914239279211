

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Import from "./features/import.bs.js";

function useToggle(initial) {
  var match = React.useState((function () {
          return initial;
        }));
  var setState = match[1];
  var toggle = function (param) {
    return Curry._1(setState, (function (x) {
                  return !x;
                }));
  };
  return /* tuple */[
          match[0],
          toggle
        ];
}

function useWithInterval($staropt$star, pollInterval, f) {
  var immediateStart = $staropt$star !== undefined ? $staropt$star : true;
  React.useEffect((function () {
          if (immediateStart) {
            Curry._1(f, /* () */0);
          }
          var id = setInterval(f, pollInterval);
          return (function (param) {
                    clearInterval(id);
                    return /* () */0;
                  });
        }), /* array */[]);
  return /* () */0;
}

function useApi(verb, m, info, apiClient) {
  var match = React.useState((function () {
          return /* Initial */418396260;
        }));
  var setState = match[1];
  var state = match[0];
  React.useEffect((function () {
          if (state === 418396260) {
            Curry._1(setState, (function (param) {
                    return /* Loading */-700189092;
                  }));
            Import.$great$great$pipe(Curry._3(apiClient.f, verb, m, info), (function (response) {
                    return Curry._1(setState, (function (param) {
                                  return response;
                                }));
                  }));
          }
          return ;
        }), /* array */[state]);
  var retry = function (param) {
    return Curry._1(setState, (function (param) {
                  return /* Initial */418396260;
                }));
  };
  return /* tuple */[
          state,
          retry
        ];
}

function useApiSubscription(pollInterval, verb, m, info, apiClient) {
  var match = useApi(verb, m, info, apiClient);
  useWithInterval(false, pollInterval, match[1]);
  return match[0];
}

export {
  useToggle ,
  useWithInterval ,
  useApi ,
  useApiSubscription ,
  
}
/* react Not a pure module */
