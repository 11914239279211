

import * as $$History from "history";
import * as Pervasives from "bs-platform/lib/es6/pervasives.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

function ofString(x) {
  switch (x) {
    case "POP" :
        return /* POP */3996017;
    case "PUSH" :
        return /* PUSH */891410906;
    case "REPLACE" :
        return /* REPLACE */721165332;
    default:
      return Pervasives.failwith("Invalid action " + x);
  }
}

var $$Location = { };

function createBrowserHistory(basename, forceRefresh, keyLength, getUserConfirmation, param) {
  var match = $$History.createBrowserHistory(basename !== undefined ? Caml_option.valFromOption(basename) : undefined, forceRefresh !== undefined ? Caml_option.valFromOption(forceRefresh) : undefined, keyLength !== undefined ? Caml_option.valFromOption(keyLength) : undefined, getUserConfirmation !== undefined ? Caml_option.valFromOption(getUserConfirmation) : undefined);
  return {
          length: match.length,
          location: match.location,
          action: ofString(match.action)
        };
}

var Action = { };

export {
  Action ,
  $$Location ,
  createBrowserHistory ,
  
}
/* history Not a pure module */
