import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import OnClickOutside from "components/OnClickOutside/OnClickOutside.js";

import iconMore from "icons/icon-more-black.svg";
import styles from "./style.css";

export class EntityActionsDropdown extends Component {
  static propTypes = {
    entityPath: PropTypes.string.isRequired,
    requestEntityDownload: PropTypes.func.isRequired,
    meta: PropTypes.shape({
      permissions: PropTypes.shape({
        download: PropTypes.bool.isRequired
      }).isRequired
    }).isRequired
  };

  state = {
    open: false
  };

  handleClickOutside = event => {
    this.setState({ open: false });
  };

  handleButtonClick = event => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ open: !this.state.open });
  };

  handleEntityDownload = event => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ open: false });
    this.props.requestEntityDownload(this.props.entityPath);
  };

  renderMenuItem = item => {
    const { value, labelId, onClick } = item;

    return (
      <div
        key={value}
        className={styles.menuItem}
        data-testid={`entity-actions-dropdown[${value}]`}
        onClick={onClick}
        tabIndex="0"
      >
        <FormattedMessage id={labelId} />
      </div>
    );
  };

  renderMenuItems = () => {
    const {
      meta: { permissions }
    } = this.props;
    const menuItems = [
      {
        value: "download",
        labelId: "entities.actions.download.menuItem",
        onClick: this.handleEntityDownload,
        visible: permissions.download
      }
    ];
    return menuItems.filter(i => i.visible).map(this.renderMenuItem);
  };

  render() {
    const renderedMenuItems = this.renderMenuItems();

    if (renderedMenuItems.length === 0) return null;

    return (
      <OnClickOutside onClickOutside={this.handleClickOutside}>
        {containerRef => (
          <div className={styles.root} ref={containerRef}>
            <button
              type="button"
              className={styles.dots}
              onClick={this.handleButtonClick}
              data-testid="entity-actions-dropdown"
            >
              <img src={iconMore} alt="" />
            </button>
            {this.state.open && (
              <div
                className={styles.menu}
                data-testid="entity-actions-dropdown-menu"
              >
                {renderedMenuItems}
              </div>
            )}
          </div>
        )}
      </OnClickOutside>
    );
  }
}

export default EntityActionsDropdown;
