

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as JobMetrics from "./Metrics/JobMetrics.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as JobDetails0 from "./JobDetails0.bs.js";
import * as VerticalLayout from "../../../components/Layout/VerticalLayout.bs.js";
import * as Time$SolvuuClient from "solvuu-client/./time.bs.js";
import * as Layout from "components/Layout";

function JobDetails(Props) {
  var job = Props.job;
  var apiClient = Props.apiClient;
  var created = Time$SolvuuClient.of_span_since_epoch(Curry._1(Time$SolvuuClient.Span.of_sec, job.created));
  var completed = Belt_Option.map(job.completed, (function (x) {
          return Time$SolvuuClient.of_span_since_epoch(Curry._1(Time$SolvuuClient.Span.of_sec, x));
        }));
  var detailsSection = JobDetails0.renderSolvuuDetails(job.id, job.spec[1], job.output, created, completed, apiClient);
  var metricsSection = React.createElement(JobMetrics.make, {
        job: job,
        apiClient: apiClient
      });
  return React.createElement(Layout.VerticalLayout, VerticalLayout.makeProps(/* x3 */26811, null, /* () */0), React.createElement(Layout.VerticalLayoutItem, {
                  children: detailsSection
                }), React.createElement(Layout.VerticalLayoutItem, {
                  children: JobDetails0.sectionSeparator
                }), React.createElement(Layout.VerticalLayoutItem, {
                  children: metricsSection
                }), React.createElement(Layout.VerticalLayoutItem, {
                  children: JobDetails0.sectionSeparator
                }));
}

var styles = JobDetails0.styles;

var EmptyValue = JobDetails0.EmptyValue;

var JobDetailCategory = JobDetails0.JobDetailCategory;

var JobDetail = JobDetails0.JobDetail;

var Columns = JobDetails0.Columns;

var Column = JobDetails0.Column;

var sectionSeparator = JobDetails0.sectionSeparator;

var JobOutputSection = JobDetails0.JobOutputSection;

var renderSolvuuDetails = JobDetails0.renderSolvuuDetails;

var make = JobDetails;

export {
  styles ,
  EmptyValue ,
  JobDetailCategory ,
  JobDetail ,
  Columns ,
  Column ,
  sectionSeparator ,
  JobOutputSection ,
  renderSolvuuDetails ,
  make ,
  
}
/* react Not a pure module */
