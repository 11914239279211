

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Import from "../features/import.bs.js";
import * as Lang$SolvuuApi from "solvuu-api/./lang.bs.js";
import * as Root$SolvuuApi from "solvuu-api/./root.bs.js";

function $$eval(f, t, simpleServerSideEval) {
  if (typeof t === "number" || t[0] !== 847309489) {
    return Promise.resolve(Curry._2(Lang$SolvuuApi.Term.eval_error, "Expected record", t));
  } else {
    var resPromiseTerm = Curry._2(Root$SolvuuApi.Summary_statistics.Arg.of_record, t[1], (function (columnNames, data) {
            var dataVar = Curry._1(Lang$SolvuuApi.Var.Exn.of_string, "data");
            var rowVar = Curry._1(Lang$SolvuuApi.Var.Exn.of_string, "row");
            return Curry._1(simpleServerSideEval, Curry._3(Lang$SolvuuApi.Term.let_in, dataVar, data, /* `List */[
                            848054398,
                            Curry._2(Import.List.map, columnNames, (function (name) {
                                    return Curry._1(Lang$SolvuuApi.Term.tuple, /* :: */[
                                                /* `String */[
                                                  -976970511,
                                                  name
                                                ],
                                                /* :: */[
                                                  /* `App */[
                                                    3257473,
                                                    /* tuple */[
                                                      /* `Var */[
                                                        4298439,
                                                        Root$SolvuuApi.stats
                                                      ],
                                                      /* `App */[
                                                        3257473,
                                                        /* tuple */[
                                                          /* `Var */[
                                                            4298439,
                                                            Root$SolvuuApi.map
                                                          ],
                                                          Curry._1(Lang$SolvuuApi.Term.tuple, /* :: */[
                                                                /* `Var */[
                                                                  4298439,
                                                                  dataVar
                                                                ],
                                                                /* :: */[
                                                                  /* `Lambda */[
                                                                    89433895,
                                                                    /* tuple */[
                                                                      rowVar,
                                                                      /* `App */[
                                                                        3257473,
                                                                        /* tuple */[
                                                                          /* `Project */[
                                                                            -1019462983,
                                                                            /* `Named */[
                                                                              699531161,
                                                                              name
                                                                            ]
                                                                          ],
                                                                          /* `Var */[
                                                                            4298439,
                                                                            rowVar
                                                                          ]
                                                                        ]
                                                                      ]
                                                                    ]
                                                                  ],
                                                                  /* [] */0
                                                                ]
                                                              ])
                                                        ]
                                                      ]
                                                    ]
                                                  ],
                                                  /* [] */0
                                                ]
                                              ]);
                                  }))
                          ]));
          }));
    if (resPromiseTerm[0] >= 106380200) {
      var message = Curry._1(Import.List.toArray, Curry._2(Import.List.map, resPromiseTerm[1], (function (param) {
                    var label = Curry._1(Lang$SolvuuApi.Label.to_string, param[0]);
                    var reason = param[1] >= 144245463 ? "invalid" : "not_found";
                    return "(" + (label + (", " + (reason + ") ")));
                  }))).join(" ");
      return Promise.resolve(Curry._2(Lang$SolvuuApi.Term.eval_error, message, t));
    } else {
      return resPromiseTerm[1];
    }
  }
}

export {
  $$eval ,
  
}
/* Import Not a pure module */
