

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Import from "../features/import.bs.js";
import * as $$String from "bs-platform/lib/es6/string.js";
import * as Upload from "../containers/Upload/Upload.bs.js";
import * as Upload0 from "../containers/Upload/Upload0.bs.js";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Caml_int64 from "bs-platform/lib/es6/caml_int64.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as S3BlobUpload from "../lib/S3BlobUpload/S3BlobUpload.bs.js";
import * as Blob$SolvuuApi from "solvuu-api/./blob.bs.js";
import * as Caml_builtin_exceptions from "bs-platform/lib/es6/caml_builtin_exceptions.js";

var Start = { };

var Progress = { };

var Success = { };

var Failure = { };

var Upload$1 = {
  Start: Start,
  Progress: Progress,
  Success: Success,
  Failure: Failure
};

var Action = {
  Upload: Upload$1
};

var initialState_uploadIds = /* array */[];

var initialState_meta = {
  blob: {
    createFromURI: Upload0.Operations.$$Blob.CreateFromURI.initialState
  },
  blobs: {
    createFromFiles: Upload0.Operations.Blobs.CreateFromFiles.initialState,
    fetch: Upload0.Operations.Blobs.Fetch.initialState
  }
};

var initialState = {
  uploadIds: initialState_uploadIds,
  uploads: /* [] */0,
  meta: initialState_meta
};

var State = {
  initialState: initialState
};

function reducer(state, action) {
  if (action[0] >= 737307005) {
    var match = action[1];
    if (match[0] >= 137824358) {
      var createFromURI = Curry._2(Upload0.Operations.$$Blob.CreateFromURI.reducer, state.meta.blob.createFromURI, match[1]);
      var blob = {
        createFromURI: createFromURI
      };
      var init = state.meta;
      var meta_blobs = init.blobs;
      var meta = {
        blob: blob,
        blobs: meta_blobs
      };
      return {
              uploadIds: state.uploadIds,
              uploads: state.uploads,
              meta: meta
            };
    } else {
      var action$1 = match[1];
      var variant = action$1[0];
      if (variant >= 94326179) {
        if (variant >= 389604418) {
          var match$1 = action$1[1];
          var file = match$1.file;
          var id = match$1.id;
          var uploads = Curry._4(Import.List.setAssoc, state.uploads, id, {
                id: id,
                originalName: file.name,
                file: Caml_option.some(file),
                meta: {
                  totalBytes: file.size,
                  uploadedBytes: 0,
                  isUploading: true,
                  isUploaded: false,
                  uploadError: false
                }
              }, $$String.equal);
          return {
                  uploadIds: state.uploadIds,
                  uploads: uploads,
                  meta: state.meta
                };
        } else {
          var id$1 = action$1[1].id;
          var match$2 = Belt_List.getAssoc(state.uploads, id$1, $$String.equal);
          if (match$2 !== undefined) {
            var upload = match$2;
            var init$1 = upload.meta;
            var uploads$1 = Curry._4(Import.List.setAssoc, state.uploads, id$1, {
                  id: upload.id,
                  originalName: upload.originalName,
                  file: upload.file,
                  meta: {
                    totalBytes: init$1.totalBytes,
                    uploadedBytes: init$1.uploadedBytes,
                    isUploading: false,
                    isUploaded: true,
                    uploadError: init$1.uploadError
                  }
                }, $$String.equal);
            return {
                    uploadIds: state.uploadIds,
                    uploads: uploads$1,
                    meta: state.meta
                  };
          } else {
            return state;
          }
        }
      } else if (variant >= -464807894) {
        var id$2 = action$1[1].id;
        var match$3 = Curry._3(Import.List.getAssoc, state.uploads, id$2, $$String.equal);
        if (match$3 !== undefined) {
          var upload$1 = match$3;
          var init$2 = upload$1.meta;
          var uploads$2 = Curry._4(Import.List.setAssoc, state.uploads, id$2, {
                id: upload$1.id,
                originalName: upload$1.originalName,
                file: upload$1.file,
                meta: {
                  totalBytes: init$2.totalBytes,
                  uploadedBytes: init$2.uploadedBytes,
                  isUploading: false,
                  isUploaded: init$2.isUploaded,
                  uploadError: true
                }
              }, $$String.equal);
          return {
                  uploadIds: state.uploadIds,
                  uploads: uploads$2,
                  meta: state.meta
                };
        } else {
          return state;
        }
      } else {
        var match$4 = action$1[1];
        var id$3 = match$4.id;
        var match$5 = Belt_List.getAssoc(state.uploads, id$3, $$String.equal);
        if (match$5 !== undefined) {
          var upload$2 = match$5;
          var init$3 = upload$2.meta;
          var uploads$3 = Curry._4(Import.List.setAssoc, state.uploads, id$3, {
                id: upload$2.id,
                originalName: upload$2.originalName,
                file: upload$2.file,
                meta: {
                  totalBytes: match$4.totalBytes,
                  uploadedBytes: match$4.uploadedBytes,
                  isUploading: init$3.isUploading,
                  isUploaded: init$3.isUploaded,
                  uploadError: init$3.uploadError
                }
              }, $$String.equal);
          return {
                  uploadIds: state.uploadIds,
                  uploads: uploads$3,
                  meta: state.meta
                };
        } else {
          return state;
        }
      }
    }
  } else {
    var match$6 = action[1];
    if (match$6[0] >= 916621809) {
      var createFromFiles = Curry._2(Upload0.Operations.Blobs.CreateFromFiles.reducer, state.meta.blobs.createFromFiles, match$6[1]);
      var init$4 = state.meta.blobs;
      var blobs_fetch = init$4.fetch;
      var blobs = {
        createFromFiles: createFromFiles,
        fetch: blobs_fetch
      };
      var init$5 = state.meta;
      var meta_blob = init$5.blob;
      var meta$1 = {
        blob: meta_blob,
        blobs: blobs
      };
      return {
              uploadIds: state.uploadIds,
              uploads: state.uploads,
              meta: meta$1
            };
    } else {
      var action$2 = match$6[1];
      var $$fetch = Curry._2(Upload0.Operations.Blobs.Fetch.reducer, state.meta.blobs.fetch, action$2);
      var init$6 = state.meta.blobs;
      var blobs_createFromFiles = init$6.createFromFiles;
      var blobs$1 = {
        createFromFiles: blobs_createFromFiles,
        fetch: $$fetch
      };
      var init$7 = state.meta;
      var meta_blob$1 = init$7.blob;
      var meta$2 = {
        blob: meta_blob$1,
        blobs: blobs$1
      };
      var uploadIds = typeof action$2 === "number" || action$2[0] !== 94326179 ? state.uploadIds : Belt_Array.reverse(Curry._1(Import.List.toArray, Curry._2(Import.List.map, action$2[1].blobs, (function (x) {
                        return x.id;
                      }))));
      var uploads$4 = typeof action$2 === "number" || action$2[0] !== 94326179 ? state.uploads : Curry._1(Import.List.reverse, Curry._3(Import.List.reduce, action$2[1].blobs, state.uploads, (function (uploads, blob) {
                    return Curry._4(Import.List.setAssoc, uploads, blob.id, {
                                id: blob.id,
                                originalName: Blob$SolvuuApi.source(blob.state),
                                file: undefined,
                                meta: {
                                  totalBytes: undefined,
                                  uploadedBytes: 0,
                                  isUploading: true,
                                  isUploaded: false,
                                  uploadError: false
                                }
                              }, $$String.equal);
                  })));
      return {
              uploadIds: uploadIds,
              uploads: uploads$4,
              meta: meta$2
            };
    }
  }
}

function fetchBlobs(apiClient) {
  return Import.$great$great$pipe(Curry._3(apiClient.f, /* GET */3546230, Blob$SolvuuApi.Get_all, /* () */0), (function (response) {
                if (typeof response === "number") {
                  return /* `Error */[
                          106380200,
                          /* Unauthorized */324098644
                        ];
                } else {
                  var info = response[1].info;
                  var variant = info[0];
                  if (variant !== -99004555) {
                    if (variant >= 17692) {
                      return /* `Ok */[
                              17724,
                              info[1]
                            ];
                    } else {
                      return /* `Error */[
                              106380200,
                              /* Not_found */-358247754
                            ];
                    }
                  } else {
                    return /* `Error */[
                            106380200,
                            /* Bad_request */-99004555
                          ];
                  }
                }
              }));
}

function fetchBlobsWithDispatch(dispatch, apiClient) {
  var start = function (x) {
    return Curry._1(Upload0.Operations.Blobs.Fetch.start, x);
  };
  var success = function (x) {
    return Curry._1(Upload0.Operations.Blobs.Fetch.success, x);
  };
  var failure = function (x) {
    return Curry._1(Upload0.Operations.Blobs.Fetch.failure, x);
  };
  Curry._1(dispatch, start(/* () */0));
  return Import.$great$great$pipe(fetchBlobs(apiClient), (function (response) {
                if (response[0] >= 106380200) {
                  return Curry._1(dispatch, failure(/* array */[response[1]]));
                } else {
                  return Curry._1(dispatch, success({
                                  blobs: response[1],
                                  timestamp: Date.now()
                                }));
                }
              }));
}

function startUploadWithDispatch(thisId, file, dispatch, apiClient) {
  var service = S3BlobUpload.make(thisId, file, apiClient);
  var result = {
    contents: false
  };
  var onStart = function (param) {
    result.contents = true;
    return Curry._1(dispatch, /* `Blob */[
                737307005,
                /* `Upload */[
                  -1058128351,
                  /* `Start */[
                    389604418,
                    {
                      id: thisId,
                      file: file
                    }
                  ]
                ]
              ]);
  };
  var onProgress = function ($$event) {
    return Curry._1(dispatch, /* `Blob */[
                737307005,
                /* `Upload */[
                  -1058128351,
                  /* `Progress */[
                    -539184179,
                    {
                      id: thisId,
                      uploadedBytes: $$event.loaded,
                      totalBytes: $$event.total
                    }
                  ]
                ]
              ]);
  };
  var onSuccess = function (param) {
    return Curry._1(dispatch, /* `Blob */[
                737307005,
                /* `Upload */[
                  -1058128351,
                  /* `Success */[
                    94326179,
                    {
                      id: thisId
                    }
                  ]
                ]
              ]);
  };
  var onFailure = function (param) {
    return Curry._1(dispatch, /* `Blob */[
                737307005,
                /* `Upload */[
                  -1058128351,
                  /* `Failure */[
                    -464807894,
                    {
                      id: thisId
                    }
                  ]
                ]
              ]);
  };
  return Import.$great$great$pipe(S3BlobUpload.send(service, onStart, onProgress, onSuccess, onFailure), (function (param) {
                return result.contents;
              }));
}

function createBlob(src, sizeBytes, apiClient) {
  var source = src;
  var size = Belt_Option.map(sizeBytes, Caml_int64.of_float);
  var info = {
    source: source,
    size: size,
    checksum: undefined
  };
  var $$let = Blob$SolvuuApi.Create.$$Request;
  var $$let$1 = Blob$SolvuuApi.Create.$$Response;
  return Curry._3(apiClient.f, /* POST */891112544, {
              entity: Blob$SolvuuApi.Create.entity,
              service: Blob$SolvuuApi.Create.service,
              $$Request: {
                path: $$let.path,
                body: $$let.body,
                info: $$let.info
              },
              $$Response: {
                ok_of_json: $$let$1.ok_of_json,
                json_of_ok: $$let$1.json_of_ok,
                bad_request_of_json: $$let$1.bad_request_of_json,
                json_of_bad_request: $$let$1.json_of_bad_request,
                not_found_of_json: $$let$1.not_found_of_json,
                json_of_not_found: $$let$1.json_of_not_found
              }
            }, info);
}

function createBlobFromURI(uri, apiClient) {
  return createBlob(uri, undefined, apiClient);
}

function createBlobFromFile(file, apiClient) {
  var src = "file:///" + file.name;
  var sizeBytes = file.size;
  return createBlob(src, sizeBytes, apiClient);
}

function uploadBlobsFromFilesWithDispatch(files, dispatch, apiClient) {
  var start = function (x) {
    return Curry._1(Upload0.Operations.Blobs.CreateFromFiles.start, x);
  };
  var success = function (x) {
    return Curry._1(Upload0.Operations.Blobs.CreateFromFiles.success, x);
  };
  var failure = function (x) {
    return Curry._1(Upload0.Operations.Blobs.CreateFromFiles.failure, x);
  };
  Curry._1(dispatch, start(/* () */0));
  var responses = Promise.all(Belt_Array.map(files, (function (file) {
              return Import.$great$great$pipe(createBlobFromFile(file, apiClient), (function (response) {
                            return /* tuple */[
                                    response,
                                    file
                                  ];
                          }));
            })));
  return Import.$great$great$eq(responses, (function (responses) {
                var match = Belt_Array.partition(responses, (function (param) {
                        var response = param[0];
                        if (typeof response === "number") {
                          return false;
                        } else {
                          var info = response[1].info;
                          if (typeof info === "number") {
                            return false;
                          } else {
                            return info[0] === 17692;
                          }
                        }
                      }));
                var failed = match[1];
                var successful = match[0];
                return Import.$great$great$eq(fetchBlobsWithDispatch(dispatch, apiClient), (function (param) {
                              if (failed.length === 0) {
                                Curry._1(dispatch, success(/* () */0));
                              } else {
                                var fileNames = Belt_Array.map(failed, (function (param) {
                                        return param[1].name;
                                      }));
                                var joinedFileNames = fileNames.join(", ");
                                var message = "The following files failed to upload: " + (String(joinedFileNames) + "");
                                Curry._1(dispatch, failure(/* array */[{
                                            message: message,
                                            fileNames: fileNames
                                          }]));
                              }
                              return Promise.all(Belt_Array.map(successful, (function (param) {
                                                var response = param[0];
                                                if (typeof response === "number") {
                                                  throw [
                                                        Caml_builtin_exceptions.assert_failure,
                                                        /* tuple */[
                                                          "Drive.re",
                                                          372,
                                                          33
                                                        ]
                                                      ];
                                                }
                                                var info = response[1].info;
                                                if (typeof info === "number") {
                                                  throw [
                                                        Caml_builtin_exceptions.assert_failure,
                                                        /* tuple */[
                                                          "Drive.re",
                                                          381,
                                                          23
                                                        ]
                                                      ];
                                                }
                                                if (info[0] !== 17692) {
                                                  throw [
                                                        Caml_builtin_exceptions.assert_failure,
                                                        /* tuple */[
                                                          "Drive.re",
                                                          381,
                                                          23
                                                        ]
                                                      ];
                                                }
                                                return startUploadWithDispatch(info[1].id, param[1], dispatch, apiClient);
                                              })));
                            }));
              }));
}

function createBlobFromURIWithDispatch(uri, dispatch, apiClient) {
  var start = function (x) {
    return Curry._1(Upload0.Operations.$$Blob.CreateFromURI.start, x);
  };
  var success = function (x) {
    return Curry._1(Upload0.Operations.$$Blob.CreateFromURI.success, x);
  };
  var failure = function (x) {
    return Curry._1(Upload0.Operations.$$Blob.CreateFromURI.failure, x);
  };
  Curry._1(dispatch, start(/* () */0));
  return Import.$great$great$eq(createBlob(uri, undefined, apiClient), (function (response) {
                var result;
                if (typeof response === "number") {
                  result = /* `Error */[
                    106380200,
                    /* () */0
                  ];
                } else {
                  var info = response[1].info;
                  result = info[0] >= 17692 ? /* `Ok */[
                      17724,
                      info[1]
                    ] : /* `Error */[
                      106380200,
                      /* () */0
                    ];
                }
                if (result[0] >= 106380200) {
                  Curry._1(dispatch, failure(/* array */["Could not add the URI"]));
                  return Import.$$return(/* () */0);
                } else {
                  return Import.$great$great$pipe(fetchBlobsWithDispatch(dispatch, apiClient), (function (param) {
                                return Curry._1(dispatch, success(/* () */0));
                              }));
                }
              }));
}

function Drive(Props) {
  var apiClient = Props.apiClient;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var fetchBlobs = function (param) {
    fetchBlobsWithDispatch(dispatch, apiClient);
    return /* () */0;
  };
  var uploadBlobsFromFiles = function (array) {
    uploadBlobsFromFilesWithDispatch(array, dispatch, apiClient);
    return /* () */0;
  };
  var createBlobFromURI = function (uri) {
    createBlobFromURIWithDispatch(uri, dispatch, apiClient);
    return /* () */0;
  };
  var uploadIds = state.uploadIds;
  var uploads = state.uploads;
  var meta_blobsFetch = state.meta.blobs.fetch;
  var meta_blobsCreateFromFiles = state.meta.blobs.createFromFiles;
  var meta_blobCreateFromURI = state.meta.blob.createFromURI;
  var meta = {
    blobsFetch: meta_blobsFetch,
    blobsCreateFromFiles: meta_blobsCreateFromFiles,
    blobCreateFromURI: meta_blobCreateFromURI
  };
  return React.createElement(Upload.make, {
              fetchBlobs: fetchBlobs,
              uploadBlobsFromFiles: uploadBlobsFromFiles,
              createBlobFromURI: createBlobFromURI,
              uploadIds: uploadIds,
              uploads: uploads,
              meta: meta,
              apiClient: apiClient
            });
}

var make = Drive;

export {
  Action ,
  State ,
  reducer ,
  fetchBlobs ,
  fetchBlobsWithDispatch ,
  startUploadWithDispatch ,
  createBlob ,
  createBlobFromURI ,
  createBlobFromFile ,
  uploadBlobsFromFilesWithDispatch ,
  createBlobFromURIWithDispatch ,
  make ,
  
}
/* react Not a pure module */
