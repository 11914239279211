import { combineReducers } from "redux";
import { assocPath, keys, filter, fromPairs } from "ramda";

import {
  createOperation,
  createReducer,
  resettableReducer
} from "features/utils";
import { reconcileEntity, entityByPathSelector } from "features/entities";
import {
  getDownloadURL,
  blobViewByIdSelector
} from "features/entities/blobViews";
import { formatOfTerm } from "lib/solvuu/formatUtils";
import { blobIdOfTerm } from "lib/solvuu/termUtils";

export const submitOperation = createOperation("GENOME_BROWSER/SUBMIT");
const GENOME_BROWSER_VIEW_INIT = "GENOME_BROWSER/VIEW_INIT";
const GENOME_BROWSER_VIEW_TEARDOWN = "GENOME_BROWSER/VIEW_TEARDOWN";
const GENOME_BROWSER_FORM_CHANGE = "GENOME_BROWSER/FORM_CHANGE";

// ACTIONS

export function genomeBrowserViewInit(reference, tracks) {
  return {
    type: GENOME_BROWSER_VIEW_INIT,
    payload: { reference, tracks }
  };
}

export function genomeBrowserViewTeardown() {
  return { type: GENOME_BROWSER_VIEW_TEARDOWN };
}

export function genomeBrowserFormChange(field, value) {
  return {
    type: GENOME_BROWSER_FORM_CHANGE,
    payload: { field, value }
  };
}

export function genomeBrowserFormSubmit() {
  const { start, success, failure } = submitOperation.actionCreators;

  return async function(dispatch, getState) {
    const { form } = genomeBrowserSelector(getState());
    dispatch(start());

    try {
      // Assemble the paths to required blobs
      const blobPaths = [
        form.reference.path,
        form.reference.indexPath,
        ...form.tracks.reduce(
          (result, track) => [...result, track.path, track.indexPath],
          []
        )
      ].filter(path => !!path);

      // Fetch blob IDs at required paths
      await Promise.all(blobPaths.map(path => dispatch(reconcileEntity(path))));

      // Lookup blob IDs by path
      const blobIdByPath = fromPairs(
        blobPaths.map(path => {
          const blobId = getBlobId(path);
          return [path, blobId];
        })
      );

      // Ensure all blob IDs are fetched
      const missingBlobPaths = keys(filter(blobId => !blobId, blobIdByPath));
      if (missingBlobPaths.length > 0) {
        const paths = missingBlobPaths.join(", ");
        const message = `Following files were not resolved correctly: ${paths}`;
        throw new Error(message);
      }

      // Generate S3 URLs for the blobs
      await Promise.all(
        blobPaths.map(path => dispatch(getDownloadURL(blobIdByPath[path])))
      );

      function getPresignedURL(path) {
        const blobId = blobIdByPath[path];
        return blobViewByIdSelector(getState(), blobId).downloadURL;
      }

      function getBlobId(path) {
        const entity = entityByPathSelector(getState(), path);
        return entity && blobIdOfTerm(entity.term);
      }

      function getFormat(path) {
        const entity = entityByPathSelector(getState(), path);
        return entity && formatOfTerm(entity.term);
      }

      function generateReference(reference) {
        return {
          url: getPresignedURL(reference.path),
          indexURL: getPresignedURL(reference.indexPath)
        };
      }

      function generateTrack(track) {
        return {
          url: getPresignedURL(track.path),
          indexURL: getPresignedURL(track.indexPath),
          format: getFormat(track.path),
          name: track.name
        };
      }

      // Assemble the track and reference with presigned URLS
      const reference = generateReference(form.reference);
      const tracks = form.tracks.map(generateTrack);
      dispatch(success({ reference, tracks }));
    } catch (e) {
      dispatch(failure({ message: e.message }));
    }
  };
}

// INITIAL STATE

export const initialState = {
  data: {},
  form: {
    reference: {
      path: "",
      indexPath: ""
    },
    tracks: []
  },
  meta: {
    submit: submitOperation.initialState
  }
};

// SELECTORS

export function genomeBrowserSelector(state) {
  return state.genomeBrowser;
}

// STATE TRANSFORMATIONS

function initFormState(state, action) {
  return { ...state, ...action.payload };
}

function setFormFieldFromAction(state, action) {
  const { field, value } = action.payload;
  const path = Array.isArray(field) ? field : field.split(".");

  return assocPath(path, value, state);
}

function setDataFromAction(state, action) {
  return { ...state, ...action.payload };
}

// REDUCERS

const dataReducer = createReducer(initialState.data, {
  [submitOperation.actionTypes.SUCCESS]: setDataFromAction
});

const formReducer = createReducer(initialState.form, {
  [GENOME_BROWSER_FORM_CHANGE]: setFormFieldFromAction,
  [GENOME_BROWSER_VIEW_INIT]: initFormState
});

const resettable = resettableReducer(GENOME_BROWSER_VIEW_TEARDOWN);

export default resettable(
  combineReducers({
    data: dataReducer,
    form: formReducer,
    meta: combineReducers({
      submit: submitOperation.reducer
    })
  })
);
