

import * as JobsFiltersDropdownJs from "./JobsFiltersDropdown.js";

var make = JobsFiltersDropdownJs.default;

export {
  make ,
  
}
/* make Not a pure module */
