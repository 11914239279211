

import * as Err from "../../../components/Err.bs.js";
import * as Card from "../../../components/Card/Card.bs.js";
import * as React from "react";
import * as Loading from "../../../components/Loading.bs.js";
import * as Preview from "./Preview/Preview.bs.js";
import * as BlobHooks from "./BlobHooks.bs.js";
import * as BlobUtils from "../../../lib/solvuu/blobUtils.bs.js";
import * as ReactIntl from "react-intl";
import * as VerticalLayout from "../../../components/Layout/VerticalLayout.bs.js";
import * as Layout from "components/Layout";
import * as ErrBoundaryJs from "components/ErrBoundary.js";

function SolvuuBlob$UploadRow(Props) {
  var id = Props.id;
  return "<UploadRow blobId={" + (String(id) + "}/>");
}

var UploadRow = {
  make: SolvuuBlob$UploadRow
};

function SolvuuBlob$BlobVisualization(Props) {
  var blobId = Props.blobId;
  var apiClient = Props.apiClient;
  return React.createElement(Card.make, {
              header: React.createElement(Card.Header.make, {
                    children: React.createElement("strong", undefined, "Preview")
                  }),
              children: React.createElement(Preview.make, {
                    blobId: blobId,
                    apiClient: apiClient
                  })
            });
}

var BlobVisualization = {
  make: SolvuuBlob$BlobVisualization
};

function SolvuuBlob(Props) {
  var blobId = Props.blobId;
  var apiClient = Props.apiClient;
  var blob = BlobHooks.useBlob(blobId, undefined, apiClient);
  if (typeof blob === "number") {
    return React.createElement(Loading.make, { });
  } else if (blob[0] >= 737307005) {
    var ok = blob[1];
    var upload = null;
    var match = BlobUtils.Re.downloadable(ok.blob);
    var preview = match ? React.createElement(SolvuuBlob$BlobVisualization, {
            blobId: blobId,
            apiClient: apiClient
          }) : null;
    var renderError = function (param) {
      return React.createElement(Err.Alert.make, {
                  title: React.createElement(ReactIntl.FormattedMessage, {
                        id: "entities.blob.visualization.crashed"
                      })
                });
    };
    return React.createElement(Layout.VerticalLayout, VerticalLayout.makeProps(/* x3 */26811, null, /* () */0), React.createElement(Layout.VerticalLayoutItem, {
                    children: upload
                  }), React.createElement(Layout.VerticalLayoutItem, {
                    children: React.createElement(ErrBoundaryJs.default, {
                          children: preview,
                          renderError: renderError
                        })
                  }));
  } else {
    return React.createElement(Err.Operation.make, {
                message: blob[1]
              });
  }
}

var make = SolvuuBlob;

export {
  UploadRow ,
  BlobVisualization ,
  make ,
  
}
/* Err Not a pure module */
