

import * as React from "react";
import * as ReactIntl from "react-intl";
import * as StyleCss from "./style.css";

var styles = StyleCss.default;

function PublicActionCard(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: styles.root
            }, children);
}

function PublicActionCard$Title(Props) {
  var id = Props.id;
  return React.createElement(ReactIntl.FormattedMessage, {
              id: id,
              children: (function (title) {
                  return React.createElement("h3", {
                              className: styles.title
                            }, title);
                })
            });
}

var Title = {
  make: PublicActionCard$Title
};

var make = PublicActionCard;

export {
  styles ,
  make ,
  Title ,
  
}
/* styles Not a pure module */
