

import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Import from "../../../features/import.bs.js";
import * as Select from "../../../components/Select/Select.bs.js";
import * as ReactExtras from "../../../lib/solvuu/ReactExtras.bs.js";
import * as ReactSelect from "../../../lib/ReactSelect.bs.js";

var images = /* :: */[
  /* `Docker */[
    -612329920,
    {
      name: "solvuu-server:dev",
      registry: "solvuu.com"
    }
  ],
  /* :: */[
    /* `Docker */[
      -612329920,
      {
        name: "bcftools:1.3.1",
        registry: "solvuu.com"
      }
    ],
    /* :: */[
      /* `Docker */[
        -612329920,
        {
          name: "breakseq-lite:1.0",
          registry: "solvuu.com"
        }
      ],
      /* :: */[
        /* `Docker */[
          -612329920,
          {
            name: "breakseq2:2.2",
            registry: "solvuu.com"
          }
        ],
        /* :: */[
          /* `Docker */[
            -612329920,
            {
              name: "bwa:0.7.10",
              registry: "solvuu.com"
            }
          ],
          /* :: */[
            /* `Docker */[
              -612329920,
              {
                name: "cnvnator:0.3.2",
                registry: "solvuu.com"
              }
            ],
            /* :: */[
              /* `Docker */[
                -612329920,
                {
                  name: "curator",
                  registry: "solvuu.com"
                }
              ],
              /* :: */[
                /* `Docker */[
                  -612329920,
                  {
                    name: "degust",
                    registry: "solvuu.com"
                  }
                ],
                /* :: */[
                  /* `Docker */[
                    -612329920,
                    {
                      name: "delly:0.7.2",
                      registry: "solvuu.com"
                    }
                  ],
                  /* :: */[
                    /* `Docker */[
                      -612329920,
                      {
                        name: "delly:0.7.5",
                        registry: "solvuu.com"
                      }
                    ],
                    /* :: */[
                      /* `Docker */[
                        -612329920,
                        {
                          name: "erds:1.1",
                          registry: "solvuu.com"
                        }
                      ],
                      /* :: */[
                        /* `Docker */[
                          -612329920,
                          {
                            name: "freec:1.0",
                            registry: "solvuu.com"
                          }
                        ],
                        /* :: */[
                          /* `Docker */[
                            -612329920,
                            {
                              name: "freec:2.0",
                              registry: "solvuu.com"
                            }
                          ],
                          /* :: */[
                            /* `Docker */[
                              -612329920,
                              {
                                name: "freec:9.1",
                                registry: "solvuu.com"
                              }
                            ],
                            /* :: */[
                              /* `Docker */[
                                -612329920,
                                {
                                  name: "gasv:2013.10.01",
                                  registry: "solvuu.com"
                                }
                              ],
                              /* :: */[
                                /* `Docker */[
                                  -612329920,
                                  {
                                    name: "gem:20130406",
                                    registry: "solvuu.com"
                                  }
                                ],
                                /* :: */[
                                  /* `Docker */[
                                    -612329920,
                                    {
                                      name: "picard:2.2.3",
                                      registry: "solvuu.com"
                                    }
                                  ],
                                  /* :: */[
                                    /* `Docker */[
                                      -612329920,
                                      {
                                        name: "pindel:0.2.5b8",
                                        registry: "solvuu.com"
                                      }
                                    ],
                                    /* :: */[
                                      /* `Docker */[
                                        -612329920,
                                        {
                                          name: "perl",
                                          registry: "index.docker.io"
                                        }
                                      ],
                                      /* :: */[
                                        /* `Docker */[
                                          -612329920,
                                          {
                                            name: "qiime:1.8.0",
                                            registry: "solvuu.com"
                                          }
                                        ],
                                        /* :: */[
                                          /* `Docker */[
                                            -612329920,
                                            {
                                              name: "qiime:2017.12.1",
                                              registry: "solvuu.com"
                                            }
                                          ],
                                          /* :: */[
                                            /* `Docker */[
                                              -612329920,
                                              {
                                                name: "rdxplorer:3.2",
                                                registry: "solvuu.com"
                                              }
                                            ],
                                            /* :: */[
                                              /* `Docker */[
                                                -612329920,
                                                {
                                                  name: "readdepth:16fa2e07df03951ef8e2234e0525374ff8ae19c5",
                                                  registry: "solvuu.com"
                                                }
                                              ],
                                              /* :: */[
                                                /* `Docker */[
                                                  -612329920,
                                                  {
                                                    name: "rsem:1.3.0",
                                                    registry: "solvuu.com"
                                                  }
                                                ],
                                                /* :: */[
                                                  /* `Docker */[
                                                    -612329920,
                                                    {
                                                      name: "samtools:1.3",
                                                      registry: "solvuu.com"
                                                    }
                                                  ],
                                                  /* :: */[
                                                    /* `Docker */[
                                                      -612329920,
                                                      {
                                                        name: "scalpel:0.5.3",
                                                        registry: "solvuu.com"
                                                      }
                                                    ],
                                                    /* :: */[
                                                      /* `Docker */[
                                                        -612329920,
                                                        {
                                                          name: "seqtk:1.2-r94",
                                                          registry: "solvuu.com"
                                                        }
                                                      ],
                                                      /* :: */[
                                                        /* `Docker */[
                                                          -612329920,
                                                          {
                                                            name: "soapindel:2.1",
                                                            registry: "solvuu.com"
                                                          }
                                                        ],
                                                        /* :: */[
                                                          /* `Docker */[
                                                            -612329920,
                                                            {
                                                              name: "trimmomatic:0.36",
                                                              registry: "solvuu.com"
                                                            }
                                                          ],
                                                          /* :: */[
                                                            /* `Docker */[
                                                              -612329920,
                                                              {
                                                                name: "vcftools:0.1.14",
                                                                registry: "solvuu.com"
                                                              }
                                                            ],
                                                            /* [] */0
                                                          ]
                                                        ]
                                                      ]
                                                    ]
                                                  ]
                                                ]
                                              ]
                                            ]
                                          ]
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]
  ]
];

var predefinedValues = Curry._2(Import.List.map, images, (function (x) {
        var match = x[1];
        return Curry._2(Import.sprintf(/* Format */[
                        /* String */Block.__(2, [
                            /* No_padding */0,
                            /* Char_literal */Block.__(12, [
                                /* "/" */47,
                                /* String */Block.__(2, [
                                    /* No_padding */0,
                                    /* End_of_format */0
                                  ])
                              ])
                          ]),
                        "%s/%s"
                      ]), match.registry, match.name);
      }));

function ImageSelect(Props) {
  var label = Props.label;
  var value = Props.value;
  var onChange = Props.onChange;
  var required = Props.required;
  var handleChange = function (value) {
    if (value !== undefined) {
      return Curry._1(onChange, value.value);
    } else {
      return /* () */0;
    }
  };
  var usesCreatedValue = value.length !== 0 && !Curry._3(Import.List.has, predefinedValues, value, (function (prim, prim$1) {
          return prim === prim$1;
        }));
  var selectValues = usesCreatedValue ? /* :: */[
      value,
      predefinedValues
    ] : predefinedValues;
  var selectOptions = Curry._2(Import.List.map, selectValues, (function (value) {
          return ReactSelect.$$Option.make(value, value);
        }));
  var label$1 = React.createElement(React.Fragment, undefined, label, required ? "*" : null);
  var promptTextCreator = function (label) {
    return "Use custom tool: " + label;
  };
  return React.createElement(Select.Creatable.make, {
              label: label$1,
              value: value,
              onChange: handleChange,
              options: selectOptions,
              promptTextCreator: promptTextCreator
            });
}

function mapProps(props) {
  var label = props.label;
  var value = props.value;
  var onChange = props.onChange;
  var required = props.required;
  return {
          label: label,
          value: value,
          onChange: onChange,
          required: required
        };
}

var $$default = ReactExtras.jsComponent("Jobs.Form.ImageSelect", ImageSelect, mapProps);

var make = ImageSelect;

export {
  images ,
  predefinedValues ,
  make ,
  mapProps ,
  $$default ,
  $$default as default,
  
}
/* predefinedValues Not a pure module */
