import React, { Component } from "react";
import PropTypes from "prop-types";
import sizeMe from "react-sizeme";
import parcoords from "parcoord-es";
import { select } from "d3-selection";
import { scaleLinear } from "d3-scale";
import { max } from "d3-array";
import { equals, fromPairs, last, maxBy, sort } from "ramda";

import { colorScale } from "./Heatmap.js";

import styles from "table/TableParallelCoordinates.css";

class ParallelCoordinates extends Component {
  static propTypes = {
    labels: PropTypes.arrayOf(PropTypes.string).isRequired,
    values: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
    size: PropTypes.object.isRequired
  };

  plotRef = React.createRef();

  drawPlot = () => {
    const { labels, values } = this.props;
    const node = this.plotRef.current;
    const selection = select(node);

    // Reset plot
    selection.selectAll("*").remove();

    const height = 300;
    const margin = { left: 50, right: 50, top: 30, bottom: 10 };
    const range = height - margin.top - margin.bottom;
    const absDomain = max(values, row => max(row, Math.abs));
    const domain = [-absDomain, absDomain];
    const yscale = scaleLinear()
      .domain(domain)
      .range([range, 1]);
    const color = row => {
      const representativeValue = last(sort(maxBy(Math.abs), row));
      return colorScale(representativeValue);
    };
    const dimensions = fromPairs(
      labels.map((label, idx) => [idx, { title: label, yscale }])
    );
    const brushMode = "1D-axes";
    const alphaOnBrushed = 0.05;

    parcoords()(node)
      .data(values)
      .height(height)
      .margin(margin)
      .dimensions(dimensions)
      .color(color)
      .alphaOnBrushed(alphaOnBrushed)
      .render()
      .createAxes()
      .reorderable()
      .brushMode(brushMode);
  };

  componentDidMount() {
    this.drawPlot();
  }

  componentDidUpdate(prevProps) {
    // Update whenever labels, values or size changes
    if (!equals(this.props, prevProps)) {
      this.drawPlot();
    }
  }

  render() {
    return (
      <div className={styles.plotAreaWrapper}>
        <div className={styles.xAxisLabel}>
          <span>z-score</span>
        </div>
        <div ref={this.plotRef} className={styles.plotArea} />
      </div>
    );
  }
}

const noPlaceholder = process.env.NODE_ENV === "test";
const refreshRate = 100;
export default sizeMe({ refreshRate, noPlaceholder })(ParallelCoordinates);
