

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Import from "../features/import.bs.js";
import * as Std$Bolt from "bolt/./std.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as ScatterPlot from "./ScatterPlot.bs.js";
import * as Root$SolvuuApi from "solvuu-api/./root.bs.js";
import * as Js_null_undefined from "bs-platform/lib/es6/js_null_undefined.js";
import * as ScatterMatrixJs from "./ScatterMatrix.js";

function ofRecord(record) {
  return Curry._1(Std$Bolt.Res.join, Curry._2(Root$SolvuuApi.Scatter_matrix.T.of_record, record, (function (scatters, axisLabels, pointLabels) {
                    return Curry._2(Std$Bolt.Res.map, Curry._1(Std$Bolt.Res.all, Curry._2(Import.List.map, scatters, (function (record) {
                                          return Curry._2(Std$Bolt.Res.map, ScatterPlot.Props.ofRecord(record), (function (props) {
                                                        var pearsonCorrelationCoefficient = props.pearsonCorrelationCoefficient;
                                                        return {
                                                                points: Belt_Array.map(props.points, (function (props) {
                                                                        return {
                                                                                x: props.x,
                                                                                y: props.y,
                                                                                label: Js_null_undefined.fromOption(props.label)
                                                                              };
                                                                      })),
                                                                xAxisName: props.xAxisName,
                                                                yAxisName: props.yAxisName,
                                                                pearsonCorrelationCoefficient: pearsonCorrelationCoefficient
                                                              };
                                                      }));
                                        }))), (function (scatters) {
                                  return {
                                          scatters: Curry._1(Import.List.toArray, scatters),
                                          axisLabels: Curry._1(Import.List.toArray, axisLabels),
                                          pointLabels: Curry._1(Import.List.toArray, pointLabels)
                                        };
                                }));
                  })));
}

function ofTerm(term) {
  if (typeof term === "number" || term[0] !== 847309489) {
    return /* `Error */[
            106380200,
            /* Expected_record */-756637544
          ];
  } else {
    var res = ofRecord(term[1]);
    return Curry._2(Std$Bolt.Res.map_error, res, (function (e) {
                  return /* `Record */[
                          847309489,
                          e
                        ];
                }));
  }
}

var Props = {
  ofRecord: ofRecord,
  ofTerm: ofTerm
};

var make = ScatterMatrixJs.default;

export {
  Props ,
  make ,
  
}
/* make Not a pure module */
