import React, { Component } from "react";
import PropTypes from "prop-types";
import { equals } from "ramda";
import { throttle } from "lodash";
import { getNiceTickValues } from "recharts-scale";
import { scaleLinear } from "d3-scale";
import { brushY, brushX, brushSelection } from "d3-brush";
import { select } from "d3-selection";

export default class Brushing extends Component {
  state = {};

  static propTypes = {
    domain: PropTypes.arrayOf(PropTypes.number).isRequired, // [begin, end]
    children: PropTypes.func.isRequired
  };

  static getDerivedStateFromProps(props, state) {
    // Initialize and reset state domain and selectedDomain
    // to props.domain whenever it changes
    if (!equals(props.domain, state.domain)) {
      return {
        domain: props.domain,
        selectedDomain: props.domain
      };
    }

    return null;
  }

  handleDomainSelection = domain => {
    this.setState({ selectedDomain: domain });
  };

  render() {
    const { children } = this.props;
    const { domain, selectedDomain } = this.state;
    const brushKey = domain.join(",");

    return children({
      onChange: this.handleDomainSelection,
      selectedDomain,
      brushKey
    });
  }
}

export class DualBrushing extends Component {
  static propTypes = {
    xDomain: PropTypes.arrayOf(PropTypes.number).isRequired,
    yDomain: PropTypes.arrayOf(PropTypes.number).isRequired,
    children: PropTypes.func.isRequired
  };

  render() {
    const { xDomain, yDomain, children } = this.props;

    return (
      <Brushing domain={xDomain}>
        {xBrushing => (
          <Brushing domain={yDomain}>
            {yBrushing => children({ xBrushing, yBrushing })}
          </Brushing>
        )}
      </Brushing>
    );
  }
}

export class Brush extends Component {
  static propTypes = {
    // primary extent is the dimension along which the brushing is performed
    primaryExtent: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    scale: PropTypes.func.isRequired,
    brushFactory: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    reverse: PropTypes.bool.isRequired,
    throttleMs: PropTypes.number
  };

  rootRef = React.createRef();

  initializeD3Brush = () => {
    const {
      width,
      height,
      primaryExtent,
      brushFactory,
      scale,
      onChange,
      reverse,
      throttleMs
    } = this.props;

    const d3Selection = select(this.rootRef.current);
    const throttledOnChange = throttleMs
      ? throttle(onChange, throttleMs)
      : onChange;

    // Y-axis is inverted in <svg> coordinates, so have to flip
    // the obtained extent before proceeding.
    function processExtent(extent) {
      return reverse ? extent.reverse() : extent;
    }

    const selectionRange = processExtent([0, primaryExtent]);
    const selectionScale = scale.copy().range(selectionRange);

    function handleBrushEvent() {
      const selectedExtent = selectedBrushExtent();
      const selectedDomain = selectedExtent.map(selectionScale.invert);
      throttledOnChange(selectedDomain);
    }

    function selectedBrushExtent() {
      return processExtent(brushSelection(d3Selection.node()));
    }

    // Visual attributes
    const handleBackground = "#fff";
    const handleOutline = "#333";
    const handleRadius = 3;
    const selectionBackground = "#44a1f5";
    const selectionOpacity = 0.5;
    const brushExtent = [[0, 0], [width, height]];

    const brush = brushFactory()
      .extent(brushExtent)
      .on("brush", handleBrushEvent);

    d3Selection.call(brush);

    d3Selection
      .selectAll(".handle")
      .attr("fill", handleBackground)
      .attr("stroke", handleOutline)
      .attr("rx", handleRadius)
      .attr("ry", handleRadius);
    d3Selection
      .selectAll(".selection")
      .attr("fill", selectionBackground)
      .attr("fill-opacity", selectionOpacity);

    // Instance methods, used to move the brush programmatically, e.g. in tests
    this.moveBrush = extent => brush.move(d3Selection, extent);
    this.currentBrush = selectedBrushExtent;
  };

  componentDidMount() {
    this.initializeD3Brush();
    // Move the brush selection to the full extent
    this.moveBrush([0, this.props.primaryExtent]);
  }

  componentDidUpdate(prevProps) {
    if (this.props.primaryExtent !== prevProps.primaryExtent) {
      const scale = scaleLinear()
        .domain([0, prevProps.primaryExtent])
        .range([0, this.props.primaryExtent]);
      const oldExtent = this.currentBrush();
      const newExtent = oldExtent.map(scale);
      this.initializeD3Brush();
      this.moveBrush(newExtent);
    }
  }

  render() {
    const { x, y, width, height } = this.props;
    const brushBackground = "#eee";

    // Add some padding to x, y to account for brush handles
    return (
      <g transform={`translate(${x + 3.5} ${y + 3.5})`}>
        <rect
          x={0}
          y={0}
          width={width}
          height={height}
          fill={brushBackground}
        />
        <g ref={this.rootRef} />
      </g>
    );
  }
}

export class VerticalBrush extends Component {
  static defaultWidth = 20;

  render() {
    return (
      <Brush
        brushFactory={brushY}
        primaryExtent={this.props.height}
        reverse={true}
        {...this.props}
      />
    );
  }
}

export class HorizontalBrush extends Component {
  static defaultHeight = 20;

  render() {
    return (
      <Brush
        brushFactory={brushX}
        primaryExtent={this.props.width}
        reverse={false}
        {...this.props}
      />
    );
  }
}

export function getNiceTicks(domain, ticksCount = 10) {
  return getNiceTickValues(domain, ticksCount, true).filter(
    tick => domain[0] <= tick && tick <= domain[1]
  );
}

export function getNiceDomain(domain) {
  return scaleLinear()
    .domain(domain)
    .nice()
    .domain();
}
