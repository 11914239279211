

import * as List from "bs-platform/lib/es6/list.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Import from "../../features/import.bs.js";
import * as Caml_obj from "bs-platform/lib/es6/caml_obj.js";
import * as ReactExtras from "../../lib/solvuu/ReactExtras.bs.js";
import * as FormControls from "../FormControls/FormControls.bs.js";

function VariantSelect(Props) {
  var variantsWithValues = Props.variantsWithValues;
  var variant = Props.variant;
  var onChange = Props.onChange;
  var name = Props.name;
  var label = Props.label;
  var selectOptions = ReactExtras.listToElement(Curry._2(Import.List.map, variantsWithValues, (function (param) {
              var value = param[1];
              return React.createElement("option", {
                          key: value,
                          value: value
                        }, value);
            })));
  var currentValue = List.find((function (param) {
            return Caml_obj.caml_equal(param[0], variant);
          }), variantsWithValues)[1];
  var handleChange = function ($$event) {
    var value = ReactExtras.eventTargetValue($$event);
    return Curry._1(onChange, List.find((function (param) {
                        return param[1] === value;
                      }), variantsWithValues)[0]);
  };
  return React.createElement(FormControls.Select.make, FormControls.Select.makeProps(label, name, currentValue, handleChange, undefined, selectOptions, /* () */0));
}

var make = VariantSelect;

export {
  make ,
  
}
/* react Not a pure module */
