import React, { Component } from "react";
import { pie, arc } from "d3-shape";
import { FormattedDate } from "react-intl";

import * as SidebarSection from "containers/Dashboard/SidebarSection/SidebarSection.bs";
import {
  HorizontalLayout,
  HorizontalLayoutItem,
  VerticalLayout,
  VerticalLayoutItem
} from "components/Layout";
import * as Button from "components/Button.bs";
import iconChevronDown from "icons/icon-chevron-down-black.svg";

import {
  colorSecondaryGreen,
  colorSecondaryBlue,
  colorSecondaryOrange
} from "variables.css";
import styles from "./style.css";
import * as Route from "Route.bs";

export default class Jobs extends Component {
  handlePieClick = () => {
    this.props.navigateToJobs();
  };

  render() {
    const chartWidth = 260;
    const chartHeight = chartWidth;
    const chartViewBox = `0 0 ${chartWidth} ${chartHeight}`;
    const colorPrimary = "#4fc3a5";
    const radius = chartWidth / 2;
    const outerRadius = radius - 16;
    const innerRadius = radius - 44;
    const centerX = chartWidth / 2;
    const centerY = chartHeight / 2;

    const pieData = [
      { value: 2, color: colorSecondaryBlue },
      { value: 5, color: colorSecondaryGreen },
      { value: 1, color: colorSecondaryOrange }
    ];
    const pies = pie()
      .sort(null)
      .value(pie => pie.value)
      .padAngle(0)(pieData);
    const arcGen = arc()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius);
    const pieParts = pies.map(pie => ({
      path: arcGen(pie),
      centroid: arcGen.centroid(pie),
      data: pie.data
    }));

    function getMonday(d) {
      const day = d.getDay();
      const diff = d.getDate() - day + (day === 0 ? -6 : 1);
      return new Date(d).setDate(diff);
    }
    function getSunday(d) {
      const monday = new Date(getMonday(d));
      const diff = monday.getDate() + 6;
      return monday.setDate(diff);
    }

    const totalValue = 945;

    return (
      <SidebarSection.make>
        <VerticalLayout gap="3x">
          <VerticalLayoutItem>
            <SidebarSection.SectionHeader.make>
              Jobs this week <img alt="" src={iconChevronDown} />
            </SidebarSection.SectionHeader.make>
          </VerticalLayoutItem>
          <VerticalLayoutItem>
            <HorizontalLayout gap="3x">
              <HorizontalLayoutItem>
                <button type="button" className={styles.buttonAll}>
                  All
                </button>
              </HorizontalLayoutItem>
              <HorizontalLayoutItem>
                <button type="button" className={styles.buttonRunning}>
                  Running
                </button>
              </HorizontalLayoutItem>
              <HorizontalLayoutItem>
                <button type="button" className={styles.buttonComplete}>
                  Complete
                </button>
              </HorizontalLayoutItem>
              <HorizontalLayoutItem>
                <button type="button" className={styles.buttonHolding}>
                  Holding
                </button>
              </HorizontalLayoutItem>
            </HorizontalLayout>
          </VerticalLayoutItem>
          <VerticalLayoutItem>
            <div className={styles.wrapper}>
              <svg
                width={chartWidth}
                height={chartHeight}
                viewBox={chartViewBox}
                className={styles.chart}
              >
                <circle
                  cx={centerX}
                  cy={centerY}
                  r={radius}
                  fill={colorPrimary}
                  opacity={0.3}
                />
                <circle cx={centerX} cy={centerY} r={innerRadius} fill="#fff" />
                <g
                  transform={`translate(${centerX} ${centerY})`}
                  cursor="pointer"
                >
                  {pieParts.map(({ path, data }) => (
                    <g
                      key={path}
                      cursor="pointer"
                      onClick={this.handlePieClick}
                    >
                      <path d={path} fill={data.color} />
                    </g>
                  ))}
                </g>
              </svg>
              <div className={styles.total}>
                <span className={styles.totalValue}>{totalValue}</span>
                <div className={styles.totalLabel}>Jobs This Week</div>
                <div className={styles.totalTimespan}>
                  <FormattedDate
                    value={getMonday(new Date())}
                    month="short"
                    day="numeric"
                  />
                  {" - "}
                  <FormattedDate
                    value={getSunday(new Date())}
                    month="short"
                    day="numeric"
                  />
                </div>
              </div>
            </div>
          </VerticalLayoutItem>
          <VerticalLayoutItem>
            <div className={styles.buttonWrapper}>
              <Button.Link.make
                to={Route.Js.jobs}
                variant={Button.Variant.text}
              >
                View All
              </Button.Link.make>
            </div>
          </VerticalLayoutItem>
        </VerticalLayout>
      </SidebarSection.make>
    );
  }
}
