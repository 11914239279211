

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Import from "../features/import.bs.js";
import * as Std$Bolt from "bolt/./std.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Lang$SolvuuApi from "solvuu-api/./lang.bs.js";
import * as Root$SolvuuApi from "solvuu-api/./root.bs.js";

function $$eval(f, t) {
  if (typeof t === "number" || t[0] !== 847309489) {
    return Promise.resolve(Curry._2(Lang$SolvuuApi.Term.eval_error, "Expected record", t));
  } else {
    var result = Curry._2(Root$SolvuuApi.Krona_chart.Arg.of_record, t[1], (function (levelColumnNames, valueColumnName, data) {
            if (valueColumnName !== undefined) {
              var valueColumnName$1 = valueColumnName;
              var values = Curry._3(Lang$SolvuuApi.Table.project_column, data, /* `Named */[
                    699531161,
                    valueColumnName$1
                  ], Curry._1(Lang$SolvuuApi.Record.Param.Arg_type.create, (function (param) {
                          if (typeof param === "number") {
                            return /* `Ok */[
                                    17724,
                                    undefined
                                  ];
                          } else {
                            var variant = param[0];
                            if (variant !== 3654863) {
                              if (variant !== 925978388) {
                                return /* `Ok */[
                                        17724,
                                        undefined
                                      ];
                              } else {
                                var match = param[1];
                                if (typeof match === "number" || match[0] !== 3654863) {
                                  return /* `Ok */[
                                          17724,
                                          undefined
                                        ];
                                } else {
                                  return /* `Ok */[
                                          17724,
                                          match[1]
                                        ];
                                }
                              }
                            } else {
                              return /* `Ok */[
                                      17724,
                                      param[1]
                                    ];
                            }
                          }
                        })));
              if (values[0] >= 106380200) {
                return /* `Ok */[
                        17724,
                        Curry._2(Lang$SolvuuApi.Term.eval_error, "Unable to find column with name: " + valueColumnName$1, data)
                      ];
              } else if (typeof data === "number" || data[0] !== 848054398) {
                return /* `Ok */[
                        17724,
                        Curry._2(Lang$SolvuuApi.Term.eval_error, "Expected list", data)
                      ];
              } else {
                var labels = Curry._1(Std$Bolt.Res.all, Curry._2(Import.List.map, data[1], (function (row) {
                            if (typeof row === "number" || row[0] !== 847309489) {
                              return /* `Error */[
                                      106380200,
                                      /* `ExpectedRecord */[
                                        980211529,
                                        row
                                      ]
                                    ];
                            } else {
                              var row$1 = row[1];
                              return /* `Ok */[
                                      17724,
                                      Curry._2(Import.List.keepMap, levelColumnNames, (function (name) {
                                              return Belt_Option.flatMap(Curry._2(Lang$SolvuuApi.Record.find, row$1, /* `Named */[
                                                              699531161,
                                                              name
                                                            ]), (function (param) {
                                                            if (typeof param === "number") {
                                                              return ;
                                                            } else {
                                                              var variant = param[0];
                                                              if (variant !== -976970511) {
                                                                if (variant !== 925978388) {
                                                                  return ;
                                                                } else {
                                                                  var match = param[1];
                                                                  if (typeof match === "number" || match[0] !== -976970511) {
                                                                    return ;
                                                                  } else {
                                                                    return match[1];
                                                                  }
                                                                }
                                                              } else {
                                                                return param[1];
                                                              }
                                                            }
                                                          }));
                                            }))
                                    ];
                            }
                          })));
                if (labels[0] >= 106380200) {
                  return /* `Ok */[
                          17724,
                          Curry._2(Lang$SolvuuApi.Term.eval_error, "Expected record", labels[1][1])
                        ];
                } else {
                  return /* `Ok */[
                          17724,
                          /* `Viz */[
                            4300231,
                            /* `Record */[
                              847309489,
                              Curry._2(Lang$SolvuuApi.Record.tagged, f, /* `Record */[
                                    847309489,
                                    /* :: */[
                                      /* tuple */[
                                        Root$SolvuuApi.Krona_chart.T.Const.Label.data,
                                        /* `List */[
                                          848054398,
                                          Curry._2(Import.List.keepMap, Curry._2(Import.List.zip, values[1], labels[1]), (function (param) {
                                                  var labels = param[1];
                                                  return Belt_Option.map(param[0], (function (value) {
                                                                return /* `Record */[
                                                                        847309489,
                                                                        Curry._2(Root$SolvuuApi.Krona_chart.T.Flat.to_record, value, labels)
                                                                      ];
                                                              }));
                                                }))
                                        ]
                                      ],
                                      /* [] */0
                                    ]
                                  ])
                            ]
                          ]
                        ];
                }
              }
            } else {
              return /* `Error */[
                      106380200,
                      /* :: */[
                        /* tuple */[
                          Root$SolvuuApi.Krona_chart.Arg.Const.Label.valueColumnName,
                          /* Invalid */144245463
                        ],
                        /* [] */0
                      ]
                    ];
            }
          }));
    var match = Curry._1(Std$Bolt.Res.join, result);
    if (match[0] >= 106380200) {
      var message = Curry._1(Import.List.toArray, Curry._2(Import.List.map, match[1], (function (param) {
                    var label = Curry._1(Lang$SolvuuApi.Label.to_string, param[0]);
                    var reason = param[1] >= 144245463 ? "invalid" : "not_found";
                    return "(" + (label + (", " + (reason + ") ")));
                  }))).join(" ");
      return Promise.resolve(Curry._2(Lang$SolvuuApi.Term.eval_error, message, t));
    } else {
      return Promise.resolve(match[1]);
    }
  }
}

export {
  $$eval ,
  
}
/* Import Not a pure module */
