

import * as FormGroupJs from "./FormGroup.js";

function renderCheckbox(prim) {
  return FormGroupJs.renderCheckbox(prim);
}

var make = FormGroupJs.FormGroupElement;

var $$Element = {
  make: make
};

var make$1 = FormGroupJs.FieldInfoElement;

var FieldInfoElement = {
  make: make$1
};

var make$2 = FormGroupJs.ControlledFormGroup;

var ControlledFormGroup = {
  make: make$2
};

var make$3 = FormGroupJs.default;

export {
  renderCheckbox ,
  $$Element ,
  FieldInfoElement ,
  ControlledFormGroup ,
  make$3 as make,
  
}
/* make Not a pure module */
