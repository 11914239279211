


function fst(pair) {
  return (pair[0]);
}

function snd(pair) {
  return (pair[1]);
}

function toTuple(x) {
  return /* tuple */[
          fst(x),
          snd(x)
        ];
}

var Wtf = {
  fst: fst,
  snd: snd,
  toTuple: toTuple
};

export {
  Wtf ,
  
}
/* No side effect */
