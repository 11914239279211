import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { FormattedMessage } from "react-intl";

import TreeArea from "./TreeArea";
import styles from "./style.css";

class PhylogeneticTree extends Component {
  static propTypes = {
    getDataForChart: PropTypes.func.isRequired
  };

  state = {
    circular: false
  };

  handleLayoutChange = circular => event => {
    this.setState({ circular });
  };

  render() {
    const { getDataForChart } = this.props;
    const { circular } = this.state;
    const { tree } = getDataForChart();

    return (
      <div className={styles.root} data-testid="phylogenetic-analysis">
        <div className={styles.controls}>
          <FormattedMessage id="phylogeneticTree.layout" tagName="label" />
          <div>
            <button
              onClick={this.handleLayoutChange(false)}
              className={cx(styles.button, {
                [styles.active]: !circular
              })}
              data-testid="layout-rectangular"
            >
              <FormattedMessage id="phylogeneticTree.layout.rectangular" />
            </button>
            <button
              onClick={this.handleLayoutChange(true)}
              className={cx(styles.button, {
                [styles.active]: circular
              })}
              data-testid="layout-circular"
            >
              <FormattedMessage id="phylogeneticTree.layout.circular" />
            </button>
          </div>
        </div>
        <TreeArea tree={tree} circular={circular} />
      </div>
    );
  }
}

export default PhylogeneticTree;
