

import * as React from "react";
import * as StyleCss from "./style.css";

var styles = StyleCss.default;

function FormLayout$VerticalFormFields(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: styles.verticalFields
            }, children);
}

var VerticalFormFields = {
  make: FormLayout$VerticalFormFields
};

function FormLayout$VerticalFormField(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: styles.verticalField
            }, children);
}

var VerticalFormField = {
  make: FormLayout$VerticalFormField
};

function FormLayout$VerticalFormActions(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: styles.verticalActions
            }, children);
}

var VerticalFormActions = {
  make: FormLayout$VerticalFormActions
};

export {
  styles ,
  VerticalFormFields ,
  VerticalFormField ,
  VerticalFormActions ,
  
}
/* styles Not a pure module */
