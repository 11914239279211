

import * as TermJs from "../lib/solvuu/termJs.bs.js";

function makeProps(term, param) {
  return {
          term: TermJs.convertToJs(term)
        };
}

export {
  makeProps ,
  
}
/* TermJs Not a pure module */
