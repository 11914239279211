

import * as React from "react";
import * as Locale from "./Locale.bs.js";
import * as ReactIntl from "./lib/ReactIntl.bs.js";


if (!Intl.PluralRules) {
  require("@formatjs/intl-pluralrules/polyfill");
  require("@formatjs/intl-pluralrules/dist/locale-data/en");
}
if (!Intl.RelativeTimeFormat) {
  require("@formatjs/intl-relativetimeformat/polyfill");
  require("@formatjs/intl-relativetimeformat/dist/locale-data/en");
}

;

var locale = "en";

var cache = ReactIntl.createIntlCache(/* () */0);

function createIntl(param) {
  return ReactIntl.createIntl({
              locale: locale,
              messages: Locale.en
            }, cache);
}

function $$Intl(Props) {
  var children = Props.children;
  return React.createElement(ReactIntl.IntlProvider.make, {
              locale: locale,
              messages: Locale.en,
              textComponent: "span",
              children: children
            });
}

var make = $$Intl;

export {
  locale ,
  cache ,
  createIntl ,
  make ,
  
}
/*  Not a pure module */
