/* This file contains multiple optimizations for Recharts' BarChart implementation
 * to support the use-case of Taxonomic Analysis' bar chart (thousands of stacked bars).
 *
 * Further optimizations to recharts' internal functions in src/overrides/RechartsReactUtils.js
 */
import React, { Component } from "react";
import { BarChart, Bar } from "recharts";
import { filterEventsOfChild } from "recharts/lib/util/ReactUtils";
import {
  getCateCoordinateOfBar,
  truncateByDomain,
  findPositionOfBar
} from "recharts/lib/util/ChartUtils";
import { equals } from "ramda";

const getWidth = e => e.width;

class Rectangles extends Component {
  shouldComponentUpdate(nextProps) {
    return this.props.data !== nextProps.data;
  }

  renderRectanglesStatically(data) {
    const { shape } = this.props;

    return data.map((entry, i) => {
      const eventProps = filterEventsOfChild(this.props, entry, i);
      const props = {
        width: entry.width,
        height: entry.height,
        x: entry.x,
        y: entry.y,
        fill: this.props.fill,
        fillOpacity: this.props.fillOpacity,
        strokeWidth: this.props.strokeWidth,
        stroke: this.props.stroke,
        key: i,
        ...eventProps
      };
      return shape(props);
    });
  }

  /* PERFORMANCE: avoid rendering of unnecessary <g> elements.
   */
  render() {
    return (
      <g className="recharts-bar-rectangles">
        {this.renderRectanglesStatically(this.props.data)}
      </g>
    );
  }
}

export class OptimizedBar extends Bar {
  static propTypes = null;

  /* PERFORMANCE: reimplement Bar.getComposedData to discard items with value equal to 0.
   * (they wouldn't be visible anyway)
   */
  static getComposedData = ({
    props,
    item,
    barPosition,
    bandSize,
    xAxis,
    yAxis,
    xAxisTicks,
    yAxisTicks,
    stackedData,
    dataStartIndex,
    displayedData,
    offset
  }) => {
    const pos = findPositionOfBar(barPosition, item);
    if (!pos) {
      return [];
    }

    const { layout } = props;
    const numericAxis = layout === "horizontal" ? yAxis : xAxis;
    const stackedDomain = stackedData ? numericAxis.scale.domain() : null;

    const rects = displayedData.map((entry, index) => {
      let value, x, y, width, height, background;

      value = truncateByDomain(
        stackedData[dataStartIndex + index],
        stackedDomain
      );

      if (value[0] === value[1]) return null; // OPTIMIZATION

      x = xAxis.scale(value[0]);
      y = getCateCoordinateOfBar({
        axis: yAxis,
        ticks: yAxisTicks,
        bandSize,
        offset: pos.offset,
        entry,
        index
      });
      width = xAxis.scale(value[1]) - xAxis.scale(value[0]);
      height = pos.size;
      background = { x: xAxis.x, y, width: xAxis.width, height };

      return {
        x,
        y,
        width,
        height,
        value: stackedData ? value : value[1],
        payload: entry,
        background
      };
    });

    const data = rects.filter(rect => rect);

    return { data, layout, ...offset };
  };

  /* PERFORMANCE: only re-render bars when their width or highlighted/muted status changes.
   * This speeds up rerenders when hovering over different bars
   */
  shouldComponentUpdate(nextProps, nextState) {
    const should = Bar.prototype.shouldComponentUpdate.call(
      this,
      nextProps,
      nextState
    );
    if (!should) return false;
    if (this.props.highlighted !== nextProps.highlighted) return true;
    if (this.props.muted !== nextProps.muted) return true;

    return !equals(this.props.data.map(getWidth), nextProps.data.map(getWidth));
  }

  /* PERFORMANCE: avoid rendering of unnecessary <g> elements.
   */
  render() {
    const { highlighted, muted, ...rectangleProps } = this.props;

    return (
      <g
        className="recharts-bar"
        data-is-highlighted={highlighted}
        data-is-muted={muted}
      >
        <Rectangles {...rectangleProps} />
      </g>
    );
  }
}

export class OptimizedBarChart extends BarChart {
  /* HACK: bypass expensive recharts calculations when data hasn't changed
   * This avoids calling of updateStateOfAxisMapsOffsetAndStackGroups
   * in https://github.com/recharts/recharts/blob/master/src/chart/generateCategoricalChart.js#L197
   */
  componentWillReceiveProps(nextProps) {
    if (this.props.data === nextProps.data) {
      return;
    }
    return BarChart.prototype.componentWillReceiveProps.call(this, nextProps);
  }
}
