

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as FormatJS from "../../lib/FormatJS.bs.js";
import * as ReactIntl from "../../lib/ReactIntl.bs.js";
import * as ReactIntl$1 from "react-intl";
import * as StyleCss from "./style.css";
import * as Time$SolvuuClient from "solvuu-client/./time.bs.js";

var styles = StyleCss.default;

function FormattedRelative(Props) {
  var value = Props.value;
  var match = Props.updateInterval;
  var updateInterval = match !== undefined ? match : /* `Seconds */[
      668917343,
      5
    ];
  var updateIntervalInSeconds = updateInterval[1];
  var formattedValue = Time$SolvuuClient.toUTCString(value);
  var match$1 = FormatJS.IntlUtils.selectUnit(Curry._1(Time$SolvuuClient.Span.to_ms, Time$SolvuuClient.to_span_since_epoch(Time$SolvuuClient.now(/* () */0))), Curry._1(Time$SolvuuClient.Span.to_ms, Time$SolvuuClient.to_span_since_epoch(value)));
  var unit = match$1.unit;
  var updateIntervalInSeconds$1 = unit >= -754457900 ? (
      unit >= 4994652 ? undefined : updateIntervalInSeconds
    ) : (
      unit >= -934569940 ? undefined : updateIntervalInSeconds
    );
  return React.createElement(ReactIntl$1.FormattedRelativeTime, ReactIntl.FormattedRelativeTime.makeProps(match$1.value, unit, updateIntervalInSeconds$1, /* auto */-1065951377, undefined, (function (formattedRelativeValue) {
                    return React.createElement("abbr", {
                                className: styles.abbr,
                                title: formattedValue
                              }, formattedRelativeValue);
                  }), undefined, /* () */0));
}

var make = FormattedRelative;

export {
  make ,
  
}
/* styles Not a pure module */
