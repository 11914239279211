import React from "react";

import folderIcon from "icons/entities/icon-folder.svg";
import blankFileIcon from "icons/entities/icon-file-blank.svg";

export const folder = ({ className }) => (
  <img alt="" className={className} src={folderIcon} />
);

export const blankFile = ({ className }) => (
  <img alt="" className={className} src={blankFileIcon} />
);

/* SVG adapted from icons/entities/icon-file.svg */
export const file = ({ className, name }) => {
  // Unique identifiers for <defs> elements, to avoid collisions with global namespace of IDs
  const filterId = "components-EntityIcon-a";
  const pathId = "components-EntityIcon-b";

  return (
    <svg
      className={className}
      width="38"
      height="40"
      viewBox="0 0 38 40"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          d="M11 31H5a1 1 0 0 1-1-1V20a1 1 0 0 1 1-1h6V4.757c0-.467.183-.915.509-1.245A1.71 1.71 0 0 1 12.735 3h14.393L36 11v24.243c0 .97-.774 1.757-1.728 1.757H12.735a1.71 1.71 0 0 1-1.226-.512A1.772 1.772 0 0 1 11 35.243V31z"
          id={pathId}
        />
        <filter
          x="-15.6%"
          y="-11.8%"
          width="131.3%"
          height="129.4%"
          filterUnits="objectBoundingBox"
          id={filterId}
        >
          <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            stdDeviation="1.5"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          />
          <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter2" />
          <feGaussianBlur
            stdDeviation="1"
            in="shadowOffsetOuter2"
            result="shadowBlurOuter2"
          />
          <feColorMatrix
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
            in="shadowBlurOuter2"
            result="shadowMatrixOuter2"
          />
          <feOffset in="SourceAlpha" result="shadowOffsetOuter3" />
          <feGaussianBlur
            stdDeviation="1"
            in="shadowOffsetOuter3"
            result="shadowBlurOuter3"
          />
          <feColorMatrix
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0"
            in="shadowBlurOuter3"
            result="shadowMatrixOuter3"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="shadowMatrixOuter2" />
            <feMergeNode in="shadowMatrixOuter3" />
          </feMerge>
        </filter>
      </defs>
      <g transform="translate(-1 -1)" fill="none" fillRule="evenodd">
        <path d="M0 0h40v40H0z" />
        <use
          fill="#000"
          filter={`url(#${filterId})`}
          xlinkHref={`#${pathId}`}
        />
        <use fill="#FAFAFA" xlinkHref={`#${pathId}`} />
        <path
          d="M36 11.229v24.014c0 .97-.774 1.757-1.728 1.757H12.735a1.71 1.71 0 0 1-1.226-.512A1.772 1.772 0 0 1 11 35.243V4.757c0-.467.183-.915.509-1.245A1.71 1.71 0 0 1 12.735 3h14.393L36 11.229z"
          fill="#E2E5E7"
        />
        <path
          d="M36 11l-8.872-8-.092 6.46c-.002.408.176.8.494 1.089.32.289.752.451 1.204.451H36z"
          fill="#B0B7BD"
        />
        <path fill="#CAD1D8" d="M36 11v7l-7-7z" />
        <rect fill="#33435C" x="4" y="19" width="28" height="12" rx="1" />
        <text
          fill="#FFF"
          fontFamily="ProximaNova-Bold, Proxima Nova"
          fontSize="7"
          fontWeight="bold"
        >
          <tspan x="18" y="27" textAnchor="middle">
            {name}
          </tspan>
        </text>
      </g>
    </svg>
  );
};
