

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Button from "../../../../components/Button.bs.js";
import * as Import from "../../../../features/import.bs.js";
import * as TypeJs from "../../../../lib/solvuu/typeJs.bs.js";
import * as Caml_int64 from "bs-platform/lib/es6/caml_int64.js";
import * as EntityIcon from "../../../../components/EntityIcon/EntityIcon.bs.js";
import * as TermEvents from "../../../TermEvents.bs.js";
import * as StyleCss from "./style.css";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as ReactExtras from "../../../../lib/solvuu/ReactExtras.bs.js";
import * as FormattedBytes from "../../../../components/FormattedBytes/FormattedBytes.bs.js";
import * as ReactTruncate from "react-truncate";

function id(sidebar) {
  switch (sidebar) {
    case /* Info */0 :
        return "info";
    case /* Comments */1 :
        return "comments";
    case /* Activity */2 :
        return "activity";
    
  }
}

var sidebars = /* :: */[
  /* tuple */[
    "info",
    /* Info */0
  ],
  /* :: */[
    /* tuple */[
      "comments",
      /* Comments */1
    ],
    /* :: */[
      /* tuple */[
        "activity",
        /* Activity */2
      ],
      /* [] */0
    ]
  ]
];

function sidebarFromJs(value) {
  return Belt_Option.getExn(Curry._3(Import.List.getAssoc, sidebars, value, (function (prim, prim$1) {
                    return prim === prim$1;
                  })));
}

function sidebarToJs(sidebar) {
  return Belt_Option.getExn(Curry._2(Import.List.getBy, sidebars, (function (param) {
                      return param[1] === sidebar;
                    })))[0];
}

var Sidebar = {
  id: id,
  sidebars: sidebars,
  sidebarFromJs: sidebarFromJs,
  sidebarToJs: sidebarToJs
};

var styles = StyleCss.default;

function EntitySidebar$Comments(Props) {
  return React.createElement("div", {
              className: styles.sidebarContentEmpty
            }, "No comments yet");
}

var Comments = {
  make: EntitySidebar$Comments
};

function EntitySidebar$Activity(Props) {
  var entity = Props.entity;
  var apiClient = Props.apiClient;
  var match = entity.term;
  var termId;
  if (typeof match === "number") {
    termId = undefined;
  } else {
    var variant = match[0];
    termId = variant !== -216449554 ? (
        variant !== 3405101 ? undefined : match[1].id
      ) : match[1];
  }
  if (termId !== undefined) {
    return React.createElement(TermEvents.make, {
                id: termId,
                apiClient: apiClient
              });
  } else {
    return React.createElement("div", {
                className: styles.sidebarContentEmpty
              }, "Cannot show activity for this item.");
  }
}

var Activity = {
  make: EntitySidebar$Activity
};

function EntitySidebar$Info(Props) {
  var entity = Props.entity;
  var display = Props.display;
  var bytesSize = Props.bytesSize;
  var stringElement = function (value) {
    return React.createElement("div", {
                className: styles.infoCellValueString
              }, Belt_Option.getWithDefault(value, "-"));
  };
  var abbrElement = function (value, title) {
    return React.createElement("abbr", {
                className: styles.infoCellValueAbbr,
                title: title
              }, value);
  };
  var match = entity.term;
  var id;
  if (typeof match === "number") {
    id = undefined;
  } else {
    var variant = match[0];
    id = variant !== -216449554 ? (
        variant !== 3405101 ? (
            variant !== 295523485 ? undefined : match[1]
          ) : match[1].id
      ) : match[1];
  }
  var idElement = stringElement(id);
  var readableType = function (type_) {
    if (typeof type_ === "number") {
      if (type_ >= 737157829) {
        if (type_ >= 748194550) {
          if (type_ >= 785140586) {
            if (type_ >= 936769581) {
              return "Time";
            } else {
              return "?";
            }
          } else if (type_ >= 758940238) {
            return "Date";
          } else {
            return "Char";
          }
        } else if (type_ >= 737456202) {
          return "Bool";
        } else {
          return "Biom";
        }
      } else if (type_ !== -883458824) {
        if (type_ >= 365180284) {
          if (type_ >= 642196241) {
            return "Newick";
          } else {
            return "Float";
          }
        } else if (type_ >= 3654863) {
          return "Int";
        } else {
          return "String";
        }
      } else {
        return "?";
      }
    } else {
      var variant = type_[0];
      if (variant >= 848054398) {
        if (variant >= 937413395) {
          if (variant >= 951904681) {
            return "Arrow";
          } else {
            return "Tvar";
          }
        } else if (variant >= 925483562) {
          return "Sexp";
        } else {
          return "List of " + readableType(type_[1]);
        }
      } else if (variant !== 216537025) {
        if (variant >= 847309489) {
          return "Record";
        } else {
          return "Solvuu type";
        }
      } else {
        return "Nullable of " + readableType(type_[1]);
      }
    }
  };
  var typeElement = abbrElement(readableType(entity.type_), TypeJs.stringify(Curry._1(TypeJs.convertToJs, entity.type_)));
  var commonElements_000 = /* tuple */[
    "Type",
    typeElement
  ];
  var commonElements_001 = /* :: */[
    /* tuple */[
      "ID",
      idElement
    ],
    /* [] */0
  ];
  var commonElements = /* :: */[
    commonElements_000,
    commonElements_001
  ];
  var sizeElements = bytesSize !== undefined ? /* :: */[
      /* tuple */[
        "Size",
        React.createElement("div", {
              className: styles.infoCellValueBytes
            }, React.createElement(FormattedBytes.make, {
                  value: Caml_int64.of_float(bytesSize)
                }))
      ],
      /* [] */0
    ] : /* [] */0;
  var projectElements;
  if (display) {
    var match$1 = display[0];
    projectElements = /* :: */[
      /* tuple */[
        "Title",
        stringElement(match$1.projectTitle)
      ],
      /* :: */[
        /* tuple */[
          "Lead",
          stringElement(match$1.projectLead)
        ],
        /* :: */[
          /* tuple */[
            "Informatics Contact",
            stringElement(match$1.projectInformaticsContact)
          ],
          /* :: */[
            /* tuple */[
              "Data Handoff",
              stringElement(match$1.projectDataHandoff)
            ],
            /* :: */[
              /* tuple */[
                "Analysis Type",
                stringElement(match$1.projectAnalysisType)
              ],
              /* :: */[
                /* tuple */[
                  "Platform",
                  stringElement(match$1.projectPlatform)
                ],
                /* :: */[
                  /* tuple */[
                    "Status",
                    stringElement(match$1.projectStatus)
                  ],
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ];
  } else {
    projectElements = /* [] */0;
  }
  var cells = Curry._1(Import.List.flatten, /* :: */[
        commonElements,
        /* :: */[
          sizeElements,
          /* :: */[
            projectElements,
            /* [] */0
          ]
        ]
      ]);
  return React.createElement("table", {
              className: styles.infoTable
            }, React.createElement("tbody", undefined, ReactExtras.listToElement(Curry._2(Import.List.map, cells, (function (param) {
                            var name = param[0];
                            return React.createElement("tr", {
                                        key: name
                                      }, React.createElement("td", {
                                            className: styles.infoCell
                                          }, React.createElement("div", {
                                                className: styles.infoCellName
                                              }, name)), React.createElement("td", {
                                            className: styles.infoCell
                                          }, param[1]));
                          })))));
}

var Info = {
  make: EntitySidebar$Info
};

function makeProps(lines, onTruncate, children, param) {
  return {
          lines: lines,
          onTruncate: onTruncate,
          children: children
        };
}

var Truncate = {
  makeProps: makeProps
};

function EntitySidebar$ProjectAbstract(Props) {
  var projectAbstract = Props.projectAbstract;
  var match = React.useReducer((function (state, action) {
          if (action) {
            if (state !== 1) {
              return /* Expanded */1;
            } else {
              return state;
            }
          } else if (state !== 0) {
            return state;
          } else {
            return /* Collapsed */2;
          }
        }), /* Initial */0);
  var send = match[1];
  var state = match[0];
  var abstractText;
  if (state !== 1) {
    var onTruncate = function (truncated) {
      if (truncated) {
        return Curry._1(send, /* TextTruncated */0);
      } else {
        return /* () */0;
      }
    };
    abstractText = React.createElement(ReactTruncate.default, {
          lines: 5,
          onTruncate: onTruncate,
          children: projectAbstract
        });
  } else {
    abstractText = projectAbstract;
  }
  var expandButton;
  if (state >= 2) {
    var onClick = function (param) {
      return Curry._1(send, /* Expand */1);
    };
    expandButton = React.createElement("div", {
          className: styles.headerAbstractExpandButton
        }, React.createElement(Button.make, Button.makeProps(onClick, undefined, /* `Basic */[
                  -1058563442,
                  Curry._4(Button.Type.Basic.make, undefined, /* Text */936573133, undefined, /* () */0)
                ], "See More", /* () */0)));
  } else {
    expandButton = null;
  }
  var className = state >= 2 ? styles.headerAbstractCollapsed : styles.headerAbstract;
  return React.createElement("div", {
              className: className
            }, abstractText, expandButton);
}

var ProjectAbstract = {
  Truncate: Truncate,
  make: EntitySidebar$ProjectAbstract
};

function EntitySidebar(Props) {
  var entity = Props.entity;
  var sidebar = Props.sidebar;
  var onCloseSidebar = Props.onCloseSidebar;
  var display = Props.display;
  var bytesSize = Props.bytesSize;
  var apiClient = Props.apiClient;
  var sidebarId = id(sidebar);
  var handleSidebarClose = function (param) {
    return Curry._1(onCloseSidebar, /* () */0);
  };
  var abstractElement;
  if (sidebar !== 0 || !display) {
    abstractElement = null;
  } else {
    var match = display[0].projectAbstract;
    abstractElement = match !== undefined ? React.createElement(EntitySidebar$ProjectAbstract, {
            projectAbstract: match
          }) : null;
  }
  var sidebarHeaderElement = React.createElement("div", {
        className: styles.headerWrapper
      }, React.createElement("div", {
            className: styles.header
          }, React.createElement("div", {
                className: styles.headerIcon
              }, React.createElement(EntityIcon.make, {
                    term: entity.term,
                    type_: entity.type_
                  })), React.createElement("div", {
                className: styles.headerName,
                title: entity.name
              }, entity.name), ReactExtras.withTestId("entity-sidebar-close", React.createElement("button", {
                    className: styles.headerCloseButton,
                    type: "button",
                    onClick: handleSidebarClose
                  }))), abstractElement);
  var sidebarContentElement;
  switch (sidebar) {
    case /* Info */0 :
        sidebarContentElement = React.createElement(EntitySidebar$Info, {
              entity: entity,
              display: display,
              bytesSize: bytesSize
            });
        break;
    case /* Comments */1 :
        sidebarContentElement = React.createElement(EntitySidebar$Comments, { });
        break;
    case /* Activity */2 :
        sidebarContentElement = React.createElement(EntitySidebar$Activity, {
              entity: entity,
              apiClient: apiClient
            });
        break;
    
  }
  return ReactExtras.withTestId("entity-sidebar[" + (String(sidebarId) + "]"), React.createElement("div", {
                  className: styles.wrapper
                }, React.createElement("div", {
                      className: styles.sidebar
                    }, sidebarHeaderElement, sidebarContentElement)));
}

var make = EntitySidebar;

export {
  Sidebar ,
  styles ,
  Comments ,
  Activity ,
  Info ,
  ProjectAbstract ,
  make ,
  
}
/* styles Not a pure module */
