

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Import from "../features/import.bs.js";
import * as Root$SolvuuApi from "solvuu-api/./root.bs.js";

function $$eval(f, t, simpleServerSideEval) {
  var arg = typeof t === "number" || t[0] !== 295523485 ? Curry._1(simpleServerSideEval, t) : Import.$$return(t);
  return Import.$great$great$pipe(arg, (function (arg) {
                return /* `App */[
                        3257473,
                        /* tuple */[
                          /* `Var */[
                            4298439,
                            Root$SolvuuApi.png
                          ],
                          arg
                        ]
                      ];
              }));
}

export {
  $$eval ,
  
}
/* Import Not a pure module */
