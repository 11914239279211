import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import * as Err from "components/Err.bs";

import styles from "./Err.css";

const initialState = {
  error: null,
  info: null
};

export default class ErrBoundary extends Component {
  state = initialState;

  static propTypes = {
    /**
     * Changing the resetKey prop clears the error display.
     * This can be used e.g. for hiding errors when user navigates to
     * a different page.
     */
    resetKey: PropTypes.any,
    renderError: PropTypes.func
  };

  componentDidCatch(error, info) {
    this.setState({ error, info });
  }

  componentDidUpdate(prevProps) {
    if (this.props.resetKey !== prevProps.resetKey) {
      this.setState(initialState);
    }
  }

  render() {
    const { renderError } = this.props;
    const { error } = this.state;

    if (error) {
      if (renderError) return renderError();
      else
        return (
          <div className={styles.boundaryRoot}>
            <Err.Alert.make
              title={<FormattedMessage id="errorBoundary.title" />}
              content={<FormattedMessage id="errorBoundary.message" />}
            />
          </div>
        );
    } else return this.props.children;
  }
}
