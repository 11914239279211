import React, { Component } from "react";
import PropTypes from "prop-types";

import styles from "./style.css";

import * as Loading from "components/Loading.bs";

class Image extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    blobId: PropTypes.string.isRequired,
    downloadURL: PropTypes.string,
    meta: PropTypes.shape({
      getDownloadURL: PropTypes.shape({}).isRequired
    }).isRequired
  };

  state = {
    loaded: false
  };

  handleLoaded = () => {
    this.setState({ loaded: true });
  };

  render() {
    const {
      name,
      downloadURL,
      meta: { getDownloadURL }
    } = this.props;
    const { loaded } = this.state;

    const showLoading = getDownloadURL.pending || !loaded;
    const showContent = getDownloadURL.success;

    return (
      <div className={styles.root}>
        <section className={styles.content}>
          {showLoading && (
            <div className={styles.loading}>
              <Loading.make />
            </div>
          )}
          {showContent && (
            <img
              alt={name}
              src={downloadURL}
              className={styles.image}
              onLoad={this.handleLoaded}
            />
          )}
        </section>
      </div>
    );
  }
}

export default Image;
