import { connect } from "react-redux";

import {
  createEntity,
  entityCreatorFormChange,
  entityCreatorFormTeardown,
  entityCreatorFormSelector,
  entityCreatorMetaSelector
} from "features/entities/create";
import EntityCreator from "../containers/Entities/EntityCreator/EntityCreator";

function mapStateToProps(state, { rootPath }) {
  const form = entityCreatorFormSelector(state);
  const meta = entityCreatorMetaSelector(state);

  return { root: rootPath, form, meta };
}

const mapDispatchToProps = {
  createEntity,
  onChange: entityCreatorFormChange,
  onTeardown: entityCreatorFormTeardown
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EntityCreator);
