import React from "react";
import PropTypes from "prop-types";
import { FormattedDate as IntlFormattedDate } from "react-intl";

export default class FormattedDate extends React.Component {
  static propTypes = {
    value: PropTypes.string.isRequired,
    children: PropTypes.func.isRequired
  };

  render() {
    const { value, children } = this.props;
    if (!value || !children) return null;

    const [month, day, year] = value.split("/");
    const monthIndex = parseInt(month, 10) - 1;
    const date = new Date(year, monthIndex, day);
    return (
      <IntlFormattedDate
        value={date}
        day="numeric"
        month="short"
        year="numeric"
      >
        {children}
      </IntlFormattedDate>
    );
  }
}
