

import * as React from "react";
import * as ActivityJs from "./Activity.js";
import * as ReasonReactRouter from "reason-react/src/ReasonReactRouter.js";

var make = ActivityJs.default;

var Internal = {
  make: make
};

function Activity(Props) {
  var $$location = ReasonReactRouter.useUrl(undefined, /* () */0);
  return React.createElement(make, {
              location: $$location
            });
}

var make$1 = Activity;

export {
  Internal ,
  make$1 as make,
  
}
/* make Not a pure module */
