

import * as Blog from "./containers/Blog/Blog.bs.js";
import * as Home from "./containers/Marketing/Home.bs.js";
import * as Jobs from "./containers/Jobs/Jobs.bs.js";
import * as Admin from "./containers/Admin/Admin.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Drive from "./components/Drive.bs.js";
import * as Login from "./containers/Login/Login.bs.js";
import * as Route from "./Route.bs.js";
import * as React from "react";
import * as Charts from "./containers/Charts/Charts.bs.js";
import * as Layout from "./Layout.bs.js";
import * as Charts2 from "./containers/Charts/Charts2.bs.js";
import * as Company from "./containers/Marketing/Company/Company.bs.js";
import * as JobShow from "./containers/Jobs/Show/JobShow.bs.js";
import * as Loading from "./components/Loading.bs.js";
import * as Product from "./containers/Marketing/Product/Product.bs.js";
import * as Profile from "./containers/Settings/Profile/Profile.bs.js";
import * as Science from "./containers/Marketing/Science/Science.bs.js";
import * as Analysis from "./containers/Analysis/Analysis.bs.js";
import * as ApiClient from "./ApiClient.bs.js";
import * as Dashboard from "./containers/Dashboard/Dashboard.bs.js";
import * as TermsOfUse from "./containers/TermsOfUse/TermsOfUse.bs.js";
import * as UploadShow from "./containers/Upload/Show/UploadShow.bs.js";
import * as EntityRedux from "./app/EntityRedux.bs.js";
import * as ScienceItem from "./containers/Marketing/Science/Item/ScienceItem.bs.js";
import * as SolvuuQuery from "./containers/SolvuuQuery/SolvuuQuery.bs.js";
import * as PageNotFound from "./containers/PageNotFound/PageNotFound.bs.js";
import * as Registration from "./containers/Registration/Registration.bs.js";
import * as PasswordReset from "./containers/PasswordReset/PasswordReset.bs.js";
import * as PrivacyPolicy from "./containers/PrivacyPolicy/PrivacyPolicy.bs.js";
import * as ConfirmationRedux from "./app/ConfirmationRedux.bs.js";
import * as SettingsComponent from "./containers/Settings/SettingsComponent.bs.js";
import * as EntityCreatorRedux from "./app/EntityCreatorRedux.bs.js";
import * as GenomeBrowserRedux from "./app/GenomeBrowserRedux.bs.js";
import * as AuthenticatedLayout from "./containers/Layout/AuthenticatedLayout/AuthenticatedLayout.bs.js";

function Page(Props) {
  var route = Props.route;
  var logout = Props.logout;
  var loginState = Props.loginState;
  var uploadInProgress = Props.uploadInProgress;
  var background = Props.background;
  var profile = Props.profile;
  var refreshProfile = Props.refreshProfile;
  var withAuth = function (content) {
    if (loginState[0] >= 648407695) {
      var apiClient = ApiClient.make(loginState[1]);
      return React.createElement(AuthenticatedLayout.make, {
                  profile: profile,
                  uploadInProgress: uploadInProgress,
                  logout: logout,
                  background: background,
                  children: Curry._1(content, apiClient)
                });
    } else {
      Route.push(/* Login */203508041);
      return React.createElement(Loading.make, { });
    }
  };
  if (typeof route === "number") {
    if (route >= 285436586) {
      if (route >= 803993151) {
        if (route >= 975642249) {
          if (route >= 1062278927) {
            return React.createElement(Layout.Public.make, {
                        profile: profile,
                        loginState: loginState,
                        logout: logout,
                        children: React.createElement(Product.make, { })
                      });
          } else {
            return React.createElement(Layout.Public.make, {
                        profile: profile,
                        loginState: loginState,
                        logout: logout,
                        children: React.createElement(TermsOfUse.make, { })
                      });
          }
        } else if (route >= 959246196) {
          return React.createElement(PageNotFound.make, { });
        } else if (loginState[0] >= 648407695) {
          return React.createElement(AuthenticatedLayout.make, {
                      profile: profile,
                      uploadInProgress: uploadInProgress,
                      logout: logout,
                      background: background,
                      children: React.createElement(Dashboard.make, { })
                    });
        } else {
          return React.createElement(Layout.Public.make, {
                      profile: profile,
                      loginState: loginState,
                      logout: logout,
                      children: React.createElement(Home.make, { })
                    });
        }
      } else if (route >= 742332284) {
        if (route >= 796400751) {
          return withAuth((function (param) {
                        return React.createElement(Admin.make, { });
                      }));
        } else {
          return withAuth((function (param) {
                        return React.createElement(Analysis.make, { });
                      }));
        }
      } else if (route >= 483171485) {
        return withAuth((function (apiClient) {
                      return React.createElement(Charts2.make, {
                                  apiClient: apiClient
                                });
                    }));
      } else {
        Curry._1(logout, /* () */0);
        return React.createElement(Layout.PublicAction.make, {
                    children: null
                  });
      }
    } else if (route >= -334882315) {
      if (route >= -229792710) {
        if (route >= 203508041) {
          return React.createElement(Layout.PublicAction.make, {
                      children: React.createElement(Login.make, {
                            state: loginState
                          })
                    });
        } else {
          return React.createElement(Layout.Public.make, {
                      profile: profile,
                      loginState: loginState,
                      logout: logout,
                      children: React.createElement(PrivacyPolicy.make, { })
                    });
        }
      } else if (route >= -250086680) {
        return withAuth((function (apiClient) {
                      return React.createElement(SolvuuQuery.make, {
                                  apiClient: apiClient
                                });
                    }));
      } else {
        return withAuth((function (apiClient) {
                      return React.createElement(Charts.make, {
                                  apiClient: apiClient
                                });
                    }));
      }
    } else if (route !== -793101027) {
      if (route >= -442163778) {
        return React.createElement(Layout.Public.make, {
                    profile: profile,
                    loginState: loginState,
                    logout: logout,
                    children: React.createElement(PageNotFound.make, { })
                  });
      } else {
        return withAuth((function (param) {
                      return React.createElement(GenomeBrowserRedux.make, { });
                    }));
      }
    } else {
      return React.createElement(Layout.Public.make, {
                  profile: profile,
                  loginState: loginState,
                  logout: logout,
                  children: React.createElement(Company.make, { })
                });
    }
  } else {
    var variant = route[0];
    if (variant >= 251200025) {
      if (variant >= 826169846) {
        if (variant >= 967870275) {
          var match = route[1];
          if (match[0] >= 858743897) {
            var path = match[1];
            return withAuth((function (param) {
                          return React.createElement(EntityRedux.make, {
                                      key: path,
                                      entityPath: path
                                    });
                        }));
          } else {
            var path$1 = match[1];
            return withAuth((function (param) {
                          return React.createElement(EntityCreatorRedux.make, {
                                      key: path$1,
                                      rootPath: path$1
                                    });
                        }));
          }
        } else {
          var match$1 = route[1];
          if (typeof match$1 === "number") {
            return withAuth((function (apiClient) {
                          return React.createElement(Jobs.make, {
                                      apiClient: apiClient
                                    });
                        }));
          } else if (match$1[0] >= 892015045) {
            var match$2 = match$1[1];
            var hash = match$2[1];
            var jobId = match$2[0];
            return withAuth((function (apiClient) {
                          return React.createElement(Jobs.Browse.make, {
                                      jobId: jobId,
                                      hash: hash,
                                      apiClient: apiClient
                                    });
                        }));
          } else {
            var id = match$1[1];
            return withAuth((function (apiClient) {
                          return React.createElement(JobShow.make, {
                                      id: id,
                                      apiClient: apiClient
                                    });
                        }));
          }
        }
      } else if (variant >= 737307010) {
        var match$3 = route[1];
        if (typeof match$3 === "number") {
          return React.createElement(Layout.Public.make, {
                      profile: profile,
                      loginState: loginState,
                      logout: logout,
                      children: React.createElement(Blog.make, { })
                    });
        } else if (match$3[0] >= 892711040) {
          return React.createElement(Layout.Public.make, {
                      profile: profile,
                      loginState: loginState,
                      logout: logout,
                      children: React.createElement(Blog.Post.make, {
                            slug: match$3[1]
                          })
                    });
        } else {
          return React.createElement(Layout.Public.make, {
                      profile: profile,
                      loginState: loginState,
                      logout: logout,
                      children: React.createElement(Blog.make, {
                            category: match$3[1]
                          })
                    });
        }
      } else if (route[1] >= 299239215) {
        return React.createElement(Layout.PublicAction.make, {
                    children: React.createElement(Registration.make, { })
                  });
      } else {
        return React.createElement(Layout.PublicAction.make, {
                    children: React.createElement(ConfirmationRedux.make, { })
                  });
      }
    } else if (variant >= -454084349) {
      if (variant >= -219555606) {
        var match$4 = route[1];
        if (typeof match$4 === "number") {
          return withAuth((function (apiClient) {
                        return React.createElement(Drive.make, {
                                    apiClient: apiClient
                                  });
                      }));
        } else {
          var blobId = match$4[1];
          return withAuth((function (apiClient) {
                        return React.createElement(UploadShow.make, {
                                    blobId: blobId,
                                    apiClient: apiClient
                                  });
                      }));
        }
      } else {
        return withAuth((function (apiClient) {
                      return React.createElement(SettingsComponent.make, {
                                  children: React.createElement(Profile.Page.make, {
                                        apiClient: apiClient,
                                        profile: profile,
                                        refreshProfile: refreshProfile
                                      })
                                });
                    }));
      }
    } else if (variant >= -799423340) {
      if (route[1] >= 999946793) {
        return React.createElement(Layout.PublicAction.make, {
                    children: React.createElement(PasswordReset.Update.make, { })
                  });
      } else {
        return React.createElement(Layout.PublicAction.make, {
                    children: React.createElement(PasswordReset.$$Request.make, { })
                  });
      }
    } else {
      var match$5 = route[1];
      if (typeof match$5 === "number") {
        return React.createElement(Layout.Public.make, {
                    profile: profile,
                    loginState: loginState,
                    logout: logout,
                    children: React.createElement(Science.make, { })
                  });
      } else {
        return React.createElement(Layout.Public.make, {
                    profile: profile,
                    loginState: loginState,
                    logout: logout,
                    children: React.createElement(ScienceItem.make, {
                          item: match$5[1]
                        })
                  });
      }
    }
  }
}

var make = Page;

export {
  make ,
  
}
/* Blog Not a pure module */
