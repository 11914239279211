/* eslint-disable react/style-prop-object */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, FormattedNumber, injectIntl } from "react-intl";
import { max, memoize } from "ramda";
import {
  CartesianGrid,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis
} from "recharts";

import ResponsiveContainer from "components/ResponsiveContainer";

import Tooltip, {
  TooltipTitle,
  TooltipGroup,
  TooltipValue,
  TooltipValueDetail
} from "../Tooltip";
import { OptimizedBar, OptimizedBarChart } from "./OptimizedRecharts";

class BarChartTooltip extends Component {
  render() {
    const {
      currentTaxonomy,
      payload: fullPayload,
      getTaxonomyDistributionInDataset,
      getTaxonomyDistributionInSample
    } = this.props;

    const payload = fullPayload.find(p => p.name === currentTaxonomy);
    if (!payload) return null;

    const sampleId = payload.payload.sampleId;
    const taxonomy = payload.name;

    const {
      value: valueInDataset,
      total: totalInDataset,
      relative: fractionInDataset
    } = getTaxonomyDistributionInDataset(taxonomy);
    const {
      value: valueInSample,
      total: totalInSample,
      relative: fractionInSample
    } = getTaxonomyDistributionInSample(taxonomy, sampleId);

    const formattedValueInSample = <FormattedNumber value={valueInSample} />;
    const formattedTotalInSample = <FormattedNumber value={totalInSample} />;
    const formattedPercentageInSample = (
      <FormattedNumber
        style="percent"
        minimuFractionDigits={2}
        value={fractionInSample}
      />
    );
    const formattedValueInDataset = <FormattedNumber value={valueInDataset} />;
    const formattedTotalInDataset = <FormattedNumber value={totalInDataset} />;
    const formattedPercentageInDataset = (
      <FormattedNumber
        style="percent"
        minimuFractionDigits={2}
        value={fractionInDataset}
      />
    );

    return (
      <Tooltip>
        <TooltipTitle>
          <FormattedMessage
            id="biom.taxonomicAnalysis.barChart.tooltip.taxonomy"
            tagName="strong"
          />
          {` ${taxonomy}`}
        </TooltipTitle>
        <TooltipGroup key={payload.name}>
          <TooltipValue>
            <FormattedMessage
              id="biom.taxonomicAnalysis.barChart.tooltip.sample"
              tagName="strong"
            />
            {` ${sampleId}`}
          </TooltipValue>
          <TooltipValueDetail>
            <FormattedMessage
              id="biom.taxonomicAnalysis.barChart.tooltip.distributionInSample"
              values={{
                value: formattedValueInSample,
                total: formattedTotalInSample,
                percentage: formattedPercentageInSample
              }}
            />
          </TooltipValueDetail>
          <TooltipValueDetail>
            <FormattedMessage
              id="biom.taxonomicAnalysis.barChart.tooltip.distributionInDataset"
              values={{
                value: formattedValueInDataset,
                total: formattedTotalInDataset,
                percentage: formattedPercentageInDataset
              }}
            />
          </TooltipValueDetail>
        </TooltipGroup>
      </Tooltip>
    );
  }
}

class BarChartArea extends Component {
  static propTypes = {
    chartData: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        sampleId: PropTypes.string.isRequired
      })
    ),
    sampleIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    taxonomies: PropTypes.arrayOf(PropTypes.string).isRequired,
    getTaxonomyDistributionInDataset: PropTypes.func.isRequired,
    getTaxonomyDistributionInSample: PropTypes.func.isRequired,
    getTaxonomyColor: PropTypes.func.isRequired
  };

  state = {
    currentHoveredBar: null
  };

  handleMouseEnter = memoize(bar => event => {
    if (this.state.currentHoveredBar !== bar) {
      this.setState({ currentHoveredBar: bar });
    }
  });

  handleMouseMove = memoize(bar => event => {
    if (this.state.currentHoveredBar !== bar) {
      this.setState({ currentHoveredBar: bar });
    }
  });

  handleMouseLeave = memoize(bar => event => {
    this.setState({ currentHoveredBar: null });
  });

  handleBarClick = memoize(bar => event => {
    if (this.props.selectedTaxon !== bar) {
      this.props.onSelectedTaxonChange(bar);
    } else {
      this.props.onSelectedTaxonChange(null);
    }
  });

  handleYAxisTickClick = tick => {
    const sampleId = this.props.data[tick.index].sampleId;
    return this.props.navigateToSample(sampleId);
  };

  renderXTick = tick => {
    const { expand, intl } = this.props;

    if (expand) {
      return intl.formatNumber(tick, { style: "percent" });
    } else {
      return intl.formatNumber(tick);
    }
  };

  renderBar = props => {
    return <rect {...props} />;
  };

  render() {
    const {
      data,
      sampleIds,
      taxonomies,
      expand,
      selectedTaxon,
      getTaxonomyDistributionInDataset,
      getTaxonomyDistributionInSample,
      getTaxonomyColor
    } = this.props;
    const { currentHoveredBar } = this.state;

    const currentHighlightedBar = currentHoveredBar || selectedTaxon;

    // Recharts' options
    const bars = taxonomies;
    const isAnimationActive = sampleIds.length < 10;
    const layout = "vertical";
    const stackOffset = expand ? "expand" : "none";
    const xAxisDomain = [0, "dataMax"];
    const yAxisTickProps = { cursor: "pointer" };
    const yAxisWidth = data.map(k => k.label.length).reduce(max) * 8;

    const minHeight = data.length * 25;
    const width = "100%";

    return (
      <ResponsiveContainer width={width} minHeight={minHeight}>
        <OptimizedBarChart
          data={data}
          layout={layout}
          stackOffset={stackOffset}
        >
          <XAxis
            type="number"
            orientation="top"
            tickFormatter={this.renderXTick}
            domain={xAxisDomain}
          />
          <YAxis
            dataKey="label"
            type="category"
            interval={0}
            tick={yAxisTickProps}
            width={yAxisWidth}
            onClick={this.handleYAxisTickClick}
          />
          <RechartsTooltip
            content={<BarChartTooltip />}
            getTaxonomyDistributionInDataset={getTaxonomyDistributionInDataset}
            getTaxonomyDistributionInSample={getTaxonomyDistributionInSample}
            currentTaxonomy={currentHoveredBar}
            cursor={false}
          />
          <CartesianGrid strokeDasharray="3 3" horizontal={false} />
          {bars.map((bar, idx) => {
            const color = getTaxonomyColor(bar);
            const opacity = 0.8;
            const highlighted = currentHighlightedBar === bar;
            const muted = currentHighlightedBar && !highlighted;
            return (
              <OptimizedBar
                dataKey={bar}
                key={bar}
                stackId="singleStack"
                fill={color}
                fillOpacity={opacity}
                stroke={color}
                strokeWidth={0}
                shape={this.renderBar}
                isAnimationActive={isAnimationActive}
                onClick={this.handleBarClick(bar)}
                onMouseEnter={this.handleMouseEnter(bar)}
                onMouseLeave={this.handleMouseLeave(bar)}
                onMouseMove={this.handleMouseMove(bar)}
                highlighted={highlighted}
                muted={muted}
              />
            );
          })}
        </OptimizedBarChart>
      </ResponsiveContainer>
    );
  }
}

export default injectIntl(BarChartArea);
