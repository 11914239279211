

import * as Root from "../Root.bs.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Modal from "../components/Modal/Modal.bs.js";
import * as React from "react";
import * as Import from "../features/import.bs.js";
import * as Printf from "bs-platform/lib/es6/printf.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as ReactExtras from "../lib/solvuu/ReactExtras.bs.js";
import * as VerticalLayout from "../components/Layout/VerticalLayout.bs.js";
import * as TableToolButton from "./TableToolButton.bs.js";
import * as Modal$1 from "components/Modal";
import * as Layout from "components/Layout";
import * as TableVisualizationsLauncherCss from "./TableVisualizationsLauncher.css";

var styles = TableVisualizationsLauncherCss.default;

var testIds = {
  toggleGraphical: "table-visualization-launcher-toggle-graphical",
  toggleStatistical: "table-visualization-launcher-toggle-statistical",
  button: "table-visualization-launcher-button",
  buttonLabel: "table-visualization-launcher-button-label",
  searchInput: "table-visualization-launcher-search"
};

function TableVisualizationsLauncher$Opt(Props) {
  var id = Props.id;
  var icon = Props.icon;
  var availability = Props.availability;
  var onClick = Props.onClick;
  var handleClick = function (_event) {
    if (availability) {
      return Curry._1(onClick, /* () */0);
    } else {
      return /* () */0;
    }
  };
  var disabled = !availability;
  var icon$1 = React.createElement(Root.Icon.make, {
        icon: icon
      });
  var label = React.createElement(ReactIntl.FormattedMessage, {
        id: Curry._1(Printf.sprintf(/* Format */[
                  /* String_literal */Block.__(11, [
                      "table.actions.visualize.",
                      /* String */Block.__(2, [
                          /* No_padding */0,
                          /* End_of_format */0
                        ])
                    ]),
                  "table.actions.visualize.%s"
                ]), id)
      });
  var description = availability ? React.createElement(ReactIntl.FormattedHTMLMessage, {
          id: Curry._1(Printf.sprintf(/* Format */[
                    /* String_literal */Block.__(11, [
                        "table.actions.visualize.",
                        /* String */Block.__(2, [
                            /* No_padding */0,
                            /* String_literal */Block.__(11, [
                                ".description",
                                /* End_of_format */0
                              ])
                          ])
                      ]),
                    "table.actions.visualize.%s.description"
                  ]), id)
        }) : React.createElement(ReactIntl.FormattedMessage, {
          id: "table.actions.visualize.upcoming"
        });
  return ReactExtras.withTestId(testIds.button, React.createElement("button", {
                  key: id,
                  className: styles.button,
                  disabled: disabled,
                  type: "button",
                  onClick: handleClick
                }, React.createElement("div", {
                      className: styles.buttonInner
                    }, React.createElement("div", {
                          className: styles.iconOuter
                        }, React.createElement("div", {
                              className: styles.iconInner
                            }, icon$1)), ReactExtras.withTestId(testIds.buttonLabel, React.createElement("div", {
                              className: styles.buttonLabel
                            }, label))), React.createElement("div", {
                      className: styles.buttonDescription
                    }, description)));
}

var Opt = {
  make: TableVisualizationsLauncher$Opt
};

function TableVisualizationsLauncher$LauncherWithButton(Props) {
  var visualizations = Props.visualizations;
  var isAvailable = Props.isAvailable;
  var keywords = Props.keywords;
  var id = Props.id;
  var renderToggleButton = Props.renderToggleButton;
  var onVisualizationAdd = Props.onVisualizationAdd;
  var match = React.useReducer((function (state, action) {
          if (typeof action === "number") {
            if (action !== 0) {
              return {
                      open_: false,
                      search: ""
                    };
            } else {
              return {
                      open_: true,
                      search: ""
                    };
            }
          } else {
            return {
                    open_: state.open_,
                    search: action[0]
                  };
          }
        }), {
        open_: false,
        search: ""
      });
  var send = match[1];
  var state = match[0];
  var match$1 = state.open_;
  var modalElement;
  if (match$1) {
    var visibleVisualizationOptions = Curry._2(Import.List.keep, visualizations, (function (k) {
            var needle = state.search.toLowerCase();
            return Curry._1(keywords, k).includes(needle);
          }));
    var visualizationButtons = ReactExtras.listToElement(Curry._2(Import.List.map, visibleVisualizationOptions, (function (k) {
                var id$1 = Curry._1(id, k);
                return React.createElement(TableVisualizationsLauncher$Opt, {
                            id: id$1,
                            icon: k,
                            availability: Curry._1(isAvailable, k),
                            onClick: (function (param) {
                                Curry._1(send, /* Close */1);
                                return Curry._1(onVisualizationAdd, k);
                              }),
                            key: id$1
                          });
              })));
    var searchElement = React.createElement("div", {
          className: styles.searchInputWrapper
        }, ReactExtras.withTestId(testIds.searchInput, React.createElement("input", {
                  className: styles.searchInput,
                  autoFocus: true,
                  placeholder: "Search",
                  type: "text",
                  value: state.search,
                  onChange: (function ($$event) {
                      var value = ReactExtras.eventTargetValue($$event);
                      return Curry._1(send, /* ChangeSearch */[value]);
                    })
                })));
    var headerElement = React.createElement(Modal$1.ModalHeader, {
          children: React.createElement(ReactIntl.FormattedMessage, {
                id: "table.actions.visualize.launcher.header"
              })
        });
    modalElement = React.createElement(Modal$1.default, Modal.makeProps(Caml_option.some(headerElement), undefined, (function (param) {
                return Curry._1(send, /* Close */1);
              }), 420, React.createElement(Modal$1.ModalBody, {
                  children: React.createElement(Layout.VerticalLayout, VerticalLayout.makeProps(/* x2 */26810, null, /* () */0), React.createElement(Layout.VerticalLayoutItem, {
                            children: searchElement
                          }), React.createElement(Layout.VerticalLayoutItem, {
                            children: React.createElement("div", {
                                  className: styles.buttons
                                }, visualizationButtons)
                          }))
                }), /* () */0));
  } else {
    modalElement = null;
  }
  var onClick = function (param) {
    var match = state.open_;
    return Curry._1(send, match ? /* Close */1 : /* Open */0);
  };
  var toggleButtonElement = Curry._1(renderToggleButton, onClick);
  return React.createElement(React.Fragment, undefined, toggleButtonElement, modalElement);
}

var LauncherWithButton = {
  make: TableVisualizationsLauncher$LauncherWithButton
};

function TableVisualizationsLauncher$GraphicalAnalysis(Props) {
  var currentVisualizations = Props.currentVisualizations;
  var onVisualizationAdd = Props.onVisualizationAdd;
  var renderToggleButton = function (onClick) {
    var count = Curry._1(Import.List.length, Curry._2(Import.List.keep, currentVisualizations, (function (param) {
                return Root.kind(param.t) === /* Graphical */711231859;
              })));
    var message = count !== 0 ? (
        count !== 1 ? React.createElement(ReactIntl.FormattedMessage, {
                id: "table.actions.visualize.graphical.toggle.many",
                values: {
                  count: count
                }
              }) : React.createElement(ReactIntl.FormattedMessage, {
                id: "table.actions.visualize.graphical.toggle.one"
              })
      ) : React.createElement(ReactIntl.FormattedMessage, {
            id: "table.actions.visualize.graphical.toggle.none"
          });
    return React.createElement(TableToolButton.make, {
                icon: /* Visualization */1,
                onClick: onClick,
                testId: testIds.toggleGraphical,
                children: message
              });
  };
  return React.createElement(TableVisualizationsLauncher$LauncherWithButton, {
              visualizations: Curry._2(Import.List.keep, Root.all, (function (x) {
                      return Root.kind(x) === /* Graphical */711231859;
                    })),
              isAvailable: (function (x) {
                  return Belt_Option.isSome(Root.toVar(x));
                }),
              keywords: Root.keywords,
              id: Root.id,
              renderToggleButton: renderToggleButton,
              onVisualizationAdd: onVisualizationAdd
            });
}

var GraphicalAnalysis = {
  make: TableVisualizationsLauncher$GraphicalAnalysis
};

function TableVisualizationsLauncher$StatisticalAnalysis(Props) {
  var currentVisualizations = Props.currentVisualizations;
  var onVisualizationAdd = Props.onVisualizationAdd;
  var renderToggleButton = function (onClick) {
    var count = Curry._1(Import.List.length, Curry._2(Import.List.keep, currentVisualizations, (function (param) {
                return Root.kind(param.t) === /* Statistical */-608833509;
              })));
    var message = count !== 0 ? (
        count !== 1 ? React.createElement(ReactIntl.FormattedMessage, {
                id: "table.actions.visualize.statistical.toggle.many",
                values: {
                  count: count
                }
              }) : React.createElement(ReactIntl.FormattedMessage, {
                id: "table.actions.visualize.statistical.toggle.one"
              })
      ) : React.createElement(ReactIntl.FormattedMessage, {
            id: "table.actions.visualize.statistical.toggle.none"
          });
    return React.createElement(TableToolButton.make, {
                icon: /* Statistic */2,
                onClick: onClick,
                testId: testIds.toggleStatistical,
                children: message
              });
  };
  return React.createElement(TableVisualizationsLauncher$LauncherWithButton, {
              visualizations: Curry._2(Import.List.keep, Root.all, (function (x) {
                      return Root.kind(x) === /* Statistical */-608833509;
                    })),
              isAvailable: (function (x) {
                  return Belt_Option.isSome(Root.toVar(x));
                }),
              keywords: Root.keywords,
              id: Root.id,
              renderToggleButton: renderToggleButton,
              onVisualizationAdd: onVisualizationAdd
            });
}

var StatisticalAnalysis = {
  make: TableVisualizationsLauncher$StatisticalAnalysis
};

function TableVisualizationsLauncher(Props) {
  var visualizations = Props.visualizations;
  var message = Props.message;
  var onVisualizationAdd = Props.onVisualizationAdd;
  var renderToggleButton = function (onClick) {
    return React.createElement(TableToolButton.make, {
                icon: /* Statistic */2,
                onClick: onClick,
                testId: testIds.toggleStatistical,
                children: message
              });
  };
  return React.createElement(TableVisualizationsLauncher$LauncherWithButton, {
              visualizations: visualizations,
              isAvailable: (function (x) {
                  return Belt_Option.isSome(Root.toVar(x));
                }),
              keywords: Root.keywords,
              id: Root.id,
              renderToggleButton: renderToggleButton,
              onVisualizationAdd: onVisualizationAdd
            });
}

var make = TableVisualizationsLauncher;

export {
  Opt ,
  LauncherWithButton ,
  GraphicalAnalysis ,
  StatisticalAnalysis ,
  make ,
  testIds ,
  
}
/* styles Not a pure module */
