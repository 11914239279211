import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import SolvuuPropTypes from "lib/solvuu/solvuuPropTypes";

import * as Button from "components/Button.bs";
import Modal, { ModalBody, ModalHeader, ModalFooter } from "components/Modal";
import * as FormatEditor from "components/FormatEditor/FormatEditor.bs.js";
import { HorizontalLayout, HorizontalLayoutItem } from "components/Layout";
import * as ReactExtras from "lib/solvuu/ReactExtras.bs";

import styles from "./style.css";

export default class FormatEditorModal extends Component {
  static propTypes = {
    fileName: PropTypes.string.isRequired,
    format: SolvuuPropTypes.format.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = { format: props.format };
  }

  handleSave = () => {
    this.props.onSave(this.state.format);
  };

  handleFormatChange = format => {
    this.setState({ format });
  };

  handleClose = () => {
    this.props.onClose();
  };

  render() {
    const { fileName } = this.props;
    const { format } = this.state;

    return (
      <Modal
        onClose={this.handleClose}
        header={<ModalHeader>{fileName}</ModalHeader>}
      >
        <ModalBody>
          <FormatEditor.Base.make
            format={format}
            onChange={this.handleFormatChange}
          />
        </ModalBody>
        <ModalFooter>
          <div className={styles.buttons}>
            <HorizontalLayout gap="3x">
              <HorizontalLayoutItem>
                {ReactExtras.withTestId(
                  "cancel-button",
                  <Button.make onClick={this.handleClose}>
                    <FormattedMessage id="entityCreator.fields.blobs.format.cancel" />
                  </Button.make>
                )}
              </HorizontalLayoutItem>
              <HorizontalLayoutItem>
                {ReactExtras.withTestId(
                  "save-button",
                  <Button.make
                    type={Button.Type.basic({
                      variant: Button.Variant.primary
                    })}
                    onClick={this.handleSave}
                  >
                    <FormattedMessage id="entityCreator.fields.blobs.format.save" />
                  </Button.make>
                )}
              </HorizontalLayoutItem>
            </HorizontalLayout>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}
