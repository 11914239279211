import React, { Component } from "react";
import PropTypes from "prop-types";
import { BarChart, Bar, XAxis, YAxis, Tooltip } from "recharts";
import { aperture } from "ramda";

import ResponsiveContainer from "components/ResponsiveContainer";
import { primary } from "lib/colors";

export default class Histogram extends Component {
  static propTypes = {
    bins: PropTypes.arrayOf(PropTypes.number).isRequired,
    counts: PropTypes.arrayOf(PropTypes.number).isRequired,
    xAxisLabel: PropTypes.string.isRequired
  };

  render() {
    const { bins, counts, xAxisLabel } = this.props;
    console.assert(
      bins.length === counts.length + 1,
      "A count is required for each pair of bin ends"
    );

    const data = aperture(2, bins).map(([x0, x1], index) => ({
      x0,
      x1,
      count: counts[index]
    }));

    function tickFormatter(idx) {
      const bin = data[idx];
      return bin.x0;
    }

    function labelFormatter(idx) {
      const bin = data[idx];
      return `${bin.x0}, ${bin.x1}`;
    }

    const width = "100%";
    const height = 200;
    const margin = { bottom: 20 };

    return (
      <ResponsiveContainer height={height} width={width}>
        <BarChart data={data} margin={margin} barCategoryGap={-1}>
          <XAxis
            tickFormatter={tickFormatter}
            tickLine={false}
            label={{ value: xAxisLabel, position: "bottom" }}
          />
          <YAxis
            dataKey="count"
            label={{
              value: "Count",
              angle: -90,
              position: "insideLeft",
              dy: 20
            }}
          />
          <Tooltip labelFormatter={labelFormatter} />
          <Bar dataKey="count" fill={primary} isAnimationActive={false} />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
