import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import styles from "./style.css";

export class VerticalLayout extends Component {
  static propTypes = {
    gap: PropTypes.oneOf(["1x", "2x", "3x", "4x"]).isRequired
  };

  render() {
    const { gap } = this.props;

    const className = cx(styles.verticalLayout, {
      [styles.verticalLayout1X]: gap === "1x",
      [styles.verticalLayout2X]: gap === "2x",
      [styles.verticalLayout3X]: gap === "3x",
      [styles.verticalLayout4X]: gap === "4x"
    });
    return (
      <div className={className} data-testid={this.props["data-testid"]}>
        {this.props.children}
      </div>
    );
  }
}

export class VerticalLayoutItem extends Component {
  render() {
    return (
      <div
        className={styles.verticalLayoutItem}
        data-testid={this.props["data-testid"]}
      >
        {this.props.children}
      </div>
    );
  }
}

export class HorizontalLayout extends Component {
  static propTypes = {
    gap: PropTypes.oneOf(["1x", "2x", "3x"]).isRequired
  };

  render() {
    const { gap } = this.props;

    const className = cx(styles.horizontalLayout, {
      [styles.horizontalLayout1X]: gap === "1x",
      [styles.horizontalLayout2X]: gap === "2x",
      [styles.horizontalLayout3X]: gap === "3x"
    });
    return (
      <div className={className} data-testid={this.props["data-testid"]}>
        {this.props.children}
      </div>
    );
  }
}

export class HorizontalLayoutItem extends Component {
  render() {
    return (
      <div
        className={styles.horizontalLayoutItem}
        data-testid={this.props["data-testid"]}
      >
        {this.props.children}
      </div>
    );
  }
}
