

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_format from "bs-platform/lib/es6/caml_format.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as ReactExtras from "../lib/solvuu/ReactExtras.bs.js";
import * as Caml_js_exceptions from "bs-platform/lib/es6/caml_js_exceptions.js";
import * as Caml_builtin_exceptions from "bs-platform/lib/es6/caml_builtin_exceptions.js";

function stringToValue(query) {
  var value;
  try {
    value = Caml_format.caml_float_of_string(query);
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn[0] === Caml_builtin_exceptions.failure) {
      return ;
    } else {
      throw exn;
    }
  }
  return value;
}

function ValueInputs$MakeValueComponent(Props) {
  var value = Props.value;
  var onChange = Props.onChange;
  var className = Props.className;
  var invalidClassName = Props.invalidClassName;
  var testId = Props.testId;
  var match = React.useReducer((function (state, action) {
          if (action.tag) {
            var dirtyValue = value.toString();
            return {
                    dirtyValue: dirtyValue,
                    focused: action[0]
                  };
          } else {
            return {
                    dirtyValue: action[0],
                    focused: state.focused
                  };
          }
        }), {
        dirtyValue: value.toString(),
        focused: false
      });
  var sendAction = match[1];
  var state = match[0];
  var send = function (action) {
    Curry._1(sendAction, action);
    if (action.tag) {
      return /* () */0;
    } else {
      var match = stringToValue(action[0]);
      if (match !== undefined) {
        return Curry._1(onChange, Caml_option.valFromOption(match));
      } else {
        return /* () */0;
      }
    }
  };
  var handleChange = function ($$event) {
    var query = ReactExtras.eventTargetValue($$event);
    return send(/* Change */Block.__(0, [query]));
  };
  var handleFocus = function (param) {
    return send(/* SetFocus */Block.__(1, [true]));
  };
  var handleBlur = function (param) {
    return send(/* SetFocus */Block.__(1, [false]));
  };
  var match$1 = state.focused;
  var displayedValue = match$1 ? state.dirtyValue : value.toString();
  var invalid = Belt_Option.isNone(stringToValue(displayedValue));
  var className$1 = Cn.make(/* :: */[
        className,
        /* :: */[
          Cn.ifTrue(invalidClassName, invalid),
          /* [] */0
        ]
      ]);
  var input = React.createElement("input", {
        className: className$1,
        type: "text",
        value: displayedValue,
        onFocus: handleFocus,
        onBlur: handleBlur,
        onChange: handleChange
      });
  if (testId !== undefined) {
    return ReactExtras.withTestId(testId, input);
  } else {
    return input;
  }
}

ValueInputs$MakeValueComponent.displayName = "ValueInputs.Float";

var Float = {
  make: ValueInputs$MakeValueComponent
};

function stringToValue$1(query) {
  var value;
  try {
    value = Caml_format.caml_int_of_string(query);
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn[0] === Caml_builtin_exceptions.failure) {
      return ;
    } else {
      throw exn;
    }
  }
  return value;
}

function ValueInputs$MakeValueComponent$1(Props) {
  var value = Props.value;
  var onChange = Props.onChange;
  var className = Props.className;
  var invalidClassName = Props.invalidClassName;
  var testId = Props.testId;
  var match = React.useReducer((function (state, action) {
          if (action.tag) {
            var dirtyValue = value.toString();
            return {
                    dirtyValue: dirtyValue,
                    focused: action[0]
                  };
          } else {
            return {
                    dirtyValue: action[0],
                    focused: state.focused
                  };
          }
        }), {
        dirtyValue: value.toString(),
        focused: false
      });
  var sendAction = match[1];
  var state = match[0];
  var send = function (action) {
    Curry._1(sendAction, action);
    if (action.tag) {
      return /* () */0;
    } else {
      var match = stringToValue$1(action[0]);
      if (match !== undefined) {
        return Curry._1(onChange, Caml_option.valFromOption(match));
      } else {
        return /* () */0;
      }
    }
  };
  var handleChange = function ($$event) {
    var query = ReactExtras.eventTargetValue($$event);
    return send(/* Change */Block.__(0, [query]));
  };
  var handleFocus = function (param) {
    return send(/* SetFocus */Block.__(1, [true]));
  };
  var handleBlur = function (param) {
    return send(/* SetFocus */Block.__(1, [false]));
  };
  var match$1 = state.focused;
  var displayedValue = match$1 ? state.dirtyValue : value.toString();
  var invalid = Belt_Option.isNone(stringToValue$1(displayedValue));
  var className$1 = Cn.make(/* :: */[
        className,
        /* :: */[
          Cn.ifTrue(invalidClassName, invalid),
          /* [] */0
        ]
      ]);
  var input = React.createElement("input", {
        className: className$1,
        type: "text",
        value: displayedValue,
        onFocus: handleFocus,
        onBlur: handleBlur,
        onChange: handleChange
      });
  if (testId !== undefined) {
    return ReactExtras.withTestId(testId, input);
  } else {
    return input;
  }
}

ValueInputs$MakeValueComponent$1.displayName = "ValueInputs.Int";

var Int = {
  make: ValueInputs$MakeValueComponent$1
};

export {
  Int ,
  Float ,
  
}
/*  Not a pure module */
