

import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Import from "../features/import.bs.js";
import * as Std$Bolt from "bolt/./std.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Lang$SolvuuApi from "solvuu-api/./lang.bs.js";
import * as Root$SolvuuApi from "solvuu-api/./root.bs.js";
import * as Science$SolvuuApi from "solvuu-api/./science.bs.js";

function $$eval(f, t) {
  if (typeof t === "number" || t[0] !== 847309489) {
    return Promise.resolve(Curry._2(Lang$SolvuuApi.Term.eval_error, "Expected record", t));
  } else {
    var result = Curry._2(Root$SolvuuApi.Quantile_quantile_plot.Arg.of_record, t[1], (function (columnName, transform, data) {
            if (columnName !== undefined) {
              var columnName$1 = columnName;
              var transformFn = function (f, x) {
                var transformed = Curry._1(f, x);
                var match = isFinite(transformed);
                if (match) {
                  return transformed;
                }
                
              };
              var fromTransform;
              switch (transform) {
                case "Log2Transform" :
                    fromTransform = /* `Ok */[
                      17724,
                      /* tuple */[
                        (function (param) {
                            return transformFn((function (x) {
                                          return Math.log2(x);
                                        }), param);
                          }),
                        Science$SolvuuApi.log2UniformDistributionPercentiles,
                        Curry._1(Import.sprintf(/* Format */[
                                  /* String_literal */Block.__(11, [
                                      "log2(",
                                      /* String */Block.__(2, [
                                          /* No_padding */0,
                                          /* Char_literal */Block.__(12, [
                                              /* ")" */41,
                                              /* End_of_format */0
                                            ])
                                        ])
                                    ]),
                                  "log2(%s)"
                                ]), columnName$1),
                        "log2(uniform)"
                      ]
                    ];
                    break;
                case "MinusLog10Transform" :
                    fromTransform = /* `Ok */[
                      17724,
                      /* tuple */[
                        (function (param) {
                            return transformFn((function (x) {
                                          return -1.0 * Math.log10(x);
                                        }), param);
                          }),
                        Science$SolvuuApi.minusLog10UniformDistributionPercentiles,
                        Curry._1(Import.sprintf(/* Format */[
                                  /* String_literal */Block.__(11, [
                                      "-log10(",
                                      /* String */Block.__(2, [
                                          /* No_padding */0,
                                          /* Char_literal */Block.__(12, [
                                              /* ")" */41,
                                              /* End_of_format */0
                                            ])
                                        ])
                                    ]),
                                  "-log10(%s)"
                                ]), columnName$1),
                        "-log10(uniform)"
                      ]
                    ];
                    break;
                case "NoTransform" :
                    fromTransform = /* `Ok */[
                      17724,
                      /* tuple */[
                        (function (param) {
                            return transformFn((function (x) {
                                          return x;
                                        }), param);
                          }),
                        Science$SolvuuApi.normalDistributionPercentiles,
                        columnName$1,
                        "normal"
                      ]
                    ];
                    break;
                default:
                  fromTransform = /* `Error */[
                    106380200,
                    /* :: */[
                      /* tuple */[
                        Root$SolvuuApi.Quantile_quantile_plot.Arg.Const.Label.transform,
                        /* Invalid */144245463
                      ],
                      /* [] */0
                    ]
                  ];
              }
              if (fromTransform[0] >= 106380200) {
                return fromTransform;
              } else {
                var match = fromTransform[1];
                var transformFn$1 = match[0];
                var data$1 = Curry._2(Std$Bolt.Res.map_error, Curry._2(Std$Bolt.Res.map, Curry._3(Lang$SolvuuApi.Table.project_column, data, /* `Named */[
                              699531161,
                              columnName$1
                            ], Curry._1(Lang$SolvuuApi.Record.Param.Arg_type.create, (function (param) {
                                    if (typeof param === "number") {
                                      return /* `Ok */[
                                              17724,
                                              undefined
                                            ];
                                    } else {
                                      var variant = param[0];
                                      if (variant !== 3654863 && variant !== 365180284) {
                                        if (variant !== 925978388) {
                                          return /* `Ok */[
                                                  17724,
                                                  undefined
                                                ];
                                        } else {
                                          var match = param[1];
                                          if (typeof match === "number") {
                                            return /* `Ok */[
                                                    17724,
                                                    undefined
                                                  ];
                                          } else {
                                            var variant$1 = match[0];
                                            if (variant$1 !== 3654863 && variant$1 !== 365180284) {
                                              return /* `Ok */[
                                                      17724,
                                                      undefined
                                                    ];
                                            } else {
                                              return /* `Ok */[
                                                      17724,
                                                      match[1]
                                                    ];
                                            }
                                          }
                                        }
                                      } else {
                                        return /* `Ok */[
                                                17724,
                                                param[1]
                                              ];
                                      }
                                    }
                                  }))), (function (l) {
                            return Curry._1(Import.List.toArray, Curry._2(Import.List.keepMap, l, (function (x) {
                                              return Belt_Option.flatMap(x, transformFn$1);
                                            })));
                          })), (function (param) {
                        return /* :: */[
                                /* tuple */[
                                  Root$SolvuuApi.Quantile_quantile_plot.Arg.Const.Label.data,
                                  /* Invalid */144245463
                                ],
                                /* [] */0
                              ];
                      }));
                if (data$1[0] >= 106380200) {
                  return data$1;
                } else {
                  var data$2 = data$1[1];
                  var dataQuantiles = Science$SolvuuApi.calculatePercentiles(data$2);
                  var sampleSize = data$2.length;
                  return /* `Ok */[
                          17724,
                          /* `Viz */[
                            4300231,
                            /* `Record */[
                              847309489,
                              Curry._2(Lang$SolvuuApi.Record.tagged, f, /* `Record */[
                                    847309489,
                                    /* :: */[
                                      /* tuple */[
                                        Root$SolvuuApi.Quantile_quantile_plot.T.Const.Label.theoreticalQuantiles,
                                        /* `List */[
                                          848054398,
                                          Curry._2(Import.List.map, Curry._1(Import.List.fromArray, match[1]), (function (x) {
                                                  return /* `Float */[
                                                          365180284,
                                                          x
                                                        ];
                                                }))
                                        ]
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          Root$SolvuuApi.Quantile_quantile_plot.T.Const.Label.dataQuantiles,
                                          /* `List */[
                                            848054398,
                                            Curry._2(Import.List.map, Curry._1(Import.List.fromArray, dataQuantiles), (function (x) {
                                                    return /* `Float */[
                                                            365180284,
                                                            x
                                                          ];
                                                  }))
                                          ]
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            Root$SolvuuApi.Quantile_quantile_plot.T.Const.Label.sampleSize,
                                            /* `Int */[
                                              3654863,
                                              sampleSize
                                            ]
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              Root$SolvuuApi.Quantile_quantile_plot.T.Const.Label.theoreticalAxisLabel,
                                              /* `String */[
                                                -976970511,
                                                match[2]
                                              ]
                                            ],
                                            /* :: */[
                                              /* tuple */[
                                                Root$SolvuuApi.Quantile_quantile_plot.T.Const.Label.dataAxisLabel,
                                                /* `String */[
                                                  -976970511,
                                                  match[3]
                                                ]
                                              ],
                                              /* [] */0
                                            ]
                                          ]
                                        ]
                                      ]
                                    ]
                                  ])
                            ]
                          ]
                        ];
                }
              }
            } else {
              return /* `Error */[
                      106380200,
                      /* :: */[
                        /* tuple */[
                          Root$SolvuuApi.Quantile_quantile_plot.Arg.Const.Label.columnName,
                          /* Invalid */144245463
                        ],
                        /* [] */0
                      ]
                    ];
            }
          }));
    var match = Curry._1(Std$Bolt.Res.join, result);
    if (match[0] >= 106380200) {
      var message = Curry._1(Import.List.toArray, Curry._2(Import.List.map, match[1], (function (param) {
                    var label = Curry._1(Lang$SolvuuApi.Label.to_string, param[0]);
                    var reason = param[1] >= 144245463 ? "invalid" : "not_found";
                    return "(" + (label + (", " + (reason + ") ")));
                  }))).join(" ");
      return Promise.resolve(Curry._2(Lang$SolvuuApi.Term.eval_error, message, t));
    } else {
      return Promise.resolve(match[1]);
    }
  }
}

export {
  $$eval ,
  
}
/* Import Not a pure module */
