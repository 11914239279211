import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  ScatterChart as RechartsScatterChart,
  Scatter as RechartsScatter,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import sizeMe from "react-sizeme";
import { extent } from "d3-array";
import { scaleLinear } from "d3-scale";

import { primary, secondary } from "lib/colors";
import {
  DualBrushing,
  VerticalBrush,
  HorizontalBrush,
  getNiceTicks,
  getNiceDomain
} from "components/Brushing";

import styles from "table/TableScatterPlot.css";

class ScatterPlotTooltip extends Component {
  render() {
    const { payload: payloads, xAxisName, yAxisName } = this.props;
    if (payloads.length === 0) return null;

    const payload = payloads[0].payload;
    const { x, y, label } = payload;

    return (
      <div className={styles.tooltip}>
        {label && (
          <p>
            <strong>{label}</strong>
          </p>
        )}
        <p className={styles.tooltipValue}>
          {xAxisName}: {x}
        </p>
        <p className={styles.tooltipValue}>
          {yAxisName}: {y}
        </p>
      </div>
    );
  }
}

class ScatterPlot extends Component {
  static propTypes = {
    size: PropTypes.shape({
      width: PropTypes.number
    }).isRequired,
    points: PropTypes.arrayOf(
      PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
        label: PropTypes.string
      })
    ).isRequired,
    xAxisName: PropTypes.string.isRequired,
    yAxisName: PropTypes.string.isRequired,
    pearsonCorrelationCoefficient: PropTypes.number
  };

  render() {
    const {
      size,
      points,
      xAxisName,
      yAxisName,
      pearsonCorrelationCoefficient
    } = this.props;

    const width = size.width || 600;
    const height = width;
    const margin = { top: 0, right: 0, bottom: 60, left: 60 };

    const xAxisHeight = 40;
    const yAxisWidth = 60;

    const yBrushHeight = height - xAxisHeight - margin.top - margin.bottom;
    const yBrushWidth = VerticalBrush.defaultWidth;
    const xBrushHeight = HorizontalBrush.defaultHeight;
    const xBrushWidth = width - yAxisWidth - margin.left - margin.right - 7;
    const xBrushX = margin.left + yAxisWidth;
    const xBrushY = height - xBrushHeight - 7;
    const yBrushX = 0;
    const yBrushY = margin.top;

    const xDomain = getNiceDomain(extent(points, p => p.x));
    const yDomain = getNiceDomain(extent(points, p => p.y));

    const xScale = scaleLinear().domain(xDomain);
    const yScale = scaleLinear().domain(yDomain);

    const axisPadding = 5; // Add padding to avoid clipping scatter points

    return (
      <div className={styles.plotRoot}>
        <div className={styles.plotDetails}>
          {typeof pearsonCorrelationCoefficient === "number" && (
            <div data-testid="scatter-plot-pearson-correlation-coefficient">
              {`Pearson's r = ${pearsonCorrelationCoefficient.toFixed(5)}`}
            </div>
          )}
          <div data-testid="scatter-plot-total-data-points">
            {`n = ${points.length}`}
          </div>
        </div>
        <DualBrushing xDomain={xDomain} yDomain={yDomain}>
          {({ xBrushing, yBrushing }) => {
            const xTicks = getNiceTicks(xBrushing.selectedDomain, 10);
            const yTicks = getNiceTicks(yBrushing.selectedDomain, 10);

            return (
              <RechartsScatterChart
                margin={margin}
                width={width}
                height={height}
              >
                <XAxis
                  dataKey="x"
                  type="number"
                  label={{ value: xAxisName, position: "bottom" }}
                  domain={xBrushing.selectedDomain}
                  allowDataOverflow={true}
                  ticks={xTicks}
                  height={xAxisHeight}
                  padding={{ left: axisPadding, right: axisPadding }}
                />
                <YAxis
                  dataKey="y"
                  type="number"
                  label={{ value: yAxisName, angle: -90, position: "left" }}
                  domain={yBrushing.selectedDomain}
                  allowDataOverflow={true}
                  ticks={yTicks}
                  width={yAxisWidth}
                  padding={{ top: axisPadding, bottom: axisPadding }}
                />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip
                  content={
                    <ScatterPlotTooltip
                      xAxisName={xAxisName}
                      yAxisName={yAxisName}
                    />
                  }
                />
                <RechartsScatter
                  data={points}
                  fill={primary}
                  line={{ stroke: secondary }}
                  lineType="fitting"
                  isAnimationActive={false}
                />
                <g>
                  <VerticalBrush
                    x={yBrushX}
                    y={yBrushY}
                    width={yBrushWidth}
                    height={yBrushHeight}
                    onChange={yBrushing.onChange}
                    scale={yScale}
                    throttleMs={100}
                    key={yBrushing.brushKey + width}
                  />
                </g>
                <g>
                  <HorizontalBrush
                    x={xBrushX}
                    y={xBrushY}
                    width={xBrushWidth}
                    height={xBrushHeight}
                    onChange={xBrushing.onChange}
                    scale={xScale}
                    throttleMs={100}
                    key={xBrushing.brushKey + height}
                  />
                </g>
              </RechartsScatterChart>
            );
          }}
        </DualBrushing>
      </div>
    );
  }
}

const noPlaceholder = process.env.NODE_ENV === "test";
const refreshRate = 100;
const SizedScatterPlot = sizeMe({ refreshRate, noPlaceholder })(ScatterPlot);

// Injects the style into sizeMe placeholder, for correct size measurement
export default props => (
  <SizedScatterPlot className={styles.plotRoot} {...props} />
);
