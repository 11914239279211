

import * as Auth from "./features/Auth.bs.js";
import * as Json from "@glennsl/bs-json/src/Json.bs.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Fetch from "bs-fetch/src/Fetch.js";
import * as Import from "./features/import.bs.js";
import * as $$String from "bs-platform/lib/es6/string.js";
import * as Pervasives from "bs-platform/lib/es6/pervasives.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Service$SolvuuApi from "solvuu-api/./service.bs.js";

function request(auth, http_method, m, info) {
  var make_path = function (api_version, path) {
    return "/" + $$String.concat("/", /* :: */[
                "0",
                path
              ]);
  };
  var to_header = function (username, token) {
    var token$1 = btoa(Curry._2(Import.sprintf(/* Format */[
                  /* String */Block.__(2, [
                      /* No_padding */0,
                      /* Char_literal */Block.__(12, [
                          /* ":" */58,
                          /* String */Block.__(2, [
                              /* No_padding */0,
                              /* End_of_format */0
                            ])
                        ])
                    ]),
                  "%s:%s"
                ]), username, token));
    var authorization = Curry._1(Import.sprintf(/* Format */[
              /* String_literal */Block.__(11, [
                  "Cognito ",
                  /* String */Block.__(2, [
                      /* No_padding */0,
                      /* End_of_format */0
                    ])
                ]),
              "Cognito %s"
            ]), token$1);
    return /* array */[/* tuple */[
              "Authorization",
              authorization
            ]];
  };
  var uri = make_path(/* Version_0 */744427497, Curry._1(m.$$Request.path, info));
  var headers;
  if (auth !== undefined) {
    var match = auth;
    var username = match.username;
    headers = Import.$great$great$pipe(Auth.Token.get(match.token), (function (token) {
            if (token !== undefined) {
              return /* `Ok */[
                      17724,
                      Caml_option.some(to_header(username, token))
                    ];
            } else {
              return /* Unauthorized */324098644;
            }
          }));
  } else {
    headers = Import.$$return(/* `Ok */[
          17724,
          undefined
        ]);
  }
  return Import.$great$great$eq(headers, (function (header) {
                if (typeof header === "number") {
                  return Import.$$return(/* Unauthorized */324098644);
                } else {
                  var method__ = http_method !== 3546230 ? (
                      http_method !== 891112544 ? (
                          http_method !== 994393768 ? Pervasives.failwith("Unsupported method") : /* Patch */8
                        ) : /* Post */2
                    ) : /* Get */0;
                  var body;
                  if (http_method !== 3546230) {
                    var match = Curry._1(m.$$Request.body, info);
                    body = typeof match === "number" ? undefined : Caml_option.some(JSON.stringify(match[1]));
                  } else {
                    body = undefined;
                  }
                  var requestInit = Fetch.RequestInit.make(method__, header[1], body, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(/* () */0);
                  return Import.$great$great$eq(fetch(uri, requestInit), (function (response) {
                                var status = response.status;
                                if (status !== 401) {
                                  return Import.$great$great$pipe(response.json(), (function (body) {
                                                var info = status !== 200 ? (
                                                    status !== 400 ? (
                                                        status !== 404 ? Pervasives.failwith(Curry._2(Import.sprintf(/* Format */[
                                                                        /* String_literal */Block.__(11, [
                                                                            "unexpected response ",
                                                                            /* Int */Block.__(4, [
                                                                                /* Int_d */0,
                                                                                /* No_padding */0,
                                                                                /* No_precision */0,
                                                                                /* String */Block.__(2, [
                                                                                    /* No_padding */0,
                                                                                    /* End_of_format */0
                                                                                  ])
                                                                              ])
                                                                          ]),
                                                                        "unexpected response %d%s"
                                                                      ]), status, JSON.stringify(body))) : /* `Not_found */[
                                                            -358247754,
                                                            Curry._1(m.$$Response.not_found_of_json, body)
                                                          ]
                                                      ) : /* `Bad_request */[
                                                        -99004555,
                                                        Curry._1(m.$$Response.bad_request_of_json, body)
                                                      ]
                                                  ) : /* `OK */[
                                                    17692,
                                                    Curry._1(m.$$Response.ok_of_json, body)
                                                  ];
                                                return /* `Response */[
                                                        -71406943,
                                                        {
                                                          api_version: /* Version_0 */744427497,
                                                          http_version: /* HTTP_1_1 */540315116,
                                                          info: info
                                                        }
                                                      ];
                                              }));
                                } else {
                                  return Import.$$return(/* Unauthorized */324098644);
                                }
                              }));
                }
              }));
}

function make(auth) {
  return {
          f: (function (http_method, m, info) {
              return request(auth, http_method, m, info);
            })
        };
}

function json_of_response(m, response) {
  if (typeof response === "number") {
    return "Unauthorized";
  } else {
    return Json.stringify(Curry._1(Service$SolvuuApi.$$Response.json_of_t(m.$$Response.json_of_ok, m.$$Response.json_of_bad_request, m.$$Response.json_of_not_found), response[1]));
  }
}

export {
  request ,
  make ,
  json_of_response ,
  
}
/* Auth Not a pure module */
