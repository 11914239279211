/* eslint-disable react/style-prop-object */
import React, { Component } from "react";
import {
  Bar,
  BarChart as RechartsBarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import sizeMe from "react-sizeme";
import { FormattedNumber } from "react-intl";
import { max } from "d3-array";

import { categoricalSimple as categorical } from "lib/colors";
import measureTextWidth from "lib/measureTextWidth";
import * as LangBarChart from "lang/BarChart.bs.js";

class BarChart extends Component {
  render() {
    const { series, groups, stack, layout, size } = this.props;

    const seriesNames = series.map(series => series.name);
    const groupNames = groups.map(group => group.name);
    const data = groups.map((group, groupIdx) => {
      const groupData = series.reduce(
        (acc, series) =>
          Object.assign(acc, { [series.name]: series.data[groupIdx] }),
        {}
      );
      return { $__name: group.name, ...groupData };
    });

    // Vertical layout - bars point upwards
    // Horizontal layout - bars point to the right
    const ValueAxis = layout === LangBarChart.Props.vertical ? YAxis : XAxis;
    const GroupAxis = layout === LangBarChart.Props.vertical ? XAxis : YAxis;

    // Width dimension is missing in tests
    const width = size.width || 1000;

    // Rotate the group names if they won't fit in a vertical layout
    const groupSpan = width / groups.length;
    const groupNameSpan =
      max(groupNames, name => measureTextWidth(name, 14)) + 20;
    const rotateGroupNames =
      layout === LangBarChart.Props.vertical && groupNameSpan > groupSpan;
    const groupAxisTick = rotateGroupNames
      ? { angle: -90, textAnchor: "end", dx: -5 }
      : undefined;

    // Minimum width / height of the group axis is based on the group names
    const groupAxisWidth =
      layout === LangBarChart.Props.horizontal ? groupNameSpan : undefined;
    const groupAxisHeight = rotateGroupNames ? groupNameSpan : undefined;

    // Take the axis height into account for total vertical chart height
    const height =
      layout === LangBarChart.Props.vertical
        ? 300 + (groupAxisHeight || 0)
        : groups.length * 50;

    // Position and rotate the value axis label
    const valueAxisLabel =
      layout === LangBarChart.Props.vertical
        ? {
            value: "Value",
            angle: -90,
            position: "insideLeft",
            dy: 20
          }
        : {
            value: "Value",
            position: "insideLeft",
            dy: 15,
            dx: -10
          };

    // The meaning of horizontal/vertical is switched in recharts library
    const rechartsLayout =
      layout === LangBarChart.Props.vertical ? "horizontal" : "vertical";

    // Prevent the tooltip from overlapping the highlighted group
    const tooltipOffset =
      (layout === LangBarChart.Props.vertical ? width : height) /
        groups.length /
        2 +
      10;

    function tooltipFormatter(value, _name, tooltipProps) {
      const groupName = tooltipProps.payload.$__name;
      const groupSum = groups.find(group => group.name === groupName).sum;
      // It does not make sense to show percentage when:
      // The sum is 0 - the percentage is not defined
      // There's just one series - the percentage is always going to be 100%
      const showPercentage = groupSum > 0 && series.length > 1;

      return (
        <span>
          <FormattedNumber value={value} />
          {showPercentage && (
            <span>
              {" "}
              (
              <FormattedNumber
                style="percent"
                minimuFractionDigits={2}
                value={value / groupSum}
              />
              )
            </span>
          )}
        </span>
      );
    }

    // Top-level div element helps react-sizeme to measure the width
    return (
      <div>
        <RechartsBarChart
          width={width}
          height={height}
          data={data}
          layout={rechartsLayout}
        >
          <GroupAxis
            dataKey="$__name"
            type="category"
            width={groupAxisWidth}
            height={groupAxisHeight}
            tick={groupAxisTick}
            interval={0}
          />
          <ValueAxis type="number" label={valueAxisLabel} />
          <Tooltip formatter={tooltipFormatter} offset={tooltipOffset} />
          <Legend />
          <CartesianGrid
            horizontal={layout === LangBarChart.Props.vertical}
            vertical={layout === LangBarChart.Props.horizontal}
            strokeDasharray="3 3"
          />
          {seriesNames.map((seriesName, seriesIdx) => (
            <Bar
              key={seriesName}
              dataKey={seriesName}
              fill={categorical[seriesIdx]}
              isAnimationActive={false}
              stackId={stack ? "0" : undefined}
            />
          ))}
        </RechartsBarChart>
      </div>
    );
  }
}

const noPlaceholder = process.env.NODE_ENV === "test";
const refreshRate = 100;
const enhance = sizeMe({ refreshRate, noPlaceholder });

export default enhance(BarChart);
