

import * as Pervasives from "bs-platform/lib/es6/pervasives.js";

function toString(x) {
  if (x !== -88314117) {
    if (x >= 42029996) {
      if (x >= 726513581) {
        return "AREA";
      } else {
        return "BUBBLE";
      }
    } else if (x >= 3303859) {
      return "BAR";
    } else {
      return "SANKEY";
    }
  } else {
    return "KRONA";
  }
}

function ofString(x) {
  switch (x) {
    case "AREA" :
        return /* Area */726513581;
    case "BAR" :
        return /* Bar */3303859;
    case "BUBBLE" :
        return /* Bubble */42029996;
    case "KRONA" :
        return /* Krona */-88314117;
    case "SANKEY" :
        return /* Sankey */-763086433;
    default:
      return Pervasives.failwith("Invalid: " + x);
  }
}

function equal(a, b) {
  return a === b;
}

export {
  toString ,
  ofString ,
  equal ,
  
}
/* No side effect */
