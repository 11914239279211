

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as FormatJS from "./FormatJS.bs.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";

var Cache = { };

var Messages = { };

var IntlShape = { };

function createIntlCache(prim) {
  return ReactIntl.createIntlCache();
}

function createIntl(prim, prim$1) {
  return ReactIntl.createIntl(prim, prim$1);
}

function makeProps(value, day, month, year, key, param) {
  return {
          value: value,
          day: Belt_Option.map(day, (function (x) {
                  return "numeric";
                })),
          month: Belt_Option.map(month, (function (x) {
                  return "short";
                })),
          year: Belt_Option.map(year, (function (x) {
                  return "numeric";
                })),
          key: key
        };
}

var FormattedDate = {
  makeProps: makeProps
};

var FormattedHTMLMessage = { };

var FormattedMessage = { };

function makeProps$1(value, style, minimumFractionDigits, children, key, param) {
  return {
          value: value,
          style: Belt_Option.map(style, (function (x) {
                  if (x >= -119887163) {
                    if (x >= 457508689) {
                      return "currency";
                    } else {
                      return "percent";
                    }
                  } else if (x >= -422333295) {
                    return "decimal";
                  } else {
                    return "unit";
                  }
                })),
          minimumFractionDigits: minimumFractionDigits,
          children: children,
          key: key
        };
}

var FormattedNumber = {
  makeProps: makeProps$1
};

function makeProps$2(value, unit, updateIntervalInSeconds, numeric, style, children, key, param) {
  return {
          value: value,
          unit: Curry._1(FormatJS.IntlUtils.Unit.toString, unit),
          updateIntervalInSeconds: updateIntervalInSeconds,
          numeric: Belt_Option.map(numeric, (function (x) {
                  if (x >= -958984497) {
                    return "always";
                  } else {
                    return "auto";
                  }
                })),
          style: Belt_Option.map(style, (function (x) {
                  if (x !== -64519044) {
                    if (x >= 550578843) {
                      return "narrow";
                    } else {
                      return "long";
                    }
                  } else {
                    return "short";
                  }
                })),
          children: children,
          key: key
        };
}

var FormattedRelativeTime = {
  makeProps: makeProps$2
};

var make = ReactIntl.IntlProvider;

var IntlProvider = {
  make: make
};

export {
  Cache ,
  Messages ,
  IntlShape ,
  createIntlCache ,
  createIntl ,
  FormattedDate ,
  FormattedHTMLMessage ,
  FormattedMessage ,
  FormattedNumber ,
  FormattedRelativeTime ,
  IntlProvider ,
  
}
/* make Not a pure module */
