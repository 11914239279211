

import * as Root from "../Root.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Import from "../features/import.bs.js";
import * as TermJs from "../lib/solvuu/termJs.bs.js";
import * as TermEval from "../lib/solvuu/termEval.bs.js";
import * as TableCell from "./TableCell.bs.js";
import * as TypeUtils from "../lib/solvuu/typeUtils.bs.js";
import * as TableTTest from "./TableTTest.bs.js";
import * as ReactIntl from "react-intl";
import * as TableCss from "./Table.css";
import * as ReactExtras from "../lib/solvuu/ReactExtras.bs.js";
import * as TableBoxPlot from "./TableBoxPlot.bs.js";
import * as TableDotPlot from "./TableDotPlot.bs.js";
import * as TableFilters from "./TableFilters.bs.js";
import * as TableHeatmap from "./TableHeatmap.bs.js";
import * as FullWidthCard from "../containers/Layout/AuthenticatedLayout/FullWidthCard.bs.js";
import * as TableBarChart from "./TableBarChart.bs.js";
import * as TableElements from "./TableElements.bs.js";
import * as TableAreaChart from "./TableAreaChart.bs.js";
import * as TableHistogram from "./TableHistogram.bs.js";
import * as TableLineChart from "./TableLineChart.bs.js";
import * as TableDonutChart from "./TableDonutChart.bs.js";
import * as TableHexbinPlot from "./TableHexbinPlot.bs.js";
import * as TableKronaChart from "./TableKronaChart.bs.js";
import * as TableViolinPlot from "./TableViolinPlot.bs.js";
import * as HorizontalLayout from "../components/Layout/HorizontalLayout.bs.js";
import * as TableBubbleChart from "./TableBubbleChart.bs.js";
import * as TableScatterPlot from "./TableScatterPlot.bs.js";
import * as TableVennDiagram from "./TableVennDiagram.bs.js";
import * as TableVolcanoPlot from "./TableVolcanoPlot.bs.js";
import * as TermRenderContext from "../lang/TermRenderContext.bs.js";
import * as Layout from "components/Layout";
import * as TableKernelDensity from "./TableKernelDensity.bs.js";
import * as TableScatterMatrix from "./TableScatterMatrix.bs.js";
import * as FeaturesTableFilters from "../features/entities/tables/FeaturesTableFilters.bs.js";
import * as TableSummaryStatistics from "./TableSummaryStatistics.bs.js";
import * as Caml_builtin_exceptions from "bs-platform/lib/es6/caml_builtin_exceptions.js";
import * as TableParallelCoordinates from "./TableParallelCoordinates.bs.js";
import * as TablePrincipalCoordinates from "./TablePrincipalCoordinates.bs.js";
import * as TableQuantileQuantilePlot from "./TableQuantileQuantilePlot.bs.js";
import * as TableVisualizationsLauncher from "./TableVisualizationsLauncher.bs.js";

var styles = TableCss.default;

function staticTable(t, key, data, onClose, $$eval, apiClient, renderTerm) {
  var exit = 0;
  if (t >= 265105709) {
    if (t < 588923762) {
      if (t >= 360427206) {
        if (t >= 464204031) {
          if (t !== 586017299) {
            if (t >= 464204032) {
              exit = t >= 588923761 ? 1 : 2;
            } else {
              return React.createElement(TableScatterMatrix.make, {
                          onClose: onClose,
                          data: data,
                          eval: $$eval,
                          apiClient: apiClient,
                          renderTerm: renderTerm,
                          key: key
                        });
            }
          } else {
            return React.createElement(TableVolcanoPlot.make, {
                        onClose: onClose,
                        data: data,
                        eval: $$eval,
                        apiClient: apiClient,
                        renderTerm: renderTerm,
                        key: key
                      });
          }
        } else if (t !== 377205848) {
          if (t >= 360427207) {
            exit = 2;
          } else {
            return React.createElement(TableTTest.make, {
                        onClose: onClose,
                        data: data,
                        eval: $$eval,
                        apiClient: apiClient,
                        renderTerm: renderTerm,
                        key: key
                      });
          }
        } else {
          return "Unsupported";
        }
      } else if (t >= 288973297) {
        if (t !== 300511203) {
          if (t >= 288973298) {
            exit = 2;
          } else {
            return React.createElement(TableAreaChart.make, {
                        onClose: onClose,
                        data: data,
                        eval: $$eval,
                        apiClient: apiClient,
                        renderTerm: renderTerm,
                        key: key
                      });
          }
        } else {
          return React.createElement(TableKronaChart.make, {
                      onClose: onClose,
                      data: data,
                      eval: $$eval,
                      apiClient: apiClient,
                      renderTerm: renderTerm,
                      key: key
                    });
        }
      } else if (t !== 266627423) {
        if (t >= 265105710) {
          exit = 2;
        } else {
          return React.createElement(TableHexbinPlot.make, {
                      onClose: onClose,
                      data: data,
                      eval: $$eval,
                      apiClient: apiClient,
                      renderTerm: renderTerm,
                      key: key
                    });
        }
      } else {
        return React.createElement(TableScatterPlot.make, {
                    onClose: onClose,
                    data: data,
                    eval: $$eval,
                    apiClient: apiClient,
                    renderTerm: renderTerm,
                    key: key
                  });
      }
    } else if (t >= 780238891) {
      if (t >= 969511897) {
        if (t !== 977193341) {
          exit = t !== 988997452 ? 2 : 1;
        } else {
          return "Unsupported";
        }
      } else if (t !== 907398765 && t < 969511896) {
        exit = 2;
      } else {
        return "Unsupported";
      }
    } else if (t >= 739741056) {
      if (t !== 775314475) {
        if (t >= 780238890) {
          return React.createElement(TableLineChart.make, {
                      onClose: onClose,
                      data: data,
                      eval: $$eval,
                      apiClient: apiClient,
                      renderTerm: renderTerm,
                      key: key
                    });
        } else {
          exit = 2;
        }
      } else {
        return "Unsupported";
      }
    } else if (t !== 672270517) {
      if (t >= 739741055) {
        return React.createElement(TableQuantileQuantilePlot.make, {
                    onClose: onClose,
                    data: data,
                    eval: $$eval,
                    apiClient: apiClient,
                    renderTerm: renderTerm,
                    key: key
                  });
      } else {
        exit = 2;
      }
    } else {
      return "Unsupported";
    }
  } else if (t >= -313597416) {
    if (t >= 3357607) {
      if (t >= 83361157) {
        if (t !== 117882500) {
          if (t !== 118811026) {
            exit = 2;
          } else {
            return React.createElement(TableBubbleChart.make, {
                        onClose: onClose,
                        data: data,
                        eval: $$eval,
                        apiClient: apiClient,
                        renderTerm: renderTerm,
                        key: key
                      });
          }
        } else {
          return React.createElement(TableHistogram.make, {
                      onClose: onClose,
                      data: data,
                      eval: $$eval,
                      apiClient: apiClient,
                      renderTerm: renderTerm,
                      key: key
                    });
        }
      } else if (t !== 56152651) {
        if (t >= 83361156) {
          return React.createElement(TableVennDiagram.make, {
                      onClose: onClose,
                      data: data,
                      eval: $$eval,
                      apiClient: apiClient,
                      renderTerm: renderTerm,
                      key: key
                    });
        } else {
          exit = 2;
        }
      } else {
        exit = 1;
      }
    } else if (t >= -214208188) {
      if (t !== -55180330) {
        if (t >= 3357606) {
          return "Unsupported";
        } else {
          exit = 2;
        }
      } else {
        return React.createElement(TableViolinPlot.make, {
                    onClose: onClose,
                    data: data,
                    eval: $$eval,
                    apiClient: apiClient,
                    renderTerm: renderTerm,
                    key: key
                  });
      }
    } else if (t !== -298608085) {
      if (t >= -214208189) {
        return "Unsupported";
      } else {
        exit = 2;
      }
    } else {
      return React.createElement(TableBarChart.make, {
                  onClose: onClose,
                  data: data,
                  eval: $$eval,
                  apiClient: apiClient,
                  renderTerm: renderTerm,
                  key: key
                });
    }
  } else if (t >= -496309490) {
    if (t >= -322318507) {
      if (t !== -318813910) {
        if (t >= -313597417) {
          return "Unsupported";
        } else {
          exit = 2;
        }
      } else {
        return React.createElement(TableDotPlot.make, {
                    onClose: onClose,
                    data: data,
                    eval: $$eval,
                    apiClient: apiClient,
                    renderTerm: renderTerm,
                    key: key
                  });
      }
    } else if (t !== -412176980) {
      if (t >= -322318508) {
        return React.createElement(TableParallelCoordinates.make, {
                    onClose: onClose,
                    data: data,
                    eval: $$eval,
                    apiClient: apiClient,
                    renderTerm: renderTerm,
                    key: key
                  });
      } else {
        exit = 2;
      }
    } else {
      return React.createElement(TableBoxPlot.make, {
                  onClose: onClose,
                  data: data,
                  eval: $$eval,
                  apiClient: apiClient,
                  renderTerm: renderTerm,
                  key: key
                });
    }
  } else if (t >= -642279939) {
    if (t !== -533179959) {
      if (t >= -496309491) {
        return React.createElement(TablePrincipalCoordinates.make, {
                    onClose: onClose,
                    data: data,
                    eval: $$eval,
                    apiClient: apiClient,
                    renderTerm: renderTerm,
                    key: key
                  });
      } else {
        exit = 2;
      }
    } else {
      return React.createElement(TableSummaryStatistics.make, {
                  onClose: onClose,
                  data: data,
                  eval: $$eval,
                  apiClient: apiClient,
                  renderTerm: renderTerm,
                  key: key
                });
    }
  } else if (t !== -844938357) {
    if (t >= -642279940) {
      return React.createElement(TableDonutChart.make, {
                  onClose: onClose,
                  data: data,
                  eval: $$eval,
                  apiClient: apiClient,
                  renderTerm: renderTerm,
                  key: key
                });
    } else {
      exit = 2;
    }
  } else {
    return React.createElement(TableKernelDensity.make, {
                onClose: onClose,
                data: data,
                eval: $$eval,
                apiClient: apiClient,
                renderTerm: renderTerm,
                key: key
              });
  }
  switch (exit) {
    case 1 :
        return React.createElement(TableHeatmap.make, {
                    onClose: onClose,
                    data: data,
                    eval: $$eval,
                    apiClient: apiClient,
                    renderTerm: renderTerm,
                    key: key
                  });
    case 2 :
        throw [
              Caml_builtin_exceptions.match_failure,
              /* tuple */[
                "Table.re",
                17,
                2
              ]
            ];
    
  }
}

function Table$Row(Props) {
  var columns = Props.columns;
  var row = Props.row;
  Props.rowId;
  var context = Props.context;
  var apiClient = Props.apiClient;
  var renderTerm = Props.renderTerm;
  var cells;
  if (typeof row === "number") {
    cells = null;
  } else {
    var variant = row[0];
    if (variant !== 106380200) {
      if (variant !== 847309489) {
        cells = null;
      } else {
        var rowContents = row[1];
        cells = ReactExtras.listToElement(Curry._2(Import.List.map, columns, (function (column) {
                    var name = column[0];
                    var cell = FeaturesTableFilters.findCellForColumn(name, rowContents);
                    var context$1 = TermRenderContext.into(context, /* RecordElement */425137163);
                    return React.createElement(TableCell.make, {
                                term: cell[1],
                                column: column,
                                context: context$1,
                                apiClient: apiClient,
                                renderTerm: renderTerm,
                                key: name
                              });
                  })));
      }
    } else {
      cells = React.createElement(TableElements.CellElement.make, {
            testId: "error",
            variant: /* Error */3,
            colSpan: Curry._1(Import.List.length, columns),
            children: TableCell.contentsToElement(Curry._2((function (param) {
                          return (function (param$1, param$2) {
                              return TableCell.termToContents(param, row, param$1, param$2);
                            });
                        })(context), apiClient, renderTerm))
          });
    }
  }
  return React.createElement(TableElements.RowElement.make, {
              testId: "table-row",
              children: cells
            });
}

var make = React.memo(Table$Row);

function Table$EmptyTable(Props) {
  return React.createElement(TableElements.EmptyElement.make, {
              children: React.createElement(ReactIntl.FormattedMessage, {
                    id: "table.empty"
                  })
            });
}

function Table$VisualizationsSection(Props) {
  var visualizations = Props.visualizations;
  var changeVisualizations = Props.changeVisualizations;
  var term = Props.term;
  var type_ = Props.type_;
  var $$eval = Props.eval;
  var apiClient = Props.apiClient;
  var renderTerm = Props.renderTerm;
  var visualizationElements = ReactExtras.listToElement(Curry._2(Import.List.mapReverse, visualizations, (function (param) {
              var id = param.id;
              var onClose = function (param) {
                return Curry._1(changeVisualizations, Root.WithId.remove(id, visualizations));
              };
              return staticTable(param.t, id, /* tuple */[
                          term,
                          type_
                        ], onClose, $$eval, apiClient, renderTerm);
            })));
  return React.createElement("div", undefined, visualizationElements);
}

function Table$ToolsSection(Props) {
  var columns = Props.columns;
  var filterCriteria = Props.filterCriteria;
  var handleChangeFilterCriteria = Props.handleChangeFilterCriteria;
  var visualizations = Props.visualizations;
  var onVisualizationAdd = Props.onVisualizationAdd;
  return React.createElement("div", {
              className: styles.toolsSection
            }, React.createElement(Layout.HorizontalLayout, HorizontalLayout.makeProps(/* x3 */26811, null, /* () */0), React.createElement(Layout.HorizontalLayoutItem, {
                      children: React.createElement(TableFilters.make, {
                            columns: columns,
                            criteria: filterCriteria,
                            onSubmit: handleChangeFilterCriteria
                          })
                    }), React.createElement(Layout.HorizontalLayoutItem, {
                      children: React.createElement(TableVisualizationsLauncher.GraphicalAnalysis.make, {
                            currentVisualizations: visualizations,
                            onVisualizationAdd: onVisualizationAdd
                          })
                    }), React.createElement(Layout.HorizontalLayoutItem, {
                      children: React.createElement(TableVisualizationsLauncher.StatisticalAnalysis.make, {
                            currentVisualizations: visualizations,
                            onVisualizationAdd: onVisualizationAdd
                          })
                    })));
}

function Table$PaginationSection(Props) {
  var count = Props.count;
  var total = Props.total;
  var hidden = Props.hidden;
  var onClick = Props.onClick;
  var match = hidden > 0;
  return React.createElement("div", {
              className: styles.pager
            }, React.createElement("div", {
                  className: styles.pagerSummary
                }, React.createElement(ReactIntl.FormattedMessage, {
                      id: "table.pager.summary",
                      values: {
                        count: count,
                        total: total
                      }
                    })), match ? React.createElement("button", {
                    className: styles.pagerMore,
                    type: "button",
                    onClick: onClick
                  }, React.createElement(ReactIntl.FormattedMessage, {
                        id: "table.pager.showMore",
                        values: {
                          hidden: hidden
                        }
                      })) : null, React.createElement("div", {
                  className: styles.pagerSummary
                }));
}

function Table$Table(Props) {
  var term = Props.term;
  var type_ = Props.type_;
  var $$eval = Props.eval;
  var rows = Props.rows;
  var columns = Props.columns;
  var totalRowsCount = Props.totalRowsCount;
  var maxRowsCount = Props.maxRowsCount;
  var filteredRowIndices = Props.filteredRowIndices;
  var filterCriteria = Props.filterCriteria;
  var changeFilterCriteria = Props.changeFilterCriteria;
  var send = Props.send;
  var visualizations = Props.visualizations;
  var changeVisualizations = Props.changeVisualizations;
  var apiClient = Props.apiClient;
  var renderTerm = Props.renderTerm;
  var match = React.useMemo((function () {
          var filteredRowIndices$1 = Curry._1(Import.List.fromArray, filteredRowIndices);
          var filteredRowsWithIds = FeaturesTableFilters.pickRowsWithIndices(Curry._2(Import.List.mapWithIndex, rows, (function (index, row) {
                      return /* tuple */[
                              row,
                              String(index)
                            ];
                    })), filteredRowIndices$1);
          var filteredRows = Curry._2(Import.List.map, filteredRowsWithIds, (function (param) {
                  return param[0];
                }));
          return /* tuple */[
                  filteredRows,
                  filteredRowsWithIds
                ];
        }), /* tuple */[
        rows,
        filteredRowIndices
      ]);
  var filteredRowsWithIds = match[1];
  var context = React.useMemo((function () {
          return TermRenderContext.root;
        }));
  var rowsCount = filteredRowIndices.length;
  var tableHeaders = React.useMemo((function () {
          return ReactExtras.listToElement(Curry._2(Import.List.map, columns, (function (param) {
                            var name = param[0];
                            return React.createElement(TableElements.HeaderElement.make, {
                                        testId: "table-column",
                                        children: name,
                                        key: name
                                      });
                          })));
        }), /* array */[columns]);
  var tableRows = React.useMemo((function () {
          return ReactExtras.listToElement(Curry._2(Import.List.map, Import.List.takeAtMost(filteredRowsWithIds, maxRowsCount), (function (param) {
                            var rowId = param[1];
                            return React.createElement(make, {
                                        columns: columns,
                                        row: param[0],
                                        rowId: rowId,
                                        context: context,
                                        apiClient: apiClient,
                                        renderTerm: renderTerm,
                                        key: rowId
                                      });
                          })));
        }), /* tuple */[
        filteredRowsWithIds,
        maxRowsCount
      ]);
  return ReactExtras.withTestId("entity-static-table", React.createElement("div", {
                  className: styles.root
                }, React.createElement(FullWidthCard.make, {
                      children: null
                    }, React.createElement(Table$VisualizationsSection, {
                          visualizations: visualizations,
                          changeVisualizations: changeVisualizations,
                          term: term,
                          type_: type_,
                          eval: $$eval,
                          apiClient: apiClient,
                          renderTerm: renderTerm
                        }), React.createElement(Table$ToolsSection, {
                          columns: columns,
                          filterCriteria: filterCriteria,
                          handleChangeFilterCriteria: changeFilterCriteria,
                          visualizations: visualizations,
                          onVisualizationAdd: (function (viz) {
                              return Curry._1(changeVisualizations, Root.WithId.add(viz, visualizations));
                            })
                        }), React.useMemo((function () {
                            return React.createElement(TableElements.TableElement.make, {
                                        children: null,
                                        key: "table"
                                      }, React.createElement("thead", undefined, React.createElement("tr", undefined, tableHeaders)), React.createElement("tbody", undefined, tableRows));
                          }), /* tuple */[
                          tableHeaders,
                          tableRows
                        ]), React.createElement(Table$PaginationSection, {
                          count: rowsCount,
                          total: totalRowsCount,
                          hidden: rowsCount - maxRowsCount | 0,
                          onClick: (function (param) {
                              return Curry._1(send, /* ShowNextPage */-373415137);
                            })
                        }))));
}

var initialState = {
  maxRowsCount: 20,
  type_: undefined,
  filterCriteria: /* [] */0,
  visualizations: /* [] */0
};

function reducer(state, action) {
  if (typeof action === "number") {
    return {
            maxRowsCount: state.maxRowsCount + 20 | 0,
            type_: state.type_,
            filterCriteria: state.filterCriteria,
            visualizations: state.visualizations
          };
  } else {
    var variant = action[0];
    if (variant !== -325924033) {
      if (variant >= 555208445) {
        return {
                maxRowsCount: state.maxRowsCount,
                type_: action[1],
                filterCriteria: state.filterCriteria,
                visualizations: state.visualizations
              };
      } else {
        return {
                maxRowsCount: state.maxRowsCount,
                type_: state.type_,
                filterCriteria: action[1],
                visualizations: state.visualizations
              };
      }
    } else {
      return {
              maxRowsCount: state.maxRowsCount,
              type_: state.type_,
              filterCriteria: state.filterCriteria,
              visualizations: action[1]
            };
    }
  }
}

function Table(Props) {
  var term = Props.term;
  var apiClient = Props.apiClient;
  var renderTerm = Props.renderTerm;
  var type_ = TypeUtils.inferTermTypeRe(term, /* Type_not_available */-883458824);
  var $$eval = function (x) {
    return TermEval.$$eval(apiClient)(x);
  };
  var match = React.useReducer(reducer, initialState);
  var send = match[1];
  var state = match[0];
  var filteredRowIndices = FeaturesTableFilters.filterTableWithCriteria(TermJs.convertToJs(term), FeaturesTableFilters.criteriaToJs(state.filterCriteria));
  var changeFilterCriteria = function (x) {
    return Curry._1(send, /* `ChangeFilterCriteria */[
                -846912409,
                x
              ]);
  };
  var changeVisualizations = function (x) {
    return Curry._1(send, /* `ChangeVisualizations */[
                -325924033,
                x
              ]);
  };
  var columns;
  if (typeof type_ === "number" || type_[0] !== 848054398) {
    columns = /* [] */0;
  } else {
    var match$1 = type_[1];
    columns = typeof match$1 === "number" || match$1[0] !== 847309489 ? /* [] */0 : match$1[1][0];
  }
  var rows = typeof term === "number" || term[0] !== 848054398 ? /* [] */0 : term[1];
  var totalRowsCount = React.useMemo((function () {
          return Curry._1(Import.List.length, rows);
        }), /* array */[rows]);
  var match$2 = Curry._1(Import.List.length, columns);
  if (match$2 !== 0) {
    if (totalRowsCount !== 0) {
      return React.createElement(Table$Table, {
                  term: term,
                  type_: type_,
                  eval: $$eval,
                  rows: rows,
                  columns: columns,
                  totalRowsCount: totalRowsCount,
                  maxRowsCount: state.maxRowsCount,
                  filteredRowIndices: filteredRowIndices,
                  filterCriteria: state.filterCriteria,
                  changeFilterCriteria: changeFilterCriteria,
                  send: send,
                  visualizations: state.visualizations,
                  changeVisualizations: changeVisualizations,
                  apiClient: apiClient,
                  renderTerm: renderTerm
                });
    } else {
      return React.createElement(Table$EmptyTable, { });
    }
  } else {
    return React.createElement(Table$EmptyTable, { });
  }
}

var make$1 = Table;

export {
  staticTable ,
  make$1 as make,
  
}
/* styles Not a pure module */
