

import * as Link from "../../Link.bs.js";
import * as React from "react";
import * as Section from "../Section.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as StyleCss from "./style.css";
import * as GeneRegulation from "./Item/GeneRegulation.bs.js";
import * as Transcriptomics from "./Item/Transcriptomics.bs.js";
import * as VariantDiscovery from "./Item/VariantDiscovery.bs.js";
import * as MicrobialGenomics from "./Item/MicrobialGenomics.bs.js";
import * as SingleCellGenomics from "./Item/SingleCellGenomics.bs.js";

var styles = StyleCss.default;

function Science(Props) {
  return React.createElement("div", {
              className: styles.root
            }, React.createElement("header", {
                  className: styles.header
                }, React.createElement("h1", undefined, "Advancing Science Through Software"), React.createElement("div", {
                      className: styles.headerBackground
                    }, React.createElement("div", {
                          className: styles.headerBackgroundGradient
                        }))), React.createElement(Section.make, {
                  children: React.createElement("div", {
                        className: styles.overview
                      }, React.createElement("p", undefined, "Our goal is to help experimental biologists and bioinformatics\n              scientists manage, organize, validate, and interpret all types of\n              biological data in context, towards experimental validation and\n              testable hypothesis. Our flagship data management, organization\n              solution and bioinformatics expertise can help you on small and\n              large data science projects."), React.createElement("p", undefined, "Whether you are interested in characterizing mutational landscape\n              of cancer genomes, understanding perturbed pathways in stem cells,\n              studying trait improvement in agricultural crops, dissecting\n              disease susceptibility/resistance in aquaculture, or performing\n              longitudinal studies to understand microbial dysbiosis in human\n              health, our bioinformatics solutions can help you reduce millions\n              of data points to a manageable and biologically meaningful set of\n              target biomarkers. We follow community accepted best practices for\n              data analysis, supported by rich, interactive visualization."))
                }), React.createElement(Section.make, {
                  children: null
                }, React.createElement(Section.BackgroundGradient.make, { }), React.createElement("div", {
                      className: styles.sciences
                    }, Belt_Array.map(/* array */[
                          MicrobialGenomics,
                          Transcriptomics,
                          GeneRegulation,
                          VariantDiscovery,
                          SingleCellGenomics
                        ], (function (x) {
                            return React.createElement("div", {
                                        key: x.id,
                                        className: styles.science,
                                        id: x.id
                                      }, React.createElement("img", {
                                            className: styles.scienceIcon,
                                            alt: "",
                                            src: x.icon
                                          }), React.createElement("div", {
                                            className: styles.scienceDescription
                                          }, React.createElement("h3", undefined, x.title), x.description, React.createElement("p", undefined, React.createElement(Link.make, {
                                                    to: /* `Science */[
                                                      -923566716,
                                                      /* `Item */[
                                                        815329587,
                                                        x.id
                                                      ]
                                                    ],
                                                    children: "Learn More →"
                                                  }))));
                          })))));
}

var make = Science;

export {
  styles ,
  make ,
  
}
/* styles Not a pure module */
