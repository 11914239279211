

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Ramda from "../lib/Ramda.bs.js";
import * as Import from "../features/import.bs.js";
import * as Std$Bolt from "bolt/./std.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Lang$SolvuuApi from "solvuu-api/./lang.bs.js";
import * as Root$SolvuuApi from "solvuu-api/./root.bs.js";
import * as CountBy from "ramda/src/countBy";
import * as ToPairs from "ramda/src/toPairs";

function $$eval(f, t, loop) {
  if (typeof t === "number" || t[0] !== 847309489) {
    return Promise.resolve(Curry._2(Lang$SolvuuApi.Term.eval_error, "Expected record", t));
  } else {
    var cartesian = function (l1, l2) {
      return Curry._1(Import.List.flatten, Curry._2(Import.List.map, l1, (function (e) {
                        return Curry._2(Import.List.map, l2, (function (e$prime) {
                                      return /* tuple */[
                                              e,
                                              e$prime
                                            ];
                                    }));
                      })));
    };
    var result = Curry._2(Root$SolvuuApi.Scatter_matrix.Arg.of_record, t[1], (function (columnNames, labelColumnName, data) {
            if (columnNames && columnNames[1]) {
              return /* `Ok */[
                      17724,
                      Promise.all(Curry._1(Import.List.toArray, Curry._2(Import.List.map, cartesian(columnNames, columnNames), (function (param) {
                                        return Curry._1(loop, /* `App */[
                                                    3257473,
                                                    /* tuple */[
                                                      /* `Var */[
                                                        4298439,
                                                        Root$SolvuuApi.scatter_plot
                                                      ],
                                                      /* `Record */[
                                                        847309489,
                                                        Curry._4(Root$SolvuuApi.Scatter_plot.Arg.to_record, param[0], param[1], labelColumnName, data)
                                                      ]
                                                    ]
                                                  ]);
                                      })))).then((function (scatters) {
                              var param = Belt_Option.flatMap(Curry._1(Import.List.head, Curry._1(Import.List.fromArray, scatters)), (function (term) {
                                      if (typeof term === "number" || term[0] !== 4300231) {
                                        return ;
                                      } else {
                                        var match = term[1];
                                        if (typeof match === "number" || match[0] !== 847309489) {
                                          return ;
                                        } else {
                                          var param = Curry._2(Std$Bolt.Res.bind, Curry._2(Lang$SolvuuApi.Record.Param.Tagged.parse, match[1], Lang$SolvuuApi.Record.Param.Arg_type.record), (function (param) {
                                                  return Curry._1(Std$Bolt.Res.join, Curry._2(Root$SolvuuApi.Scatter_plot.T.of_record, param[1], (function (points, param, param$1, param$2) {
                                                                    return Curry._1(Std$Bolt.Res.all, Curry._2(Import.List.map, points, (function (point) {
                                                                                      return Curry._2(Root$SolvuuApi.Scatter_plot.T.Point.of_record, point, (function (param, param$1, label) {
                                                                                                    return label;
                                                                                                  }));
                                                                                    })));
                                                                  })));
                                                }));
                                          if (param[0] >= 106380200) {
                                            return ;
                                          } else {
                                            return param[1];
                                          }
                                        }
                                      }
                                    }));
                              var allLabels = Curry._2(Import.List.keepMap, param !== undefined ? param : /* [] */0, (function (x) {
                                      return x;
                                    }));
                              var __x = Curry._1(Import.List.toArray, allLabels);
                              var labels = Curry._2(Import.List.map, Curry._2(Import.List.sort, Curry._2(Import.List.map, Curry._1(Import.List.fromArray, ToPairs.default(CountBy.default((function (a) {
                                                          return a;
                                                        }), __x))), Ramda.Wtf.toTuple), (function (param, param$1) {
                                          return param$1[1] - param[1] | 0;
                                        })), (function (param) {
                                      return param[0];
                                    }));
                              return Promise.resolve(/* `Viz */[
                                          4300231,
                                          /* `Record */[
                                            847309489,
                                            Curry._2(Lang$SolvuuApi.Record.tagged, f, /* `Record */[
                                                  847309489,
                                                  /* :: */[
                                                    /* tuple */[
                                                      Root$SolvuuApi.Scatter_matrix.T.Const.Label.scatters,
                                                      /* `List */[
                                                        848054398,
                                                        Curry._1(Import.List.fromArray, scatters)
                                                      ]
                                                    ],
                                                    /* :: */[
                                                      /* tuple */[
                                                        Root$SolvuuApi.Scatter_matrix.T.Const.Label.axisLabels,
                                                        /* `List */[
                                                          848054398,
                                                          Curry._2(Import.List.map, columnNames, (function (x) {
                                                                  return /* `String */[
                                                                          -976970511,
                                                                          x
                                                                        ];
                                                                }))
                                                        ]
                                                      ],
                                                      /* :: */[
                                                        /* tuple */[
                                                          Root$SolvuuApi.Scatter_matrix.T.Const.Label.pointLabels,
                                                          /* `List */[
                                                            848054398,
                                                            Curry._2(Import.List.map, labels, (function (x) {
                                                                    return /* `String */[
                                                                            -976970511,
                                                                            x
                                                                          ];
                                                                  }))
                                                          ]
                                                        ],
                                                        /* [] */0
                                                      ]
                                                    ]
                                                  ]
                                                ])
                                          ]
                                        ]);
                            }))
                    ];
            }
            return /* `Error */[
                    106380200,
                    /* :: */[
                      /* tuple */[
                        Root$SolvuuApi.Scatter_matrix.Arg.Const.Label.columnNames,
                        /* Invalid */144245463
                      ],
                      /* [] */0
                    ]
                  ];
          }));
    var match = Curry._1(Std$Bolt.Res.join, result);
    if (match[0] >= 106380200) {
      var message = Curry._1(Import.List.toArray, Curry._2(Import.List.map, match[1], (function (param) {
                    var label = Curry._1(Lang$SolvuuApi.Label.to_string, param[0]);
                    var reason = param[1] >= 144245463 ? "invalid" : "not_found";
                    return "(" + (label + (", " + (reason + ") ")));
                  }))).join(" ");
      return Promise.resolve(Curry._2(Lang$SolvuuApi.Term.eval_error, message, t));
    } else {
      return match[1];
    }
  }
}

export {
  $$eval ,
  
}
/* Import Not a pure module */
