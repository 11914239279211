

import * as Err from "./Err.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Spread from "../containers/Spread.bs.js";
import * as $$String from "bs-platform/lib/es6/string.js";
import * as ReactIntl from "react-intl";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as LoadingCss from "./Loading.css";

var styles = LoadingCss.default;

function Loading$Icon(Props) {
  return React.createElement("img", {
              className: styles.icon,
              alt: "",
              src: require("icons/icon-loading.png")
            });
}

function Loading(Props) {
  var match = Props.text;
  var text = match !== undefined ? Caml_option.valFromOption(match) : React.createElement(ReactIntl.FormattedMessage, {
          id: "loading"
        });
  return React.createElement(Spread.make, {
              props: {
                "data-testid": "loading"
              },
              children: React.createElement("div", {
                    className: styles.root
                  }, React.createElement(Loading$Icon, { }), text)
            });
}

function Loading$Dots(Props) {
  var match = React.useState((function () {
          return 0;
        }));
  var setNum = match[1];
  React.useEffect((function () {
          var id = setInterval((function (param) {
                  return Curry._1(setNum, (function (x) {
                                var x$1 = x + 1 | 0;
                                var match = x$1 > 3;
                                if (match) {
                                  return 0;
                                } else {
                                  return x$1;
                                }
                              }));
                }), 1000);
          return (function (param) {
                    clearInterval(id);
                    return /* () */0;
                  });
        }), ([]));
  return React.createElement("span", {
              className: styles.dots
            }, $$String.make(match[0], /* "." */46));
}

var Dots = {
  make: Loading$Dots
};

function Loading$Error(Props) {
  return React.createElement(Err.Alert.make, {
              title: React.createElement(ReactIntl.FormattedMessage, {
                    id: "loading.error.title"
                  }),
              content: React.createElement(ReactIntl.FormattedMessage, {
                    id: "loading.error.message"
                  })
            });
}

var $$Error = {
  make: Loading$Error
};

var make = Loading;

export {
  make ,
  Dots ,
  $$Error ,
  
}
/* styles Not a pure module */
