/* eslint-disable react/style-prop-object */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, FormattedNumber, injectIntl } from "react-intl";
import { max, memoize } from "ramda";
import {
  CartesianGrid,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis
} from "recharts";

import ResponsiveContainer from "components/ResponsiveContainer";

import {
  OptimizedAreaChart as AreaChart,
  OptimizedArea as Area
} from "./OptimizedRecharts";
import Tooltip, {
  TooltipTitle,
  TooltipGroup,
  TooltipValue,
  TooltipValueDetail
} from "../Tooltip";

class AreaChartTooltip extends Component {
  render() {
    const {
      currentTaxonomy,
      payload: fullPayload,
      getTaxonomyDistributionInDataset,
      getTaxonomyDistributionInSample
    } = this.props;

    const payload = fullPayload.find(p => p.name === currentTaxonomy);
    if (!payload) return null;

    const sampleId = payload.payload.sampleId;
    const taxonomy = payload.name;

    const {
      value: valueInDataset,
      total: totalInDataset,
      relative: fractionInDataset
    } = getTaxonomyDistributionInDataset(taxonomy);
    const {
      value: valueInSample,
      total: totalInSample,
      relative: fractionInSample
    } = getTaxonomyDistributionInSample(taxonomy, sampleId);

    const formattedValueInSample = <FormattedNumber value={valueInSample} />;
    const formattedTotalInSample = <FormattedNumber value={totalInSample} />;
    const formattedPercentageInSample = (
      <FormattedNumber
        style="percent"
        minimuFractionDigits={2}
        value={fractionInSample}
      />
    );
    const formattedValueInDataset = <FormattedNumber value={valueInDataset} />;
    const formattedTotalInDataset = <FormattedNumber value={totalInDataset} />;
    const formattedPercentageInDataset = (
      <FormattedNumber
        style="percent"
        minimuFractionDigits={2}
        value={fractionInDataset}
      />
    );

    return (
      <Tooltip>
        <TooltipTitle>
          <FormattedMessage
            id="biom.taxonomicAnalysis.areaChart.tooltip.taxonomy"
            tagName="strong"
          />
          {` ${taxonomy}`}
        </TooltipTitle>
        <TooltipGroup>
          <TooltipValue>
            <FormattedMessage
              id="biom.taxonomicAnalysis.areaChart.tooltip.sample"
              tagName="strong"
            />
            {` ${sampleId}`}
          </TooltipValue>
          <TooltipValueDetail>
            <FormattedMessage
              id="biom.taxonomicAnalysis.areaChart.tooltip.distributionInSample"
              values={{
                value: formattedValueInSample,
                total: formattedTotalInSample,
                percentage: formattedPercentageInSample
              }}
            />
          </TooltipValueDetail>
          <TooltipValueDetail>
            <FormattedMessage
              id="biom.taxonomicAnalysis.areaChart.tooltip.distributionInDataset"
              values={{
                value: formattedValueInDataset,
                total: formattedTotalInDataset,
                percentage: formattedPercentageInDataset
              }}
            />
          </TooltipValueDetail>
        </TooltipGroup>
      </Tooltip>
    );
  }
}
class AreaChartArea extends Component {
  static propTypes = {
    chartData: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        sampleId: PropTypes.string.isRequired
      })
    ),
    taxonomies: PropTypes.arrayOf(PropTypes.string).isRequired,
    getTaxonomyDistributionInDataset: PropTypes.func.isRequired,
    getTaxonomyDistributionInSample: PropTypes.func.isRequired,
    getTaxonomyColor: PropTypes.func.isRequired
  };

  state = {
    currentHoveredArea: null
  };

  handleAreaMouseEnter = memoize(area => event => {
    if (this.state.currentHoveredArea !== area) {
      this.setState({ currentHoveredArea: area });
    }
  });

  handleAreaMouseMove = memoize(area => event => {
    if (this.state.currentHoveredArea !== area) {
      this.setState({ currentHoveredArea: area });
    }
  });

  handleAreaMouseLeave = memoize(area => event => {
    this.setState({ currentHoveredArea: null });
  });

  handleAreaClick = memoize(area => event => {
    if (this.props.selectedTaxon !== area) {
      this.props.onSelectedTaxonChange(area);
    } else {
      this.props.onSelectedTaxonChange(null);
    }
  });

  handleYAxisTickClick = tick => {
    const sampleId = this.props.data[tick.index].sampleId;
    return this.props.navigateToSample(sampleId);
  };

  renderYTick = tick => {
    return this.props.intl.formatNumber(tick, { style: "percent" });
  };

  render() {
    const {
      data,
      taxonomies,
      selectedTaxon,
      getTaxonomyColor,
      getTaxonomyDistributionInDataset,
      getTaxonomyDistributionInSample,
      intl
    } = this.props;
    const { currentHoveredArea } = this.state;

    const currentHighlightedArea = currentHoveredArea || selectedTaxon;

    // Recharts' options
    const isAnimationActive = false;
    const layout = "horizontal";
    const stackOffset = "expand";
    const xAxisTickProps = {
      cursor: "pointer",
      angle: -90,
      textAnchor: "end",
      dx: -5
    };
    const xAxisHeight = data.map(k => k.label.length).reduce(max) * 6;
    const yAxisLabelProps = {
      value: intl.formatMessage({
        id: "biom.taxonomicAnalysis.areaChart.yAxis.label"
      }),
      angle: -90,
      dx: -20
    };
    const margin = { bottom: xAxisHeight, right: 10, left: 10 };

    const height = 500 + xAxisHeight;
    const width = "100%";
    const minWidth = data.length * 50;

    return (
      <ResponsiveContainer width={width} height={height} minWidth={minWidth}>
        <AreaChart
          data={data}
          layout={layout}
          stackOffset={stackOffset}
          margin={margin}
          isAnimationActive={isAnimationActive}
        >
          <YAxis
            type="number"
            orientation="left"
            tickFormatter={this.renderYTick}
            label={yAxisLabelProps}
          />
          <XAxis
            dataKey="label"
            type="category"
            interval={0}
            tick={xAxisTickProps}
            width={xAxisHeight}
            onClick={this.handleYAxisTickClick}
          />
          <RechartsTooltip
            content={<AreaChartTooltip />}
            getTaxonomyDistributionInDataset={getTaxonomyDistributionInDataset}
            getTaxonomyDistributionInSample={getTaxonomyDistributionInSample}
            currentTaxonomy={currentHoveredArea}
            cursor={false}
          />
          <CartesianGrid strokeDasharray="3 3" />
          {taxonomies.map(taxonomy => {
            const color = getTaxonomyColor(taxonomy);
            const opacity = 0.8;
            const highlighted = currentHighlightedArea === taxonomy;
            const muted = currentHighlightedArea && !highlighted;
            return (
              <Area
                dataKey={taxonomy}
                key={taxonomy}
                stackId="singleStack"
                fill={color}
                fillOpacity={opacity}
                stroke={color}
                strokeOpacity={opacity}
                dot={false}
                activeDot={false}
                highlighted={highlighted}
                muted={muted}
                isAnimationActive={isAnimationActive}
                onClick={this.handleAreaClick(taxonomy)}
                onMouseEnter={this.handleAreaMouseEnter(taxonomy)}
                onMouseLeave={this.handleAreaMouseLeave(taxonomy)}
                onMouseMove={this.handleAreaMouseMove(taxonomy)}
              />
            );
          })}
        </AreaChart>
      </ResponsiveContainer>
    );
  }
}

export default injectIntl(AreaChartArea);
