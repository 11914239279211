import React, { Component } from "react";
import PropTypes from "prop-types";

import styles from "./style.css";

export default class LoadingStatus extends Component {
  static propTypes = {
    operation: PropTypes.shape({
      pending: PropTypes.bool.isRequired,
      success: PropTypes.bool.isRequired,
      failure: PropTypes.bool.isRequired,
      errors: PropTypes.shape({
        message: PropTypes.string
      })
    }).isRequired
  };

  render() {
    const { operation } = this.props;

    return (
      <div className={styles.loadingStatus}>
        {operation.pending && (
          <span className={styles.loadingStatusPending}>
            Fetching latest updates...
          </span>
        )}
        {operation.failure && (
          <span className={styles.loadingStatusFailure}>
            The information may be out of date.{" "}
            {operation.errors && operation.errors.message}
          </span>
        )}
      </div>
    );
  }
}
