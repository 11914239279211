

import * as Aws from "../../features/Aws.bs.js";
import * as Err from "../../components/Err.bs.js";
import * as Link from "../Link.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Flags from "../../flags.bs.js";
import * as Paths from "../../Paths.bs.js";
import * as React from "react";
import * as Button from "../../components/Button.bs.js";
import * as Import from "../../features/import.bs.js";
import * as FormGroup from "../../components/FormGroup/FormGroup.bs.js";
import * as FormLayout from "../../components/FormLayout/FormLayout.bs.js";
import * as Pervasives from "bs-platform/lib/es6/pervasives.js";
import * as ReactIntl from "react-intl";
import * as StyleCss from "./style.css";
import * as ReactBootstrap from "../../lib/ReactBootstrap.bs.js";
import * as PublicActionCard from "../../components/PublicActionCard/PublicActionCard.bs.js";
import * as Lib from "react-bootstrap/lib";
import * as AmazonCognitoIdentityJS from "../../lib/AmazonCognitoIdentityJS.bs.js";
import * as AmazonCognitoIdentityJs from "amazon-cognito-identity-js";

var styles = StyleCss.default;

function toFormError(t) {
  if (typeof t === "number") {
    if (t >= 421190005) {
      return {
              field: "passwordConfirmation",
              id: "registration.errors.passwordConfirmation.notMatching",
              message: "Passwords don't match"
            };
    } else {
      return {
              field: "acceptTerms",
              id: "registration.errors.acceptTerms.notAccepted",
              message: "Must accept terms of use"
            };
    }
  } else {
    return {
            field: undefined,
            id: undefined,
            message: JSON.stringify(t[1], null, 2)
          };
  }
}

function isEmailConfirmationDisabledError(error) {
  if (error.code === "UserLambdaValidationException") {
    return error.message.includes("EMAIL_CONFIRMATION_DISABLED");
  } else {
    return false;
  }
}

function isPending(x) {
  if (typeof x === "number") {
    return false;
  } else {
    return x[0] < 815032112;
  }
}

function reduce(state, $$event) {
  if (typeof state === "number") {
    return Pervasives.failwith("BUG");
  } else if (state[0] >= 815032112) {
    if (typeof $$event === "number" || $$event[0] >= 781116926) {
      return Pervasives.failwith("BUG");
    } else {
      var form = $$event[1];
      var match = form.password === form.passwordConfirmation;
      var match$1 = form.acceptTerms;
      var errors = Curry._2(Import.List.keepMap, /* :: */[
            match ? undefined : /* PasswordNotMatching */421190005,
            /* :: */[
              match$1 ? undefined : /* TermsNotAccepted */-344848013,
              /* [] */0
            ]
          ], (function (x) {
              return x;
            }));
      if (errors) {
        return /* `Init */[
                815032112,
                {
                  form: form,
                  errors: errors
                }
              ];
      } else {
        return /* `Pending */[
                -113393609,
                form
              ];
      }
    }
  } else if (typeof $$event === "number") {
    return /* Success */94326179;
  } else if ($$event[0] >= 781116926) {
    var error = $$event[1];
    var match$2 = isEmailConfirmationDisabledError(error);
    var errors$1 = match$2 ? /* :: */[
        /* `Cognito */[
          -620336111,
          error
        ],
        /* [] */0
      ] : /* [] */0;
    return /* `Init */[
            815032112,
            {
              form: state[1],
              errors: errors$1
            }
          ];
  } else {
    return Pervasives.failwith("BUG");
  }
}

function register(param) {
  var attributes = /* array */[
    new AmazonCognitoIdentityJs.CognitoUserAttribute({
          Name: "email",
          Value: param.email
        }),
    new AmazonCognitoIdentityJs.CognitoUserAttribute({
          Name: "name",
          Value: param.name
        })
  ];
  var pool = Aws.getUserPool(/* () */0);
  return AmazonCognitoIdentityJS.UserPool.signUp(pool, param.username, param.password, attributes, null, (function (err, _result) {
                if (err == null) {
                  return /* Succeed */94323042;
                } else {
                  return /* `Fail */[
                          781116926,
                          err
                        ];
                }
              }));
}

function useRegistration(param) {
  var match = React.useReducer(reduce, /* `Init */[
        815032112,
        {
          form: {
            username: "",
            name: "",
            email: "",
            password: "",
            passwordConfirmation: "",
            acceptTerms: false
          },
          errors: /* [] */0
        }
      ]);
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          if (typeof state !== "number") {
            if (state[0] >= 815032112) {
              
            } else {
              register(state[1]).then((function (e) {
                      Curry._1(dispatch, e);
                      return Promise.resolve(/* () */0);
                    }));
            }
          }
          return ;
        }), /* array */[state]);
  return /* tuple */[
          state,
          dispatch
        ];
}

function Registration(Props) {
  var match = React.useState((function () {
          return "";
        }));
  var setUsername = match[1];
  var username = match[0];
  var match$1 = React.useState((function () {
          return "";
        }));
  var setName = match$1[1];
  var name = match$1[0];
  var match$2 = React.useState((function () {
          return "";
        }));
  var setEmail = match$2[1];
  var email = match$2[0];
  var match$3 = React.useState((function () {
          return "";
        }));
  var setPassword = match$3[1];
  var password = match$3[0];
  var match$4 = React.useState((function () {
          return "";
        }));
  var setPasswordConfirmation = match$4[1];
  var passwordConfirmation = match$4[0];
  var match$5 = React.useState((function () {
          return false;
        }));
  var setAcceptTerms = match$5[1];
  var acceptTerms = match$5[0];
  var match$6 = useRegistration(/* () */0);
  var dispatch = match$6[1];
  var state = match$6[0];
  var onFormSubmit = function ($$event) {
    $$event.preventDefault();
    return Curry._1(dispatch, /* `Start */[
                389604418,
                {
                  username: username,
                  name: name,
                  email: email,
                  password: password,
                  passwordConfirmation: passwordConfirmation,
                  acceptTerms: acceptTerms
                }
              ]);
  };
  var renderSuccess = function (param) {
    return React.createElement("div", undefined, React.createElement(Lib.Alert, ReactBootstrap.Alert.makeProps(/* info */-977586066, undefined, null, undefined, /* () */0), React.createElement(ReactIntl.FormattedMessage, {
                        id: "registration.success.title",
                        tagName: "h4"
                      }), React.createElement(ReactIntl.FormattedMessage, {
                        id: "registration.success.message",
                        tagName: "p"
                      })));
  };
  var renderForm = function (param) {
    var errors = typeof state === "number" || state[0] < 815032112 ? /* [] */0 : Curry._2(Import.List.map, state[1].errors, toFormError);
    return React.createElement(React.Fragment, undefined, React.createElement(PublicActionCard.Title.make, {
                    id: "registration.header"
                  }), React.createElement("form", {
                    onSubmit: onFormSubmit
                  }, React.createElement(Err.Form.make, {
                        titleId: "registration.errors.title",
                        errors: errors
                      }), React.createElement(FormLayout.VerticalFormFields.make, {
                        children: null
                      }, React.createElement(FormLayout.VerticalFormField.make, {
                            children: React.createElement(FormGroup.ControlledFormGroup.make, {
                                  value: username,
                                  type: "text",
                                  onFieldChange: (function (x) {
                                      return Curry._1(setUsername, (function (param) {
                                                    return x;
                                                  }));
                                    }),
                                  name: "username",
                                  required: true,
                                  validationState: null,
                                  label: React.createElement(ReactIntl.FormattedMessage, {
                                        id: "registration.username"
                                      })
                                })
                          }), React.createElement(FormLayout.VerticalFormField.make, {
                            children: React.createElement(FormGroup.ControlledFormGroup.make, {
                                  value: name,
                                  type: "text",
                                  onFieldChange: (function (x) {
                                      return Curry._1(setName, (function (param) {
                                                    return x;
                                                  }));
                                    }),
                                  name: "name",
                                  required: false,
                                  validationState: null,
                                  label: React.createElement(ReactIntl.FormattedMessage, {
                                        id: "registration.name"
                                      })
                                })
                          }), React.createElement(FormLayout.VerticalFormField.make, {
                            children: React.createElement(FormGroup.ControlledFormGroup.make, {
                                  value: email,
                                  type: "email",
                                  onFieldChange: (function (x) {
                                      return Curry._1(setEmail, (function (param) {
                                                    return x;
                                                  }));
                                    }),
                                  name: "email",
                                  required: true,
                                  validationState: null,
                                  label: React.createElement(ReactIntl.FormattedMessage, {
                                        id: "registration.email"
                                      })
                                })
                          }), React.createElement(FormLayout.VerticalFormField.make, {
                            children: React.createElement(FormGroup.ControlledFormGroup.make, {
                                  value: password,
                                  type: "password",
                                  onFieldChange: (function (x) {
                                      return Curry._1(setPassword, (function (param) {
                                                    return x;
                                                  }));
                                    }),
                                  name: "password",
                                  required: true,
                                  validationState: null,
                                  label: React.createElement(ReactIntl.FormattedMessage, {
                                        id: "registration.password"
                                      })
                                })
                          }), React.createElement(FormLayout.VerticalFormField.make, {
                            children: React.createElement(FormGroup.ControlledFormGroup.make, {
                                  value: passwordConfirmation,
                                  type: "password",
                                  onFieldChange: (function (x) {
                                      return Curry._1(setPasswordConfirmation, (function (param) {
                                                    return x;
                                                  }));
                                    }),
                                  name: "passwordConfirmation",
                                  required: true,
                                  validationState: null,
                                  label: React.createElement(ReactIntl.FormattedMessage, {
                                        id: "registration.passwordConfirmation"
                                      })
                                })
                          }), React.createElement(FormLayout.VerticalFormField.make, {
                            children: React.createElement(FormGroup.ControlledFormGroup.make, {
                                  renderControl: FormGroup.renderCheckbox,
                                  value: acceptTerms,
                                  type: "checkbox",
                                  onFieldChange: (function (x) {
                                      return Curry._1(setAcceptTerms, (function (param) {
                                                    return x;
                                                  }));
                                    }),
                                  name: "acceptTerms",
                                  required: true,
                                  validationState: null,
                                  label: React.createElement(ReactIntl.FormattedMessage, {
                                        id: "registration.acceptTerms",
                                        values: {
                                          link: React.createElement("a", {
                                                href: Paths.getPublicPageURL(Paths.termsOfUse),
                                                rel: "noopener noreferrer",
                                                target: "_blank"
                                              }, React.createElement(ReactIntl.FormattedMessage, {
                                                    id: "registration.acceptTerms.link"
                                                  }))
                                        }
                                      })
                                })
                          })), React.createElement(FormLayout.VerticalFormActions.make, {
                        children: React.createElement("div", {
                              className: styles.actions
                            }, React.createElement(Button.make, Button.makeProps(undefined, undefined, /* `Submit */[
                                      -828715976,
                                      Curry._5(Button.Type.Submit.make, isPending(state), undefined, undefined, undefined, /* () */0)
                                    ], React.createElement(ReactIntl.FormattedMessage, {
                                          id: "registration.submit"
                                        }), /* () */0)), React.createElement("p", {
                                  className: styles.loginMessage
                                }, React.createElement(ReactIntl.FormattedMessage, {
                                      id: "registration.login"
                                    }), " ", React.createElement(Link.make, {
                                      to: /* Login */203508041,
                                      children: React.createElement(ReactIntl.FormattedMessage, {
                                            id: "registration.loginLink"
                                          })
                                    })))
                      })));
  };
  var disabledRegistration = React.createElement(Lib.Alert, ReactBootstrap.Alert.makeProps(/* info */-977586066, undefined, null, undefined, /* () */0), React.createElement("h4", undefined, "Solvuu is currently in closed beta."), React.createElement("p", undefined, "Please email ", React.createElement("a", {
                href: "mailto:info@solvuu.com?subject=Registration"
              }, " info@solvuu.com"), " if you'd like to get an account."));
  if (Flags.disableRegistration) {
    return disabledRegistration;
  } else {
    return React.createElement(PublicActionCard.make, {
                children: typeof state === "number" ? renderSuccess(/* () */0) : renderForm(/* () */0)
              });
  }
}

var make = Registration;

export {
  make ,
  
}
/* styles Not a pure module */
