

import * as UploadJs from "./Upload.js";
import * as FeaturesUtils from "../../features/utils/FeaturesUtils.bs.js";

var $$Error = { };

var make = UploadJs.WindowDragAndDrop;

var WindowDragAndDrop = {
  make: make
};

function make$1(x) {
  return /* `Blob */[
          737307005,
          x
        ];
}

var $$Error$1 = { };

function make$2(x) {
  return /* `Blob */[
          737307005,
          /* `CreateFromURI */[
            137824358,
            x
          ]
        ];
}

var include = FeaturesUtils.Operation({
      make: make$2
    });

var CreateFromURI_initialState = include.initialState;

var CreateFromURI_start = include.start;

var CreateFromURI_success = include.success;

var CreateFromURI_failure = include.failure;

var CreateFromURI_reducer = include.reducer;

var CreateFromURI_Status = include.Status;

var CreateFromURI = {
  $$Error: $$Error$1,
  initialState: CreateFromURI_initialState,
  start: CreateFromURI_start,
  success: CreateFromURI_success,
  failure: CreateFromURI_failure,
  reducer: CreateFromURI_reducer,
  Status: CreateFromURI_Status
};

var $$Blob = {
  make: make$1,
  CreateFromURI: CreateFromURI
};

function make$3(x) {
  return /* `Blobs */[
          -936778666,
          x
        ];
}

var Failure = { };

function make$4(x) {
  return /* `Blobs */[
          -936778666,
          /* `CreateFromFiles */[
            916621809,
            x
          ]
        ];
}

var include$1 = FeaturesUtils.Operation({
      make: make$4
    });

var CreateFromFiles_initialState = include$1.initialState;

var CreateFromFiles_start = include$1.start;

var CreateFromFiles_success = include$1.success;

var CreateFromFiles_failure = include$1.failure;

var CreateFromFiles_reducer = include$1.reducer;

var CreateFromFiles_Status = include$1.Status;

var CreateFromFiles = {
  Failure: Failure,
  initialState: CreateFromFiles_initialState,
  start: CreateFromFiles_start,
  success: CreateFromFiles_success,
  failure: CreateFromFiles_failure,
  reducer: CreateFromFiles_reducer,
  Status: CreateFromFiles_Status
};

var Success = { };

function make$5(x) {
  return /* `Blobs */[
          -936778666,
          /* `Fetch */[
            287802394,
            x
          ]
        ];
}

var include$2 = FeaturesUtils.Operation({
      make: make$5
    });

var Fetch_initialState = include$2.initialState;

var Fetch_start = include$2.start;

var Fetch_success = include$2.success;

var Fetch_failure = include$2.failure;

var Fetch_reducer = include$2.reducer;

var Fetch_Status = include$2.Status;

var Fetch = {
  Success: Success,
  initialState: Fetch_initialState,
  start: Fetch_start,
  success: Fetch_success,
  failure: Fetch_failure,
  reducer: Fetch_reducer,
  Status: Fetch_Status
};

var Blobs = {
  make: make$3,
  CreateFromFiles: CreateFromFiles,
  Fetch: Fetch
};

var Operations = {
  $$Blob: $$Blob,
  Blobs: Blobs
};

var UploadT = { };

export {
  $$Error ,
  WindowDragAndDrop ,
  Operations ,
  UploadT ,
  
}
/* make Not a pure module */
