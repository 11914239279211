import { connect } from "react-redux";
import { partial } from "ramda";

import { phylogeneticTreeDataSelector } from "features/phylogeneticAnalysis";

import PhylogeneticTree from "./PhylogeneticTree";

function mapStateToProps(state, { term, dependencyPaths }) {
  const getDataForChart = partial(phylogeneticTreeDataSelector, [
    state,
    term,
    dependencyPaths
  ]);

  return { getDataForChart };
}

const enhance = connect(mapStateToProps);

export default enhance(PhylogeneticTree);
