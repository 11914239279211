

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Import from "../../../features/import.bs.js";
import * as Blob$SolvuuApi from "solvuu-api/./blob.bs.js";

function useBlob(blobId, instructions, apiClient) {
  var match = React.useState((function () {
          return /* Initial */418396260;
        }));
  var setState = match[1];
  var state = match[0];
  React.useEffect((function () {
          if (state === 418396260) {
            Curry._1(setState, (function (param) {
                    return /* Loading */-700189092;
                  }));
            var $$let = Blob$SolvuuApi.Get_by_id.$$Request;
            var $$let$1 = Blob$SolvuuApi.Get_by_id.$$Response;
            Import.$great$great$pipe(Curry._3(apiClient.f, /* POST */891112544, {
                      entity: Blob$SolvuuApi.Get_by_id.entity,
                      service: Blob$SolvuuApi.Get_by_id.service,
                      $$Request: {
                        path: $$let.path,
                        body: $$let.body,
                        info: $$let.info
                      },
                      $$Response: {
                        ok_of_json: $$let$1.ok_of_json,
                        json_of_ok: $$let$1.json_of_ok,
                        bad_request_of_json: $$let$1.bad_request_of_json,
                        json_of_bad_request: $$let$1.json_of_bad_request,
                        not_found_of_json: $$let$1.not_found_of_json,
                        json_of_not_found: $$let$1.json_of_not_found
                      }
                    }, {
                      id: blobId,
                      instructions: instructions
                    }), (function (response) {
                    if (typeof response === "number") {
                      return Curry._1(setState, (function (param) {
                                    return /* `Error */[
                                            106380200,
                                            "Unauthorized"
                                          ];
                                  }));
                    } else {
                      var info = response[1].info;
                      var variant = info[0];
                      if (variant !== -99004555) {
                        if (variant >= 17692) {
                          var ok = info[1];
                          return Curry._1(setState, (function (param) {
                                        return /* `Blob */[
                                                737307005,
                                                ok
                                              ];
                                      }));
                        } else {
                          return Curry._1(setState, (function (param) {
                                        return /* `Error */[
                                                106380200,
                                                "Not Found"
                                              ];
                                      }));
                        }
                      } else {
                        return Curry._1(setState, (function (param) {
                                      return /* `Error */[
                                              106380200,
                                              "Bad Request"
                                            ];
                                    }));
                      }
                    }
                  }));
          }
          return ;
        }), /* array */[state]);
  return state;
}

export {
  useBlob ,
  
}
/* react Not a pure module */
