/**
 * Module for managing the entity creation flow.
 *
 * Entity creation form is the primary way of adding new data to user account.
 * For simple cases, it allows for selecting type, name, and value.
 * E.g. type = Term, name = meaning_of_life, value = 42.
 *
 * It also handles more complex input, like options for uploading blobs (via create/blobs).
 */
import { assocPath, pickAll } from "ramda";
import { push } from "connected-react-router";

import { createReducer } from "features/utils";
import { locationSelector } from "features/routing";
import * as PathUtils from "lib/solvuu/pathUtils.bs";
import { terms } from "lib/solvuu/termEval";
import * as Paths from "Paths";

import { reconcileEntity, extractErrorMessageFromCreateResponse } from "..";
import { createEntityOperation } from "../operations";
import { createAndBindBlobs, inferenceInProgressSelector } from "./blobs";

// CONSTANTS
export const PROJECT = "Project";
export const projectFields = [
  "projectTitle",
  "projectAbstract",
  "projectLead",
  "projectInformaticsContact",
  "projectDataHandoff",
  "projectAnalysisType",
  "projectPlatform",
  "projectStatus"
];
export const creatableTypes = {
  BLOBS: "Blobs",
  RECORD: "Record",
  PROJECT: PROJECT,
  TABLE: "Table",
  TERM: "Term"
};

const assert = console.assert;

// ACTION TYPES

const ENTITY_CREATOR_FORM_CHANGE = "ENTITIES/ENTITY_CREATOR_FORM_CHANGE";
const ENTITY_CREATOR_FORM_TEARDOWN = "ENTITIES/ENTITY_CREATOR_FORM_TEARDOWN";

// ACTIONS

/**
 * Redirects to entity creator form with root path and entity type preselected.
 */
export function entityCreatorFormInit(rootPath, type) {
  return async function(dispatch, getState) {
    dispatch(entityCreatorFormChange(["type"], type));

    const creatorPath = Paths.entityCreate(rootPath);
    if (locationSelector(getState()).pathname !== creatorPath) {
      dispatch(push(creatorPath));
    }
  };
}

export function entityCreatorFormTeardown() {
  return {
    type: ENTITY_CREATOR_FORM_TEARDOWN
  };
}

export function entityCreatorFormChange(field, value) {
  assert(Array.isArray(field), "Field definition must be an array");

  return {
    type: ENTITY_CREATOR_FORM_CHANGE,
    payload: { field, value }
  };
}

export function createEntity(root) {
  const { start, success, failure } = createEntityOperation.actionCreators;

  function termContents(state) {
    const { value } = entityCreatorFormSelector(state);
    return terms.solvuu(value);
  }

  function initialRecordContents(state) {
    const form = entityCreatorFormSelector(state);
    if (form.type === creatableTypes.PROJECT) {
      const formValues = { ...form, projectStatus: "ONGOING" };
      const allFormValues = pickAll(projectFields, formValues);
      return terms.project(allFormValues);
    }

    return null;
  }

  return async function(dispatch, getState, { solvuuApi }) {
    const { name, type } = entityCreatorFormSelector(getState());
    if (type === creatableTypes.BLOBS) {
      return dispatch(createAndBindBlobs(root));
    }

    const path = `${root}/${name}`;
    const solvuuPath = PathUtils.pathToSolvuu(path);
    dispatch(start({ path }));

    const creatingDirectory =
      type === creatableTypes.RECORD || type === creatableTypes.PROJECT;
    const request = creatingDirectory
      ? solvuuApi.createDirectory(solvuuPath, initialRecordContents(getState()))
      : solvuuApi.createTerm(solvuuPath, termContents(getState()));
    const response = await request;
    if (response.ok) {
      await dispatch(reconcileEntity(root));
      dispatch(success({ path }));
      dispatch(push(Paths.entity(path)));
    } else {
      const message = extractErrorMessageFromCreateResponse(response);
      dispatch(failure([{ message, field: null }]));
    }
  };
}

// INITIAL STATE

export const initialState = {
  type: PROJECT
};

// SELECTORS

export function entityCreatorFormSelector(state) {
  return state.entities.creatorForm;
}

export function entityCreatorMetaSelector(state) {
  const create = state.entities.meta.create;
  const inferenceInProgress = inferenceInProgressSelector(state);
  return { create, inferenceInProgress };
}

// STATE TRANSFORMATIONS

function resetFormState(state, action) {
  return initialState;
}

function setFormFieldFromAction(state, action) {
  const { field, value } = action.payload;
  return assocPath(field, value, state);
}

// REDUCERS

export default createReducer(initialState, {
  [ENTITY_CREATOR_FORM_CHANGE]: setFormFieldFromAction,
  [ENTITY_CREATOR_FORM_TEARDOWN]: resetFormState
});
