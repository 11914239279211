import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import SolvuuPropTypes from "lib/solvuu/solvuuPropTypes";
import * as Root$SolvuuApi from "solvuu-api/./root.bs.js";

import Form from "components/Form";
import FormGroup from "components/FormGroup/FormGroup.js";
import FormatEditorModal from "components/FormatEditorModal";
import * as Loading from "components/Loading.bs";

import * as FileDrop from "app/FileDropRedux.bs";
import * as UploadRow from "app/UploadRowRedux.bs";

import styles from "./style.css";

class FormatField extends Component {
  static propTypes = {
    fileName: PropTypes.string.isRequired,
    format: SolvuuPropTypes.format.isRequired,
    inference: PropTypes.shape({}).isRequired,
    onChange: PropTypes.func.isRequired
  };

  state = {
    editing: false
  };

  handleToggleEditing = () => {
    this.setState(state => ({ editing: !state.editing }));
  };

  handleSave = format => {
    this.props.onChange(format);
    this.setState({ editing: false });
  };

  renderContents() {
    const { format, inference } = this.props;

    if (inference.pending) {
      return (
        <Fragment>
          <FormattedMessage id="entityCreator.fields.blobs.format.inferring" />
          <Loading.Dots.make />
        </Fragment>
      );
    }

    return (
      <Fragment>
        {Root$SolvuuApi.Fmt.to_string_hum(format)}
        <button
          type="button"
          onClick={this.handleToggleEditing}
          className={styles.formatFieldEdit}
        >
          <FormattedMessage id="entityCreator.fields.blobs.format.edit" />
        </button>
      </Fragment>
    );
  }

  renderModal() {
    const { format, fileName } = this.props;
    const { editing } = this.state;

    if (!editing) return null;

    return (
      <FormatEditorModal
        fileName={fileName}
        format={format}
        onSave={this.handleSave}
        onClose={this.handleToggleEditing}
      />
    );
  }

  render() {
    return (
      <div className={styles.formatField}>
        <div className={styles.formatFieldContents}>
          {this.renderContents()}
        </div>
        {this.renderModal()}
      </div>
    );
  }
}

const blobPropType = PropTypes.shape({
  id: PropTypes.string.isRequired, // transient blob ID
  blobId: PropTypes.string, // concrete blob ID
  file: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  path: PropTypes.string,
  format: SolvuuPropTypes.format.isRequired,
  meta: PropTypes.shape({
    inferFormat: PropTypes.shape({}).isRequired
  }).isRequired
}).isRequired;

class BlobForm extends Component {
  static propTypes = {
    blob: blobPropType,
    dismissBlob: PropTypes.func.isRequired
  };

  handleBlobDismiss = () => {
    this.props.dismissBlob(this.props.blob.id);
  };

  render() {
    const { blob } = this.props;

    return (
      <div className={styles.blob}>
        <div className={styles.blobField}>
          <FormGroup
            name={`blobs.${blob.id}.name`}
            label={<FormattedMessage id="entityCreator.fields.blobs.name" />}
            required
          />
        </div>
        <div className={styles.blobField}>
          <Form.Consumer>
            {({ onFieldChange }) => (
              <FormatField
                fileName={blob.name}
                format={blob.format}
                inference={blob.meta.inferFormat}
                onChange={format =>
                  onFieldChange(`blobs.${blob.id}.format`, format)
                }
              />
            )}
          </Form.Consumer>
        </div>
        <div className={styles.blobActions}>
          <button type="button" onClick={this.handleBlobDismiss}>
            &times;
          </button>
        </div>
      </div>
    );
  }
}

export default class BlobsForm extends Component {
  static propTypes = {
    blobs: PropTypes.arrayOf(blobPropType).isRequired,
    dismissBlob: PropTypes.func.isRequired,
    rootPath: PropTypes.string.isRequired
  };

  renderForm(blob) {
    return <BlobForm blob={blob} dismissBlob={this.props.dismissBlob} />;
  }

  renderUpload(blob) {
    const { name, path, blobId } = blob;
    return <UploadRow.make id={blobId} location={{ name, path }} />;
  }

  renderBlob(blob) {
    if (blob.startedUploading) {
      return this.renderUpload(blob);
    } else {
      return this.renderForm(blob);
    }
  }

  render() {
    const { blobs, rootPath } = this.props;

    return (
      <div>
        {blobs.map(blob => (
          <Fragment key={blob.id}>
            {this.renderBlob(blob)}
            <hr />
          </Fragment>
        ))}
        <FileDrop.make entityPath={rootPath} />
      </div>
    );
  }
}
