

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as React from "react";
import * as StyleCss from "./style.css";

var styles = StyleCss.default;

function FullWidthCard(Props) {
  var children = Props.children;
  var match = Props.expandVertically;
  var expandVertically = match !== undefined ? match : false;
  var className = Cn.make(/* :: */[
        styles.fullWidthCard,
        /* :: */[
          Cn.ifTrue(styles.fullWidthCardExpandVertically, expandVertically),
          /* [] */0
        ]
      ]);
  return React.createElement("div", {
              className: className
            }, children);
}

var make = FullWidthCard;

export {
  styles ,
  make ,
  
}
/* styles Not a pure module */
