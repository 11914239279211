// https://bl.ocks.org/tophtucker/62f93a4658387bb61e4510c37e2e97cf
export default function measureText(string, fontSize = 14) {
  const widths = [
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0.25625,
    0.23125,
    0.34375,
    0.5921875,
    0.59375,
    0.7328125,
    0.6421875,
    0.2,
    0.2484375,
    0.2484375,
    0.340625,
    0.5,
    0.23125,
    0.3,
    0.23125,
    0.296875,
    0.6125,
    0.3390625,
    0.5890625,
    0.5578125,
    0.5578125,
    0.590625,
    0.5921875,
    0.515625,
    0.5828125,
    0.5921875,
    0.225,
    0.23125,
    0.5,
    0.5,
    0.5,
    0.4625,
    0.7828125,
    0.6578125,
    0.6296875,
    0.6765625,
    0.7,
    0.5703125,
    0.5515625,
    0.7140625,
    0.7125,
    0.2390625,
    0.475,
    0.6015625,
    0.49375,
    0.809375,
    0.7078125,
    0.765625,
    0.5875,
    0.765625,
    0.609375,
    0.5859375,
    0.5703125,
    0.7015625,
    0.6578125,
    0.8828125,
    0.653125,
    0.6265625,
    0.5859375,
    0.24375,
    0.296875,
    0.24375,
    0.4328125,
    0.5703125,
    0.2265625,
    0.528125,
    0.575,
    0.496875,
    0.575,
    0.5640625,
    0.3390625,
    0.575,
    0.553125,
    0.225,
    0.3234375,
    0.5140625,
    0.225,
    0.8078125,
    0.553125,
    0.571875,
    0.571875,
    0.571875,
    0.3296875,
    0.465625,
    0.2953125,
    0.5515625,
    0.490625,
    0.734375,
    0.4875,
    0.490625,
    0.471875,
    0.2609375,
    0.2109375,
    0.2609375,
    0.503125
  ];
  const avg = 0.49883223684210515;
  return (
    string
      .split("")
      .map(c =>
        c.charCodeAt(0) < widths.length ? widths[c.charCodeAt(0)] : avg
      )
      .reduce((cur, acc) => acc + cur, 0) * fontSize
  );
}
