

import * as Link from "../../../Link.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Flags from "../../../../flags.bs.js";
import * as React from "react";
import * as Import from "../../../../features/import.bs.js";
import * as $$String from "bs-platform/lib/es6/string.js";
import * as WithTestId from "../../../WithTestId.bs.js";
import * as ReactIntl from "react-intl";
import * as StyleCss from "./style.css";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as ReactExtras from "../../../../lib/solvuu/ReactExtras.bs.js";
import * as IdenticonJs from "identicon.js";
import * as OnClickOutside from "../../../../components/OnClickOutside/OnClickOutside.bs.js";

var styles = StyleCss.default;

var iconDropdownWhite = require("icons/icon-chevron-down-white.svg");

var iconDropdownBlack = require("icons/icon-chevron-down-black.svg");

function NavbarActionsDropdown$UserIdenticon(Props) {
  var username = Props.username;
  var hash = username.padEnd(15, "dna");
  var src = "data:image/svg+xml;base64," + new IdenticonJs.default(hash, {
          format: "svg",
          size: 24,
          margin: 0,
          foreground: /* array */[
            245,
            245,
            245,
            255
          ],
          background: /* array */[
            0,
            0,
            0,
            0
          ]
        }).toString();
  return React.createElement("img", {
              className: styles.identicon,
              alt: username,
              src: src
            });
}

var UserIdenticon = {
  make: NavbarActionsDropdown$UserIdenticon
};

function useMenu(param) {
  var match = React.useState((function () {
          return false;
        }));
  var setState = match[1];
  var closeMenu = function (param) {
    return Curry._1(setState, (function (param) {
                  return false;
                }));
  };
  var toggleMenu = function (param) {
    return Curry._1(setState, (function (state) {
                  return !state;
                }));
  };
  return /* tuple */[
          match[0],
          closeMenu,
          toggleMenu
        ];
}

function NavbarActionsDropdown(Props) {
  var profile = Props.profile;
  var logout = Props.logout;
  var background = Props.background;
  var match = useMenu(/* () */0);
  var toggleMenu = match[2];
  var closeMenu = match[1];
  var isMenuOpen = match[0];
  var restricted = function (menuItem) {
    if (Flags.enableAllSaasFeatures) {
      return Caml_option.some(menuItem);
    }
    
  };
  var key = function (menuItem) {
    if (typeof menuItem === "number") {
      if (menuItem !== 742332284) {
        if (menuItem >= 796400751) {
          return "admin";
        } else {
          return "solvuuQuery";
        }
      } else {
        return "analysis";
      }
    } else {
      return "settings";
    }
  };
  var menuItems = Curry._2(Import.List.map, Curry._2(Import.List.keepMap, /* :: */[
            /* `Settings */[
              -454084349,
              /* Profile */-1063620343
            ],
            /* :: */[
              Flags.enableAllSaasFeatures ? /* Query */-250086680 : undefined,
              /* :: */[
                Flags.enableAllSaasFeatures ? /* Analysis */742332284 : undefined,
                /* :: */[
                  Belt_Option.flatMap(undefined, restricted),
                  /* [] */0
                ]
              ]
            ]
          ], (function (x) {
              return x;
            })), (function (menuItem) {
          var key$1 = key(menuItem);
          return React.createElement(WithTestId.make, {
                      id: "navigation[" + (key$1 + "]"),
                      children: React.createElement(Link.make, {
                            to: menuItem,
                            className: styles.dropdownMenuItem,
                            onClick: closeMenu,
                            children: React.createElement(ReactIntl.FormattedMessage, {
                                  id: "navigation." + key$1
                                })
                          }),
                      key: key$1
                    });
        }));
  var profileSection;
  if (typeof profile === "number" || profile[0] < 94326179) {
    profileSection = null;
  } else {
    var profile$1 = profile[1];
    var email = Belt_Option.getWithDefault(profile$1.email, "");
    if (email === "") {
      profileSection = null;
    } else {
      var name = Belt_Option.getWithDefault(profile$1.name, "");
      var tmp = name === "" ? profile$1.username : name;
      profileSection = React.createElement(WithTestId.make, {
            id: "navbar-actions-profile-section",
            children: React.createElement("div", {
                  className: styles.dropdownProfileSection
                }, React.createElement("div", {
                      className: styles.dropdownProfileName
                    }, tmp), React.createElement("div", {
                      className: styles.dropdownProfileEmail
                    }, email))
          });
    }
  }
  var logoutButton = React.createElement(WithTestId.make, {
        id: "navigation[logout]",
        children: React.createElement("button", {
              key: "logout",
              className: styles.dropdownMenuItem,
              type: "button",
              onClick: (function (param) {
                  return Curry._1(logout, /* () */0);
                })
            }, React.createElement(ReactIntl.FormattedMessage, {
                  id: "navigation.logout"
                }))
      });
  return React.createElement(OnClickOutside.make, {
              onClickOutside: (function (param) {
                  return Curry._1(closeMenu, /* () */0);
                }),
              children: (function (containerRef) {
                  var tmp;
                  if (typeof profile === "number" || profile[0] !== 94326179) {
                    tmp = null;
                  } else {
                    var username = Belt_Option.getWithDefault(profile[1].name, "");
                    tmp = username === "" ? null : React.createElement(NavbarActionsDropdown$UserIdenticon, {
                            username: username
                          });
                  }
                  var match = $$String.equal(background, "dark");
                  return React.createElement("div", {
                              ref: containerRef,
                              className: styles.root
                            }, React.createElement(WithTestId.make, {
                                  id: "navbar-actions-dropdown-button",
                                  children: React.createElement("button", {
                                        className: styles.button,
                                        type: "button",
                                        onClick: (function (param) {
                                            return Curry._1(toggleMenu, /* () */0);
                                          })
                                      }, React.createElement("div", {
                                            className: styles.buttonInner
                                          }, React.createElement("div", {
                                                className: styles.avatar
                                              }, tmp), React.createElement("img", {
                                                alt: "more actions",
                                                src: match ? iconDropdownWhite : iconDropdownBlack
                                              })))
                                }), isMenuOpen ? React.createElement(WithTestId.make, {
                                    id: "navbar-actions-dropdown-menu",
                                    children: React.createElement("div", {
                                          className: styles.dropdownMenu
                                        }, profileSection, ReactExtras.listToElement(menuItems), logoutButton)
                                  }) : null);
                })
            });
}

var make = NavbarActionsDropdown;

export {
  styles ,
  iconDropdownWhite ,
  iconDropdownBlack ,
  UserIdenticon ,
  useMenu ,
  make ,
  
}
/* styles Not a pure module */
