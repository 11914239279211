

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Js_mapperRt from "bs-platform/lib/es6/js_mapperRt.js";

var Failure = { };

var UserAttribute = { };

var AuthenticationDetails = { };

function signUp(t, username, password, attributes, validationData, callback) {
  return new Promise((function (resolve, param) {
                t.signUp(username, password, attributes, validationData, (function (x, y) {
                        return resolve(Curry._2(callback, x, y));
                      }));
                return /* () */0;
              }));
}

var UserPool = {
  signUp: signUp
};

var JwtToken = { };

var AccessToken = { };

var Session = { };

var MFAParam = { };

function make_unsafe(x) {
  return x;
}

var MFACode = {
  make_unsafe: make_unsafe
};

var jsMapperConstantArray = /* array */[
  /* tuple */[
    4151929,
    "SMS"
  ],
  /* tuple */[
    50085628,
    "EMAIL"
  ]
];

function deliveryMediumToJs(param) {
  return Js_mapperRt.binarySearch(2, param, jsMapperConstantArray);
}

function deliveryMediumFromJs(param) {
  return Js_mapperRt.revSearch(2, jsMapperConstantArray, param);
}

var MFAOption = {
  deliveryMediumToJs: deliveryMediumToJs,
  deliveryMediumFromJs: deliveryMediumFromJs
};

function sendMFACode(user, mfaCode, onSuccess, onFailure) {
  return new Promise((function (resolve, param) {
                user.sendMFACode(mfaCode, {
                      onSuccess: (function (x) {
                          return resolve(Curry._1(onSuccess, x));
                        }),
                      onFailure: (function (x) {
                          return resolve(Curry._1(onFailure, x));
                        })
                    });
                return /* () */0;
              }));
}

function authenticateUser(cognitoUser, details, onSuccess, onFailure, onMFARequired) {
  return new Promise((function (resolve, param) {
                cognitoUser.authenticateUser(details, {
                      onSuccess: (function (x) {
                          return resolve(Curry._1(onSuccess, x));
                        }),
                      onFailure: (function (x) {
                          return resolve(Curry._1(onFailure, x));
                        }),
                      mfaRequired: (function (x, y) {
                          return resolve(Curry._2(onMFARequired, x, y));
                        })
                    });
                return /* () */0;
              }));
}

function forgotPassword(user, onSuccess, onFailure) {
  return new Promise((function (resolve, param) {
                user.forgotPassword({
                      onSuccess: (function (x) {
                          return resolve(Curry._1(onSuccess, x));
                        }),
                      onFailure: (function (x) {
                          return resolve(Curry._1(onFailure, x));
                        })
                    });
                return /* () */0;
              }));
}

function confirmPassword(user, code, password, onSuccess, onFailure) {
  return new Promise((function (resolve, param) {
                user.confirmPassword(code, password, {
                      onSuccess: (function (param) {
                          return resolve(Curry._1(onSuccess, /* () */0));
                        }),
                      onFailure: (function (x) {
                          return resolve(Curry._1(onFailure, x));
                        })
                    });
                return /* () */0;
              }));
}

function cognitoToPromise(f) {
  return new Promise((function (resolve, param) {
                return Curry._1(f, (function (err, response) {
                              if (err == null) {
                                return resolve(/* `Ok */[
                                            17724,
                                            response
                                          ]);
                              } else {
                                return resolve(/* `Error */[
                                            106380200,
                                            err
                                          ]);
                              }
                            }));
              }));
}

var Private = {
  cognitoToPromise: cognitoToPromise
};

function getUserAttributes(t) {
  return cognitoToPromise((function (callback) {
                t.getUserAttributes(callback);
                return /* () */0;
              }));
}

function updateAttributes(t, list) {
  return cognitoToPromise((function (callback) {
                t.updateAttributes(list, callback);
                return /* () */0;
              }));
}

function changePassword(t, old, new_) {
  return cognitoToPromise((function (callback) {
                t.changePassword(old, new_, callback);
                return /* () */0;
              }));
}

function getMFAOptions(t) {
  return cognitoToPromise((function (callback) {
                t.getMFAOptions(callback);
                return /* () */0;
              }));
}

function enableMFA(t) {
  return cognitoToPromise((function (callback) {
                t.enableMFA(callback);
                return /* () */0;
              }));
}

function disableMFA(t) {
  return cognitoToPromise((function (callback) {
                t.disableMFA(callback);
                return /* () */0;
              }));
}

var User = {
  sendMFACode: sendMFACode,
  authenticateUser: authenticateUser,
  forgotPassword: forgotPassword,
  confirmPassword: confirmPassword,
  Private: Private,
  getUserAttributes: getUserAttributes,
  updateAttributes: updateAttributes,
  changePassword: changePassword,
  getMFAOptions: getMFAOptions,
  enableMFA: enableMFA,
  disableMFA: disableMFA
};

export {
  Failure ,
  UserAttribute ,
  AuthenticationDetails ,
  UserPool ,
  JwtToken ,
  AccessToken ,
  Session ,
  MFAParam ,
  MFACode ,
  MFAOption ,
  User ,
  
}
/* No side effect */
