import { connect } from "react-redux";
import { equals } from "ramda";

import * as UploadRow from "containers/Upload/UploadRow/UploadRow.bs";
import {
  uploadSelector,
  uploadProgressPercentageSelector
} from "features/upload";

function mapStateToProps(state, { id }) {
  const upload = uploadSelector(state, id);
  const progressPercentage = uploadProgressPercentageSelector(state, id);
  return {
    ...upload,
    meta: {
      ...upload.meta,
      progressPercentage
    }
  };
}

const options = {
  // Performance optimization - don't rerender when mapStateToProps hasn't changed.
  // Helps when there are many UploadRows visible at a time.
  areStatePropsEqual: equals
};

const enhance = connect(
  mapStateToProps,
  null,
  null,
  options
);

export default enhance(UploadRow.make);
