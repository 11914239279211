

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Import from "../../features/import.bs.js";
import * as Krona from "components/Krona";

function ofNode(node) {
  var value = node.value;
  var label = node.label;
  var children = Curry._1(Import.List.toArray, Curry._2(Import.List.map, node.children, ofNode));
  return {
          value: value,
          label: label,
          children: children
        };
}

function toNode(data) {
  var incorporateFlatItem = function (root, flatItem) {
    var match = flatItem.labels;
    var children;
    if (match) {
      var label = match[0];
      var newFlat_value = flatItem.value;
      var newFlat_labels = match[1];
      var newFlat = {
        value: newFlat_value,
        labels: newFlat_labels
      };
      var match$1 = Curry._2(Import.List.getBy, root.children, (function (child) {
              return child.label === label;
            }));
      if (match$1 !== undefined) {
        var updatedChild = incorporateFlatItem(match$1, newFlat);
        children = Curry._2(Import.List.map, root.children, (function (child) {
                var match = child.label === updatedChild.label;
                if (match) {
                  return updatedChild;
                } else {
                  return child;
                }
              }));
      } else {
        var newChild = incorporateFlatItem({
              value: 0,
              label: label,
              children: /* [] */0
            }, newFlat);
        children = Curry._2(Import.List.concat, root.children, /* :: */[
              newChild,
              /* [] */0
            ]);
      }
    } else {
      children = root.children;
    }
    var value = root.value + flatItem.value | 0;
    return {
            value: value,
            label: root.label,
            children: children
          };
  };
  return Curry._3(Import.List.reduce, data, {
              value: 0,
              label: "Root",
              children: /* [] */0
            }, incorporateFlatItem);
}

function makeProps(data, param) {
  var node = toNode(data);
  return {
          data: ofNode(node)
        };
}

var Props = { };

var make = Krona.default;

export {
  Props ,
  makeProps ,
  make ,
  toNode ,
  
}
/* make Not a pure module */
