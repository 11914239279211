

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Import from "../../features/import.bs.js";
import * as TermJs from "./termJs.bs.js";
import * as TermEval from "./termEval.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Pervasives from "bs-platform/lib/es6/pervasives.js";
import * as Caml_string from "bs-platform/lib/es6/caml_string.js";
import * as Lang$SolvuuApi from "solvuu-api/./lang.bs.js";
import * as IndexedDataframeAccess from "../../features/biom/indexedDataframeAccess.bs.js";
import * as PhylogeneticAnalysis from "features/phylogeneticAnalysis";

function blobIdOfFormatExpression(_term) {
  while(true) {
    var term = _term;
    if (typeof term === "number") {
      return ;
    } else {
      var variant = term[0];
      if (variant >= 106380201) {
        if (variant !== 295523485) {
          if (variant !== 669191267) {
            return ;
          }
          
        } else {
          return term[1];
        }
      } else if (variant !== 3257473) {
        if (variant < 106380200) {
          return ;
        }
        
      } else {
        var match = term[1];
        var match$1 = match[0];
        if (typeof match$1 === "number") {
          _term = match[1];
          continue ;
        } else if (match$1[0] !== 4298439) {
          _term = match[1];
          continue ;
        } else {
          var match$2 = match[1];
          if (typeof match$2 === "number") {
            _term = match[1];
            continue ;
          } else {
            var variant$1 = match$2[0];
            var exit = 0;
            if (variant$1 >= 295523486) {
              if (variant$1 !== 669191267) {
                if (variant$1 !== 847309489) {
                  _term = match[1];
                  continue ;
                } else {
                  var arg = match$2[1];
                  if (arg) {
                    var match$3 = arg[0];
                    var match$4 = match$3[0];
                    if (typeof match$4 === "number" || match$4[0] !== -300665356) {
                      return ;
                    } else {
                      var match$5 = match$3[1];
                      var exit$1 = 0;
                      var exit$2 = 0;
                      var match$6 = arg[1];
                      if (match$6) {
                        var match$7 = match$6[0];
                        var match$8 = match$7[0];
                        if (typeof match$8 === "number" || match$8[0] !== -300665356) {
                          exit$2 = 4;
                        } else {
                          var match$9 = match$7[1];
                          if (typeof match$9 === "number") {
                            exit$2 = 4;
                          } else {
                            var variant$2 = match$9[0];
                            var exit$3 = 0;
                            if (variant$2 !== 106380200) {
                              if (variant$2 !== 295523485) {
                                if (variant$2 !== 669191267) {
                                  exit$2 = 4;
                                } else {
                                  exit$3 = 5;
                                }
                              } else {
                                var id = match$9[1];
                                if (match$6[1]) {
                                  exit$2 = 4;
                                } else {
                                  return id;
                                }
                              }
                            } else {
                              exit$3 = 5;
                            }
                            if (exit$3 === 5) {
                              var match$10 = match$9[1];
                              if (typeof match$10 === "number" || match$10[0] !== 295523485) {
                                exit$2 = 4;
                              } else {
                                var id$1 = match$10[1];
                                if (match$6[1]) {
                                  exit$2 = 4;
                                } else {
                                  return id$1;
                                }
                              }
                            }
                            
                          }
                        }
                      } else {
                        exit$2 = 4;
                      }
                      if (exit$2 === 4) {
                        if (typeof match$5 === "number") {
                          exit$1 = 3;
                        } else {
                          var variant$3 = match$5[0];
                          var exit$4 = 0;
                          if (variant$3 !== 106380200) {
                            if (variant$3 !== 295523485) {
                              if (variant$3 !== 669191267) {
                                exit$1 = 3;
                              } else {
                                exit$4 = 5;
                              }
                            } else {
                              var id$2 = match$5[1];
                              return id$2;
                            }
                          } else {
                            exit$4 = 5;
                          }
                          if (exit$4 === 5) {
                            var match$11 = match$5[1];
                            if (typeof match$11 === "number" || match$11[0] !== 295523485) {
                              exit$1 = 3;
                            } else {
                              var id$3 = match$11[1];
                              return id$3;
                            }
                          }
                          
                        }
                      }
                      if (exit$1 === 3) {
                        var match$12 = arg[1];
                        if (match$12) {
                          var match$13 = match$12[0];
                          var match$14 = match$13[0];
                          if (typeof match$14 === "number" || match$14[0] !== -300665356) {
                            return ;
                          } else {
                            var e = match$13[1];
                            if (typeof e === "number" || e[0] !== 3257473 || match$12[1]) {
                              return ;
                            } else {
                              _term = e;
                              continue ;
                            }
                          }
                        } else {
                          return ;
                        }
                      }
                      
                    }
                  } else {
                    return ;
                  }
                }
              } else {
                exit = 2;
              }
            } else if (variant$1 !== 106380200) {
              if (variant$1 >= 295523485) {
                return match$2[1];
              } else {
                _term = match[1];
                continue ;
              }
            } else {
              exit = 2;
            }
            if (exit === 2) {
              var match$15 = match$2[1];
              if (typeof match$15 === "number") {
                _term = match[1];
                continue ;
              } else if (match$15[0] !== 295523485) {
                _term = match[1];
                continue ;
              } else {
                return match$15[1];
              }
            }
            
          }
        }
      }
      var match$16 = term[1];
      if (typeof match$16 === "number" || match$16[0] !== 295523485) {
        return ;
      } else {
        return match$16[1];
      }
    }
  };
}

function isBiomDataframeTerm(term) {
  if (typeof term === "number" || term[0] !== -976970511) {
    return false;
  } else {
    var string_ = term[1];
    var match = string_.startsWith("{") && string_.includes("\"data\"");
    if (match) {
      try {
        IndexedDataframeAccess.dataframeFromJs(Curry._1(TermEval.Evaluations.BiomDataframe.ofTerm, term));
        return true;
      }
      catch (exn){
        return false;
      }
    } else {
      return false;
    }
  }
}

function isNewickTerm(term) {
  var newick;
  try {
    newick = Curry._1(TermEval.Evaluations.Newick.ofTerm, term);
  }
  catch (exn){
    return false;
  }
  if (newick.startsWith("(") && newick.length > 10) {
    return PhylogeneticAnalysis.isNewickString(newick);
  } else {
    return false;
  }
}

function blobIdOfTerm(termJs) {
  var term = TermJs.convertFromJs(termJs);
  var match = blobIdOfFormatExpression(term);
  if (match !== undefined) {
    return match;
  } else {
    var termString = TermJs.stringify(TermJs.convertToJs(term));
    return Pervasives.failwith("Cannot extract blob id from term: " + termString);
  }
}

function hasBlobIdInTerm(termJs) {
  var term = TermJs.convertFromJs(termJs);
  return blobIdOfFormatExpression(term) !== undefined;
}

function isTermLoaded(termJs) {
  var term = TermJs.convertFromJs(termJs);
  if (typeof term === "number") {
    return true;
  } else {
    return term[0] !== -216449554;
  }
}

function decodeBiomDataframe(termJs) {
  return Curry._1(TermEval.Evaluations.BiomDataframe.ofTerm, TermJs.convertFromJs(termJs));
}

function decodeNewickData(termJs) {
  return Curry._1(TermEval.Evaluations.Newick.ofTerm, TermJs.convertFromJs(termJs));
}

function blobId(id) {
  return TermJs.convertToJs(/* `Blob_id */[
              295523485,
              id
            ]);
}

function termId(id) {
  return TermJs.convertToJs(/* `Term_id */[
              -216449554,
              id
            ]);
}

function string(value) {
  return TermJs.convertToJs(/* `String */[
              -976970511,
              value
            ]);
}

function $$float(value) {
  return TermJs.convertToJs(/* `Float */[
              365180284,
              value
            ]);
}

function $$int(value) {
  return TermJs.convertToJs(/* `Int */[
              3654863,
              value
            ]);
}

function bool(value) {
  return TermJs.convertToJs(/* `Bool */[
              737456202,
              value
            ]);
}

function $$char(value) {
  return TermJs.convertToJs(/* `Char */[
              748194550,
              Caml_string.get(value, 0)
            ]);
}

function solvuu(value) {
  return TermJs.convertToJs(/* `Solvuu */[
              -522825178,
              value
            ]);
}

var $$null = TermJs.convertToJs(/* Null */870828711);

function some(term) {
  return TermJs.convertToJs(/* `Some */[
              925978388,
              TermJs.convertFromJs(term)
            ]);
}

function record(labels, terms) {
  var contents = Curry._2(Import.List.map, Curry._1(Import.List.fromArray, Belt_Array.zip(labels, terms)), (function (param) {
          return /* tuple */[
                  param[0],
                  TermJs.convertFromJs(param[1])
                ];
        }));
  return TermJs.convertToJs(Curry._1(Lang$SolvuuApi.Term.record, contents));
}

function list(terms) {
  var contents = Curry._2(Import.List.map, Curry._1(Import.List.fromArray, terms), TermJs.convertFromJs);
  return TermJs.convertToJs(/* `List */[
              848054398,
              contents
            ]);
}

function dir(id, labels, terms) {
  var data = !(labels == null) && !(terms == null) ? Curry._2(Import.List.map, Curry._1(Import.List.fromArray, Belt_Array.zip(labels, terms)), (function (param) {
            return /* tuple */[
                    /* `Named */[
                      699531161,
                      param[0]
                    ],
                    TermJs.convertFromJs(param[1])
                  ];
          })) : undefined;
  var contents = {
    id: id,
    data: data
  };
  return TermJs.convertToJs(/* `Dir */[
              3405101,
              contents
            ]);
}

function biomData(data) {
  return TermJs.convertToJs(Curry._1(TermEval.Evaluations.BiomDataframe.toTerm, data));
}

var Re = {
  blobIdOfFormatExpression: blobIdOfFormatExpression,
  isBiomDataframeTerm: isBiomDataframeTerm,
  isNewickTerm: isNewickTerm
};

var Terms = {
  blobId: blobId,
  termId: termId,
  string: string,
  $$float: $$float,
  $$int: $$int,
  bool: bool,
  $$char: $$char,
  solvuu: solvuu,
  some: some,
  $$null: $$null,
  list: list,
  record: record,
  dir: dir,
  biomData: biomData
};

export {
  Re ,
  blobIdOfTerm ,
  hasBlobIdInTerm ,
  isTermLoaded ,
  decodeBiomDataframe ,
  decodeNewickData ,
  Terms ,
  
}
/* null Not a pure module */
