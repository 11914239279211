import { connect } from "react-redux";

import {
  requestEntityDownload,
  entityDownloadPermissionSelector
} from "features/entities/download";

import EntityActionsDropdown from "containers/Entities/EntityActionsDropdown/EntityActionsDropdown.js";

function mapStateToProps(state, { entityPath }) {
  const downloadPermission = entityDownloadPermissionSelector(
    state,
    entityPath
  );

  return {
    meta: {
      permissions: {
        download: downloadPermission
      }
    }
  };
}

const mapDispatchToProps = {
  requestEntityDownload
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EntityActionsDropdown);
