

import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Flags from "./flags.bs.js";
import * as Import from "./features/import.bs.js";

function dashboard(username) {
  return "/";
}

function profile(username) {
  return "/" + (String(username) + "");
}

function entity(path) {
  return path;
}

function entityCreate(path) {
  return "" + (String(path) + "/~create");
}

function charts(inputType) {
  return "/charts/" + (String(inputType) + "");
}

function charts2(inputType) {
  return "/charts2/" + (String(inputType) + "");
}

function jobShow(jobId) {
  return "/jobs/" + (String(jobId) + "");
}

function jobOutputEntity(jobId, path) {
  return "/jobs/" + (String(jobId) + ("/results" + (String(path) + "")));
}

function uploadShow(blobId) {
  return "/drive/" + (String(blobId) + "");
}

function scienceItem(item) {
  return "/science/" + (String(item) + "");
}

function blogPost(slug) {
  return "/blog/" + (String(slug) + "");
}

function blogCategory(category) {
  return "/blog/categories/" + (String(category) + "");
}

function getPublicPageURL(path) {
  if (Flags.enablePublicPages) {
    return path;
  } else {
    return Curry._1(Import.sprintf(/* Format */[
                    /* String_literal */Block.__(11, [
                        "https://solvuu.com",
                        /* String */Block.__(2, [
                            /* No_padding */0,
                            /* End_of_format */0
                          ])
                      ]),
                    "https://solvuu.com%s"
                  ]), path);
  }
}

var admin = "/admin";

var analysis = "/analysis";

var root = "/";

var chartsRoot = "/charts";

var chartsRoot2 = "/charts2";

var login = "/login";

var login2 = "/login2";

var registration = "/registration";

var confirmRegistration = "/registration/confirm";

var passwordResetRequest = "/password/request";

var passwordResetUpdate = "/password/update";

var settings = "/settings";

var solvuuQuery = "/query";

var jobs = "/jobs";

var upload = "/drive";

var company = "/company";

var product = "/product";

var science = "/science";

var blog = "/blog";

var scienceExchange = "https://www.scienceexchange.com/labs/solvuu";

var termsOfUse = "/terms";

var privacyPolicy = "/privacy";

export {
  admin ,
  analysis ,
  root ,
  dashboard ,
  profile ,
  entity ,
  entityCreate ,
  chartsRoot ,
  chartsRoot2 ,
  charts ,
  charts2 ,
  login ,
  login2 ,
  registration ,
  confirmRegistration ,
  passwordResetRequest ,
  passwordResetUpdate ,
  settings ,
  solvuuQuery ,
  jobs ,
  jobShow ,
  jobOutputEntity ,
  upload ,
  uploadShow ,
  company ,
  product ,
  science ,
  scienceItem ,
  blog ,
  blogPost ,
  blogCategory ,
  scienceExchange ,
  termsOfUse ,
  privacyPolicy ,
  getPublicPageURL ,
  
}
/* Flags Not a pure module */
