

import * as Err from "../components/Err.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Import from "../features/import.bs.js";
import * as Record from "../containers/Entities/Record/Record.bs.js";
import * as Select from "../components/Select/Select.bs.js";
import * as $$String from "bs-platform/lib/es6/string.js";
import * as Loading from "../components/Loading.bs.js";
import * as Checkbox from "../components/FormControls/Checkbox.bs.js";
import * as FormGroup from "../components/FormGroup/FormGroup.bs.js";
import * as WithTestId from "../containers/WithTestId.bs.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as MultiSelect from "../components/Select/MultiSelect.bs.js";
import * as ReactExtras from "../lib/solvuu/ReactExtras.bs.js";
import * as ReactSelect from "../lib/ReactSelect.bs.js";
import * as ValueInputs from "./ValueInputs.bs.js";
import * as Lang$SolvuuApi from "solvuu-api/./lang.bs.js";
import * as Root$SolvuuApi from "solvuu-api/./root.bs.js";
import * as VerticalLayout from "../components/Layout/VerticalLayout.bs.js";
import * as TermRenderContext from "../lang/TermRenderContext.bs.js";
import * as Layout from "components/Layout";
import * as TableHeatmapCss from "./TableHeatmap.css";
import * as TableVisualizationCard from "./TableVisualizationCard.bs.js";

var styles = TableHeatmapCss.default;

function TableHeatmap$Loading(Props) {
  return React.createElement("div", {
              className: styles.centered
            }, React.createElement(Loading.make, { }));
}

var clusteringMethodSelectOptions_000 = ReactSelect.$$Option.make("Hierarchical", React.createElement(ReactIntl.FormattedMessage, {
          id: "table.heatmap.clustering.method.hierarchical"
        }));

var clusteringMethodSelectOptions_001 = /* :: */[
  ReactSelect.$$Option.make("KMeans", React.createElement(ReactIntl.FormattedMessage, {
            id: "table.heatmap.clustering.method.kmeans"
          })),
  /* [] */0
];

var clusteringMethodSelectOptions = /* :: */[
  clusteringMethodSelectOptions_000,
  clusteringMethodSelectOptions_001
];

var linkageSelectOptions_000 = ReactSelect.$$Option.make("Mean", React.createElement(ReactIntl.FormattedMessage, {
          id: "table.heatmap.clustering.linkage.mean"
        }));

var linkageSelectOptions_001 = /* :: */[
  ReactSelect.$$Option.make("Complete", React.createElement(ReactIntl.FormattedMessage, {
            id: "table.heatmap.clustering.linkage.complete"
          })),
  /* :: */[
    ReactSelect.$$Option.make("Single", React.createElement(ReactIntl.FormattedMessage, {
              id: "table.heatmap.clustering.linkage.single"
            })),
    /* :: */[
      ReactSelect.$$Option.make("Centroid", React.createElement(ReactIntl.FormattedMessage, {
                id: "table.heatmap.clustering.linkage.centroid"
              })),
      /* :: */[
        ReactSelect.$$Option.make("Ward", React.createElement(ReactIntl.FormattedMessage, {
                  id: "table.heatmap.clustering.linkage.ward"
                })),
        /* [] */0
      ]
    ]
  ]
];

var linkageSelectOptions = /* :: */[
  linkageSelectOptions_000,
  linkageSelectOptions_001
];

var distanceSelectOptions_000 = ReactSelect.$$Option.make("Euclidean", React.createElement(ReactIntl.FormattedMessage, {
          id: "table.heatmap.clustering.distance.euclidean"
        }));

var distanceSelectOptions_001 = /* :: */[
  ReactSelect.$$Option.make("Manhattan", React.createElement(ReactIntl.FormattedMessage, {
            id: "table.heatmap.clustering.distance.manhattan"
          })),
  /* :: */[
    ReactSelect.$$Option.make("Maximum", React.createElement(ReactIntl.FormattedMessage, {
              id: "table.heatmap.clustering.distance.maximum"
            })),
    /* :: */[
      ReactSelect.$$Option.make("PearsonCorrelation", React.createElement(ReactIntl.FormattedMessage, {
                id: "table.heatmap.clustering.distance.pearson"
              })),
      /* :: */[
        ReactSelect.$$Option.make("SpearmanCorrelation", React.createElement(ReactIntl.FormattedMessage, {
                  id: "table.heatmap.clustering.distance.spearman"
                })),
        /* :: */[
          ReactSelect.$$Option.make("KendallCorrelation", React.createElement(ReactIntl.FormattedMessage, {
                    id: "table.heatmap.clustering.distance.kendall"
                  })),
          /* [] */0
        ]
      ]
    ]
  ]
];

var distanceSelectOptions = /* :: */[
  distanceSelectOptions_000,
  distanceSelectOptions_001
];

var initialHierarchicalClustering = Curry._2(Lang$SolvuuApi.Record.tagged, "Hierarchical", /* `Record */[
      847309489,
      Curry._2(Root$SolvuuApi.Heatmap.Clustering.Hierarchical.to_record, "Euclidean", "Mean")
    ]);

var initialKMeansClustering = Curry._2(Lang$SolvuuApi.Record.tagged, "KMeans", /* `Record */[
      847309489,
      Curry._2(Root$SolvuuApi.Heatmap.Clustering.KMeans.to_record, "Euclidean", 3)
    ]);

function TableHeatmap$ClusteringControls$ClusteringEnabledCheckbox(Props) {
  var clustering = Props.clustering;
  var checkboxLabel = Props.checkboxLabel;
  var updateArgLabel = Props.updateArgLabel;
  var dispatch = Props.dispatch;
  var onChange = function (checked) {
    return Curry._1(dispatch, /* `UpdateArg */[
                615103405,
                /* tuple */[
                  updateArgLabel,
                  (function (param) {
                      if (checked) {
                        return /* `Some */[
                                925978388,
                                /* `Record */[
                                  847309489,
                                  initialHierarchicalClustering
                                ]
                              ];
                      } else {
                        return /* Null */870828711;
                      }
                    })
                ]
              ]);
  };
  return React.createElement(Layout.VerticalLayoutItem, {
              children: React.createElement(Checkbox.make, {
                    label: checkboxLabel,
                    name: "clusteringEnabled",
                    value: Belt_Option.isSome(clustering),
                    onChange: onChange
                  })
            });
}

function TableHeatmap$ClusteringControls$MethodControls(Props) {
  var clustering = Props.clustering;
  var updateArgLabel = Props.updateArgLabel;
  var dispatch = Props.dispatch;
  if (clustering !== undefined) {
    var match = Curry._2(Lang$SolvuuApi.Record.Param.Tagged.parse, clustering, Lang$SolvuuApi.Record.Param.Arg_type.data);
    if (match[0] >= 106380200) {
      return React.createElement(Err.Operation.make, {
                  message: "Invaild record arg"
                });
    } else {
      var onChange = function (opt) {
        return Curry._1(dispatch, /* `UpdateArg */[
                    615103405,
                    /* tuple */[
                      updateArgLabel,
                      (function (param) {
                          var match = Belt_Option.map(opt, (function (param) {
                                  return param.value;
                                }));
                          if (match !== undefined && clustering !== undefined) {
                            var record = clustering;
                            var method_ = match;
                            var match$1 = Curry._2(Lang$SolvuuApi.Record.Param.Tagged.parse, record, Lang$SolvuuApi.Record.Param.Arg_type.data);
                            var isClusteringTag = match$1[0] >= 106380200 ? false : $$String.equal(match$1[1][0], method_);
                            var tmp;
                            if (isClusteringTag) {
                              tmp = record;
                            } else {
                              switch (method_) {
                                case "Hierarchical" :
                                    tmp = initialHierarchicalClustering;
                                    break;
                                case "KMeans" :
                                    tmp = initialKMeansClustering;
                                    break;
                                default:
                                  tmp = record;
                              }
                            }
                            return /* `Some */[
                                    925978388,
                                    /* `Record */[
                                      847309489,
                                      tmp
                                    ]
                                  ];
                          } else {
                            return /* Null */870828711;
                          }
                        })
                    ]
                  ]);
      };
      var label = React.createElement(ReactIntl.FormattedMessage, {
            id: "table.heatmap.clustering.method.label"
          });
      return React.createElement(Layout.VerticalLayoutItem, {
                  children: React.createElement(Select.make, {
                        label: label,
                        value: match[1][0],
                        onChange: onChange,
                        options: clusteringMethodSelectOptions
                      })
                });
    }
  } else {
    return null;
  }
}

function TableHeatmap$ClusteringControls$DistanceControls(Props) {
  var clustering = Props.clustering;
  var updateArgLabel = Props.updateArgLabel;
  var dispatch = Props.dispatch;
  if (clustering !== undefined) {
    var component = function (distance) {
      var onChange = function (opt) {
        var distance = Belt_Option.getWithDefault(Belt_Option.map(opt, (function (param) {
                    return param.value;
                  })), "");
        return Curry._1(dispatch, /* `UpdateArg */[
                    615103405,
                    /* tuple */[
                      updateArgLabel,
                      (function (param) {
                          if (clustering !== undefined) {
                            var record = clustering;
                            var res = Curry._3(Root$SolvuuApi.Heatmap.Clustering.of_record, record, (function (param, linkage) {
                                    return Curry._2(Lang$SolvuuApi.Record.tagged, "Hierarchical", /* `Record */[
                                                847309489,
                                                Curry._2(Root$SolvuuApi.Heatmap.Clustering.Hierarchical.to_record, distance, linkage)
                                              ]);
                                  }), (function (param, numClusters) {
                                    return Curry._2(Lang$SolvuuApi.Record.tagged, "KMeans", /* `Record */[
                                                847309489,
                                                Curry._2(Root$SolvuuApi.Heatmap.Clustering.KMeans.to_record, distance, numClusters)
                                              ]);
                                  }));
                            return /* `Some */[
                                    925978388,
                                    /* `Record */[
                                      847309489,
                                      res[0] >= 106380200 ? record : res[1]
                                    ]
                                  ];
                          } else {
                            return /* Null */870828711;
                          }
                        })
                    ]
                  ]);
      };
      var label = React.createElement(ReactIntl.FormattedMessage, {
            id: "table.heatmap.clustering.distance.label"
          });
      return React.createElement(Layout.VerticalLayoutItem, {
                  children: React.createElement(Select.make, {
                        label: label,
                        value: distance,
                        onChange: onChange,
                        options: distanceSelectOptions
                      })
                });
    };
    var res = Curry._3(Root$SolvuuApi.Heatmap.Clustering.of_record, clustering, (function (distance, linkage) {
            if (linkage === "Ward") {
              return null;
            } else {
              return component(distance);
            }
          }), (function (distance, param) {
            return component(distance);
          }));
    if (res[0] >= 106380200) {
      return null;
    } else {
      return res[1];
    }
  } else {
    return null;
  }
}

function TableHeatmap$ClusteringControls$MethodRelatedControls(Props) {
  var clustering = Props.clustering;
  var updateArgLabel = Props.updateArgLabel;
  var dispatch = Props.dispatch;
  if (clustering !== undefined) {
    var res = Curry._3(Root$SolvuuApi.Heatmap.Clustering.of_record, clustering, (function (param, linkage) {
            var onChange = function (selectedOption) {
              var linkage = Belt_Option.getWithDefault(Belt_Option.map(selectedOption, (function (param) {
                          return param.value;
                        })), "");
              return Curry._1(dispatch, /* `UpdateArg */[
                          615103405,
                          /* tuple */[
                            updateArgLabel,
                            (function (param) {
                                if (clustering !== undefined) {
                                  var record = clustering;
                                  var res = Curry._3(Root$SolvuuApi.Heatmap.Clustering.of_record, record, (function (distance, param) {
                                          return Curry._2(Lang$SolvuuApi.Record.tagged, "Hierarchical", /* `Record */[
                                                      847309489,
                                                      Curry._2(Root$SolvuuApi.Heatmap.Clustering.Hierarchical.to_record, distance, linkage)
                                                    ]);
                                        }), (function (param, param$1) {
                                          return record;
                                        }));
                                  return /* `Some */[
                                          925978388,
                                          /* `Record */[
                                            847309489,
                                            res[0] >= 106380200 ? record : res[1]
                                          ]
                                        ];
                                } else {
                                  return /* Null */870828711;
                                }
                              })
                          ]
                        ]);
            };
            var label = React.createElement(ReactIntl.FormattedMessage, {
                  id: "table.heatmap.clustering.linkage.label"
                });
            return React.createElement(Layout.VerticalLayoutItem, {
                        children: React.createElement(Select.make, {
                              label: label,
                              value: linkage,
                              onChange: onChange,
                              options: linkageSelectOptions
                            })
                      });
          }), (function (param, numClusters) {
            var onChange = function (numClusters) {
              return Curry._1(dispatch, /* `UpdateArg */[
                          615103405,
                          /* tuple */[
                            updateArgLabel,
                            (function (param) {
                                if (clustering !== undefined) {
                                  var record = clustering;
                                  var res = Curry._3(Root$SolvuuApi.Heatmap.Clustering.of_record, record, (function (param, param$1) {
                                          return record;
                                        }), (function (distance, param) {
                                          return Curry._2(Lang$SolvuuApi.Record.tagged, "KMeans", /* `Record */[
                                                      847309489,
                                                      Curry._2(Root$SolvuuApi.Heatmap.Clustering.KMeans.to_record, distance, numClusters)
                                                    ]);
                                        }));
                                  return /* `Some */[
                                          925978388,
                                          /* `Record */[
                                            847309489,
                                            res[0] >= 106380200 ? record : res[1]
                                          ]
                                        ];
                                } else {
                                  return /* Null */870828711;
                                }
                              })
                          ]
                        ]);
            };
            return React.createElement(Layout.VerticalLayoutItem, {
                        children: React.createElement("div", {
                              className: styles.inputWrapper
                            }, React.createElement("div", {
                                  className: styles.inputLabel
                                }, React.createElement(ReactIntl.FormattedMessage, {
                                      id: "table.heatmap.clustering.numClusters.label"
                                    })), React.createElement(ValueInputs.Int.make, {
                                  value: numClusters,
                                  onChange: onChange,
                                  className: styles.input,
                                  invalidClassName: styles.inputInvalid
                                }))
                      });
          }));
    if (res[0] >= 106380200) {
      return null;
    } else {
      return res[1];
    }
  } else {
    return null;
  }
}

function TableHeatmap$ClusteringControls(Props) {
  var clustering = Props.clustering;
  var updateArgLabel = Props.updateArgLabel;
  var checkboxLabel = Props.checkboxLabel;
  var dispatch = Props.dispatch;
  return React.createElement(Layout.VerticalLayout, VerticalLayout.makeProps(/* x2 */26810, null, /* () */0), React.createElement(TableHeatmap$ClusteringControls$ClusteringEnabledCheckbox, {
                  clustering: clustering,
                  checkboxLabel: checkboxLabel,
                  updateArgLabel: updateArgLabel,
                  dispatch: dispatch
                }), React.createElement(TableHeatmap$ClusteringControls$MethodControls, {
                  clustering: clustering,
                  updateArgLabel: updateArgLabel,
                  dispatch: dispatch
                }), React.createElement(TableHeatmap$ClusteringControls$DistanceControls, {
                  clustering: clustering,
                  updateArgLabel: updateArgLabel,
                  dispatch: dispatch
                }), React.createElement(TableHeatmap$ClusteringControls$MethodRelatedControls, {
                  clustering: clustering,
                  updateArgLabel: updateArgLabel,
                  dispatch: dispatch
                }));
}

function TableHeatmap$YAxisClusteringControls(Props) {
  var yAxisClustering = Props.yAxisClustering;
  var dispatch = Props.dispatch;
  return React.createElement(TableHeatmap$ClusteringControls, {
              clustering: yAxisClustering,
              updateArgLabel: Root$SolvuuApi.Heatmap.Arg.Const.Label.yAxisClustering,
              checkboxLabel: React.createElement(ReactIntl.FormattedMessage, {
                    id: "table.heatmap.yAxisClustering.enabled"
                  }),
              dispatch: dispatch
            });
}

function TableHeatmap$XAxisClusteringControls(Props) {
  var xAxisClustering = Props.xAxisClustering;
  var dispatch = Props.dispatch;
  return React.createElement(TableHeatmap$ClusteringControls, {
              clustering: xAxisClustering,
              updateArgLabel: Root$SolvuuApi.Heatmap.Arg.Const.Label.xAxisClustering,
              checkboxLabel: React.createElement(ReactIntl.FormattedMessage, {
                    id: "table.heatmap.xAxisClustering.enabled"
                  }),
              dispatch: dispatch
            });
}

function TableHeatmap$ShowOriginalDataCheckbox(Props) {
  var showOriginalData = Props.showOriginalData;
  var dispatch = Props.dispatch;
  var onChange = function (value) {
    return Curry._1(dispatch, /* `UpdateArg */[
                615103405,
                /* tuple */[
                  Root$SolvuuApi.Heatmap.Arg.Const.Label.showOriginalData,
                  (function (param) {
                      return /* `Bool */[
                              737456202,
                              value
                            ];
                    })
                ]
              ]);
  };
  var label = React.createElement(ReactIntl.FormattedMessage, {
        id: "table.heatmap.visual.showOriginalData.enabled"
      });
  var info = React.createElement(ReactIntl.FormattedMessage, {
        id: "table.heatmap.visual.showOriginalData.info"
      });
  return React.createElement(FormGroup.$$Element.make, {
              name: "showOriginalData",
              children: null
            }, React.createElement(Checkbox.make, {
                  label: label,
                  name: "showOriginalData",
                  value: showOriginalData,
                  onChange: onChange
                }), React.createElement(FormGroup.FieldInfoElement.make, {
                  children: info
                }));
}

function TableHeatmap$YAxisColumnSelect(Props) {
  var yAxisColumnName = Props.yAxisColumnName;
  var columns = Props.columns;
  var dispatch = Props.dispatch;
  var options = Curry._2(Import.List.map, Curry._2(Import.List.keep, columns, (function (param) {
              return Curry._1(Root$SolvuuApi.Heatmap.Arg.yAxisDataTypeAvailable, param[1]);
            })), (function (param) {
          var name = param[0];
          return ReactSelect.$$Option.make(name, name);
        }));
  var onChange = function (opt) {
    return Curry._1(dispatch, /* `UpdateArg */[
                615103405,
                /* tuple */[
                  Root$SolvuuApi.Heatmap.Arg.Const.Label.yAxisColumnName,
                  (function (param) {
                      var match = Belt_Option.map(opt, (function (param) {
                              return param.value;
                            }));
                      if (match !== undefined) {
                        return /* `Some */[
                                925978388,
                                /* `String */[
                                  -976970511,
                                  match
                                ]
                              ];
                      } else {
                        return /* Null */870828711;
                      }
                    })
                ]
              ]);
  };
  var label = React.createElement(ReactIntl.FormattedMessage, {
        id: "table.heatmap.yAxisSelect.label"
      });
  var info = React.createElement(ReactIntl.FormattedMessage, {
        id: "table.heatmap.yAxisSelect.info"
      });
  return React.createElement(FormGroup.$$Element.make, {
              name: "yAxisColumn",
              children: null
            }, React.createElement(WithTestId.make, {
                  id: "heatmap-y-axis-select",
                  children: React.createElement(Select.make, {
                        label: label,
                        value: Belt_Option.getWithDefault(yAxisColumnName, ""),
                        onChange: onChange,
                        options: options
                      })
                }), React.createElement(FormGroup.FieldInfoElement.make, {
                  children: info
                }));
}

function TableHeatmap$XAxisColumnsSelect(Props) {
  var xAxisColumnNames = Props.xAxisColumnNames;
  var columns = Props.columns;
  var dispatch = Props.dispatch;
  var options = Curry._2(Import.List.map, Curry._2(Import.List.keep, columns, (function (param) {
              return Curry._1(Root$SolvuuApi.Heatmap.Arg.xAxisDataTypeAvailable, param[1]);
            })), (function (param) {
          var name = param[0];
          return ReactSelect.$$Option.make(name, name);
        }));
  var onChange = function (opt) {
    return Curry._1(dispatch, /* `UpdateArg */[
                615103405,
                /* tuple */[
                  Root$SolvuuApi.Heatmap.Arg.Const.Label.xAxisColumnNames,
                  (function (param) {
                      if (opt !== undefined) {
                        return /* `List */[
                                848054398,
                                Curry._2(Import.List.map, opt, (function (param) {
                                        return /* `String */[
                                                -976970511,
                                                param.value
                                              ];
                                      }))
                              ];
                      } else {
                        return /* `List */[
                                848054398,
                                /* [] */0
                              ];
                      }
                    })
                ]
              ]);
  };
  var label = React.createElement(ReactIntl.FormattedMessage, {
        id: "table.heatmap.xAxisSelect.label"
      });
  var info = React.createElement(ReactIntl.FormattedMessage, {
        id: "table.heatmap.xAxisSelect.info"
      });
  var info2 = React.createElement(ReactIntl.FormattedMessage, {
        id: "table.heatmap.xAxisSelect.info2"
      });
  return React.createElement(FormGroup.$$Element.make, {
              name: "xAxisColumn",
              children: null
            }, React.createElement(MultiSelect.make, {
                  label: label,
                  value: xAxisColumnNames,
                  onChange: onChange,
                  options: options,
                  removeSelected: true,
                  closeOnSelect: false,
                  testId: "heatmap-x-axis-select"
                }), React.createElement(FormGroup.FieldInfoElement.make, {
                  children: info
                }), React.createElement(FormGroup.FieldInfoElement.make, {
                  children: info2
                }));
}

function TableHeatmap$State(Props) {
  var onClose = Props.onClose;
  var state = Props.state;
  var dataType = Props.dataType;
  var dispatch = Props.dispatch;
  var graphic = state.graphic;
  if (typeof dataType !== "number" && dataType[0] === 848054398) {
    var match = dataType[1];
    if (typeof match !== "number" && match[0] === 847309489) {
      var columns = match[1][0];
      return React.createElement(Record.ParsedRecord.make, {
                  children: Curry._2(Root$SolvuuApi.Heatmap.Arg.of_record, state.arg, (function (yAxisColumnName, xAxisColumnNames, yAxisClustering, xAxisClustering, showOriginalData, param) {
                          var title = React.createElement(ReactIntl.FormattedMessage, {
                                id: "table.heatmap.header"
                              });
                          var controls = React.createElement(Layout.VerticalLayout, VerticalLayout.makeProps(/* x4 */26812, null, /* () */0), React.createElement(Layout.VerticalLayoutItem, {
                                    children: React.createElement(TableHeatmap$YAxisColumnSelect, {
                                          yAxisColumnName: yAxisColumnName,
                                          columns: columns,
                                          dispatch: dispatch
                                        })
                                  }), React.createElement(Layout.VerticalLayoutItem, {
                                    children: React.createElement(TableHeatmap$XAxisColumnsSelect, {
                                          xAxisColumnNames: xAxisColumnNames,
                                          columns: columns,
                                          dispatch: dispatch
                                        })
                                  }), React.createElement(Layout.VerticalLayoutItem, {
                                    children: React.createElement(TableHeatmap$YAxisClusteringControls, {
                                          yAxisClustering: yAxisClustering,
                                          dispatch: dispatch
                                        })
                                  }), React.createElement(Layout.VerticalLayoutItem, {
                                    children: React.createElement(TableHeatmap$XAxisClusteringControls, {
                                          xAxisClustering: xAxisClustering,
                                          dispatch: dispatch
                                        })
                                  }), React.createElement(Layout.VerticalLayoutItem, {
                                    children: React.createElement(TableHeatmap$ShowOriginalDataCheckbox, {
                                          showOriginalData: showOriginalData,
                                          dispatch: dispatch
                                        })
                                  }));
                          return React.createElement(TableVisualizationCard.make, {
                                      icon: /* Heatmap */988997452,
                                      title: title,
                                      controls: controls,
                                      onClose: onClose,
                                      children: ReactExtras.withTestId("entity-static-table-heatmap", React.createElement("div", {
                                                className: styles.root
                                              }, graphic))
                                    });
                        }))
                });
    }
    
  }
  return React.createElement(Err.Operation.make, {
              message: "Expected table"
            });
}

function TableHeatmap(Props) {
  var onClose = Props.onClose;
  var data = Props.data;
  var $$eval = Props.eval;
  var apiClient = Props.apiClient;
  var renderTerm = Props.renderTerm;
  var term = data[0];
  var state_arg = Curry._3(Lang$SolvuuApi.Record.update, Root$SolvuuApi.Heatmap.Arg.Const.Default.arg, Root$SolvuuApi.Heatmap.Arg.Const.Label.data, (function (param) {
          return term;
        }));
  var state = {
    arg: state_arg,
    graphic: null
  };
  var match = React.useReducer((function (param, param$1) {
          var apiClient$1 = apiClient;
          var renderTerm$1 = renderTerm;
          var param$2 = param;
          var $$event = param$1;
          var state = param$2[0];
          var evalEffect = function (arg) {
            return /* `Evaluate */[
                    -620618631,
                    /* `App */[
                      3257473,
                      /* tuple */[
                        /* `Var */[
                          4298439,
                          Root$SolvuuApi.heat_map
                        ],
                        /* `Record */[
                          847309489,
                          arg
                        ]
                      ]
                    ]
                  ];
          };
          if ($$event[0] >= 615103405) {
            var match = $$event[1];
            var state_arg = Curry._3(Lang$SolvuuApi.Record.update, state.arg, match[0], match[1]);
            var state_graphic = React.createElement(TableHeatmap$Loading, { });
            var state$1 = {
              arg: state_arg,
              graphic: state_graphic
            };
            var effect = evalEffect(state_arg);
            return /* tuple */[
                    state$1,
                    effect
                  ];
          } else {
            var graphic = Curry._6(renderTerm$1, apiClient$1, $$event[1], /* Type_not_available */-883458824, TermRenderContext.root, undefined, /* () */0);
            var state_arg$1 = state.arg;
            var state$2 = {
              arg: state_arg$1,
              graphic: graphic
            };
            return /* tuple */[
                    state$2,
                    undefined
                  ];
          }
        }), /* tuple */[
        state,
        undefined
      ]);
  var dispatch = match[1];
  var match$1 = match[0];
  var effect = match$1[1];
  React.useEffect((function () {
          if (effect !== undefined) {
            Curry._1($$eval, effect[1]).then((function (term) {
                    Curry._1(dispatch, /* `Graphic */[
                          -1051164024,
                          term
                        ]);
                    return Promise.resolve(/* () */0);
                  }));
            return ;
          }
          
        }), /* array */[effect]);
  return React.createElement(TableHeatmap$State, {
              onClose: onClose,
              state: match$1[0],
              dataType: data[1],
              dispatch: dispatch
            });
}

var make = TableHeatmap;

export {
  make ,
  
}
/* styles Not a pure module */
