

import * as React from "react";
import * as ReactExtras from "../lib/solvuu/ReactExtras.bs.js";
import * as TableToolButtonCss from "./TableToolButton.css";

var styles = TableToolButtonCss.default;

var iconFilter = (require("icons/icon-filter-white.svg"));

var iconTools = (require("icons/icon-tools-white.svg"));

var iconTick = (require("icons/icon-tick-white.svg"));

function TableToolButton(Props) {
  var icon = Props.icon;
  var onClick = Props.onClick;
  var testId = Props.testId;
  var children = Props.children;
  var iconSrc;
  switch (icon) {
    case /* Filter */0 :
        iconSrc = iconFilter;
        break;
    case /* Visualization */1 :
        iconSrc = iconTools;
        break;
    case /* Statistic */2 :
        iconSrc = iconTick;
        break;
    
  }
  var iconElement = React.createElement("img", {
        className: styles.icon,
        alt: "",
        src: iconSrc
      });
  return ReactExtras.withOptionalTestId(testId, React.createElement("button", {
                  className: styles.root,
                  type: "button",
                  onClick: onClick
                }, iconElement, children));
}

var make = TableToolButton;

export {
  make ,
  
}
/* styles Not a pure module */
