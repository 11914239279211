import qs from "qs";
import { createSelector } from "reselect";
import { connectRouter, push, replace } from "connected-react-router";

export function stringifyQuery(query) {
  // By default, null values are included in the stringified query as empty strings.
  // We want to be strict about the difference between null and empty string,
  // so we're omitting the nulls altogether.
  return qs.stringify(query, { skipNulls: true });
}

export function parseQuery(queryString) {
  // We store the visualization columns in array form.
  // The number of columns can exceed the default limit of 20 imposed by qs library.
  return qs.parse(queryString, { arrayLimit: 100 });
}

export function parseReactRouterLocation(location) {
  const { pathname, hash, search: queryString, state } = location;
  // Slice off the ? at the beginning of query string
  const rawQueryString = queryString.slice(1);
  const query = parseQuery(rawQueryString);

  return { pathname, queryString, query, hash, state };
}

export function pushLocation({ pathname, query, state }) {
  const pushParams = {};
  if (pathname) pushParams.pathname = pathname;
  if (query) pushParams.search = stringifyQuery(query);
  if (state) pushParams.state = state;

  return push(pushParams);
}

export function replaceLocation({ query }) {
  const search = stringifyQuery(query);
  return replace({ search });
}

export const locationSelector = createSelector(
  state => state.router.location.pathname,
  state => state.router.location.hash,
  state => state.router.location.search,
  state => state.router.location.state,
  (pathname, hash, search, state) => {
    return parseReactRouterLocation({ pathname, hash, search, state });
  }
);

// TODO(ashish): Delete this whole file.
// Following commented out when I moved history from
// History to App.
// export default connectRouter(History.history);
