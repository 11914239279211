import React from "react";
import { AreaChart, Area } from "recharts";

export class OptimizedArea extends Area {
  static propTypes = null;

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.highlighted !== nextProps.highlighted) return true;
    if (this.props.muted !== nextProps.muted) return true;
    if (this.props.width !== nextProps.width) return true;
    return false;
  }

  render() {
    const { highlighted, muted } = this.props;

    return React.cloneElement(Area.prototype.render.call(this), {
      "data-is-highlighted": highlighted,
      "data-is-muted": muted
    });
  }
}

export class OptimizedAreaChart extends AreaChart {
  /* HACK: bypass expensive recharts calculations when data hasn't changed
   * This avoids calling of updateStateOfAxisMapsOffsetAndStackGroups
   * in https://github.com/recharts/recharts/blob/master/src/chart/generateCategoricalChart.js#L197
   */
  componentWillReceiveProps(nextProps) {
    if (
      this.props.data === nextProps.data &&
      this.props.width === nextProps.width
    ) {
      return;
    }
    return AreaChart.prototype.componentWillReceiveProps.call(this, nextProps);
  }
}
