import { Children } from "react";
import * as ReactUtils from "recharts/es6/util/ReactUtils";

export * from "recharts/es6/util/ReactUtils";

var findChildByTypeCache = new WeakMap();
export var findChildByType = function findChildByType(children, type) {
  if (!children) return null;
  if (!findChildByTypeCache.has(children)) {
    findChildByTypeCache.set(children, new WeakMap());
  }
  if (!findChildByTypeCache.get(children).has(type)) {
    var result = ReactUtils.findChildByType(children, type);
    findChildByTypeCache.get(children).set(type, result);
  }

  return findChildByTypeCache.get(children).get(type);
};

var parseChildIndexCache = new WeakMap();
export var parseChildIndex = function parseChildIndex(child, children) {
  if (!parseChildIndexCache.has(children)) {
    const indexMap = new WeakMap();
    Children.forEach(children, function(entry, index) {
      indexMap.set(entry, index);
    });
    parseChildIndexCache.set(children, indexMap);
  }
  return parseChildIndexCache.get(children).get(child) || -1;
};
