

import * as List from "bs-platform/lib/es6/list.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Button from "../components/Button.bs.js";
import * as Import from "../features/import.bs.js";
import * as Caml_obj from "bs-platform/lib/es6/caml_obj.js";
import * as Pervasives from "bs-platform/lib/es6/pervasives.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as ReactExtras from "../lib/solvuu/ReactExtras.bs.js";
import * as ReactSelect from "../lib/ReactSelect.bs.js";
import * as ValueInputs from "./ValueInputs.bs.js";
import * as ReactSelect$1 from "react-select";
import * as OnClickOutside from "../components/OnClickOutside/OnClickOutside.bs.js";
import * as VerticalLayout from "../components/Layout/VerticalLayout.bs.js";
import * as TableToolButton from "./TableToolButton.bs.js";
import * as HorizontalLayout from "../components/Layout/HorizontalLayout.bs.js";
import * as Layout from "components/Layout";
import * as TableFilters2Css from "./TableFilters2.css";
import * as FeaturesTableFilters from "../features/entities/tables/FeaturesTableFilters.bs.js";

var styles = TableFilters2Css.default;

var iconClose = (require("icons/icon-close-black.svg"));

((require("icons/icon-filter-white.svg")));

function TableFilters2$StringQuery(Props) {
  var value = Props.value;
  var onChange = Props.onChange;
  var handleChange = function ($$event) {
    return Curry._1(onChange, ReactExtras.eventTargetValue($$event));
  };
  return ReactExtras.withTestId("table-filter-criterion-query", React.createElement("input", {
                  className: styles.criterionQuery,
                  type: "text",
                  value: value,
                  onChange: handleChange
                }));
}

function valueToString(value) {
  return Curry._1(Import.List.toArray, value).join("\n");
}

function stringToValue(input) {
  return Curry._1(Import.List.fromArray, input.split("\n"));
}

function TableFilters2$StringListQuery(Props) {
  var value = Props.value;
  var onChange = Props.onChange;
  var handleChange = function ($$event) {
    var inputValue = ReactExtras.eventTargetValue($$event);
    var tmp = inputValue === "" ? /* [] */0 : stringToValue(inputValue);
    return Curry._1(onChange, tmp);
  };
  var inputValue = valueToString(value);
  var inputHint = String(Curry._1(Import.List.length, value)) + " items";
  return React.createElement("div", undefined, ReactExtras.withTestId("table-filter-criterion-query", React.createElement("textarea", {
                      className: styles.criterionQueryTextarea,
                      rows: 4,
                      value: inputValue,
                      onChange: handleChange
                    })), React.createElement("div", {
                  className: styles.criterionQueryTextareaHint
                }, inputHint));
}

function TableFilters2$Criterion(Props) {
  var criterion = Props.criterion;
  var columns = Props.columns;
  var onRemove = Props.onRemove;
  var onChangeScope = Props.onChangeScope;
  var onChangeMatcher = Props.onChangeMatcher;
  var matcher = criterion[1];
  var scope = criterion[0];
  var handleChangeStringQuery = function (query) {
    var tmp;
    if (typeof matcher === "number") {
      console.warn("Matcher does not support string queries");
      tmp = matcher;
    } else {
      switch (matcher.tag | 0) {
        case /* Contain */0 :
            tmp = /* Contain */Block.__(0, [query]);
            break;
        case /* Equal */1 :
            var match = matcher[0];
            if (typeof match === "number" || match[0] !== -976970511) {
              console.warn("Matcher does not support string queries");
              tmp = matcher;
            } else {
              tmp = /* Equal */Block.__(1, [/* `String */[
                    -976970511,
                    query
                  ]]);
            }
            break;
        case /* NotEqual */2 :
            var match$1 = matcher[0];
            if (typeof match$1 === "number" || match$1[0] !== -976970511) {
              console.warn("Matcher does not support string queries");
              tmp = matcher;
            } else {
              tmp = /* NotEqual */Block.__(2, [/* `String */[
                    -976970511,
                    query
                  ]]);
            }
            break;
        default:
          console.warn("Matcher does not support string queries");
          tmp = matcher;
      }
    }
    return Curry._1(onChangeMatcher, tmp);
  };
  var handleChangeStringListQuery = function (queries) {
    var tmp;
    if (typeof matcher === "number" || matcher.tag !== /* EqualAnyOf */7) {
      console.warn("Matcher does not support list of strings queries");
      tmp = matcher;
    } else {
      tmp = /* EqualAnyOf */Block.__(7, [queries]);
    }
    return Curry._1(onChangeMatcher, tmp);
  };
  var handleChangeFloatQuery = function (query) {
    var tmp;
    if (typeof matcher === "number") {
      console.warn("Matcher does not support float queries");
      tmp = matcher;
    } else {
      switch (matcher.tag | 0) {
        case /* Equal */1 :
            var match = matcher[0];
            if (typeof match === "number" || match[0] !== 365180284) {
              console.warn("Matcher does not support float queries");
              tmp = matcher;
            } else {
              tmp = /* Equal */Block.__(1, [/* `Float */[
                    365180284,
                    query
                  ]]);
            }
            break;
        case /* NotEqual */2 :
            var match$1 = matcher[0];
            if (typeof match$1 === "number" || match$1[0] !== 365180284) {
              console.warn("Matcher does not support float queries");
              tmp = matcher;
            } else {
              tmp = /* NotEqual */Block.__(2, [/* `Float */[
                    365180284,
                    query
                  ]]);
            }
            break;
        case /* GreaterThan */3 :
            tmp = /* GreaterThan */Block.__(3, [query]);
            break;
        case /* LessThan */4 :
            tmp = /* LessThan */Block.__(4, [query]);
            break;
        case /* GreaterThanOrEqualTo */5 :
            tmp = /* GreaterThanOrEqualTo */Block.__(5, [query]);
            break;
        case /* LessThanOrEqualTo */6 :
            tmp = /* LessThanOrEqualTo */Block.__(6, [query]);
            break;
        default:
          console.warn("Matcher does not support float queries");
          tmp = matcher;
      }
    }
    return Curry._1(onChangeMatcher, tmp);
  };
  var handleChangeIntQuery = function (query) {
    var tmp;
    if (typeof matcher === "number") {
      console.warn("Matcher does not support int queries");
      tmp = matcher;
    } else {
      switch (matcher.tag | 0) {
        case /* Equal */1 :
            var match = matcher[0];
            if (typeof match === "number" || match[0] !== 3654863) {
              console.warn("Matcher does not support int queries");
              tmp = matcher;
            } else {
              tmp = /* Equal */Block.__(1, [/* `Int */[
                    3654863,
                    query
                  ]]);
            }
            break;
        case /* NotEqual */2 :
            var match$1 = matcher[0];
            if (typeof match$1 === "number" || match$1[0] !== 3654863) {
              console.warn("Matcher does not support int queries");
              tmp = matcher;
            } else {
              tmp = /* NotEqual */Block.__(2, [/* `Int */[
                    3654863,
                    query
                  ]]);
            }
            break;
        default:
          console.warn("Matcher does not support int queries");
          tmp = matcher;
      }
    }
    return Curry._1(onChangeMatcher, tmp);
  };
  var findSelectedColumnType = function (scope) {
    if (scope) {
      var columnName = scope[0];
      return List.find((function (param) {
                      return param[0] === columnName;
                    }), columns)[1];
    }
    
  };
  var selectedType = findSelectedColumnType(scope);
  var stringQuery;
  var exit = 0;
  if (typeof matcher === "number") {
    stringQuery = "";
  } else {
    switch (matcher.tag | 0) {
      case /* Contain */0 :
          stringQuery = matcher[0];
          break;
      case /* Equal */1 :
      case /* NotEqual */2 :
          exit = 1;
          break;
      default:
        stringQuery = "";
    }
  }
  if (exit === 1) {
    var match = matcher[0];
    stringQuery = typeof match === "number" || match[0] !== -976970511 ? "" : match[1];
  }
  var floatQuery;
  var exit$1 = 0;
  if (typeof matcher === "number") {
    floatQuery = matcher === /* IsTrue */0 ? 0.0 : 0.0;
  } else {
    switch (matcher.tag | 0) {
      case /* Equal */1 :
      case /* NotEqual */2 :
          exit$1 = 1;
          break;
      case /* Contain */0 :
      case /* EqualAnyOf */7 :
          floatQuery = 0.0;
          break;
      default:
        floatQuery = matcher[0];
    }
  }
  if (exit$1 === 1) {
    var match$1 = matcher[0];
    if (typeof match$1 === "number") {
      floatQuery = 0.0;
    } else {
      var variant = match$1[0];
      floatQuery = variant !== 3654863 && variant !== 365180284 ? 0.0 : match$1[1];
    }
  }
  var intQuery;
  var exit$2 = 0;
  if (typeof matcher === "number") {
    intQuery = 0;
  } else {
    switch (matcher.tag | 0) {
      case /* Equal */1 :
      case /* NotEqual */2 :
          exit$2 = 1;
          break;
      case /* GreaterThan */3 :
      case /* LessThan */4 :
      case /* GreaterThanOrEqualTo */5 :
      case /* LessThanOrEqualTo */6 :
          intQuery = matcher[0] | 0;
          break;
      default:
        intQuery = 0;
    }
  }
  if (exit$2 === 1) {
    var match$2 = matcher[0];
    intQuery = typeof match$2 === "number" || match$2[0] !== 3654863 ? 0 : match$2[1];
  }
  var defaultMatcherForValue = function (value) {
    if (value !== undefined) {
      var invalid = value;
      switch (invalid) {
        case "contain" :
            return /* Contain */Block.__(0, [stringQuery]);
        case "equal" :
            if (selectedType !== undefined) {
              var match = selectedType;
              if (typeof match === "number") {
                if (match !== -976970511) {
                  if (match !== 3654863) {
                    if (match !== 365180284) {
                      return /* Equal */Block.__(1, [/* `String */[
                                  -976970511,
                                  stringQuery
                                ]]);
                    } else {
                      return /* Equal */Block.__(1, [/* `Float */[
                                  365180284,
                                  floatQuery
                                ]]);
                    }
                  } else {
                    return /* Equal */Block.__(1, [/* `Int */[
                                3654863,
                                intQuery
                              ]]);
                  }
                } else {
                  return /* Equal */Block.__(1, [/* `String */[
                              -976970511,
                              stringQuery
                            ]]);
                }
              } else if (match[0] !== 216537025) {
                return /* Equal */Block.__(1, [/* `String */[
                            -976970511,
                            stringQuery
                          ]]);
              } else {
                var match$1 = match[1];
                if (match$1 !== -976970511) {
                  if (match$1 !== 3654863) {
                    if (match$1 !== 365180284) {
                      return /* Equal */Block.__(1, [/* `String */[
                                  -976970511,
                                  stringQuery
                                ]]);
                    } else {
                      return /* Equal */Block.__(1, [/* `Float */[
                                  365180284,
                                  floatQuery
                                ]]);
                    }
                  } else {
                    return /* Equal */Block.__(1, [/* `Int */[
                                3654863,
                                intQuery
                              ]]);
                  }
                } else {
                  return /* Equal */Block.__(1, [/* `String */[
                              -976970511,
                              stringQuery
                            ]]);
                }
              }
            } else {
              return /* Equal */Block.__(1, [/* `String */[
                          -976970511,
                          stringQuery
                        ]]);
            }
        case "equalAnyOf" :
            return /* EqualAnyOf */Block.__(7, [/* [] */0]);
        case "false" :
            return /* IsFalse */1;
        case "greaterThan" :
            return /* GreaterThan */Block.__(3, [floatQuery]);
        case "greaterThanOrEqualTo" :
            return /* GreaterThanOrEqualTo */Block.__(5, [floatQuery]);
        case "lessThan" :
            return /* LessThan */Block.__(4, [floatQuery]);
        case "lessThanOrEqualTo" :
            return /* LessThanOrEqualTo */Block.__(6, [floatQuery]);
        case "notEqual" :
            if (selectedType !== undefined) {
              var match$2 = selectedType;
              if (typeof match$2 === "number") {
                if (match$2 !== -976970511) {
                  if (match$2 !== 3654863) {
                    if (match$2 !== 365180284) {
                      return /* NotEqual */Block.__(2, [/* `String */[
                                  -976970511,
                                  stringQuery
                                ]]);
                    } else {
                      return /* NotEqual */Block.__(2, [/* `Float */[
                                  365180284,
                                  floatQuery
                                ]]);
                    }
                  } else {
                    return /* NotEqual */Block.__(2, [/* `Int */[
                                3654863,
                                intQuery
                              ]]);
                  }
                } else {
                  return /* NotEqual */Block.__(2, [/* `String */[
                              -976970511,
                              stringQuery
                            ]]);
                }
              } else if (match$2[0] !== 216537025) {
                return /* NotEqual */Block.__(2, [/* `String */[
                            -976970511,
                            stringQuery
                          ]]);
              } else {
                var match$3 = match$2[1];
                if (match$3 !== -976970511) {
                  if (match$3 !== 3654863) {
                    if (match$3 !== 365180284) {
                      return /* NotEqual */Block.__(2, [/* `String */[
                                  -976970511,
                                  stringQuery
                                ]]);
                    } else {
                      return /* NotEqual */Block.__(2, [/* `Float */[
                                  365180284,
                                  floatQuery
                                ]]);
                    }
                  } else {
                    return /* NotEqual */Block.__(2, [/* `Int */[
                                3654863,
                                intQuery
                              ]]);
                  }
                } else {
                  return /* NotEqual */Block.__(2, [/* `String */[
                              -976970511,
                              stringQuery
                            ]]);
                }
              }
            } else {
              return /* NotEqual */Block.__(2, [/* `String */[
                          -976970511,
                          stringQuery
                        ]]);
            }
        case "true" :
            return /* IsTrue */0;
        default:
          return Pervasives.failwith("Selected invalid matcher: " + invalid);
      }
    } else {
      return Pervasives.failwith("Must select a valid matcher");
    }
  };
  var handleChangeMatcher = function (opt) {
    var value = Belt_Option.map(opt, (function (x) {
            return x.value;
          }));
    return Curry._1(onChangeMatcher, defaultMatcherForValue(value));
  };
  var handleChangeScope = function (opt) {
    var value = Belt_Option.map(opt, (function (x) {
            return x.value;
          }));
    var newScope;
    if (value !== undefined) {
      var columnName = value;
      newScope = columnName === "" ? /* AnyColumn */0 : /* SingleColumn */[columnName];
    } else {
      newScope = /* AnyColumn */0;
    }
    Curry._1(onChangeScope, newScope);
    var match = findSelectedColumnType(newScope);
    if (match !== undefined) {
      var type_ = match;
      if (FeaturesTableFilters.matcherCompatibleWithType(matcher, type_)) {
        return /* () */0;
      } else {
        var fallbackMatchers_000 = defaultMatcherForValue("true");
        var fallbackMatchers_001 = /* :: */[
          defaultMatcherForValue("greaterThan"),
          /* :: */[
            defaultMatcherForValue("equal"),
            /* :: */[
              defaultMatcherForValue("contain"),
              /* [] */0
            ]
          ]
        ];
        var fallbackMatchers = /* :: */[
          fallbackMatchers_000,
          fallbackMatchers_001
        ];
        return Curry._1(onChangeMatcher, List.find((function (__x) {
                          return FeaturesTableFilters.matcherCompatibleWithType(__x, type_);
                        }), fallbackMatchers));
      }
    } else {
      return /* () */0;
    }
  };
  var scopeValue = scope ? scope[0] : "";
  var scopeOptions = List.append(/* :: */[
        ReactSelect.$$Option.make("", React.createElement(ReactIntl.FormattedMessage, {
                  id: "table.filters.scopes.anyColumn"
                })),
        /* [] */0
      ], Curry._2(Import.List.map, columns, (function (param) {
              var columnName = param[0];
              return ReactSelect.$$Option.make(columnName, columnName);
            })));
  var matcherValue;
  if (typeof matcher === "number") {
    matcherValue = matcher === /* IsTrue */0 ? "true" : "false";
  } else {
    switch (matcher.tag | 0) {
      case /* Contain */0 :
          matcherValue = "contain";
          break;
      case /* Equal */1 :
          matcherValue = "equal";
          break;
      case /* NotEqual */2 :
          matcherValue = "notEqual";
          break;
      case /* GreaterThan */3 :
          matcherValue = "greaterThan";
          break;
      case /* LessThan */4 :
          matcherValue = "lessThan";
          break;
      case /* GreaterThanOrEqualTo */5 :
          matcherValue = "greaterThanOrEqualTo";
          break;
      case /* LessThanOrEqualTo */6 :
          matcherValue = "lessThanOrEqualTo";
          break;
      case /* EqualAnyOf */7 :
          matcherValue = "equalAnyOf";
          break;
      
    }
  }
  var allMatcherOptions_000 = ReactSelect.$$Option.make("contain", React.createElement(ReactIntl.FormattedMessage, {
            id: "table.filters.matchers.contain"
          }));
  var allMatcherOptions_001 = /* :: */[
    ReactSelect.$$Option.make("equal", React.createElement(ReactIntl.FormattedMessage, {
              id: "table.filters.matchers.equal"
            })),
    /* :: */[
      ReactSelect.$$Option.make("notEqual", React.createElement(ReactIntl.FormattedMessage, {
                id: "table.filters.matchers.notEqual"
              })),
      /* :: */[
        ReactSelect.$$Option.make("equalAnyOf", React.createElement(ReactIntl.FormattedMessage, {
                  id: "table.filters.matchers.equalAnyOf"
                })),
        /* :: */[
          ReactSelect.$$Option.make("true", React.createElement(ReactIntl.FormattedMessage, {
                    id: "table.filters.matchers.true"
                  })),
          /* :: */[
            ReactSelect.$$Option.make("false", React.createElement(ReactIntl.FormattedMessage, {
                      id: "table.filters.matchers.false"
                    })),
            /* :: */[
              ReactSelect.$$Option.make("greaterThan", React.createElement(ReactIntl.FormattedMessage, {
                        id: "table.filters.matchers.greaterThan"
                      })),
              /* :: */[
                ReactSelect.$$Option.make("lessThan", React.createElement(ReactIntl.FormattedMessage, {
                          id: "table.filters.matchers.lessThan"
                        })),
                /* :: */[
                  ReactSelect.$$Option.make("greaterThanOrEqualTo", React.createElement(ReactIntl.FormattedMessage, {
                            id: "table.filters.matchers.greaterThanOrEqualTo"
                          })),
                  /* :: */[
                    ReactSelect.$$Option.make("lessThanOrEqualTo", React.createElement(ReactIntl.FormattedMessage, {
                              id: "table.filters.matchers.lessThanOrEqualTo"
                            })),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]
  ];
  var allMatcherOptions = /* :: */[
    allMatcherOptions_000,
    allMatcherOptions_001
  ];
  var matcherOptions = Curry._2(Import.List.keep, allMatcherOptions, (function (param) {
          if (selectedType !== undefined) {
            var __x = defaultMatcherForValue(param.value);
            return FeaturesTableFilters.matcherCompatibleWithType(__x, selectedType);
          } else {
            return true;
          }
        }));
  var queryElement;
  var exit$3 = 0;
  var query;
  var query$1;
  var query$2;
  if (typeof matcher === "number") {
    queryElement = null;
  } else {
    switch (matcher.tag | 0) {
      case /* Contain */0 :
          query = matcher[0];
          exit$3 = 1;
          break;
      case /* Equal */1 :
          var match$3 = matcher[0];
          var variant$1 = match$3[0];
          if (variant$1 !== 3654863) {
            if (variant$1 >= 365180284) {
              query$2 = match$3[1];
              exit$3 = 3;
            } else {
              query = match$3[1];
              exit$3 = 1;
            }
          } else {
            query$1 = match$3[1];
            exit$3 = 2;
          }
          break;
      case /* NotEqual */2 :
          var match$4 = matcher[0];
          var variant$2 = match$4[0];
          if (variant$2 !== 3654863) {
            if (variant$2 >= 365180284) {
              query$2 = match$4[1];
              exit$3 = 3;
            } else {
              query = match$4[1];
              exit$3 = 1;
            }
          } else {
            query$1 = match$4[1];
            exit$3 = 2;
          }
          break;
      case /* EqualAnyOf */7 :
          queryElement = React.createElement(TableFilters2$StringListQuery, {
                value: matcher[0],
                onChange: handleChangeStringListQuery
              });
          break;
      default:
        query$2 = matcher[0];
        exit$3 = 3;
    }
  }
  switch (exit$3) {
    case 1 :
        queryElement = React.createElement(TableFilters2$StringQuery, {
              value: query,
              onChange: handleChangeStringQuery
            });
        break;
    case 2 :
        queryElement = React.createElement(ValueInputs.Int.make, {
              value: query$1,
              onChange: handleChangeIntQuery,
              className: styles.criterionQuery,
              invalidClassName: styles.criterionQueryInvalid,
              testId: "table-filter-criterion-query"
            });
        break;
    case 3 :
        queryElement = React.createElement(ValueInputs.Float.make, {
              value: query$2,
              onChange: handleChangeFloatQuery,
              className: styles.criterionQuery,
              invalidClassName: styles.criterionQueryInvalid,
              testId: "table-filter-criterion-query"
            });
        break;
    
  }
  return React.createElement("div", {
              className: styles.criterion
            }, React.createElement(Layout.HorizontalLayout, HorizontalLayout.makeProps(/* x2 */26810, null, /* () */0), ReactExtras.withTestId("table-filter-criterion-scope", React.createElement(Layout.HorizontalLayoutItem, {
                          children: React.createElement(ReactSelect$1.default, {
                                value: scopeValue,
                                onChange: handleChangeScope,
                                options: Curry._1(Import.List.toArray, scopeOptions),
                                className: styles.criterionScope,
                                clearable: false,
                                disabled: false
                              })
                        })), ReactExtras.withTestId("table-filter-criterion-matcher", React.createElement(Layout.HorizontalLayoutItem, {
                          children: React.createElement(ReactSelect$1.default, {
                                value: matcherValue,
                                onChange: handleChangeMatcher,
                                options: Curry._1(Import.List.toArray, matcherOptions),
                                className: styles.criterionMatcher,
                                clearable: false,
                                disabled: false
                              })
                        })), React.createElement(Layout.HorizontalLayoutItem, {
                      children: queryElement
                    }), React.createElement(Layout.HorizontalLayoutItem, {
                      children: React.createElement("button", {
                            className: styles.criterionRemoveButton,
                            type: "button",
                            onClick: onRemove
                          }, React.createElement("img", {
                                alt: "Remove",
                                src: iconClose
                              }))
                    })));
}

function TableFilters2$Criteria(Props) {
  var columns = Props.columns;
  var criteria = Props.criteria;
  var onSubmit = Props.onSubmit;
  var match = React.useReducer((function (state, action) {
          if (typeof action === "number") {
            if (action === /* AddCriterion */0) {
              return FeaturesTableFilters.Actions.addCriterion(state);
            } else {
              return FeaturesTableFilters.defaultCriteria;
            }
          } else {
            switch (action.tag | 0) {
              case /* RemoveCriterion */0 :
                  return FeaturesTableFilters.Actions.removeCriterion(state, action[0]);
              case /* ChangeCriterionScope */1 :
                  return FeaturesTableFilters.Actions.changeCriterionScope(state, action[0], action[1]);
              case /* ChangeCriterionMatcher */2 :
                  return FeaturesTableFilters.Actions.changeCriterionMatcher(state, action[0], action[1]);
              
            }
          }
        }), criteria);
  var send = match[1];
  var state = match[0];
  var handleAddCriterion = function (_event) {
    return Curry._1(send, /* AddCriterion */0);
  };
  var handleReset = function (_event) {
    if (window.confirm("Do you want to reset all filters?")) {
      Curry._1(send, /* Reset */1);
      return Curry._1(onSubmit, FeaturesTableFilters.defaultCriteria);
    } else {
      return 0;
    }
  };
  var handleFormSubmit = function ($$event) {
    $$event.preventDefault();
    return Curry._1(onSubmit, state);
  };
  var canResetCriteria = Caml_obj.caml_notequal(state, FeaturesTableFilters.defaultCriteria);
  var match$1 = Curry._1(Import.List.length, state) === 0;
  var emptyCriteriaElement = match$1 ? React.createElement("div", {
          className: styles.criteriaEmpty
        }, React.createElement(ReactIntl.FormattedMessage, {
              id: "table.filters.empty"
            })) : null;
  return ReactExtras.withTestId("table-filter-criteria", React.createElement("div", {
                  className: styles.criteriaRoot
                }, React.createElement("form", {
                      onSubmit: handleFormSubmit
                    }, React.createElement("div", {
                          className: styles.criteria
                        }, React.createElement(Layout.VerticalLayout, VerticalLayout.makeProps(/* x1 */26809, ReactExtras.listToElement(Curry._2(Import.List.mapWithIndex, state, (function (idx, criterion) {
                                            return ReactExtras.withTestId("table-filter-criterion", React.createElement(Layout.VerticalLayoutItem, {
                                                            children: React.createElement(TableFilters2$Criterion, {
                                                                  criterion: criterion,
                                                                  columns: columns,
                                                                  onRemove: (function (param) {
                                                                      return Curry._1(send, /* RemoveCriterion */Block.__(0, [idx]));
                                                                    }),
                                                                  onChangeScope: (function (param) {
                                                                      return Curry._1(send, /* ChangeCriterionScope */Block.__(1, [
                                                                                    idx,
                                                                                    param
                                                                                  ]));
                                                                    }),
                                                                  onChangeMatcher: (function (param) {
                                                                      return Curry._1(send, /* ChangeCriterionMatcher */Block.__(2, [
                                                                                    idx,
                                                                                    param
                                                                                  ]));
                                                                    })
                                                                }),
                                                            key: String(idx)
                                                          }));
                                          }))), /* () */0)), emptyCriteriaElement), React.createElement("div", {
                          className: styles.criteriaButtons
                        }, React.createElement(Layout.HorizontalLayout, HorizontalLayout.makeProps(/* x2 */26810, null, /* () */0), React.createElement(Layout.HorizontalLayoutItem, {
                                  children: ReactExtras.withTestId("table-filter-criteria-submit", React.createElement(Button.make, Button.makeProps(undefined, undefined, /* `Submit */[
                                                -828715976,
                                                Curry._5(Button.Type.Submit.make, undefined, styles.criteriaButton, undefined, /* Text */936573133, /* () */0)
                                              ], React.createElement(ReactIntl.FormattedMessage, {
                                                    id: "table.filters.actions.apply"
                                                  }), /* () */0)))
                                }), React.createElement(Layout.HorizontalLayoutItem, {
                                  children: ReactExtras.withTestId("table-filter-criteria-add", React.createElement(Button.make, Button.makeProps(handleAddCriterion, undefined, /* `Basic */[
                                                -1058563442,
                                                Curry._4(Button.Type.Basic.make, styles.criteriaButton, /* Text */936573133, undefined, /* () */0)
                                              ], null, /* () */0), "+ ", React.createElement(ReactIntl.FormattedMessage, {
                                                id: "table.filters.actions.addNew"
                                              })))
                                }), canResetCriteria ? React.createElement(Layout.HorizontalLayoutItem, {
                                    children: ReactExtras.withTestId("table-filter-criteria-reset", React.createElement(Button.make, Button.makeProps(handleReset, undefined, /* `Basic */[
                                                  -1058563442,
                                                  Curry._4(Button.Type.Basic.make, styles.criteriaButton, /* Text */936573133, undefined, /* () */0)
                                                ], React.createElement(ReactIntl.FormattedMessage, {
                                                      id: "table.filters.actions.reset"
                                                    }), /* () */0)))
                                  }) : null)))));
}

function TableFilters2(Props) {
  var columns = Props.columns;
  var onSubmit = Props.onSubmit;
  var match = React.useState((function () {
          return /* [] */0;
        }));
  var setCriteria = match[1];
  var criteria = match[0];
  var match$1 = React.useReducer((function (state, action) {
          if (action) {
            return {
                    open_: false
                  };
          } else {
            return {
                    open_: !state.open_
                  };
          }
        }), {
        open_: false
      });
  var send = match$1[1];
  var handleButtonClick = function (param) {
    return Curry._1(send, /* Toggle */0);
  };
  var handleCriteriaSubmit = function (newCriteria) {
    Curry._1(setCriteria, (function (param) {
            return newCriteria;
          }));
    Curry._1(onSubmit, newCriteria);
    return Curry._1(send, /* Close */1);
  };
  var handleClickOutside = function (param) {
    return Curry._1(send, /* Close */1);
  };
  var match$2 = Curry._1(Import.List.length, criteria);
  var match$3 = Caml_obj.caml_equal(criteria, FeaturesTableFilters.defaultCriteria);
  var toggleButtonMessage;
  var exit = 0;
  if (match$2 !== 0) {
    if (match$2 !== 1) {
      toggleButtonMessage = React.createElement(ReactIntl.FormattedMessage, {
            id: "table.filters.toggle.many",
            values: {
              count: match$2
            }
          });
    } else if (match$3) {
      exit = 1;
    } else {
      toggleButtonMessage = React.createElement(ReactIntl.FormattedMessage, {
            id: "table.filters.toggle.one"
          });
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    toggleButtonMessage = React.createElement(ReactIntl.FormattedMessage, {
          id: "table.filters.toggle.none"
        });
  }
  var toggleButtonElement = React.createElement(TableToolButton.make, {
        icon: /* Filter */0,
        onClick: handleButtonClick,
        testId: "table-filter-criteria-toggle",
        children: toggleButtonMessage
      });
  var match$4 = match$1[0].open_;
  var criteriaElement = match$4 ? React.createElement(TableFilters2$Criteria, {
          columns: columns,
          criteria: criteria,
          onSubmit: handleCriteriaSubmit
        }) : null;
  return React.createElement(OnClickOutside.make, {
              onClickOutside: handleClickOutside,
              children: (function (containerRef) {
                  return React.createElement("div", {
                              ref: containerRef,
                              className: styles.root
                            }, toggleButtonElement, criteriaElement);
                })
            });
}

var make = TableFilters2;

export {
  make ,
  
}
/* styles Not a pure module */
