

import * as SidebarSectionJs from "./SidebarSection.js";

var make = SidebarSectionJs.SectionHeader;

var SectionHeader = {
  make: make
};

var make$1 = SidebarSectionJs.default;

export {
  SectionHeader ,
  make$1 as make,
  
}
/* make Not a pure module */
