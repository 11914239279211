

import * as Flags from "../../flags.bs.js";
import * as Paths from "../../Paths.bs.js";
import * as React from "react";
import * as Section from "./Section.bs.js";
import * as HomeCss from "./Home.css";
import * as WithTestId from "../WithTestId.bs.js";

var styles = HomeCss.default;

var heroImage = require("./Home/hero.svg");

var industryMicrobiome = require("./Home/industry-microbiome.jpg");

var industryCancer = require("./Home/industry-cancer.jpg");

var industryAgriculture = require("./Home/industry-agriculture.jpg");

var collaborationManage = require("./Home/collaboration-manage.svg");

var collaborationExplore = require("./Home/collaboration-explore.svg");

var collaborationShare = require("./Home/collaboration-share.svg");

var featureBigData = require("./Home/feature-big-data.svg");

var featureTables = require("./Home/feature-tables.svg");

var featureVisualization = require("./Home/feature-visualization.svg");

var featureSharing = require("./Home/feature-sharing.svg");

var featureSecure = require("./Home/feature-secure.svg");

var featureCloudHosted = require("./Home/feature-cloud-hosted.svg");

var featureCli = require("./Home/feature-cli.svg");

var featureApi = require("./Home/feature-api.svg");

var featureProvenance = require("./Home/feature-provenance.svg");

var featureFormulaLanguage = require("./Home/feature-formula-language.svg");

var featureAlgorithms = require("./Home/feature-algorithms.svg");

var featureWorkflows = require("./Home/feature-workflows.svg");

var featureVersioning = require("./Home/feature-versioning.svg");

var featurePublicData = require("./Home/feature-public-data.svg");

var featureDataLiberation = require("./Home/feature-data-liberation.svg");

function Home(Props) {
  var match = false && !Flags.disableSaasApp;
  return React.createElement("div", {
              className: styles.root
            }, React.createElement("header", {
                  className: styles.hero
                }, React.createElement("div", {
                      className: styles.heroHeadings
                    }, React.createElement("h1", undefined, "Exploration Without Limits"), React.createElement("h2", undefined, "A data science platform for life scientists.")), match ? React.createElement("div", {
                        className: styles.heroButtons
                      }, React.createElement(WithTestId.make, {
                            id: "registration-hero-button",
                            children: React.createElement("a", {
                                  className: styles.heroButton,
                                  href: Paths.registration
                                }, "Try Solvuu")
                          }), React.createElement("a", {
                            className: styles.heroButton,
                            href: "#industries"
                          }, "Learn more")) : null, React.createElement("div", {
                      className: styles.heroImage
                    }, React.createElement("img", {
                          alt: "",
                          src: heroImage
                        }))), React.createElement(Section.make, {
                  id: "industries",
                  children: null
                }, React.createElement(Section.BackgroundGradient.make, { }), React.createElement(Section.Header.make, {
                      children: "Industries"
                    }), React.createElement("div", {
                      className: styles.industries
                    }, React.createElement("div", {
                          className: styles.industry
                        }, React.createElement("img", {
                              className: styles.industryImage,
                              alt: "",
                              src: industryMicrobiome
                            }), React.createElement("span", {
                              className: styles.industryHeader
                            }, "Microbiome"), React.createElement("span", {
                              className: styles.industryDescription
                            }, "Translate your microbiome research into practical applications.\n                Bring novel, safe and effective products to market faster.")), React.createElement("div", {
                          className: styles.industry
                        }, React.createElement("img", {
                              className: styles.industryImage,
                              alt: "",
                              src: industryCancer
                            }), React.createElement("span", {
                              className: styles.industryHeader
                            }, "Cancer"), React.createElement("span", {
                              className: styles.industryDescription
                            }, "Integrate the right set of data science and collaboration tools,\n                and achieve rapid advances in cancer therapeutics.")), React.createElement("div", {
                          className: styles.industry
                        }, React.createElement("img", {
                              className: styles.industryImage,
                              alt: "",
                              src: industryAgriculture
                            }), React.createElement("span", {
                              className: styles.industryHeader
                            }, "Agriculture"), React.createElement("span", {
                              className: styles.industryDescription
                            }, "Accelerate research, enable innovation and create value by\n                adopting effective digital technology solutions to improve crop\n                productivity.")))), React.createElement(Section.make, {
                  children: null
                }, React.createElement(Section.Header.make, {
                      children: "Data Science Made Simple"
                    }), React.createElement("div", {
                      className: styles.collaborations
                    }, React.createElement("div", {
                          className: styles.collaboration
                        }, React.createElement("img", {
                              className: styles.collaborationIcon,
                              alt: "",
                              src: collaborationManage
                            }), React.createElement("span", {
                              className: styles.collaborationHeader
                            }, "Manage"), React.createElement("span", {
                              className: styles.collaborationDescription
                            }, "Import your small and big data. Organize according to our\n                templates, or define your own schema. Our format inference\n                algorithm synthesizes parsing functions and lets you override if\n                needed, without coding. Use our interactive import screens or\n                CLI for bulk imports.")), React.createElement("div", {
                          className: styles.collaboration
                        }, React.createElement("img", {
                              className: styles.collaborationIconExplore,
                              alt: "",
                              src: collaborationExplore
                            }), React.createElement("span", {
                              className: styles.collaborationHeader
                            }, "Explore"), React.createElement("span", {
                              className: styles.collaborationDescriptionTight
                            }, "Your data is not just bytes. Solvuu automatically computes\n                relevant summary statistics and generates rich interactive\n                visualizations. Explore and gain insights into your data\n                immediately; slice and dice as needed.")), React.createElement("div", {
                          className: styles.collaboration
                        }, React.createElement("img", {
                              className: styles.collaborationIcon,
                              alt: "",
                              src: collaborationShare
                            }), React.createElement("span", {
                              className: styles.collaborationHeader
                            }, "Share"), React.createElement("span", {
                              className: styles.collaborationDescriptionTight
                            }, "Collaboration is essential to the scientific process. Share your\n                data easily and securely with the rest of your team. Solvuu\n                allows clear insight on who has access to what, and allows you\n                to easily manage your sharing and privacy settings.")))), React.createElement(Section.make, {
                  children: null
                }, React.createElement(Section.Header.make, {
                      children: "Features"
                    }), React.createElement("div", {
                      className: styles.features
                    }, React.createElement("div", {
                          className: styles.feature
                        }, React.createElement("img", {
                              className: styles.featureIcon,
                              alt: "",
                              src: featureBigData
                            }), React.createElement("span", {
                              className: styles.featureHeader
                            }, "Big Data"), React.createElement("span", {
                              className: styles.featureDescription
                            }, "Import all your files of any size. We optimize storage, infer\n                detailed format specifications, and validate complex\n                customizable properties.")), React.createElement("div", {
                          className: styles.feature
                        }, React.createElement("img", {
                              className: styles.featureIcon,
                              alt: "",
                              src: featureTables
                            }), React.createElement("span", {
                              className: styles.featureHeader
                            }, "Tables"), React.createElement("span", {
                              className: styles.featureDescription
                            }, "Bring your tabular data to life. Collaboratively edit, enforce\n                rigorous constraints, and co-locate metadata with your big data\n                and workflows.")), React.createElement("div", {
                          className: styles.feature
                        }, React.createElement("img", {
                              className: styles.featureIcon,
                              alt: "",
                              src: featureVisualization
                            }), React.createElement("span", {
                              className: styles.featureHeader
                            }, "Visualization"), React.createElement("span", {
                              className: styles.featureDescription
                            }, "Rich interactive plots. Default views based on application and\n                data types, and fully customizable when needed. Export\n                publication quality images.")), React.createElement("div", {
                          className: styles.feature
                        }, React.createElement("img", {
                              className: styles.featureIcon,
                              alt: "",
                              src: featureSharing
                            }), React.createElement("span", {
                              className: styles.featureHeader
                            }, "Sharing"), React.createElement("span", {
                              className: styles.featureDescription
                            }, "Privacy is always the default. Optionally share with your team,\n                or the world. Clear indications of who has access to what.")), React.createElement("div", {
                          className: styles.feature
                        }, React.createElement("img", {
                              className: styles.featureIcon,
                              alt: "",
                              src: featureSecure
                            }), React.createElement("span", {
                              className: styles.featureHeader
                            }, "Secure"), React.createElement("span", {
                              className: styles.featureDescription
                            }, "Data transferred securely and encrypted at rest. We follow\n                industry standard security protocols with continuous monitoring.")), React.createElement("div", {
                          className: styles.feature
                        }, React.createElement("img", {
                              className: styles.featureIcon,
                              alt: "",
                              src: featureCloudHosted
                            }), React.createElement("span", {
                              className: styles.featureHeader
                            }, "Cloud Hosted"), React.createElement("span", {
                              className: styles.featureDescription
                            }, "No hardware. Install nothing; use everything. Dedicated hosting\n                available.")), React.createElement("div", {
                          className: styles.feature
                        }, React.createElement("img", {
                              className: styles.featureIcon,
                              alt: "",
                              src: featureCli
                            }), React.createElement("span", {
                              className: styles.featureHeader
                            }, "CLI"), React.createElement("span", {
                              className: styles.featureDescription
                            }, "Command line interface for power users and bulk operations.")), React.createElement("div", {
                          className: styles.feature
                        }, React.createElement("img", {
                              className: styles.featureIcon,
                              alt: "",
                              src: featureApi
                            }), React.createElement("span", {
                              className: styles.featureHeader
                            }, "API"), React.createElement("span", {
                              className: styles.featureDescription
                            }, "Push data from your instruments, create custom GUIs, and build\n                your own software in days instead of months.")), React.createElement("div", {
                          className: styles.feature
                        }, React.createElement("img", {
                              className: styles.featureIcon,
                              alt: "",
                              src: featureProvenance
                            }), React.createElement("span", {
                              className: styles.featureHeader
                            }, "Provenance"), React.createElement("span", {
                              className: styles.featureDescription
                            }, "A complete record of every event. Truly reproducible workflows\n                for publications and regulatory requirements.")), React.createElement("div", {
                          className: styles.feature
                        }, React.createElement("img", {
                              className: styles.featureIcon,
                              alt: "",
                              src: featureFormulaLanguage
                            }), React.createElement("span", {
                              className: styles.featureHeader
                            }, "Formula Language"), React.createElement("span", {
                              className: styles.featureDescription
                            }, "Express genomics workflows as easily as formulas in a\n                spreadsheet. Filter, map, reduce and compute statistics exactly\n                as you need.")), React.createElement("div", {
                          className: styles.feature
                        }, React.createElement("img", {
                              className: styles.featureIcon,
                              alt: "",
                              src: featureAlgorithms
                            }), React.createElement("span", {
                              className: styles.featureHeader
                            }, "Algorithms"), React.createElement("span", {
                              className: styles.featureDescription
                            }, "All your favorite open source algorithms are ready to use.\n                Proprietary tools available with appropriate licensing, and you\n                can add your own tools.")), React.createElement("div", {
                          className: styles.feature
                        }, React.createElement("img", {
                              className: styles.featureIcon,
                              alt: "",
                              src: featureWorkflows
                            }), React.createElement("span", {
                              className: styles.featureHeader
                            }, "Workflows"), React.createElement("span", {
                              className: styles.featureDescription
                            }, "Intelligent execution engine automatically parallelizes jobs and\n                minimizes IO. Fully scalable.")), React.createElement("div", {
                          className: styles.feature
                        }, React.createElement("img", {
                              className: styles.featureIcon,
                              alt: "",
                              src: featureVersioning
                            }), React.createElement("span", {
                              className: styles.featureHeader
                            }, "Versioning"), React.createElement("span", {
                              className: styles.featureDescription
                            }, "Every change to data and workflows is tracked. Roll back\n                whenever needed. Use any version of all data and all tools.")), React.createElement("div", {
                          className: styles.feature
                        }, React.createElement("img", {
                              className: styles.featureIcon,
                              alt: "",
                              src: featurePublicData
                            }), React.createElement("span", {
                              className: styles.featureHeader
                            }, "Public Data"), React.createElement("span", {
                              className: styles.featureDescription
                            }, "We integrate with major repositories like GEO, SRA, COSMIC and\n                more. Seamlessly refer to public data sets in your private\n                analyses.")), React.createElement("div", {
                          className: styles.feature
                        }, React.createElement("img", {
                              className: styles.featureIcon,
                              alt: "",
                              src: featureDataLiberation
                            }), React.createElement("span", {
                              className: styles.featureHeader
                            }, "Data Liberation"), React.createElement("span", {
                              className: styles.featureDescription
                            }, "Your data is always yours. Easily export in industry standard\n                formats, and directly submit to public repositories.")))));
}

var make = Home;

export {
  styles ,
  heroImage ,
  industryMicrobiome ,
  industryCancer ,
  industryAgriculture ,
  collaborationManage ,
  collaborationExplore ,
  collaborationShare ,
  featureBigData ,
  featureTables ,
  featureVisualization ,
  featureSharing ,
  featureSecure ,
  featureCloudHosted ,
  featureCli ,
  featureApi ,
  featureProvenance ,
  featureFormulaLanguage ,
  featureAlgorithms ,
  featureWorkflows ,
  featureVersioning ,
  featurePublicData ,
  featureDataLiberation ,
  make ,
  
}
/* styles Not a pure module */
