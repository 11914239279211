


function make(value, label) {
  return {
          value: value,
          label: label
        };
}

var $$Option = {
  make: make
};

var Creatable = { };

export {
  $$Option ,
  Creatable ,
  
}
/* No side effect */
