

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as Term from "../../../../lang/Term.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Import from "../../../../features/import.bs.js";
import * as Select from "../../../../components/Select/Select.bs.js";
import * as Loading from "../../../../components/Loading.bs.js";
import * as TermEval from "../../../../lib/solvuu/termEval.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as StyleCss from "./style.css";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_format from "bs-platform/lib/es6/caml_format.js";
import * as ReactExtras from "../../../../lib/solvuu/ReactExtras.bs.js";
import * as ReactSelect from "../../../../lib/ReactSelect.bs.js";
import * as Blob$SolvuuApi from "solvuu-api/./blob.bs.js";
import * as Root$SolvuuApi from "solvuu-api/./root.bs.js";
import * as VerticalLayout from "../../../../components/Layout/VerticalLayout.bs.js";
import * as TermRenderContext from "../../../../lang/TermRenderContext.bs.js";
import * as Layout from "components/Layout";

var styles = StyleCss.default;

function logfileToJs(logfile) {
  return String(logfile + 0 | 0);
}

function logfileFromJs(js) {
  var param = Caml_format.caml_int_of_string(js);
  if (param <= 1 && 0 <= param) {
    return param - 0 | 0;
  }
  
}

function fetchBlob(thisId, apiClient) {
  var $$let = Blob$SolvuuApi.Get_by_id.$$Request;
  var $$let$1 = Blob$SolvuuApi.Get_by_id.$$Response;
  return Import.$great$great$pipe(Curry._3(apiClient.f, /* POST */891112544, {
                  entity: Blob$SolvuuApi.Get_by_id.entity,
                  service: Blob$SolvuuApi.Get_by_id.service,
                  $$Request: {
                    path: $$let.path,
                    body: $$let.body,
                    info: $$let.info
                  },
                  $$Response: {
                    ok_of_json: $$let$1.ok_of_json,
                    json_of_ok: $$let$1.json_of_ok,
                    bad_request_of_json: $$let$1.bad_request_of_json,
                    json_of_bad_request: $$let$1.json_of_bad_request,
                    not_found_of_json: $$let$1.not_found_of_json,
                    json_of_not_found: $$let$1.json_of_not_found
                  }
                }, {
                  id: thisId,
                  instructions: undefined
                }), (function (response) {
                if (typeof response === "number") {
                  return /* `Error */[
                          106380200,
                          /* Unauthorized */324098644
                        ];
                } else {
                  var info = response[1].info;
                  var variant = info[0];
                  if (variant !== -99004555) {
                    if (variant >= 17692) {
                      return /* `Ok */[
                              17724,
                              info[1].blob
                            ];
                    } else {
                      return /* `Error */[
                              106380200,
                              /* Not_found */-358247754
                            ];
                    }
                  } else {
                    return /* `Error */[
                            106380200,
                            /* Bad_request */-99004555
                          ];
                  }
                }
              }));
}

function ofBlobList(blobs) {
  return Curry._3(Import.List.reduce, blobs, {
              stdout: undefined,
              stderr: undefined
            }, (function (acc, blob) {
                return Belt_Option.getWithDefault(Belt_Option.flatMap(Blob$SolvuuApi.source(blob.state), (function (source) {
                                  switch (source) {
                                    case "file:/solvuu/output/__SOLVUU_META__/dev/stderr" :
                                        return {
                                                stdout: acc.stdout,
                                                stderr: blob
                                              };
                                    case "file:/solvuu/output/__SOLVUU_META__/dev/stdout" :
                                        return {
                                                stdout: blob,
                                                stderr: acc.stderr
                                              };
                                    default:
                                      return ;
                                  }
                                })), acc);
              }));
}

function map(t, f) {
  return {
          stdout: Curry._1(f, t.stdout),
          stderr: Curry._1(f, t.stderr)
        };
}

function all(t) {
  return Import.$great$great$pipe(Promise.all(/* tuple */[
                  t.stdout,
                  t.stderr
                ]), (function (param) {
                return {
                        stdout: param[0],
                        stderr: param[1]
                      };
              }));
}

function useLogs(job, apiClient) {
  var match = React.useState((function () {
          return /* Initial */418396260;
        }));
  var setState = match[1];
  var state = match[0];
  React.useEffect((function () {
          if (state === 418396260) {
            Curry._1(setState, (function (param) {
                    return /* Loading */-700189092;
                  }));
            Import.$great$great$pipe(Import.$great$great$eq(Promise.all(Belt_Array.map(Curry._1(Import.List.toArray, job.output.blobs), (function (id) {
                                return fetchBlob(id, apiClient);
                              }))), (function (logs) {
                        return all(map(ofBlobList(Curry._2(Import.List.keepMap, Curry._1(Import.List.fromArray, logs), (function (result) {
                                                  if (result[0] >= 106380200) {
                                                    return ;
                                                  } else {
                                                    return result[1];
                                                  }
                                                }))), (function (blob) {
                                          if (blob !== undefined) {
                                            return Import.$great$great$pipe(TermEval.$$eval(apiClient)(/* `App */[
                                                            3257473,
                                                            /* tuple */[
                                                              /* `Var */[
                                                                4298439,
                                                                Root$SolvuuApi.string
                                                              ],
                                                              /* `Blob_id */[
                                                                295523485,
                                                                blob.id
                                                              ]
                                                            ]
                                                          ]), (function (term) {
                                                          return term;
                                                        }));
                                          } else {
                                            return Import.$$return(undefined);
                                          }
                                        })));
                      })), (function (logs) {
                    return Curry._1(setState, (function (state) {
                                  if (typeof state === "number" && state < 418396260) {
                                    return /* `Logs */[
                                            848350095,
                                            logs
                                          ];
                                  } else {
                                    return state;
                                  }
                                }));
                  }));
          }
          return ;
        }), /* array */[state]);
  return state;
}

var Logs = {
  ofBlobList: ofBlobList,
  map: map,
  all: all,
  useLogs: useLogs
};

function JobLogs(Props) {
  var job = Props.job;
  var apiClient = Props.apiClient;
  var logs = useLogs(job, apiClient);
  var match = React.useReducer((function (_state, action) {
          return {
                  currentLogfile: action[0]
                };
        }), {
        currentLogfile: /* Stdout */0
      });
  var send = match[1];
  var currentLogfile = match[0].currentLogfile;
  var options_000 = ReactSelect.$$Option.make(String(0), "Standard output");
  var options_001 = /* :: */[
    ReactSelect.$$Option.make(String(1), "Standard error"),
    /* [] */0
  ];
  var options = /* :: */[
    options_000,
    options_001
  ];
  var handleChange = function (opt) {
    var logfile = Belt_Option.flatMap(Belt_Option.map(opt, (function (param) {
                return param.value;
              })), logfileFromJs);
    if (logfile !== undefined) {
      return Curry._1(send, /* ChangeLogfile */[logfile]);
    } else {
      return /* () */0;
    }
  };
  var logfileContents;
  if (typeof logs === "number") {
    logfileContents = React.createElement(Loading.make, { });
  } else {
    var logs$1 = logs[1];
    var log = currentLogfile ? logs$1.stderr : logs$1.stdout;
    logfileContents = log !== undefined ? ReactExtras.withTestId("job-log", React.createElement("div", {
                className: Cn.make(/* :: */[
                      styles.log,
                      /* :: */[
                        styles.logFilled,
                        /* [] */0
                      ]
                    ])
              }, React.createElement(Term.make, {
                    term: log,
                    type_: /* String */-976970511,
                    context: TermRenderContext.root,
                    apiClient: apiClient
                  }))) : "Not Found";
  }
  return React.createElement(Layout.VerticalLayout, VerticalLayout.makeProps(/* x3 */26811, null, /* () */0), React.createElement(Layout.VerticalLayoutItem, {
                  children: React.createElement("div", {
                        className: styles.logSelect
                      }, React.createElement(Select.make, {
                            label: "Logfile",
                            value: String(currentLogfile + 0 | 0),
                            onChange: handleChange,
                            options: options
                          }))
                }), React.createElement(Layout.VerticalLayoutItem, {
                  children: logfileContents
                }));
}

var make = JobLogs;

export {
  styles ,
  logfileToJs ,
  logfileFromJs ,
  fetchBlob ,
  Logs ,
  make ,
  
}
/* styles Not a pure module */
