

import * as Op from "../../features/Op.bs.js";
import * as Err from "../../components/Err.bs.js";
import * as Term from "../../lang/Term.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Hooks from "../../Hooks.bs.js";
import * as Jobs0 from "./Jobs0.bs.js";
import * as React from "react";
import * as Button from "../../components/Button.bs.js";
import * as Import from "../../features/import.bs.js";
import * as JobRow from "./JobRow/JobRow.bs.js";
import * as Record from "../Entities/Record/Record.bs.js";
import * as JobForm from "../../features/jobs/form/jobForm.bs.js";
import * as Js_dict from "bs-platform/lib/es6/js_dict.js";
import * as Loading from "../../components/Loading.bs.js";
import * as Std$Bolt from "bolt/./std.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Caml_int32 from "bs-platform/lib/es6/caml_int32.js";
import * as ReactIntl from "react-intl";
import * as StyleCss from "./style.css";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as ReactExtras from "../../lib/solvuu/ReactExtras.bs.js";
import * as FullWidthCard from "../Layout/AuthenticatedLayout/FullWidthCard.bs.js";
import * as Job$SolvuuApi from "solvuu-api/./job.bs.js";
import * as TableElements from "../../table/TableElements.bs.js";
import * as Blob$SolvuuApi from "solvuu-api/./blob.bs.js";
import * as Caml_primitive from "bs-platform/lib/es6/caml_primitive.js";
import * as VerticalLayout from "../../components/Layout/VerticalLayout.bs.js";
import * as FormattedRelative from "../../components/FormattedRelative/FormattedRelative.bs.js";
import * as Time$SolvuuClient from "solvuu-client/./time.bs.js";
import * as Layout from "components/Layout";
import * as JobsFiltersDropdown from "./JobsFiltersDropdown/JobsFiltersDropdown.bs.js";
import * as PageHeader from "components/PageHeader";
import * as Caml_builtin_exceptions from "bs-platform/lib/es6/caml_builtin_exceptions.js";
import * as JobFormComponentJs from "containers/Jobs/Form/JobFormComponent.js";

function Jobs$Browse(Props) {
  var jobId = Props.jobId;
  var hash = Props.hash;
  var apiClient = Props.apiClient;
  var blobs = Op.useOp(jobId, (function (jobId) {
          var $$let = Job$SolvuuApi.Get_by_id.$$Request;
          var $$let$1 = Job$SolvuuApi.Get_by_id.$$Response;
          return Import.$great$great$eq(Curry._3(apiClient.f, /* GET */3546230, {
                          entity: Job$SolvuuApi.Get_by_id.entity,
                          service: Job$SolvuuApi.Get_by_id.service,
                          $$Request: {
                            path: $$let.path,
                            body: $$let.body,
                            info: $$let.info
                          },
                          $$Response: {
                            ok_of_json: $$let$1.ok_of_json,
                            json_of_ok: $$let$1.json_of_ok,
                            bad_request_of_json: $$let$1.bad_request_of_json,
                            json_of_bad_request: $$let$1.json_of_bad_request,
                            not_found_of_json: $$let$1.not_found_of_json,
                            json_of_not_found: $$let$1.json_of_not_found
                          }
                        }, {
                          id: jobId
                        }), (function (job) {
                        if (typeof job === "number") {
                          return Import.$$return(/* `Done */[
                                      759635106,
                                      /* `Error */[
                                        106380200,
                                        "Unauthorized"
                                      ]
                                    ]);
                        } else {
                          var info = job[1].info;
                          var variant = info[0];
                          if (variant !== -99004555) {
                            if (variant >= 17692) {
                              var job$1 = info[1];
                              var blobs = Promise.all(Curry._1(Import.List.toArray, Curry._2(Import.List.map, job$1.output.blobs, (function (blobId) {
                                              var $$let = Blob$SolvuuApi.Get_by_id.$$Request;
                                              var $$let$1 = Blob$SolvuuApi.Get_by_id.$$Response;
                                              return Curry._3(apiClient.f, /* POST */891112544, {
                                                          entity: Blob$SolvuuApi.Get_by_id.entity,
                                                          service: Blob$SolvuuApi.Get_by_id.service,
                                                          $$Request: {
                                                            path: $$let.path,
                                                            body: $$let.body,
                                                            info: $$let.info
                                                          },
                                                          $$Response: {
                                                            ok_of_json: $$let$1.ok_of_json,
                                                            json_of_ok: $$let$1.json_of_ok,
                                                            bad_request_of_json: $$let$1.bad_request_of_json,
                                                            json_of_bad_request: $$let$1.json_of_bad_request,
                                                            not_found_of_json: $$let$1.not_found_of_json,
                                                            json_of_not_found: $$let$1.json_of_not_found
                                                          }
                                                        }, {
                                                          id: blobId,
                                                          instructions: undefined
                                                        });
                                            }))));
                              return Import.$great$great$pipe(blobs, (function (blobs) {
                                            var result = Curry._2(Std$Bolt.Res.map, Curry._1(Std$Bolt.Res.all, Curry._2(Import.List.map, Curry._1(Import.List.fromArray, blobs), (function (blob) {
                                                            if (typeof blob === "number") {
                                                              return /* `Error */[
                                                                      106380200,
                                                                      "Unauthorized"
                                                                    ];
                                                            } else {
                                                              var info = blob[1].info;
                                                              var variant = info[0];
                                                              if (variant !== -99004555) {
                                                                if (variant >= 17692) {
                                                                  return /* `Ok */[
                                                                          17724,
                                                                          info[1].blob
                                                                        ];
                                                                } else {
                                                                  return /* `Error */[
                                                                          106380200,
                                                                          "Blob not found"
                                                                        ];
                                                                }
                                                              } else {
                                                                return /* `Error */[
                                                                        106380200,
                                                                        "Unable to fetch Blob"
                                                                      ];
                                                              }
                                                            }
                                                          }))), (function (x) {
                                                    return /* tuple */[
                                                            job$1.output.prefix,
                                                            x
                                                          ];
                                                  }));
                                            return /* `Done */[
                                                    759635106,
                                                    result
                                                  ];
                                          }));
                            } else {
                              return Import.$$return(/* `Done */[
                                          759635106,
                                          /* `Error */[
                                            106380200,
                                            "Job Not Found"
                                          ]
                                        ]);
                            }
                          } else {
                            return Import.$$return(/* `Done */[
                                        759635106,
                                        /* `Error */[
                                          106380200,
                                          "Unable to fetch Job"
                                        ]
                                      ]);
                          }
                        }
                      }));
        }));
  React.useEffect((function () {
          if (typeof blobs !== "number") {
            if (blobs[0] !== -783600662) {
              
            } else {
              Curry._1(blobs[1].run, /* () */0);
            }
          }
          return ;
        }), /* array */[blobs]);
  var variant = blobs[0];
  if (variant !== -783600662) {
    if (variant >= 951752159) {
      return React.createElement(Loading.make, { });
    } else {
      var data = blobs[1].data;
      if (data[0] >= 106380200) {
        return React.createElement(Err.Operation.make, {
                    message: data[1]
                  });
      } else {
        var match = data[1];
        var jobOutputPrefix = match[0];
        var paths = Curry._2(Import.List.keepMap, match[1], (function (blob) {
                var match = Blob$SolvuuApi.source(blob.state);
                var path;
                if (match !== undefined) {
                  var source = match;
                  var prefix = "file:" + jobOutputPrefix;
                  var match$1 = source.startsWith(prefix);
                  var noPrefix = match$1 ? (function (param) {
                          return source.substring(param);
                        })(prefix.length) : source;
                  path = Curry._2(Import.List.keep, Curry._1(Import.List.fromArray, noPrefix.split("/")), (function (x) {
                          return x !== "";
                        }));
                } else {
                  path = /* :: */[
                    "<Blob_id(" + (blob.id + ")>"),
                    /* [] */0
                  ];
                }
                var match$2 = Curry._1(Import.List.head, path);
                if (match$2 !== undefined && match$2 !== "__SOLVUU_META__") {
                  return /* tuple */[
                          path,
                          /* `Blob_id */[
                            295523485,
                            blob.id
                          ]
                        ];
                }
                
              }));
        return React.createElement(Record.make, {
                    apiClient: apiClient,
                    record: /* `Paths */[
                      -796624114,
                      paths
                    ],
                    hash: hash,
                    renderTerm: Term.render
                  });
      }
    }
  } else {
    return null;
  }
}

var Browse = {
  make: Jobs$Browse
};

var styles = StyleCss.default;

var State = { };

var Action = { };

function createJob(dispatch, fetchJobs, form, apiClient) {
  var start = function (x) {
    return Curry._1(Jobs0.Operations.Job.Create.start, x);
  };
  var success = function (x) {
    return Curry._1(Jobs0.Operations.Job.Create.success, x);
  };
  var failure = function (x) {
    return Curry._1(Jobs0.Operations.Job.Create.failure, x);
  };
  Curry._1(dispatch, start(/* () */0));
  var validation = JobForm.validateJobForm(form);
  if (validation[0] >= 106380200) {
    Curry._1(dispatch, failure(/* array */[validation[1]]));
    return Import.$$return(/* () */0);
  } else {
    var info = JobForm.toSolvuuSpec(validation[1]);
    var $$let = Job$SolvuuApi.Create.$$Request;
    var $$let$1 = Job$SolvuuApi.Create.$$Response;
    return Import.$great$great$eq(Curry._3(apiClient.f, /* POST */891112544, {
                    entity: Job$SolvuuApi.Create.entity,
                    service: Job$SolvuuApi.Create.service,
                    $$Request: {
                      path: $$let.path,
                      body: $$let.body,
                      info: $$let.info
                    },
                    $$Response: {
                      ok_of_json: $$let$1.ok_of_json,
                      json_of_ok: $$let$1.json_of_ok,
                      bad_request_of_json: $$let$1.bad_request_of_json,
                      json_of_bad_request: $$let$1.json_of_bad_request,
                      not_found_of_json: $$let$1.not_found_of_json,
                      json_of_not_found: $$let$1.json_of_not_found
                    }
                  }, info), (function (response) {
                  if (typeof response === "number") {
                    Curry._1(dispatch, failure(/* array */["Unauthorized"]));
                    return Import.$$return(/* () */0);
                  } else {
                    var variant = response[1].info[0];
                    if (variant !== -99004555) {
                      if (variant >= 17692) {
                        Curry._1(fetchJobs, /* () */0);
                        Curry._1(dispatch, success(/* () */0));
                        return Import.$$return(/* () */0);
                      } else {
                        Curry._1(dispatch, failure(/* array */["Not Found"]));
                        return Import.$$return(/* () */0);
                      }
                    } else {
                      Curry._1(dispatch, failure(/* array */["Bad Request"]));
                      return Import.$$return(/* () */0);
                    }
                  }
                }));
  }
}

function reducer(state, action) {
  if (action[0] >= 45074369) {
    var form = JobForm.reducer(state.form, action[1]);
    return {
            form: form,
            meta: state.meta
          };
  } else {
    var meta = Curry._2(Jobs0.Operations.Job.Create.reducer, state.meta, action[1][1]);
    return {
            form: state.form,
            meta: meta
          };
  }
}

function Jobs$Form(Props) {
  var match = Props.form;
  var form = match !== undefined ? match : JobForm.initialState;
  var apiClient = Props.apiClient;
  var fetchJobs = Props.fetchJobs;
  var match$1 = React.useReducer(reducer, {
        form: form,
        meta: Jobs0.Operations.Job.Create.initialState
      });
  var dispatch = match$1[1];
  var match$2 = match$1[0];
  var form$1 = match$2.form;
  var onSubmit = function (param) {
    createJob(dispatch, fetchJobs, form$1, apiClient);
    return /* () */0;
  };
  var onChange = function (field, value) {
    return Curry._1(dispatch, /* `JobForm */[
                45074369,
                /* `Change */[
                  -335084144,
                  {
                    field: field,
                    value: value
                  }
                ]
              ]);
  };
  var meta = {
    jobCreate: match$2.meta
  };
  return React.createElement(JobFormComponentJs.JobForm, {
              onSubmit: onSubmit,
              onChange: onChange,
              form: form$1,
              meta: meta
            });
}

var Form = {
  State: State,
  Action: Action,
  createJob: createJob,
  reducer: reducer,
  make: Jobs$Form
};

var Action$1 = { };

var Jobs = {
  initialState: /* [] */0,
  Action: Action$1
};

function reducer$1(state, action) {
  if (state) {
    return /* Visible */0;
  } else {
    return /* Hidden */1;
  }
}

var FormState = {
  reducer: reducer$1,
  initialState: /* Hidden */1
};

var Action$2 = { };

function reducer$2(state, action) {
  var variant = action[0];
  if (variant !== 730877453) {
    if (variant >= 826169846) {
      var action$1 = action[1][1];
      var $$fetch = Curry._2(Jobs0.Operations.Jobs.Fetch.reducer, state.meta.jobs.fetch, action$1);
      var jobs = {
        fetch: $$fetch
      };
      var meta = {
        jobs: jobs
      };
      var jobsById;
      if (typeof action$1 === "number" || action$1[0] !== 94326179) {
        jobsById = state.jobsById;
      } else {
        var jobsById$1 = { };
        Curry._2(Import.List.forEach, action$1[1].jobs, (function (job) {
                jobsById$1[job.id] = job;
                return /* () */0;
              }));
        jobsById = jobsById$1;
      }
      return {
              jobsById: jobsById,
              formState: state.formState,
              includeSystemJobs: state.includeSystemJobs,
              meta: meta
            };
    } else {
      return {
              jobsById: state.jobsById,
              formState: state.formState,
              includeSystemJobs: action[1],
              meta: state.meta
            };
    }
  } else {
    var formState = reducer$1(state.formState, action[1]);
    return {
            jobsById: state.jobsById,
            formState: formState,
            includeSystemJobs: state.includeSystemJobs,
            meta: state.meta
          };
  }
}

var initialState_jobsById = { };

var initialState_meta = {
  jobs: {
    fetch: Jobs0.Operations.Jobs.Fetch.initialState
  }
};

var initialState = {
  jobsById: initialState_jobsById,
  formState: /* Hidden */1,
  includeSystemJobs: false,
  meta: initialState_meta
};

var State$1 = {
  reducer: reducer$2,
  initialState: initialState
};

function fetchJobs(apiClient) {
  return Import.$great$great$pipe(Curry._3(apiClient.f, /* GET */3546230, Job$SolvuuApi.Get_all, /* () */0), (function (response) {
                if (typeof response === "number") {
                  return /* `Error */[
                          106380200,
                          /* Unauthorized */324098644
                        ];
                } else {
                  var info = response[1].info;
                  var variant = info[0];
                  if (variant !== -99004555) {
                    if (variant >= 17692) {
                      return /* `Ok */[
                              17724,
                              info[1]
                            ];
                    } else {
                      return /* `Error */[
                              106380200,
                              /* Not_found */-358247754
                            ];
                    }
                  } else {
                    throw [
                          Caml_builtin_exceptions.assert_failure,
                          /* tuple */[
                            "Jobs.re",
                            353,
                            29
                          ]
                        ];
                  }
                }
              }));
}

function fetchJobsWithDispatch(dispatch, apiClient) {
  var start = function (x) {
    return Curry._1(Jobs0.Operations.Jobs.Fetch.start, x);
  };
  var success = function (x) {
    return Curry._1(Jobs0.Operations.Jobs.Fetch.success, x);
  };
  var failure = function (x) {
    return Curry._1(Jobs0.Operations.Jobs.Fetch.failure, x);
  };
  Curry._1(dispatch, start(/* () */0));
  return Import.$great$great$pipe(fetchJobs(apiClient), (function (response) {
                if (response[0] >= 106380200) {
                  return Curry._1(dispatch, failure(/* array */[response[1]]));
                } else {
                  return Curry._1(dispatch, success({
                                  jobs: response[1],
                                  timestamp: Date.now()
                                }));
                }
              }));
}

function Jobs$1(Props) {
  var apiClient = Props.apiClient;
  var match = React.useReducer(reducer$2, initialState);
  var dispatch = match[1];
  var match$1 = match[0];
  var jobsById = match$1.jobsById;
  Hooks.useWithInterval(undefined, 5000, (function (param) {
          fetchJobsWithDispatch(dispatch, apiClient);
          return /* () */0;
        }));
  var visibleJobIds = Curry._1(Import.List.toArray, Curry._2(Import.List.map, Curry._2(Import.List.sort, Curry._2(Import.List.keep, Curry._1(Import.List.fromArray, Js_dict.entries(jobsById)), (function (param) {
                      return true;
                    })), (function (param, param$1) {
                  return Caml_int32.imul(Caml_primitive.caml_float_compare(param[1].created, param$1[1].created), -1);
                })), (function (prim) {
              return prim[0];
            })));
  var fetchStatus = Curry._1(Jobs0.Operations.Jobs.Fetch.Status.convertFromOperation, match$1.meta.jobs.fetch);
  var handleRefreshClick = React.useCallback((function (param) {
          fetchJobsWithDispatch(dispatch, apiClient);
          return /* () */0;
        }), ([]));
  var handleFormToggleClick = React.useCallback((function (param) {
          return Curry._1(dispatch, /* `FormState */[
                      730877453,
                      /* Toggle */0
                    ]);
        }), ([]));
  var handleIncludeSystemJobs = function (x) {
    return Curry._1(dispatch, /* `IncludeSystemJobs */[
                -4155251,
                x
              ]);
  };
  var lastFetchStatusElement;
  var exit = 0;
  var timestamp;
  var exit$1 = 0;
  if (typeof fetchStatus === "number") {
    lastFetchStatusElement = null;
  } else {
    switch (fetchStatus.tag | 0) {
      case /* Success */1 :
          timestamp = fetchStatus[0].lastSuccessAt;
          exit = 1;
          break;
      case /* Pending */0 :
      case /* Failure */2 :
          exit$1 = 2;
          break;
      
    }
  }
  if (exit$1 === 2) {
    var match$2 = fetchStatus[0].lastSuccessAt;
    if (match$2 !== undefined) {
      timestamp = match$2;
      exit = 1;
    } else {
      lastFetchStatusElement = null;
    }
  }
  if (exit === 1) {
    lastFetchStatusElement = React.createElement("span", {
          className: styles.fetchStatus
        }, React.createElement(ReactIntl.FormattedMessage, {
              id: "jobs.lastSuccessAt"
            }), React.createElement(FormattedRelative.make, {
              value: Time$SolvuuClient.of_span_since_epoch(Curry._1(Time$SolvuuClient.Span.of_ms, timestamp)),
              updateInterval: /* `Seconds */[
                668917343,
                5
              ]
            }));
  }
  var refreshButtonDisabled;
  refreshButtonDisabled = typeof fetchStatus === "number" || fetchStatus.tag ? false : true;
  var pageHeaderElement = React.createElement(PageHeader.default, {
        children: null
      }, React.createElement(PageHeader.default.Name, {
            children: React.createElement(ReactIntl.FormattedMessage, {
                  id: "jobs.header"
                })
          }), React.createElement(PageHeader.default.Slot, {
            children: React.createElement(JobsFiltersDropdown.make, {
                  includeSystemJobs: match$1.includeSystemJobs,
                  handleIncludeSystemJobs: handleIncludeSystemJobs
                })
          }), React.createElement(PageHeader.default.FillSpace, { }), React.createElement("div", undefined, lastFetchStatusElement, React.createElement("button", {
                className: styles.refreshButton,
                disabled: refreshButtonDisabled,
                type: "button",
                onClick: handleRefreshClick
              }, React.createElement(ReactIntl.FormattedMessage, {
                    id: "jobs.actions.refresh"
                  })), React.createElement(Button.make, Button.makeProps(handleFormToggleClick, "add-new-job", /* `Basic */[
                    -1058563442,
                    Curry._4(Button.Type.Basic.make, undefined, /* Primary */-791844958, undefined, /* () */0)
                  ], React.createElement(ReactIntl.FormattedMessage, {
                        id: "jobs.actions.addNew"
                      }), /* () */0))));
  var formUI = match$1.formState ? undefined : Caml_option.some(React.createElement("div", {
              className: styles.formSection
            }, React.createElement(Jobs$Form, {
                  apiClient: apiClient,
                  fetchJobs: (function (param) {
                      fetchJobsWithDispatch(dispatch, apiClient);
                      return /* () */0;
                    })
                })));
  var ui;
  var exit$2 = 0;
  if (typeof fetchStatus === "number") {
    ui = /* Nothing */481346541;
  } else {
    switch (fetchStatus.tag | 0) {
      case /* Pending */0 :
          if (fetchStatus[0].lastSuccessAt !== undefined) {
            exit$2 = 1;
          } else {
            ui = /* `JustLoading */[
              635806096,
              React.createElement("div", {
                    className: styles.loading
                  }, React.createElement(Loading.make, { }))
            ];
          }
          break;
      case /* Success */1 :
          exit$2 = 1;
          break;
      case /* Failure */2 :
          if (fetchStatus[0].lastSuccessAt !== undefined) {
            exit$2 = 1;
          } else {
            ui = /* `JustError */[
              867693020,
              React.createElement(Err.Operation.make, { })
            ];
          }
          break;
      
    }
  }
  if (exit$2 === 1) {
    var totalJobsCount = visibleJobIds.length;
    var errorElement;
    errorElement = typeof fetchStatus === "number" || fetchStatus.tag !== /* Failure */2 ? undefined : Caml_option.some(React.createElement(Err.Operation.make, { }));
    var tableElement = totalJobsCount !== 0 ? React.createElement(React.Fragment, undefined, React.createElement(TableElements.TableElement.make, {
                testId: "jobs",
                children: null
              }, React.createElement("thead", undefined, React.createElement(TableElements.RowElement.make, {
                        children: null
                      }, React.createElement(TableElements.HeaderElement.make, {
                            variant: /* Narrow */0,
                            children: React.createElement(ReactIntl.FormattedMessage, {
                                  id: "jobs.id"
                                })
                          }), React.createElement(TableElements.HeaderElement.make, {
                            variant: /* Narrow */0,
                            children: React.createElement(ReactIntl.FormattedMessage, {
                                  id: "jobs.status"
                                })
                          }), React.createElement(TableElements.HeaderElement.make, {
                            children: React.createElement(ReactIntl.FormattedMessage, {
                                  id: "jobs.command"
                                })
                          }), React.createElement(TableElements.HeaderElement.make, {
                            variant: /* Narrow */0,
                            children: React.createElement(ReactIntl.FormattedMessage, {
                                  id: "jobs.time"
                                })
                          }), React.createElement(TableElements.HeaderElement.make, {
                            variant: /* Narrow */0,
                            children: null
                          }))), React.createElement("tbody", undefined, Belt_Array.map(visibleJobIds, (function (id) {
                          var match = Js_dict.get(jobsById, id);
                          if (match !== undefined) {
                            return React.createElement(JobRow.make, {
                                        job: match,
                                        key: id
                                      });
                          } else {
                            return "ERROR";
                          }
                        })))), React.createElement("div", {
                className: styles.totalJobs
              }, React.createElement(ReactIntl.FormattedMessage, {
                    id: "jobs.totalJobs",
                    values: {
                      count: totalJobsCount
                    }
                  }))) : ReactExtras.withTestId("jobs", React.createElement("div", {
                className: styles.empty
              }, React.createElement(ReactIntl.FormattedMessage, {
                    id: "jobs.empty"
                  })));
    ui = errorElement !== undefined ? /* `TableWithError */[
        -835225068,
        {
          tableElement: tableElement,
          errorElement: Caml_option.valFromOption(errorElement)
        }
      ] : /* `JustTable */[
        -881730014,
        tableElement
      ];
  }
  var formElement = formUI !== undefined ? React.createElement(Layout.VerticalLayoutItem, {
          children: Caml_option.valFromOption(formUI),
          key: "form"
        }) : null;
  var statusElement;
  if (typeof ui === "number") {
    statusElement = null;
  } else {
    var variant = ui[0];
    statusElement = variant !== -835225068 ? (
        variant >= -881730013 ? React.createElement(Layout.VerticalLayoutItem, {
                children: ui[1],
                key: "status"
              }) : null
      ) : React.createElement(Layout.VerticalLayoutItem, {
            children: ui[1].errorElement,
            key: "status"
          });
  }
  var tableElement$1;
  if (typeof ui === "number") {
    tableElement$1 = null;
  } else {
    var variant$1 = ui[0];
    tableElement$1 = variant$1 !== -835225068 ? (
        variant$1 >= -881730013 ? null : React.createElement(Layout.VerticalLayoutItem, {
                children: ui[1],
                key: "table"
              })
      ) : React.createElement(Layout.VerticalLayoutItem, {
            children: ui[1].tableElement,
            key: "table"
          });
  }
  var cardClassName = formUI !== undefined || typeof ui === "number" || ui[0] < -835225068 ? "" : styles.cardWithVerticalPadding;
  return React.createElement("div", {
              className: styles.root
            }, pageHeaderElement, React.createElement(FullWidthCard.make, {
                  children: React.createElement("div", {
                        className: cardClassName
                      }, React.createElement(Layout.VerticalLayout, VerticalLayout.makeProps(/* x2 */26810, null, /* () */0), formElement, statusElement, tableElement$1))
                }));
}

var $$Error = Jobs0.$$Error;

var Operations = Jobs0.Operations;

var make = Jobs$1;

export {
  $$Error ,
  Operations ,
  Browse ,
  styles ,
  Form ,
  Jobs ,
  FormState ,
  Action$2 as Action,
  State$1 as State,
  fetchJobs ,
  fetchJobsWithDispatch ,
  make ,
  
}
/* styles Not a pure module */
