import { connect } from "react-redux";

import {
  genomeBrowserViewInit,
  genomeBrowserViewTeardown,
  genomeBrowserFormChange,
  genomeBrowserFormSubmit,
  genomeBrowserSelector
} from "features/genomeBrowser";

import GenomeBrowser from "../containers/GenomeBrowser/GenomeBrowser";

const mapStateToProps = genomeBrowserSelector;

const mapDispatchToProps = {
  onInit: genomeBrowserViewInit,
  onTeardown: genomeBrowserViewTeardown,
  onFieldChange: genomeBrowserFormChange,
  onSubmit: genomeBrowserFormSubmit
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenomeBrowser);
