import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import styles from "./style.css";

export class Input extends Component {
  static propTypes = {
    label: PropTypes.node.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
    validationState: PropTypes.oneOf(["error", null]),
    as: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
  };

  state = {
    focused: false
  };

  handleFocus = event => {
    this.setState({ focused: true });
    this.props.onFocus && this.props.onFocus(event);
  };

  handleBlur = event => {
    this.setState({ focused: false });
    this.props.onBlur && this.props.onBlur(event);
  };

  render() {
    const {
      label,
      name,
      inputRef,
      disabled,
      validationState,
      className,
      as: InputComponent = "input",
      ...rest
    } = this.props;

    const focused = this.props.value || this.state.focused;
    const required = this.props.required;

    const wrapperClassName = cx(styles.inputWrapper, {
      [styles.inputWrapperDisabled]: disabled,
      [styles.inputWrapperInvalid]: validationState === "error"
    });
    const labelClassName = cx(styles.inputLabel, {
      [styles.inputLabelFocused]: focused,
      [styles.inputLabelUnfocused]: !focused
    });
    const inputClassName = cx(styles.input, className);

    return (
      <div className={wrapperClassName}>
        <label>
          <div className={labelClassName}>
            {label}
            {required && "*"}
          </div>
          <InputComponent
            className={inputClassName}
            ref={inputRef}
            name={name}
            disabled={disabled}
            {...rest}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
          />
        </label>
      </div>
    );
  }
}

export class Textarea extends Component {
  render() {
    return <Input as="textarea" {...this.props} />;
  }
}

export class Checkbox extends Component {
  static propTypes = {
    label: PropTypes.node.isRequired,
    name: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    inputRef: PropTypes.func,
    validationState: PropTypes.oneOf(["error", null])
  };

  render() {
    const {
      label,
      name,
      type,
      checked,
      inputRef,
      disabled,
      validationState,
      ...rest
    } = this.props;

    const required = this.props.required;
    const invalid = validationState === "error";

    const wrapperClassName = cx(styles.checkboxWrapper, {
      [styles.checkboxWrapperDisabled]: disabled,
      [styles.checkboxWrapperInvalid]: invalid
    });

    return (
      <div className={wrapperClassName}>
        <label>
          <input
            className={styles.checkbox}
            type="checkbox"
            ref={inputRef}
            name={name}
            disabled={disabled}
            checked={checked}
            {...rest}
          />
          <div className={styles.checkboxLabel}>
            {label}
            {required && "*"}
          </div>
        </label>
      </div>
    );
  }
}

export class Select extends Component {
  static propTypes = {
    label: PropTypes.node.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    inputRef: PropTypes.func,
    validationState: PropTypes.oneOf(["error", null]),
    children: PropTypes.node.isRequired
  };

  render() {
    const {
      label,
      name,
      inputRef,
      disabled,
      validationState,
      ...rest
    } = this.props;

    const focused = !!this.props.value;
    const required = this.props.required;

    const wrapperClassName = cx(styles.selectWrapper, {
      [styles.selectWrapperDisabled]: disabled,
      [styles.selectWrapperInvalid]: validationState === "error"
    });
    const labelClassName = cx(styles.selectLabel, {
      [styles.selectLabelFocused]: focused,
      [styles.selectLabelUnfocused]: !focused
    });

    return (
      <div className={wrapperClassName}>
        <label>
          <div className={labelClassName}>
            {label}
            {required && "*"}
          </div>
          <select
            className={styles.select}
            ref={inputRef}
            name={name}
            disabled={disabled}
            {...rest}
          />
        </label>
      </div>
    );
  }
}
