import React, { Component } from "react";
import cx from "classnames";
import { range } from "ramda";

import * as SidebarSection from "containers/Dashboard/SidebarSection/SidebarSection.bs";
import { VerticalLayout, VerticalLayoutItem } from "components/Layout";

import iconChevronDown from "icons/icon-chevron-down-black.svg";
import iconSystemActivity from "./icon-system-activity.svg";
import styles from "./style.css";

class ActivityItem extends Component {
  render() {
    const { name, description, more, icon } = this.props;
    const hasMore = !!more;

    return (
      <div className={styles.itemWrapper}>
        <div className={cx(styles.item, { [styles.itemHasMore]: hasMore })}>
          <div className={styles.icon}>
            <img src={icon} alt="" />
          </div>
          <div className={styles.details}>
            <div className={styles.detailsTop}>
              <div className={styles.name}>{name}</div>
              <div className={styles.timestamp}>1 hour ago</div>
            </div>
            <div className={styles.description}>{description}</div>
          </div>
          {hasMore && (
            <div className={styles.itemMoreNotification}>
              {more} more notifications
            </div>
          )}
        </div>
        {hasMore && <div className={styles.itemBelowFold} />}
      </div>
    );
  }
}

class Activity extends Component {
  rootRef = React.createRef();

  tryScrollIntoView = () => {
    if (this.props.location.hash === "#activity") {
      this.rootRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  componentDidMount() {
    this.tryScrollIntoView();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.hash !== this.props.location.hash) {
      this.tryScrollIntoView();
    }
  }

  render() {
    const items = [
      ...range(240, 247)
        .reverse()
        .map(id => ({
          name: `Job #${id}`,
          description: "Job Completed",
          icon: iconSystemActivity
        })),
      {
        name: "Job #239",
        description: "Job Completed",
        icon: iconSystemActivity,
        more: 21
      }
    ];
    return (
      <VerticalLayout gap="1x">
        <VerticalLayoutItem>
          <div className={styles.header} ref={this.rootRef}>
            <SidebarSection.SectionHeader.make>
              Activity
            </SidebarSection.SectionHeader.make>
            <button className={styles.navigation}>
              Jump to
              <img src={iconChevronDown} alt="" />
            </button>
          </div>
        </VerticalLayoutItem>
        <VerticalLayoutItem>
          <div className={styles.date}>Today</div>
        </VerticalLayoutItem>
        {items.map(({ name, description, icon, more }) => (
          <VerticalLayoutItem key={name}>
            <ActivityItem
              name={name}
              description={description}
              icon={icon}
              more={more}
            />
          </VerticalLayoutItem>
        ))}
      </VerticalLayout>
    );
  }
}

export default Activity;
