

import * as React from "react";

var icon = require("./single-cell-genomics.png");

var tools = /* array */[
  {
    term: /* Bowtie2 */-338593844,
    description: "Ultra-fast, sensitive gapped, short-read aligner."
  },
  {
    term: /* STAR */924625874,
    description: "Ultra-fast universal RNA-seq aligner."
  },
  {
    term: /* Scater */-255823664,
    description: "Pre-processing, quality control, normalization and visualization of single-cell RNA-seq data in R."
  },
  {
    term: /* Seurat */616846914,
    description: "R package designed for QC, analysis, and exploration of single cell RNA-seq data."
  },
  {
    term: /* Monocle */55558457,
    description: "Toolkit for analyzing single cell gene expression experiments - clustering, classifying, and counting cells, Constructing single-cell trajectories and differential expression analysis."
  },
  {
    term: /* MAST */857147637,
    description: "Supervised analyses about differential expression of genes and gene modules, unsupervised analyses of model residuals to generate hypotheses regarding co-expression of genes."
  },
  {
    term: /* SCDE */923781137,
    description: "Single cell differential expression analysis using single cell RNA-seq data."
  },
  {
    term: /* BPSC */735908318,
    description: "Beta-Poisson model for differential expression analysis of single-cell RNA-seq data."
  },
  {
    term: /* HoneyBADGER */-119435830,
    description: "Identify and infer the presence of CNV and LOH events in single cells and reconstructs subclonal architecture using allele and expression information."
  },
  {
    term: /* ChromVAR */593715004,
    description: "R package for the analysis of sparse chromatin accessibility data from single cell or bulk ATAC or DNAse-seq data."
  },
  {
    term: /* Monovar */56500868,
    description: "Statistical method for detecting and genotyping single-nucleotide variants in single-cell data."
  }
];

var fileTypes = /* array */[
  /* Csv */3357606,
  /* Tsv */4202999,
  /* Sam */4149247,
  /* Bam */3303854,
  /* Cram */748691835,
  /* Fasta */243398181,
  /* Fastq */243398197,
  /* Bed */3304737,
  /* Wig */4349941,
  /* BigBed */484974721,
  /* BigWig */486019925,
  /* BedGraph */-809444755,
  /* Gtf */3556729,
  /* Gff3 */792454412,
  /* Vcf */4298873
];

var visualAnalytics = /* array */[
  {
    icon: /* TSNE */935668598,
    description: "Non-linear, non-parametric dimensionality reduction method to visualize cluster structures."
  },
  {
    icon: /* Heatmap */988997452,
    description: "Visualize expression similarity of population markers that can be clustered and reordered to reveal patterns."
  },
  {
    icon: /* Dendrogram */-508885141,
    description: "Tree-like represention of major cell types and subpopulations from hierarchical clustering."
  },
  {
    icon: /* VolcanoPlot */586017299,
    description: "Visualize DEG on a scatter-plot of fold change vs its significance (negative log of the p value)."
  },
  {
    icon: /* VennDiagram */83361156,
    description: "Compare gene lists from various populations."
  },
  {
    icon: /* ParallelCoordinates */-322318508,
    description: "Visualize standardized expression data across developmental cell states."
  },
  {
    icon: /* PrincipalCoordinates */-496309491,
    description: "Identify the principal components that explain a substantial amount of variance in the data."
  },
  {
    icon: /* PathwayMap */316537906,
    description: "Visualize maps of biochemical capability, or signal transduction of enriched gene lists."
  },
  {
    icon: /* NetworkDiagram */-449223643,
    description: "Understand gene co-expression and gene-trait relationships."
  },
  {
    icon: /* GenomeBrowser */-265814703,
    description: "View your transcriptome alignments from a BAM file or as a graph along with reference annotation."
  },
  {
    icon: /* Cytoscape */-265545067,
    description: "Open source software platform for visualizing complex networks and integrating these with any type of attribute data."
  }
];

var description = React.createElement("p", undefined, "High-throughput “bulk”-omics techniques have advanced our understanding of\n    the molecular states of biological systems. However, they can only capture\n    ensemble averages of cell states and are poorly suited to understand cell\n    types, states, transitions, and locations. Massively parallel single cell\n    genomics assays that can profile hundreds of thousands of individual cells\n    is rapidly emerging as a revolutionary technology.");

var overview = React.createElement(React.Fragment, undefined, React.createElement("p", undefined, "Single-cell genomics methods have the potential to transform many areas\n        of biological research such as physiology, developmental biology, and\n        anatomy – in health and disease. The Human Cell Atlas Project, an\n        international collaborative effort, aims to create a comprehensive\n        reference map of the types and properties of all human cells."), React.createElement("p", undefined, "Recently, a number of next-generation sequencing-based assays have been\n        optimized to work at the level of individual cells. While the most\n        widely used of these single-cell techniques is RNA-seq, other techniques\n        that have been adapted for single-cell measurements from “bulk” cell\n        assays include whole-genome bisulfite sequencing, DNase I\n        hypersensitivity sequencing, and ATAC-seq to assay accessible DNA\n        elements. These techniques allow researchers to characterize the genetic\n        and functional properties of individual cells in their native\n        conditions."), React.createElement("p", undefined, "Analyzing single-cell RNA-seq data is substantially more difficult than\n        analyzing data from typical RNA-seq experiments. Typical single-cell\n        studies capture hundreds or even thousands of cells, and generate very\n        large data sets. The size and scale of the data can considerably affect\n        performance of most algorithms. Solvuu’s intelligent execution engine is\n        fully scalable, automatically parallelizes jobs, and can implement\n        workflows using open-source tools."));

var id = "single-cell-genomics";

var title = "Single-cell Genomics";

export {
  id ,
  title ,
  icon ,
  overview ,
  description ,
  tools ,
  fileTypes ,
  visualAnalytics ,
  
}
/* icon Not a pure module */
