

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Krona from "../components/Krona/Krona.bs.js";
import * as Import from "../features/import.bs.js";
import * as Std$Bolt from "bolt/./std.bs.js";
import * as Root$SolvuuApi from "solvuu-api/./root.bs.js";

function ofRecord(record) {
  return Curry._1(Std$Bolt.Res.join, Curry._2(Root$SolvuuApi.Krona_chart.T.of_record, record, (function (data) {
                    return Curry._2(Std$Bolt.Res.map, Curry._1(Std$Bolt.Res.all, Curry._2(Import.List.map, data, (function (x) {
                                          return Curry._2(Root$SolvuuApi.Krona_chart.T.Flat.of_record, x, (function (value, labels) {
                                                        return {
                                                                value: value,
                                                                labels: labels
                                                              };
                                                      }));
                                        }))), (function (data) {
                                  return Krona.makeProps(data, /* () */0);
                                }));
                  })));
}

function ofTerm(term) {
  if (typeof term === "number" || term[0] !== 847309489) {
    return /* `Error */[
            106380200,
            /* Expected_record */-756637544
          ];
  } else {
    var res = ofRecord(term[1]);
    return Curry._2(Std$Bolt.Res.map_error, res, (function (e) {
                  return /* `Record */[
                          847309489,
                          e
                        ];
                }));
  }
}

var Props = {
  ofRecord: ofRecord,
  ofTerm: ofTerm
};

var make = Krona.make;

export {
  Props ,
  make ,
  
}
/* Krona Not a pure module */
