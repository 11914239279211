

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Import from "./features/import.bs.js";
import * as $$String from "bs-platform/lib/es6/string.js";
import * as ReasonReactRouter from "reason-react/src/ReasonReactRouter.js";

function ofUrl(url) {
  var x = url.path;
  if (x) {
    var entityPath = x[0];
    var exit = 0;
    switch (entityPath) {
      case "admin" :
          if (!x[1]) {
            return /* Admin */796400751;
          }
          break;
      case "analysis" :
          if (!x[1]) {
            return /* Analysis */742332284;
          }
          break;
      case "blog" :
          var match = x[1];
          if (match) {
            var slug = match[0];
            var exit$1 = 0;
            if (slug === "categories") {
              var match$1 = match[1];
              if (match$1) {
                if (!match$1[1]) {
                  return /* `Blog */[
                          737307010,
                          /* `Categories */[
                            -139419908,
                            match$1[0]
                          ]
                        ];
                }
                
              } else {
                exit$1 = 3;
              }
            } else {
              exit$1 = 3;
            }
            if (exit$1 === 3 && !match[1]) {
              return /* `Blog */[
                      737307010,
                      /* `Post */[
                        892711040,
                        slug
                      ]
                    ];
            }
            
          } else {
            return /* `Blog */[
                    737307010,
                    /* Main */858743897
                  ];
          }
          break;
      case "charts" :
          var match$2 = x[1];
          if (match$2) {
            if (match$2[0] === "genome" && !match$2[1]) {
              return /* ChartsGenome */-939079988;
            }
            
          } else {
            return /* Charts */-334882315;
          }
          break;
      case "charts2" :
          if (!x[1]) {
            return /* Charts2 */483171485;
          }
          break;
      case "company" :
          if (!x[1]) {
            return /* Company */-793101027;
          }
          break;
      case "drive" :
          var match$3 = x[1];
          if (match$3) {
            if (!match$3[1]) {
              return /* `Drive */[
                      -219555606,
                      /* `Id */[
                        16379,
                        match$3[0]
                      ]
                    ];
            }
            
          } else {
            return /* `Drive */[
                    -219555606,
                    /* Main */858743897
                  ];
          }
          break;
      case "entity" :
          var match$4 = x[1];
          if (match$4) {
            if (!match$4[1]) {
              return /* `Entity */[
                      967870275,
                      /* `Main */[
                        858743897,
                        match$4[0]
                      ]
                    ];
            }
            
          } else {
            exit = 2;
          }
          break;
      case "entityCreator" :
          var match$5 = x[1];
          if (match$5) {
            if (!match$5[1]) {
              return /* `Entity */[
                      967870275,
                      /* `Create */[
                        816044828,
                        match$5[0]
                      ]
                    ];
            }
            
          } else {
            exit = 2;
          }
          break;
      case "jobs" :
          var match$6 = x[1];
          if (match$6) {
            var match$7 = match$6[1];
            var jobId = match$6[0];
            if (match$7) {
              if (match$7[0] === "path" && !match$7[1]) {
                return /* `Jobs */[
                        826169846,
                        /* `Path */[
                          892015045,
                          /* tuple */[
                            jobId,
                            url.hash
                          ]
                        ]
                      ];
              }
              
            } else {
              return /* `Jobs */[
                      826169846,
                      /* `Id */[
                        16379,
                        jobId
                      ]
                    ];
            }
          } else {
            return /* `Jobs */[
                    826169846,
                    /* Main */858743897
                  ];
          }
          break;
      case "login" :
          if (!x[1]) {
            return /* Login */203508041;
          }
          break;
      case "logout" :
          if (!x[1]) {
            return /* Logout */285436586;
          }
          break;
      case "password" :
          var match$8 = x[1];
          if (match$8) {
            switch (match$8[0]) {
              case "request" :
                  if (!match$8[1]) {
                    return /* `PasswordReset */[
                            -799423340,
                            /* Request */299239215
                          ];
                  }
                  break;
              case "update" :
                  if (!match$8[1]) {
                    return /* `PasswordReset */[
                            -799423340,
                            /* Update */999946793
                          ];
                  }
                  break;
              default:
                
            }
          } else {
            exit = 2;
          }
          break;
      case "privacy" :
          if (!x[1]) {
            return /* PrivacyPolicy */-229792710;
          }
          break;
      case "product" :
          if (!x[1]) {
            return /* Product */1062278927;
          }
          break;
      case "query" :
          if (!x[1]) {
            return /* Query */-250086680;
          }
          break;
      case "registration" :
          var match$9 = x[1];
          if (match$9) {
            if (match$9[0] === "confirm" && !match$9[1]) {
              return /* `Registration */[
                      251200025,
                      /* Confirm */-578108192
                    ];
            }
            
          } else {
            return /* `Registration */[
                    251200025,
                    /* Request */299239215
                  ];
          }
          break;
      case "science" :
          var match$10 = x[1];
          if (match$10) {
            if (!match$10[1]) {
              return /* `Science */[
                      -923566716,
                      /* `Item */[
                        815329587,
                        match$10[0]
                      ]
                    ];
            }
            
          } else {
            return /* `Science */[
                    -923566716,
                    /* Main */858743897
                  ];
          }
          break;
      case "settings" :
          var match$11 = x[1];
          if (match$11) {
            if (match$11[0] === "profile" && !match$11[1]) {
              return /* `Settings */[
                      -454084349,
                      /* Profile */-1063620343
                    ];
            }
            
          } else {
            exit = 2;
          }
          break;
      case "terms" :
          if (!x[1]) {
            return /* TermsOfUse */975642249;
          }
          break;
      default:
        exit = 2;
    }
    if (exit === 2 && !x[1]) {
      return /* `Entity */[
              967870275,
              /* `Main */[
                858743897,
                entityPath
              ]
            ];
    }
    
  } else {
    return /* Home */803993151;
  }
  console.log(Curry._1(Import.List.toArray, x));
  return /* PageNotFound */-442163778;
}

function toUrl(t) {
  var path;
  if (typeof t === "number") {
    path = t >= 285436586 ? (
        t >= 796400751 ? (
            t >= 975642249 ? (
                t >= 1062278927 ? /* :: */[
                    "product",
                    /* [] */0
                  ] : /* :: */[
                    "terms",
                    /* [] */0
                  ]
              ) : (
                t >= 803993151 ? /* [] */0 : /* :: */[
                    "admin",
                    /* [] */0
                  ]
              )
          ) : (
            t !== 483171485 ? (
                t >= 742332284 ? /* :: */[
                    "analysis",
                    /* [] */0
                  ] : /* :: */[
                    "logout",
                    /* [] */0
                  ]
              ) : /* :: */[
                "charts2",
                /* [] */0
              ]
          )
      ) : (
        t >= -334882315 ? (
            t >= -229792710 ? (
                t >= 203508041 ? /* :: */[
                    "login",
                    /* [] */0
                  ] : /* :: */[
                    "privacy",
                    /* [] */0
                  ]
              ) : (
                t >= -250086680 ? /* :: */[
                    "query",
                    /* [] */0
                  ] : /* :: */[
                    "charts",
                    /* [] */0
                  ]
              )
          ) : (
            t !== -793101027 ? (
                t >= -442163778 ? /* [] */0 : /* :: */[
                    "charts",
                    /* :: */[
                      "genome",
                      /* [] */0
                    ]
                  ]
              ) : /* :: */[
                "company",
                /* [] */0
              ]
          )
      );
  } else {
    var variant = t[0];
    if (variant >= 251200025) {
      if (variant >= 826169846) {
        if (variant >= 967870275) {
          var match = t[1];
          if (match[0] >= 858743897) {
            var path$1 = match[1];
            var match$1 = $$String.equal(path$1, "");
            path = match$1 ? /* :: */[
                path$1,
                /* [] */0
              ] : /* :: */[
                "entity",
                /* :: */[
                  path$1,
                  /* [] */0
                ]
              ];
          } else {
            var path$2 = match[1];
            var match$2 = $$String.equal(path$2, "");
            path = match$2 ? /* :: */[
                path$2,
                /* [] */0
              ] : /* :: */[
                "entity",
                /* :: */[
                  path$2,
                  /* [] */0
                ]
              ];
          }
        } else {
          var match$3 = t[1];
          path = typeof match$3 === "number" ? /* :: */[
              "jobs",
              /* [] */0
            ] : (
              match$3[0] >= 892015045 ? /* :: */[
                  "jobs",
                  /* :: */[
                    match$3[1][0],
                    /* :: */[
                      "path",
                      /* [] */0
                    ]
                  ]
                ] : /* :: */[
                  "jobs",
                  /* :: */[
                    match$3[1],
                    /* [] */0
                  ]
                ]
            );
        }
      } else if (variant >= 737307010) {
        var match$4 = t[1];
        path = typeof match$4 === "number" ? /* :: */[
            "blog",
            /* [] */0
          ] : (
            match$4[0] >= 892711040 ? /* :: */[
                "blog",
                /* :: */[
                  match$4[1],
                  /* [] */0
                ]
              ] : /* :: */[
                "blog",
                /* :: */[
                  "categories",
                  /* :: */[
                    match$4[1],
                    /* [] */0
                  ]
                ]
              ]
          );
      } else {
        path = t[1] >= 299239215 ? /* :: */[
            "registration",
            /* [] */0
          ] : /* :: */[
            "registration",
            /* :: */[
              "confirm",
              /* [] */0
            ]
          ];
      }
    } else if (variant >= -454084349) {
      if (variant >= -219555606) {
        var match$5 = t[1];
        path = typeof match$5 === "number" ? /* :: */[
            "drive",
            /* [] */0
          ] : /* :: */[
            "drive",
            /* :: */[
              match$5[1],
              /* [] */0
            ]
          ];
      } else {
        path = /* :: */[
          "settings",
          /* :: */[
            "profile",
            /* [] */0
          ]
        ];
      }
    } else if (variant >= -799423340) {
      path = t[1] >= 999946793 ? /* :: */[
          "password",
          /* :: */[
            "update",
            /* [] */0
          ]
        ] : /* :: */[
          "password",
          /* :: */[
            "request",
            /* [] */0
          ]
        ];
    } else {
      var match$6 = t[1];
      path = typeof match$6 === "number" ? /* :: */[
          "science",
          /* [] */0
        ] : /* :: */[
          "science",
          /* :: */[
            match$6[1],
            /* [] */0
          ]
        ];
    }
  }
  var hash;
  if (typeof t === "number" || t[0] !== 826169846) {
    hash = "";
  } else {
    var match$7 = t[1];
    hash = typeof match$7 === "number" || match$7[0] !== 892015045 ? "" : match$7[1][1];
  }
  return {
          path: path,
          hash: hash,
          search: ""
        };
}

function toUrlString(param) {
  var search = param.search;
  var hash = param.hash;
  var search$1 = search === "" ? "" : "?" + search;
  var hash$1 = hash === "" ? "" : "#" + hash;
  var path = "/" + Curry._1(Import.List.toArray, param.path).join("/");
  return path + (search$1 + hash$1);
}

function push(t) {
  return ReasonReactRouter.push(toUrlString(toUrl(t)));
}

var toUrl$1 = toUrl;

function blogPost(slug) {
  return /* `Blog */[
          737307010,
          /* `Post */[
            892711040,
            slug
          ]
        ];
}

function entityCreate(path) {
  return /* `Entity */[
          967870275,
          /* `Create */[
            816044828,
            path
          ]
        ];
}

var Js_jobs = /* `Jobs */[
  826169846,
  /* Main */858743897
];

var Js = {
  toUrl: toUrl$1,
  blogPost: blogPost,
  entityCreate: entityCreate,
  jobs: Js_jobs
};

export {
  ofUrl ,
  toUrl ,
  toUrlString ,
  push ,
  Js ,
  
}
/* Import Not a pure module */
