

import * as Js_dict from "bs-platform/lib/es6/js_dict.js";

var en = Js_dict.fromArray(/* array */[
      /* tuple */[
        "admin.documentTitle",
        "Admin"
      ],
      /* tuple */[
        "analysis.documentTitle",
        "Omics Portal"
      ],
      /* tuple */[
        "analysis.header",
        "Omics Portal"
      ],
      /* tuple */[
        "analysis.tileGroups.microbialCommunity",
        "Microbial Community"
      ],
      /* tuple */[
        "analysis.tileGroups.host",
        "Host"
      ],
      /* tuple */[
        "analysis.tiles.metaprofiling.title",
        "Meta-profiling"
      ],
      /* tuple */[
        "analysis.tiles.metaprofiling.content",
        "Analyze microbial community composition from targeted amplicon-sequencing data for marker genes (16S, 18S or ITS)"
      ],
      /* tuple */[
        "analysis.tiles.metagenomics.title",
        "Metagenomics"
      ],
      /* tuple */[
        "analysis.tiles.metagenomics.content",
        "Analyze composition, diversity and functioning of microbial ecosystems from metagenomic samples"
      ],
      /* tuple */[
        "analysis.tiles.metatranscriptomics.title",
        "Metatranscriptomics"
      ],
      /* tuple */[
        "analysis.tiles.metatranscriptomics.content",
        "Analyze taxonomy, gene expression, and metabolic potential of microbial ecosystems"
      ],
      /* tuple */[
        "analysis.tiles.metaproteomics.title",
        "Metaproteomics"
      ],
      /* tuple */[
        "analysis.tiles.metaproteomics.content",
        "Identification, quantitation and functional characterization of expressed proteins from a microbial community"
      ],
      /* tuple */[
        "analysis.tiles.metabolomics.title",
        "Metabolomics"
      ],
      /* tuple */[
        "analysis.tiles.metabolomics.content",
        "Analyse chemical diversity of microbial metabolites from microbial ecosystems"
      ],
      /* tuple */[
        "analysis.tiles.viromics.title",
        "Viromics"
      ],
      /* tuple */[
        "analysis.tiles.viromics.content",
        "Retrieve, reconstruct and characterize viral genomes from microbial samples"
      ],
      /* tuple */[
        "analysis.tiles.ChIPSeq.title",
        "ChIP-Seq"
      ],
      /* tuple */[
        "analysis.tiles.ChIPSeq.content",
        "Identify and characterize genome-wide DNA binding sites for transcription factors and other proteins"
      ],
      /* tuple */[
        "analysis.tiles.DNAMethylation.title",
        "DNA Methylation"
      ],
      /* tuple */[
        "analysis.tiles.DNAMethylation.content",
        "Analyze genome-wide methylation status for epigenomic studies"
      ],
      /* tuple */[
        "analysis.tiles.deNovoAssembly.title",
        "De Novo Assembly"
      ],
      /* tuple */[
        "analysis.tiles.deNovoAssembly.content",
        "Assembly, structural and functional annotation of genomes and transcriptomes"
      ],
      /* tuple */[
        "analysis.tiles.transcriptomics.title",
        "Transcriptomics"
      ],
      /* tuple */[
        "analysis.tiles.transcriptomics.content",
        "Quantitation of gene and transcript expression levels using reference annotation"
      ],
      /* tuple */[
        "analysis.tiles.proteomics.title",
        "Proteomics"
      ],
      /* tuple */[
        "analysis.tiles.proteomics.content",
        "Identification, quantitation and functional characterization of expressed proteins from a population of cells"
      ],
      /* tuple */[
        "analysis.tiles.variantCalling.title",
        "Variant Calling"
      ],
      /* tuple */[
        "analysis.tiles.variantCalling.content",
        "Identify, and annotate variants from whole-genome sequencing and targeted re-sequencing data"
      ],
      /* tuple */[
        "csv.loading",
        "Downloading CSV data"
      ],
      /* tuple */[
        "csv.loading.details",
        "(this may take a moment)"
      ],
      /* tuple */[
        "biom.loading",
        "Downloading BIOM data"
      ],
      /* tuple */[
        "biom.loading.details",
        "(this may take a moment)"
      ],
      /* tuple */[
        "biom.taxonomicAnalysis.unavailable",
        "Taxonomic metadata is missing from this file"
      ],
      /* tuple */[
        "biom.taxonomicAnalysis.tabs.area",
        "Area Chart"
      ],
      /* tuple */[
        "biom.taxonomicAnalysis.tabs.bar",
        "Bar Chart"
      ],
      /* tuple */[
        "biom.taxonomicAnalysis.tabs.bubble",
        "Bubble Chart"
      ],
      /* tuple */[
        "biom.taxonomicAnalysis.tabs.sankey",
        "Sankey Diagram"
      ],
      /* tuple */[
        "biom.taxonomicAnalysis.tabs.krona",
        "Krona Chart"
      ],
      /* tuple */[
        "biom.taxonomicAnalysis.barChart.summary",
        "Showing {counts} counts across {taxonomies} taxa and {samples} samples"
      ],
      /* tuple */[
        "biom.taxonomicAnalysis.barChart.search.placeholder",
        "Search for a taxon..."
      ],
      /* tuple */[
        "biom.taxonomicAnalysis.barChart.expandOptions.counts",
        "Counts"
      ],
      /* tuple */[
        "biom.taxonomicAnalysis.barChart.expandOptions.percentages",
        "Percentages"
      ],
      /* tuple */[
        "biom.taxonomicAnalysis.barChart.tooltip.sample",
        "Sample:"
      ],
      /* tuple */[
        "biom.taxonomicAnalysis.barChart.tooltip.taxonomy",
        "Taxonomy:"
      ],
      /* tuple */[
        "biom.taxonomicAnalysis.barChart.tooltip.distributionInSample",
        "{value} / {total} ({percentage}) of counts in this sample"
      ],
      /* tuple */[
        "biom.taxonomicAnalysis.barChart.tooltip.distributionInDataset",
        "{value} / {total} ({percentage}) of total counts"
      ],
      /* tuple */[
        "biom.taxonomicAnalysis.barChart.sortingControls.addCriterion",
        "+ Add sorting criterion"
      ],
      /* tuple */[
        "biom.taxonomicAnalysis.barChart.sortingControls.ascending",
        "Asc"
      ],
      /* tuple */[
        "biom.taxonomicAnalysis.barChart.sortingControls.descending",
        "Desc"
      ],
      /* tuple */[
        "biom.taxonomicAnalysis.barChart.sortingControls.sampleMetadataAttributes",
        "Sample metadata"
      ],
      /* tuple */[
        "biom.taxonomicAnalysis.barChart.sortingControls.taxonomicAbundanceAttributes",
        "Taxonomic abundance"
      ],
      /* tuple */[
        "biom.taxonomicAnalysis.areaChart.summary",
        "Showing {counts} counts across {taxonomies} taxa and {samples} samples"
      ],
      /* tuple */[
        "biom.taxonomicAnalysis.areaChart.yAxis.label",
        "Relative abundance"
      ],
      /* tuple */[
        "biom.taxonomicAnalysis.areaChart.search.placeholder",
        "Search for a taxon..."
      ],
      /* tuple */[
        "biom.taxonomicAnalysis.areaChart.tooltip.sample",
        "Sample:"
      ],
      /* tuple */[
        "biom.taxonomicAnalysis.areaChart.tooltip.taxonomy",
        "Taxonomy:"
      ],
      /* tuple */[
        "biom.taxonomicAnalysis.areaChart.tooltip.distributionInSample",
        "{value} / {total} ({percentage}) of counts in this sample"
      ],
      /* tuple */[
        "biom.taxonomicAnalysis.areaChart.tooltip.distributionInDataset",
        "{value} / {total} ({percentage}) of total counts"
      ],
      /* tuple */[
        "biom.taxonomicAnalysis.bubbleChart.summary",
        "Showing {counts} counts across {taxonomies} taxa and {observations} OTUs"
      ],
      /* tuple */[
        "biom.taxonomicAnalysis.bubbleChart.layouts.list",
        "List"
      ],
      /* tuple */[
        "biom.taxonomicAnalysis.bubbleChart.layouts.pack",
        "Pack"
      ],
      /* tuple */[
        "biom.taxonomicAnalysis.bubbleChart.search.placeholder",
        "Search for a taxon..."
      ],
      /* tuple */[
        "biom.taxonomicAnalysis.bubbleChart.tooltip.taxonomy",
        "Taxonomy:"
      ],
      /* tuple */[
        "biom.taxonomicAnalysis.bubbleChart.tooltip.countDistributionInDataset",
        "{value} / {total} ({percentage}) of total counts"
      ],
      /* tuple */[
        "biom.taxonomicAnalysis.bubbleChart.tooltip.observationDistributionInDataset",
        "{value} / {total} ({percentage}) of total OTUs"
      ],
      /* tuple */[
        "biom.taxonomicAnalysis.bubbleChart.overlay.title",
        "{taxonomy} has {counts} counts and {observations} OTUs"
      ],
      /* tuple */[
        "biom.taxonomicAnalysis.bubbleChart.overlay.sample",
        "{counts} / {totalCounts} ({percentage})"
      ],
      /* tuple */[
        "biom.taxonomicAnalysis.sankeyDiagram.summary",
        "Showing {links} links between {nodes} taxa"
      ],
      /* tuple */[
        "biom.taxonomicAnalysis.sankeyDiagram.search.placeholder",
        "Search for a taxon..."
      ],
      /* tuple */[
        "biom.taxonomicAnalysis.sankeyDiagram.overlay.title",
        "{taxonomy} has {counts} counts and {branches} branches"
      ],
      /* tuple */[
        "biom.taxonomicAnalysis.kronaChart.blank",
        "No sample selected"
      ],
      /* tuple */[
        "biom.taxonomicAnalysis.kronaChart.sampleSelect.placeholder",
        "Select a sample..."
      ],
      /* tuple */[
        "dashboard.loading",
        "Please wait"
      ],
      /* tuple */[
        "dashboard.loading.details",
        "Your Dashboard is loading"
      ],
      /* tuple */[
        "dashboard.documentTitle",
        "Dashboard"
      ],
      /* tuple */[
        "documentTitle",
        "Solvuu"
      ],
      /* tuple */[
        "errorBoundary.title",
        "Oops... this page has crashed."
      ],
      /* tuple */[
        "errorBoundary.message",
        "Please try again later."
      ],
      /* tuple */[
        "entityCreator.documentTitle",
        "{entityName} / new"
      ],
      /* tuple */[
        "entityCreator.header",
        "new"
      ],
      /* tuple */[
        "entityCreator.unsavedChangesWarning",
        "The form has unsaved changes. Click Ok to discard them."
      ],
      /* tuple */[
        "entityCreator.fields.name",
        "Name"
      ],
      /* tuple */[
        "entityCreator.fields.name.description",
        "Only letters, numbers, underscores and hyphens are allowed"
      ],
      /* tuple */[
        "entityCreator.fields.type",
        "Type"
      ],
      /* tuple */[
        "entityCreator.fields.types.Project",
        "Project"
      ],
      /* tuple */[
        "entityCreator.fields.types.Record",
        "Folder"
      ],
      /* tuple */[
        "entityCreator.fields.types.Term",
        "Term"
      ],
      /* tuple */[
        "entityCreator.fields.types.Table",
        "Table (from CSV file)"
      ],
      /* tuple */[
        "entityCreator.fields.types.Blobs",
        "Files"
      ],
      /* tuple */[
        "entityCreator.fields.project.title",
        "Project title"
      ],
      /* tuple */[
        "entityCreator.fields.project.abstract",
        "Project abstract"
      ],
      /* tuple */[
        "entityCreator.fields.project.lead",
        "Project lead"
      ],
      /* tuple */[
        "entityCreator.fields.project.informaticsContact",
        "Informatics Contact"
      ],
      /* tuple */[
        "entityCreator.fields.project.dataHandoff",
        "Data Handoff"
      ],
      /* tuple */[
        "entityCreator.fields.project.analysisType",
        "Analysis Type"
      ],
      /* tuple */[
        "entityCreator.fields.project.platform",
        "Platform"
      ],
      /* tuple */[
        "entityCreator.fields.bundle.addPart",
        "+ Add file"
      ],
      /* tuple */[
        "entityCreator.fields.bundle.removePart",
        "× Remove"
      ],
      /* tuple */[
        "entityCreator.fields.format",
        "Format"
      ],
      /* tuple */[
        "entityCreator.fields.biom.format",
        "BIOM format"
      ],
      /* tuple */[
        "entityCreator.fields.csv.separator",
        "Field separator"
      ],
      /* tuple */[
        "entityCreator.fields.csv.columnsSummary",
        "Customize columns ({count} found)"
      ],
      /* tuple */[
        "entityCreator.fields.csv.addColumn",
        "+ Add column"
      ],
      /* tuple */[
        "entityCreator.fields.csv.editColumn",
        "edit"
      ],
      /* tuple */[
        "entityCreator.fields.csv.removeColumn",
        "remove"
      ],
      /* tuple */[
        "entityCreator.fields.csv.column.header",
        "Header"
      ],
      /* tuple */[
        "entityCreator.fields.csv.advancedSummary",
        "Advanced options"
      ],
      /* tuple */[
        "entityCreator.fields.csv.quote",
        "Quote character"
      ],
      /* tuple */[
        "entityCreator.fields.csv.quote.description",
        "Optional character that surrounds each data field"
      ],
      /* tuple */[
        "entityCreator.fields.csv.hasHeader",
        "Header present"
      ],
      /* tuple */[
        "entityCreator.fields.csv.hasHeader.description",
        "If checked, first line in the CSV file will be treated as column names"
      ],
      /* tuple */[
        "entityCreator.fields.csv.allowComments",
        "Allow comments"
      ],
      /* tuple */[
        "entityCreator.fields.csv.allowComments.description",
        "Lines starting with # will be ignored"
      ],
      /* tuple */[
        "entityCreator.fields.csv.shiftHeader",
        "Shift header"
      ],
      /* tuple */[
        "entityCreator.fields.csv.shiftHeader.description",
        "If checked, a single empty header will be prepended to the original header. Useful when the number of data columns in the original file is less than the number of header columns."
      ],
      /* tuple */[
        "entityCreator.fields.fasta.alphabet",
        "Alphabet"
      ],
      /* tuple */[
        "entityCreator.fields.fasta.maximumLineLength",
        "Maximum line length"
      ],
      /* tuple */[
        "entityCreator.fields.fasta.allowSharpComments",
        "Allow sharp comments"
      ],
      /* tuple */[
        "entityCreator.fields.fasta.allowSemicolonComments",
        "Allow semicolon comments"
      ],
      /* tuple */[
        "entityCreator.fields.fasta.allowEmptyLines",
        "Allow empty lines"
      ],
      /* tuple */[
        "entityCreator.fields.fasta.allowCommentsOnlyAtTop",
        "Allow comments only at the top"
      ],
      /* tuple */[
        "entityCreator.fields.fastq.format",
        "Fastq format"
      ],
      /* tuple */[
        "entityCreator.fields.gff.format",
        "GFF format"
      ],
      /* tuple */[
        "entityCreator.fields.blobs.name",
        "Name"
      ],
      /* tuple */[
        "entityCreator.fields.blobs.format",
        "Format"
      ],
      /* tuple */[
        "entityCreator.fields.blobs.format.edit",
        "edit"
      ],
      /* tuple */[
        "entityCreator.fields.blobs.format.inferring",
        "Inferring"
      ],
      /* tuple */[
        "entityCreator.fields.blobs.format.optional",
        "Optional"
      ],
      /* tuple */[
        "entityCreator.fields.blobs.format.gzipped",
        "Gzipped"
      ],
      /* tuple */[
        "entityCreator.fields.blobs.format.save",
        "Save format"
      ],
      /* tuple */[
        "entityCreator.fields.blobs.format.cancel",
        "Cancel"
      ],
      /* tuple */[
        "entityCreator.fields.value",
        "Value"
      ],
      /* tuple */[
        "entityCreator.submit",
        "Create"
      ],
      /* tuple */[
        "entityCreator.submitBlobs",
        "Start upload"
      ],
      /* tuple */[
        "entityCreator.addNew",
        "+ Add"
      ],
      /* tuple */[
        "entities.documentTitle",
        "{entityName}"
      ],
      /* tuple */[
        "entities.events",
        "Activity Log"
      ],
      /* tuple */[
        "entities.terms.type",
        "Type"
      ],
      /* tuple */[
        "entities.terms.value",
        "Value"
      ],
      /* tuple */[
        "entities.actions.download.menuItem",
        "Download"
      ],
      /* tuple */[
        "entities.blob.preview",
        "Preview"
      ],
      /* tuple */[
        "entities.blob.preview.snipped",
        "Warning: Blob is too big. Showing first {bytes} bytes."
      ],
      /* tuple */[
        "entities.blob.preview.binary",
        "Warning: this may be a binary file"
      ],
      /* tuple */[
        "entities.blob.preview.confirmBinary",
        "Show it anyway"
      ],
      /* tuple */[
        "entities.blob.actions.download.errors.credentials",
        "Cannot obtain permission for file download"
      ],
      /* tuple */[
        "entities.blob.visualization.crashed",
        "We\'re sorry, something went wrong with the file preview..."
      ],
      /* tuple */[
        "entities.HTML.title",
        "Embedded HTML file"
      ],
      /* tuple */[
        "entities.record.actions.addFile",
        "Choose file"
      ],
      /* tuple */[
        "entities.record.actions.dropFile",
        "Drag and drop files from your computer here"
      ],
      /* tuple */[
        "entities.record.summary.one",
        "1 item"
      ],
      /* tuple */[
        "entities.record.summary.many",
        "{count} items"
      ],
      /* tuple */[
        "entities.record.list.name",
        "Name"
      ],
      /* tuple */[
        "entities.record.list.bytesSize",
        "Size"
      ],
      /* tuple */[
        "footer.copyright",
        "© 2019 Solvuu, Inc. All Rights Reserved"
      ],
      /* tuple */[
        "form.errors.title",
        "Oops... there was a problem with your form."
      ],
      /* tuple */[
        "form.errors.unknown",
        "Something unexpected happened."
      ],
      /* tuple */[
        "form.requiredField",
        "Required"
      ],
      /* tuple */[
        "genomeBrowser.documentTitle",
        "Genome browser"
      ],
      /* tuple */[
        "genomeBrowser.header",
        "Visualize genome"
      ],
      /* tuple */[
        "navigation.brand",
        "Solvuu"
      ],
      /* tuple */[
        "navigation.login",
        "Login"
      ],
      /* tuple */[
        "navigation.dashboard",
        "Dashboard"
      ],
      /* tuple */[
        "navigation.analysis",
        "Omics portal"
      ],
      /* tuple */[
        "navigation.jobs",
        "Compute"
      ],
      /* tuple */[
        "navigation.upload",
        "Drive"
      ],
      /* tuple */[
        "navigation.upload.uploading",
        "Drive (uploading)"
      ],
      /* tuple */[
        "navigation.solvuuQuery",
        "Query"
      ],
      /* tuple */[
        "navigation.charts",
        "Charts"
      ],
      /* tuple */[
        "navigation.settings",
        "Settings"
      ],
      /* tuple */[
        "navigation.logout",
        "Log out"
      ],
      /* tuple */[
        "navigation.admin",
        "Admin"
      ],
      /* tuple */[
        "jobs.documentTitle",
        "Compute"
      ],
      /* tuple */[
        "jobs.header",
        "Jobs"
      ],
      /* tuple */[
        "jobs.empty",
        "There\'s nothing here yet"
      ],
      /* tuple */[
        "jobs.totalJobs",
        "{count} total"
      ],
      /* tuple */[
        "jobs.lastSuccessAt",
        "Last update: "
      ],
      /* tuple */[
        "jobs.actions.addNew",
        "Add new job"
      ],
      /* tuple */[
        "jobs.actions.show",
        "Details"
      ],
      /* tuple */[
        "jobs.actions.refresh",
        "Refresh"
      ],
      /* tuple */[
        "jobs.id",
        "ID"
      ],
      /* tuple */[
        "jobs.spec",
        "Kind"
      ],
      /* tuple */[
        "jobs.command",
        "Command"
      ],
      /* tuple */[
        "jobs.cpu",
        "CPU"
      ],
      /* tuple */[
        "jobs.mem",
        "Mem"
      ],
      /* tuple */[
        "jobs.disk",
        "Disk"
      ],
      /* tuple */[
        "jobs.container",
        "Container"
      ],
      /* tuple */[
        "jobs.status",
        "Status"
      ],
      /* tuple */[
        "jobs.time",
        "Time"
      ],
      /* tuple */[
        "jobs.image",
        "Image"
      ],
      /* tuple */[
        "jobs.uris",
        "URIs"
      ],
      /* tuple */[
        "jobs.forcePullImage",
        "Force pull image"
      ],
      /* tuple */[
        "jobs.network",
        "Network"
      ],
      /* tuple */[
        "jobs.volumes",
        "Volumes"
      ],
      /* tuple */[
        "jobs.parents",
        "Parents"
      ],
      /* tuple */[
        "jobs.command.actions.showRaw",
        "show raw"
      ],
      /* tuple */[
        "jobs.command.actions.showFormatted",
        "show formatted"
      ],
      /* tuple */[
        "jobs.form.command",
        "Command"
      ],
      /* tuple */[
        "jobs.form.image",
        "Tool"
      ],
      /* tuple */[
        "jobs.form.resources.cpu",
        "CPU cores"
      ],
      /* tuple */[
        "jobs.form.resources.mem",
        "Memory limit"
      ],
      /* tuple */[
        "jobs.form.resources.disk",
        "Disk limit"
      ],
      /* tuple */[
        "jobs.form.submit",
        "Submit job"
      ],
      /* tuple */[
        "jobs.show.header",
        "Job #{jobId}"
      ],
      /* tuple */[
        "jobs.show.lastSuccessAt",
        "Last update: "
      ],
      /* tuple */[
        "jobs.show.status",
        "Status"
      ],
      /* tuple */[
        "jobs.show.details",
        "Details"
      ],
      /* tuple */[
        "jobs.show.details.kind",
        "Kind"
      ],
      /* tuple */[
        "jobs.show.details.solvuu.command",
        "Command"
      ],
      /* tuple */[
        "jobs.show.details.solvuu.command.spec",
        "Spec"
      ],
      /* tuple */[
        "jobs.show.details.solvuu.time",
        "Timing"
      ],
      /* tuple */[
        "jobs.show.details.solvuu.time.created",
        "Created"
      ],
      /* tuple */[
        "jobs.show.details.solvuu.time.duration",
        "Duration"
      ],
      /* tuple */[
        "jobs.show.details.solvuu.output",
        "Results"
      ],
      /* tuple */[
        "jobs.show.details.solvuu.output.files.one",
        "1 file | Total size: {size}"
      ],
      /* tuple */[
        "jobs.show.details.solvuu.output.files.many",
        "{count} files | Total size: {size}"
      ],
      /* tuple */[
        "jobs.show.details.solvuu.command.env",
        "Environment"
      ],
      /* tuple */[
        "jobs.show.details.solvuu.container",
        "Tool"
      ],
      /* tuple */[
        "jobs.show.details.solvuu.container.image",
        "Image"
      ],
      /* tuple */[
        "jobs.show.details.solvuu.container.cached",
        "Cached"
      ],
      /* tuple */[
        "jobs.show.details.solvuu.resources",
        "Resource limits"
      ],
      /* tuple */[
        "jobs.show.details.solvuu.resources.cpu",
        "CPU"
      ],
      /* tuple */[
        "jobs.show.details.solvuu.resources.mem",
        "Memory"
      ],
      /* tuple */[
        "jobs.show.details.solvuu.resources.disk",
        "Disk"
      ],
      /* tuple */[
        "jobs.show.details.backend",
        "Backend"
      ],
      /* tuple */[
        "jobs.show.details.backend.show",
        "Show"
      ],
      /* tuple */[
        "jobs.show.metrics",
        "Resource usage"
      ],
      /* tuple */[
        "jobs.show.metrics.mem",
        "Memory"
      ],
      /* tuple */[
        "jobs.show.metrics.disk",
        "Disk"
      ],
      /* tuple */[
        "jobs.show.metrics.cpu",
        "CPU"
      ],
      /* tuple */[
        "jobs.show.input",
        "Input"
      ],
      /* tuple */[
        "jobs.show.input.blobs",
        "Blobs"
      ],
      /* tuple */[
        "jobs.show.output",
        "Output"
      ],
      /* tuple */[
        "jobs.show.output.prefix",
        "Prefix"
      ],
      /* tuple */[
        "jobs.show.output.blobs",
        "Blobs"
      ],
      /* tuple */[
        "jobs.show.events",
        "Events"
      ],
      /* tuple */[
        "loading",
        "loading"
      ],
      /* tuple */[
        "loading.error.title",
        "Oops... we’ve encountered an error."
      ],
      /* tuple */[
        "loading.error.message",
        "Please try again later."
      ],
      /* tuple */[
        "login.documentTitle",
        "Login"
      ],
      /* tuple */[
        "login.header",
        "Login"
      ],
      /* tuple */[
        "login.username",
        "Username"
      ],
      /* tuple */[
        "login.password",
        "Password"
      ],
      /* tuple */[
        "login.mfaCode",
        "Enter authentication code"
      ],
      /* tuple */[
        "login.mfaCode.info",
        "Code was sent to {destination}"
      ],
      /* tuple */[
        "login.submit",
        "Login"
      ],
      /* tuple */[
        "login.passwordResetLink",
        "Forgot password"
      ],
      /* tuple */[
        "login.registrationLink",
        "Create account"
      ],
      /* tuple */[
        "login.submitMFA",
        "Submit"
      ],
      /* tuple */[
        "login.authenticationRequiredAlert.message",
        "404: Page not found"
      ],
      /* tuple */[
        "operationError.unexpected",
        "Oops... we\'ve encountered an unexpected error."
      ],
      /* tuple */[
        "operationError.expected",
        "Oops... we\'ve encountered an error."
      ],
      /* tuple */[
        "operationError.defaultMessage",
        "Please try again later."
      ],
      /* tuple */[
        "operationError.showDetails",
        "More details"
      ],
      /* tuple */[
        "passwordReset.request.documentTitle",
        "Reset password"
      ],
      /* tuple */[
        "passwordReset.request.header",
        "Reset password"
      ],
      /* tuple */[
        "passwordReset.request.success.title",
        "Success"
      ],
      /* tuple */[
        "passwordReset.request.success.message",
        "Check your email for further instructions"
      ],
      /* tuple */[
        "passwordReset.request.submit",
        "Submit"
      ],
      /* tuple */[
        "passwordReset.form.username",
        "Username"
      ],
      /* tuple */[
        "passwordReset.form.password",
        "New password"
      ],
      /* tuple */[
        "passwordReset.form.passwordConfirmation",
        "Confirm new password"
      ],
      /* tuple */[
        "passwordReset.update.documentTitle",
        "Set new password"
      ],
      /* tuple */[
        "passwordReset.update.header",
        "Set new password"
      ],
      /* tuple */[
        "passwordReset.update.submit",
        "Submit"
      ],
      /* tuple */[
        "phylogeneticTree.layout",
        "Layout"
      ],
      /* tuple */[
        "phylogeneticTree.layout.rectangular",
        "Rectangular"
      ],
      /* tuple */[
        "phylogeneticTree.layout.circular",
        "Circular"
      ],
      /* tuple */[
        "registration.documentTitle",
        "Registration"
      ],
      /* tuple */[
        "registration.header",
        "Create an account"
      ],
      /* tuple */[
        "registration.username",
        "Username"
      ],
      /* tuple */[
        "registration.name",
        "Name"
      ],
      /* tuple */[
        "registration.email",
        "Email"
      ],
      /* tuple */[
        "registration.password",
        "Password"
      ],
      /* tuple */[
        "registration.passwordConfirmation",
        "Confirm password"
      ],
      /* tuple */[
        "registration.acceptTerms",
        "I agree to the {link}"
      ],
      /* tuple */[
        "registration.acceptTerms.link",
        "Terms of Use"
      ],
      /* tuple */[
        "registration.submit",
        "Create account"
      ],
      /* tuple */[
        "registration.login",
        "Already have an account?"
      ],
      /* tuple */[
        "registration.loginLink",
        "Login"
      ],
      /* tuple */[
        "registration.errors.title",
        "There was a problem with your request"
      ],
      /* tuple */[
        "registration.errors.username.duplicated",
        "This username is already taken"
      ],
      /* tuple */[
        "registration.errors.passwordConfirmation.notMatching",
        "Passwords don\'t match"
      ],
      /* tuple */[
        "registration.errors.acceptTerms.notAccepted",
        "It is required to accept Terms of Use"
      ],
      /* tuple */[
        "registration.errors.unknown",
        "Something unexpected happened"
      ],
      /* tuple */[
        "registration.success.title",
        "Administrator confirmation is required"
      ],
      /* tuple */[
        "registration.success.message",
        "An administrator needs to verify your request. You\'ll receive an email with further instructions soon."
      ],
      /* tuple */[
        "registration.confirmation.documentTitle",
        "Confirm registration"
      ],
      /* tuple */[
        "registration.confirmation.success.title",
        "Confirmation successful!"
      ],
      /* tuple */[
        "registration.confirmation.success.message",
        "You can now log in"
      ],
      /* tuple */[
        "registration.confirmation.resend",
        "Resend confirmation code"
      ],
      /* tuple */[
        "registration.confirmation.resend.success.title",
        "Confirmation code was resent"
      ],
      /* tuple */[
        "registration.confirmation.resend.success.message",
        "Check your email for further instructions"
      ],
      /* tuple */[
        "settings.documentTitle",
        "Settings"
      ],
      /* tuple */[
        "settings.header",
        "Settings"
      ],
      /* tuple */[
        "settings.log.documentTitle",
        "Settings / Log"
      ],
      /* tuple */[
        "settings.log.eventId",
        "ID"
      ],
      /* tuple */[
        "settings.log.timestamp",
        "Time"
      ],
      /* tuple */[
        "settings.log.by",
        "By"
      ],
      /* tuple */[
        "settings.log.info",
        "Event"
      ],
      /* tuple */[
        "settings.log.info.show",
        "Show full event"
      ],
      /* tuple */[
        "settings.profile",
        "Profile"
      ],
      /* tuple */[
        "settings.profile.documentTitle",
        "Settings / Profile"
      ],
      /* tuple */[
        "settings.profile.header",
        "User details"
      ],
      /* tuple */[
        "settings.profile.username",
        "Username"
      ],
      /* tuple */[
        "settings.profile.username.description",
        "Cannot be changed"
      ],
      /* tuple */[
        "settings.profile.email",
        "Email"
      ],
      /* tuple */[
        "settings.profile.name",
        "Name"
      ],
      /* tuple */[
        "settings.profile.submit",
        "Update"
      ],
      /* tuple */[
        "settings.profile.success.title",
        "Success!"
      ],
      /* tuple */[
        "settings.profile.success.message",
        "Updated successfully"
      ],
      /* tuple */[
        "settings.profile.errors.title",
        "There was a problem with your request"
      ],
      /* tuple */[
        "settings.profile.errors.unknown",
        "Something unexpected happened"
      ],
      /* tuple */[
        "settings.profile.changePassword.header",
        "Change password"
      ],
      /* tuple */[
        "settings.profile.changePassword.currentPassword",
        "Current password"
      ],
      /* tuple */[
        "settings.profile.changePassword.newPassword",
        "New password"
      ],
      /* tuple */[
        "settings.profile.changePassword.newPasswordConfirmation",
        "Confirm new password"
      ],
      /* tuple */[
        "settings.profile.changePassword.errors.newPasswordConfirmation.notMatching",
        "Passwords don\'t match"
      ],
      /* tuple */[
        "settings.profile.MFASettings.header",
        "Multi-factor authentication"
      ],
      /* tuple */[
        "settings.profile.MFASettings.enabled",
        "Enabled"
      ],
      /* tuple */[
        "settings.profile.MFASettings.phoneNumber",
        "Phone number"
      ],
      /* tuple */[
        "settings.profile.MFASettings.phoneNumber.info",
        "Must start with + country code, i.e.: +12223334444"
      ],
      /* tuple */[
        "search.pending",
        "Searching"
      ],
      /* tuple */[
        "search.results.dismiss",
        "Close"
      ],
      /* tuple */[
        "search.results.empty",
        "Nothing found"
      ],
      /* tuple */[
        "search.documentTitle",
        "Search"
      ],
      /* tuple */[
        "solvuuQuery.documentTitle",
        "Query"
      ],
      /* tuple */[
        "solvuuQuery.submit",
        "Solve"
      ],
      /* tuple */[
        "solvuuQuery.placeholder",
        "Enter solvuu language query, e.g. 2 + 3"
      ],
      /* tuple */[
        "list.empty",
        "Empty list"
      ],
      /* tuple */[
        "table.empty",
        "Empty table"
      ],
      /* tuple */[
        "table.cell.missing",
        "missing value"
      ],
      /* tuple */[
        "table.cell.cannotDisplay",
        "cannot display value"
      ],
      /* tuple */[
        "table.cell.errors.invalid",
        "Invalid value: {value}"
      ],
      /* tuple */[
        "table.cell.errors.invalidBlob",
        "Failed upload"
      ],
      /* tuple */[
        "table.cell.errors.invalidExecution",
        "Failed job"
      ],
      /* tuple */[
        "table.cell.errors.invalidType",
        "Invalid type: {value}"
      ],
      /* tuple */[
        "table.pager.summary",
        "Matched {count} out of {total} rows"
      ],
      /* tuple */[
        "table.pager.showMore",
        "{hidden} rows remaining - show more"
      ],
      /* tuple */[
        "table.filters.toggle.none",
        "Add filter"
      ],
      /* tuple */[
        "table.filters.toggle.one",
        "1 filter active"
      ],
      /* tuple */[
        "table.filters.toggle.many",
        "{count} filters active"
      ],
      /* tuple */[
        "table.filters.empty",
        "No filters"
      ],
      /* tuple */[
        "table.filters.actions.apply",
        "Apply filters"
      ],
      /* tuple */[
        "table.filters.actions.addNew",
        "Add new"
      ],
      /* tuple */[
        "table.filters.actions.reset",
        "Reset"
      ],
      /* tuple */[
        "table.filters.scopes.anyColumn",
        "Any column"
      ],
      /* tuple */[
        "table.filters.matchers.contain",
        "contains"
      ],
      /* tuple */[
        "table.filters.matchers.equal",
        "equal to"
      ],
      /* tuple */[
        "table.filters.matchers.notEqual",
        "not equal to"
      ],
      /* tuple */[
        "table.filters.matchers.equalAnyOf",
        "equal to any of"
      ],
      /* tuple */[
        "table.filters.matchers.greaterThan",
        "greater than"
      ],
      /* tuple */[
        "table.filters.matchers.lessThan",
        "less than"
      ],
      /* tuple */[
        "table.filters.matchers.greaterThanOrEqualTo",
        "greater than or equal to"
      ],
      /* tuple */[
        "table.filters.matchers.lessThanOrEqualTo",
        "less than or equal to"
      ],
      /* tuple */[
        "table.filters.matchers.true",
        "is true"
      ],
      /* tuple */[
        "table.filters.matchers.false",
        "is false"
      ],
      /* tuple */[
        "table.actions.visualize.graphical.toggle.none",
        "Add visualization"
      ],
      /* tuple */[
        "table.actions.visualize.graphical.toggle.one",
        "1 visualization active"
      ],
      /* tuple */[
        "table.actions.visualize.graphical.toggle.many",
        "{count} visualizations active"
      ],
      /* tuple */[
        "table.actions.visualize.statistical.toggle.none",
        "Add statistical test"
      ],
      /* tuple */[
        "table.actions.visualize.statistical.toggle.one",
        "1 statistical test active"
      ],
      /* tuple */[
        "table.actions.visualize.statistical.toggle.many",
        "{count} statistical tests active"
      ],
      /* tuple */[
        "table.actions.visualize.launcher.header",
        "Tools"
      ],
      /* tuple */[
        "table.actions.visualize.areaChart",
        "Area Chart"
      ],
      /* tuple */[
        "table.actions.visualize.areaChart.description",
        "Area charts are used to represent cumulated totals using numbers or percentages over time."
      ],
      /* tuple */[
        "table.actions.visualize.barChart",
        "Bar Chart"
      ],
      /* tuple */[
        "table.actions.visualize.barChart.description",
        "A chart that presents categorical data with rectangular bars with heights or lengths proportional to the values that they represent."
      ],
      /* tuple */[
        "table.actions.visualize.boxPlot",
        "Tukey Box Plot"
      ],
      /* tuple */[
        "table.actions.visualize.boxPlot.description",
        "Box-and-whisker plot to depict groups of numerical data through their quartiles."
      ],
      /* tuple */[
        "table.actions.visualize.csv",
        "CSV Parser"
      ],
      /* tuple */[
        "table.actions.visualize.csv.description",
        "Parse a csv file."
      ],
      /* tuple */[
        "table.actions.visualize.filter",
        "Filter"
      ],
      /* tuple */[
        "table.actions.visualize.filter.description",
        "Filter data."
      ],
      /* tuple */[
        "table.actions.visualize.bubbleChart",
        "Bubble Chart"
      ],
      /* tuple */[
        "table.actions.visualize.bubbleChart.description",
        "Bubble chart visualizes the data through bubbles which size correspond to the value."
      ],
      /* tuple */[
        "table.actions.visualize.donutChart",
        "Donut Chart"
      ],
      /* tuple */[
        "table.actions.visualize.donutChart.description",
        "Donut chart is an alternative for pie chart, very commonly used in biology to represent relative values (e.g. abundance measurements)."
      ],
      /* tuple */[
        "table.actions.visualize.dotPlot",
        "Dot Plot"
      ],
      /* tuple */[
        "table.actions.visualize.dotPlot.description",
        "Dot plots are ideal for displaying data distributions for small sample size."
      ],
      /* tuple */[
        "table.actions.visualize.heatmap",
        "Heatmap"
      ],
      /* tuple */[
        "table.actions.visualize.heatmap.description",
        "Visualize heatmap to reveal patterns in features and samples."
      ],
      /* tuple */[
        "table.actions.visualize.hierarchicalClustering",
        "Hierarchical Clustering"
      ],
      /* tuple */[
        "table.actions.visualize.hierarchicalClustering.description",
        "Unsupervised clustering of numerical data (expression, OTU table, etc) using similarity. Visualize clusters as dendrogram and heatmap to reveal patterns in features and samples."
      ],
      /* tuple */[
        "table.actions.visualize.kMeansClustering",
        "K-means Clustering"
      ],
      /* tuple */[
        "table.actions.visualize.kMeansClustering.description",
        "Unsupervised clustering of numerical data (expression, OTU table, etc) using similarity. Visualize pre-defined number of clusters as heatmap to reveal patterns in features and samples."
      ],
      /* tuple */[
        "table.actions.visualize.kronaChart",
        "Krona Chart"
      ],
      /* tuple */[
        "table.actions.visualize.kronaChart.description",
        "Krona allows hierarchical data to be explored with zooming, multi-layered pie charts."
      ],
      /* tuple */[
        "table.actions.visualize.lineChart",
        "Line Chart"
      ],
      /* tuple */[
        "table.actions.visualize.lineChart.description",
        "A line chart is a type of chart which displays information as a series of data points connected by straight line segments."
      ],
      /* tuple */[
        "table.actions.visualize.hexbinPlot",
        "Hexbin plot"
      ],
      /* tuple */[
        "table.actions.visualize.hexbinPlot.description",
        "In a hexbin plot, the data points are binned in 2D and the number of counts per bin is then color-coded. It is essentially like a 2D histogram."
      ],
      /* tuple */[
        "table.actions.visualize.histogram",
        "Histogram"
      ],
      /* tuple */[
        "table.actions.visualize.histogram.description",
        "Represent distribution of data through a frequency histogram."
      ],
      /* tuple */[
        "table.actions.visualize.kernelDensity",
        "Kernel Density Plot"
      ],
      /* tuple */[
        "table.actions.visualize.kernelDensity.description",
        "Visualize distribution of numerical data as a continuous replacement for the discrete histogram."
      ],
      /* tuple */[
        "table.actions.visualize.parallelCoordinates",
        "Parallel Coordinates"
      ],
      /* tuple */[
        "table.actions.visualize.parallelCoordinates.description",
        "Plot multivariate numerical data, compare many variables together and see the relationships between them (e.g., time series, longitudinal and tissue-specific expression data)."
      ],
      /* tuple */[
        "table.actions.visualize.principalCoordinates",
        "MDS Plot"
      ],
      /* tuple */[
        "table.actions.visualize.principalCoordinates.description",
        "Identify patterns in high dimensional data (e.g., expression, OTU table) by highlighting the variables with the greatest variance across the samples. Useful for feature selection, feature extraction, and outlier detection."
      ],
      /* tuple */[
        "table.actions.visualize.quantileQuantilePlot",
        "Quantile-Quantile Plot"
      ],
      /* tuple */[
        "table.actions.visualize.quantileQuantilePlot.description",
        "Q–Q plot is a probability plot for comparing two probability distributions by plotting their quantiles against each other."
      ],
      /* tuple */[
        "table.actions.visualize.scatterPlot",
        "Scatter Plot"
      ],
      /* tuple */[
        "table.actions.visualize.scatterPlot.description",
        "Detect if a relationship or correlation between the two variables exists by displaying a variable in each axis. R<sup>2</sup> values explain the percentage of variation between the two variables."
      ],
      /* tuple */[
        "table.actions.visualize.scatterMatrix",
        "Scatterplot Matrix"
      ],
      /* tuple */[
        "table.actions.visualize.scatterMatrix.description",
        "Draw all-vs-all table of scatter plots when there are more than two variables in the data. R<sup>2</sup> values explain the percentage of variation between the two variables."
      ],
      /* tuple */[
        "table.actions.visualize.summaryStatistics",
        "Summary Statistics"
      ],
      /* tuple */[
        "table.actions.visualize.summaryStatistics.description",
        "A collection of descriptive statistics on the data that includes mean, median, variance and others."
      ],
      /* tuple */[
        "table.actions.visualize.tTest",
        "T-test"
      ],
      /* tuple */[
        "table.actions.visualize.tTest.description",
        "A t-test lets you compare differences between two groups. There are three types of t-tests: paired, independent, and one sample."
      ],
      /* tuple */[
        "table.actions.visualize.vennDiagram",
        "Venn Diagram"
      ],
      /* tuple */[
        "table.actions.visualize.vennDiagram.description",
        "Compare gene lists or other features across tissue types or conditions."
      ],
      /* tuple */[
        "table.actions.visualize.violinPlot",
        "Violin Plot"
      ],
      /* tuple */[
        "table.actions.visualize.violinPlot.description",
        "Similar to box plot with a rotated kernel density plot on each side. Particularly useful when the data distribution is multimodal."
      ],
      /* tuple */[
        "table.actions.visualize.volcanoPlot",
        "Volcano Plot"
      ],
      /* tuple */[
        "table.actions.visualize.volcanoPlot.description",
        "Visualize differential gene expression (DGE) on a scatter plot of fold change vs its significance (negative log p-value)."
      ],
      /* tuple */[
        "table.actions.visualize.upcoming",
        "Coming soon..."
      ],
      /* tuple */[
        "table.actions.visualize.anova",
        "ANOVA Tests"
      ],
      /* tuple */[
        "table.actions.visualize.mannWhitneyUTest",
        "Mann-Whitney U-test"
      ],
      /* tuple */[
        "table.actions.visualize.kruskallWallisRankSumTest",
        "Kruskall-Wallis Rank Sum Test"
      ],
      /* tuple */[
        "table.actions.visualize.welchTTest",
        "Welch’s T-test"
      ],
      /* tuple */[
        "table.actions.visualize.wilcoxonSignedRankTest",
        "Wilcoxon Signed Rank Test"
      ],
      /* tuple */[
        "table.actions.visualize.kolmogorovSmirnovTest",
        "Kolmogorov-Smirnov Test"
      ],
      /* tuple */[
        "table.actions.visualize.fisherTest",
        "Fisher’s Test"
      ],
      /* tuple */[
        "table.visualization.actions.close",
        "Delete"
      ],
      /* tuple */[
        "table.visualization.actions.collapse",
        "Collapse"
      ],
      /* tuple */[
        "table.visualization.actions.expand",
        "Expand"
      ],
      /* tuple */[
        "table.visualization.actions.hideControls",
        "Hide settings"
      ],
      /* tuple */[
        "table.visualization.actions.showControls",
        "Show settings"
      ],
      /* tuple */[
        "table.visualization.controls.header",
        "Tool settings"
      ],
      /* tuple */[
        "table.histogram.header",
        "Histogram"
      ],
      /* tuple */[
        "table.histogram.columnSelect.label",
        "Column"
      ],
      /* tuple */[
        "table.histogram.columnsSelect.info",
        "Both numerical and categorical column types are allowed"
      ],
      /* tuple */[
        "table.histogram.noData",
        "Nothing to show"
      ],
      /* tuple */[
        "table.kernelDensity.header",
        "Kernel Density Plot"
      ],
      /* tuple */[
        "table.kernelDensity.columnSelect.label",
        "Column"
      ],
      /* tuple */[
        "table.kernelDensity.columnsSelect.info",
        "Only numerical column types are allowed"
      ],
      /* tuple */[
        "table.kernelDensity.noData",
        "Nothing to show"
      ],
      /* tuple */[
        "table.kernelDensity.chart.yAxis",
        "Density"
      ],
      /* tuple */[
        "table.kronaChart.header",
        "Krona Chart"
      ],
      /* tuple */[
        "table.kronaChart.noData",
        "Nothing to show"
      ],
      /* tuple */[
        "table.kronaChart.valueColumnSelect.label",
        "Counts column"
      ],
      /* tuple */[
        "table.kronaChart.valueColumnSelect.info",
        "Only numerical column types are allowed"
      ],
      /* tuple */[
        "table.kronaChart.levelColumnSelect.header",
        "Hierarchy levels"
      ],
      /* tuple */[
        "table.kronaChart.levelColumnSelect.new.label",
        "Add new level"
      ],
      /* tuple */[
        "table.kronaChart.levelColumnSelect.existing.label",
        "Level { levelIndex }"
      ],
      /* tuple */[
        "table.lineChart.header",
        "Line Chart"
      ],
      /* tuple */[
        "table.lineChart.noData",
        "Nothing to show"
      ],
      /* tuple */[
        "table.lineChart.columnsSelect.label",
        "Data series columns"
      ],
      /* tuple */[
        "table.lineChart.columnsSelect.info",
        "Only numerical column types are allowed"
      ],
      /* tuple */[
        "table.lineChart.labelColumnSelect.label",
        "Label column"
      ],
      /* tuple */[
        "table.heatmap.header",
        "Heatmap"
      ],
      /* tuple */[
        "table.heatmap.xAxisSelect.label",
        "Data columns"
      ],
      /* tuple */[
        "table.heatmap.xAxisSelect.info",
        "Data for heatmap\'s columns"
      ],
      /* tuple */[
        "table.heatmap.xAxisSelect.info2",
        "Only numerical column types are allowed"
      ],
      /* tuple */[
        "table.heatmap.yAxisSelect.label",
        "Labels column"
      ],
      /* tuple */[
        "table.heatmap.yAxisSelect.info",
        "Annotates the heatmap\'s rows"
      ],
      /* tuple */[
        "table.heatmap.yAxisClustering.enabled",
        "Cluster labels"
      ],
      /* tuple */[
        "table.heatmap.xAxisClustering.enabled",
        "Cluster data"
      ],
      /* tuple */[
        "table.heatmap.clustering.method.label",
        "Clustering method"
      ],
      /* tuple */[
        "table.heatmap.clustering.method.hierarchical",
        "Hierarchical Clustering"
      ],
      /* tuple */[
        "table.heatmap.clustering.method.kmeans",
        "K-Means Clustering"
      ],
      /* tuple */[
        "table.heatmap.clustering.distance.label",
        "Distance metric"
      ],
      /* tuple */[
        "table.heatmap.clustering.distance.euclidean",
        "Euclidean"
      ],
      /* tuple */[
        "table.heatmap.clustering.distance.manhattan",
        "Manhattan"
      ],
      /* tuple */[
        "table.heatmap.clustering.distance.maximum",
        "Maximum"
      ],
      /* tuple */[
        "table.heatmap.clustering.distance.pearson",
        "Pearson correlation"
      ],
      /* tuple */[
        "table.heatmap.clustering.distance.spearman",
        "Spearman correlation"
      ],
      /* tuple */[
        "table.heatmap.clustering.distance.kendall",
        "Kendall correlation"
      ],
      /* tuple */[
        "table.heatmap.clustering.linkage.label",
        "Cluster linkage"
      ],
      /* tuple */[
        "table.heatmap.clustering.linkage.mean",
        "Mean"
      ],
      /* tuple */[
        "table.heatmap.clustering.linkage.complete",
        "Complete"
      ],
      /* tuple */[
        "table.heatmap.clustering.linkage.single",
        "Single"
      ],
      /* tuple */[
        "table.heatmap.clustering.linkage.centroid",
        "Centroid"
      ],
      /* tuple */[
        "table.heatmap.clustering.linkage.ward",
        "Ward\'s method"
      ],
      /* tuple */[
        "table.heatmap.clustering.numClusters.label",
        "Number of clusters"
      ],
      /* tuple */[
        "table.heatmap.visual.showOriginalData.enabled",
        "Show original values"
      ],
      /* tuple */[
        "table.heatmap.visual.showOriginalData.info",
        "Shows unnormalized values inside heatmap cells"
      ],
      /* tuple */[
        "table.heatmap.noData",
        "Nothing to show"
      ],
      /* tuple */[
        "table.hexbinPlot.header",
        "Hexbin Plot"
      ],
      /* tuple */[
        "table.hexbinPlot.xAxisSelect.label",
        "X axis column"
      ],
      /* tuple */[
        "table.hexbinPlot.xAxisSelect.info",
        "Only numerical column types are allowed"
      ],
      /* tuple */[
        "table.hexbinPlot.yAxisSelect.label",
        "Y axis column"
      ],
      /* tuple */[
        "table.hexbinPlot.yAxisSelect.info",
        "Only numerical column types are allowed"
      ],
      /* tuple */[
        "table.hexbinPlot.labelSelect.label",
        "Label column"
      ],
      /* tuple */[
        "table.hexbinPlot.noData",
        "Nothing to show"
      ],
      /* tuple */[
        "table.areaChart.header",
        "Area Chart"
      ],
      /* tuple */[
        "table.areaChart.noData",
        "Nothing to show"
      ],
      /* tuple */[
        "table.areaChart.tooMuchData",
        "There\'s too much data to display. Try filtering to reduce the number of groups below {maxGroups}."
      ],
      /* tuple */[
        "table.areaChart.columnsSelect.label",
        "Data series columns"
      ],
      /* tuple */[
        "table.areaChart.columnsSelect.info",
        "Only numerical column types are allowed"
      ],
      /* tuple */[
        "table.areaChart.labelColumnSelect.label",
        "Group label column"
      ],
      /* tuple */[
        "table.areaChart.relativeMeasuresCheckbox.label",
        "Show relative measures"
      ],
      /* tuple */[
        "table.areaChart.relativeMeasuresCheckbox.info",
        "Renders percentage share on Y axis"
      ],
      /* tuple */[
        "table.barChart.header",
        "Bar Chart"
      ],
      /* tuple */[
        "table.barChart.noData",
        "Nothing to show"
      ],
      /* tuple */[
        "table.barChart.columnsSelect.label",
        "Data series columns"
      ],
      /* tuple */[
        "table.barChart.columnsSelect.info",
        "Only numerical column types are allowed"
      ],
      /* tuple */[
        "table.barChart.labelColumnSelect.label",
        "Group label column"
      ],
      /* tuple */[
        "table.barChart.barStackCheckbox.label",
        "Stack the bars within a group"
      ],
      /* tuple */[
        "table.barChart.layoutSelect.label",
        "Layout"
      ],
      /* tuple */[
        "table.barChart.layoutSelect.info.forced",
        "This layout is required due to a large number of groups."
      ],
      /* tuple */[
        "table.boxPlot.header",
        "Tukey Box Plot"
      ],
      /* tuple */[
        "table.boxPlot.columnsSelect.label",
        "Columns"
      ],
      /* tuple */[
        "table.boxPlot.columnsSelect.info",
        "Only numerical column types are allowed"
      ],
      /* tuple */[
        "table.boxPlot.showOutliers.label",
        "Show outliers"
      ],
      /* tuple */[
        "table.boxPlot.showOutliers.info",
        "Shows data points outside {range} range"
      ],
      /* tuple */[
        "table.boxPlot.tooltip.min",
        "Min: {value}"
      ],
      /* tuple */[
        "table.boxPlot.tooltip.max",
        "Max: {value}"
      ],
      /* tuple */[
        "table.boxPlot.tooltip.mean",
        "Mean: {value}"
      ],
      /* tuple */[
        "table.boxPlot.tooltip.median",
        "Median: {value}"
      ],
      /* tuple */[
        "table.boxPlot.tooltip.firstQuartile",
        "1st quartile: {value}"
      ],
      /* tuple */[
        "table.boxPlot.tooltip.thirdQuartile",
        "3rd quartile: {value}"
      ],
      /* tuple */[
        "table.boxPlot.noData",
        "Nothing to show"
      ],
      /* tuple */[
        "table.bubbleChart.header",
        "Bubble Chart"
      ],
      /* tuple */[
        "table.bubbleChart.noData",
        "Nothing to show"
      ],
      /* tuple */[
        "table.bubbleChart.tooMuchData",
        "There\'s too much data to display. Try filtering to reduce the number of bubbles below {maxBubbles}."
      ],
      /* tuple */[
        "table.bubbleChart.columnSelect.label",
        "Data series column"
      ],
      /* tuple */[
        "table.bubbleChart.columnSelect.info",
        "Only numerical column types are allowed"
      ],
      /* tuple */[
        "table.bubbleChart.labelColumnSelect.label",
        "Label column"
      ],
      /* tuple */[
        "table.bubbleChart.labelColumnSelect.info",
        "Adds labels to individual bubbles"
      ],
      /* tuple */[
        "table.donutChart.header",
        "Donut Chart"
      ],
      /* tuple */[
        "table.donutChart.noData",
        "Nothing to show"
      ],
      /* tuple */[
        "table.donutChart.tooMuchData",
        "There\'s too much data to display. Try filtering to reduce the number of slices to {maxSlices}."
      ],
      /* tuple */[
        "table.donutChart.columnsSelect.label",
        "Data series columns"
      ],
      /* tuple */[
        "table.donutChart.columnsSelect.info",
        "Only numerical column types are allowed"
      ],
      /* tuple */[
        "table.donutChart.labelColumnSelect.label",
        "Labels column"
      ],
      /* tuple */[
        "table.dotPlot.header",
        "Dot Plot"
      ],
      /* tuple */[
        "table.dotPlot.noData",
        "Nothing to show"
      ],
      /* tuple */[
        "table.dotPlot.tooMuchData",
        "There\'s too much data to display. Try filtering to reduce the number of points to {maxPoints}."
      ],
      /* tuple */[
        "table.dotPlot.columnsSelect.label",
        "Data columns"
      ],
      /* tuple */[
        "table.dotPlot.columnsSelect.info",
        "Only numerical column types are allowed"
      ],
      /* tuple */[
        "table.parallelCoordinates.header",
        "Parallel Coordinates"
      ],
      /* tuple */[
        "table.parallelCoordinates.columnsSelect.label",
        "Columns"
      ],
      /* tuple */[
        "table.parallelCoordinates.columnsSelect.info",
        "Only numerical column types are allowed"
      ],
      /* tuple */[
        "table.parallelCoordinates.columnsSelect.info2",
        "A minimum of 3 columns is required to draw the plot"
      ],
      /* tuple */[
        "table.parallelCoordinates.noData",
        "Nothing to show"
      ],
      /* tuple */[
        "table.principalCoordinates.header",
        "MDS Plot"
      ],
      /* tuple */[
        "table.principalCoordinates.columnsSelect.label",
        "Columns"
      ],
      /* tuple */[
        "table.principalCoordinates.columnsSelect.info",
        "Only numerical column types are allowed"
      ],
      /* tuple */[
        "table.principalCoordinates.columnsSelect.info2",
        "A minimum of 2 columns is required to draw the plot"
      ],
      /* tuple */[
        "table.principalCoordinates.noData",
        "Nothing to show"
      ],
      /* tuple */[
        "table.principalCoordinates.notEnoughData",
        "Not enough data to perform MDS"
      ],
      /* tuple */[
        "table.principalCoordinates.distance.label",
        "Distance metric"
      ],
      /* tuple */[
        "table.principalCoordinates.distance.info",
        "Used to calculate the dissimilarity matrix"
      ],
      /* tuple */[
        "table.principalCoordinates.distance.euclidean",
        "Euclidean"
      ],
      /* tuple */[
        "table.principalCoordinates.distance.manhattan",
        "Manhattan"
      ],
      /* tuple */[
        "table.principalCoordinates.distance.maximum",
        "Maximum"
      ],
      /* tuple */[
        "table.principalCoordinates.distance.pearson",
        "Pearson correlation"
      ],
      /* tuple */[
        "table.principalCoordinates.distance.spearman",
        "Spearman correlation"
      ],
      /* tuple */[
        "table.principalCoordinates.distance.kendall",
        "Kendall correlation"
      ],
      /* tuple */[
        "table.quantileQuantilePlot.header",
        "Quantile-Quantile Plot"
      ],
      /* tuple */[
        "table.quantileQuantilePlot.columnSelect.label",
        "Data columns"
      ],
      /* tuple */[
        "table.quantileQuantilePlot.columnSelect.info",
        "Only numerical column types are allowed"
      ],
      /* tuple */[
        "table.quantileQuantilePlot.transformSelect.label",
        "Data transformation"
      ],
      /* tuple */[
        "table.quantileQuantilePlot.transformSelect.noTransform",
        "None"
      ],
      /* tuple */[
        "table.quantileQuantilePlot.transformSelect.minusLog10Transform",
        "-log10(x)"
      ],
      /* tuple */[
        "table.quantileQuantilePlot.transformSelect.log2Transform",
        "log2(x)"
      ],
      /* tuple */[
        "table.quantileQuantilePlot.transformSelect.logInfo",
        "Zero and negative values are removed in this transformation"
      ],
      /* tuple */[
        "table.quantileQuantilePlot.noData",
        "Nothing to show"
      ],
      /* tuple */[
        "table.scatterPlot.header",
        "Scatter Plot"
      ],
      /* tuple */[
        "table.scatterPlot.xAxisSelect.label",
        "X axis column"
      ],
      /* tuple */[
        "table.scatterPlot.xAxisSelect.info",
        "Only numerical column types are allowed"
      ],
      /* tuple */[
        "table.scatterPlot.yAxisSelect.label",
        "Y axis column"
      ],
      /* tuple */[
        "table.scatterPlot.yAxisSelect.info",
        "Only numerical column types are allowed"
      ],
      /* tuple */[
        "table.scatterPlot.labelSelect.label",
        "Annotations column"
      ],
      /* tuple */[
        "table.scatterPlot.labelSelect.info",
        "Optional. Adds metadata annotation to data points"
      ],
      /* tuple */[
        "table.scatterPlot.noData",
        "Nothing to show"
      ],
      /* tuple */[
        "table.scatterMatrix.header",
        "Scatterplot Matrix"
      ],
      /* tuple */[
        "table.scatterMatrix.columnsSelect.label",
        "Data columns"
      ],
      /* tuple */[
        "table.scatterMatrix.columnsSelect.info",
        "Only numerical column types are allowed"
      ],
      /* tuple */[
        "table.scatterMatrix.columnsSelect.info2",
        "A minimum of 2 columns is required to draw the plot"
      ],
      /* tuple */[
        "table.scatterMatrix.labelSelect.label",
        "Annotations column"
      ],
      /* tuple */[
        "table.scatterMatrix.labelSelect.info",
        "Optional. Adds metadata annotation to data points"
      ],
      /* tuple */[
        "table.scatterMatrix.noData",
        "Nothing to show"
      ],
      /* tuple */[
        "table.summaryStatistics.header",
        "Summary Statistics"
      ],
      /* tuple */[
        "table.summaryStatistics.columnsSelect.label",
        "Data columns"
      ],
      /* tuple */[
        "table.summaryStatistics.columnsSelect.info",
        "Only numerical column types are allowed"
      ],
      /* tuple */[
        "table.summaryStatistics.noData",
        "Nothing to show"
      ],
      /* tuple */[
        "table.summaryStatistics.statistic.size",
        "Sample size"
      ],
      /* tuple */[
        "table.summaryStatistics.statistic.min",
        "Minimum"
      ],
      /* tuple */[
        "table.summaryStatistics.statistic.max",
        "Maximum"
      ],
      /* tuple */[
        "table.summaryStatistics.statistic.range",
        "Range"
      ],
      /* tuple */[
        "table.summaryStatistics.statistic.sum",
        "Sum"
      ],
      /* tuple */[
        "table.summaryStatistics.statistic.mean",
        "Mean"
      ],
      /* tuple */[
        "table.summaryStatistics.statistic.median",
        "Median"
      ],
      /* tuple */[
        "table.summaryStatistics.statistic.firstQuartile",
        "First quartile"
      ],
      /* tuple */[
        "table.summaryStatistics.statistic.thirdQuartile",
        "Third quartile"
      ],
      /* tuple */[
        "table.summaryStatistics.statistic.variance",
        "Variance"
      ],
      /* tuple */[
        "table.summaryStatistics.statistic.standardDeviation",
        "Standard deviation"
      ],
      /* tuple */[
        "table.tTest.header",
        "T-test"
      ],
      /* tuple */[
        "table.tTest.typeSelect.label",
        "Type"
      ],
      /* tuple */[
        "table.tTest.typeSelect.info",
        "Type of t-test"
      ],
      /* tuple */[
        "table.tTest.columnSelect.label",
        "Data column"
      ],
      /* tuple */[
        "table.tTest.columnSelect.info",
        "Only numerical column types are allowed"
      ],
      /* tuple */[
        "table.tTest.column2Select.label",
        "Data column"
      ],
      /* tuple */[
        "table.tTest.column2Select.info",
        "Only numerical column types are allowed"
      ],
      /* tuple */[
        "table.vennDiagram.header",
        "Venn Diagram"
      ],
      /* tuple */[
        "table.vennDiagram.columnsSelect.label",
        "Columns"
      ],
      /* tuple */[
        "table.vennDiagram.columnsSelect.info",
        "Only numerical column types are allowed"
      ],
      /* tuple */[
        "table.vennDiagram.columnsSelect.info2",
        "Missing values are ignored"
      ],
      /* tuple */[
        "table.vennDiagram.noData",
        "Nothing to show"
      ],
      /* tuple */[
        "table.vennDiagram.summaryItems.headers.set",
        "Set"
      ],
      /* tuple */[
        "table.vennDiagram.summaryItems.headers.size",
        "Size"
      ],
      /* tuple */[
        "table.vennDiagram.summaryItems.headers.items",
        "Items"
      ],
      /* tuple */[
        "table.vennDiagram.summaryItems.showAllItems",
        "show all"
      ],
      /* tuple */[
        "table.vennDiagram.disjointSetsWarning",
        "Warning: following sets are not represented on the screen."
      ],
      /* tuple */[
        "table.violinPlot.header",
        "Violin Plot"
      ],
      /* tuple */[
        "table.violinPlot.columnsSelect.label",
        "Columns"
      ],
      /* tuple */[
        "table.violinPlot.columnsSelect.info",
        "Only numerical column types are allowed"
      ],
      /* tuple */[
        "table.violinPlot.noData",
        "Nothing to show"
      ],
      /* tuple */[
        "table.volcanoPlot.header",
        "Volcano Plot"
      ],
      /* tuple */[
        "table.volcanoPlot.xAxisSelect.label",
        "Fold Change column"
      ],
      /* tuple */[
        "table.volcanoPlot.xAxisSelect.info",
        "Column containing calculated FC or log(FC) values"
      ],
      /* tuple */[
        "table.volcanoPlot.yAxisSelect.label",
        "p-value column"
      ],
      /* tuple */[
        "table.volcanoPlot.yAxisSelect.info",
        "Column containing calculated p-values"
      ],
      /* tuple */[
        "table.volcanoPlot.labelSelect.label",
        "Label column"
      ],
      /* tuple */[
        "table.volcanoPlot.labelSelect.info",
        "Optional. Adds metadata to data points"
      ],
      /* tuple */[
        "table.volcanoPlot.logTransformXAxis.label",
        "Take log of Fold Change values"
      ],
      /* tuple */[
        "table.volcanoPlot.pValueThresholdSlider.label",
        "p-value threshold"
      ],
      /* tuple */[
        "table.volcanoPlot.logFCThresholdSlider.label",
        "log FC threshold"
      ],
      /* tuple */[
        "table.volcanoPlot.noData",
        "Nothing to show"
      ],
      /* tuple */[
        "upload.documentTitle",
        "Drive"
      ],
      /* tuple */[
        "upload.header",
        "Uploads"
      ],
      /* tuple */[
        "upload.actions.newFiles",
        "Upload files"
      ],
      /* tuple */[
        "upload.actions.newURI",
        "Add URL"
      ],
      /* tuple */[
        "upload.uploads.empty",
        "There\'s nothing here yet"
      ],
      /* tuple */[
        "upload.uploads.preparing",
        "preparing upload..."
      ],
      /* tuple */[
        "upload.uploads.details.checksum",
        "Checksum (MD5): {checksum}"
      ],
      /* tuple */[
        "upload.uploads.details.totalBytes",
        "Size: {bytes}"
      ],
      /* tuple */[
        "upload.uploads.details.uploadedBytes",
        "Uploaded: {bytes}"
      ],
      /* tuple */[
        "upload.uploads.details.events",
        "Events"
      ],
      /* tuple */[
        "upload.uploads.success",
        "uploaded"
      ],
      /* tuple */[
        "upload.uploads.error",
        "upload failed"
      ],
      /* tuple */[
        "upload.uploads.actions.showDetails",
        "Details"
      ],
      /* tuple */[
        "upload.uploads.actions.hideDetails",
        "Hide details"
      ],
      /* tuple */[
        "upload.uploads.actions.show",
        "Visualize"
      ],
      /* tuple */[
        "upload.uploads.actions.fetchEvents",
        "Refresh"
      ],
      /* tuple */[
        "charts.documentTitle",
        "Charts"
      ],
      /* tuple */[
        "charts.fileSelection.message",
        "Drag and drop a file from your computer to visualize."
      ],
      /* tuple */[
        "charts.fileSelection.formatsMessage",
        "Supported files: .csv, .tsv, .biom, .nwk"
      ],
      /* tuple */[
        "charts.fileSelection.reselect.message",
        "We\'re missing {fileName}. Drag and drop it from your computer here."
      ],
      /* tuple */[
        "charts.fileSelection.format.header",
        "Parsing options"
      ],
      /* tuple */[
        "charts.fileSelection.format.notCustomizable",
        "Parsing of this file cannot be customized."
      ],
      /* tuple */[
        "charts.fileSelection.actions.select",
        "Choose file"
      ],
      /* tuple */[
        "charts.fileSelection.actions.selectExample",
        "Or, browse one of our examples:"
      ],
      /* tuple */[
        "charts.fileSelection.actions.reselect",
        "Select {fileName} again"
      ],
      /* tuple */[
        "charts.fileSelection.actions.clear",
        "× clear file"
      ],
      /* tuple */[
        "charts.fileSelection.actions.changeFormat",
        "Save"
      ],
      /* tuple */[
        "charts.fileSelection.actions.cancelFormat",
        "Cancel"
      ],
      /* tuple */[
        "charts.fileSelection.fileSelecting.message",
        "Reading {fileName}"
      ],
      /* tuple */[
        "charts.fileSelection.fileSelected.message",
        "Selected {fileName}"
      ],
      /* tuple */[
        "marketing.product.documentTitle",
        "Product"
      ],
      /* tuple */[
        "marketing.company.documentTitle",
        "Company"
      ],
      /* tuple */[
        "marketing.science.documentTitle",
        "Science"
      ],
      /* tuple */[
        "marketing.science.item.documentTitle",
        "Science: {item}"
      ],
      /* tuple */[
        "marketing.blog.documentTitle",
        "Solvuu Blog"
      ],
      /* tuple */[
        "marketing.blog.post.documentTitle",
        "{title}"
      ],
      /* tuple */[
        "marketing.blog.category.documentTitle",
        "Solvuu Blog: {category}"
      ],
      /* tuple */[
        "privacyPolicy.documentTitle",
        "Privacy Policy"
      ],
      /* tuple */[
        "termsOfUse.documentTitle",
        "Terms of Use"
      ]
    ]);

export {
  en ,
  
}
/* en Not a pure module */
