

import * as React from "react";
import * as ReactBeforeunload from "react-beforeunload";

function onBeforeunload(when_, $$event) {
  var shouldPromptOnUnload = typeof when_ === "number" ? when_ === 518738846 : when_[0] === -76840209;
  if (shouldPromptOnUnload) {
    $$event.preventDefault();
  }
  if (typeof when_ === "number") {
    return ;
  } else {
    return when_[1].message;
  }
}

function ExitPrompt(Props) {
  var when_ = Props.when_;
  return React.createElement(ReactBeforeunload.default, {
              onBeforeunload: (function (param) {
                  return onBeforeunload(when_, param);
                })
            });
}

var make = ExitPrompt;

export {
  onBeforeunload ,
  make ,
  
}
/* react Not a pure module */
