

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as StyleCss from "./style.css";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Time$SolvuuClient from "solvuu-client/./time.bs.js";

var styles = StyleCss.default;

function useForceUpdate(param) {
  var match = React.useReducer((function (state, _action) {
          return state + 1 | 0;
        }), 0);
  var dispatch = match[1];
  return React.useCallback((function (param) {
                return Curry._1(dispatch, /* () */0);
              }), ([]));
}

function JobDuration(Props) {
  var created = Props.created;
  var completed = Props.completed;
  var forceUpdate = useForceUpdate(/* () */0);
  React.useEffect((function () {
          if (completed !== undefined) {
            return ;
          } else {
            var timerId = setInterval((function (param) {
                    return Curry._1(forceUpdate, /* () */0);
                  }), 1000);
            return (function (param) {
                      clearInterval(timerId);
                      return /* () */0;
                    });
          }
        }), /* tuple */[
        created,
        completed
      ]);
  var title = Belt_Option.map(completed, (function (x) {
          return "Completed at " + Time$SolvuuClient.toUTCString(x);
        }));
  var latest = Belt_Option.getWithDefault(completed, Time$SolvuuClient.now(/* () */0));
  var tmp = {
    className: styles.abbr
  };
  if (title !== undefined) {
    tmp.title = Caml_option.valFromOption(title);
  }
  return React.createElement("abbr", tmp, Curry._1(Time$SolvuuClient.Span.to_string_HHMMSS, Time$SolvuuClient.diff(latest, created)));
}

var make = JobDuration;

export {
  styles ,
  useForceUpdate ,
  make ,
  
}
/* styles Not a pure module */
