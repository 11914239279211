import React, { Component } from "react";
import PropTypes from "prop-types";
import { Alert } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

import * as Button from "components/Button.bs";
import * as Loading from "components/Loading.bs";
import * as PublicActionCard from "components/PublicActionCard/PublicActionCard.bs";
import styles from "./style.css";

class RegistrationConfirmation extends Component {
  static propTypes = {
    confirmRegistration: PropTypes.func.isRequired,
    resendConfirmation: PropTypes.func.isRequired,
    meta: PropTypes.shape({
      confirm: PropTypes.shape({
        pending: PropTypes.bool.isRequired,
        failure: PropTypes.bool.isRequired,
        errors: PropTypes.array
      }).isRequired,
      resendConfirmation: PropTypes.shape({
        success: PropTypes.bool.isRequired,
        pending: PropTypes.bool.isRequired,
        failure: PropTypes.bool.isRequired,
        errors: PropTypes.array
      }).isRequired
    }).isRequired
  };

  componentDidMount() {
    this.props.confirmRegistration();
  }

  onResendConfirmation = () => {
    this.props.resendConfirmation();
  };

  renderContent() {
    const {
      meta: { confirm, resendConfirmation }
    } = this.props;

    if (confirm.pending || resendConfirmation.pending) return <Loading.make />;

    if (resendConfirmation.success) {
      return (
        <Alert bsStyle="success" bsClass={styles.alert}>
          <FormattedMessage
            id="registration.confirmation.resend.success.title"
            tagName="h4"
          />
          <FormattedMessage id="registration.confirmation.resend.success.message" />
        </Alert>
      );
    }

    if (confirm.failure || resendConfirmation.failure) {
      const errors = resendConfirmation.failure
        ? resendConfirmation.errors
        : confirm.errors;
      return (
        <div>
          <Alert bsStyle="danger">
            {errors.map((error, idx) =>
              error.message ? (
                <p key={idx}>{error.message}</p>
              ) : (
                <FormattedMessage
                  key={idx}
                  id={error.id}
                  tagName="p"
                  values={error.values}
                />
              )
            )}
          </Alert>
          <Button.make onClick={this.onResendConfirmation}>
            <FormattedMessage id="registration.confirmation.resend" />
          </Button.make>
        </div>
      );
    }

    return null;
  }

  render() {
    return (
      <PublicActionCard.make>{this.renderContent()}</PublicActionCard.make>
    );
  }
}

export default RegistrationConfirmation;
