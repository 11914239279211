import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { FormattedMessage, FormattedNumber } from "react-intl";

import iconBubbleChart from "../icon-bubble-chart.svg";
import iconBubbleList from "../icon-bubble-list.svg";

import TaxonomyLevelSelect from "../TaxonomyLevelSelect";
import OptimizedSelect from "../OptimizedSelect";
import BubbleChartArea, { LAYOUT_PACK, LAYOUT_LIST } from "./BubbleChartArea";

import styles from "./style.css";

class BubbleChart extends Component {
  static propTypes = {
    getDataForTaxonomyLevel: PropTypes.func.isRequired,
    chartControls: PropTypes.shape({
      taxonomyLevel: PropTypes.number.isRequired
    }).isRequired,
    setChartControls: PropTypes.func.isRequired
  };

  state = {
    selectedTaxon: null
  };

  handleTaxonomyLevelChange = taxonomyLevel => {
    this.setState({ selectedTaxon: null });
    this.props.setChartControls({ taxonomyLevel });
  };

  handleLayoutChange = bubbleLayout => event => {
    this.props.setChartControls({ bubbleLayout });
  };

  handleSelectedTaxonChange = option => {
    const selectedTaxon = option ? option.value : null;
    this.setState({ selectedTaxon });
  };

  handleSampleClick = sampleId => {
    this.props.navigateToSample(sampleId);
  };

  render() {
    const { getDataForTaxonomyLevel, chartControls } = this.props;
    const { taxonomyLevel, bubbleLayout = LAYOUT_PACK } = chartControls;
    const { selectedTaxon } = this.state;

    const data = getDataForTaxonomyLevel(taxonomyLevel);
    const { taxonomies, observationIds, countsTotal } = data;

    const formattedCounts = (
      <strong>
        <FormattedNumber value={countsTotal} />
      </strong>
    );
    const formattedTaxonomies = (
      <strong>
        <FormattedNumber value={taxonomies.length} />
      </strong>
    );
    const formattedObservations = (
      <strong>
        <FormattedNumber value={observationIds.length} />
      </strong>
    );

    return (
      <div className={styles.root}>
        <div className={styles.controls}>
          <TaxonomyLevelSelect
            value={taxonomyLevel}
            onChange={this.handleTaxonomyLevelChange}
          />
          <div className={styles.layoutControls}>
            <button
              type="button"
              className={cx(styles.layoutButton, {
                [styles.layoutButtonActive]: bubbleLayout === LAYOUT_PACK
              })}
              onClick={this.handleLayoutChange(LAYOUT_PACK)}
            >
              <img src={iconBubbleChart} alt="" />
              <FormattedMessage id="biom.taxonomicAnalysis.bubbleChart.layouts.pack" />
            </button>
            <button
              type="button"
              className={cx(styles.layoutButton, {
                [styles.layoutButtonActive]: bubbleLayout === LAYOUT_LIST
              })}
              onClick={this.handleLayoutChange(LAYOUT_LIST)}
            >
              <img src={iconBubbleList} alt="" />
              <FormattedMessage id="biom.taxonomicAnalysis.bubbleChart.layouts.list" />
            </button>
          </div>
        </div>
        <div className={styles.controls}>
          <p className={styles.summary}>
            <FormattedMessage
              id="biom.taxonomicAnalysis.bubbleChart.summary"
              values={{
                counts: formattedCounts,
                taxonomies: formattedTaxonomies,
                observations: formattedObservations
              }}
            />
          </p>
          <OptimizedSelect
            value={selectedTaxon}
            onChange={this.handleSelectedTaxonChange}
            values={taxonomies}
            clearable
            placeholder={
              <FormattedMessage id="biom.taxonomicAnalysis.bubbleChart.search.placeholder" />
            }
          />
        </div>
        <BubbleChartArea
          data={data}
          layout={bubbleLayout}
          onSelect={this.handleSelectedTaxonChange}
          onSampleClick={this.handleSampleClick}
          selectedTaxon={selectedTaxon}
        />
      </div>
    );
  }
}

export default BubbleChart;
