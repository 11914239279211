import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Sankey } from "recharts";

import ResponsiveContainer from "components/ResponsiveContainer";
import { sequential as COLORS } from "lib/colors";
import styles from "./style.css";

export default class SankeyDiagramArea extends PureComponent {
  static propTypes = {
    data: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          id: PropTypes.number.isRequired
        })
      ).isRequired,
      links: PropTypes.arrayOf(
        PropTypes.shape({
          source: PropTypes.number.isRequired,
          target: PropTypes.number.isRequired,
          value: PropTypes.number.isRequired
        })
      ).isRequired
    }),
    onClickNode: PropTypes.func.isRequired
  };

  renderNodeText = props => {
    const {
      payload: { label, sourceLinks, targetLinks }
    } = props;
    const isSourceNode = sourceLinks.length === 0 && targetLinks.length > 0;

    const marginHorizontal = isSourceNode ? props.width + 6 : -6;
    const fontSize = 10;
    const x = props.x + marginHorizontal;
    const y = props.y + props.height / 2 + fontSize / 2 - 2; // Center the text vertically relative to the node
    const fill = "#333";
    const fillOpacity = props.height > 1 ? 1.0 : 0.7;
    const textAnchor = isSourceNode ? "start" : "end";

    return (
      <text
        x={x}
        y={y}
        fontSize={10}
        textAnchor={textAnchor}
        fill={fill}
        fillOpacity={fillOpacity}
        onClick={props.onClick}
        cursor={props.cursor}
        data-testid={`sankey-node[${label}]`}
      >
        {label}
      </text>
    );
  };

  renderNode = props => {
    const height = Math.max(props.height, 1);
    const onClick = () => {
      this.props.onClickNode(props.payload.id);
    };
    const fill = COLORS[props.payload.id];
    const rect = (
      <rect
        x={props.x}
        y={props.y}
        width={props.width}
        className="recharts-sankey-node"
        height={height}
        fill={fill}
        fillOpacity={0.8}
        onClick={onClick}
        cursor="pointer"
        stroke={fill}
        strokeWidth={0}
      />
    );

    const text = this.renderNodeText({ ...props, onClick, cursor: "pointer" });
    const title = (
      <title>
        {props.payload.label}: {props.payload.value}
      </title>
    );

    return (
      <g className={styles.node}>
        {title}
        {rect}
        {text}
      </g>
    );
  };

  renderLink = props => {
    const {
      sourceX,
      sourceY,
      sourceControlX,
      targetControlX,
      targetX,
      targetY
    } = props;

    const linkWidth = Math.max(props.linkWidth, 1);
    const halfHeight = linkWidth / 2;
    const sourceBottomY = sourceY + halfHeight;
    const sourceTopY = sourceY - halfHeight;
    const targetBottomY = targetY + halfHeight;
    const targetTopY = targetY - halfHeight;
    const fill = COLORS[props.payload.target.id];

    return (
      <path
        d={`
          M${sourceX},${sourceBottomY}
          C${sourceControlX},${sourceBottomY} ${targetControlX},${targetBottomY} ${targetX},${targetBottomY}
          L${targetX},${targetTopY} C${targetControlX},${targetTopY} ${sourceControlX},${sourceTopY} ${sourceX},${sourceTopY}
        `}
        stroke="none"
        fill={fill}
        fillOpacity={0.2}
        className="recharts-sankey-link"
      />
    );
  };

  render() {
    const { data } = this.props;
    const minHeight = 500;
    const height = Math.max(data.nodes.length * 8, minHeight);
    const width = "100%";

    return (
      <ResponsiveContainer width={width} height={height}>
        <Sankey
          data={data}
          nameKey="label"
          nodePadding={8}
          node={this.renderNode}
          link={this.renderLink}
          iterations={64}
        />
      </ResponsiveContainer>
    );
  }
}
