

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Hooks from "../../../../Hooks.bs.js";
import * as React from "react";
import * as Js_dict from "bs-platform/lib/es6/js_dict.js";
import * as Loading from "../../../../components/Loading.bs.js";
import * as Classnames from "classnames";
import * as ReactIntl from "react-intl";
import * as StyleCss from "./style.css";
import * as Blob$SolvuuApi from "solvuu-api/./blob.bs.js";
import * as S3 from "aws-sdk/clients/s3";
import * as Caml_builtin_exceptions from "bs-platform/lib/es6/caml_builtin_exceptions.js";

var styles = StyleCss.default;

function useBlob(blobId, apiClient) {
  var $$let = Blob$SolvuuApi.Get_by_id.$$Request;
  var $$let$1 = Blob$SolvuuApi.Get_by_id.$$Response;
  var match = Hooks.useApi(/* POST */891112544, {
        entity: Blob$SolvuuApi.Get_by_id.entity,
        service: Blob$SolvuuApi.Get_by_id.service,
        $$Request: {
          path: $$let.path,
          body: $$let.body,
          info: $$let.info
        },
        $$Response: {
          ok_of_json: $$let$1.ok_of_json,
          json_of_ok: $$let$1.json_of_ok,
          bad_request_of_json: $$let$1.bad_request_of_json,
          json_of_bad_request: $$let$1.json_of_bad_request,
          not_found_of_json: $$let$1.not_found_of_json,
          json_of_not_found: $$let$1.json_of_not_found
        }
      }, {
        id: blobId,
        instructions: /* Download */-344447064
      }, apiClient);
  var run = match[1];
  var detailsForDownload = match[0];
  React.useEffect((function () {
          Curry._1(run, /* () */0);
          return ;
        }), /* array */[]);
  if (typeof detailsForDownload === "number") {
    if (detailsForDownload !== 324098644) {
      return detailsForDownload;
    } else {
      return /* `Error */[
              106380200,
              "Unauthorized"
            ];
    }
  } else {
    var info = detailsForDownload[1].info;
    var variant = info[0];
    if (variant !== -99004555) {
      if (variant >= 17692) {
        return /* `Success */[
                94326179,
                info[1]
              ];
      } else {
        return /* `Error */[
                106380200,
                "Not Found"
              ];
      }
    } else {
      return /* `Error */[
              106380200,
              "Bad Request"
            ];
    }
  }
}

function useDownloadURL(blobId, apiClient) {
  var blob = useBlob(blobId, apiClient);
  if (typeof blob === "number" || blob[0] >= 106380200) {
    return blob;
  } else {
    var match = blob[1].instructions;
    if (match !== undefined) {
      var instructions = match;
      if (instructions[0] >= 549625211) {
        return /* `Error */[
                106380200,
                "Unable to download blob"
              ];
      } else {
        var instructions$1 = instructions[1][1];
        if (instructions$1[0] >= -344447064) {
          var instructions$2 = instructions$1[1];
          var match$1 = instructions$2.credentials;
          var credentials = match$1[1];
          var bucket = instructions$2.location.bucket;
          var key = instructions$2.location.key;
          var s3 = new S3.default({
                accessKeyId: credentials.access_key_id,
                secretAccessKey: credentials.secret_access_key,
                sessionToken: credentials.session_token,
                region: credentials.region,
                maxRetries: undefined,
                s3DisableBodySigning: undefined,
                params: undefined
              });
          var url = s3.getSignedUrl("getObject", Js_dict.fromArray(/* array */[
                    /* tuple */[
                      "Key",
                      key
                    ],
                    /* tuple */[
                      "Bucket",
                      bucket
                    ],
                    /* tuple */[
                      "ResponseContentType",
                      "text/html"
                    ],
                    /* tuple */[
                      "ResponseContentDisposition",
                      "inline"
                    ]
                  ]));
          return /* `Success */[
                  94326179,
                  url
                ];
        } else {
          throw [
                Caml_builtin_exceptions.assert_failure,
                /* tuple */[
                  "HTML.re",
                  54,
                  26
                ]
              ];
        }
      }
    } else {
      return /* `Error */[
              106380200,
              "No download instructions received"
            ];
    }
  }
}

function HTML(Props) {
  var name = Props.name;
  var blobId = Props.blobId;
  var apiClient = Props.apiClient;
  var match = React.useState((function () {
          return false;
        }));
  var setLoaded = match[1];
  var downloadUrl = useDownloadURL(blobId, apiClient);
  var showLoading = downloadUrl === -700189092 || !match[0];
  var contentClassName = Classnames.default(Js_dict.fromArray(/* array */[
            /* tuple */[
              styles.content,
              true
            ],
            /* tuple */[
              styles.contentPadded,
              "krona".includes(name) || "metaphlan".includes(name)
            ]
          ]));
  var tmp;
  if (typeof downloadUrl === "number" || downloadUrl[0] !== 94326179) {
    tmp = null;
  } else {
    var src = downloadUrl[1];
    tmp = React.createElement(ReactIntl.FormattedMessage, {
          id: "entities.HTML.title",
          children: (function (title) {
              return React.createElement("iframe", {
                          className: styles.iframe,
                          title: title,
                          sandbox: "allow-scripts",
                          src: src,
                          onLoad: (function (param) {
                              return Curry._1(setLoaded, (function (param) {
                                            return true;
                                          }));
                            })
                        });
            })
        });
  }
  return React.createElement("div", {
              className: styles.root
            }, React.createElement("section", {
                  className: contentClassName
                }, showLoading ? React.createElement("div", {
                        className: styles.loading
                      }, React.createElement(Loading.make, { })) : null, tmp));
}

var make = HTML;

export {
  styles ,
  useBlob ,
  useDownloadURL ,
  make ,
  
}
/* styles Not a pure module */
