

import * as ConfirmationReduxJs from "./ConfirmationRedux.js";

var make = ConfirmationReduxJs.default;

export {
  make ,
  
}
/* make Not a pure module */
