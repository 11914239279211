

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Import from "../features/import.bs.js";
import * as Std$Bolt from "bolt/./std.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Lang$SolvuuApi from "solvuu-api/./lang.bs.js";
import * as Root$SolvuuApi from "solvuu-api/./root.bs.js";
import * as Science$SolvuuApi from "solvuu-api/./science.bs.js";
import * as Caml_builtin_exceptions from "bs-platform/lib/es6/caml_builtin_exceptions.js";

function $$eval(f, t) {
  if (typeof t === "number" || t[0] !== 847309489) {
    return Curry._2(Lang$SolvuuApi.Term.eval_error, "typing error", t);
  } else {
    var result = Curry._1(Std$Bolt.Res.join, Curry._2(Root$SolvuuApi.Heatmap.Arg.of_record, t[1], (function (yAxisColumnName, xAxisColumnNames, yAxisClustering, xAxisClustering, showOriginalData, data) {
                if (typeof data === "number" || data[0] !== 848054398) {
                  return /* `Ok */[
                          17724,
                          Curry._2(Lang$SolvuuApi.Term.eval_error, "Expected table", data)
                        ];
                } else if (yAxisColumnName !== undefined) {
                  var yAxisColumnName$1 = yAxisColumnName;
                  var rows = data[1];
                  var termToString = function (term) {
                    if (typeof term === "number") {
                      if (term !== 870828711) {
                        return ;
                      } else {
                        return "";
                      }
                    } else {
                      var variant = term[0];
                      if (variant !== -976970511) {
                        if (variant !== 925978388) {
                          return ;
                        } else {
                          var match = term[1];
                          if (typeof match === "number" || match[0] !== -976970511) {
                            return ;
                          } else {
                            return match[1];
                          }
                        }
                      } else {
                        return term[1];
                      }
                    }
                  };
                  var termToNumeric = function (_term) {
                    while(true) {
                      var term = _term;
                      if (typeof term === "number") {
                        return ;
                      } else {
                        var variant = term[0];
                        if (variant !== 3654863 && variant !== 365180284) {
                          if (variant !== 925978388) {
                            return ;
                          } else {
                            _term = term[1];
                            continue ;
                          }
                        } else {
                          return term[1];
                        }
                      }
                    };
                  };
                  var x = Curry._1(Import.List.toArray, xAxisColumnNames);
                  var y = Curry._1(Std$Bolt.Res.all, Curry._2(Import.List.keepMap, rows, (function (row) {
                              if (typeof row === "number" || row[0] !== 847309489) {
                                return /* `Error */[
                                        106380200,
                                        /* ExpectedRecord */980211529
                                      ];
                              } else {
                                var match = Curry._2(Lang$SolvuuApi.Record.find, row[1], /* `Named */[
                                      699531161,
                                      yAxisColumnName$1
                                    ]);
                                if (match !== undefined) {
                                  var match$1 = termToString(match);
                                  if (match$1 !== undefined) {
                                    return /* `Ok */[
                                            17724,
                                            match$1
                                          ];
                                  } else {
                                    return ;
                                  }
                                } else {
                                  return /* `Error */[
                                          106380200,
                                          /* LabelNotFound */1029964323
                                        ];
                                }
                              }
                            })));
                  if (y[0] >= 106380200) {
                    var e = y[1];
                    var message = e !== 980211529 ? (
                        e >= 1029964323 ? "Invalid column with name: " + yAxisColumnName$1 : "Expected list"
                      ) : "Expected record";
                    return /* `Ok */[
                            17724,
                            Curry._2(Lang$SolvuuApi.Term.eval_error, message, t)
                          ];
                  } else {
                    var y$1 = Curry._1(Import.List.toArray, y[1]);
                    var e$1 = Curry._1(Std$Bolt.Res.all, Curry._2(Import.List.map, rows, (function (row) {
                                if (typeof row === "number" || row[0] !== 847309489) {
                                  return /* `Error */[
                                          106380200,
                                          /* ExpectedRecord */980211529
                                        ];
                                } else {
                                  var record = row[1];
                                  var e = Curry._1(Std$Bolt.Res.all, Curry._2(Import.List.map, xAxisColumnNames, (function (xAxisColumnName) {
                                              var match = Curry._2(Lang$SolvuuApi.Record.find, record, /* `Named */[
                                                    699531161,
                                                    xAxisColumnName
                                                  ]);
                                              if (match !== undefined) {
                                                return /* `Ok */[
                                                        17724,
                                                        match
                                                      ];
                                              } else {
                                                return /* `Error */[
                                                        106380200,
                                                        /* `LabelNotFound */[
                                                          1029964323,
                                                          xAxisColumnName
                                                        ]
                                                      ];
                                              }
                                            })));
                                  if (e[0] >= 106380200) {
                                    return e;
                                  } else {
                                    return /* `Ok */[
                                            17724,
                                            Curry._1(Import.List.toArray, Curry._2(Import.List.keepMap, e[1], termToNumeric))
                                          ];
                                  }
                                }
                              })));
                    var matrix = e$1[0] >= 106380200 ? e$1 : /* `Ok */[
                        17724,
                        Curry._1(Import.List.toArray, e$1[1])
                      ];
                    if (matrix[0] >= 106380200) {
                      var e$2 = matrix[1];
                      var message$1 = typeof e$2 === "number" ? (
                          e$2 >= 980211529 ? "Expected record" : "Expected list"
                        ) : "Invalid column with name: " + e$2[1];
                      return /* `Ok */[
                              17724,
                              Curry._2(Lang$SolvuuApi.Term.eval_error, message$1, t)
                            ];
                    } else {
                      var matrix$1 = matrix[1];
                      var normalizedMatrix = Science$SolvuuApi.normalizeMatrix(matrix$1);
                      var linkageFnFor = function (linkage) {
                        switch (linkage) {
                          case "Centroid" :
                              return /* `Ok */[
                                      17724,
                                      Science$SolvuuApi.centroidLinkage
                                    ];
                          case "Complete" :
                              return /* `Ok */[
                                      17724,
                                      Science$SolvuuApi.completeLinkage
                                    ];
                          case "Mean" :
                              return /* `Ok */[
                                      17724,
                                      Science$SolvuuApi.meanLinkage
                                    ];
                          case "Single" :
                              return /* `Ok */[
                                      17724,
                                      Science$SolvuuApi.singleLinkage
                                    ];
                          case "Ward" :
                              return /* `Ok */[
                                      17724,
                                      Science$SolvuuApi.wardLinkage
                                    ];
                          default:
                            return /* Invalid */144245463;
                        }
                      };
                      var distanceFnFor = function (distance) {
                        switch (distance) {
                          case "Euclidean" :
                              return /* `Ok */[
                                      17724,
                                      Science$SolvuuApi.euclideanDistance
                                    ];
                          case "KendallCorrelation" :
                              return /* `Ok */[
                                      17724,
                                      Science$SolvuuApi.kendallCorrelationDistance
                                    ];
                          case "Manhattan" :
                              return /* `Ok */[
                                      17724,
                                      Science$SolvuuApi.manhattanDistance
                                    ];
                          case "Maximum" :
                              return /* `Ok */[
                                      17724,
                                      Science$SolvuuApi.maximumDistance
                                    ];
                          case "PearsonCorrelation" :
                              return /* `Ok */[
                                      17724,
                                      Science$SolvuuApi.pearsonCorrelationDistance
                                    ];
                          case "SpearmanCorrelation" :
                              return /* `Ok */[
                                      17724,
                                      Science$SolvuuApi.spearmanCorrelationDistance
                                    ];
                          default:
                            return /* Invalid */144245463;
                        }
                      };
                      var normalizeNumClusters = function (axis, numClusters) {
                        var elements = axis.length;
                        var match = 1 <= numClusters;
                        var match$1 = numClusters <= elements;
                        if (match) {
                          if (match$1) {
                            return numClusters;
                          } else {
                            return elements;
                          }
                        } else {
                          return 1;
                        }
                      };
                      var yAxisClusterLevels = yAxisClustering !== undefined ? Curry._1(Std$Bolt.Res.join, Curry._3(Root$SolvuuApi.Heatmap.Clustering.of_record, yAxisClustering, (function (distance, linkage) {
                                    var match = linkageFnFor(linkage);
                                    if (typeof match === "number") {
                                      return /* `Error */[
                                              106380200,
                                              /* :: */[
                                                /* tuple */[
                                                  Root$SolvuuApi.Heatmap.Clustering.Hierarchical.Const.Label.linkage,
                                                  /* Invalid */144245463
                                                ],
                                                /* [] */0
                                              ]
                                            ];
                                    } else {
                                      var match$1 = distanceFnFor(distance);
                                      if (typeof match$1 === "number") {
                                        return /* `Error */[
                                                106380200,
                                                /* :: */[
                                                  /* tuple */[
                                                    Root$SolvuuApi.Heatmap.Clustering.Hierarchical.Const.Label.distance,
                                                    /* Invalid */144245463
                                                  ],
                                                  /* [] */0
                                                ]
                                              ];
                                      } else {
                                        return /* `Ok */[
                                                17724,
                                                Science$SolvuuApi.hierarchicalClustering(normalizedMatrix, match[1], match$1[1], false)
                                              ];
                                      }
                                    }
                                  }), (function (distance, numClusters) {
                                    var match = distanceFnFor(distance);
                                    if (typeof match === "number") {
                                      return /* `Error */[
                                              106380200,
                                              /* :: */[
                                                /* tuple */[
                                                  Root$SolvuuApi.Heatmap.Clustering.KMeans.Const.Label.distance,
                                                  /* Invalid */144245463
                                                ],
                                                /* [] */0
                                              ]
                                            ];
                                    } else {
                                      return /* `Ok */[
                                              17724,
                                              Science$SolvuuApi.kMeansClustering(normalizedMatrix, match[1], normalizeNumClusters(y$1, numClusters), false)
                                            ];
                                    }
                                  }))) : /* `Ok */[
                          17724,
                          /* array */[]
                        ];
                      var xAxisClusterLevels = xAxisClustering !== undefined ? Curry._1(Std$Bolt.Res.join, Curry._3(Root$SolvuuApi.Heatmap.Clustering.of_record, xAxisClustering, (function (distance, linkage) {
                                    var match = linkageFnFor(linkage);
                                    if (typeof match === "number") {
                                      return /* `Error */[
                                              106380200,
                                              /* :: */[
                                                /* tuple */[
                                                  Root$SolvuuApi.Heatmap.Clustering.Hierarchical.Const.Label.linkage,
                                                  /* Invalid */144245463
                                                ],
                                                /* [] */0
                                              ]
                                            ];
                                    } else {
                                      var match$1 = distanceFnFor(distance);
                                      if (typeof match$1 === "number") {
                                        return /* `Error */[
                                                106380200,
                                                /* :: */[
                                                  /* tuple */[
                                                    Root$SolvuuApi.Heatmap.Clustering.Hierarchical.Const.Label.distance,
                                                    /* Invalid */144245463
                                                  ],
                                                  /* [] */0
                                                ]
                                              ];
                                      } else {
                                        return /* `Ok */[
                                                17724,
                                                Science$SolvuuApi.hierarchicalClustering(normalizedMatrix, match[1], match$1[1], true)
                                              ];
                                      }
                                    }
                                  }), (function (distance, numClusters) {
                                    var match = distanceFnFor(distance);
                                    if (typeof match === "number") {
                                      return /* `Error */[
                                              106380200,
                                              /* :: */[
                                                /* tuple */[
                                                  Root$SolvuuApi.Heatmap.Clustering.KMeans.Const.Label.distance,
                                                  /* Invalid */144245463
                                                ],
                                                /* [] */0
                                              ]
                                            ];
                                    } else {
                                      return /* `Ok */[
                                              17724,
                                              Science$SolvuuApi.kMeansClustering(normalizedMatrix, match[1], normalizeNumClusters(x, numClusters), true)
                                            ];
                                    }
                                  }))) : /* `Ok */[
                          17724,
                          /* array */[]
                        ];
                      var match = y$1.length;
                      if (match !== 0) {
                        if (yAxisClusterLevels[0] >= 106380200) {
                          return yAxisClusterLevels;
                        } else if (xAxisClusterLevels[0] >= 106380200) {
                          return xAxisClusterLevels;
                        } else {
                          var x_001 = Curry._1(Import.List.fromArray, Belt_Array.map(x, (function (x) {
                                      return /* `String */[
                                              -976970511,
                                              x
                                            ];
                                    })));
                          var x$1 = /* `List */[
                            848054398,
                            x_001
                          ];
                          var y_001 = Curry._1(Import.List.fromArray, Belt_Array.map(y$1, (function (x) {
                                      return /* `String */[
                                              -976970511,
                                              x
                                            ];
                                    })));
                          var y$2 = /* `List */[
                            848054398,
                            y_001
                          ];
                          var matrix_001 = Curry._1(Import.List.fromArray, Belt_Array.map(matrix$1, (function (x) {
                                      return /* `List */[
                                              848054398,
                                              Curry._1(Import.List.fromArray, Belt_Array.map(x, (function (x) {
                                                          return /* `Float */[
                                                                  365180284,
                                                                  x
                                                                ];
                                                        })))
                                            ];
                                    })));
                          var matrix$2 = /* `List */[
                            848054398,
                            matrix_001
                          ];
                          var normalizedMatrix_001 = Curry._1(Import.List.fromArray, Belt_Array.map(normalizedMatrix, (function (x) {
                                      return /* `List */[
                                              848054398,
                                              Curry._1(Import.List.fromArray, Belt_Array.map(x, (function (x) {
                                                          return /* `Float */[
                                                                  365180284,
                                                                  x
                                                                ];
                                                        })))
                                            ];
                                    })));
                          var normalizedMatrix$1 = /* `List */[
                            848054398,
                            normalizedMatrix_001
                          ];
                          var xAxisClusterLevels_001 = Curry._1(Import.List.fromArray, Belt_Array.map(xAxisClusterLevels[1], (function (x) {
                                      return /* `List */[
                                              848054398,
                                              Curry._1(Import.List.fromArray, Belt_Array.map(x.clusters, (function (x) {
                                                          return /* `Int */[
                                                                  3654863,
                                                                  x
                                                                ];
                                                        })))
                                            ];
                                    })));
                          var xAxisClusterLevels$1 = /* `List */[
                            848054398,
                            xAxisClusterLevels_001
                          ];
                          var showOriginalData$1 = /* `Bool */[
                            737456202,
                            showOriginalData
                          ];
                          var yAxisClusterLevels_001 = Curry._1(Import.List.fromArray, Belt_Array.map(yAxisClusterLevels[1], (function (x) {
                                      return /* `List */[
                                              848054398,
                                              Curry._1(Import.List.fromArray, Belt_Array.map(x.clusters, (function (x) {
                                                          return /* `Int */[
                                                                  3654863,
                                                                  x
                                                                ];
                                                        })))
                                            ];
                                    })));
                          var yAxisClusterLevels$1 = /* `List */[
                            848054398,
                            yAxisClusterLevels_001
                          ];
                          var match$1 = Curry._1(Lang$SolvuuApi.Record.of_alist, /* :: */[
                                /* tuple */[
                                  Root$SolvuuApi.Heatmap.T.Const.Label.x,
                                  x$1
                                ],
                                /* :: */[
                                  /* tuple */[
                                    Root$SolvuuApi.Heatmap.T.Const.Label.y,
                                    y$2
                                  ],
                                  /* :: */[
                                    /* tuple */[
                                      Root$SolvuuApi.Heatmap.T.Const.Label.matrix,
                                      matrix$2
                                    ],
                                    /* :: */[
                                      /* tuple */[
                                        Root$SolvuuApi.Heatmap.T.Const.Label.normalizedMatrix,
                                        normalizedMatrix$1
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          Root$SolvuuApi.Heatmap.T.Const.Label.xAxisClusterLevels,
                                          xAxisClusterLevels$1
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            Root$SolvuuApi.Heatmap.T.Const.Label.yAxisClusterLevels,
                                            yAxisClusterLevels$1
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              Root$SolvuuApi.Heatmap.T.Const.Label.showOriginalData,
                                              showOriginalData$1
                                            ],
                                            /* [] */0
                                          ]
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]);
                          if (match$1[0] >= 17724) {
                            return /* `Ok */[
                                    17724,
                                    /* `Viz */[
                                      4300231,
                                      /* `Record */[
                                        847309489,
                                        Curry._2(Lang$SolvuuApi.Record.tagged, f, /* `Record */[
                                              847309489,
                                              match$1[1]
                                            ])
                                      ]
                                    ]
                                  ];
                          } else {
                            throw [
                                  Caml_builtin_exceptions.assert_failure,
                                  /* tuple */[
                                    "RunHeatmap.re",
                                    376,
                                    47
                                  ]
                                ];
                          }
                        }
                      } else {
                        return /* `Ok */[
                                17724,
                                Curry._2(Lang$SolvuuApi.Term.eval_error, "No data", t)
                              ];
                      }
                    }
                  }
                } else {
                  return /* `Error */[
                          106380200,
                          /* :: */[
                            /* tuple */[
                              Root$SolvuuApi.Heatmap.Arg.Const.Label.yAxisColumnName,
                              /* Not_found */-358247754
                            ],
                            /* [] */0
                          ]
                        ];
                }
              })));
    if (result[0] >= 106380200) {
      var message = Curry._1(Import.List.toArray, Curry._2(Import.List.map, result[1], (function (param) {
                    var label = Curry._1(Lang$SolvuuApi.Label.to_string, param[0]);
                    var reason = param[1] >= 144245463 ? "invalid" : "not_found";
                    return "(" + (label + (", " + (reason + ") ")));
                  }))).join(" ");
      return Curry._2(Lang$SolvuuApi.Term.eval_error, message, t);
    } else {
      return result[1];
    }
  }
}

export {
  $$eval ,
  
}
/* Import Not a pure module */
