import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { BarChart, Bar, XAxis, YAxis, Tooltip } from "recharts";
import { scaleLinear } from "d3-scale";
import { min as d3Min, max as d3Max } from "d3-array";
import { groupWith, equals, sort, pipe, map, range, head, last } from "ramda";

import ResponsiveContainer from "components/ResponsiveContainer";
import { primary } from "lib/colors";

import styles from "table/TableBoxPlot.css";

class BoxPlotTooltip extends Component {
  render() {
    const payloads = this.props.payload;
    if (payloads === null || payloads.length === 0) return null;

    const payload = payloads[0].payload;

    const formattedMinimum = <FormattedNumber value={payload.min} />;
    const formattedMaximum = <FormattedNumber value={payload.max} />;
    const formattedMean = <FormattedNumber value={payload.mean} />;
    const formattedMedian = <FormattedNumber value={payload.median} />;
    const formattedQuartile1 = (
      <FormattedNumber value={payload.firstQuartile} />
    );
    const formattedQuartile3 = (
      <FormattedNumber value={payload.thirdQuartile} />
    );

    const values = [
      ["min", formattedMinimum],
      ["mean", formattedMean],
      ["firstQuartile", formattedQuartile1],
      ["median", formattedMedian],
      ["thirdQuartile", formattedQuartile3],
      ["max", formattedMaximum]
    ];

    return (
      <div className={styles.tooltip}>
        <p>
          <strong>{payload.name}</strong>
        </p>
        {values.map(([id, value]) => (
          <p key={id} className={styles.tooltipValue}>
            <FormattedMessage
              id={`table.boxPlot.tooltip.${id}`}
              values={{ value }}
            />
          </p>
        ))}
      </div>
    );
  }
}

const sortAscending = sort((a, b) => a - b);

const WHISKER_SPAN = 10;
const OUTLIER_RADIUS = 2;
const OUTLIER_MARGIN = 3;
const OUTLIER_STROKE = "#000";
const OUTLIER_FILL = "#fff";

class BoxWithWhiskers extends Component {
  renderOutliers(center) {
    const { scale, payload } = this.props;

    // Positions for outlier circles
    function makeScaleForOutliers(outliers) {
      const count = outliers.length;
      const r = OUTLIER_RADIUS;
      console.assert(count > 0, "There needs to be at least 1 outlier");

      // Draw outliers side-by side if multiple at the same level
      const width = count * 2 * r + (count - 1) * OUTLIER_MARGIN;
      const ids = range(0, count);
      const cx = scaleLinear()
        .domain([head(ids), last(ids)])
        .range([center - width / 2 + r, center + width / 2 - r]);

      const cy = scale(head(outliers));
      return { cx, cy, r, ids };
    }

    const outliers = pipe(
      sortAscending,
      groupWith(equals),
      map(makeScaleForOutliers)
    )(payload.outliers);

    return (
      <g data-testid="box-plot-outliers">
        {outliers.map(({ cx, cy, r, ids }) => (
          <g key={cy}>
            {ids.map(id => (
              <circle
                key={id}
                cx={cx(id)}
                cy={cy}
                r={r}
                fill={OUTLIER_FILL}
                stroke={OUTLIER_STROKE}
              />
            ))}
          </g>
        ))}
      </g>
    );
  }

  render() {
    const { x, width, payload, fill, scale, showOutliers } = this.props;

    // Y-axis positions of important plot elements
    const boxStart = scale(payload.thirdQuartile);
    const boxEnd = scale(payload.firstQuartile);
    const boxMiddle = scale(payload.median);
    const whiskersStart = scale(payload.whiskersStart);
    const whiskersEnd = scale(payload.whiskersEnd);
    const boxY = Math.min(boxStart, boxEnd);
    const boxHeight = Math.abs(boxStart - boxEnd);

    // X-axis positions of plot elements
    const center = x + width / 2;
    const whiskerSpanStart = center - WHISKER_SPAN;
    const whiskerSpanEnd = center + WHISKER_SPAN;

    return (
      <g data-testid="box-plot-box-with-whiskers">
        <g data-testid="box-plot-whiskers">
          <line
            x1={center}
            y1={whiskersStart}
            x2={center}
            y2={whiskersEnd}
            stroke="#000"
            strokeDasharray="4 2"
          />
          <line
            x1={whiskerSpanStart}
            y1={whiskersStart}
            x2={whiskerSpanEnd}
            y2={whiskersStart}
            stroke="#000"
          />
          <line
            x1={whiskerSpanStart}
            y1={whiskersEnd}
            x2={whiskerSpanEnd}
            y2={whiskersEnd}
            stroke="#000"
          />
        </g>
        {showOutliers && this.renderOutliers(center)}
        <rect
          x={x}
          y={boxY}
          width={width}
          height={boxHeight}
          stroke="#000"
          fill="#fff"
          data-testid="box-plot-box"
        />
        <line
          x1={x}
          y1={boxMiddle}
          x2={x + width}
          y2={boxMiddle}
          stroke={fill}
          strokeWidth={2.5}
          data-testid="box-plot-median"
        />
      </g>
    );
  }
}

export default class BoxPlot extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        min: PropTypes.number.isRequired,
        max: PropTypes.number.isRequired,
        mean: PropTypes.number.isRequired,
        median: PropTypes.number.isRequired,
        firstQuartile: PropTypes.number.isRequired,
        thirdQuartile: PropTypes.number.isRequired,
        trimmedMin: PropTypes.number.isRequired,
        trimmedMax: PropTypes.number.isRequired
      })
    ).isRequired,
    showOutliers: PropTypes.bool.isRequired
  };

  render() {
    const { data, showOutliers } = this.props;

    const dataWithWhiskers = data.map(stats => ({
      ...stats,
      whiskersStart: Math.min(stats.trimmedMin, stats.firstQuartile),
      whiskersEnd: Math.max(stats.trimmedMax, stats.thirdQuartile)
    }));

    function getDomainMin(s) {
      return showOutliers ? s.min : s.whiskersStart;
    }
    function getDomainMax(s) {
      return showOutliers ? s.max : s.whiskersEnd;
    }

    const min = d3Min(dataWithWhiskers, getDomainMin);
    const max = d3Max(dataWithWhiskers, getDomainMax);
    const domain = [min, max];
    const scale = scaleLinear().domain(domain);
    const dataKey = "median";

    const rotateXTicks = data.length > 10;
    const tick = rotateXTicks
      ? {
          angle: -90,
          textAnchor: "end",
          dy: 5,
          dx: -5
        }
      : undefined;
    const xAxisHeight = rotateXTicks ? 150 : undefined;
    const width = "100%";
    const height = 300 + (xAxisHeight || 0);
    const margin = { left: 20 };

    return (
      <ResponsiveContainer width={width} height={height}>
        <BarChart data={dataWithWhiskers} margin={margin}>
          <XAxis dataKey="name" interval={0} tick={tick} height={xAxisHeight} />
          <YAxis
            dataKey={dataKey}
            scale={scale}
            domain={domain}
            label={{ value: "Value", angle: -90, position: "left", dy: -10 }}
          />
          <Tooltip content={<BoxPlotTooltip />} cursor={{ opacity: 0.5 }} />
          <Bar
            dataKey={dataKey}
            fill={primary}
            isAnimationActive={false}
            shape={
              <BoxWithWhiskers scale={scale} showOutliers={showOutliers} />
            }
          />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
