

import * as Aws from "../../../../features/Aws.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Import from "../../../../features/import.bs.js";
import * as Pervasives from "bs-platform/lib/es6/pervasives.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as OperationStatus from "../../../../features/utils/OperationStatus.bs.js";
import * as MFASettingsJs from "./MFASettings.js";
import * as AmazonCognitoIdentityJS from "../../../../lib/AmazonCognitoIdentityJS.bs.js";
import * as AmazonCognitoIdentityJs from "amazon-cognito-identity-js";

function ok_exn(x) {
  if (x[0] >= 106380200) {
    console.log(x[1]);
    return Pervasives.failwith("");
  } else {
    return x[1];
  }
}

var make = MFASettingsJs.default;

var Internal = {
  make: make
};

function useFetchMFASettings(param) {
  var match = React.useState((function () {
          return /* Initial */418396260;
        }));
  var setState = match[1];
  var state = match[0];
  React.useEffect((function () {
          if (typeof state !== "number") {
            if (state[0] !== -700189092) {
              
            } else {
              Import.$great$great$eq(Aws.getAuthenticatedUser(/* () */0), (function (cognitoUser) {
                      return Import.$great$great$eq(AmazonCognitoIdentityJS.User.getMFAOptions(cognitoUser), (function (mfaOptions) {
                                    return Import.$great$great$pipe(AmazonCognitoIdentityJS.User.getUserAttributes(cognitoUser), (function (userAttributes) {
                                                  var phoneAttribute = Curry._2(Import.List.getBy, Curry._1(Import.List.fromArray, ok_exn(userAttributes)), (function (a) {
                                                          return a.getName() === "phone_number";
                                                        }));
                                                  var enabled = (mfaOptions == null);
                                                  var phoneNumber = Belt_Option.map(phoneAttribute, (function (x) {
                                                          return x.getValue();
                                                        }));
                                                  return Curry._1(setState, (function (param) {
                                                                return /* `Success */[
                                                                        94326179,
                                                                        {
                                                                          enabled: enabled,
                                                                          phoneNumber: phoneNumber
                                                                        }
                                                                      ];
                                                              }));
                                                }));
                                  }));
                    }));
            }
          }
          return ;
        }), /* array */[state]);
  var run = function (param) {
    return Curry._1(setState, (function (param) {
                  return /* `Loading */[
                          -700189092,
                          /* () */0
                        ];
                }));
  };
  return /* tuple */[
          state,
          run
        ];
}

function useUpdateMFASettings(form) {
  var match = React.useState((function () {
          return /* Initial */418396260;
        }));
  var setState = match[1];
  var state = match[0];
  React.useEffect((function () {
          if (typeof state !== "number" && state[0] === -700189092) {
            var match = form.phoneNumber;
            if (match !== undefined) {
              var phoneNumber = match;
              Import.$great$great$eq(Aws.getAuthenticatedUser(/* () */0), (function (cognitoUser) {
                      var tmp;
                      if (form.enabled == null) {
                        var attributes = /* array */[new AmazonCognitoIdentityJs.CognitoUserAttribute({
                                Name: "phone_number",
                                Value: phoneNumber
                              })];
                        tmp = Import.$great$great$pipe(Import.$great$great$pipe(AmazonCognitoIdentityJS.User.updateAttributes(cognitoUser, attributes), ok_exn), (function (prim) {
                                return /* () */0;
                              }));
                      } else {
                        tmp = Import.$$return(/* () */0);
                      }
                      return Import.$great$great$pipe(Import.$great$great$eq(tmp, (function (param) {
                                        var match = (form.enabled == null);
                                        if (match) {
                                          return AmazonCognitoIdentityJS.User.enableMFA(cognitoUser);
                                        } else {
                                          return AmazonCognitoIdentityJS.User.disableMFA(cognitoUser);
                                        }
                                      })), (function (param) {
                                    return Curry._1(setState, (function (param) {
                                                  return /* `Success */[
                                                          94326179,
                                                          form
                                                        ];
                                                }));
                                  }));
                    }));
            } else {
              Curry._1(setState, (function (param) {
                      return /* `Error */[
                              106380200,
                              "Phone Number Required"
                            ];
                    }));
            }
          }
          return ;
        }), /* array */[state]);
  var run = function (param) {
    return Curry._1(setState, (function (param) {
                  return /* `Loading */[
                          -700189092,
                          /* () */0
                        ];
                }));
  };
  return /* tuple */[
          state,
          run
        ];
}

var Hooks = {
  useFetchMFASettings: useFetchMFASettings,
  useUpdateMFASettings: useUpdateMFASettings
};

function MFASettings$Page(Props) {
  Props.apiClient;
  var match = React.useState((function () {
          return {
                  enabled: undefined,
                  phoneNumber: undefined
                };
        }));
  var form = match[0];
  var mfaFormChange = function (param) {
    console.log("mfaFormChange");
    return /* () */0;
  };
  var match$1 = useFetchMFASettings(/* () */0);
  var match$2 = useUpdateMFASettings(form);
  var bridge = function (x) {
    if (typeof x === "number") {
      return /* Initial */0;
    } else {
      var variant = x[0];
      if (variant !== 94326179) {
        if (variant >= 106380200) {
          return /* Failure */[Caml_option.some(x[1])];
        } else {
          return /* Pending */1;
        }
      } else {
        return /* Success */2;
      }
    }
  };
  var $$fetch = OperationStatus.convertToJs(bridge(match$1[0]));
  var update = OperationStatus.convertToJs(bridge(match$2[0]));
  var meta = {
    fetch: $$fetch,
    update: update
  };
  return React.createElement(make, {
              fetchMFASettings: match$1[1],
              updateMFASettings: match$2[1],
              mfaFormChange: mfaFormChange,
              form: form,
              meta: meta
            });
}

var Page = {
  make: MFASettings$Page
};

export {
  ok_exn ,
  Internal ,
  Hooks ,
  Page ,
  
}
/* make Not a pure module */
