

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Import from "../features/import.bs.js";
import * as Std$Bolt from "bolt/./std.bs.js";
import * as BoxPlotJs from "./BoxPlot.js";
import * as Root$SolvuuApi from "solvuu-api/./root.bs.js";

function ofRecord(record) {
  return Curry._1(Std$Bolt.Res.join, Curry._2(Root$SolvuuApi.Box_plot.T.of_record, record, (function (columnStats, showOutliers) {
                    var columnStats$1 = Curry._1(Std$Bolt.Res.all, Curry._2(Import.List.map, columnStats, (function (param) {
                                var name = param[0];
                                return Curry._2(Root$SolvuuApi.Stats.T.of_record, param[1], (function (param, param$1, max, min, param$2, param$3, mean, param$4, param$5, median, first_quartile, third_quartile, trimmed_max, trimmed_min, outliers) {
                                              return {
                                                      name: name,
                                                      min: min,
                                                      max: max,
                                                      mean: mean,
                                                      median: median,
                                                      firstQuartile: first_quartile,
                                                      thirdQuartile: third_quartile,
                                                      trimmedMin: trimmed_min,
                                                      trimmedMax: trimmed_max,
                                                      outliers: Curry._1(Import.List.toArray, outliers)
                                                    };
                                            }));
                              })));
                    if (columnStats$1[0] >= 106380200) {
                      return columnStats$1;
                    } else {
                      return /* `Ok */[
                              17724,
                              {
                                data: Curry._1(Import.List.toArray, columnStats$1[1]),
                                showOutliers: showOutliers
                              }
                            ];
                    }
                  })));
}

function ofTerm(term) {
  if (typeof term === "number" || term[0] !== 847309489) {
    return /* `Error */[
            106380200,
            /* Expected_record */-756637544
          ];
  } else {
    var res = ofRecord(term[1]);
    return Curry._2(Std$Bolt.Res.map_error, res, (function (e) {
                  return /* `Record */[
                          847309489,
                          e
                        ];
                }));
  }
}

var Props = {
  ofRecord: ofRecord,
  ofTerm: ofTerm
};

var make = BoxPlotJs.default;

export {
  Props ,
  make ,
  
}
/* make Not a pure module */
