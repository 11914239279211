

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as JobJs from "../../../lib/solvuu/jobJs.bs.js";
import * as React from "react";
import * as Button from "../../../components/Button.bs.js";
import * as Command from "../Command/Command.bs.js";
import * as JobStatus from "../JobStatus/JobStatus.bs.js";
import * as ReactIntl from "react-intl";
import * as StyleCss from "./style.css";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as JobDuration from "../JobDuration/JobDuration.bs.js";
import * as ReactExtras from "../../../lib/solvuu/ReactExtras.bs.js";
import * as Job$SolvuuApi from "solvuu-api/./job.bs.js";
import * as TableElements from "../../../table/TableElements.bs.js";
import * as FormattedRelative from "../../../components/FormattedRelative/FormattedRelative.bs.js";
import * as Time$SolvuuClient from "solvuu-client/./time.bs.js";

var styles = StyleCss.default;

var iconDate = (require("icons/icon-date-black.svg"));

var iconTime = (require("icons/icon-time-black.svg"));

function JobRow(Props) {
  var job = Props.job;
  var jobId = Job$SolvuuApi.id(job);
  var match = job.spec;
  var commandCell = React.createElement(Command.make, {
        spec: /* `Solvuu */[
          -522825178,
          match[1].command.spec
        ]
      });
  var statusCell = React.createElement(JobStatus.make, {
        job: job
      });
  var timeCell = React.createElement(React.Fragment, undefined, React.createElement("div", {
            className: styles.time
          }, React.createElement("img", {
                className: styles.icon,
                alt: "",
                src: iconDate
              }), React.createElement(FormattedRelative.make, {
                value: Time$SolvuuClient.of_span_since_epoch(Curry._1(Time$SolvuuClient.Span.of_sec, job.created))
              })), React.createElement("div", {
            className: styles.time
          }, React.createElement("img", {
                className: styles.icon,
                alt: "",
                src: iconTime
              }), React.createElement(JobDuration.make, {
                created: Time$SolvuuClient.of_span_since_epoch(Curry._1(Time$SolvuuClient.Span.of_sec, job.created)),
                completed: Belt_Option.map(job.completed, (function (seconds) {
                        return Time$SolvuuClient.of_span_since_epoch(Curry._1(Time$SolvuuClient.Span.of_sec, seconds));
                      }))
              })));
  var actionsCell = React.createElement(Button.Link.make, Button.Link.makeProps(undefined, /* `Jobs */[
            826169846,
            /* `Id */[
              16379,
              jobId
            ]
          ], /* Secondary */67972948, undefined, undefined, React.createElement(ReactIntl.FormattedMessage, {
                id: "jobs.actions.show"
              }), /* () */0));
  return React.createElement(TableElements.RowElement.make, {
              children: null
            }, React.createElement(TableElements.CellElement.make, {
                  testId: "id",
                  children: jobId
                }), React.createElement(TableElements.CellElement.make, {
                  testId: "status",
                  children: statusCell
                }), React.createElement(TableElements.CellElement.make, {
                  testId: "command",
                  children: commandCell
                }), React.createElement(TableElements.CellElement.make, {
                  testId: "time",
                  children: timeCell
                }), React.createElement(TableElements.CellElement.make, {
                  testId: "actions",
                  children: actionsCell
                }));
}

function mapProps(props) {
  var job = Curry._1(JobJs.convertFromJs, props.job);
  return {
          job: job
        };
}

var $$default = ReactExtras.jsComponent("Jobs.JobRow", JobRow, mapProps);

var make = JobRow;

export {
  styles ,
  iconDate ,
  iconTime ,
  make ,
  mapProps ,
  $$default ,
  $$default as default,
  
}
/* styles Not a pure module */
