import { createOperation } from "features/utils";

export const jobCreateOperation = createOperation("JOBS/JOB_CREATE");
export const jobFetchOperation = createOperation("JOBS/JOB_FETCH");
export const jobEventsFetchOperation = createOperation("JOBS/JOB_EVENTS_FETCH");
export const jobOutputFetchOperation = createOperation("JOBS/JOB_OUTPUT_FETCH");
export const jobMetricsFetchOperation = createOperation(
  "JOBS/JOB_METRICS_FETCH"
);
export const jobsFetchOperation = createOperation("JOBS/JOBS_FETCH");
