

import * as List from "bs-platform/lib/es6/list.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Import from "../import.bs.js";
import * as Ndarray from "bs-bigarray/src/ndarray.bs.js";
import * as Caml_obj from "bs-platform/lib/es6/caml_obj.js";
import * as Dataframe from "../../lib/solvuu/dataframe.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Pervasives from "bs-platform/lib/es6/pervasives.js";
import * as MemoizeWeak from "memoize-weak";

var memoized = MemoizeWeak.default((function (dataframeJs) {
        return Curry._1(Dataframe.Indexed.read_t, dataframeJs);
      }));

function dataframeFromJs(dataframe) {
  return memoized(dataframe);
}

function bigdataForAttribute(frames, attribute) {
  return List.find((function (param) {
                  return List.exists((function (label) {
                                return Caml_obj.caml_equal(label, attribute);
                              }), param[0]);
                }), frames)[1];
}

function oneDimensionRange(ndarray) {
  return Belt_Array.range(0, Ndarray.size(ndarray) - 1 | 0);
}

var memoized$1 = MemoizeWeak.default((function (bigdata) {
        var variant = bigdata[0];
        if (variant !== -714735238) {
          if (variant >= 28091885) {
            if (variant >= 81581934) {
              var ndarray = bigdata[1];
              if (Ndarray.dimension(ndarray) === 1) {
                var floats = Belt_Array.map(oneDimensionRange(ndarray), (function (index) {
                        return Curry._2(Ndarray.get, ndarray, /* array */[index]);
                      }));
                return /* `Floats */[
                        -169175177,
                        floats
                      ];
              } else {
                return Pervasives.failwith("Unsupported data type");
              }
            } else {
              var ndarray$1 = bigdata[1];
              if (Ndarray.dimension(ndarray$1) === 1) {
                var floats$1 = Belt_Array.map(oneDimensionRange(ndarray$1), (function (index) {
                        return Curry._2(Ndarray.get, ndarray$1, /* array */[index]);
                      }));
                return /* `Floats */[
                        -169175177,
                        floats$1
                      ];
              } else {
                return Pervasives.failwith("Unsupported data type");
              }
            }
          } else if (variant >= -661245189) {
            return Pervasives.failwith("Unsupported data type");
          } else {
            var match = bigdata[1];
            if (match[0] >= -83649777) {
              return /* `StringMatrix */[
                      456331794,
                      match[1]
                    ];
            } else {
              return /* `Strings */[
                      -968575390,
                      match[1]
                    ];
            }
          }
        } else {
          var ndarray$2 = bigdata[1];
          if (Ndarray.dimension(ndarray$2) === 1) {
            var ints = Belt_Array.map(oneDimensionRange(ndarray$2), (function (index) {
                    return Curry._2(Ndarray.get, ndarray$2, /* array */[index]);
                  }));
            return /* `Ints */[
                    815034564,
                    ints
                  ];
          } else {
            return Pervasives.failwith("Unsupported data type");
          }
        }
      }));

function getIndexedData(dataframeJs, attribute) {
  var dataframe = dataframeFromJs(dataframeJs);
  var value = memoized$1(bigdataForAttribute(dataframe.indexed, attribute));
  var variant = value[0];
  if (variant >= 456331794) {
    if (variant >= 815034564) {
      return Belt_Array.map(Belt_Array.map(value[1], (function (prim) {
                        return prim;
                      })), (function (prim) {
                    return prim;
                  }));
    } else {
      return Belt_Array.map(value[1], (function (prim) {
                    return prim;
                  }));
    }
  } else if (variant >= -169175177) {
    return Belt_Array.map(value[1], (function (prim) {
                  return prim;
                }));
  } else {
    return Belt_Array.map(value[1], (function (prim) {
                  return prim;
                }));
  }
}

function getIndexedAttributes(dataframeJs) {
  var dataframe = dataframeFromJs(dataframeJs);
  return Curry._1(Import.List.toArray, Curry._1(Import.List.flatten, Curry._2(Import.List.map, dataframe.indexed, (function (param) {
                        return param[0];
                      }))));
}

function getIndexedStringMatrixData(dataframeJs, attribute) {
  var dataframe = dataframeFromJs(dataframeJs);
  var value = memoized$1(bigdataForAttribute(dataframe.indexed, attribute));
  if (typeof value === "number" || value[0] !== 456331794) {
    return Pervasives.failwith("Unsupported data type");
  } else {
    return value[1];
  }
}

function getIndexedStringData(dataframeJs, attribute) {
  var dataframe = dataframeFromJs(dataframeJs);
  var value = memoized$1(bigdataForAttribute(dataframe.indexed, attribute));
  if (typeof value === "number" || value[0] !== -968575390) {
    return Pervasives.failwith("Unsupported data type");
  } else {
    return value[1];
  }
}

function getIndex(dataframeJs, attribute) {
  var dataframe = dataframeFromJs(dataframeJs);
  var value = memoized$1(bigdataForAttribute(dataframe.index, attribute));
  if (typeof value === "number" || value[0] !== 815034564) {
    return Pervasives.failwith("Unsupported data type");
  } else {
    return Belt_Array.map(value[1], (function (prim) {
                  return prim;
                }));
  }
}

function getMatrixData(dataframeJs, attribute) {
  var dataframe = dataframeFromJs(dataframeJs);
  var value = memoized$1(bigdataForAttribute(dataframe.data, attribute));
  if (typeof value === "number" || value[0] !== -169175177) {
    return Pervasives.failwith("Unsupported data type");
  } else {
    return value[1];
  }
}

function hasIndexedData(dataframeJs, attribute) {
  var dataframe = dataframeFromJs(dataframeJs);
  try {
    bigdataForAttribute(dataframe.indexed, attribute);
    return true;
  }
  catch (exn){
    return false;
  }
}

export {
  dataframeFromJs ,
  getIndexedData ,
  getIndexedAttributes ,
  getIndexedStringMatrixData ,
  getIndexedStringData ,
  getIndex ,
  getMatrixData ,
  hasIndexedData ,
  
}
/* memoized Not a pure module */
