

import * as Err from "../../../components/Err.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Input from "../../../components/FormControls/Input.bs.js";
import * as Modal from "../../../components/Modal/Modal.bs.js";
import * as React from "react";
import * as Button from "../../../components/Button.bs.js";
import * as Upload0 from "../Upload0.bs.js";
import * as StyleCss from "./style.css";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as VerticalLayout from "../../../components/Layout/VerticalLayout.bs.js";
import * as HorizontalLayout from "../../../components/Layout/HorizontalLayout.bs.js";
import * as Modal$1 from "components/Modal";
import * as Layout from "components/Layout";

var styles = StyleCss.default;

function NewURIModal(Props) {
  var onSubmit = Props.onSubmit;
  var onClose = Props.onClose;
  var status = Props.status;
  var status$1 = Curry._1(Upload0.Operations.$$Blob.CreateFromURI.Status.convertFromOperation, status);
  var match = React.useReducer((function (_state, action) {
          return {
                  uri: action[0]
                };
        }), {
        uri: ""
      });
  var send = match[1];
  var state = match[0];
  var isSubmitting;
  isSubmitting = typeof status$1 === "number" || status$1.tag ? false : true;
  var handleInputChange = function (value) {
    return Curry._1(send, /* ChangeURL */[value]);
  };
  var handleSubmit = function ($$event) {
    $$event.preventDefault();
    return Curry._1(onSubmit, state.uri);
  };
  var handleModalClose = function (param) {
    if (isSubmitting) {
      return /* () */0;
    } else {
      return Curry._1(onClose, /* () */0);
    }
  };
  var inputElement = React.createElement(Input.make, {
        name: "uri",
        label: "URL",
        value: state.uri,
        onChange: handleInputChange,
        type_: "url",
        required: true,
        autoFocus: true
      });
  var cancelButton = React.createElement(Button.make, Button.makeProps(handleModalClose, undefined, /* `Basic */[
            -1058563442,
            Curry._4(Button.Type.Basic.make, undefined, /* Secondary */67972948, isSubmitting, /* () */0)
          ], "Cancel", /* () */0));
  var submitButton = React.createElement(Button.make, Button.makeProps(undefined, undefined, /* `Submit */[
            -828715976,
            Curry._5(Button.Type.Submit.make, isSubmitting, undefined, undefined, undefined, /* () */0)
          ], "Submit", /* () */0));
  var errorElement;
  if (typeof status$1 === "number" || status$1.tag !== /* Failure */2) {
    errorElement = null;
  } else {
    var errors = status$1[0].errors;
    var message = errors.length !== 0 ? errors.join(" ") : undefined;
    var tmp = { };
    if (message !== undefined) {
      tmp.message = Caml_option.valFromOption(message);
    }
    errorElement = React.createElement(Layout.VerticalLayoutItem, {
          children: React.createElement(Err.Operation.make, tmp)
        });
  }
  return React.createElement(Modal$1.default, Modal.makeProps(undefined, undefined, handleModalClose, 600, React.createElement(Modal$1.ModalBody, {
                      children: React.createElement("form", {
                            disabled: isSubmitting,
                            onSubmit: handleSubmit
                          }, React.createElement(Layout.VerticalLayout, VerticalLayout.makeProps(/* x2 */26810, null, /* () */0), errorElement, React.createElement(Layout.VerticalLayoutItem, {
                                    children: inputElement
                                  }), React.createElement(Layout.VerticalLayoutItem, {
                                    children: React.createElement("div", {
                                          className: styles.buttons
                                        }, React.createElement(Layout.HorizontalLayout, HorizontalLayout.makeProps(/* x3 */26811, null, /* () */0), React.createElement(Layout.HorizontalLayoutItem, {
                                                  children: cancelButton
                                                }), React.createElement(Layout.HorizontalLayoutItem, {
                                                  children: submitButton
                                                })))
                                  })))
                    }), /* () */0));
}

var make = NewURIModal;

export {
  styles ,
  make ,
  
}
/* styles Not a pure module */
