import { connect } from "react-redux";

import { dismissBlob, blobsSelector } from "features/entities/create/blobs";

import BlobsForm from "containers/Entities/EntityCreator/BlobsForm/BlobsForm.js";

function mapStateToProps(state) {
  return {
    blobs: blobsSelector(state)
  };
}

const mapDispatchToProps = { dismissBlob };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BlobsForm);
