

import * as ByteSize from "byte-size";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";

function byteSize(units, precision, value) {
  var options_units = Belt_Option.map(units, (function (x) {
          if (x >= 271889119) {
            if (x >= 1039596624) {
              return "metric";
            } else {
              return "metric_octet";
            }
          } else if (x >= 186609384) {
            return "iec_octet";
          } else {
            return "iec";
          }
        }));
  var options = {
    units: options_units,
    precision: precision
  };
  return ByteSize.default(value, options);
}

export {
  byteSize ,
  
}
/* byte-size Not a pure module */
