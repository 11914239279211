/**
 * Module for managing information from phylogenetic trees.
 */
import { NewickTools } from "newick";

import { decodeNewickData } from "lib/solvuu/termUtils";

// SELECTORS

export function phylogeneticTreeDataSelector(state, term) {
  const newickString = decodeNewickData(term);
  const tree = NewickTools.parse(newickString);
  return { tree };
}

export function isNewickString(string) {
  try {
    return !!NewickTools.parse(string);
  } catch (_) {
    return false;
  }
}
