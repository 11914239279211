

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Import from "../features/import.bs.js";
import * as Std$Bolt from "bolt/./std.bs.js";
import * as Lang$SolvuuApi from "solvuu-api/./lang.bs.js";
import * as Root$SolvuuApi from "solvuu-api/./root.bs.js";

function $$eval(f, t) {
  if (typeof t === "number" || t[0] !== 847309489) {
    return Promise.resolve(Curry._2(Lang$SolvuuApi.Term.eval_error, "Expected record", t));
  } else {
    var result = Curry._2(Root$SolvuuApi.Line_chart.Arg.of_record, t[1], (function (columnNames, labelColumnName, data) {
            var series = Curry._2(Std$Bolt.Res.map_error, Curry._1(Std$Bolt.Res.all, Curry._2(Import.List.map, columnNames, (function (name) {
                            return Curry._2(Std$Bolt.Res.map, Curry._3(Lang$SolvuuApi.Table.project_column, data, /* `Named */[
                                            699531161,
                                            name
                                          ], Curry._1(Lang$SolvuuApi.Record.Param.Arg_type.create, (function (param) {
                                                  if (typeof param === "number") {
                                                    return /* Invalid */144245463;
                                                  } else {
                                                    var variant = param[0];
                                                    if (variant !== 3654863 && variant !== 365180284) {
                                                      if (variant !== 925978388) {
                                                        return /* Invalid */144245463;
                                                      } else {
                                                        var match = param[1];
                                                        if (typeof match === "number") {
                                                          return /* Invalid */144245463;
                                                        } else {
                                                          var variant$1 = match[0];
                                                          if (variant$1 !== 3654863 && variant$1 !== 365180284) {
                                                            return /* Invalid */144245463;
                                                          } else {
                                                            return /* `Ok */[
                                                                    17724,
                                                                    match[1]
                                                                  ];
                                                          }
                                                        }
                                                      }
                                                    } else {
                                                      return /* `Ok */[
                                                              17724,
                                                              param[1]
                                                            ];
                                                    }
                                                  }
                                                }))), (function (data) {
                                          return /* tuple */[
                                                  name,
                                                  data
                                                ];
                                        }));
                          }))), (function (param) {
                    return /* :: */[
                            /* tuple */[
                              Root$SolvuuApi.Line_chart.Arg.Const.Label.data,
                              /* Invalid */144245463
                            ],
                            /* [] */0
                          ];
                  }));
            if (series[0] >= 106380200) {
              return series;
            } else {
              var groupNames;
              if (labelColumnName !== undefined) {
                var name = labelColumnName;
                groupNames = Curry._2(Std$Bolt.Res.map_error, Curry._3(Lang$SolvuuApi.Table.project_column, data, /* `Named */[
                          699531161,
                          name
                        ], Curry._1(Lang$SolvuuApi.Record.Param.Arg_type.create, (function (param) {
                                if (typeof param === "number") {
                                  return /* `Ok */[
                                          17724,
                                          ""
                                        ];
                                } else {
                                  var variant = param[0];
                                  if (variant !== -976970511) {
                                    if (variant !== 925978388) {
                                      return /* `Ok */[
                                              17724,
                                              ""
                                            ];
                                    } else {
                                      var match = param[1];
                                      if (typeof match === "number" || match[0] !== -976970511) {
                                        return /* `Ok */[
                                                17724,
                                                ""
                                              ];
                                      } else {
                                        return /* `Ok */[
                                                17724,
                                                match[1]
                                              ];
                                      }
                                    }
                                  } else {
                                    return /* `Ok */[
                                            17724,
                                            param[1]
                                          ];
                                  }
                                }
                              }))), (function (param) {
                        return /* `InvalidColumn */[
                                334299405,
                                name
                              ];
                      }));
              } else {
                groupNames = typeof data === "number" || data[0] !== 848054398 ? /* `Error */[
                    106380200,
                    /* ExpectedList */429088534
                  ] : /* `Ok */[
                    17724,
                    Curry._2(Import.List.makeBy, Curry._1(Import.List.length, data[1]), (function (prim) {
                            return String(prim);
                          }))
                  ];
              }
              if (groupNames[0] >= 106380200) {
                var e = groupNames[1];
                var message = typeof e === "number" ? "Expected list" : "Invalid column: " + e[1];
                return /* `Ok */[
                        17724,
                        Curry._2(Lang$SolvuuApi.Term.eval_error, message, data)
                      ];
              } else {
                var groups = Curry._2(Import.List.map, groupNames[1], (function (name) {
                        return /* `Record */[
                                847309489,
                                Curry._1(Root$SolvuuApi.Line_chart.T.Group.to_record, name)
                              ];
                      }));
                var series$1 = Curry._2(Import.List.map, series[1], (function (param) {
                        return /* `Record */[
                                847309489,
                                Curry._2(Root$SolvuuApi.Line_chart.T.Series.to_record, param[0], param[1])
                              ];
                      }));
                return /* `Ok */[
                        17724,
                        /* `Viz */[
                          4300231,
                          /* `Record */[
                            847309489,
                            Curry._2(Lang$SolvuuApi.Record.tagged, f, /* `Record */[
                                  847309489,
                                  /* :: */[
                                    /* tuple */[
                                      Root$SolvuuApi.Line_chart.T.Const.Label.series,
                                      /* `List */[
                                        848054398,
                                        series$1
                                      ]
                                    ],
                                    /* :: */[
                                      /* tuple */[
                                        Root$SolvuuApi.Line_chart.T.Const.Label.groups,
                                        /* `List */[
                                          848054398,
                                          groups
                                        ]
                                      ],
                                      /* [] */0
                                    ]
                                  ]
                                ])
                          ]
                        ]
                      ];
              }
            }
          }));
    var match = Curry._1(Std$Bolt.Res.join, result);
    if (match[0] >= 106380200) {
      var message = Curry._1(Import.List.toArray, Curry._2(Import.List.map, match[1], (function (param) {
                    var label = Curry._1(Lang$SolvuuApi.Label.to_string, param[0]);
                    var reason = param[1] >= 144245463 ? "invalid" : "not_found";
                    return "(" + (label + (", " + (reason + ") ")));
                  }))).join(" ");
      return Promise.resolve(Curry._2(Lang$SolvuuApi.Term.eval_error, message, t));
    } else {
      return Promise.resolve(match[1]);
    }
  }
}

export {
  $$eval ,
  
}
/* Import Not a pure module */
