

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Import from "../features/import.bs.js";
import * as Std$Bolt from "bolt/./std.bs.js";
import * as Root$SolvuuApi from "solvuu-api/./root.bs.js";
import * as ViolinPlotJs from "./ViolinPlot.js";

function ofRecord(record) {
  return Curry._1(Std$Bolt.Res.join, Curry._2(Root$SolvuuApi.Violin_plot.T.of_record, record, (function (series) {
                    return Curry._2(Std$Bolt.Res.map, Curry._1(Std$Bolt.Res.all, Curry._2(Import.List.map, series, (function (record) {
                                          return Curry._1(Std$Bolt.Res.join, Curry._2(Root$SolvuuApi.Violin_plot.T.Series.of_record, record, (function (label, densities) {
                                                            return Curry._2(Std$Bolt.Res.map, Curry._1(Std$Bolt.Res.all, Curry._2(Import.List.map, densities, (function (record) {
                                                                                  return Curry._2(Root$SolvuuApi.Violin_plot.T.Density_point.of_record, record, (function (value, density) {
                                                                                                return {
                                                                                                        value: value,
                                                                                                        density: density
                                                                                                      };
                                                                                              }));
                                                                                }))), (function (densities) {
                                                                          return {
                                                                                  label: label,
                                                                                  densities: Curry._1(Import.List.toArray, densities)
                                                                                };
                                                                        }));
                                                          })));
                                        }))), (function (series) {
                                  return {
                                          series: Curry._1(Import.List.toArray, series)
                                        };
                                }));
                  })));
}

function ofTerm(term) {
  if (typeof term === "number" || term[0] !== 847309489) {
    return /* `Error */[
            106380200,
            /* Expected_record */-756637544
          ];
  } else {
    var res = ofRecord(term[1]);
    return Curry._2(Std$Bolt.Res.map_error, res, (function (e) {
                  return /* `Record */[
                          847309489,
                          e
                        ];
                }));
  }
}

var Props = {
  ofRecord: ofRecord,
  ofTerm: ofTerm
};

var make = ViolinPlotJs.default;

export {
  Props ,
  make ,
  
}
/* make Not a pure module */
