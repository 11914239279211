

import * as Err from "../../../components/Err.bs.js";
import * as Item from "./Item/Item.bs.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Route from "../../../Route.bs.js";
import * as React from "react";
import * as Import from "../../../features/import.bs.js";
import * as $$String from "bs-platform/lib/es6/string.js";
import * as Std$Bolt from "bolt/./std.bs.js";
import * as Pervasives from "bs-platform/lib/es6/pervasives.js";
import * as ReactIntl from "react-intl";
import * as StyleCss from "./style.css";
import * as ReactExtras from "../../../lib/solvuu/ReactExtras.bs.js";
import * as FullWidthCard from "../../Layout/AuthenticatedLayout/FullWidthCard.bs.js";
import * as Lang$SolvuuApi from "solvuu-api/./lang.bs.js";
import * as ReasonReactRouter from "reason-react/src/ReasonReactRouter.js";
import * as TermRenderContext from "../../../lang/TermRenderContext.bs.js";

var styles = StyleCss.default;

var iconArrowDown = (require("icons/icon-arrow-down-black.svg"));

var iconArrowUp = (require("icons/icon-arrow-up-black.svg"));

function Record$SortingIndicator(Props) {
  var direction = Props.direction;
  var src = direction ? iconArrowDown : iconArrowUp;
  return React.createElement("img", {
              alt: "",
              src: src
            });
}

var SortingIndicator = {
  make: Record$SortingIndicator
};

function Record(Props) {
  var apiClient = Props.apiClient;
  var record = Props.record;
  var hash = Props.hash;
  var renderTerm = Props.renderTerm;
  var url = ReasonReactRouter.useUrl(undefined, /* () */0);
  var projection = hash !== undefined ? atob(hash) : atob(url.hash);
  var projectionPath = Curry._2(Import.List.keep, Curry._1(Import.List.fromArray, projection.split("/")), (function (x) {
          return x !== "";
        }));
  var projectionPathLen = Curry._1(Import.List.length, projectionPath);
  var items = record[0] >= 847309489 ? Curry._2(Import.List.map, record[1], (function (param) {
            return /* tuple */[
                    param[0],
                    /* `Term */[
                      936571788,
                      param[1]
                    ]
                  ];
          })) : Curry._2(Import.List.map, Curry._3(Import.List.reduce, Curry._2(Import.List.sort, Curry._2(Import.List.keepMap, record[1], (function (param) {
                        var match = Curry._2(Import.List.splitAt, param[0], projectionPathLen);
                        if (match !== undefined) {
                          var match$1 = match;
                          var right = match$1[1];
                          if (Curry._1(Import.List.toArray, match$1[0]).join("/") === projection) {
                            var tmp;
                            if (right) {
                              var head = right[0];
                              tmp = /* tuple */[
                                head,
                                /* `Hash */[
                                  803298286,
                                  Pervasives.$at(projectionPath, /* :: */[
                                        head,
                                        /* [] */0
                                      ])
                                ]
                              ];
                            } else {
                              tmp = /* tuple */[
                                "",
                                /* `Term */[
                                  936571788,
                                  param[1]
                                ]
                              ];
                            }
                            return tmp;
                          } else {
                            return ;
                          }
                        }
                        
                      })), (function (param, param$1) {
                    return $$String.compare(param[0], param$1[0]);
                  })), /* [] */0, (function (acc, x) {
                if (Curry._3(Import.List.has, acc, x, (function (param, param$1) {
                          return $$String.equal(param[0], param$1[0]);
                        }))) {
                  return acc;
                } else {
                  return /* :: */[
                          x,
                          acc
                        ];
                }
              })), (function (param) {
            return /* tuple */[
                    /* `Named */[
                      699531161,
                      param[0]
                    ],
                    param[1]
                  ];
          }));
  console.log(Curry._1(Import.List.toArray, items));
  var itemsCount = Curry._1(Import.List.length, items);
  var summary = itemsCount !== 1 ? React.createElement(ReactIntl.FormattedMessage, {
          id: "entities.record.summary.many",
          values: {
            count: itemsCount
          }
        }) : React.createElement(ReactIntl.FormattedMessage, {
          id: "entities.record.summary.one"
        });
  var handleRootClick = function (param) {
    return /* () */0;
  };
  var handleTableClick = function ($$event) {
    $$event.stopPropagation();
    return /* () */0;
  };
  var handleSortClick = function (attribute, _event) {
    return /* () */0;
  };
  var match = Curry._2(Std$Bolt.List.find_opt, items, (function (param) {
          var route = param[1];
          if (typeof route === "number" || route[0] !== 936571788) {
            return ;
          } else {
            return route[1];
          }
        }));
  var tmp;
  if (match !== undefined) {
    tmp = Curry._6(renderTerm, apiClient, match, /* Type_not_available */-883458824, TermRenderContext.root, undefined, /* () */0);
  } else {
    var match$1 = "" === "name";
    tmp = React.createElement(React.Fragment, undefined, React.createElement("div", {
              className: styles.summary
            }, summary), React.createElement("table", {
              className: styles.table,
              onClick: handleTableClick
            }, React.createElement("thead", undefined, React.createElement("tr", {
                      className: styles.tableHeader
                    }, React.createElement("th", {
                          className: styles.tableColumn,
                          tabIndex: 0,
                          onKeyDown: (function (param) {
                              var attribute = "name";
                              var $$event = param;
                              var keyCode = $$event.keyCode;
                              if (keyCode === 32 || keyCode === 13) {
                                $$event.preventDefault();
                                return handleSortClick(attribute, $$event);
                              } else {
                                return 0;
                              }
                            }),
                          onClick: (function (param) {
                              return handleSortClick("name", param);
                            })
                        }, React.createElement(ReactIntl.FormattedMessage, {
                              id: "entities.record.list.name"
                            }), match$1 ? React.createElement(Record$SortingIndicator, {
                                direction: /* Ascending */0
                              }) : null), null)), React.createElement("tbody", undefined, ReactExtras.listToElement(Curry._2(Import.List.mapWithIndex, items, (function (idx, param) {
                            var route = param[1];
                            var label = param[0];
                            var variant = label[0];
                            var name;
                            if (variant !== 699531161) {
                              if (variant >= 948354667) {
                                name = "<User_id(" + (label[1] + ")");
                              } else if (route[0] >= 936571788) {
                                var term = route[1];
                                if (typeof term === "number") {
                                  name = "<Unknown(" + (String(idx) + ")>");
                                } else {
                                  var variant$1 = term[0];
                                  name = variant$1 !== -1057852867 ? (
                                      variant$1 !== -216449554 ? (
                                          variant$1 !== 295523485 ? "<Unknown(" + (String(idx) + ")>") : "<Blob_id(" + (term[1] + ")>")
                                        ) : "<Term_id(" + (term[1] + ")>")
                                    ) : "<Job_id(" + (term[1] + ")>");
                                }
                              } else {
                                var last = function (_l) {
                                  while(true) {
                                    var l = _l;
                                    if (l) {
                                      var xs = l[1];
                                      if (xs) {
                                        _l = xs;
                                        continue ;
                                      } else {
                                        return l[0];
                                      }
                                    } else {
                                      return "<Unknown(" + (String(idx) + ")>");
                                    }
                                  };
                                };
                                name = last(route[1]);
                              }
                            } else {
                              name = label[1];
                            }
                            var route$1;
                            if (route[0] >= 936571788) {
                              route$1 = /* PageNotFound */-442163778;
                            } else {
                              var hash = btoa(Curry._1(Import.List.toArray, route[1]).join("/"));
                              route$1 = Route.ofUrl({
                                    path: url.path,
                                    hash: hash,
                                    search: url.search
                                  });
                            }
                            return React.createElement(Item.make, {
                                        name: name,
                                        route: route$1,
                                        key: name
                                      });
                          }))))));
  }
  return ReactExtras.withTestId("entity-record", React.createElement("div", {
                  className: styles.root,
                  onClick: handleRootClick
                }, React.createElement(FullWidthCard.make, {
                      children: tmp,
                      expandVertically: true
                    })));
}

function Record$ParsedRecord(Props) {
  var children = Props.children;
  if (children[0] >= 106380200) {
    var __x = Curry._1(Import.List.toArray, Curry._2(Import.List.map, children[1], (function (param) {
                  return "Field " + (Curry._1(Lang$SolvuuApi.Label.to_string, param[0]) + ((
                              param[1] >= 144245463 ? " was invalid" : " was not found"
                            ) + "."));
                }))).join(" ");
    var message = Curry._1(Import.sprintf(/* Format */[
              /* String_literal */Block.__(11, [
                  "Argument error: ",
                  /* String */Block.__(2, [
                      /* No_padding */0,
                      /* End_of_format */0
                    ])
                ]),
              "Argument error: %s"
            ]), __x);
    return React.createElement(Err.Operation.make, {
                message: message
              });
  } else {
    return children[1];
  }
}

var ParsedRecord = {
  make: Record$ParsedRecord
};

function Record$OfRecord(Props) {
  var ofRecord = Props.ofRecord;
  var arg = Props.arg;
  var f = Props.f;
  return React.createElement(Record$ParsedRecord, {
              children: Curry._2(ofRecord, arg, f)
            });
}

var OfRecord = {
  make: Record$OfRecord
};

var make = Record;

export {
  styles ,
  iconArrowDown ,
  iconArrowUp ,
  SortingIndicator ,
  make ,
  ParsedRecord ,
  OfRecord ,
  
}
/* styles Not a pure module */
