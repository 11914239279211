import React from "react";

// Copied from https://github.com/escaladesports/react-twitter-share-link
export default class TwitterShareLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      link: props.link
    };
  }
  componentDidMount() {
    if (!this.state.link) {
      this.setState({
        link: document.location.href
      });
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.link !== prevProps.link) {
      this.setState({
        link: this.props.link
      });
    }
  }
  render() {
    return this.props.children(
      `http://twitter.com/share?url=${encodeURIComponent(this.state.link)}`
    );
  }
}
