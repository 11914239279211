/* eslint-disable react/style-prop-object */
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Cell,
  Legend,
  Pie,
  PieChart as RechartsPieChart,
  Tooltip as RechartsTooltip
} from "recharts";
import { scaleOrdinal } from "d3-scale";
import { range } from "ramda";
import { FormattedNumber } from "react-intl";

import ResponsiveContainer from "components/ResponsiveContainer";
import Tooltip, {
  TooltipGroup,
  TooltipTitle,
  TooltipValue
} from "containers/Entities/TaxonomicAnalysis/Biom/Tooltip";

import { categoricalSimple } from "lib/colors";

import styles from "table/TableDonutChart.css";

class DonutTooltip extends Component {
  render() {
    const {
      slices,
      colorScale,
      payload,
      viewBox,
      coordinate,
      maxRadius
    } = this.props;
    const hoveredSlice = payload[0];
    if (!hoveredSlice) return null;

    const hoveredRing = hoveredSlice.payload.ring;

    // Avoid covering of the donut by the tooltip.
    const center = viewBox.width / 2;
    const offsetFromCenter = coordinate.x - center;
    const left = maxRadius - offsetFromCenter + 10;
    const style = {
      transform: `translateX(${left}px)`,
      transition: "transform 400ms"
    };

    return (
      <div style={style}>
        <Tooltip>
          <TooltipTitle>{hoveredRing.name}</TooltipTitle>
          <TooltipGroup>
            {slices.map((slice, idx) => {
              const value = hoveredRing.data[idx];
              const relativeValue = value / hoveredRing.sum;
              const formattedName = (
                <span style={{ color: colorScale(idx) }}>{slice.name}</span>
              );
              const formattedRelative = (
                <FormattedNumber
                  style="percent"
                  minimuFractionDigits={2}
                  value={relativeValue}
                />
              );
              const className =
                hoveredSlice.name === slice.name
                  ? styles.tooltipSliceHovered
                  : undefined;

              return (
                <TooltipValue key={idx}>
                  <span className={className}>
                    {formattedName}: {value} ({formattedRelative})
                  </span>
                </TooltipValue>
              );
            })}
          </TooltipGroup>
        </Tooltip>
      </div>
    );
  }
}

class DonutChart extends Component {
  static propTypes = {
    rings: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        data: PropTypes.arrayOf(PropTypes.number).isRequired,
        sum: PropTypes.number.isRequired
      })
    ).isRequired,
    slices: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired
      })
    ).isRequired
  };

  render() {
    const { rings, slices } = this.props;
    const colorScale = scaleOrdinal()
      .domain(range(0, slices.length))
      .range(categoricalSimple);
    const rechartsPies = rings.map(ring => {
      return ring.data.map((value, idx) => ({
        name: slices[idx].name,
        value,
        ring
      }));
    });
    const rechartsLegendPayload = slices.map((slice, idx) => {
      return { value: slice.name, color: colorScale(idx), type: "rect" };
    });
    const maxRadius = 200;
    const minRadius = 100;
    const ringsCount = rings.length;
    const ringWidth = (maxRadius - minRadius) / ringsCount;

    // Calculate total chart height
    const donutAreaPadding = 50;
    const legendHeight = Math.ceil(slices.length / 10) * 20; // Approximate the legend height based on the number of items
    const height = 2 * maxRadius + legendHeight + donutAreaPadding;

    // Start drawing the pie at the top and proceed clockwise
    const startAngle = 90;
    const endAngle = startAngle - 360;

    return (
      <ResponsiveContainer minHeight={height}>
        <RechartsPieChart>
          {rechartsPies.map((data, idx) => {
            const outerRadius = maxRadius - idx * ringWidth;
            const innerRadius = outerRadius - ringWidth;
            return (
              <Pie
                key={idx}
                data={data}
                dataKey="value"
                outerRadius={outerRadius}
                innerRadius={innerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                isAnimationActive={false}
              >
                {data.map((value, idx) => (
                  <Cell key={idx} fill={colorScale(idx)} />
                ))}
              </Pie>
            );
          })}
          <RechartsTooltip
            content={
              <DonutTooltip
                slices={slices}
                colorScale={colorScale}
                maxRadius={maxRadius}
              />
            }
            offset={0}
          />
          <Legend payload={rechartsLegendPayload} />
        </RechartsPieChart>
      </ResponsiveContainer>
    );
  }
}

export default DonutChart;
