

import * as Term from "../../lang/Term.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Button from "../../components/Button.bs.js";
import * as Import from "../../features/import.bs.js";
import * as TermEval from "../../lib/solvuu/termEval.bs.js";
import * as ReactIntl from "react-intl";
import * as StyleCss from "./style.css";
import * as TermRenderContext from "../../lang/TermRenderContext.bs.js";
import * as ErrBoundaryJs from "components/ErrBoundary.js";

var styles = StyleCss.default;

var initialState = {
  query: "",
  result: undefined,
  submitting: false,
  error: undefined
};

var State = {
  initialState: initialState
};

function useEvaluate(apiClient) {
  var match = React.useState((function () {
          return initialState;
        }));
  var setState = match[1];
  var evaluateQuery = function (query) {
    Curry._1(setState, (function (state) {
            return {
                    query: state.query,
                    result: state.result,
                    submitting: true,
                    error: undefined
                  };
          }));
    Import.$great$great$pipe(TermEval.$$eval(apiClient)(/* `Solvuu */[
              -522825178,
              query
            ]), (function (term) {
            return Curry._1(setState, (function (state) {
                          return {
                                  query: state.query,
                                  result: /* tuple */[
                                    query,
                                    term
                                  ],
                                  submitting: false,
                                  error: state.error
                                };
                        }));
          }));
    return /* () */0;
  };
  var changeQuery = function (query) {
    return Curry._1(setState, (function (state) {
                  return {
                          query: query,
                          result: state.result,
                          submitting: state.submitting,
                          error: state.error
                        };
                }));
  };
  return /* tuple */[
          match[0],
          changeQuery,
          evaluateQuery
        ];
}

function SolvuuQuery(Props) {
  var apiClient = Props.apiClient;
  var match = useEvaluate(apiClient);
  var evaluateQuery = match[2];
  var changeQuery = match[1];
  var state = match[0];
  var formSubmitting = state.submitting;
  var formDisabled = formSubmitting || state.query.length === 0;
  var match$1 = state.result;
  var tmp;
  if (match$1 !== undefined) {
    var match$2 = match$1;
    tmp = React.createElement(ErrBoundaryJs.default, {
          children: React.createElement(Term.make, {
                term: match$2[1],
                type_: /* Type_not_available */-883458824,
                context: TermRenderContext.root,
                apiClient: apiClient,
                key: match$2[0]
              })
        });
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              className: styles.root
            }, React.createElement("form", {
                  disabled: formDisabled,
                  onSubmit: (function ($$event) {
                      $$event.preventDefault();
                      return Curry._1(evaluateQuery, state.query);
                    })
                }, React.createElement("div", {
                      className: styles.query
                    }, React.createElement(ReactIntl.FormattedMessage, {
                          id: "solvuuQuery.placeholder",
                          children: (function (placeholder) {
                              return React.createElement("input", {
                                          className: styles.queryInput,
                                          placeholder: placeholder,
                                          type: "text",
                                          value: state.query,
                                          onChange: (function ($$event) {
                                              var target = $$event.target;
                                              return Curry._1(changeQuery, target.value);
                                            })
                                        });
                            })
                        }), React.createElement("div", {
                          className: styles.queryBorder
                        }), React.createElement(Button.make, Button.makeProps(undefined, undefined, /* `Submit */[
                              -828715976,
                              Curry._5(Button.Type.Submit.make, formSubmitting, undefined, formDisabled, undefined, /* () */0)
                            ], React.createElement(ReactIntl.FormattedMessage, {
                                  id: "solvuuQuery.submit"
                                }), /* () */0)))), tmp);
}

var make = SolvuuQuery;

export {
  styles ,
  State ,
  useEvaluate ,
  make ,
  
}
/* styles Not a pure module */
