

import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as FormControlsJs from "./FormControls.js";
import * as Js_null_undefined from "bs-platform/lib/es6/js_null_undefined.js";

function makeProps(label, name, value, onChange, $staropt$star, children, param) {
  var disabled = $staropt$star !== undefined ? $staropt$star : false;
  return {
          label: label,
          name: name,
          value: Js_null_undefined.fromOption(value),
          onChange: onChange,
          disabled: disabled,
          validationState: null,
          children: children
        };
}

var make = FormControlsJs.Select;

var Select = {
  makeProps: makeProps,
  make: make
};

function makeProps$1(label, name, type_, value, onChange, disabled, required, autoFocus, $staropt$star, param) {
  var validationState = $staropt$star !== undefined ? Caml_option.valFromOption($staropt$star) : null;
  var tmp = {
    label: label,
    name: name,
    type: type_,
    value: value,
    onChange: onChange,
    validationState: validationState
  };
  if (disabled !== undefined) {
    tmp.disabled = Caml_option.valFromOption(disabled);
  }
  if (required !== undefined) {
    tmp.required = Caml_option.valFromOption(required);
  }
  if (autoFocus !== undefined) {
    tmp.autoFocus = Caml_option.valFromOption(autoFocus);
  }
  return tmp;
}

var make$1 = FormControlsJs.Input;

var Input = {
  makeProps: makeProps$1,
  make: make$1
};

function makeProps$2(label, name, checked, onChange, $staropt$star, param) {
  var disabled = $staropt$star !== undefined ? $staropt$star : false;
  return {
          label: label,
          name: name,
          checked: checked,
          onChange: onChange,
          disabled: disabled,
          validationState: null
        };
}

var make$2 = FormControlsJs.Checkbox;

var Checkbox = {
  makeProps: makeProps$2,
  make: make$2
};

export {
  Select ,
  Input ,
  Checkbox ,
  
}
/* make Not a pure module */
