

import * as Auth from "./features/Auth.bs.js";
import * as $$Intl from "./Intl.bs.js";
import * as Page from "./Page.bs.js";
import * as Route from "./Route.bs.js";
import * as React from "react";
import * as $$History from "./lib/History.bs.js";
import * as Profile from "./containers/Settings/Profile/Profile.bs.js";
import * as ReactHotLoader from "./lib/ReactHotLoader.bs.js";
import * as ReactHelmetAsync from "./lib/ReactHelmetAsync.bs.js";
import * as ReasonReactRouter from "reason-react/src/ReasonReactRouter.js";
import * as ErrBoundaryJs from "components/ErrBoundary.js";

var history = $$History.createBrowserHistory(undefined, undefined, undefined, undefined, /* () */0);

function make(param) {
  var match = Auth.useAuth(/* () */0);
  var match$1 = Profile.useProfile(/* () */0);
  var profile = match$1[0];
  React.useEffect((function () {
          typeof profile === "number";
          return ;
        }), /* array */[profile]);
  var route = Route.ofUrl(ReasonReactRouter.useUrl(undefined, /* () */0));
  return React.createElement(ReactHotLoader.AppContainer.make, {
              children: React.createElement($$Intl.make, {
                    children: React.createElement(ReactHelmetAsync.HelmetProvider.make, {
                          children: React.createElement(ErrBoundaryJs.default, {
                                children: React.createElement(Page.make, {
                                      route: route,
                                      logout: match[1],
                                      loginState: match[0],
                                      uploadInProgress: false,
                                      background: "dark",
                                      profile: profile,
                                      refreshProfile: match$1[1]
                                    })
                              })
                        })
                  })
            });
}

export {
  history ,
  make ,
  
}
/* history Not a pure module */
