import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  LineChart as RechartsLineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip
} from "recharts";
import { injectIntl } from "react-intl";
import sizeMe from "react-sizeme";
import { scaleLinear } from "d3-scale";
import { extent } from "d3-array";

import { primary } from "lib/colors";
import { compose } from "ramda";

import Brushing, { HorizontalBrush, getNiceDomain } from "components/Brushing";

class LineChart extends Component {
  static propTypes = {
    size: PropTypes.shape({
      width: PropTypes.number
    }).isRequired,
    points: PropTypes.arrayOf(
      PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired
      })
    ).isRequired,
    xAxisLabel: PropTypes.string.isRequired,
    intl: PropTypes.object.isRequired
  };

  render() {
    const { points, xAxisLabel, intl, size } = this.props;

    const width = size.width || 1000;
    const height = 300;

    const yAxisWidth = 80;
    const yAxisLabelWidth = 20;
    const yAxisLabel = intl.formatMessage({
      id: "table.kernelDensity.chart.yAxis"
    });

    const xAxisHeight = 40;

    const brushX = yAxisWidth + yAxisLabelWidth;
    const brushWidth = width - brushX - 7;
    const brushHeight = HorizontalBrush.defaultHeight;
    const brushY = height - brushHeight - 7;

    const margin = { left: yAxisLabelWidth, bottom: xAxisHeight + brushHeight };

    const fullXDomain = extent(points, p => p.x);
    const fullYDomain = getNiceDomain(extent(points, p => p.y));
    const brushScale = scaleLinear().domain(fullXDomain);

    // Top-level div element helps react-sizeme to measure the width
    return (
      <div>
        <Brushing domain={fullXDomain}>
          {({ selectedDomain, onChange, brushKey }) => {
            const displayedPoints = points.filter(
              p => selectedDomain[0] <= p.x && p.x <= selectedDomain[1]
            );
            const xDomain = extent(displayedPoints, p => p.x);

            return (
              <RechartsLineChart
                data={displayedPoints}
                margin={margin}
                width={width}
                height={height}
              >
                <XAxis
                  dataKey="x"
                  type="number"
                  label={{
                    value: xAxisLabel,
                    position: "bottom",
                    dy: -3
                  }}
                  domain={xDomain}
                  height={xAxisHeight}
                  tickCount={9}
                />
                <YAxis
                  dataKey="y"
                  type="number"
                  domain={fullYDomain}
                  label={{
                    value: yAxisLabel,
                    angle: -90,
                    position: "left",
                    dy: -20
                  }}
                  width={yAxisWidth}
                />
                <Tooltip />
                <Line
                  dataKey="y"
                  type="monotone"
                  stroke={primary}
                  strokeWidth={2}
                  isAnimationActive={false}
                  dot={false}
                />
                <g>
                  <HorizontalBrush
                    key={brushKey}
                    x={brushX}
                    y={brushY}
                    width={brushWidth}
                    height={brushHeight}
                    scale={brushScale}
                    onChange={onChange}
                  />
                </g>
              </RechartsLineChart>
            );
          }}
        </Brushing>
      </div>
    );
  }
}

const noPlaceholder = process.env.NODE_ENV === "test";
const refreshRate = 100;
const enhance = compose(
  injectIntl,
  sizeMe({ refreshRate, noPlaceholder })
);

export default enhance(LineChart);
