import { last, nth } from "ramda";
import invariant from "invariant";
import createUUID from "uuid/v4";

export const PATH_SEPARATOR = "/";

export function splitPathIntoComponents(path) {
  invariant(
    path.startsWith(PATH_SEPARATOR),
    "Paths need to start with %s, was %s",
    PATH_SEPARATOR,
    path
  );
  const names = path.split(PATH_SEPARATOR).slice(1);
  return names.map((name, idx) => ({
    name,
    path: PATH_SEPARATOR + names.slice(0, idx + 1).join(PATH_SEPARATOR)
  }));
}

export function lastEntityName(path) {
  return last(splitPathIntoComponents(path)).name;
}

/**
 * Returns the nth element of the given list or string.
 * If n is negative the element at index length + n is returned.
 * https://ramdajs.com/docs/#nth
 */
const getSecondFromLast = nth(-2);

export function getParentPath(path) {
  const components = splitPathIntoComponents(path);
  return getSecondFromLast(components).path;
}

/**
 * Evaluation paths are temporary client-side paths generated to identify results
 * of arbitrary term evaluations.
 * These paths are not persisted in Solvuu backend, unlike regular paths.
 */
const EVALUATION = "EVALUATION";

export function createEvaluationPath(seed = null) {
  return `/${EVALUATION}/${seed || createUUID()}`;
}

export function isEvaluationPath(path) {
  return splitPathIntoComponents(path)[0].name === EVALUATION;
}

/**
 * Job output paths are also not persisted on the backend.
 * These paths are meant to point to output files of solvuu jobs.
 */
export function isJobOutputPath(path) {
  const components = splitPathIntoComponents(path);
  return (
    components.length > 2 &&
    components[0].name === "jobs" &&
    components[2].name === "results"
  );
}

export function deconstructJobOutputPath(path) {
  if (!isJobOutputPath(path)) return null;
  const components = splitPathIntoComponents(path);
  const jobId = components[1].name;
  const basePath = components[2].path;
  return { jobId, basePath };
}

export function createJobOutputPath(jobId, basePath) {
  return `/jobs/${jobId}/results${basePath === "/" ? "" : basePath}`;
}

/**
 * Entity paths start with / (forward slash), like URLs
 * Hovewer, Solvuu endpoints expect absolute paths to start with @ in the root.
 */
export function pathToSolvuu(path) {
  return path.replace(/^\//, "@");
}
