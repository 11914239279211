import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { values } from "ramda";

import * as Card from "components/Card/Card.bs";
import * as DocumentTitle from "components/DocumentTitle.bs";
import ExitPrompt from "components/ExitPrompt";
import Form from "components/Form";
import { Select, Textarea } from "components/FormControls/FormControls.js";
import FormGroup from "components/FormGroup/FormGroup.js";
import {
  VerticalFormFields as FormFields,
  VerticalFormField as FormField,
  VerticalFormActions as FormActions
} from "components/FormLayout";
import * as Err from "components/Err.bs";
import PageHeader from "components/PageHeader";
import * as Button from "components/Button.bs";
import { creatableTypes } from "features/entities/create";
import * as PathUtils from "lib/solvuu/pathUtils.bs";

import * as BlobsForm from "app/BlobsFormRedux.bs";
import styles from "./style.css";

export const TYPE_OPTIONS = values(creatableTypes).map(value => ({
  value,
  labelId: `entityCreator.fields.types.${value}`
}));

class EntityTypeSelect extends Component {
  render() {
    const props = this.props;

    const pickableOptions = TYPE_OPTIONS.filter(
      ({ value }) => value !== creatableTypes.TABLE
    );

    return (
      <Select
        {...props}
        onChange={event => {
          props.onChange(event.target.value);
        }}
      >
        {pickableOptions.map(option => (
          <FormattedMessage id={option.labelId} key={option.value}>
            {label => <option value={option.value}>{label}</option>}
          </FormattedMessage>
        ))}
      </Select>
    );
  }
}

class LongTextarea extends Component {
  render() {
    const props = this.props;

    return (
      <Textarea
        {...props}
        value={props.value || ""}
        onChange={event => {
          props.onChange(event.target.value);
        }}
        rows={4}
      />
    );
  }
}

export default class EntityCreator extends Component {
  static propTypes = {
    onTeardown: PropTypes.func.isRequired,
    form: PropTypes.shape({}).isRequired,
    onChange: PropTypes.func.isRequired,
    meta: PropTypes.shape({
      create: PropTypes.shape({}).isRequired,
      inferenceInProgress: PropTypes.bool.isRequired
    }).isRequired
  };

  componentWillUnmount() {
    this.props.onTeardown();
  }

  onFieldChange = (field, value) => {
    const fieldPath = Array.isArray(field) ? field : field.split(".");
    this.props.onChange(fieldPath, value);
  };

  onFormSubmit = event => {
    event.preventDefault();
    const { root, createEntity } = this.props;
    return createEntity(root);
  };

  getFormContext() {
    const { form, meta } = this.props;

    return {
      form,
      onFieldChange: this.onFieldChange,
      errors: meta.create.errors || []
    };
  }

  render() {
    const {
      root,
      form,
      meta: { create, inferenceInProgress }
    } = this.props;
    const { type } = form;

    const showName = type !== creatableTypes.BLOBS;
    const showInput = type === creatableTypes.TERM;
    const showProject = type === creatableTypes.PROJECT;
    const showBlobs = type === creatableTypes.BLOBS;

    const hasUnsavedChanges = !create.success;
    const isSubmitting = create.pending;
    const isDisabled = inferenceInProgress;

    const entityName = PathUtils.lastEntityName(root);

    return (
      <div className={styles.root}>
        <DocumentTitle.make
          id="entityCreator.documentTitle"
          values={{ entityName }}
        />
        <FormattedMessage id="entityCreator.unsavedChangesWarning">
          {message => <ExitPrompt when={hasUnsavedChanges} message={message} />}
        </FormattedMessage>
        <PageHeader>
          <PageHeader.Breadcrumbs path={root} selected={false}>
            <span className={styles.headerBreadcrumb}>
              <FormattedMessage id="entityCreator.header" />
            </span>
          </PageHeader.Breadcrumbs>
        </PageHeader>
        <Card.make>
          <Form.Provider value={this.getFormContext()}>
            <form onSubmit={this.onFormSubmit}>
              <Err.Form.make />
              <FormGroup
                name="type"
                label={<FormattedMessage id="entityCreator.fields.type" />}
                required
                renderControl={props => <EntityTypeSelect {...props} />}
              />
              <hr />
              <FormFields>
                {showName && (
                  <FormField>
                    <FormGroup
                      name="name"
                      label={
                        <FormattedMessage id="entityCreator.fields.name" />
                      }
                      required
                      info={
                        <FormattedMessage id="entityCreator.fields.name.description" />
                      }
                    />
                  </FormField>
                )}
                {showInput && (
                  <FormField>
                    <FormGroup
                      name="value"
                      label={
                        <FormattedMessage id="entityCreator.fields.value" />
                      }
                      required
                    />
                  </FormField>
                )}
                {showProject && (
                  <>
                    <FormField>
                      <FormGroup
                        name="projectTitle"
                        label={
                          <FormattedMessage id="entityCreator.fields.project.title" />
                        }
                        required
                      />
                    </FormField>
                    <FormField>
                      <FormGroup
                        name="projectAbstract"
                        label={
                          <FormattedMessage id="entityCreator.fields.project.abstract" />
                        }
                        renderControl={props => <LongTextarea {...props} />}
                      />
                    </FormField>
                    <FormField>
                      <FormGroup
                        name="projectLead"
                        label={
                          <FormattedMessage id="entityCreator.fields.project.lead" />
                        }
                      />
                    </FormField>
                    <FormField>
                      <FormGroup
                        name="projectInformaticsContact"
                        label={
                          <FormattedMessage id="entityCreator.fields.project.informaticsContact" />
                        }
                      />
                    </FormField>
                    <FormField>
                      <FormGroup
                        name="projectDataHandoff"
                        label={
                          <FormattedMessage id="entityCreator.fields.project.dataHandoff" />
                        }
                      />
                    </FormField>
                    <FormField>
                      <FormGroup
                        name="projectAnalysisType"
                        label={
                          <FormattedMessage id="entityCreator.fields.project.analysisType" />
                        }
                      />
                    </FormField>
                    <FormField>
                      <FormGroup
                        name="projectPlatform"
                        label={
                          <FormattedMessage id="entityCreator.fields.project.platform" />
                        }
                      />
                    </FormField>
                  </>
                )}
                {showBlobs && <BlobsForm.make rootPath={root} />}
              </FormFields>
              <FormActions>
                <Button.make
                  type={Button.Type.submit({
                    isSubmitting: isSubmitting,
                    disabled: isDisabled
                  })}
                >
                  {showBlobs ? (
                    <FormattedMessage id="entityCreator.submitBlobs" />
                  ) : (
                    <FormattedMessage id="entityCreator.submit" />
                  )}
                </Button.make>
              </FormActions>
            </form>
          </Form.Provider>
        </Card.make>
      </div>
    );
  }
}
