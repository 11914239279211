/**
 * Module for actions related to biom screen (taxonomic analysis).
 *
 * * Changing tabs ("Taxon", "Quality Control" etc.)
 * * Keeping the state of chart's controls (chart type, current taxonomic level etc.)
 * * Providing aggregated data to charts
 *
 * Notes:
 *
 * The current tabs and chart controls state is stored / read from URL query string.
 */
import qs from "qs";
import { createSelector } from "reselect";
import createCachedSelector from "re-reselect";
import {
  add,
  addIndex,
  adjust,
  append,
  ascend,
  compose,
  descend,
  either,
  filter,
  flatten,
  fromPairs,
  intersection,
  keys,
  lensProp,
  map,
  mergeWith,
  mergeDeepLeft,
  over,
  pair,
  pick,
  prop,
  propEq,
  props,
  remove,
  sort,
  sortWith,
  sum,
  uniq,
  values
} from "ramda";

import * as TermUtils from "lib/solvuu/termUtils.bs";

import { pathByEntityTermSelector } from "features/entities";
import { locationSelector, pushLocation } from "features/routing";

import * as BiomAggregations from "./biomAggregations.bs";

// SELECTORS

const DEFAULT_TAXON_TAB = "bar";

export function taxonTabLocationSelector(state, tab) {
  const { pathname, query } = locationSelector(state);
  const taxonomy = tab !== DEFAULT_TAXON_TAB ? tab : undefined;
  const search = "?" + qs.stringify({ ...query, taxonomy });

  return { pathname, search };
}

function biomStateSelector(state) {
  const { query } = locationSelector(state);
  return query;
}

export function currentTaxonTabSelector(state) {
  const biomState = biomStateSelector(state);
  return biomState.taxonomy || DEFAULT_TAXON_TAB;
}

export function currentSampleIdSelector(state) {
  const biomState = biomStateSelector(state);
  return biomState.sampleId;
}

export function currentChartControlsSelector(state) {
  const biomState = biomStateSelector(state);
  const taxonomyLevel = parseInt(biomState.taxonomyLevel, 10) || 1;
  const barExpand = biomState.barExpand === "true";
  const barSortingCriteria = biomState.barSortingCriteria || [];

  return { ...biomState, taxonomyLevel, barExpand, barSortingCriteria };
}

// ACTIONS

export function setChartControls(controls) {
  return function(dispatch, getState) {
    const { query } = locationSelector(getState());
    const newQuery = { ...query, ...controls };
    return dispatch(pushLocation({ query: newQuery }));
  };
}

/**
 * Managing bar chart sample sorting criteria.
 *
 * There are 2 types of criteria:
 * * sample metadata - sorts samples according to the value of given attribute in sample metadata
 * * taxonomic abundance - sorts samples according to the percentage of given taxonomy in a sample (abundance)
 */
export const SORT_DESCENDING = "desc";
export const SORT_ASCENDING = "asc";
export const SAMPLE_METADATA_ATTRIBUTE = "metadata";
export const TAXONOMIC_ABUNDANCE_ATTRIBUTE = "taxonomy";
const scLens = lensProp("barSortingCriteria");

export function addSortingCriterion(attributeName) {
  return function(dispatch, getState) {
    const controls = currentChartControlsSelector(getState());
    const appendCriterion = append({
      attribute: attributeName,
      direction: SORT_ASCENDING
    });
    const updatedControls = over(scLens, appendCriterion, controls);
    return dispatch(setChartControls(updatedControls));
  };
}

export function removeSortingCriterion(idx) {
  return function(dispatch, getState) {
    const controls = currentChartControlsSelector(getState());
    const removeCriterion = remove(idx, 1);
    const updatedControls = over(scLens, removeCriterion, controls);
    return dispatch(setChartControls(updatedControls));
  };
}

export function changeSortingCriterion(idx, newCriterion) {
  return function(dispatch, getState) {
    const controls = currentChartControlsSelector(getState());
    const changeCriterion = adjust(mergeDeepLeft(newCriterion), idx);
    const updatedControls = over(scLens, changeCriterion, controls);
    return dispatch(setChartControls(updatedControls));
  };
}

export function navigateToSample(sampleId) {
  return function(dispatch, getState) {
    const { query } = locationSelector(getState());
    const newQuery = {
      ...query,
      tab: "taxonomic-analysis",
      taxonomy: "krona",
      sampleId
    };
    return dispatch(pushLocation({ query: newQuery }));
  };
}

// CHART DATA SELECTORS

const dataframeByTermSelector = createSelector(
  term => TermUtils.decodeBiomDataframe(term),
  dataframe => dataframe
);

/**
 * Selector for the Bar and Area charts.
 *
 * Supports grouping by taxonomy level and ordering samples based on defined criteria.
 */
export function taxonomyBySampleChartDataSelector(
  state,
  term,
  taxonomyLevel = 1,
  sortingCriteria = []
) {
  const dataframe = dataframeByTermSelector(term);
  const distributions = BiomAggregations.taxonomyDistributionBySample(
    dataframe,
    taxonomyLevel
  );
  const sampleIds = BiomAggregations.allSampleIds(dataframe);
  const sampleMetadata = BiomAggregations.allSampleMetadata(dataframe);
  const distributionsBySampleId = fromPairs(
    distributions.map(d => [d.sampleId, d])
  );

  // Calculate count distributions and totals
  const countsBySample = fromPairs(
    distributions.map(props(["sampleId", "countsSum"]))
  );
  const countsByTaxonomy = distributions
    .map(prop("countsByTaxonomy"))
    .reduce(mergeWith(add));
  const sumValues = compose(
    sum,
    values
  );
  const countsTotal = sumValues(countsBySample);
  console.assert(
    countsTotal === sumValues(countsByTaxonomy),
    "Calculations error - counts by sample and counts by taxonomy do not sum to the same value"
  );

  // Sort taxonomies by total counts
  const taxonomyCount = t => countsByTaxonomy[t];
  const taxonomies = sort(descend(taxonomyCount), keys(countsByTaxonomy));

  // Construct available sorting attributes
  const sampleMetadataAttributes = BiomAggregations.allSampleMetadataAttributes(
    dataframe
  ).map(attribute => ({
    ...attribute,
    type: SAMPLE_METADATA_ATTRIBUTE
  }));
  const taxonomicAbundanceAttributes = taxonomies.map(t => ({
    name: t,
    type: TAXONOMIC_ABUNDANCE_ATTRIBUTE
  }));
  const sortingAttributes = [
    ...sampleMetadataAttributes,
    ...taxonomicAbundanceAttributes
  ];

  // Sort samples according to sorting criteria
  const sampleMetadataCriteria = sortingCriteria.filter(criterion =>
    sampleMetadataAttributes.some(
      attribute => attribute.name === criterion.attribute
    )
  );
  const getMetadataValue = attribute => distribution =>
    sampleMetadata[distribution.sampleId][attribute];
  const getAbundanceValue = taxonomy => distribution =>
    getTaxonomyDistributionInSample(taxonomy, distribution.sampleId).relative;
  const comparators = sortingCriteria.map(criterion => {
    const { attribute, direction } = criterion;
    const getter = sampleMetadataCriteria.includes(criterion)
      ? getMetadataValue
      : getAbundanceValue;
    const getValue = getter(attribute);
    const directed = direction === SORT_DESCENDING ? descend : ascend;
    return directed(getValue);
  });
  const sortedDistributions = sortWith(comparators, distributions);
  const sortedSampleIds = sortedDistributions.map(prop("sampleId"));
  console.assert(
    sampleIds.length === intersection(sampleIds, sortedSampleIds).length,
    "Sorting error - sortedSampleIds does not contain all sample ids"
  );

  // Construct chart data
  const chartData = sortedDistributions.map(distribution => {
    // Relabel the axis ticks based on metadata values relevant to current sorting
    const labelSuffix = sampleMetadataCriteria
      .map(c => getMetadataValue(c.attribute)(distribution))
      .map(v => `[${v}]`)
      .join(" ");
    return {
      label: distribution.sampleId + " " + labelSuffix,
      sampleId: distribution.sampleId,
      ...distribution.countsByTaxonomy
    };
  });

  // Selectors for relative abundances and counts
  function getTaxonomyDistributionInDataset(taxonomy) {
    const value = countsByTaxonomy[taxonomy];
    const total = countsTotal;
    const relative = value / total;
    return { value, total, relative };
  }

  function getTaxonomyDistributionInSample(taxonomy, sampleId) {
    const distribution = distributionsBySampleId[sampleId];
    const value = distribution.countsByTaxonomy[taxonomy];
    const total = distribution.countsSum;
    const relative = value / total;
    return { value, total, relative };
  }

  function getTaxonomiesTotalCounts(selectedTaxonomies) {
    return sumValues(pick(selectedTaxonomies, countsByTaxonomy));
  }

  return {
    chartData,
    taxonomies,
    sortingAttributes,
    sampleIds: sortedSampleIds,
    getTaxonomyDistributionInDataset,
    getTaxonomyDistributionInSample,
    getTaxonomiesTotalCounts
  };
}

/**
 * Selector for the Bubble chart.
 */
export const taxonomyByTaxonChartDataSelector = createCachedSelector(
  (state, term, taxonomyLevel) => term,
  (state, entityPath, taxonomyLevel) => taxonomyLevel,
  taxonomyByTaxonChartDataSelectorImplementation
)(
  (state, term, taxonomyLevel) =>
    `${cacheKeySelector(state, term)}-${taxonomyLevel}`
);

function taxonomyByTaxonChartDataSelectorImplementation(
  term,
  taxonomyLevel = 1
) {
  const dataframe = dataframeByTermSelector(term);
  const distributions = BiomAggregations.taxonomyDistributionByTaxon(
    dataframe,
    taxonomyLevel
  );
  const sampleIds = BiomAggregations.allSampleIds(dataframe);
  const observationIds = BiomAggregations.allObservationIds(dataframe);

  const chartData = distributions.map(distribution => ({
    label: distribution.taxonomy,
    ...distribution
  }));

  const countsByTaxonomy = fromPairs(
    distributions.map(props(["taxonomy", "countsSum"]))
  );

  const countsBySample = distributions
    .map(prop("countsBySampleId"))
    .reduce(mergeWith(add));

  const sumValues = compose(
    sum,
    values
  );
  const countsTotal = sumValues(countsByTaxonomy);

  const taxonomies = keys(countsByTaxonomy);

  console.assert(
    countsTotal === sumValues(countsBySample),
    "Calculations error - counts by sample and counts by taxonomy do not sum to the same value"
  );

  return {
    chartData,
    sampleIds,
    observationIds,
    taxonomies,
    countsBySample,
    countsByTaxonomy,
    countsTotal
  };
}

/**
 * Selector for Krona chart - taxonomic hierarchy in a sample.
 */
export function taxonomyHierarchyForSampleIdChartDataSelector(
  state,
  term,
  sampleId
) {
  const dataframe = dataframeByTermSelector(term);
  const chartData = sampleId
    ? BiomAggregations.taxonomyHierarchyForSampleId(dataframe, sampleId)
    : null;
  const sampleIds = BiomAggregations.allSampleIds(dataframe);

  return {
    chartData,
    sampleIds
  };
}

/**
 * Selector for the Sankey diagram.
 *
 * Returns the taxonomic hierarchy distribution up to a defined taxonomyLevel
 * "flattened" (a list of nodes and a list of links between these nodes) rather than
 * a nested structure.
 *
 * @example
 * {
 *   chartData: {
 *     nodes: [
 *       { label: "n1", value: 10, id: 0 },
 *       { label: "n2", value: 5, id: 1 }
 *     ],
 *     links: [
 *       { source: 0, target: 1, value: 5 }
 *     ]
 *   }
 * }
 */
export const taxonomyHierarchyNodesAndLinksChartDataSelector = createCachedSelector(
  (state, term, taxonomyLevel) => term,
  (state, term, taxonomyLevel) => taxonomyLevel,
  taxonomyHierarchyNodesAndLinksChartDataSelectorImplementation
)(
  (state, term, taxonomyLevel) =>
    `${cacheKeySelector(state, term)}-${taxonomyLevel}`
);

function taxonomyHierarchyNodesAndLinksChartDataSelectorImplementation(
  term,
  taxonomyLevel
) {
  console.assert(
    taxonomyLevel > 1,
    "Need at least 2 taxonomy levels to calculate links"
  );

  const dataframe = dataframeByTermSelector(term);
  const fullHierarchy = BiomAggregations.taxonomyHierarchyDistribution(
    dataframe
  );

  const nodes = [];
  const links = [];

  // Depth-first traversal of the full hierarchy
  function drilldown(node, currentTaxonomyLevel = 1, parentNodeIndex = null) {
    if (currentTaxonomyLevel > taxonomyLevel) return;

    const nodeIndex = nodes.length;
    nodes[nodeIndex] = {
      label: node.label,
      value: node.value,
      id: nodeIndex
    };

    if (parentNodeIndex !== null) {
      links.push({
        source: parentNodeIndex,
        target: nodeIndex,
        value: node.value
      });
    }

    node.children.forEach(childNode => {
      drilldown(childNode, currentTaxonomyLevel + 1, nodeIndex);
    });
  }

  fullHierarchy.children.forEach(node => {
    drilldown(node);
  });

  const chartData = { nodes, links };
  return { chartData };
}

/**
 * Returns similar structure as taxonomyHierarchyNodesAndLinksChartDataSelector
 * but only includes nodes and links for given node ID
 * (that is, nodes which are connected to the given node by a link, and a given node itself)
 */
export function taxonomyHierarchyNodesAndLinksForNodeChartDataSelector(
  state,
  term,
  taxonomyLevel,
  nodeId
) {
  const rawData = taxonomyHierarchyNodesAndLinksChartDataSelector(
    state,
    term,
    taxonomyLevel
  );

  // Define transformations
  const isLinkWithNodeId = either(
    propEq("source", nodeId),
    propEq("target", nodeId)
  );
  const getLinksForNodeId = filter(isLinkWithNodeId);
  const getNodeIdsFromLinks = compose(
    uniq,
    flatten,
    map(props(["source", "target"]))
  );
  const createNodeIdMap = compose(
    fromPairs,
    addIndex(map)(pair)
  );

  // Perform transformations
  const selectedLinks = getLinksForNodeId(rawData.chartData.links);
  const visibleNodeIds = getNodeIdsFromLinks(selectedLinks);
  const nodeIdMap = createNodeIdMap(visibleNodeIds);

  // Assemble final nodes and links
  const visibleNodes = visibleNodeIds.map(i => rawData.chartData.nodes[i]);
  const visibleLinks = selectedLinks.map(l => ({
    value: l.value,
    target: nodeIdMap[l.target],
    source: nodeIdMap[l.source]
  }));

  return {
    ...rawData,
    chartData: {
      ...rawData.chartData,
      links: visibleLinks,
      nodes: visibleNodes
    }
  };
}

// Some BIOM files may not contain any taxonomic metadata at all...
export function hasTaxonomicMetadataSelector(state, term) {
  const dataframe = dataframeByTermSelector(term);
  return BiomAggregations.hasTaxonomicMetadata(dataframe);
}

// Internal

function cacheKeySelector(state, term) {
  const path = pathByEntityTermSelector(state, term);
  return path || "-";
}
