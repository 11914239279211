import React, { Component } from "react";
import PropTypes from "prop-types";

import * as DocumentTitle from "components/DocumentTitle.bs";
import * as Loading from "components/Loading.bs";
import * as Err from "components/Err.bs";
import PageHeader from "components/PageHeader";

import * as PathUtils from "lib/solvuu/pathUtils.bs";
import SolvuuPropTypes from "lib/solvuu/solvuuPropTypes";

import * as EntityActionsDropdown from "app/EntityActionsDropdownRedux.bs";
import * as EntityContents from "./EntityContents.bs";
import * as EntitySidebar from "app/EntitySidebarRedux.bs";
import EntitySidebarToggles from "./EntitySidebarToggles";

import styles from "./style.css";

class Entity extends Component {
  static propTypes = {
    entityPath: PropTypes.string.isRequired,
    fetchEntity: PropTypes.func.isRequired,
    entity: PropTypes.shape({
      type: SolvuuPropTypes.type.isRequired,
      name: PropTypes.string.isRequired
    }),
    meta: PropTypes.shape({
      fetch: PropTypes.shape({}).isRequired
    }).isRequired,
    resetSelection: PropTypes.func.isRequired
  };

  state = {
    openedSidebar: null
  };

  handleOpenedSidebarChange = openedSidebar => {
    this.setState({ openedSidebar });
  };

  handleOpenedSidebarClose = () => {
    this.setState({ openedSidebar: null });
  };

  handleBreadcrumbsSelection = () => {
    this.props.resetSelection(this.props.entityPath);
  };

  componentDidMount() {
    const { entityPath } = this.props;
    this.props.fetchEntity(entityPath);
  }

  renderDocumentTitle() {
    const { entityPath } = this.props;
    const entityName = PathUtils.lastEntityName(entityPath);

    return (
      <DocumentTitle.make id="entities.documentTitle" values={{ entityName }} />
    );
  }

  renderWithHeader(node) {
    const {
      entityPath,
      selectedEntityPath,
      entity,
      meta: { fetch, loaded }
    } = this.props;
    const { openedSidebar } = this.state;
    const sidebarEntityPath = selectedEntityPath || entityPath;

    // Only show loading status when refreshing data in the background
    const loadingStatusElement = loaded ? (
      <PageHeader.LoadingStatus operation={fetch} />
    ) : null;

    const sidebarTogglesElement = loaded ? (
      <EntitySidebarToggles
        term={entity.term}
        openedSidebar={openedSidebar}
        onChangeOpenedSidebar={this.handleOpenedSidebarChange}
      />
    ) : null;

    const sidebarElement =
      loaded && openedSidebar ? (
        <EntitySidebar.make
          key={sidebarEntityPath}
          path={sidebarEntityPath}
          sidebar={openedSidebar}
          onCloseSidebar={this.handleOpenedSidebarClose}
        />
      ) : null;

    const actionsDropdownElement = loaded ? (
      <PageHeader.Slot>
        <EntityActionsDropdown.make entityPath={entityPath} />
      </PageHeader.Slot>
    ) : null;

    return (
      <>
        {this.renderDocumentTitle()}
        <PageHeader>
          <PageHeader.Breadcrumbs
            path={entityPath}
            selected={!selectedEntityPath}
            onSelect={this.handleBreadcrumbsSelection}
          />
          {actionsDropdownElement}
          {loadingStatusElement}
          <PageHeader.FillSpace />
          {sidebarTogglesElement}
        </PageHeader>
        <div className={styles.main}>
          {sidebarElement}
          {node}
        </div>
      </>
    );
  }

  renderContents() {
    const { entity } = this.props;
    const contents = <EntityContents.make entity={entity} />;
    return this.renderWithHeader(contents);
  }

  render() {
    const {
      meta: { fetch, loaded }
    } = this.props;

    if (loaded) {
      return this.renderContents();
    }

    if (fetch.failure) {
      return this.renderWithHeader(
        <Err.Operation.make message={fetch.errors.message} />
      );
    }

    return this.renderWithHeader(
      <div className={styles.loading}>
        <Loading.make />
      </div>
    );
  }
}

export default Entity;
