

import * as Link from "../../Link.bs.js";
import * as Flags from "../../../flags.bs.js";
import * as Route from "../../../Route.bs.js";
import * as React from "react";
import * as Loading from "../../../components/Loading.bs.js";
import * as NavLink from "../../NavLink.bs.js";
import * as ExitPrompt from "../../../components/ExitPrompt/ExitPrompt.bs.js";
import * as WithTestId from "../../WithTestId.bs.js";
import * as ReactIntl from "react-intl";
import * as StyleCss from "./style.css";
import * as ReasonReactRouter from "reason-react/src/ReasonReactRouter.js";
import * as NavbarActionsDropdown from "./NavbarActionsDropdown/NavbarActionsDropdown.bs.js";
import * as ErrBoundaryJs from "components/ErrBoundary.js";

var logo = require("../logo-white-logomark.svg");

var iconBell = require("icons/icon-alert-white.svg");

var styles = StyleCss.default;

function renderNavigation(uploadInProgress) {
  return React.createElement("div", {
              className: styles.navigationWrapper
            }, Flags.enableAllSaasFeatures ? React.createElement(WithTestId.make, {
                    id: "navigation[jobs]",
                    children: React.createElement(NavLink.make, {
                          to: /* `Jobs */[
                            826169846,
                            /* Main */858743897
                          ],
                          className: styles.navigationItem,
                          activeClassName: styles.navigationItemActive,
                          children: React.createElement(ReactIntl.FormattedMessage, {
                                id: "navigation.jobs"
                              })
                        })
                  }) : null, Flags.enableAllSaasFeatures ? React.createElement(WithTestId.make, {
                    id: "navigation[upload]",
                    children: React.createElement(NavLink.make, {
                          to: /* `Drive */[
                            -219555606,
                            /* Main */858743897
                          ],
                          className: styles.navigationItem,
                          activeClassName: styles.navigationItemActive,
                          children: uploadInProgress ? React.createElement("span", undefined, React.createElement(ReactIntl.FormattedMessage, {
                                      id: "navigation.upload.uploading"
                                    }), React.createElement(Loading.Dots.make, { })) : React.createElement(ReactIntl.FormattedMessage, {
                                  id: "navigation.upload"
                                })
                        })
                  }) : null, React.createElement(WithTestId.make, {
                  id: "navigation[charts]",
                  children: React.createElement(NavLink.make, {
                        to: /* Charts */-334882315,
                        className: styles.navigationItem,
                        activeClassName: styles.navigationItemActive,
                        children: React.createElement(ReactIntl.FormattedMessage, {
                              id: "navigation.charts"
                            })
                      })
                }));
}

function AuthenticatedLayout(Props) {
  var profile = Props.profile;
  var uploadInProgress = Props.uploadInProgress;
  var logout = Props.logout;
  var background = Props.background;
  var children = Props.children;
  var resetKey = Route.toUrlString(ReasonReactRouter.useUrl(undefined, /* () */0));
  return React.createElement("div", {
              className: styles.root
            }, React.createElement("section", {
                  className: styles.navbar
                }, React.createElement(ReactIntl.FormattedMessage, {
                      id: "navigation.dashboard",
                      children: (function (title) {
                          return React.createElement(NavLink.make, {
                                      to: /* Home */803993151,
                                      className: styles.rootNavigationItem,
                                      activeClassName: styles.rootNavigationItemActive,
                                      children: React.createElement("img", {
                                            className: styles.logo,
                                            title: title,
                                            alt: title,
                                            src: logo
                                          })
                                    });
                        })
                    }), renderNavigation(uploadInProgress), React.createElement("div", {
                      className: styles.navbarSpace
                    }), Flags.enableAllSaasFeatures ? React.createElement("div", {
                        className: styles.activityWrapper
                      }, React.createElement(Link.make, {
                            to: /* Home */803993151,
                            children: null
                          }, null, React.createElement("img", {
                                className: styles.activityBell,
                                alt: "Activity",
                                src: iconBell
                              }))) : null, React.createElement("div", {
                      className: styles.navbarActionsWrapper
                    }, React.createElement(NavbarActionsDropdown.make, {
                          profile: profile,
                          logout: logout,
                          background: background
                        }))), React.createElement("section", {
                  className: styles.main
                }, React.createElement("div", {
                      className: styles.mainInner
                    }, React.createElement(ErrBoundaryJs.default, {
                          children: children,
                          resetKey: resetKey
                        }))), React.createElement("footer", {
                  className: styles.footer
                }, React.createElement("div", {
                      className: styles.footerLeft
                    }, React.createElement(ReactIntl.FormattedMessage, {
                          id: "footer.copyright"
                        })), React.createElement("div", {
                      className: styles.footerCenter
                    }, React.createElement("ul", {
                          className: styles.footerLinks
                        }, React.createElement("li", {
                              className: styles.footerLink
                            }, React.createElement(Link.make, {
                                  to: /* Company */-793101027,
                                  children: "Company"
                                })), React.createElement("li", {
                              className: styles.footerLink
                            }, React.createElement(Link.make, {
                                  to: /* `Science */[
                                    -923566716,
                                    /* Main */858743897
                                  ],
                                  children: "Science"
                                })), React.createElement("li", {
                              className: styles.footerLink
                            }, React.createElement(Link.make, {
                                  to: /* `Blog */[
                                    737307010,
                                    /* Main */858743897
                                  ],
                                  children: "Blog"
                                })), React.createElement("li", {
                              className: styles.footerLink
                            }, React.createElement(Link.make, {
                                  to: /* TermsOfUse */975642249,
                                  children: "Terms of Use"
                                })), React.createElement("li", {
                              className: styles.footerLink
                            }, React.createElement(Link.make, {
                                  to: /* PrivacyPolicy */-229792710,
                                  children: "Privacy"
                                })))), React.createElement("div", {
                      className: styles.footerRight
                    })), uploadInProgress ? React.createElement(ExitPrompt.make, {
                    when_: /* OnUnload */518738846
                  }) : null);
}

var make = AuthenticatedLayout;

export {
  logo ,
  iconBell ,
  styles ,
  renderNavigation ,
  make ,
  
}
/* logo Not a pure module */
