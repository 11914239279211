

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Import from "../../../features/import.bs.js";
import * as ReactIntl from "react-intl";
import * as StyleCss from "./style.css";
import * as ReactExtras from "../../../lib/solvuu/ReactExtras.bs.js";

var styles = StyleCss.default;

function Command$Code(Props) {
  var children = Props.children;
  return React.createElement("code", {
              className: styles.code
            }, children);
}

var Code = {
  make: Command$Code
};

function reducer(state, action) {
  if (state) {
    return /* Formatted */0;
  } else {
    return /* Raw */1;
  }
}

function Command(Props) {
  var spec = Props.spec;
  var match = React.useReducer(reducer, /* Formatted */0);
  var send = match[1];
  var state = match[0];
  var handleToggle = function (param) {
    return Curry._1(send, /* Toggle */0);
  };
  var prefix = React.createElement("span", {
        className: styles.commandPrefix
      }, "$");
  var scriptToSubcommands = function (script) {
    var __x = Curry._1(Import.List.fromArray, script.split((/\s+&&\s+/)));
    return Curry._2(Import.List.keepMap, __x, (function (x) {
                  return x;
                }));
  };
  var formattedCommand;
  var exit = 0;
  if (state) {
    var match$1 = spec[1];
    if (typeof match$1 === "number" || match$1[0] !== -67845589) {
      exit = 1;
    } else {
      formattedCommand = React.createElement("div", {
            className: styles.inlineList
          }, prefix, React.createElement(Command$Code, {
                children: match$1[1]
              }));
    }
  } else {
    var match$2 = spec[1];
    if (typeof match$2 === "number" || match$2[0] !== -67845589) {
      exit = 1;
    } else {
      var subcommands = scriptToSubcommands(match$2[1]);
      formattedCommand = React.createElement("ul", {
            className: styles.commandList
          }, ReactExtras.mapListToElement((function (index, subcommand) {
                  return React.createElement("li", {
                              key: String(index)
                            }, prefix, React.createElement(Command$Code, {
                                  children: subcommand
                                }));
                }), subcommands));
    }
  }
  if (exit === 1) {
    var match$3 = spec[1][1];
    formattedCommand = React.createElement("div", {
          className: styles.inlineList
        }, prefix, ReactExtras.mapListToElement((function (index, component) {
                return React.createElement(Command$Code, {
                            children: component,
                            key: String(index)
                          });
              }), /* :: */[
              match$3.name,
              match$3.args
            ]));
  }
  var match$4 = spec[1];
  var button = match$4[0] >= 771170225 || Curry._1(Import.List.length, scriptToSubcommands(match$4[1])) <= 1 ? null : ReactExtras.withTestId("command-formatted-toggle", React.createElement("button", {
              className: styles.button,
              type: "button",
              onClick: handleToggle
            }, state ? React.createElement(ReactIntl.FormattedMessage, {
                    id: "jobs.command.actions.showFormatted"
                  }) : React.createElement(ReactIntl.FormattedMessage, {
                    id: "jobs.command.actions.showRaw"
                  })));
  return React.createElement("div", {
              className: styles.root
            }, formattedCommand, button);
}

var initialState = /* Formatted */0;

var make = Command;

export {
  styles ,
  Code ,
  initialState ,
  reducer ,
  make ,
  
}
/* styles Not a pure module */
