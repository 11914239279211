

import * as Root from "../Root.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as ReactExtras from "../lib/solvuu/ReactExtras.bs.js";
import * as HorizontalLayout from "../components/Layout/HorizontalLayout.bs.js";
import * as Layout from "components/Layout";
import * as TableVisualizationCardCss from "./TableVisualizationCard.css";

var styles = TableVisualizationCardCss.default;

var testIds = {
  buttonClose: "table-visualization-card-close",
  buttonExpand: "table-visualization-card-expand",
  buttonCollapse: "table-visualization-card-collapse",
  buttonControls: "table-visualization-card-toggle-controls",
  content: "table-visualization-card-content",
  controls: "table-visualization-card-controls"
};

function TableVisualizationCard(Props) {
  var icon = Props.icon;
  var title = Props.title;
  var controls = Props.controls;
  var onClose = Props.onClose;
  var children = Props.children;
  var match = React.useReducer((function (state, action) {
          if (action) {
            if (state.controlsState) {
              return {
                      contentState: state.contentState,
                      controlsState: /* Visible */0
                    };
            } else {
              return {
                      contentState: state.contentState,
                      controlsState: /* Hidden */1
                    };
            }
          } else if (state.contentState) {
            return {
                    contentState: /* Expanded */0,
                    controlsState: state.controlsState
                  };
          } else {
            return {
                    contentState: /* Collapsed */1,
                    controlsState: state.controlsState
                  };
          }
        }), {
        contentState: /* Expanded */0,
        controlsState: /* Visible */0
      });
  var state = match[0];
  var controlsState = state.controlsState;
  var contentState = state.contentState;
  var send = match[1];
  var handleCloseClick = function (param) {
    return Curry._1(onClose, /* () */0);
  };
  var closeButton = ReactExtras.withTestId(testIds.buttonClose, React.createElement("button", {
            className: styles.buttonClose,
            type: "button",
            onClick: handleCloseClick
          }, React.createElement("div", {
                className: styles.buttonIcon
              }), React.createElement(ReactIntl.FormattedMessage, {
                id: "table.visualization.actions.close"
              })));
  var handleToggleClick = function (param) {
    return Curry._1(send, /* ToggleContent */0);
  };
  var className = contentState ? styles.buttonExpand : styles.buttonCollapse;
  var message = contentState ? React.createElement(ReactIntl.FormattedMessage, {
          id: "table.visualization.actions.expand"
        }) : React.createElement(ReactIntl.FormattedMessage, {
          id: "table.visualization.actions.collapse"
        });
  var testId = contentState ? testIds.buttonExpand : testIds.buttonCollapse;
  var contentToggleButton = ReactExtras.withTestId(testId, React.createElement("button", {
            className: className,
            type: "button",
            onClick: handleToggleClick
          }, React.createElement("div", {
                className: styles.buttonIcon
              }), message));
  var handleToggleClick$1 = function (param) {
    return Curry._1(send, /* ToggleControls */1);
  };
  var message$1 = controlsState ? React.createElement(ReactIntl.FormattedMessage, {
          id: "table.visualization.actions.showControls"
        }) : React.createElement(ReactIntl.FormattedMessage, {
          id: "table.visualization.actions.hideControls"
        });
  var controlsToggleButton = contentState || controls === undefined ? undefined : Caml_option.some(ReactExtras.withTestId(testIds.buttonControls, React.createElement("button", {
                  className: styles.buttonControls,
                  type: "button",
                  onClick: handleToggleClick$1
                }, React.createElement("div", {
                      className: styles.buttonIcon
                    }), message$1)));
  var header = React.createElement("div", {
        className: styles.header
      }, React.createElement("div", {
            className: styles.visualizationIcon
          }, React.createElement(Root.Icon.make, {
                icon: icon
              })), React.createElement("div", {
            className: styles.title
          }, title), React.createElement(Layout.HorizontalLayout, HorizontalLayout.makeProps(/* x3 */26811, null, /* () */0), controlsToggleButton !== undefined ? React.createElement(Layout.HorizontalLayoutItem, {
                  children: Caml_option.valFromOption(controlsToggleButton)
                }) : null, React.createElement(Layout.HorizontalLayoutItem, {
                children: contentToggleButton
              }), React.createElement(Layout.HorizontalLayoutItem, {
                children: closeButton
              })));
  var className$1 = contentState ? styles.contentCollapsed : styles.content;
  var controlsElement;
  if (controls !== undefined && !controlsState) {
    var handleCloseClick$1 = function (param) {
      return Curry._1(send, /* ToggleControls */1);
    };
    controlsElement = ReactExtras.withTestId(testIds.controls, React.createElement("div", {
              className: styles.controls
            }, React.createElement("div", {
                  className: styles.controlsHeader
                }, React.createElement(ReactIntl.FormattedMessage, {
                      id: "table.visualization.controls.header"
                    }), React.createElement("button", {
                      className: styles.controlsHeaderButton,
                      type: "button",
                      onClick: handleCloseClick$1
                    })), React.createElement("div", undefined, Caml_option.valFromOption(controls))));
  } else {
    controlsElement = null;
  }
  var className$2 = controls !== undefined ? styles.visualizationWithControls : styles.visualization;
  var visualizationElement = React.createElement("div", {
        className: className$2
      }, children);
  var content = ReactExtras.withTestId(testIds.content, React.createElement("div", {
            className: className$1
          }, visualizationElement, controlsElement));
  return React.createElement("div", {
              className: styles.root
            }, header, content);
}

var make = TableVisualizationCard;

export {
  styles ,
  testIds ,
  make ,
  
}
/* styles Not a pure module */
