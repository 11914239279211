


function _read(file) {
  return new Promise((function (resolve, param) {
                var reader = new FileReader();
                reader.onload = (function ($$event) {
                    return resolve($$event.target.result);
                  });
                reader.readAsText(file);
                return /* () */0;
              }));
}

var $$String = {
  _read: _read
};

function read(file) {
  return new Promise((function (resolve, reject) {
                var reader = new FileReader();
                reader.onload = (function ($$event) {
                    return resolve($$event.target.result);
                  });
                reader.onerror = (function (error) {
                    return reject(error);
                  });
                reader.readAsArrayBuffer(file);
                return /* () */0;
              }));
}

var $$ArrayBuffer = {
  read: read
};

export {
  $$String ,
  $$ArrayBuffer ,
  
}
/* No side effect */
