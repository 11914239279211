

import * as Err from "../components/Err.bs.js";
import * as Op2 from "../features/Op2.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Import from "../features/import.bs.js";
import * as Loading from "../components/Loading.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Term$SolvuuApi from "solvuu-api/./term.bs.js";
import * as TermEventsCss from "./TermEvents.css";
import * as FormattedRelative from "../components/FormattedRelative/FormattedRelative.bs.js";
import * as Time$SolvuuClient from "solvuu-client/./time.bs.js";

var styles = TermEventsCss.default;

function joinList(x) {
  return Curry._1(Import.List.toArray, x).join(", ");
}

function eventInfoToHuman(info) {
  var variant = info[0];
  if (variant !== 490984684) {
    if (variant >= 816044828) {
      if (variant >= 999946793) {
        return /* tuple */[
                "Updated",
                undefined
              ];
      } else {
        return /* tuple */[
                "Created",
                undefined
              ];
      }
    } else if (variant >= 771069884) {
      var payload = info[1];
      var id = payload[0] >= 926225260 ? payload[1][1] : payload[1];
      return /* tuple */[
              "Evaluated",
              id
            ];
    } else {
      var items = joinList(Curry._2(Import.List.map, info[1], (function (param) {
                  return param[0];
                })));
      return /* tuple */[
              "Added item",
              items
            ];
    }
  } else {
    var items$1 = joinList(info[1]);
    return /* tuple */[
            "Removed item",
            items$1
          ];
  }
}

function TermEvents$Readable(Props) {
  var info = Props.info;
  var match = eventInfoToHuman(info);
  var argument = match[1];
  var argumentElement = argument !== undefined ? React.createElement(React.Fragment, undefined, React.createElement("span", undefined, ": "), React.createElement("strong", undefined, argument)) : null;
  return React.createElement(React.Fragment, undefined, React.createElement("strong", undefined, match[0]), argumentElement);
}

function TermEvents$Info(Props) {
  Props.id;
  var timestamp = Props.timestamp;
  var user = Props.user;
  var info = Props.info;
  var match = React.useState((function () {
          return false;
        }));
  var setRaw = match[1];
  var user$1 = typeof user === "number" ? "System" : user[1].username;
  return React.createElement("div", {
              className: styles.event
            }, React.createElement("div", {
                  className: styles.eventTimestamp
                }, React.createElement(FormattedRelative.make, {
                      value: Time$SolvuuClient.of_span_since_epoch(Curry._1(Time$SolvuuClient.Span.of_ms, timestamp * 1000))
                    })), React.createElement("div", {
                  className: styles.eventContent,
                  onClick: (function (param) {
                      return Curry._1(setRaw, (function (x) {
                                    return !x;
                                  }));
                    })
                }, match[0] ? React.createElement("div", undefined, React.createElement("code", undefined, JSON.stringify(Curry._1(Term$SolvuuApi.$$Event.json_of_info, info)))) : React.createElement(TermEvents$Readable, {
                        info: info
                      }), React.createElement("span", undefined, "by " + user$1)));
}

function TermEvents(Props) {
  var id = Props.id;
  var apiClient = Props.apiClient;
  var match = Op2.useOp(undefined, id, (function (id) {
          return Curry._3(apiClient.f, /* GET */3546230, Term$SolvuuApi.Get_events, {
                      id: id
                    });
        }), /* () */0);
  var run = match.run;
  React.useEffect((function () {
          Curry._1(run, /* () */0);
          return ;
        }), /* array */[id]);
  var match$1 = Op2.State.status(match.state);
  var tmp;
  if (typeof match$1 === "number") {
    tmp = React.createElement(Loading.make, { });
  } else if (match$1[0] >= -783600662) {
    tmp = React.createElement(Loading.make, { });
  } else {
    var match$2 = match$1[1];
    if (typeof match$2 === "number") {
      tmp = React.createElement(Err.Operation.make, {
            message: "Unauthorized"
          });
    } else {
      var match$3 = match$2[1].info;
      var variant = match$3[0];
      tmp = variant !== -99004555 ? (
          variant >= 17692 ? React.createElement("div", {
                  className: styles.list
                }, Belt_Array.map(Curry._1(Import.List.toArray, match$3[1]), (function (param) {
                        return React.createElement(TermEvents$Info, {
                                    id: param.id,
                                    timestamp: param.timestamp,
                                    user: param.user,
                                    info: param.info
                                  });
                      }))) : React.createElement(Err.Operation.make, {
                  message: "Not found"
                })
        ) : React.createElement(Err.Operation.make, {
              message: "Bad request"
            });
    }
  }
  return React.createElement("div", {
              className: styles.root
            }, tmp);
}

var make = TermEvents;

export {
  make ,
  
}
/* styles Not a pure module */
