import S3 from "aws-sdk/clients/s3";

import * as SolvuuApiWrapper from "lib/solvuu/solvuuApiWrapper.bs";

const MB = 1024 * 1024;
/**
 * This class manages upload of arbitrarily sized files directly to AWS S3.
 */
export default class S3BlobUpload {
  /* @constructor
   * @param {String} id - (optional) the destination blob ID
   * @param {File} file - the file to be uploaded
   * @param {SolvuuApiWrapper instance} solvuuApi - API to perform the requests
   */
  constructor(id, file, solvuuApi) {
    this.id = id;
    this.file = file;
    this.solvuuApi = solvuuApi;
  }

  send = async ({ onStart, onProgress, onSuccess, onFailure }) => {
    const uploadRequest = await this.solvuuApi.requestBlobUpload(this.id);

    if (!uploadRequest.ok) {
      onFailure(uploadRequest.data || true);
      return;
    }

    const uploadDetails = SolvuuApiWrapper.Js.blobUploadRequestToDetails(
      uploadRequest
    );
    const blobId = uploadDetails.blobId;
    const Bucket = uploadDetails.bucket;
    const Key = uploadDetails.key;
    const UploadId = uploadDetails.uploadId;
    const Body = this.file;
    const {
      accessKeyId,
      secretAccessKey,
      sessionToken,
      region
    } = uploadDetails.credentials;

    const partSize = 5 * MB;
    const s3 = new S3({
      accessKeyId,
      secretAccessKey,
      sessionToken,
      region,
      maxRetries: 10,
      s3DisableBodySigning: false,
      params: { UploadId } // Use the server-provided upload id for multi-part upload if available
    });

    const onUploadProgress = event => {
      onProgress(event);
    };
    const s3Callback = (resolve, reject) => (err, data) => {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    };
    const upload = s3.upload(
      { Bucket, Key, Body },
      { partSize },
      s3Callback(onSuccess, onFailure)
    );
    upload.on("httpUploadProgress", onUploadProgress);
    onStart({ blobId });
  };
}
