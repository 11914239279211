

import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Int64 from "bs-platform/lib/es6/int64.js";
import * as Import from "../../import.bs.js";
import * as Pervasives from "bs-platform/lib/es6/pervasives.js";
import * as Caml_format from "bs-platform/lib/es6/caml_format.js";

function validateJobForm(t) {
  var match = Pervasives.float_of_string_opt(t.resources.cpu);
  if (match !== undefined) {
    return /* `Ok */[
            17724,
            {
              commandValue: t.commandValue,
              image: t.image,
              resources: {
                mem: t.resources.mem,
                disk: t.resources.disk,
                cpu: match
              }
            }
          ];
  } else {
    return /* `Error */[
            106380200,
            /* :: */[
              /* tuple */[
                "cpu",
                /* Invalid */144245463
              ],
              /* [] */0
            ]
          ];
  }
}

function fromSpec(spec) {
  var match = spec[1];
  var resources = match.resources;
  var match$1 = match.command.spec;
  var commandValue;
  if (match$1[0] >= 771170225) {
    var match$2 = match$1[1];
    commandValue = Curry._1(Import.List.toArray, /* :: */[
            match$2.name,
            match$2.args
          ]).join(" ");
  } else {
    commandValue = match$1[1];
  }
  var match$3 = match.container.image;
  var match$4 = match$3[1];
  var image = Curry._2(Import.sprintf(/* Format */[
            /* String */Block.__(2, [
                /* No_padding */0,
                /* Char_literal */Block.__(12, [
                    /* "/" */47,
                    /* String */Block.__(2, [
                        /* No_padding */0,
                        /* End_of_format */0
                      ])
                  ])
              ]),
            "%s/%s"
          ]), match$4.registry, match$4.name);
  var mem = Int64.to_string(resources.mem);
  var disk = Int64.to_string(resources.disk);
  var cpu = resources.cpu.toString();
  return {
          commandValue: commandValue,
          image: image,
          resources: {
            mem: mem,
            disk: disk,
            cpu: cpu
          }
        };
}

function toSolvuuSpec(form) {
  return {
          resources: {
            cpu: form.resources.cpu,
            mem: Caml_format.caml_int64_of_string(form.resources.mem),
            disk: Caml_format.caml_int64_of_string(form.resources.disk)
          },
          command: {
            spec: /* `Script */[
              -67845589,
              form.commandValue
            ],
            env: /* [] */0,
            prefetches: /* [] */0
          },
          container: {
            image: /* `Docker */[
              -612329920,
              {
                name: form.image,
                registry: "index.docker.io"
              }
            ],
            cached: false
          }
        };
}

var Action = { };

function reducer(state, action) {
  if (action[0] >= 815032112) {
    return action[1].form;
  } else {
    var match = action[1];
    var x = state;
    var field = match.field;
    var value = match.value;
    var match$1 = field === "commandValue";
    var commandValue = match$1 ? value : x.commandValue;
    var match$2 = field === "image";
    var image = match$2 ? value : x.image;
    var match$3 = field === "resources.mem";
    var mem = match$3 ? value : x.resources.mem;
    var match$4 = field === "resources.disk";
    var disk = match$4 ? value : x.resources.disk;
    var match$5 = field === "resources.cpu";
    var cpu = match$5 ? value : x.resources.cpu;
    var resources = {
      mem: mem,
      disk: disk,
      cpu: cpu
    };
    return {
            commandValue: commandValue,
            image: image,
            resources: resources
          };
  }
}

var initialState = {
  commandValue: "",
  image: "solvuu/solvuu-server:dev",
  resources: {
    mem: "524288000",
    disk: "209715200",
    cpu: "0.1"
  }
};

export {
  initialState ,
  fromSpec ,
  toSolvuuSpec ,
  Action ,
  validateJobForm ,
  reducer ,
  
}
/* Import Not a pure module */
