/* eslint-disable react/style-prop-object */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { pack, hierarchy } from "d3-hierarchy";
import sizeMe from "react-sizeme";
import memoize from "memoize-one";
import { descend, prop, sort, sortBy, clamp, keys, head, last } from "ramda";

import { categorical as COLORS } from "lib/colors";

import Tooltip, { TooltipValue, TooltipValueDetail } from "../Tooltip";

import styles from "./style.css";

export const LAYOUT_PACK = "pack";
export const LAYOUT_LIST = "list";

class SVGCircle extends Component {
  render() {
    const { padding = 0, r, ...rest } = this.props;

    const diameter = r * 2;
    const width = diameter + padding * 2;
    const height = diameter + padding * 2;
    const viewBox = `0 0 ${width} ${height}`;
    const cx = r + padding;
    const cy = cx;

    return (
      <svg width={diameter} height={diameter} viewBox={viewBox}>
        <circle r={r} cx={cx} cy={cy} {...rest} />
      </svg>
    );
  }
}

class BubbleDetailOverlay extends Component {
  static propTypes = {
    bubble: PropTypes.shape({
      id: PropTypes.number.isRequired,
      r: PropTypes.number.isRequired,
      data: PropTypes.shape({
        label: PropTypes.string.isRequired,
        countsSum: PropTypes.number.isRequired,
        observationsTotal: PropTypes.number.isRequired
      }).isRequired
    }),
    countsBySample: PropTypes.shape({}).isRequired,
    onClose: PropTypes.func.isRequired,
    onSampleClick: PropTypes.func.isRequired
  };

  handleSampleClick = sampleId => () => {
    this.props.onSampleClick(sampleId);
  };

  renderSample = sampleId => {
    const { bubble, countsBySample } = this.props;

    const counts = bubble.data.countsBySampleId[sampleId];
    const totalCounts = countsBySample[sampleId];
    const fraction = counts / totalCounts;

    const formattedCounts = <FormattedNumber value={counts} />;
    const formattedTotalCounts = <FormattedNumber value={totalCounts} />;
    const formattedPercentage = (
      <FormattedNumber
        style="percent"
        minimuFractionDigits={2}
        value={fraction}
      />
    );

    const sampleBarStyle = {
      width: `${fraction * 100}%`,
      background: COLORS[bubble.id],
      opacity: 0.7
    };

    return (
      <div className={styles.sample} key={sampleId}>
        <strong
          className={styles.sampleId}
          onClick={this.handleSampleClick(sampleId)}
        >
          {sampleId}{" "}
        </strong>
        <div className={styles.sampleDistribution}>
          <FormattedMessage
            id="biom.taxonomicAnalysis.bubbleChart.overlay.sample"
            values={{
              counts: formattedCounts,
              totalCounts: formattedTotalCounts,
              percentage: formattedPercentage
            }}
          />
          <div className={styles.sampleBar}>
            <div className={styles.sampleBarContents} style={sampleBarStyle} />
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { bubble, onClose, countsBySample } = this.props;

    const taxonomy = bubble.data.label;
    const counts = bubble.data.countsSum;
    const observations = bubble.data.observationsTotal;

    const formattedTaxonomy = <strong>{taxonomy}</strong>;
    const formattedCounts = (
      <strong>
        <FormattedNumber value={counts} />
      </strong>
    );
    const formattedObservations = (
      <strong>
        <FormattedNumber value={observations} />
      </strong>
    );

    const fraction = sampleId =>
      bubble.data.countsBySampleId[sampleId] / countsBySample[sampleId];
    const sampleIds = sort(descend(fraction), keys(countsBySample));

    const radius = Math.min(bubble.r, 50);

    return (
      <div className={styles.overlay}>
        <div className={styles.overlayHeader}>
          <SVGCircle
            r={radius}
            padding={4}
            fill={COLORS[bubble.id]}
            fillOpacity={0.7}
            stroke={COLORS[bubble.id]}
            strokeWidth={2}
          />
          <p className={styles.overlayTitle}>
            <FormattedMessage
              id="biom.taxonomicAnalysis.bubbleChart.overlay.title"
              values={{
                taxonomy: formattedTaxonomy,
                counts: formattedCounts,
                observations: formattedObservations
              }}
            />
          </p>
          <span className={styles.closeOverlay} onClick={onClose}>
            &times; close
          </span>
        </div>
        <div className={styles.samples}>{sampleIds.map(this.renderSample)}</div>
      </div>
    );
  }
}

class BubbleTooltip extends Component {
  static propTypes = {
    bubble: PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
      r: PropTypes.number.isRequired,
      data: PropTypes.shape({
        label: PropTypes.string.isRequired,
        countsSum: PropTypes.number.isRequired,
        observationsTotal: PropTypes.number.isRequired
      }).isRequired
    }),
    countsTotal: PropTypes.number.isRequired,
    observationsTotal: PropTypes.number.isRequired,
    containerWidth: PropTypes.number.isRequired
  };

  rootRef = React.createRef();

  componentDidMount() {
    this.repositionTooltip();
  }

  componentDidUpdate() {
    this.repositionTooltip();
  }

  repositionTooltip() {
    const { bubble, containerWidth } = this.props;
    const { clientWidth, clientHeight } = this.rootRef.current;

    const tooltipPadding = 5;
    const maxLeft = Math.max(0, containerWidth - clientWidth);

    const top = bubble.y - bubble.r - clientHeight - tooltipPadding;
    const left = clamp(0, maxLeft, bubble.x - clientWidth / 2);

    this.rootRef.current.style.top = `${top}px`;
    this.rootRef.current.style.left = `${left}px`;
  }

  render() {
    const { bubble, countsTotal, observationsTotal } = this.props;
    const {
      label,
      countsSum,
      observationsTotal: bubbleObservationsTotal
    } = bubble.data;

    const countsFraction = countsSum / countsTotal;
    const observationsFraction = bubbleObservationsTotal / observationsTotal;

    const formattedCounts = <FormattedNumber value={countsSum} />;
    const formattedCountsTotal = <FormattedNumber value={countsTotal} />;
    const formattedCountsPercentage = (
      <FormattedNumber
        style="percent"
        minimuFractionDigits={2}
        value={countsFraction}
      />
    );
    const formattedObservations = (
      <FormattedNumber value={bubbleObservationsTotal} />
    );
    const formattedObservationsTotal = (
      <FormattedNumber value={observationsTotal} />
    );
    const formattedObservationsPercentage = (
      <FormattedNumber
        style="percent"
        minimuFractionDigits={2}
        value={observationsFraction}
      />
    );

    return (
      <div className={styles.tooltipRoot} ref={this.rootRef}>
        <Tooltip>
          <TooltipValue>
            <FormattedMessage
              id="biom.taxonomicAnalysis.bubbleChart.tooltip.taxonomy"
              tagName="strong"
            />
            {` ${label}`}
          </TooltipValue>
          <TooltipValueDetail>
            <FormattedMessage
              id="biom.taxonomicAnalysis.bubbleChart.tooltip.countDistributionInDataset"
              values={{
                value: formattedCounts,
                total: formattedCountsTotal,
                percentage: formattedCountsPercentage
              }}
            />
          </TooltipValueDetail>
          <TooltipValueDetail>
            <FormattedMessage
              id="biom.taxonomicAnalysis.bubbleChart.tooltip.observationDistributionInDataset"
              values={{
                value: formattedObservations,
                total: formattedObservationsTotal,
                percentage: formattedObservationsPercentage
              }}
            />
          </TooltipValueDetail>
        </Tooltip>
      </div>
    );
  }
}

function calculateLayout(layout, data, size) {
  if (layout === LAYOUT_PACK) {
    return calculatePackLayout(data, size);
  } else {
    return calculateListLayout(data, size);
  }
}

function calculatePackLayout(data, size) {
  const width = Math.max(500, size.width);
  const height = 500;

  const d3Layout = pack()
    .size([width, height])
    .padding(3);
  const bubbles = d3Layout(
    hierarchy({
      children: data.chartData
    })
      .sum(prop("countsSum"))
      .sort(descend(prop("value")))
  ).children.map((bubble, idx) => ({ ...bubble, id: idx }));

  return {
    width,
    height,
    bubbles
  };
}

function calculateListLayout(data, size) {
  const { width, bubbles } = calculatePackLayout(data, size);
  const padding = 6;

  let lastX = 0; // x coordinate of recently positioned bubble
  let lastY = bubbles[0].r + padding; // y coordinate of recently positioned bubble
  let lastR = 0; // radius of recently positioned bubble
  let rowR = bubbles[0].r; // radius of first bubble in a row

  const listBubbles = bubbles.map(bubble => {
    let x = lastX + lastR + bubble.r + padding;
    let y = lastY;

    // break into new row
    if (x + bubble.r > width - padding) {
      x = bubble.r + padding;
      y = y + bubble.r + rowR + padding;
      rowR = bubble.r;
    }

    lastX = x;
    lastY = y;
    lastR = bubble.r;

    return {
      ...bubble,
      x,
      y
    };
  });

  return {
    width,
    height: lastY + rowR + padding,
    bubbles: listBubbles
  };
}

function sizesEqual(s1, s2) {
  return s1.width === s2.width;
}

class BubbleChartArea extends Component {
  state = {
    hoveredBubbleLabel: null
  };

  calculateLayout = memoize(calculateLayout);

  calculateSize = memoize(size => size, sizesEqual);

  getLayout = () => {
    const { layout, data, size: rawSize } = this.props;
    const size = this.calculateSize(rawSize);
    return this.calculateLayout(layout, data, size);
  };

  handleBubbleMouseEnter = bubble => event => {
    this.setState({ hoveredBubbleLabel: bubble.data.label });
  };

  handleBubbleMouseLeave = bubble => event => {
    this.setState({ hoveredBubbleLabel: null });
  };

  handleBubbleClick = bubble => event => {
    this.props.onSelect({ value: bubble.data.label });
  };

  handleCloseOverlay = bubble => event => {
    this.props.onSelect(null);
  };

  renderLegend() {
    const { bubbles } = this.getLayout();

    const getRadius = prop("r");
    const sortedBubbles = sortBy(getRadius, bubbles);
    const minBubble = head(sortedBubbles);
    const maxBubble = last(sortedBubbles);
    const minRadius = Math.min(3, Math.ceil(minBubble.r));
    const maxRadius = Math.min(10, Math.ceil(maxBubble.r));
    const fill = "#666";

    return (
      <div className={styles.legend}>
        <FormattedNumber value={minBubble.value} />
        <SVGCircle r={minRadius} fill={fill} />
        &mdash;
        <SVGCircle r={maxRadius} fill={fill} />
        <FormattedNumber value={maxBubble.value} />
      </div>
    );
  }

  renderBubble = bubble => {
    const { hoveredBubbleLabel } = this.state;

    return (
      <circle
        cx={bubble.x}
        cy={bubble.y}
        r={Math.max(bubble.r, 1)}
        fill={COLORS[bubble.id]}
        fillOpacity={0.7}
        stroke={COLORS[bubble.id]}
        strokeWidth={2}
        strokeOpacity={hoveredBubbleLabel === bubble.data.label ? 1.0 : 0.0}
        key={bubble.data.label}
        cursor="pointer"
        data-testid={`bubble[${bubble.data.label}]`}
        onMouseEnter={this.handleBubbleMouseEnter(bubble)}
        onMouseLeave={this.handleBubbleMouseLeave(bubble)}
        onClick={this.handleBubbleClick(bubble)}
      />
    );
  };

  render() {
    const { data, selectedTaxon, onSampleClick } = this.props;
    const { hoveredBubbleLabel } = this.state;

    const { width, height, bubbles } = this.getLayout();

    const hoveredBubble = bubbles.find(
      b => b.data.label === hoveredBubbleLabel
    );

    const selectedBubble = bubbles.find(b => b.data.label === selectedTaxon);

    return (
      // Empty top-level div spans 100% width and is used for
      // measurement with react-sizeme
      <div>
        <div className={styles.chartArea} style={{ width }}>
          {hoveredBubble && (
            <BubbleTooltip
              bubble={hoveredBubble}
              countsTotal={data.countsTotal}
              observationsTotal={data.observationIds.length}
              containerWidth={width}
            />
          )}
          {selectedBubble && (
            <BubbleDetailOverlay
              bubble={selectedBubble}
              countsBySample={data.countsBySample}
              onClose={this.handleCloseOverlay(selectedBubble)}
              onSampleClick={onSampleClick}
            />
          )}
          {this.renderLegend()}
          <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
            {bubbles.map(this.renderBubble)}
          </svg>
        </div>
      </div>
    );
  }
}

export default sizeMe({ refreshRate: 100 })(BubbleChartArea);
