


var match = process.env.SOLVUU_DISABLE_SAAS_APP;

var disableSaasApp = match === "true" ? true : false;

var match$1 = process.env.SOLVUU_DISABLE_REGISTRATION;

var disableRegistration = match$1 === "true" ? true : false;

var match$2 = process.env.SOLVUU_RESTRICT_SAAS_FEATURES;

var enableAllSaasFeatures = match$2 !== undefined && match$2 !== "false" ? false : true;

var match$3 = process.env.SOLVUU_DEPLOYMENT;

var enableAnalytics = match$3 === "prod" ? true : false;

var match$4 = process.env.SOLVUU_DISABLE_PUBLIC_PAGES;

var enablePublicPages = match$4 !== undefined && match$4 !== "false" ? false : true;

var t = process.env;

export {
  t ,
  disableSaasApp ,
  disableRegistration ,
  enableAllSaasFeatures ,
  enableAnalytics ,
  enablePublicPages ,
  
}
/* match Not a pure module */
