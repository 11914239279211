import React, { Component } from "react";

import styles from "./style.css";

export default class Tooltip extends Component {
  render() {
    return <div className={styles.tooltip}>{this.props.children}</div>;
  }
}

export class TooltipTitle extends Component {
  render() {
    return <p className={styles.tooltipTitle}>{this.props.children}</p>;
  }
}

export class TooltipGroup extends Component {
  render() {
    return <div className={styles.tooltipGroup}>{this.props.children}</div>;
  }
}

export class TooltipValue extends Component {
  render() {
    return <p className={styles.tooltipValue}>{this.props.children}</p>;
  }
}

export class TooltipValueDetail extends Component {
  render() {
    return (
      <small className={styles.tooltipValueDetail}>{this.props.children}</small>
    );
  }
}
