

import * as Link from "../../Link.bs.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as StyleCss from "./style.css";

var logo = require("containers/Layout/logo-white.svg");

var styles = StyleCss.default;

function PublicActionLayout(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: styles.root
            }, React.createElement("div", {
                  className: styles.logoWrapper
                }, React.createElement(Link.make, {
                      to: /* Home */803993151,
                      children: React.createElement(ReactIntl.FormattedMessage, {
                            id: "navigation.brand",
                            children: (function (alt) {
                                return React.createElement("img", {
                                            className: styles.logo,
                                            alt: alt,
                                            src: logo
                                          });
                              })
                          })
                    })), React.createElement("div", {
                  className: styles.content
                }, children), React.createElement("div", {
                  className: styles.space
                }));
}

var make = PublicActionLayout;

export {
  logo ,
  styles ,
  make ,
  
}
/* logo Not a pure module */
