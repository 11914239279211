

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Import from "../features/import.bs.js";
import * as Std$Bolt from "bolt/./std.bs.js";
import * as Root$SolvuuApi from "solvuu-api/./root.bs.js";
import * as KernelDensityLineChartJs from "./KernelDensityLineChart.js";

function ofRecord(record) {
  return Curry._1(Std$Bolt.Res.join, Curry._2(Root$SolvuuApi.Kernel_density.T.of_record, record, (function (records, xAxisLabel) {
                    return Curry._2(Std$Bolt.Res.map, Curry._1(Std$Bolt.Res.all, Curry._2(Import.List.map, records, (function (record) {
                                          return Curry._2(Root$SolvuuApi.Kernel_density.T.Point.of_record, record, (function (x, y) {
                                                        return {
                                                                x: x,
                                                                y: y
                                                              };
                                                      }));
                                        }))), (function (points) {
                                  return {
                                          points: Curry._1(Import.List.toArray, points),
                                          xAxisLabel: xAxisLabel
                                        };
                                }));
                  })));
}

function ofTerm(term) {
  if (typeof term === "number" || term[0] !== 847309489) {
    return /* `Error */[
            106380200,
            /* Expected_record */-756637544
          ];
  } else {
    var res = ofRecord(term[1]);
    return Curry._2(Std$Bolt.Res.map_error, res, (function (e) {
                  return /* `Record */[
                          847309489,
                          e
                        ];
                }));
  }
}

var Props = {
  ofRecord: ofRecord,
  ofTerm: ofTerm
};

var make = KernelDensityLineChartJs.default;

export {
  Props ,
  make ,
  
}
/* make Not a pure module */
