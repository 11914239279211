import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import styles from "./style.css";

const taxonomyLevels = [
  [1, "Kingdom"],
  [2, "Phylum"],
  [3, "Class"],
  [4, "Order"],
  [5, "Family"],
  [6, "Genus"],
  [7, "Species"]
];

class TaxonomyLevelSelect extends Component {
  static propTypes = {
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    minTaxonomyLevel: PropTypes.number.isRequired
  };

  static defaultProps = {
    minTaxonomyLevel: 1
  };

  setTaxonomyLevel = level => () => {
    this.props.onChange(level);
  };

  render() {
    const { value, minTaxonomyLevel } = this.props;

    return (
      <div className={styles.root}>
        {taxonomyLevels.map(([level, label]) => (
          <button
            key={level}
            onClick={this.setTaxonomyLevel(level)}
            className={cx(styles.button, { [styles.active]: level === value })}
            disabled={level < minTaxonomyLevel}
            data-testid={`taxonomy-level-${level}`}
          >
            {label}
          </button>
        ))}
      </div>
    );
  }
}

export default TaxonomyLevelSelect;
