

import * as Tabs from "../../../../components/Tabs.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as TermJs from "../../../../lib/solvuu/termJs.bs.js";
import * as BiomTab from "./BiomTab.bs.js";
import * as Js_dict from "bs-platform/lib/es6/js_dict.js";
import * as Caml_obj from "bs-platform/lib/es6/caml_obj.js";
import * as TermUtils from "../../../../lib/solvuu/termUtils.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Caml_int32 from "bs-platform/lib/es6/caml_int32.js";
import * as Pervasives from "bs-platform/lib/es6/pervasives.js";
import * as WithTestId from "../../../WithTestId.bs.js";
import * as ReactIntl from "react-intl";
import * as StyleCss from "./style.css";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Caml_primitive from "bs-platform/lib/es6/caml_primitive.js";
import * as Pick from "ramda/src/pick";
import * as Uniq from "ramda/src/uniq";
import * as BiomAggregations from "../../../../features/biom/biomAggregations.bs.js";
import * as Either from "ramda/src/either";
import * as SortWith from "ramda/src/sortWith";
import * as Intersection from "ramda/src/intersection";
import * as Caml_builtin_exceptions from "bs-platform/lib/es6/caml_builtin_exceptions.js";
import * as BarChartJs from "containers/Entities/TaxonomicAnalysis/Biom/BarChart/BarChart.js";
import * as AreaChartJs from "containers/Entities/TaxonomicAnalysis/Biom/AreaChart/AreaChart.js";
import * as KronaChartJs from "containers/Entities/TaxonomicAnalysis/Biom/KronaChart/KronaChart.js";
import * as BubbleChartJs from "containers/Entities/TaxonomicAnalysis/Biom/BubbleChart/BubbleChart.js";
import * as SankeyDiagramJs from "containers/Entities/TaxonomicAnalysis/Biom/SankeyDiagram/SankeyDiagram.js";

function navigateToSample(sampleId) {
  return {
          tab: "taxonomic-analysis",
          taxonomy: "krona",
          sampleId: sampleId
        };
}

function dict(t) {
  return Js_dict.fromArray(/* array */[
              /* tuple */[
                "tab",
                t.tab
              ],
              /* tuple */[
                "taxonomy",
                t.taxonomy
              ],
              /* tuple */[
                "sampleId",
                t.sampleId
              ]
            ]);
}

var Query = {
  navigateToSample: navigateToSample,
  dict: dict
};

var iconAreaChart = require("./icon-area-chart.svg");

var iconBarChart = require("./icon-bar-chart.svg");

var iconBubbleChart = require("./icon-bubble-chart.svg");

var iconSankey = require("./icon-sankey.svg");

var iconKrona = require("./icon-krona.svg");

var styles = StyleCss.default;

function toString(x) {
  if (x !== -88314117) {
    if (x >= 42029996) {
      if (x >= 726513581) {
        return "AREA";
      } else {
        return "BUBBLE";
      }
    } else if (x >= 3303859) {
      return "BAR";
    } else {
      return "SANKEY";
    }
  } else {
    return "KRONA";
  }
}

function ofString(x) {
  switch (x) {
    case "AREA" :
        return /* Area */726513581;
    case "BAR" :
        return /* Bar */3303859;
    case "BUBBLE" :
        return /* Bubble */42029996;
    case "KRONA" :
        return /* Krona */-88314117;
    case "SANKEY" :
        return /* Sankey */-763086433;
    default:
      return Pervasives.failwith("Invalid: " + x);
  }
}

var Tab = {
  toString: toString,
  ofString: ofString
};

var barSortingCriteria = /* array */[];

var chartControls = {
  taxonomyLevel: 1,
  barExpand: false,
  barSortingCriteria: barSortingCriteria
};

var Default = {
  chartControls: chartControls
};

function taxonomyBySampleChartDataSelector(term, taxonomyLevel, sortingCriteria) {
  var dataframe = TermUtils.decodeBiomDataframe(TermJs.convertToJs(term));
  var distributions = BiomAggregations.taxonomyDistributionBySample(dataframe, taxonomyLevel);
  var sampleIds = BiomAggregations.allSampleIds(dataframe);
  var sampleMetadata = BiomAggregations.allSampleMetadata(dataframe);
  var distributionsBySampleId = Js_dict.fromArray(Belt_Array.map(distributions, (function (d) {
              return /* tuple */[
                      d.sampleId,
                      d
                    ];
            })));
  var countsBySample = Js_dict.fromArray(Belt_Array.map(distributions, (function (x) {
              return /* tuple */[
                      x.sampleId,
                      x.countsSum
                    ];
            })));
  var countsByTaxonomy = Belt_Array.reduce(Belt_Array.map(distributions, (function (x) {
              return x.countsByTaxonomy;
            })), { }, (function (acc, x) {
          Belt_Array.forEach(Js_dict.entries(x), (function (param) {
                  var v = param[1];
                  var k = param[0];
                  var match = Js_dict.get(acc, k);
                  acc[k] = match !== undefined ? v + match : v;
                  return /* () */0;
                }));
          return acc;
        }));
  var sumValues = function (x) {
    return Belt_Array.reduce(Js_dict.values(x), 0, (function (prim, prim$1) {
                  return prim + prim$1;
                }));
  };
  var countsTotal = sumValues(countsBySample);
  if (countsTotal !== sumValues(countsByTaxonomy)) {
    throw [
          Caml_builtin_exceptions.assert_failure,
          /* tuple */[
            "Biom.re",
            128,
            2
          ]
        ];
  }
  var taxonomies = Object.keys(countsByTaxonomy).sort((function (a, b) {
          var a$prime = countsByTaxonomy[a];
          var b$prime = countsByTaxonomy[b];
          return Caml_int32.imul(-1, Caml_primitive.caml_float_compare(a$prime, b$prime));
        }));
  var sampleMetadataAttributes = Belt_Array.map(BiomAggregations.allSampleMetadataAttributes(dataframe), (function (attribute) {
          return {
                  name: attribute.name,
                  type: "metadata"
                };
        }));
  var taxonomicAbundanceAttributes = Belt_Array.map(taxonomies, (function (t) {
          return {
                  name: t,
                  type: "taxonomy"
                };
        }));
  var sortingAttributes = Belt_Array.concat(sampleMetadataAttributes, taxonomicAbundanceAttributes);
  var sampleMetadataCriteria = sortingCriteria.filter((function (criterion) {
          return sampleMetadataAttributes.some((function (attribute) {
                        return attribute.name === criterion.attribute;
                      }));
        }));
  var getMetadataValue = function (attribute, distribution) {
    return Belt_Option.getExn(Belt_Option.flatMap(Js_dict.get(sampleMetadata, distribution.sampleId), (function (x) {
                      return Js_dict.get(x, attribute);
                    })));
  };
  var getTaxonomyDistributionInSample = function (taxonomy, sampleId) {
    var distribution = distributionsBySampleId[sampleId];
    var value = distribution.countsByTaxonomy[taxonomy];
    var total = distribution.countsSum;
    var relative = value / total;
    return {
            value: value,
            total: total,
            relative: relative
          };
  };
  var getAbundanceValue = function (taxonomy, distribution) {
    return getTaxonomyDistributionInSample(taxonomy, distribution.sampleId).relative;
  };
  var comparators = Belt_Array.map(sortingCriteria, (function (criterion) {
          var attribute = criterion.attribute;
          var direction = criterion.direction;
          var match = sampleMetadataCriteria.includes(criterion);
          var getter = match ? getMetadataValue : getAbundanceValue;
          var getValue = Curry._1(getter, attribute);
          var ascend = function (f, a, b) {
            var a$prime = Curry._1(f, a);
            var b$prime = Curry._1(f, b);
            return Caml_obj.caml_compare(a$prime, b$prime);
          };
          var descend = function (f, a, b) {
            return Caml_int32.imul(ascend(f, a, b), -1);
          };
          var match$1 = direction === "desc";
          var directed = match$1 ? descend : ascend;
          return Curry._1(directed, getValue);
        }));
  var sortedDistributions = SortWith.default(comparators, distributions);
  var sortedSampleIds = Belt_Array.map(sortedDistributions, (function (x) {
          return x.sampleId;
        }));
  if (sampleIds.length !== Intersection.default(sampleIds, sortedSampleIds).length) {
    throw [
          Caml_builtin_exceptions.assert_failure,
          /* tuple */[
            "Biom.re",
            191,
            2
          ]
        ];
  }
  var chartData = Belt_Array.map(sortedDistributions, (function (distribution) {
          var labelSuffix = Belt_Array.map(Belt_Array.map(sampleMetadataCriteria, (function (c) {
                        return getMetadataValue(c.attribute, distribution);
                      })), (function (v) {
                    return "" + (String(v) + "");
                  })).join(" ");
          var acc = { };
          Belt_Array.forEach(Object.keys(distribution.countsByTaxonomy), (function (k) {
                  acc[k] = distribution.countsByTaxonomy[k];
                  return /* () */0;
                }));
          acc["label"] = distribution.sampleId + (" " + labelSuffix);
          acc["sampleId"] = distribution.sampleId;
          return acc;
        }));
  var getTaxonomyDistributionInDataset = function (taxonomy) {
    var value = countsByTaxonomy[taxonomy];
    var relative = value / countsTotal;
    return {
            value: value,
            total: countsTotal,
            relative: relative
          };
  };
  var getTaxonomiesTotalCounts = function (selectedTaxonomies) {
    return sumValues(Pick.default(selectedTaxonomies, countsByTaxonomy));
  };
  return {
          chartData: chartData,
          taxonomies: taxonomies,
          sortingAttributes: sortingAttributes,
          sampleIds: sortedSampleIds,
          getTaxonomyDistributionInDataset: getTaxonomyDistributionInDataset,
          getTaxonomyDistributionInSample: getTaxonomyDistributionInSample,
          getTaxonomiesTotalCounts: getTaxonomiesTotalCounts
        };
}

function taxonomyHierarchyForSampleIdChartDataSelector(term, sampleId) {
  var dataframe = TermUtils.decodeBiomDataframe(TermJs.convertToJs(term));
  var chartData = sampleId === "" ? null : BiomAggregations.taxonomyHierarchyForSampleId(dataframe, sampleId);
  var sampleIds = BiomAggregations.allSampleIds(dataframe);
  return {
          chartData: chartData,
          sampleIds: sampleIds
        };
}

function taxonomyHierarchyNodesAndLinksChartDataSelector(term, taxonomyLevel) {
  if (taxonomyLevel <= 1) {
    throw [
          Caml_builtin_exceptions.assert_failure,
          /* tuple */[
            "Biom.re",
            274,
            2
          ]
        ];
  }
  var dataframe = TermUtils.decodeBiomDataframe(TermJs.convertToJs(term));
  var fullHierarchy = BiomAggregations.taxonomyHierarchyDistribution(dataframe, undefined, undefined);
  var nodes = /* array */[];
  var links = /* array */[];
  var drilldown = function (node, $staropt$star, $staropt$star$1, param) {
    var currentTaxonomyLevel = $staropt$star !== undefined ? $staropt$star : 1;
    var parentNodeIndex = $staropt$star$1 !== undefined ? Caml_option.valFromOption($staropt$star$1) : null;
    var match = currentTaxonomyLevel > taxonomyLevel;
    if (match) {
      return /* () */0;
    } else {
      var nodeIndex = nodes.length;
      nodes[nodeIndex] = {
        label: node.label,
        value: node.value,
        id: nodeIndex
      };
      if (!(parentNodeIndex == null)) {
        links.push({
              source: parentNodeIndex,
              target: nodeIndex,
              value: node.value
            });
      }
      return Belt_Array.forEach(node.children, (function (childNode) {
                    return drilldown(childNode, currentTaxonomyLevel + 1 | 0, Caml_option.some(nodeIndex), /* () */0);
                  }));
    }
  };
  Belt_Array.forEach(fullHierarchy.children, (function (node) {
          return drilldown(node, undefined, undefined, /* () */0);
        }));
  var thisChartData = {
    nodes: nodes,
    links: links
  };
  return {
          chartData: thisChartData
        };
}

function taxonomyHierarchyNodesAndLinksForNodeChartDataSelector(term, taxonomyLevel, nodeId) {
  var rawData = taxonomyHierarchyNodesAndLinksChartDataSelector(term, taxonomyLevel);
  var isLinkWithNodeId = function (param) {
    return Either.default((function (x) {
                  return Caml_obj.caml_equal(x.source, nodeId);
                }), (function (x) {
                  return Caml_obj.caml_equal(x.target, nodeId);
                }), param);
  };
  var getNodeIdsFromLinks = function (xs) {
    return Uniq.default(Belt_Array.concatMany(Belt_Array.map(xs, (function (x) {
                          return /* array */[
                                  x.source,
                                  x.target
                                ];
                        }))));
  };
  var createNodeIdMap = function (xs) {
    return Js_dict.fromArray(Belt_Array.mapWithIndex(xs, (function (idx, a) {
                      return /* tuple */[
                              String(a),
                              idx
                            ];
                    })));
  };
  var selectedLinks = rawData.chartData.links.filter(isLinkWithNodeId);
  var visibleNodeIds = getNodeIdsFromLinks(selectedLinks);
  var nodeIdMap = createNodeIdMap(visibleNodeIds);
  var visibleNodes = Belt_Array.mapWithIndex(visibleNodeIds, (function (idx, param) {
          return rawData.chartData.nodes[idx];
        }));
  var visibleLinks = Belt_Array.map(selectedLinks, (function (l) {
          return {
                  source: nodeIdMap[String(l.source)],
                  target: nodeIdMap[String(l.target)],
                  value: l.value
                };
        }));
  return {
          chartData: {
            nodes: visibleNodes,
            links: visibleLinks
          }
        };
}

function taxonomyByTaxonChartDataSelector(term, taxonomyLevel) {
  var dataframe = TermUtils.decodeBiomDataframe(TermJs.convertToJs(term));
  var distributions = BiomAggregations.taxonomyDistributionByTaxon(dataframe, taxonomyLevel);
  var sampleIds = BiomAggregations.allSampleIds(dataframe);
  var observationIds = BiomAggregations.allObservationIds(dataframe);
  var chartData = Belt_Array.map(distributions, (function (distribution) {
          return {
                  label: distribution.taxonomy,
                  countsByObservationId: distribution.countsByObservationId,
                  countsBySampleId: distribution.countsBySampleId,
                  countsSum: distribution.countsSum,
                  observationsTotal: distribution.observationsTotal,
                  samplesTotal: distribution.samplesTotal,
                  taxonomy: distribution.taxonomy
                };
        }));
  var countsByTaxonomy = Js_dict.fromArray(Belt_Array.map(distributions, (function (d) {
              return /* tuple */[
                      d.taxonomy,
                      d.countsSum
                    ];
            })));
  var countsBySample = Belt_Array.reduce(Belt_Array.map(distributions, (function (d) {
              return d.countsBySampleId;
            })), { }, (function (acc, x) {
          Belt_Array.forEach(Js_dict.entries(x), (function (param) {
                  var v = param[1];
                  var k = param[0];
                  var match = Js_dict.get(acc, k);
                  if (match !== undefined) {
                    acc[k] = v + match;
                    return /* () */0;
                  } else {
                    acc[k] = v;
                    return /* () */0;
                  }
                }));
          return acc;
        }));
  var sumValues = function (x) {
    return Belt_Array.reduce(Js_dict.values(x), 0, (function (prim, prim$1) {
                  return prim + prim$1;
                }));
  };
  var countsTotal = sumValues(countsByTaxonomy);
  var taxonomies = Object.keys(countsByTaxonomy);
  if (countsTotal !== sumValues(countsBySample)) {
    throw [
          Caml_builtin_exceptions.assert_failure,
          /* tuple */[
            "Biom.re",
            404,
            2
          ]
        ];
  }
  return {
          chartData: chartData,
          sampleIds: sampleIds,
          observationIds: observationIds,
          taxonomies: taxonomies,
          countsBySample: countsBySample,
          countsByTaxonomy: countsByTaxonomy,
          countsTotal: countsTotal
        };
}

function useSampleId(param) {
  var match = React.useState((function () {
          return "";
        }));
  var setState = match[1];
  var navigateToSample = function (sample) {
    return Curry._1(setState, (function (param) {
                  return sample;
                }));
  };
  return /* tuple */[
          match[0],
          navigateToSample
        ];
}

function useChartControls($staropt$star, param) {
  var init = $staropt$star !== undefined ? $staropt$star : chartControls;
  var match = React.useState((function () {
          return init;
        }));
  var setState = match[1];
  var setChartControls = function (x) {
    return Curry._1(setState, (function (param) {
                  return {
                          taxonomyLevel: Belt_Option.getWithDefault(x.taxonomyLevel, param.taxonomyLevel),
                          barExpand: Belt_Option.getWithDefault(x.barExpand, param.barExpand),
                          barSortingCriteria: Belt_Option.getWithDefault(x.barSortingCriteria, param.barSortingCriteria)
                        };
                }));
  };
  return /* tuple */[
          match[0],
          setChartControls
        ];
}

var Internal = { };

function Biom$AreaChart(Props) {
  var term = Props.term;
  var chartControls = Props.chartControls;
  var setChartControls = Props.setChartControls;
  var getDataForChart = function (level) {
    return taxonomyBySampleChartDataSelector(term, level, /* array */[]);
  };
  var match = useSampleId(/* () */0);
  return React.createElement(AreaChartJs.default, {
              chartControls: chartControls,
              setChartControls: setChartControls,
              navigateToSample: match[1],
              getDataForChart: getDataForChart
            });
}

var AreaChart = {
  Internal: Internal,
  make: Biom$AreaChart
};

var Internal$1 = { };

function Biom$BarChart(Props) {
  var term = Props.term;
  var chartControls = Props.chartControls;
  var setChartControls = Props.setChartControls;
  var match = useSampleId(/* () */0);
  var getDataForChart = function (taxonomyLevel, sortingCriteria) {
    return taxonomyBySampleChartDataSelector(term, taxonomyLevel, sortingCriteria);
  };
  return React.createElement(BarChartJs.default, {
              chartControls: chartControls,
              setChartControls: setChartControls,
              getDataForChart: getDataForChart,
              navigateToSample: match[1]
            });
}

var BarChart = {
  Internal: Internal$1,
  make: Biom$BarChart
};

var Internal$2 = { };

function Biom$BubbleChart(Props) {
  var term = Props.term;
  var chartControls = Props.chartControls;
  var setChartControls = Props.setChartControls;
  var getDataForTaxonomyLevel = function (x) {
    return taxonomyByTaxonChartDataSelector(term, x);
  };
  return React.createElement(BubbleChartJs.default, {
              getDataForTaxonomyLevel: getDataForTaxonomyLevel,
              chartControls: chartControls,
              setChartControls: setChartControls
            });
}

var BubbleChart = {
  Internal: Internal$2,
  make: Biom$BubbleChart
};

var Internal$3 = { };

function Biom$KronaChart(Props) {
  var term = Props.term;
  var match = useSampleId(/* () */0);
  var sampleId = match[0];
  var data = taxonomyHierarchyForSampleIdChartDataSelector(term, sampleId);
  var chartData = data.chartData;
  var sampleIds = data.sampleIds;
  var tmp = {
    sampleId: sampleId,
    sampleIds: sampleIds,
    navigateToSample: match[1]
  };
  var tmp$1 = (chartData == null) ? undefined : Caml_option.some(chartData);
  if (tmp$1 !== undefined) {
    tmp.chartData = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(KronaChartJs.default, tmp);
}

var KronaChart = {
  Internal: Internal$3,
  make: Biom$KronaChart
};

var Internal$4 = { };

function Biom$SankeyDiagram(Props) {
  var term = Props.term;
  var chartControls = Props.chartControls;
  var setChartControls = Props.setChartControls;
  var getDataForTaxonomyLevel = function (level) {
    return taxonomyHierarchyNodesAndLinksChartDataSelector(term, level);
  };
  var getDataForNode = function (taxonomyLevel, nodeId) {
    return taxonomyHierarchyNodesAndLinksForNodeChartDataSelector(term, taxonomyLevel, nodeId);
  };
  return React.createElement(SankeyDiagramJs.default, {
              getDataForTaxonomyLevel: getDataForTaxonomyLevel,
              getDataForNode: getDataForNode,
              chartControls: chartControls,
              setChartControls: setChartControls
            });
}

var SankeyDiagram = {
  Internal: Internal$4,
  make: Biom$SankeyDiagram
};

function hasTaxonomicMetadata(term) {
  if (typeof term === "number" || term[0] !== -976970511) {
    return false;
  } else {
    try {
      return BiomAggregations.hasTaxonomicMetadata(JSON.parse(term[1]));
    }
    catch (exn){
      return false;
    }
  }
}

function tabs(term, chartControls, setChartControls) {
  return /* :: */[
          /* tuple */[
            /* Bar */3303859,
            {
              title: React.createElement(React.Fragment, undefined, React.createElement("img", {
                        alt: "",
                        src: iconBarChart
                      }), React.createElement(ReactIntl.FormattedMessage, {
                        id: "biom.taxonomicAnalysis.tabs.bar"
                      })),
              render: (function (param) {
                  return React.createElement(Biom$BarChart, {
                              term: term,
                              chartControls: chartControls,
                              setChartControls: setChartControls
                            });
                })
            }
          ],
          /* :: */[
            /* tuple */[
              /* Bubble */42029996,
              {
                title: React.createElement(React.Fragment, undefined, React.createElement("img", {
                          alt: "",
                          src: iconBubbleChart
                        }), React.createElement(ReactIntl.FormattedMessage, {
                          id: "biom.taxonomicAnalysis.tabs.bubble"
                        })),
                render: (function (param) {
                    return React.createElement(Biom$BubbleChart, {
                                term: term,
                                chartControls: chartControls,
                                setChartControls: setChartControls
                              });
                  })
              }
            ],
            /* :: */[
              /* tuple */[
                /* Area */726513581,
                {
                  title: React.createElement(React.Fragment, undefined, React.createElement("img", {
                            alt: "",
                            src: iconAreaChart
                          }), React.createElement(ReactIntl.FormattedMessage, {
                            id: "biom.taxonomicAnalysis.tabs.area"
                          })),
                  render: (function (param) {
                      return React.createElement(Biom$AreaChart, {
                                  term: term,
                                  chartControls: chartControls,
                                  setChartControls: setChartControls
                                });
                    })
                }
              ],
              /* :: */[
                /* tuple */[
                  /* Sankey */-763086433,
                  {
                    title: React.createElement(React.Fragment, undefined, React.createElement("img", {
                              alt: "",
                              src: iconSankey
                            }), React.createElement(ReactIntl.FormattedMessage, {
                              id: "biom.taxonomicAnalysis.tabs.sankey"
                            })),
                    render: (function (param) {
                        return React.createElement(Biom$SankeyDiagram, {
                                    term: term,
                                    chartControls: chartControls,
                                    setChartControls: setChartControls
                                  });
                      })
                  }
                ],
                /* :: */[
                  /* tuple */[
                    /* Krona */-88314117,
                    {
                      title: React.createElement(React.Fragment, undefined, React.createElement("img", {
                                alt: "",
                                src: iconKrona
                              }), React.createElement(ReactIntl.FormattedMessage, {
                                id: "biom.taxonomicAnalysis.tabs.krona"
                              })),
                      render: (function (param) {
                          return React.createElement(Biom$KronaChart, {
                                      term: term
                                    });
                        })
                    }
                  ],
                  /* [] */0
                ]
              ]
            ]
          ]
        ];
}

function Biom(Props) {
  var term = Props.term;
  var match = useChartControls(undefined, /* () */0);
  var tabs$1 = tabs(term, match[0], match[1]);
  var match$1 = hasTaxonomicMetadata(term);
  if (match$1) {
    return React.createElement(WithTestId.make, {
                id: "taxonomic-analysis",
                children: React.createElement("div", {
                      className: styles.root
                    }, React.createElement(Tabs.make, {
                          tabs: tabs$1,
                          init: /* Bar */3303859,
                          toString: BiomTab.toString,
                          type_: /* Secondary */67972948
                        }))
              });
  } else {
    return React.createElement("div", {
                className: styles.blank
              }, React.createElement(ReactIntl.FormattedMessage, {
                    id: "biom.taxonomicAnalysis.unavailable"
                  }));
  }
}

var make = Biom;

export {
  Query ,
  iconAreaChart ,
  iconBarChart ,
  iconBubbleChart ,
  iconSankey ,
  iconKrona ,
  styles ,
  Tab ,
  Default ,
  taxonomyBySampleChartDataSelector ,
  taxonomyHierarchyForSampleIdChartDataSelector ,
  taxonomyHierarchyNodesAndLinksChartDataSelector ,
  taxonomyHierarchyNodesAndLinksForNodeChartDataSelector ,
  taxonomyByTaxonChartDataSelector ,
  useSampleId ,
  useChartControls ,
  AreaChart ,
  BarChart ,
  BubbleChart ,
  KronaChart ,
  SankeyDiagram ,
  hasTaxonomicMetadata ,
  tabs ,
  make ,
  
}
/* iconAreaChart Not a pure module */
