

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as Op from "../features/Op.bs.js";
import * as Err from "../components/Err.bs.js";
import * as Op2 from "../features/Op2.bs.js";
import * as Term from "../lang/Term.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Button from "../components/Button.bs.js";
import * as Import from "../features/import.bs.js";
import * as Loading from "../components/Loading.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as ReactExtras from "../lib/solvuu/ReactExtras.bs.js";
import * as SourceCss from "./Source.css";
import * as VerticalLayout from "../components/Layout/VerticalLayout.bs.js";
import * as ReactDropzone from "react-dropzone";
import * as HorizontalLayout from "../components/Layout/HorizontalLayout.bs.js";
import * as Layout from "components/Layout";
import * as DragAndDropBanner from "./DragAndDropBanner";

var styles = SourceCss.default;

function filename(t) {
  if (t[0] >= 781515420) {
    return t[1].name;
  } else {
    return t[1].href;
  }
}

function load(t) {
  if (t[0] >= 781515420) {
    return Import.$$return(t[1]);
  } else {
    var url = t[1].href;
    return Import.$great$great$pipe(Import.$great$great$eq(fetch(url), (function (prim) {
                      return prim.blob();
                    })), (function (blob) {
                  return new File(/* array */[blob], url);
                }));
  }
}

function useLoad(apiClient) {
  return Op.useOp(undefined, (function (source) {
                if (source !== undefined) {
                  var source$1 = source;
                  return Import.$great$great$pipe(Import.$great$great$eq(load(source$1), (function (file) {
                                    return Term.bytesOfFile(apiClient, file);
                                  })), (function (term) {
                                return /* `Done */[
                                        759635106,
                                        /* tuple */[
                                          source$1,
                                          term
                                        ]
                                      ];
                              }));
                } else {
                  return Promise.resolve(/* `Retry */[
                              -101284024,
                              undefined
                            ]);
                }
              }));
}

function useLoad2(onChange, apiClient) {
  return Op2.useOp(onChange, undefined, (function (source) {
                if (source !== undefined) {
                  var source$1 = source;
                  return Import.$great$great$pipe(Import.$great$great$eq(load(source$1), (function (file) {
                                    return Term.bytesOfFile(apiClient, file);
                                  })), (function (term) {
                                return /* tuple */[
                                        source$1,
                                        term
                                      ];
                              }));
                } else {
                  return Promise.resolve(undefined);
                }
              }), /* () */0);
}

var make = DragAndDropBanner.default;

function ofUseLoad(x) {
  var variant = x[0];
  if (variant !== -783600662) {
    if (variant >= 951752159) {
      return /* `Running */[
              951752159,
              x[1]
            ];
    } else {
      return /* `Output */[
              -1055554783,
              /* `Ok */[
                17724,
                x[1].data[0]
              ]
            ];
    }
  } else {
    return /* `Input */[
            -783600662,
            x[1].data
          ];
  }
}

function ofUseLoad2(x) {
  var match = Op2.State.status(x);
  if (typeof match === "number") {
    return /* `Running */[
            951752159,
            Op2.State.input(x)
          ];
  } else if (match[0] >= -783600662) {
    return /* `Input */[
            -783600662,
            match[1]
          ];
  } else {
    var match$1 = match[1];
    if (match$1 !== undefined) {
      return /* `Output */[
              -1055554783,
              /* `Ok */[
                17724,
                match$1[0]
              ]
            ];
    } else {
      return /* `Output */[
              -1055554783,
              /* `Error */[
                106380200,
                "invalid empty input"
              ]
            ];
    }
  }
}

var Status = {
  ofUseLoad: ofUseLoad,
  ofUseLoad2: ofUseLoad2
};

function Source(Props) {
  var accept = Props.accept;
  var status = Props.status;
  var onSelect = Props.onSelect;
  var onClear = Props.onClear;
  var renderDropzone = function (dropzoneChildren) {
    return React.createElement(ReactDropzone.default, {
                accept: accept,
                className: styles.dropzone,
                activeClassName: styles.dropzoneActive,
                onDrop: (function (acceptedFiles, rejectedFiles) {
                    var files = Belt_Array.concat(acceptedFiles, rejectedFiles);
                    var match = Belt_Array.get(files, 0);
                    if (match !== undefined) {
                      return Curry._1(onSelect, /* `File */[
                                  781515420,
                                  Caml_option.valFromOption(match)
                                ]);
                    } else {
                      return /* () */0;
                    }
                  }),
                multiple: false,
                children: dropzoneChildren
              });
  };
  var renderFilled = function (dropzoneChildren) {
    return React.createElement("div", {
                className: Cn.make(/* :: */[
                      styles.dropzone,
                      /* :: */[
                        styles.dropzoneFilled,
                        /* [] */0
                      ]
                    ])
              }, dropzoneChildren);
  };
  var clearFileButton = ReactExtras.withTestId("standalone-visualizations-file-clear", React.createElement(Button.make, Button.makeProps((function ($$event) {
                  $$event.stopPropagation();
                  return Curry._1(onClear, /* () */0);
                }), undefined, /* `Basic */[
                -1058563442,
                Curry._4(Button.Type.Basic.make, undefined, /* Secondary */67972948, undefined, /* () */0)
              ], React.createElement(ReactIntl.FormattedMessage, {
                    id: "charts.fileSelection.actions.clear"
                  }), /* () */0)));
  var emptyDropzone = renderDropzone(React.createElement(React.Fragment, undefined, React.createElement(make, { }), React.createElement("div", {
                className: styles.dropzoneMessage
              }, React.createElement(ReactIntl.FormattedMessage, {
                    id: "charts.fileSelection.message"
                  }), React.createElement("div", {
                    className: styles.dropzoneMessageSmall
                  }, React.createElement(ReactIntl.FormattedMessage, {
                        id: "charts.fileSelection.formatsMessage"
                      }))), React.createElement(Button.make, Button.makeProps(undefined, undefined, undefined, React.createElement(ReactIntl.FormattedMessage, {
                        id: "charts.fileSelection.actions.select"
                      }), /* () */0)), React.createElement("div", {
                className: styles.dropzoneExamples
              }, React.createElement(ReactIntl.FormattedMessage, {
                    id: "charts.fileSelection.actions.selectExample"
                  }), React.createElement("br", undefined), ReactExtras.listToElement(Curry._2(Import.List.map, /* :: */[
                        "example-counts-small.csv",
                        /* :: */[
                          "rich_sparse_otu_table_hdf5.biom",
                          /* :: */[
                            "primates.nwk",
                            /* [] */0
                          ]
                        ]
                      ], (function (example) {
                          var url = window.location.protocol + ("//" + (window.location.host + ("/solvuu-examples/" + example)));
                          return React.createElement("button", {
                                      key: example,
                                      className: styles.dropzoneExamplesButton,
                                      onClick: (function ($$event) {
                                          $$event.stopPropagation();
                                          return Curry._1(onSelect, /* `Url */[
                                                      4252495,
                                                      new URL(url)
                                                    ]);
                                        })
                                    }, example);
                        }))))));
  var variant = status[0];
  var cardContents;
  if (variant !== -783600662) {
    if (variant >= 951752159) {
      var fileName = Belt_Option.mapWithDefault(status[1], "", filename);
      cardContents = renderFilled(React.createElement("div", {
                className: styles.dropzoneMessage
              }, React.createElement("span", undefined, React.createElement(ReactIntl.FormattedMessage, {
                        id: "charts.fileSelection.fileSelecting.message",
                        values: {
                          fileName: fileName
                        }
                      }), React.createElement(Loading.Dots.make, { }))));
    } else {
      var match = status[1];
      var variant$1 = match[0];
      if (variant$1 !== 14018374) {
        if (variant$1 >= 106380200) {
          var tmp = { };
          var tmp$1 = match[1];
          if (tmp$1 !== undefined) {
            tmp.message = Caml_option.valFromOption(tmp$1);
          }
          cardContents = React.createElement(Layout.VerticalLayout, VerticalLayout.makeProps(/* x2 */26810, null, /* () */0), React.createElement(Layout.VerticalLayoutItem, {
                    children: React.createElement(Err.Operation.make, tmp)
                  }), React.createElement(Layout.VerticalLayoutItem, {
                    children: emptyDropzone
                  }));
        } else {
          var fileName$1 = filename(match[1]);
          cardContents = renderFilled(React.createElement(React.Fragment, undefined, React.createElement("div", {
                        className: styles.dropzoneMessage
                      }, React.createElement(ReactIntl.FormattedMessage, {
                            id: "charts.fileSelection.fileSelected.message",
                            values: {
                              fileName: fileName$1
                            }
                          })), clearFileButton));
        }
      } else {
        var fileName$2 = filename(match[1]);
        cardContents = renderDropzone(React.createElement(React.Fragment, undefined, React.createElement("div", {
                      className: styles.dropzoneMessage
                    }, React.createElement(ReactIntl.FormattedMessage, {
                          id: "charts.fileSelection.reselect.message",
                          values: {
                            fileName: fileName$2
                          }
                        })), React.createElement(Layout.HorizontalLayout, HorizontalLayout.makeProps(/* x3 */26811, null, /* () */0), React.createElement(Layout.HorizontalLayoutItem, {
                          children: React.createElement(Button.make, Button.makeProps(undefined, undefined, undefined, React.createElement(ReactIntl.FormattedMessage, {
                                        id: "charts.fileSelection.actions.reselect",
                                        values: {
                                          fileName: fileName$2
                                        }
                                      }), /* () */0))
                        }), React.createElement(Layout.HorizontalLayoutItem, {
                          children: clearFileButton
                        }))));
      }
    }
  } else {
    cardContents = emptyDropzone;
  }
  return ReactExtras.withTestId("standalone-visualizations-file-selection", React.createElement("div", undefined, cardContents));
}

var make$1 = Source;

export {
  filename ,
  load ,
  useLoad ,
  useLoad2 ,
  Status ,
  make$1 as make,
  
}
/* styles Not a pure module */
