

import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Import from "../import.bs.js";
import * as Printf from "bs-platform/lib/es6/printf.js";
import * as Js_dict from "bs-platform/lib/es6/js_dict.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Caml_array from "bs-platform/lib/es6/caml_array.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Uniq from "ramda/src/uniq";
import * as IndexedDataframeAccess from "./indexedDataframeAccess.bs.js";

var taxonomyAttribute = "observation_metadata_taxonomy";

var sampleIdAttribute = "sample_id";

var observationIdAttribute = "observation_id";

var countsAttribute = "observation_matrix_data";

var sampleMetadataAttributePrefix = "sample_metadata_";

function hasTaxonomicMetadata(dataframeJs) {
  return IndexedDataframeAccess.hasIndexedData(dataframeJs, taxonomyAttribute);
}

function assertTaxonomyLevel(taxonomyLevel) {
  console.assert(taxonomyLevel >= 1 && taxonomyLevel <= 7, Curry._1(Printf.sprintf(/* Format */[
                /* String_literal */Block.__(11, [
                    "Taxonomy level must be in range <1, 7>, was ",
                    /* Int */Block.__(4, [
                        /* Int_d */0,
                        /* No_padding */0,
                        /* No_precision */0,
                        /* End_of_format */0
                      ])
                  ]),
                "Taxonomy level must be in range <1, 7>, was %d"
              ]), taxonomyLevel));
  return /* () */0;
}

function assertTaxonomy(dataframeJs) {
  console.assert(hasTaxonomicMetadata(dataframeJs), "Dataframe does not contain taxonomic metadata");
  return /* () */0;
}

function lookupInIndex(data, dataIndex, idx) {
  return Caml_array.caml_array_get(data, Caml_array.caml_array_get(dataIndex, idx));
}

function taxonomyDistributionBySample(dataframeJs, taxonomyLevel) {
  assertTaxonomy(dataframeJs);
  assertTaxonomyLevel(taxonomyLevel);
  var sampleIds = IndexedDataframeAccess.getIndexedStringData(dataframeJs, sampleIdAttribute);
  var sampleIdIndex = IndexedDataframeAccess.getIndex(dataframeJs, sampleIdAttribute);
  var taxonomies = IndexedDataframeAccess.getIndexedStringMatrixData(dataframeJs, taxonomyAttribute);
  var taxonomyIndex = IndexedDataframeAccess.getIndex(dataframeJs, taxonomyAttribute);
  var counts = IndexedDataframeAccess.getMatrixData(dataframeJs, countsAttribute);
  var taxonomiesAtLevel = Uniq.default(Belt_Array.map(taxonomies, (function (t) {
              return t.slice(0, taxonomyLevel).join("/");
            })));
  var groupedBySampleId = { };
  Belt_Array.forEachWithIndex(counts, (function (idx, count) {
          var sampleId = lookupInIndex(sampleIds, sampleIdIndex, idx);
          var taxonomy = lookupInIndex(taxonomies, taxonomyIndex, idx);
          var taxonomySlice = taxonomy.slice(0, taxonomyLevel).join("/");
          var match = Js_dict.get(groupedBySampleId, sampleId);
          var bySampleId;
          if (match !== undefined) {
            bySampleId = match;
          } else {
            var countsByTaxonomy = Js_dict.fromArray(Belt_Array.map(taxonomiesAtLevel, (function (taxonomy) {
                        return /* tuple */[
                                taxonomy,
                                0.0
                              ];
                      })));
            var bySampleId$1 = {
              sampleId: sampleId,
              countsSum: 0.0,
              countsByTaxonomy: countsByTaxonomy
            };
            groupedBySampleId[sampleId] = bySampleId$1;
            bySampleId = bySampleId$1;
          }
          bySampleId.countsSum = bySampleId.countsSum + count;
          bySampleId.countsByTaxonomy[taxonomySlice] = bySampleId.countsByTaxonomy[taxonomySlice] + count;
          return /* () */0;
        }));
  var __x = Belt_Array.map(Js_dict.values(groupedBySampleId), (function (bySampleId) {
          return {
                  sampleId: bySampleId.sampleId,
                  countsSum: bySampleId.countsSum,
                  countsByTaxonomy: bySampleId.countsByTaxonomy
                };
        }));
  return __x.sort((function (a, b) {
                var b$1 = b.sampleId;
                var a$1 = a.sampleId;
                return a$1.localeCompare(b$1) | 0;
              }));
}

function taxonomyDistributionByTaxon(dataframeJs, taxonomyLevel) {
  assertTaxonomy(dataframeJs);
  assertTaxonomyLevel(taxonomyLevel);
  var sampleIds = IndexedDataframeAccess.getIndexedStringData(dataframeJs, sampleIdAttribute);
  var sampleIdIndex = IndexedDataframeAccess.getIndex(dataframeJs, sampleIdAttribute);
  var taxonomies = IndexedDataframeAccess.getIndexedStringMatrixData(dataframeJs, taxonomyAttribute);
  var taxonomyIndex = IndexedDataframeAccess.getIndex(dataframeJs, taxonomyAttribute);
  var observationIds = IndexedDataframeAccess.getIndexedStringData(dataframeJs, observationIdAttribute);
  var observationIdIndex = IndexedDataframeAccess.getIndex(dataframeJs, observationIdAttribute);
  var counts = IndexedDataframeAccess.getMatrixData(dataframeJs, countsAttribute);
  var groupedByTaxonomy = { };
  Belt_Array.forEachWithIndex(counts, (function (idx, count) {
          var sampleId = lookupInIndex(sampleIds, sampleIdIndex, idx);
          var taxonomy = lookupInIndex(taxonomies, taxonomyIndex, idx);
          var observationId = lookupInIndex(observationIds, observationIdIndex, idx);
          var taxonomySlice = taxonomy.slice(0, taxonomyLevel).join("/");
          var match = Js_dict.get(groupedByTaxonomy, taxonomySlice);
          var byTaxonomy;
          if (match !== undefined) {
            byTaxonomy = match;
          } else {
            var countsBySampleId = Js_dict.fromArray(Belt_Array.map(sampleIds, (function (taxonomy) {
                        return /* tuple */[
                                taxonomy,
                                0.0
                              ];
                      })));
            var countsByObservationId = { };
            var byTaxonomy$1 = {
              taxonomy: taxonomySlice,
              countsSum: 0.0,
              countsBySampleId: countsBySampleId,
              countsByObservationId: countsByObservationId
            };
            groupedByTaxonomy[taxonomySlice] = byTaxonomy$1;
            byTaxonomy = byTaxonomy$1;
          }
          var countsSum = byTaxonomy.countsSum + count;
          var countBySampleId = byTaxonomy.countsBySampleId[sampleId] + count;
          var countByObservationId = Belt_Option.getWithDefault(Js_dict.get(byTaxonomy.countsByObservationId, observationId), 0.0) + count;
          byTaxonomy.countsSum = countsSum;
          byTaxonomy.countsBySampleId[sampleId] = countBySampleId;
          byTaxonomy.countsByObservationId[observationId] = countByObservationId;
          return /* () */0;
        }));
  var __x = Belt_Array.map(Js_dict.values(groupedByTaxonomy), (function (bySampleId) {
          var samplesTotal = Belt_Array.keep(Js_dict.values(bySampleId.countsBySampleId), (function (counts) {
                  return counts > 0.0;
                })).length;
          var observationsTotal = Js_dict.values(bySampleId.countsByObservationId).length;
          return {
                  taxonomy: bySampleId.taxonomy,
                  countsSum: bySampleId.countsSum,
                  samplesTotal: samplesTotal,
                  observationsTotal: observationsTotal,
                  countsBySampleId: bySampleId.countsBySampleId,
                  countsByObservationId: bySampleId.countsByObservationId
                };
        }));
  return __x.sort((function (a, b) {
                var b$1 = b.taxonomy;
                var a$1 = a.taxonomy;
                return a$1.localeCompare(b$1) | 0;
              }));
}

function taxonomyHierarchyDistribution(dataframeJs, rootLabel, filterCount) {
  assertTaxonomy(dataframeJs);
  var sampleIds = IndexedDataframeAccess.getIndexedStringData(dataframeJs, sampleIdAttribute);
  var sampleIdIndex = IndexedDataframeAccess.getIndex(dataframeJs, sampleIdAttribute);
  var taxonomies = IndexedDataframeAccess.getIndexedStringMatrixData(dataframeJs, taxonomyAttribute);
  var taxonomyIndex = IndexedDataframeAccess.getIndex(dataframeJs, taxonomyAttribute);
  var counts = IndexedDataframeAccess.getMatrixData(dataframeJs, countsAttribute);
  var rootLabel$1 = Belt_Option.getWithDefault(rootLabel, "Dataframe");
  var filterCount$1 = Belt_Option.getWithDefault(filterCount, (function (param) {
          return true;
        }));
  var hierarchy = {
    label: rootLabel$1,
    value: 0.0,
    children: /* array */[]
  };
  var addCount = function (_node, _taxonomy, count) {
    while(true) {
      var taxonomy = _taxonomy;
      var node = _node;
      if (taxonomy) {
        var label = taxonomy[0];
        if (label === "") {
          return /* () */0;
        } else {
          var child = Belt_Array.getBy(node.children, (function(label){
              return function (child) {
                return child.label === label;
              }
              }(label)));
          var child$1;
          if (child !== undefined) {
            child$1 = child;
          } else {
            var newChild = {
              label: label,
              value: 0.0,
              children: /* array */[]
            };
            node.children.push(newChild);
            child$1 = newChild;
          }
          child$1.value = child$1.value + count;
          _taxonomy = taxonomy[1];
          _node = child$1;
          continue ;
        }
      } else {
        return /* () */0;
      }
    };
  };
  Belt_Array.forEachWithIndex(counts, (function (idx, count) {
          var sampleId = lookupInIndex(sampleIds, sampleIdIndex, idx);
          var taxonomy = lookupInIndex(taxonomies, taxonomyIndex, idx);
          var match = Curry._1(filterCount$1, {
                sampleId: sampleId,
                taxonomy: taxonomy
              });
          if (match) {
            hierarchy.value = hierarchy.value + count;
            addCount(hierarchy, Curry._1(Import.List.fromArray, taxonomy), count);
            return /* () */0;
          } else {
            return /* () */0;
          }
        }));
  var toHierarchy = function (node) {
    return {
            label: node.label,
            value: node.value,
            children: Belt_Array.map(node.children, toHierarchy)
          };
  };
  return toHierarchy(hierarchy);
}

function taxonomyHierarchyForSampleId(dataframeJs, sampleId) {
  assertTaxonomy(dataframeJs);
  var sampleIdMatches = function (props) {
    return props.sampleId === sampleId;
  };
  return taxonomyHierarchyDistribution(dataframeJs, sampleId, sampleIdMatches);
}

function allSampleIds(dataframeJs) {
  return IndexedDataframeAccess.getIndexedStringData(dataframeJs, sampleIdAttribute);
}

function allObservationIds(dataframeJs) {
  return IndexedDataframeAccess.getIndexedStringData(dataframeJs, observationIdAttribute);
}

function allSampleMetadataAttributes(dataframeJs) {
  var __x = Belt_Array.map(Belt_Array.keep(IndexedDataframeAccess.getIndexedAttributes(dataframeJs), (function (attribute) {
              return attribute.startsWith(sampleMetadataAttributePrefix);
            })), (function (attribute) {
          return attribute.replace(sampleMetadataAttributePrefix, "");
        }));
  return Belt_Array.map(__x.sort((function (a, b) {
                    return a.localeCompare(b) | 0;
                  })), (function (attribute) {
                return {
                        name: attribute
                      };
              }));
}

function allSampleMetadata(dataframeJs) {
  var sampleIds = IndexedDataframeAccess.getIndexedStringData(dataframeJs, sampleIdAttribute);
  var sampleIdIndex = IndexedDataframeAccess.getIndex(dataframeJs, sampleIdAttribute);
  var counts = IndexedDataframeAccess.getMatrixData(dataframeJs, countsAttribute);
  var sampleMetadataAttributes = allSampleMetadataAttributes(dataframeJs);
  var sampleMetadata = Js_dict.fromArray(Belt_Array.map(sampleMetadataAttributes, (function (attribute) {
              var dataframeAttribute = sampleMetadataAttributePrefix + attribute.name;
              var data = IndexedDataframeAccess.getIndexedData(dataframeJs, dataframeAttribute);
              return /* tuple */[
                      attribute.name,
                      data
                    ];
            })));
  var sampleMetadataIndex = Js_dict.fromArray(Belt_Array.map(sampleMetadataAttributes, (function (attribute) {
              var dataframeAttribute = sampleMetadataAttributePrefix + attribute.name;
              var index = IndexedDataframeAccess.getIndex(dataframeJs, dataframeAttribute);
              return /* tuple */[
                      attribute.name,
                      index
                    ];
            })));
  var groupedBySampleId = { };
  Belt_Array.forEachWithIndex(counts, (function (idx, _count) {
          var sampleId = lookupInIndex(sampleIds, sampleIdIndex, idx);
          var match = Js_dict.get(groupedBySampleId, sampleId);
          if (match !== undefined) {
            return /* () */0;
          } else {
            var metadata = Js_dict.fromArray(Belt_Array.map(sampleMetadataAttributes, (function (attribute) {
                        var sampleMetadataIdx = Belt_Array.getExn(sampleMetadataIndex[attribute.name], idx);
                        var value = Belt_Array.getExn(sampleMetadata[attribute.name], sampleMetadataIdx);
                        return /* tuple */[
                                attribute.name,
                                value
                              ];
                      })));
            groupedBySampleId[sampleId] = metadata;
            return /* () */0;
          }
        }));
  return groupedBySampleId;
}

export {
  hasTaxonomicMetadata ,
  taxonomyDistributionBySample ,
  taxonomyDistributionByTaxon ,
  taxonomyHierarchyDistribution ,
  taxonomyHierarchyForSampleId ,
  allSampleIds ,
  allObservationIds ,
  allSampleMetadataAttributes ,
  allSampleMetadata ,
  
}
/* Import Not a pure module */
