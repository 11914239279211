import React, { Component } from "react";

import styles from "./style.css";

export class VerticalFormFields extends Component {
  render() {
    return <div className={styles.verticalFields}>{this.props.children}</div>;
  }
}

export class VerticalFormField extends Component {
  render() {
    return <div className={styles.verticalField}>{this.props.children}</div>;
  }
}

export class VerticalFormActions extends Component {
  render() {
    return <div className={styles.verticalActions}>{this.props.children}</div>;
  }
}
