import React, { Component } from "react";
import PropTypes from "prop-types";

export default class Krona extends Component {
  static propTypes = {
    data: PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      children: PropTypes.arrayOf(PropTypes.shape({})).isRequired
    })
  };

  hierarchyToXML(hierarchy) {
    const createElement = (...args) => document.createElement(...args);
    const krona = createElement("krona");
    krona.setAttribute("collapse", "false");
    const attributes = createElement("attributes");
    attributes.setAttribute("magnitude", "observations");
    krona.appendChild(attributes);
    const attribute = createElement("attribute");
    attribute.setAttribute("display", "Observations");
    attribute.innerHTML = "observations";
    attributes.appendChild(attribute);
    const datasets = createElement("datasets");
    const dataset = createElement("dataset");
    dataset.innerHTML = "BIOM";
    datasets.appendChild(dataset);
    krona.appendChild(datasets);
    function appendNode(dataItem) {
      const node = createElement("node");
      const observations = createElement("observations");
      const val = createElement("val");
      val.innerHTML = dataItem.value;
      observations.appendChild(val);
      node.appendChild(observations);
      node.setAttribute("name", dataItem.label);
      dataItem.children.forEach(child => {
        node.appendChild(appendNode(child));
      });
      return node;
    }
    krona.appendChild(appendNode(hierarchy));

    const result = new XMLSerializer().serializeToString(krona);
    return result;
  }

  renderKronaChart() {
    if (!this.kronaSlot) return;

    const xml = this.hierarchyToXML(this.props.data);
    this.xml = xml; // Attach to component instance for test assertions

    this.kronaSlot.innerHTML = "";
    const object = document.createElement("object");
    object.setAttribute("data", "/krona.html");
    object.setAttribute("type", "text/html");
    object.style.width = "100%";
    object.style.height = "100%";

    function onKronaScriptLoad() {
      object.contentDocument.onload(); // Kick-off Krona rendering
    }

    function onObjectLoad() {
      const dataSlot = object.contentDocument.getElementById("dataSlot");
      const scriptSlot = object.contentDocument.getElementById("scriptSlot");
      dataSlot.innerHTML = xml;
      scriptSlot.setAttribute("src", "/krona-2.0.js");
      scriptSlot.onload = onKronaScriptLoad;
    }
    object.onload = onObjectLoad;

    this.kronaSlot.style.height = "700px";
    this.kronaSlot.appendChild(object);
  }

  componentDidMount() {
    this.renderKronaChart();
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.renderKronaChart();
    }
  }

  render() {
    return <div ref={el => (this.kronaSlot = el)} data-testid="krona" />;
  }
}
