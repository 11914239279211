

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Import from "../../features/import.bs.js";
import * as TermJs from "./termJs.bs.js";
import * as TypeJs from "./typeJs.bs.js";
import * as Caml_obj from "bs-platform/lib/es6/caml_obj.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";

function inferTermTypeRe(_term, type_) {
  while(true) {
    var term = _term;
    if (typeof term === "number") {
      return type_;
    } else {
      var variant = term[0];
      if (variant >= 365180285) {
        if (variant >= 847309490) {
          if (variant !== 848054398) {
            if (variant !== 925978388) {
              return type_;
            } else {
              var term$1 = term[1];
              if (typeof type_ === "number" || type_[0] !== 216537025) {
                return /* `Nullable */[
                        216537025,
                        inferTermTypeRe(term$1, /* Type_not_available */-883458824)
                      ];
              } else {
                return /* `Nullable */[
                        216537025,
                        inferTermTypeRe(term$1, type_[1])
                      ];
              }
            }
          } else {
            var match = term[1];
            if (match && type_ === -883458824) {
              return /* `List */[
                      848054398,
                      inferTermTypeRe(match[0], /* Type_not_available */-883458824)
                    ];
            } else {
              return type_;
            }
          }
        } else if (variant !== 737456202) {
          if (variant >= 847309489 && type_ === -883458824) {
            var fieldsWithTypes = Curry._2(Import.List.map, term[1], (function (tuple) {
                    var match = tuple[0];
                    if (typeof match === "number" || match[0] !== 699531161) {
                      return /* tuple */[
                              "",
                              /* Type_not_available */-883458824
                            ];
                    } else {
                      return /* tuple */[
                              match[1],
                              inferTermTypeRe(tuple[1], /* Type_not_available */-883458824)
                            ];
                    }
                  }));
            return /* `Record */[
                    847309489,
                    /* tuple */[
                      fieldsWithTypes,
                      undefined
                    ]
                  ];
          } else {
            return type_;
          }
        } else {
          return /* Bool */737456202;
        }
      } else if (variant >= 3405102) {
        if (variant !== 3654863) {
          if (variant >= 365180284) {
            return /* Float */365180284;
          } else {
            return type_;
          }
        } else {
          return /* Int */3654863;
        }
      } else if (variant !== -976970511) {
        if (variant >= 3405101) {
          var match$1 = term[1].data;
          if (match$1 !== undefined && type_ === -883458824) {
            _term = /* `Record */[
              847309489,
              match$1
            ];
            continue ;
          } else {
            return type_;
          }
        } else {
          return type_;
        }
      } else {
        return /* String */-976970511;
      }
    }
  };
}

function inferTermType(termJs, typeJs) {
  var term = TermJs.convertFromJs(termJs);
  var type_ = TypeJs.convertFromJs(typeJs);
  return Curry._1(TypeJs.convertToJs, inferTermTypeRe(term, type_));
}

function isRecordType(typeJs) {
  var match = TypeJs.convertFromJs(typeJs);
  if (typeof match === "number") {
    return false;
  } else {
    return match[0] === 847309489;
  }
}

function isListOfRecordsType(typeJs) {
  var match = TypeJs.convertFromJs(typeJs);
  if (typeof match === "number" || match[0] !== 848054398) {
    return false;
  } else {
    var match$1 = match[1];
    if (typeof match$1 === "number") {
      return false;
    } else {
      return match$1[0] === 847309489;
    }
  }
}

function areTypesEqual(type1Js, type2Js) {
  var type1 = TypeJs.convertFromJs(type1Js);
  var type2 = TypeJs.convertFromJs(type2Js);
  return Caml_obj.caml_equal(type1, type2);
}

function naturalOrderForType(typeJs) {
  var _type_ = TypeJs.convertFromJs(typeJs);
  while(true) {
    var type_ = _type_;
    if (typeof type_ === "number") {
      if (type_ >= 737157829) {
        if (type_ >= 748194550) {
          if (type_ >= 785140586) {
            if (type_ >= 936769581) {
              return 7;
            } else {
              return 1;
            }
          } else if (type_ >= 758940238) {
            return 8;
          } else {
            return 9;
          }
        } else if (type_ >= 737456202) {
          return 10;
        } else {
          return 15;
        }
      } else if (type_ !== -883458824) {
        if (type_ >= 365180284) {
          if (type_ >= 642196241) {
            return 14;
          } else {
            return 12;
          }
        } else if (type_ >= 3654863) {
          return 11;
        } else {
          return 13;
        }
      } else {
        return 2;
      }
    } else {
      var variant = type_[0];
      if (variant >= 848054398) {
        if (variant >= 937413395) {
          if (variant >= 951904681) {
            return 6;
          } else {
            return 3;
          }
        } else if (variant >= 925483562) {
          return 5;
        } else {
          return 16;
        }
      } else if (variant !== 216537025) {
        if (variant >= 847309489) {
          return 1000;
        } else {
          return 4;
        }
      } else {
        _type_ = type_[1];
        continue ;
      }
    }
  };
}

var string = Curry._1(TypeJs.convertToJs, /* String */-976970511);

var $$float = Curry._1(TypeJs.convertToJs, /* Float */365180284);

var $$int = Curry._1(TypeJs.convertToJs, /* Int */3654863);

var bool = Curry._1(TypeJs.convertToJs, /* Bool */737456202);

var $$char = Curry._1(TypeJs.convertToJs, /* Char */748194550);

var notAvailable = Curry._1(TypeJs.convertToJs, /* Type_not_available */-883458824);

var biom = Curry._1(TypeJs.convertToJs, /* Biom */737157829);

var newick = Curry._1(TypeJs.convertToJs, /* Newick */642196241);

function optional(type_) {
  return Curry._1(TypeJs.convertToJs, /* `Nullable */[
              216537025,
              TypeJs.convertFromJs(type_)
            ]);
}

function list(type_) {
  return Curry._1(TypeJs.convertToJs, /* `List */[
              848054398,
              TypeJs.convertFromJs(type_)
            ]);
}

function record(labels, types) {
  var __x = Belt_Array.zip(labels, types);
  var contents = Curry._1(Import.List.fromArray, Belt_Array.map(__x, (function (param) {
              return /* tuple */[
                      param[0],
                      TypeJs.convertFromJs(param[1])
                    ];
            })));
  return Curry._1(TypeJs.convertToJs, /* `Record */[
              847309489,
              /* tuple */[
                contents,
                undefined
              ]
            ]);
}

var Types = {
  string: string,
  $$float: $$float,
  $$int: $$int,
  bool: bool,
  $$char: $$char,
  notAvailable: notAvailable,
  biom: biom,
  newick: newick,
  optional: optional,
  list: list,
  record: record
};

export {
  inferTermTypeRe ,
  inferTermType ,
  isRecordType ,
  isListOfRecordsType ,
  areTypesEqual ,
  naturalOrderForType ,
  Types ,
  
}
/* string Not a pure module */
