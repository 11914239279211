

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Import from "../features/import.bs.js";
import * as Std$Bolt from "bolt/./std.bs.js";
import * as ReactIntl from "react-intl";
import * as ReactExtras from "../lib/solvuu/ReactExtras.bs.js";
import * as TableElements from "../table/TableElements.bs.js";
import * as Caml_primitive from "bs-platform/lib/es6/caml_primitive.js";
import * as Root$SolvuuApi from "solvuu-api/./root.bs.js";
import * as VerticalLayout from "../components/Layout/VerticalLayout.bs.js";
import * as ReactTruncate from "react-truncate";
import * as VennDiagramJs from "./VennDiagram.js";
import * as Layout from "components/Layout";
import * as TableVennDiagramCss from "table/TableVennDiagram.css";

function ofRecord(record) {
  return Curry._1(Std$Bolt.Res.join, Curry._2(Root$SolvuuApi.Venn_diagram.T.of_record, record, (function (subsets) {
                    return Curry._2(Std$Bolt.Res.map, Curry._1(Std$Bolt.Res.all, Curry._2(Import.List.map, subsets, (function (record) {
                                          return Curry._2(Root$SolvuuApi.Venn_diagram.T.Subset.of_record, record, (function (columns, items, size) {
                                                        return {
                                                                columns: columns,
                                                                items: items,
                                                                size: size
                                                              };
                                                      }));
                                        }))), (function (subsets) {
                                  return subsets;
                                }));
                  })));
}

function ofTerm(term) {
  if (typeof term === "number" || term[0] !== 847309489) {
    return /* `Error */[
            106380200,
            /* Expected_record */-756637544
          ];
  } else {
    var res = ofRecord(term[1]);
    return Curry._2(Std$Bolt.Res.map_error, res, (function (e) {
                  return /* `Record */[
                          847309489,
                          e
                        ];
                }));
  }
}

var Props = {
  ofRecord: ofRecord,
  ofTerm: ofTerm
};

function makeProps(subsets, param) {
  var thisData = Curry._1(Import.List.toArray, Curry._2(Import.List.map, subsets, (function (subset) {
              return {
                      sets: Curry._1(Import.List.toArray, subset.columns),
                      size: subset.size
                    };
            })));
  return {
          data: thisData
        };
}

var make = VennDiagramJs.default;

function VennDiagram$VennSummaryItems(Props) {
  var items = Props.items;
  var match = React.useReducer((function (state, action) {
          return {
                  truncate: !state.truncate
                };
        }), {
        truncate: true
      });
  var send = match[1];
  var handleToggleClick = function (param) {
    return Curry._1(send, /* Toggle */0);
  };
  var ellipsis = React.createElement(React.Fragment, undefined, "… ", React.createElement("button", {
            className: TableVennDiagramCss.default.itemsToggleButton,
            type: "button",
            onClick: handleToggleClick
          }, React.createElement(ReactIntl.FormattedMessage, {
                id: "table.vennDiagram.summaryItems.showAllItems"
              })));
  var text = Curry._1(Import.List.toArray, items).join(" ");
  var match$1 = match[0].truncate;
  if (match$1) {
    return React.createElement(ReactTruncate.default, {
                lines: 1,
                ellipsis: ellipsis,
                children: text
              });
  } else {
    return text;
  }
}

function VennDiagram$VennSummaryTable(Props) {
  var subsets = Props.subsets;
  var header = React.createElement("tr", undefined, React.createElement(TableElements.HeaderElement.make, {
            testId: "set",
            children: React.createElement(ReactIntl.FormattedMessage, {
                  id: "table.vennDiagram.summaryItems.headers.set"
                })
          }), React.createElement(TableElements.HeaderElement.make, {
            testId: "size",
            children: React.createElement(ReactIntl.FormattedMessage, {
                  id: "table.vennDiagram.summaryItems.headers.size"
                })
          }), React.createElement(TableElements.HeaderElement.make, {
            testId: "items",
            children: React.createElement(ReactIntl.FormattedMessage, {
                  id: "table.vennDiagram.summaryItems.headers.items"
                })
          }));
  var rows = ReactExtras.listToElement(Curry._2(Import.List.map, Curry._2(Import.List.sort, Curry._2(Import.List.keep, subsets, (function (subset) {
                      return subset.size > 0;
                    })), (function (a, b) {
                  return Caml_primitive.caml_int_compare(Curry._1(Import.List.length, b.columns), Curry._1(Import.List.length, a.columns));
                })), (function (subset) {
              var key = VennDiagramJs.setToString(Curry._1(Import.List.toArray, subset.columns));
              return React.createElement(TableElements.RowElement.make, {
                          testId: key,
                          children: null,
                          key: key
                        }, React.createElement(TableElements.CellElement.make, {
                              testId: "set",
                              children: key
                            }), React.createElement(TableElements.CellElement.make, {
                              testId: "size",
                              children: String(subset.size)
                            }), React.createElement(TableElements.CellElement.make, {
                              testId: "items",
                              children: React.createElement(VennDiagram$VennSummaryItems, {
                                    items: subset.items
                                  })
                            }));
            })));
  return React.createElement(TableElements.TableElement.make, {
              children: null
            }, React.createElement("thead", undefined, header), React.createElement("tbody", undefined, rows));
}

function make$1(subsets) {
  var plot = React.createElement(make, makeProps(subsets, /* () */0));
  var summaryTable = React.createElement(VennDiagram$VennSummaryTable, {
        subsets: subsets
      });
  return React.createElement(Layout.VerticalLayout, VerticalLayout.makeProps(/* x2 */26810, null, /* () */0), React.createElement(Layout.VerticalLayoutItem, {
                  children: plot
                }), React.createElement(Layout.VerticalLayoutItem, {
                  children: summaryTable
                }));
}

export {
  Props ,
  make$1 as make,
  
}
/* make Not a pure module */
