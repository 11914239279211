import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Select from "react-select";

import Krona from "components/Krona";

import styles from "./style.css";

class Chart extends Component {
  static propTypes = {
    chartData: PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      children: PropTypes.arrayOf(PropTypes.shape({})).isRequired
    }),
    sampleId: PropTypes.string,
    sampleIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    navigateToSample: PropTypes.func.isRequired
  };

  renderBlank() {
    return (
      <div className={styles.blank}>
        <FormattedMessage id="biom.taxonomicAnalysis.kronaChart.blank" />
      </div>
    );
  }

  renderKrona() {
    const { sampleId, chartData } = this.props;

    // Adding key prop to force re-initialization of Krona chart
    // when sampleId changes
    return <Krona data={chartData} key={sampleId} />;
  }

  onSampleIdChange = option => {
    this.props.navigateToSample(option.value);
  };

  render() {
    const { sampleId, sampleIds } = this.props;

    const options = sampleIds.map(id => ({ label: id, value: id }));

    return (
      <div className={styles.root}>
        <div className={styles.controls}>
          <Select
            name="sampleId"
            value={sampleId}
            onChange={this.onSampleIdChange}
            options={options}
            clearable={false}
            placeholder={
              <FormattedMessage id="biom.taxonomicAnalysis.kronaChart.sampleSelect.placeholder" />
            }
          />
        </div>
        {sampleId ? this.renderKrona() : this.renderBlank()}
      </div>
    );
  }
}

export default Chart;
