import React, { Component } from "react";
import { ResponsiveContainer } from "recharts";

class TestResponsiveContainer extends Component {
  render() {
    const width = Number.isFinite(this.props.width) ? this.props.width : 1000;
    const height = Number.isFinite(this.props.height) ? this.props.height : 500;
    return React.cloneElement(this.props.children, { width, height });
  }
}

export default (process.env.NODE_ENV === "test"
  ? TestResponsiveContainer
  : ResponsiveContainer);
