import React, { Component } from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import Beforeunload from "react-beforeunload";

import { parseReactRouterLocation } from "features/routing";

/**
 * ExitPrompt component will show a confirmation alert if user tries to
 * close, refresh or change the location of current page.
 * Use the when prop to control whether the prompt is enabled.
 */

function defaultHasLocationChanged(oldLocation, newLocation) {
  return oldLocation.pathname !== newLocation.pathname;
}
class ExitPrompt extends Component {
  static propTypes = {
    when: PropTypes.bool.isRequired,
    onUnload: PropTypes.bool.isRequired,
    onLocationChange: PropTypes.bool.isRequired,
    hasLocationChanged: PropTypes.func.isRequired,
    message: PropTypes.string,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  static defaultProps = {
    when: true,
    onUnload: true,
    onLocationChange: true,
    hasLocationChanged: defaultHasLocationChanged
  };

  onBeforeunload = event => {
    const shouldPromptOnUnload = this.props.when && this.props.onUnload;

    if (shouldPromptOnUnload) {
      event.preventDefault();
      return this.props.message || "";
    }
  };

  onLocationChangePrompt = location => {
    if (!this.props.when) return true;
    if (!this.props.onLocationChange) return true;

    return this.props.hasLocationChanged(
      parseReactRouterLocation(this.props.location),
      parseReactRouterLocation(location)
    )
      ? this.props.message || ""
      : true;
  };

  componentDidMount() {
    if (this.props.onLocationChange) {
      this.unblock = this.props.history.block(this.onLocationChangePrompt);
    } else {
      this.unblock = () => {};
    }
  }

  componentWillUnmount() {
    this.unblock();
  }

  render() {
    return <Beforeunload onBeforeunload={this.onBeforeunload} />;
  }
}

export default withRouter(ExitPrompt);
