import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { max } from "d3-array";

import * as FormattedBytes from "components/FormattedBytes/FormattedBytes.bs.js";
import * as ClientTime from "solvuu-client/./time.bs.js";
import ResponsiveContainer from "components/ResponsiveContainer";

import styles from "./style.css";

export const BYTES = "bytes";
export const FRACTION = "fraction";

export default class Graph extends Component {
  static propTypes = {
    series: PropTypes.array.isRequired,
    limit: PropTypes.number,
    startAtTimestamp: PropTypes.number,
    color: PropTypes.string.isRequired,
    valueType: PropTypes.oneOf([FRACTION, BYTES]).isRequired
  };

  render() {
    const {
      series,
      limit,
      startAtTimestamp = 0,
      valueType,
      color
    } = this.props;

    function formatFraction(fraction) {
      return fraction.toFixed(2);
    }

    function formatValue(value) {
      if (valueType === BYTES) {
        return FormattedBytes.toStringHum(value);
      }
      if (valueType === FRACTION) {
        return formatFraction(value);
      }
      return value;
    }

    function formatTimestamp(epochTimestamp) {
      const deltaSeconds = Math.floor(epochTimestamp - startAtTimestamp);
      return ClientTime.Span.to_string_HHMMSS(deltaSeconds);
    }

    function formatTooltipTimestamp(epochTimestamp) {
      return <>{formatTimestamp(epochTimestamp)} after creation</>;
    }

    function formatTooltipValue(value) {
      if (valueType === BYTES) {
        return FormattedBytes.toStringHum(value);
      }
      if (valueType === FRACTION) {
        return value.toFixed(5);
      }
      return value;
    }

    // If the limit is greater than the series' value domain, increase the domain
    // so that the limit line is visible.
    let yAxisDomain = undefined;
    let maxValue = max(series, d => d.value);
    if (limit && maxValue < limit) {
      maxValue = limit;
      yAxisDomain = [0, limit];
    }

    // When y-axis displays byte values, the default ticks have unappealing values when formatted (e.g. 268.1MB).
    // Replace the default ticks with four evenly-spaced ones.
    let yAxisTicks = undefined;
    if (valueType === BYTES) {
      yAxisTicks = [
        0,
        Math.floor(maxValue * 0.25),
        Math.floor(maxValue * 0.5),
        Math.floor(maxValue * 0.75),
        maxValue
      ];
    }

    const originalMinTimestamp = startAtTimestamp;
    const originalMaxTimestamp = max(series, d => d.timestamp);
    const xAxisTicks = [originalMinTimestamp, originalMaxTimestamp];

    // If there's a few data points, extend the timestamp domain so that there appears to be a minimum of data points.
    // This has the effect of pushing small area charts to the left.
    const minSeriesLength = 10;
    let maxTimestamp = originalMaxTimestamp;
    if (series.length < minSeriesLength) {
      const fillerDataLength = minSeriesLength - series.length;
      maxTimestamp = originalMaxTimestamp + fillerDataLength * 30; // 30 seconds between data points
    }
    const xAxisDomain = [originalMinTimestamp, maxTimestamp];

    const animate = false;
    const margin = { top: 0, right: 0, bottom: 0, left: 0 };

    return (
      <div
        className={styles.graphFilled}
        data-testid={this.props["data-testid"]}
      >
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart data={series} margin={margin} isAnimationActive={animate}>
            <CartesianGrid strokeDasharray="3 3" />
            <Area
              dataKey="value"
              fill={color}
              stroke={color}
              isAnimationActive={animate}
            />
            <YAxis
              dataKey="value"
              type="number"
              domain={yAxisDomain}
              tickFormatter={formatValue}
              ticks={yAxisTicks}
              width={70}
            />
            <XAxis
              dataKey="timestamp"
              type="number"
              domain={xAxisDomain}
              ticks={xAxisTicks}
              interval="preserveStartEnd"
              height={20}
              tickFormatter={formatTimestamp}
            />
            <Tooltip
              formatter={formatTooltipValue}
              labelFormatter={formatTooltipTimestamp}
            />
            {limit && (
              <ReferenceLine y={limit} stroke="red" strokeDasharray="5 1" />
            )}
          </AreaChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

export class EmptyGraph extends Component {
  render() {
    return <div className={styles.graphEmpty}>No data</div>;
  }
}
