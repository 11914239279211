

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Route from "../Route.bs.js";
import * as React from "react";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

function Link(Props) {
  var _to = Props.to;
  var className = Props.className;
  var ariaCurrent = Props.ariaCurrent;
  var onClick = Props.onClick;
  var _type = Props.type;
  var tabIndex = Props.tabIndex;
  var children = Props.children;
  var onClick$1 = function ($$event) {
    if (!$$event.defaultPrevented && $$event.button === 0 && !$$event.altKey && !$$event.ctrlKey && !$$event.metaKey && !$$event.shiftKey) {
      $$event.preventDefault();
      Belt_Option.forEach(onClick, (function (f) {
              return Curry._1(f, /* () */0);
            }));
      if (typeof _to === "number" && _to === 959246196) {
        return /* () */0;
      } else {
        return Route.push(_to);
      }
    } else {
      return 0;
    }
  };
  var urlString = Route.toUrlString(Route.toUrl(_to));
  var tmp = {
    tabIndex: tabIndex,
    href: urlString,
    onClick: onClick$1
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (_type !== undefined) {
    tmp.type = Caml_option.valFromOption(_type);
  }
  return React.cloneElement(React.createElement("a", tmp, children), {
              "aria-current": ariaCurrent
            });
}

var make = Link;

export {
  make ,
  
}
/* Route Not a pure module */
