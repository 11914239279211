/**
 * Handles the file format inference.
 * Used prior to the file upload, and when visualizing files of unknown formats.
 *
 * Notes:
 * * the files are identified by an unique ID string
 * * the server performs format inference based on the filename
 * and partial file content.
 */

import { combineReducers } from "redux";
import { omit, values } from "ramda";

import { createOperation, createReducer } from "features/utils";
import { formats } from "lib/solvuu/formatUtils";
import * as SolvuuApiWrapper from "lib/solvuu/solvuuApiWrapper.bs";

// CONSTANTS

export const inferFormatOperation = createOperation("ENTITIES/INFER_FORMAT");
export const CLEAR_FORMAT = "ENTITIES/CLEAR_FORMAT";
export const INFERENCE_BYTES_LIMIT = 10 * 1024;

// ACTIONS

export function inferFileFormat(id, file) {
  const { start, success, failure } = inferFormatOperation.actionCreators;

  return async function(dispatch, getState, { solvuuApi }) {
    dispatch(start({ id }));
    try {
      const fileHead = await readFileHead(file);
      const response = await solvuuApi.inferFormat(file.name, fileHead);
      if (!response.ok) {
        throw new Error(response.data);
      }
      const format = SolvuuApiWrapper.Js.formatInferenceResponseToFormat(
        response
      );
      dispatch(success({ id, format }));
    } catch (e) {
      dispatch(failure({ id }));
    }
  };
}

export function clearFormat(id) {
  return {
    type: CLEAR_FORMAT,
    payload: { id }
  };
}

// Internal

async function readFileHead(file) {
  const slice = Blob.prototype.slice || Blob.prototype.webkitSlice;
  const slicedFile = slice.call(file, 0, INFERENCE_BYTES_LIMIT);
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onload = event => resolve(event.target.result);
    reader.readAsText(slicedFile);
  });
}

// INITIAL STATE

export const initialState = {};

const initialValueState = {
  format: formats.string,
  meta: {
    inferFormat: inferFormatOperation.initialState
  }
};

// SELECTORS

export function inferFormatSelector(state, id) {
  const formatValue = formatValueSelector(state, id);
  const {
    format,
    meta: { inferFormat }
  } = formatValue;
  return {
    format,
    meta: { inferFormat }
  };
}

// Internal

function formatValueSelector(state, id) {
  return state.entities.formats[id] || initialValueState;
}

// REDUCERS

const formatReducer = createReducer(initialValueState.format, {
  [inferFormatOperation.actionTypes.SUCCESS]: (state, action) =>
    action.payload.format
});

const valueReducer = combineReducers({
  format: formatReducer,
  meta: combineReducers({
    inferFormat: inferFormatOperation.reducer
  })
});

const updateValueActions = values(inferFormatOperation.actionTypes);

export default function reducer(state = initialState, action) {
  if (updateValueActions.includes(action.type)) {
    const { id } = action.payload;
    return {
      ...state,
      [id]: valueReducer(state[id], action)
    };
  } else if (action.type === CLEAR_FORMAT) {
    const { id } = action.payload;
    return omit([id], state);
  } else {
    return state;
  }
}
