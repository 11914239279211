import { CognitoUserAttribute, CognitoUser } from "amazon-cognito-identity-js";
import { combineReducers } from "redux";
import { push } from "connected-react-router";

import {
  createOperation,
  createReducer,
  fallbackReducer
} from "features/utils";
import { locationSelector } from "features/routing";
import { getUserPool } from "features/aws.js";
import * as Paths from "Paths";

export const registerOperation = createOperation("REGISTRATION/REGISTER");
export const confirmOperation = createOperation("REGISTRATION/CONFIRM");
export const resendConfirmationOperation = createOperation(
  "REGISTRATION/RESEND_CONFIRMATION"
);
const REGISTRATION_FORM_CHANGE = "registration/REGISTRATION_FORM_CHANGE";

export function register() {
  const { start, success, failure } = registerOperation.actionCreators;

  return async function(dispatch, getState) {
    dispatch(start());

    const form = registrationSelector(getState()).form;
    // Client validation
    const validationErrors = validateForm(form);
    if (validationErrors.length) {
      dispatch(failure(validationErrors));
      return;
    }

    // Perform the request
    const attributeList = [];
    const dataEmail = {
      Name: "email",
      Value: form.email
    };
    const dataName = {
      Name: "name",
      Value: form.name
    };
    const attributeEmail = new CognitoUserAttribute(dataEmail);
    const attributeName = new CognitoUserAttribute(dataName);
    attributeList.push(attributeEmail);
    attributeList.push(attributeName);

    const userPool = getUserPool();
    userPool.signUp(form.username, form.password, attributeList, null, function(
      err,
      result
    ) {
      if (err && !isEmailConfirmationDisabledError(err)) {
        const errors = extractErrorsFromCognitoError(err);
        dispatch(failure(errors));
        return;
      }
      dispatch(success());
    });
  };
}

function validateForm(form) {
  let errors = [];
  if (form.password !== form.passwordConfirmation) {
    errors.push({
      id: "registration.errors.passwordConfirmation.notMatching",
      field: "passwordConfirmation"
    });
  }
  if (!form.acceptTerms) {
    errors.push({
      id: "registration.errors.acceptTerms.notAccepted",
      field: "acceptTerms"
    });
  }
  return errors;
}

function isEmailConfirmationDisabledError(error) {
  return (
    error &&
    error.code === "UserLambdaValidationException" &&
    error.message.includes("EMAIL_CONFIRMATION_DISABLED")
  );
}

function extractErrorsFromCognitoError(error) {
  const errors = [];
  if (error.code === "InvalidPasswordException") {
    errors.push({ message: error.message, field: "password" });
  }
  if (error.code === "UsernameExistsException") {
    errors.push({ message: error.message, field: "username" });
  }
  if (!errors.length && error.message) {
    errors.push({ message: error.message, field: null });
  }
  if (!errors.length) {
    errors.push({ id: "registration.errors.unknown", field: null });
  }

  return errors;
}

export function confirmRegistration() {
  const { start, success, failure } = confirmOperation.actionCreators;

  return async function(dispatch, getState) {
    const {
      query: { username, code }
    } = locationSelector(getState());
    const userPool = getUserPool();
    const userData = { Username: username || "", Pool: userPool };
    const cognitoUser = new CognitoUser(userData);

    // Perform the request
    dispatch(start());
    cognitoUser.confirmRegistration(code || "", true, function(err, result) {
      if (err) {
        const errors = extractErrorsFromCognitoError(err);
        dispatch(failure(errors));
        return;
      }
      dispatch(success());
      dispatch(push(Paths.login));
    });
  };
}

export function resendConfirmation(providedUsername) {
  const {
    start,
    success,
    failure
  } = resendConfirmationOperation.actionCreators;

  return async function(dispatch, getState) {
    const { query } = locationSelector(getState());
    const username = providedUsername || query.username;
    const userPool = getUserPool();
    const userData = { Username: username || "", Pool: userPool };
    const cognitoUser = new CognitoUser(userData);

    // Perform the request
    dispatch(start());
    cognitoUser.resendConfirmationCode(function(err, result) {
      if (err) {
        const errors = extractErrorsFromCognitoError(err);
        dispatch(failure(errors));
        return;
      }
      dispatch(success());
    });
  };
}

export function registrationFormChange(field, value) {
  return {
    type: REGISTRATION_FORM_CHANGE,
    payload: { field, value }
  };
}

export const initialState = {
  form: {
    name: "",
    username: "",
    email: "",
    password: "",
    passwordConfirmation: "",
    acceptTerms: false
  },
  meta: {
    register: registerOperation.initialState,
    confirm: confirmOperation.initialState,
    resendConfirmation: resendConfirmationOperation.initialState
  }
};

export function registrationSelector(state) {
  return state.registration;
}

function resetFormState() {
  return initialState.form;
}

function setFormFieldFromAction(state, action) {
  return {
    ...state,
    [action.payload.field]: action.payload.value
  };
}

function resetFormFieldErrorsFromAction(state, action) {
  if (!state.errors) return state;
  return {
    ...state,
    errors: state.errors.filter(e => e.field !== action.payload.field)
  };
}

const formReducer = createReducer(initialState.form, {
  [REGISTRATION_FORM_CHANGE]: setFormFieldFromAction,
  [registerOperation.actionTypes.SUCCESS]: resetFormState
});

const registerOperationReducer = fallbackReducer(registerOperation.reducer, {
  [REGISTRATION_FORM_CHANGE]: resetFormFieldErrorsFromAction
});

const metaReducer = combineReducers({
  register: registerOperationReducer,
  confirm: confirmOperation.reducer,
  resendConfirmation: resendConfirmationOperation.reducer
});

export default combineReducers({
  form: formReducer,
  meta: metaReducer
});
