

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Import from "../../features/import.bs.js";
import * as StyleCss from "./style.css";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as ReactExtras from "../../lib/solvuu/ReactExtras.bs.js";
import * as ReactSelect from "react-select";

var styles = StyleCss.default;

function makeProps(value, onChange, options, className, clearable, removeSelected, closeOnSelect, param) {
  var tmp = {
    value: Curry._1(Import.List.toArray, value),
    options: Curry._1(Import.List.toArray, options),
    onChange: (function (options) {
        var __x = (options == null) ? undefined : Caml_option.some(options);
        return Curry._1(onChange, Belt_Option.map(__x, Import.List.fromArray));
      }),
    clearable: clearable,
    closeOnSelect: closeOnSelect,
    removeSelected: removeSelected,
    multi: true,
    placeholder: null
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return tmp;
}

var ReactMultiSelect = {
  makeProps: makeProps
};

function MultiSelect(Props) {
  var label = Props.label;
  var value = Props.value;
  var onChange = Props.onChange;
  var options = Props.options;
  var className = Props.className;
  var match = Props.clearable;
  var clearable = match !== undefined ? match : false;
  var match$1 = Props.removeSelected;
  var removeSelected = match$1 !== undefined ? match$1 : true;
  var match$2 = Props.closeOnSelect;
  var closeOnSelect = match$2 !== undefined ? match$2 : false;
  var testId = Props.testId;
  var className$1 = Cn.make(/* :: */[
        styles.select,
        /* :: */[
          Cn.unpack(className),
          /* [] */0
        ]
      ]);
  return React.createElement("div", {
              className: styles.wrapper
            }, ReactExtras.withOptionalTestId(testId, React.createElement(ReactSelect.default, makeProps(value, onChange, options, className$1, clearable, removeSelected, closeOnSelect, /* () */0))), label !== undefined ? React.createElement("label", {
                    className: styles.label
                  }, Caml_option.valFromOption(label)) : null);
}

var make = MultiSelect;

export {
  styles ,
  ReactMultiSelect ,
  make ,
  
}
/* styles Not a pure module */
