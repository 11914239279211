

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Import from "../features/import.bs.js";
import * as Std$Bolt from "bolt/./std.bs.js";
import * as Lang$SolvuuApi from "solvuu-api/./lang.bs.js";
import * as Root$SolvuuApi from "solvuu-api/./root.bs.js";
import * as Science$SolvuuApi from "solvuu-api/./science.bs.js";

function $$eval(f, t, rawServerSideEval, simpleServerSideEval) {
  if (typeof t === "number" || t[0] !== 847309489) {
    return Promise.resolve(Curry._2(Lang$SolvuuApi.Term.eval_error, "Expected record", t));
  } else {
    var dataParam = Curry._2(Lang$SolvuuApi.Record.Param.flag1, Root$SolvuuApi.Histogram.Arg.Const.Label.data, Curry._1(Lang$SolvuuApi.Record.Param.Flag.required, Lang$SolvuuApi.Record.Param.Arg_type.data));
    var res = Curry._3(Root$SolvuuApi.Histogram.Arg.of_record, t[1], dataParam, (function (numBins, binWeights, dataWeights, columnName, data) {
            if (columnName !== undefined) {
              var columnName$1 = columnName;
              var label = /* `Named */[
                699531161,
                columnName$1
              ];
              var x = Curry._1(Lang$SolvuuApi.Var.Exn.of_string, "x");
              var data_001 = /* tuple */[
                /* `Var */[
                  4298439,
                  Root$SolvuuApi.map
                ],
                /* `Record */[
                  847309489,
                  /* :: */[
                    /* tuple */[
                      /* `Positional */[
                        -300665356,
                        1
                      ],
                      data
                    ],
                    /* :: */[
                      /* tuple */[
                        /* `Positional */[
                          -300665356,
                          2
                        ],
                        /* `Lambda */[
                          89433895,
                          /* tuple */[
                            Curry._1(Lang$SolvuuApi.Var.to_string, x),
                            /* `App */[
                              3257473,
                              /* tuple */[
                                /* `Project */[
                                  -1019462983,
                                  label
                                ],
                                /* `Var */[
                                  4298439,
                                  x
                                ]
                              ]
                            ]
                          ]
                        ]
                      ],
                      /* [] */0
                    ]
                  ]
                ]
              ];
              var data$1 = /* `App */[
                3257473,
                data_001
              ];
              return /* `Ok */[
                      17724,
                      Curry._2(rawServerSideEval, data$1, (function (param) {
                              var typ = param.typ;
                              var data = param.term;
                              var arg = Curry._5(Root$SolvuuApi.Histogram.Arg.to_record, numBins, binWeights, dataWeights, columnName$1, data);
                              if (typeof typ !== "number" && typ[0] === 848054398) {
                                var match = typ[1];
                                var exit = 0;
                                if (typeof match === "number") {
                                  if (match >= 3654864) {
                                    if (match !== 365180284) {
                                      if (match === 737456202) {
                                        exit = 3;
                                      }
                                      
                                    } else {
                                      exit = 2;
                                    }
                                  } else if (match !== -976970511) {
                                    if (match >= 3654863) {
                                      exit = 2;
                                    }
                                    
                                  } else {
                                    exit = 3;
                                  }
                                } else if (match[0] === 216537025) {
                                  var match$1 = match[1];
                                  if (match$1 >= 3654864) {
                                    if (match$1 !== 365180284) {
                                      if (match$1 === 737456202) {
                                        exit = 3;
                                      }
                                      
                                    } else {
                                      exit = 2;
                                    }
                                  } else if (match$1 !== -976970511) {
                                    if (match$1 >= 3654863) {
                                      exit = 2;
                                    }
                                    
                                  } else {
                                    exit = 3;
                                  }
                                }
                                switch (exit) {
                                  case 2 :
                                      return Curry._1(simpleServerSideEval, /* `App */[
                                                  3257473,
                                                  /* tuple */[
                                                    /* `Var */[
                                                      4298439,
                                                      f
                                                    ],
                                                    /* `Record */[
                                                      847309489,
                                                      arg
                                                    ]
                                                  ]
                                                ]);
                                  case 3 :
                                      var result = Curry._1(Std$Bolt.Res.join, Curry._3(Root$SolvuuApi.Histogram.Arg.of_record, arg, Root$SolvuuApi.Histogram.Arg.Param.categoricalDataParam, (function (param, param$1, param$2, columnName, categoricalSeries) {
                                                  if (columnName !== undefined) {
                                                    var bins = Curry._2(Import.List.map, Curry._1(Import.List.fromArray, Science$SolvuuApi.categoricalSeriesToHistogram(categoricalSeries)), (function (bin) {
                                                            return /* `Record */[
                                                                    847309489,
                                                                    /* :: */[
                                                                      /* tuple */[
                                                                        Root$SolvuuApi.Histogram.T.Categorical.Bin.Const.Label.name,
                                                                        /* `String */[
                                                                          -976970511,
                                                                          bin.name
                                                                        ]
                                                                      ],
                                                                      /* :: */[
                                                                        /* tuple */[
                                                                          Root$SolvuuApi.Histogram.T.Categorical.Bin.Const.Label.count,
                                                                          /* `Int */[
                                                                            3654863,
                                                                            bin.count
                                                                          ]
                                                                        ],
                                                                        /* [] */0
                                                                      ]
                                                                    ]
                                                                  ];
                                                          }));
                                                    return /* `Ok */[
                                                            17724,
                                                            /* `Viz */[
                                                              4300231,
                                                              /* `Record */[
                                                                847309489,
                                                                Curry._2(Lang$SolvuuApi.Record.tagged, f, /* `Record */[
                                                                      847309489,
                                                                      Curry._2(Lang$SolvuuApi.Record.tagged, Root$SolvuuApi.Histogram.T.Categorical.Const.tag, /* `Record */[
                                                                            847309489,
                                                                            /* :: */[
                                                                              /* tuple */[
                                                                                Root$SolvuuApi.Histogram.T.Categorical.Const.Label.bins,
                                                                                /* `List */[
                                                                                  848054398,
                                                                                  bins
                                                                                ]
                                                                              ],
                                                                              /* :: */[
                                                                                /* tuple */[
                                                                                  Root$SolvuuApi.Histogram.T.Categorical.Const.Label.xAxisLabel,
                                                                                  /* `String */[
                                                                                    -976970511,
                                                                                    columnName
                                                                                  ]
                                                                                ],
                                                                                /* [] */0
                                                                              ]
                                                                            ]
                                                                          ])
                                                                    ])
                                                              ]
                                                            ]
                                                          ];
                                                  } else {
                                                    return /* `Error */[
                                                            106380200,
                                                            /* :: */[
                                                              /* tuple */[
                                                                Root$SolvuuApi.Histogram.Arg.Const.Label.columnName,
                                                                /* Invalid */144245463
                                                              ],
                                                              /* [] */0
                                                            ]
                                                          ];
                                                  }
                                                })));
                                      var tmp;
                                      if (result[0] >= 106380200) {
                                        var message = Curry._1(Import.List.toArray, Curry._2(Import.List.map, result[1], (function (param) {
                                                      var label = Curry._1(Lang$SolvuuApi.Label.to_string, param[0]);
                                                      var reason = param[1] >= 144245463 ? "invalid" : "not_found";
                                                      return "(" + (label + (", " + (reason + ") ")));
                                                    }))).join(" ");
                                        tmp = Curry._2(Lang$SolvuuApi.Term.eval_error, message, t);
                                      } else {
                                        tmp = result[1];
                                      }
                                      return Promise.resolve(tmp);
                                  
                                }
                              }
                              return Promise.resolve(Curry._3(Lang$SolvuuApi.Term.type_error, typ, "Invalid type", data));
                            }))
                    ];
            } else {
              return /* `Error */[
                      106380200,
                      /* :: */[
                        /* tuple */[
                          Root$SolvuuApi.Histogram.Arg.Const.Label.columnName,
                          /* Invalid */144245463
                        ],
                        /* [] */0
                      ]
                    ];
            }
          }));
    var match = Curry._1(Std$Bolt.Res.join, res);
    if (match[0] >= 106380200) {
      var message = Curry._1(Import.List.toArray, Curry._2(Import.List.map, match[1], (function (param) {
                    var label = Curry._1(Lang$SolvuuApi.Label.to_string, param[0]);
                    var reason = param[1] >= 144245463 ? "invalid" : "not_found";
                    return "(" + (label + (", " + (reason + ") ")));
                  }))).join(" ");
      return Promise.resolve(Curry._2(Lang$SolvuuApi.Term.eval_error, message, t));
    } else {
      return match[1];
    }
  }
}

export {
  $$eval ,
  
}
/* Import Not a pure module */
