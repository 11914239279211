

import * as Link from "./Link.bs.js";
import * as Route from "../Route.bs.js";
import * as React from "react";
import * as $$String from "bs-platform/lib/es6/string.js";
import * as Js_dict from "bs-platform/lib/es6/js_dict.js";
import * as Classnames from "classnames";
import * as ReasonReactRouter from "reason-react/src/ReasonReactRouter.js";

function NavLink(Props) {
  var _to = Props.to;
  var className = Props.className;
  var activeClassName = Props.activeClassName;
  var children = Props.children;
  var url = ReasonReactRouter.useUrl(undefined, /* () */0);
  var toUrlString = Route.toUrlString(Route.toUrl(_to));
  var urlUrlString = Route.toUrlString(url);
  var isActive = $$String.equal(toUrlString, urlUrlString);
  var className$1 = Classnames.default(className, Js_dict.fromList(/* :: */[
            /* tuple */[
              activeClassName,
              isActive
            ],
            /* [] */0
          ]));
  var ariaCurrent = isActive ? "page" : null;
  return React.createElement(Link.make, {
              to: _to,
              className: className$1,
              ariaCurrent: ariaCurrent,
              children: children
            });
}

var make = NavLink;

export {
  make ,
  
}
/* Link Not a pure module */
