


function makeProps(monitorWidth, monitorHeight, refreshRate, refreshMode, noPlaceholder, children, key, param) {
  return {
          monitorWidth: monitorWidth,
          monitorHeight: monitorHeight,
          refreshRate: refreshRate,
          refreshMode: refreshMode !== undefined ? (
              refreshMode >= -8671255 ? "debounce" : "throttle"
            ) : undefined,
          noPlaceholder: noPlaceholder,
          children: children,
          key: key
        };
}

export {
  makeProps ,
  
}
/* No side effect */
