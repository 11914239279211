

import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Js_null_undefined from "bs-platform/lib/es6/js_null_undefined.js";

function convertFromJs(prop) {
  var match = prop.pending;
  var match$1 = prop.success;
  var match$2 = prop.failure;
  var match$3 = prop.errors;
  if (match) {
    return /* Pending */1;
  } else if (match$1) {
    return /* Success */2;
  } else if (match$2) {
    return /* Failure */[(match$3 == null) ? undefined : Caml_option.some(match$3)];
  } else {
    return /* Initial */0;
  }
}

function convertToJs(prop) {
  if (typeof prop === "number") {
    switch (prop) {
      case /* Initial */0 :
          return {
                  pending: false,
                  success: false,
                  failure: false,
                  errors: null
                };
      case /* Pending */1 :
          return {
                  pending: true,
                  success: false,
                  failure: false,
                  errors: null
                };
      case /* Success */2 :
          return {
                  pending: false,
                  success: true,
                  failure: false,
                  errors: null
                };
      
    }
  } else {
    return {
            pending: false,
            success: false,
            failure: true,
            errors: Js_null_undefined.fromOption(prop[0])
          };
  }
}

export {
  convertFromJs ,
  convertToJs ,
  
}
/* No side effect */
