import { Component } from "react";
import PropTypes from "prop-types";

export default class Latch extends Component {
  static propTypes = {
    value: PropTypes.bool.isRequired,
    render: PropTypes.func.isRequired
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.value) return { value: true };
    return null;
  }

  constructor(props) {
    super(props);
    this.state = { value: props.value };
  }

  render() {
    return this.props.render(this.state.value);
  }
}
