

import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Import from "../features/import.bs.js";
import * as Printf from "bs-platform/lib/es6/printf.js";
import * as Record from "../containers/Entities/Record/Record.bs.js";
import * as Select from "../components/Select/Select.bs.js";
import * as Loading from "../components/Loading.bs.js";
import * as FormGroup from "../components/FormGroup/FormGroup.bs.js";
import * as Pervasives from "bs-platform/lib/es6/pervasives.js";
import * as WithTestId from "../containers/WithTestId.bs.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as ReactExtras from "../lib/solvuu/ReactExtras.bs.js";
import * as ReactSelect from "../lib/ReactSelect.bs.js";
import * as Lang$SolvuuApi from "solvuu-api/./lang.bs.js";
import * as Root$SolvuuApi from "solvuu-api/./root.bs.js";
import * as VerticalLayout from "../components/Layout/VerticalLayout.bs.js";
import * as TermRenderContext from "../lang/TermRenderContext.bs.js";
import * as Layout from "components/Layout";
import * as TableKronaChartCss from "./TableKronaChart.css";
import * as TableVisualizationCard from "./TableVisualizationCard.bs.js";

var styles = TableKronaChartCss.default;

function TableKronaChart$Loading(Props) {
  return React.createElement("div", {
              className: styles.centered
            }, React.createElement(Loading.make, { }));
}

function TableKronaChart$State$SelectedLevelSelect(Props) {
  var value = Props.value;
  var onChange = Props.onChange;
  var onRemove = Props.onRemove;
  var selectOptions = Props.selectOptions;
  var levelIndex = Props.levelIndex;
  var label = React.createElement(ReactIntl.FormattedMessage, {
        id: "table.kronaChart.levelColumnSelect.existing.label",
        values: {
          levelIndex: levelIndex
        }
      });
  var onChange$1 = function (opt) {
    var selectedValue = Belt_Option.map(opt, (function (x) {
            return x.value;
          }));
    if (selectedValue !== undefined) {
      return Curry._1(onChange, selectedValue);
    } else {
      return Curry._1(onRemove, /* () */0);
    }
  };
  return React.createElement(Select.make, {
              label: label,
              value: value,
              onChange: onChange$1,
              options: selectOptions,
              clearable: true
            });
}

function TableKronaChart$State$NewLevelSelect(Props) {
  var onAdd = Props.onAdd;
  var selectOptions = Props.selectOptions;
  var label = React.createElement(ReactIntl.FormattedMessage, {
        id: "table.kronaChart.levelColumnSelect.new.label"
      });
  var handleChange = function (opt) {
    var selectedValue = Belt_Option.map(opt, (function (x) {
            return x.value;
          }));
    if (selectedValue !== undefined) {
      return Curry._1(onAdd, selectedValue);
    } else {
      return Pervasives.failwith("Unexpected none value on a non-clearable field");
    }
  };
  return React.createElement(Select.make, {
              label: label,
              value: "",
              onChange: handleChange,
              options: selectOptions,
              clearable: false
            });
}

function TableKronaChart$State$LevelColumnsSelects(Props) {
  var columns = Props.columns;
  var levelColumnNames = Props.levelColumnNames;
  var dispatch = Props.dispatch;
  var selectOptions = Curry._2(Import.List.map, Curry._2(Import.List.map, Curry._2(Import.List.keep, columns, (function (param) {
                  return Curry._1(Root$SolvuuApi.Krona_chart.Arg.levelTypeAvailable, param[1]);
                })), (function (param) {
              return param[0];
            })), (function (name) {
          var selectOption = ReactSelect.$$Option.make(name, name);
          var match = Curry._3(Import.List.has, levelColumnNames, name, (function (prim, prim$1) {
                  return prim === prim$1;
                }));
          var state = match ? /* Selected */186574459 : /* Available */425081257;
          return /* tuple */[
                  selectOption,
                  state
                ];
        }));
  var existingLevelSelects = ReactExtras.listToElement(Curry._2(Import.List.mapWithIndex, levelColumnNames, (function (idx, columnName) {
              var onRemove = function (param) {
                return Curry._1(dispatch, /* `UpdateArg */[
                            615103405,
                            /* tuple */[
                              Root$SolvuuApi.Krona_chart.Arg.Const.Label.levelColumnNames,
                              (function (param) {
                                  if (param !== undefined) {
                                    var prev = param;
                                    if (typeof prev === "number" || prev[0] !== 848054398) {
                                      return Curry._2(Lang$SolvuuApi.Term.eval_error, "Expected list of strings", prev);
                                    } else {
                                      return /* `List */[
                                              848054398,
                                              Curry._2(Import.List.keep, prev[1], (function (x) {
                                                      if (typeof x === "number" || x[0] !== -976970511) {
                                                        return false;
                                                      } else {
                                                        return x[1] !== columnName;
                                                      }
                                                    }))
                                            ];
                                    }
                                  } else {
                                    return /* `List */[
                                            848054398,
                                            /* [] */0
                                          ];
                                  }
                                })
                            ]
                          ]);
              };
              var onChange = function (newColumnName) {
                return Curry._1(dispatch, /* `UpdateArg */[
                            615103405,
                            /* tuple */[
                              Root$SolvuuApi.Krona_chart.Arg.Const.Label.levelColumnNames,
                              (function (param) {
                                  if (param !== undefined) {
                                    var prev = param;
                                    if (typeof prev === "number" || prev[0] !== 848054398) {
                                      return Curry._2(Lang$SolvuuApi.Term.eval_error, "Expected list of strings", prev);
                                    } else {
                                      return /* `List */[
                                              848054398,
                                              Curry._2(Import.List.map, prev[1], (function (prev) {
                                                      if (typeof prev === "number" || prev[0] !== -976970511) {
                                                        return prev;
                                                      } else {
                                                        var prev$1 = prev[1];
                                                        return /* `String */[
                                                                -976970511,
                                                                prev$1 === columnName ? newColumnName : (
                                                                    prev$1 === newColumnName ? columnName : prev$1
                                                                  )
                                                              ];
                                                      }
                                                    }))
                                            ];
                                    }
                                  } else {
                                    return /* `List */[
                                            848054398,
                                            /* [] */0
                                          ];
                                  }
                                })
                            ]
                          ]);
              };
              var levelIndex = idx + 1 | 0;
              var key = Curry._2(Printf.sprintf(/* Format */[
                        /* Int */Block.__(4, [
                            /* Int_d */0,
                            /* No_padding */0,
                            /* No_precision */0,
                            /* Char_literal */Block.__(12, [
                                /* "-" */45,
                                /* String */Block.__(2, [
                                    /* No_padding */0,
                                    /* End_of_format */0
                                  ])
                              ])
                          ]),
                        "%d-%s"
                      ]), levelIndex, columnName);
              var selectOptions$1 = Curry._2(Import.List.map, selectOptions, (function (param) {
                      return param[0];
                    }));
              return React.createElement(Layout.VerticalLayoutItem, {
                          children: React.createElement(TableKronaChart$State$SelectedLevelSelect, {
                                value: columnName,
                                onChange: onChange,
                                onRemove: onRemove,
                                selectOptions: selectOptions$1,
                                levelIndex: levelIndex
                              }),
                          key: key
                        });
            })));
  var handleAdd = function (value) {
    return Curry._1(dispatch, /* `UpdateArg */[
                615103405,
                /* tuple */[
                  Root$SolvuuApi.Krona_chart.Arg.Const.Label.levelColumnNames,
                  (function (param) {
                      if (param !== undefined) {
                        var prev = param;
                        if (typeof prev === "number" || prev[0] !== 848054398) {
                          return Curry._2(Lang$SolvuuApi.Term.eval_error, "Expected list", prev);
                        } else {
                          return /* `List */[
                                  848054398,
                                  Curry._2(Import.List.concat, prev[1], /* :: */[
                                        /* `String */[
                                          -976970511,
                                          value
                                        ],
                                        /* [] */0
                                      ])
                                ];
                        }
                      } else {
                        return /* `List */[
                                848054398,
                                /* :: */[
                                  /* `String */[
                                    -976970511,
                                    value
                                  ],
                                  /* [] */0
                                ]
                              ];
                      }
                    })
                ]
              ]);
  };
  var selectOptions$1 = Curry._2(Import.List.keepMap, selectOptions, (function (param) {
          if (param[1] >= 425081257) {
            return param[0];
          }
          
        }));
  var newLevelSelect = selectOptions$1 ? React.createElement(Layout.VerticalLayoutItem, {
          children: React.createElement(TableKronaChart$State$NewLevelSelect, {
                onAdd: handleAdd,
                selectOptions: selectOptions$1
              })
        }) : null;
  return React.createElement(Layout.VerticalLayoutItem, {
              children: React.createElement(Layout.VerticalLayout, VerticalLayout.makeProps(/* x1 */26809, null, /* () */0), React.createElement(Layout.VerticalLayoutItem, {
                        children: React.createElement(ReactIntl.FormattedMessage, {
                              id: "table.kronaChart.levelColumnSelect.header"
                            })
                      }), existingLevelSelects, newLevelSelect)
            });
}

function TableKronaChart$State$ValueColumnSelect(Props) {
  var columns = Props.columns;
  var valueColumnName = Props.valueColumnName;
  var dispatch = Props.dispatch;
  var columnSelectOptions = Curry._2(Import.List.map, Curry._2(Import.List.keep, columns, (function (param) {
              return Curry._1(Root$SolvuuApi.Krona_chart.Arg.valueTypeAvailable, param[1]);
            })), (function (param) {
          var name = param[0];
          return ReactSelect.$$Option.make(name, name);
        }));
  var handleColumnSelect = function (opt) {
    var columnName = Belt_Option.map(opt, (function (param) {
            return param.value;
          }));
    if (columnName !== undefined) {
      var columnName$1 = columnName;
      return Curry._1(dispatch, /* `UpdateArg */[
                  615103405,
                  /* tuple */[
                    Root$SolvuuApi.Krona_chart.Arg.Const.Label.valueColumnName,
                    (function (param) {
                        return /* `Some */[
                                925978388,
                                /* `String */[
                                  -976970511,
                                  columnName$1
                                ]
                              ];
                      })
                  ]
                ]);
    } else {
      return Pervasives.failwith("Unexpected: value column select should not be clearable.");
    }
  };
  var columnSelectValue = valueColumnName !== undefined ? valueColumnName : "";
  return React.createElement(Layout.VerticalLayoutItem, {
              children: React.createElement(FormGroup.$$Element.make, {
                    name: "valueColumn",
                    children: null
                  }, React.createElement(WithTestId.make, {
                        id: "krona-chart-value-column-select",
                        children: React.createElement(Select.make, {
                              label: React.createElement(ReactIntl.FormattedMessage, {
                                    id: "table.kronaChart.valueColumnSelect.label"
                                  }),
                              value: columnSelectValue,
                              onChange: handleColumnSelect,
                              options: columnSelectOptions,
                              clearable: false
                            })
                      }), React.createElement(FormGroup.FieldInfoElement.make, {
                        children: React.createElement(ReactIntl.FormattedMessage, {
                              id: "table.kronaChart.valueColumnSelect.info"
                            })
                      }))
            });
}

function TableKronaChart$State(Props) {
  var onClose = Props.onClose;
  var state = Props.state;
  var columns = Props.columns;
  var dispatch = Props.dispatch;
  var graphic = state.graphic;
  return React.createElement(Record.ParsedRecord.make, {
              children: Curry._2(Root$SolvuuApi.Krona_chart.Arg.of_record, state.arg, (function (levelColumnNames, valueColumnName, param) {
                      var title = React.createElement(ReactIntl.FormattedMessage, {
                            id: "table.kronaChart.header"
                          });
                      var controls = React.createElement(Layout.VerticalLayout, VerticalLayout.makeProps(/* x4 */26812, null, /* () */0), React.createElement(TableKronaChart$State$LevelColumnsSelects, {
                                columns: columns,
                                levelColumnNames: levelColumnNames,
                                dispatch: dispatch
                              }), React.createElement(TableKronaChart$State$ValueColumnSelect, {
                                columns: columns,
                                valueColumnName: valueColumnName,
                                dispatch: dispatch
                              }));
                      return React.createElement(TableVisualizationCard.make, {
                                  icon: /* KronaChart */300511203,
                                  title: title,
                                  controls: controls,
                                  onClose: onClose,
                                  children: ReactExtras.withTestId("entity-static-table-krona-chart", React.createElement("div", {
                                            className: styles.root
                                          }, graphic))
                                });
                    }))
            });
}

function TableKronaChart(Props) {
  var onClose = Props.onClose;
  var data = Props.data;
  var $$eval = Props.eval;
  var apiClient = Props.apiClient;
  var renderTerm = Props.renderTerm;
  var type_ = data[1];
  var term = data[0];
  var state_arg = Curry._3(Lang$SolvuuApi.Record.update, Root$SolvuuApi.Krona_chart.Arg.Const.Default.arg, Root$SolvuuApi.Krona_chart.Arg.Const.Label.data, (function (param) {
          return term;
        }));
  var state = {
    arg: state_arg,
    graphic: null
  };
  var match = React.useReducer((function (param, param$1) {
          var apiClient$1 = apiClient;
          var renderTerm$1 = renderTerm;
          var param$2 = param;
          var $$event = param$1;
          var state = param$2[0];
          var evalEffect = function (arg) {
            return /* `Evaluate */[
                    -620618631,
                    /* `App */[
                      3257473,
                      /* tuple */[
                        /* `Var */[
                          4298439,
                          Root$SolvuuApi.krona_chart
                        ],
                        /* `Record */[
                          847309489,
                          arg
                        ]
                      ]
                    ]
                  ];
          };
          if ($$event[0] >= 615103405) {
            var match = $$event[1];
            var state_arg = Curry._3(Lang$SolvuuApi.Record.update, state.arg, match[0], match[1]);
            var state_graphic = React.createElement(TableKronaChart$Loading, { });
            var state$1 = {
              arg: state_arg,
              graphic: state_graphic
            };
            var effect = evalEffect(state_arg);
            return /* tuple */[
                    state$1,
                    effect
                  ];
          } else {
            var graphic = Curry._6(renderTerm$1, apiClient$1, $$event[1], /* Type_not_available */-883458824, TermRenderContext.root, undefined, /* () */0);
            var state_arg$1 = state.arg;
            var state$2 = {
              arg: state_arg$1,
              graphic: graphic
            };
            return /* tuple */[
                    state$2,
                    undefined
                  ];
          }
        }), /* tuple */[
        state,
        undefined
      ]);
  var dispatch = match[1];
  var match$1 = match[0];
  var effect = match$1[1];
  React.useEffect((function () {
          if (effect !== undefined) {
            Curry._1($$eval, effect[1]).then((function (term) {
                    Curry._1(dispatch, /* `Graphic */[
                          -1051164024,
                          term
                        ]);
                    return Promise.resolve(/* () */0);
                  }));
            return ;
          }
          
        }), /* array */[effect]);
  if (typeof type_ !== "number" && type_[0] === 848054398) {
    var match$2 = type_[1];
    if (typeof match$2 !== "number" && match$2[0] === 847309489) {
      return React.createElement(TableKronaChart$State, {
                  onClose: onClose,
                  state: match$1[0],
                  columns: match$2[1][0],
                  dispatch: dispatch
                });
    }
    
  }
  var term$1 = Curry._3(Lang$SolvuuApi.Term.type_error, type_, "Expected list of records", term);
  return Curry._6(renderTerm, apiClient, term$1, /* Type_not_available */-883458824, TermRenderContext.root, undefined, /* () */0);
}

var make = TableKronaChart;

export {
  make ,
  
}
/* styles Not a pure module */
