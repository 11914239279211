

import * as Op from "../../features/Op.bs.js";
import * as Qs from "qs";
import * as Aws from "../../features/Aws.bs.js";
import * as Err from "../../components/Err.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Button from "../../components/Button.bs.js";
import * as $$String from "bs-platform/lib/es6/string.js";
import * as FormGroup from "../../components/FormGroup/FormGroup.bs.js";
import * as FormLayout from "../../components/FormLayout/FormLayout.bs.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as ReactBootstrap from "../../lib/ReactBootstrap.bs.js";
import * as PublicActionCard from "../../components/PublicActionCard/PublicActionCard.bs.js";
import * as ReasonReactRouter from "reason-react/src/ReasonReactRouter.js";
import * as Lib from "react-bootstrap/lib";
import * as AmazonCognitoIdentityJS from "../../lib/AmazonCognitoIdentityJS.bs.js";
import * as AmazonCognitoIdentityJs from "amazon-cognito-identity-js";

function requestPasswordReset(username) {
  var pool = Aws.getUserPool(/* () */0);
  var user = new AmazonCognitoIdentityJs.CognitoUser({
        Username: username,
        Pool: pool
      });
  var onSuccess = function (_data) {
    return /* Ok */17724;
  };
  var onFailure = function (error) {
    return /* `Error */[
            106380200,
            error.message
          ];
  };
  return AmazonCognitoIdentityJS.User.forgotPassword(user, onSuccess, onFailure);
}

function username(state) {
  var variant = state[0];
  if (variant !== -783600662) {
    if (variant >= 951752159) {
      return state[1].username;
    } else {
      return ;
    }
  } else {
    return state[1].data.username;
  }
}

function run(state) {
  if (state[0] !== -783600662) {
    return (function (param) {
        return /* () */0;
      });
  } else {
    var run$1 = state[1].run;
    return (function ($$event) {
        $$event.preventDefault();
        return Curry._1(run$1, /* () */0);
      });
  }
}

function PasswordReset$Request(Props) {
  var state = Op.useOp({
        username: "",
        error: undefined
      }, (function (param) {
          var username = param.username;
          return requestPasswordReset(username).then((function (e) {
                        return Promise.resolve(typeof e === "number" ? /* `Done */[
                                      759635106,
                                      /* () */0
                                    ] : /* `Retry */[
                                      -101284024,
                                      {
                                        username: username,
                                        error: e[1]
                                      }
                                    ]);
                      }));
        }));
  var renderForm = function (param) {
    return React.createElement("form", {
                onSubmit: run(state)
              }, React.createElement(Err.Form.make, {
                    errors: state[0] !== -783600662 ? /* [] */0 : Belt_Option.mapWithDefault(state[1].data.error, /* [] */0, (function (error) {
                              return /* :: */[
                                      {
                                        field: undefined,
                                        id: undefined,
                                        message: error
                                      },
                                      /* [] */0
                                    ];
                            }))
                  }), React.createElement(FormLayout.VerticalFormFields.make, {
                    children: React.createElement(FormLayout.VerticalFormField.make, {
                          children: React.createElement(FormGroup.ControlledFormGroup.make, {
                                value: username(state),
                                type: "text",
                                onFieldChange: (function (param) {
                                    var state$1 = state;
                                    var x = param;
                                    if (state$1[0] !== -783600662) {
                                      return /* () */0;
                                    } else {
                                      return Curry._1(state$1[1].setInput, (function (prev) {
                                                    return {
                                                            username: Belt_Option.getWithDefault(x, ""),
                                                            error: prev.error
                                                          };
                                                  }));
                                    }
                                  }),
                                name: "username",
                                required: true,
                                validationState: null,
                                label: React.createElement(ReactIntl.FormattedMessage, {
                                      id: "passwordReset.form.username"
                                    })
                              })
                        })
                  }), React.createElement(FormLayout.VerticalFormActions.make, {
                    children: React.createElement(Button.make, Button.makeProps(undefined, undefined, /* `Submit */[
                              -828715976,
                              Curry._5(Button.Type.Submit.make, Op.isRunning(state), undefined, undefined, undefined, /* () */0)
                            ], React.createElement(ReactIntl.FormattedMessage, {
                                  id: "passwordReset.request.submit"
                                }), /* () */0))
                  }));
  };
  var renderSuccess = function (param) {
    return React.createElement(Lib.Alert, ReactBootstrap.Alert.makeProps(/* info */-977586066, undefined, null, undefined, /* () */0), React.createElement(ReactIntl.FormattedMessage, {
                    id: "passwordReset.request.success.title",
                    tagName: "h4"
                  }), React.createElement(ReactIntl.FormattedMessage, {
                    id: "passwordReset.request.success.message",
                    tagName: "p"
                  }));
  };
  return React.createElement(PublicActionCard.make, {
              children: null
            }, React.createElement(PublicActionCard.Title.make, {
                  id: "passwordReset.request.header"
                }), state[0] >= -783600662 ? renderForm(/* () */0) : renderSuccess(/* () */0));
}

function updatePassword(username, code, password) {
  var pool = Aws.getUserPool(/* () */0);
  var user = new AmazonCognitoIdentityJs.CognitoUser({
        Username: username,
        Pool: pool
      });
  var onSuccess = function (param) {
    return /* Ok */17724;
  };
  var onFailure = function (error) {
    return /* `Error */[
            106380200,
            error
          ];
  };
  return AmazonCognitoIdentityJS.User.confirmPassword(user, code, password, onSuccess, onFailure);
}

function password(state) {
  var variant = state[0];
  if (variant !== -783600662) {
    if (variant >= 951752159) {
      return state[1].password;
    } else {
      return ;
    }
  } else {
    return state[1].data.password;
  }
}

function passwordConfirmation(state) {
  var variant = state[0];
  if (variant !== -783600662) {
    if (variant >= 951752159) {
      return state[1].passwordConfirmation;
    } else {
      return ;
    }
  } else {
    return state[1].data.passwordConfirmation;
  }
}

function error(state) {
  var variant = state[0];
  if (variant !== -783600662) {
    if (variant >= 951752159) {
      return state[1].error;
    } else {
      return ;
    }
  } else {
    return state[1].data.error;
  }
}

function PasswordReset$Update(Props) {
  var query = Qs.parse(ReasonReactRouter.useUrl(undefined, /* () */0).search);
  var state = Op.useOp({
        username: query.username,
        code: query.code,
        password: "",
        passwordConfirmation: "",
        error: undefined
      }, (function (input) {
          return updatePassword(input.username, input.code, input.password).then((function (e) {
                        return Promise.resolve(typeof e === "number" ? /* `Done */[
                                      759635106,
                                      /* () */0
                                    ] : /* `Retry */[
                                      -101284024,
                                      {
                                        username: input.username,
                                        code: input.code,
                                        password: input.password,
                                        passwordConfirmation: input.passwordConfirmation,
                                        error: e[1]
                                      }
                                    ]);
                      }));
        }));
  Belt_Option.eq(password(state), passwordConfirmation(state), $$String.equal);
  return React.createElement(PublicActionCard.make, {
              children: null
            }, React.createElement(PublicActionCard.Title.make, {
                  id: "passwordReset.update.header"
                }), React.createElement("form", {
                  onSubmit: (function (param) {
                      var state$1 = state;
                      var $$event = param;
                      $$event.preventDefault();
                      if (state$1[0] !== -783600662) {
                        return /* () */0;
                      } else {
                        return Curry._1(state$1[1].run, /* () */0);
                      }
                    })
                }, React.createElement(Err.Form.make, {
                      errors: state[0] !== -783600662 ? /* [] */0 : Belt_Option.mapWithDefault(error(state), /* [] */0, (function (error) {
                                return /* :: */[
                                        {
                                          field: undefined,
                                          id: undefined,
                                          message: error.message
                                        },
                                        /* [] */0
                                      ];
                              }))
                    }), React.createElement(FormLayout.VerticalFormFields.make, {
                      children: null
                    }, React.createElement(FormLayout.VerticalFormField.make, {
                          children: React.createElement(FormGroup.ControlledFormGroup.make, {
                                value: password(state),
                                type: "password",
                                onFieldChange: (function (param) {
                                    var state$1 = state;
                                    var x = param;
                                    if (state$1[0] !== -783600662) {
                                      return /* () */0;
                                    } else {
                                      return Curry._1(state$1[1].setInput, (function (prev) {
                                                    return {
                                                            username: prev.username,
                                                            code: prev.code,
                                                            password: Belt_Option.getWithDefault(x, ""),
                                                            passwordConfirmation: prev.passwordConfirmation,
                                                            error: prev.error
                                                          };
                                                  }));
                                    }
                                  }),
                                name: "password",
                                required: true,
                                validationState: null,
                                label: React.createElement(ReactIntl.FormattedMessage, {
                                      id: "passwordReset.form.password"
                                    })
                              })
                        }), React.createElement(FormLayout.VerticalFormField.make, {
                          children: React.createElement(FormGroup.ControlledFormGroup.make, {
                                value: passwordConfirmation(state),
                                type: "password",
                                onFieldChange: (function (param) {
                                    var state$1 = state;
                                    var x = param;
                                    if (state$1[0] !== -783600662) {
                                      return /* () */0;
                                    } else {
                                      return Curry._1(state$1[1].setInput, (function (prev) {
                                                    return {
                                                            username: prev.username,
                                                            code: prev.code,
                                                            password: prev.password,
                                                            passwordConfirmation: Belt_Option.getWithDefault(x, ""),
                                                            error: prev.error
                                                          };
                                                  }));
                                    }
                                  }),
                                name: "passwordConfirmation",
                                required: true,
                                validationState: null,
                                label: React.createElement(ReactIntl.FormattedMessage, {
                                      id: "passwordReset.form.passwordConfirmation"
                                    })
                              })
                        })), React.createElement(FormLayout.VerticalFormActions.make, {
                      children: React.createElement(Button.make, Button.makeProps(undefined, undefined, /* `Submit */[
                                -828715976,
                                Curry._5(Button.Type.Submit.make, Op.isRunning(state), undefined, undefined, undefined, /* () */0)
                              ], React.createElement(ReactIntl.FormattedMessage, {
                                    id: "passwordReset.update.submit"
                                  }), /* () */0))
                    })));
}

var $$Request = {
  make: PasswordReset$Request
};

var Update = {
  make: PasswordReset$Update
};

export {
  $$Request ,
  Update ,
  
}
/* Op Not a pure module */
