import React, { Component } from "react";
import PropTypes from "prop-types";
import { Alert } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

import * as Card from "components/Card/Card.bs";
import * as Loading from "components/Loading.bs";
import * as Button from "components/Button.bs";
import Form from "components/Form";
import FormGroup, { renderCheckbox } from "components/FormGroup/FormGroup.js";
import {
  VerticalFormFields as FormFields,
  VerticalFormField as FormField,
  VerticalFormActions as FormActions
} from "components/FormLayout";
import * as Err from "components/Err.bs";

class MFASettings extends Component {
  static propTypes = {
    fetchMFASettings: PropTypes.func.isRequired,
    updateMFASettings: PropTypes.func.isRequired,
    mfaFormChange: PropTypes.func.isRequired,
    form: PropTypes.shape({
      enabled: PropTypes.bool,
      phoneNumber: PropTypes.string
    }),
    meta: PropTypes.shape({
      fetch: PropTypes.shape({
        pending: PropTypes.bool.isRequired,
        success: PropTypes.bool.isRequired,
        failure: PropTypes.bool.isRequired,
        errors: PropTypes.any
      }),
      update: PropTypes.shape({
        pending: PropTypes.bool.isRequired,
        success: PropTypes.bool.isRequired,
        failure: PropTypes.bool.isRequired,
        errors: PropTypes.any
      })
    })
  };

  getFormContext() {
    return {
      form: this.props.form,
      errors: this.props.meta.update.errors || [],
      onFieldChange: this.props.mfaFormChange
    };
  }

  onFormSubmit = event => {
    event.preventDefault();
    this.props.updateMFASettings();
  };

  componentDidMount() {
    this.props.fetchMFASettings();
  }

  renderForm() {
    const {
      form,
      meta: { fetch, update }
    } = this.props;

    if (fetch.failure) {
      // TODO: research whether using $$Error is the proper solution
      return <Loading.$$Error.make />;
    }

    if (fetch.pending || !fetch.success) {
      return <Loading.make />;
    }

    return (
      <Form.Provider value={this.getFormContext()}>
        {update.success && (
          <Alert bsStyle="success">
            <FormattedMessage
              id="settings.profile.success.title"
              tagName="h4"
            />
            <FormattedMessage
              id="settings.profile.success.message"
              tagName="p"
            />
          </Alert>
        )}
        <Err.Form.make />
        <form onSubmit={this.onFormSubmit}>
          <FormFields>
            <FormField>
              <FormGroup
                name="enabled"
                renderControl={renderCheckbox}
                label={
                  <FormattedMessage id="settings.profile.MFASettings.enabled" />
                }
              />
            </FormField>
            {form.enabled && (
              <FormField>
                <FormGroup
                  name="phoneNumber"
                  type="tel"
                  label={
                    <FormattedMessage id="settings.profile.MFASettings.phoneNumber" />
                  }
                  info={
                    <FormattedMessage id="settings.profile.MFASettings.phoneNumber.info" />
                  }
                  required
                />
              </FormField>
            )}
          </FormFields>
          <FormActions>
            <Button.make
              type={Button.Type.submit({ isSubmitting: update.pending })}
            >
              <FormattedMessage id="settings.profile.submit" />
            </Button.make>
          </FormActions>
        </form>
      </Form.Provider>
    );
  }

  render() {
    return (
      <Card.make
        header={
          <Card.Header.make>
            <FormattedMessage
              id="settings.profile.MFASettings.header"
              tagName="strong"
            />
          </Card.Header.make>
        }
      >
        {this.renderForm()}
      </Card.make>
    );
  }
}

export default MFASettings;
