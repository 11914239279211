

import * as Blob$SolvuuApi from "solvuu-api/./blob.bs.js";

var convertFromJs = Blob$SolvuuApi.Json.read_t;

var convertToJs = Blob$SolvuuApi.Json.write_t;

var convertEventInfoToJs = Blob$SolvuuApi.$$Event.json_of_info;

var convertEventInfoFromJs = Blob$SolvuuApi.$$Event.info_of_json;

export {
  convertFromJs ,
  convertToJs ,
  convertEventInfoFromJs ,
  convertEventInfoToJs ,
  
}
/* Blob-SolvuuApi Not a pure module */
