

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Import from "../features/import.bs.js";
import * as Std$Bolt from "bolt/./std.bs.js";
import * as Lang$SolvuuApi from "solvuu-api/./lang.bs.js";
import * as Root$SolvuuApi from "solvuu-api/./root.bs.js";

function $$eval(f, t, loop) {
  if (typeof t === "number" || t[0] !== 847309489) {
    return Promise.resolve(Curry._2(Lang$SolvuuApi.Term.eval_error, "Expected record", t));
  } else {
    var resPromiseResTerm = Curry._2(Root$SolvuuApi.Volcano_plot.Arg.of_record, t[1], (function (xAxisColumnName, yAxisColumnName, labelColumnName, logTransformXAxis, logFCThreshold, pValueThreshold, data) {
            return Curry._1(loop, /* `App */[
                          3257473,
                          /* tuple */[
                            /* `Var */[
                              4298439,
                              Root$SolvuuApi.scatter_plot
                            ],
                            /* `Record */[
                              847309489,
                              Curry._4(Root$SolvuuApi.Scatter_plot.Arg.to_record, xAxisColumnName, yAxisColumnName, labelColumnName, data)
                            ]
                          ]
                        ]).then((function (scatter) {
                          var tmp;
                          if (typeof scatter === "number" || scatter[0] !== 4300231) {
                            tmp = /* `Ok */[
                              17724,
                              scatter
                            ];
                          } else {
                            var match = scatter[1];
                            if (typeof match === "number" || match[0] !== 847309489) {
                              tmp = /* `Ok */[
                                17724,
                                scatter
                              ];
                            } else {
                              var e = Curry._2(Lang$SolvuuApi.Record.Param.Tagged.parse, match[1], Lang$SolvuuApi.Record.Param.Arg_type.record);
                              if (e[0] >= 106380200) {
                                tmp = e;
                              } else {
                                var resPoints = Curry._1(Std$Bolt.Res.join, Curry._2(Root$SolvuuApi.Scatter_plot.T.of_record, e[1][1], (function (points, param, param$1, param$2) {
                                            return Curry._1(Std$Bolt.Res.all, Curry._2(Import.List.map, points, (function (record) {
                                                              return Curry._2(Root$SolvuuApi.Scatter_plot.T.Point.of_record, record, (function (x, y, label) {
                                                                            return /* tuple */[
                                                                                    x,
                                                                                    y,
                                                                                    label
                                                                                  ];
                                                                          }));
                                                            })));
                                          })));
                                tmp = Curry._2(Std$Bolt.Res.map, resPoints, (function (points) {
                                        var transformXAxis = function (fc) {
                                          if (logTransformXAxis) {
                                            return Math.log2(fc);
                                          } else {
                                            return fc;
                                          }
                                        };
                                        return /* `Viz */[
                                                4300231,
                                                /* `Record */[
                                                  847309489,
                                                  Curry._2(Lang$SolvuuApi.Record.tagged, f, /* `Record */[
                                                        847309489,
                                                        /* :: */[
                                                          /* tuple */[
                                                            Root$SolvuuApi.Volcano_plot.T.Const.Label.points,
                                                            /* `List */[
                                                              848054398,
                                                              Curry._2(Import.List.map, Curry._2(Import.List.keep, points, (function (param) {
                                                                          return param[1] !== 0.0;
                                                                        })), (function (param) {
                                                                      var y = param[1];
                                                                      var x = param[0];
                                                                      return /* `Record */[
                                                                              847309489,
                                                                              Curry._5(Root$SolvuuApi.Volcano_plot.T.Point.to_record, transformXAxis(x), -1.0 * Math.log10(y), transformXAxis(x), y, param[2])
                                                                            ];
                                                                    }))
                                                            ]
                                                          ],
                                                          /* :: */[
                                                            /* tuple */[
                                                              Root$SolvuuApi.Volcano_plot.T.Const.Label.xAxisThreshold,
                                                              /* `Float */[
                                                                365180284,
                                                                logFCThreshold
                                                              ]
                                                            ],
                                                            /* :: */[
                                                              /* tuple */[
                                                                Root$SolvuuApi.Volcano_plot.T.Const.Label.yAxisThreshold,
                                                                /* `Float */[
                                                                  365180284,
                                                                  -1.0 * Math.log10(pValueThreshold)
                                                                ]
                                                              ],
                                                              /* [] */0
                                                            ]
                                                          ]
                                                        ]
                                                      ])
                                                ]
                                              ];
                                      }));
                              }
                            }
                          }
                          return Promise.resolve(tmp);
                        }));
          }));
    var promiseResTerm = resPromiseResTerm[0] >= 106380200 ? Promise.resolve(/* `Error */[
            106380200,
            resPromiseResTerm[1]
          ]) : resPromiseResTerm[1];
    return promiseResTerm.then((function (resTerm) {
                  if (resTerm[0] >= 106380200) {
                    var message = Curry._1(Import.List.toArray, Curry._2(Import.List.map, resTerm[1], (function (param) {
                                  var label = Curry._1(Lang$SolvuuApi.Label.to_string, param[0]);
                                  var reason = param[1] >= 144245463 ? "invalid" : "not_found";
                                  return "(" + (label + (", " + (reason + ") ")));
                                }))).join(" ");
                    return Promise.resolve(Curry._2(Lang$SolvuuApi.Term.eval_error, message, t));
                  } else {
                    return Promise.resolve(resTerm[1]);
                  }
                }));
  }
}

export {
  $$eval ,
  
}
/* Import Not a pure module */
