

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Std$Bolt from "bolt/./std.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as HeatmapJs from "./Heatmap.js";
import * as Root$SolvuuApi from "solvuu-api/./root.bs.js";

function ofRecord(record) {
  return Curry._1(Std$Bolt.Res.join, Curry._2(Root$SolvuuApi.Heatmap.T.of_record, record, (function (x, y, matrix, normalizedMatrix, xAxisClusterLevels, yAxisClusterLevels, showOriginalData) {
                    var xAxisClusterLevels$1 = Belt_Array.map(xAxisClusterLevels, (function (x) {
                            return {
                                    clusters: x
                                  };
                          }));
                    var yAxisClusterLevels$1 = Belt_Array.map(yAxisClusterLevels, (function (x) {
                            return {
                                    clusters: x
                                  };
                          }));
                    var data = {
                      x: x,
                      y: y,
                      matrix: matrix,
                      normalizedMatrix: normalizedMatrix,
                      xAxisClusterLevels: xAxisClusterLevels$1,
                      yAxisClusterLevels: yAxisClusterLevels$1
                    };
                    if (y.length !== 0) {
                      if (x.length !== 0) {
                        return /* `Ok */[
                                17724,
                                {
                                  data: Caml_option.some(data),
                                  showOriginalData: showOriginalData
                                }
                              ];
                      } else {
                        return /* `Error */[
                                106380200,
                                /* :: */[
                                  /* tuple */[
                                    Root$SolvuuApi.Heatmap.T.Const.Label.x,
                                    /* Invalid */144245463
                                  ],
                                  /* [] */0
                                ]
                              ];
                      }
                    } else {
                      return /* `Error */[
                              106380200,
                              /* :: */[
                                /* tuple */[
                                  Root$SolvuuApi.Heatmap.T.Const.Label.y,
                                  /* Invalid */144245463
                                ],
                                /* [] */0
                              ]
                            ];
                    }
                  })));
}

function ofTerm(term) {
  if (typeof term === "number" || term[0] !== 847309489) {
    return /* `Error */[
            106380200,
            /* Expected_record */-756637544
          ];
  } else {
    var res = ofRecord(term[1]);
    return Curry._2(Std$Bolt.Res.map_error, res, (function (e) {
                  return /* `Record */[
                          847309489,
                          e
                        ];
                }));
  }
}

var Props = {
  ofRecord: ofRecord,
  ofTerm: ofTerm
};

var make = HeatmapJs.default;

export {
  Props ,
  make ,
  
}
/* make Not a pure module */
