

import * as Err from "../../components/Err.bs.js";
import * as Card from "../../components/Card/Card.bs.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Flags from "../../flags.bs.js";
import * as React from "react";
import * as Button from "../../components/Button.bs.js";
import * as Import from "../../features/import.bs.js";
import * as ApiClient from "../../ApiClient.bs.js";
import * as StyleCss from "./style.css";
import * as Basic$SolvuuApi from "solvuu-api/./basic.bs.js";

var styles = StyleCss.default;

function Admin(Props) {
  var match = React.useState((function () {
          return ;
        }));
  var setPingData = match[1];
  var pingData = match[0];
  var match$1 = React.useState((function () {
          return false;
        }));
  var setIsSubmitting = match$1[1];
  var ping = function (param) {
    Curry._1(setIsSubmitting, (function (param) {
            return true;
          }));
    var $$let = Basic$SolvuuApi.Ping.$$Request;
    var $$let$1 = Basic$SolvuuApi.Ping.$$Response;
    return ApiClient.request(undefined, /* GET */3546230, {
                  entity: Basic$SolvuuApi.Ping.entity,
                  service: Basic$SolvuuApi.Ping.service,
                  $$Request: {
                    path: $$let.path,
                    body: $$let.body,
                    info: $$let.info
                  },
                  $$Response: {
                    ok_of_json: $$let$1.ok_of_json,
                    json_of_ok: $$let$1.json_of_ok,
                    bad_request_of_json: $$let$1.bad_request_of_json,
                    json_of_bad_request: $$let$1.json_of_bad_request,
                    not_found_of_json: $$let$1.not_found_of_json,
                    json_of_not_found: $$let$1.json_of_not_found
                  }
                }, /* () */0).then((function (x) {
                  Curry._1(setPingData, (function (param) {
                          return /* tuple */[
                                  new Date(Date.now()),
                                  x
                                ];
                        }));
                  Curry._1(setIsSubmitting, (function (param) {
                          return false;
                        }));
                  return Promise.resolve(/* () */0);
                }));
  };
  var env = React.createElement("pre", undefined, JSON.stringify(Flags.t, null, 2));
  var pingError;
  var exit = 0;
  var response;
  if (pingData !== undefined) {
    var response$1 = pingData[1];
    if (typeof response$1 === "number" || response$1[1].info[0] < 17692) {
      response = response$1;
      exit = 1;
    } else {
      pingError = React.createElement(React.Fragment, undefined);
    }
  } else {
    pingError = React.createElement(React.Fragment, undefined);
  }
  if (exit === 1) {
    var $$let = Basic$SolvuuApi.Ping.$$Request;
    var $$let$1 = Basic$SolvuuApi.Ping.$$Response;
    pingError = React.createElement(Err.Operation.make, {
          message: ApiClient.json_of_response({
                entity: Basic$SolvuuApi.Ping.entity,
                service: Basic$SolvuuApi.Ping.service,
                $$Request: {
                  path: $$let.path,
                  body: $$let.body,
                  info: $$let.info
                },
                $$Response: {
                  ok_of_json: $$let$1.ok_of_json,
                  json_of_ok: $$let$1.json_of_ok,
                  bad_request_of_json: $$let$1.bad_request_of_json,
                  json_of_bad_request: $$let$1.json_of_bad_request,
                  not_found_of_json: $$let$1.not_found_of_json,
                  json_of_not_found: $$let$1.json_of_not_found
                }
              }, response)
        });
  }
  var field = "last successful ping: ";
  var lastSuccessAt;
  var exit$1 = 0;
  if (pingData !== undefined) {
    var match$2 = pingData;
    var match$3 = match$2[1];
    if (typeof match$3 === "number" || match$3[1].info[0] < 17692) {
      exit$1 = 1;
    } else {
      lastSuccessAt = React.createElement("p", undefined, field, match$2[0].toISOString());
    }
  } else {
    exit$1 = 1;
  }
  if (exit$1 === 1) {
    lastSuccessAt = React.createElement("p", undefined, "last successful ping: unknown");
  }
  var field$1 = "solvuu version: ";
  var solvuuVersion;
  var exit$2 = 0;
  if (pingData !== undefined) {
    var match$4 = pingData[1];
    if (typeof match$4 === "number") {
      exit$2 = 1;
    } else {
      var match$5 = match$4[1].info;
      if (match$5[0] >= 17692) {
        var version = match$5[1].version;
        solvuuVersion = React.createElement("p", undefined, field$1, React.createElement("a", {
                  href: Curry._1(Import.sprintf(/* Format */[
                            /* String_literal */Block.__(11, [
                                "https://github.com/solvuu-inc/solvuu-root/tree/",
                                /* String */Block.__(2, [
                                    /* No_padding */0,
                                    /* End_of_format */0
                                  ])
                              ]),
                            "https://github.com/solvuu-inc/solvuu-root/tree/%s"
                          ]), version),
                  rel: "noopener noreferrer",
                  target: "_blank"
                }, version));
      } else {
        exit$2 = 1;
      }
    }
  } else {
    exit$2 = 1;
  }
  if (exit$2 === 1) {
    solvuuVersion = React.createElement("p", undefined, "solvuu version: unknown");
  }
  var field$2 = "solvuu build time: ";
  var solvuuBuildTime;
  var exit$3 = 0;
  if (pingData !== undefined) {
    var match$6 = pingData[1];
    if (typeof match$6 === "number") {
      exit$3 = 1;
    } else {
      var match$7 = match$6[1].info;
      if (match$7[0] >= 17692) {
        solvuuBuildTime = React.createElement("p", undefined, field$2, match$7[1].build_time);
      } else {
        exit$3 = 1;
      }
    }
  } else {
    exit$3 = 1;
  }
  if (exit$3 === 1) {
    solvuuBuildTime = React.createElement("p", undefined, "solvuu build time: unknown");
  }
  var submitButton = React.createElement(Button.make, Button.makeProps((function (param) {
              ping(/* () */0);
              return /* () */0;
            }), undefined, /* `Submit */[
            -828715976,
            Curry._5(Button.Type.Submit.make, match$1[0], undefined, undefined, undefined, /* () */0)
          ], "Send ping", /* () */0));
  return React.createElement("div", {
              className: styles.root
            }, React.createElement("section", {
                  className: styles.section
                }, React.createElement(Card.make, {
                      header: React.createElement(Card.Header.make, {
                            children: React.createElement("strong", undefined, "Diagnostics")
                          }),
                      children: null
                    }, env, pingError, lastSuccessAt, solvuuVersion, solvuuBuildTime, submitButton)));
}

var make = Admin;

export {
  styles ,
  make ,
  
}
/* styles Not a pure module */
