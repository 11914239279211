

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Int64 from "bs-platform/lib/es6/int64.js";
import * as JobJs from "./jobJs.bs.js";
import * as BlobJs from "./blobJs.bs.js";
import * as Import from "../../features/import.bs.js";
import * as BlobUtils from "./blobUtils.bs.js";
import * as Caml_int64 from "bs-platform/lib/es6/caml_int64.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Blob$SolvuuApi from "solvuu-api/./blob.bs.js";

function sourceIs(source, blob) {
  var match = Blob$SolvuuApi.source(blob.state);
  if (match !== undefined) {
    return match === source;
  } else {
    return false;
  }
}

function isStdout(param) {
  return sourceIs("file:/solvuu/output/__SOLVUU_META__/dev/stdout", param);
}

function isStderr(param) {
  return sourceIs("file:/solvuu/output/__SOLVUU_META__/dev/stderr", param);
}

function visibleOutputBlobs(blobs) {
  return Curry._2(Import.List.keep, blobs, (function (blob) {
                if (sourceIs("file:/solvuu/output/__SOLVUU_META__/dev/stdout", blob)) {
                  return false;
                } else {
                  return !sourceIs("file:/solvuu/output/__SOLVUU_META__/dev/stderr", blob);
                }
              }));
}

function outputTotalBytes(blobs) {
  return Curry._3(Import.List.reduce, Curry._2(Import.List.keepMap, blobs, BlobUtils.Re.totalBytes), Int64.zero, Caml_int64.add);
}

function jobMatchesFilter(job, includeSystemJobs) {
  return true;
}

var Re = {
  sourceIs: sourceIs,
  isStdout: isStdout,
  isStderr: isStderr,
  visibleOutputBlobs: visibleOutputBlobs,
  outputTotalBytes: outputTotalBytes,
  jobMatchesFilter: jobMatchesFilter
};

function outputBlobIds(jobJs) {
  var job = Curry._1(JobJs.convertFromJs, jobJs);
  return Curry._1(Import.List.toArray, job.output.blobs);
}

function logBlobIds(blobsJs) {
  var blobs = Curry._2(Import.List.map, Curry._1(Import.List.fromArray, blobsJs), BlobJs.convertFromJs);
  var stdoutBlobId = Belt_Option.map(Curry._2(Import.List.getBy, blobs, isStdout), (function (blob) {
          return blob.id;
        }));
  var stderrBlobId = Belt_Option.map(Curry._2(Import.List.getBy, blobs, isStderr), (function (blob) {
          return blob.id;
        }));
  return {
          stdout: stdoutBlobId,
          stderr: stderrBlobId
        };
}

function jobMatchesFilter$1(jobJs, includeSystemJobs) {
  var job = Curry._1(JobJs.convertFromJs, jobJs);
  return jobMatchesFilter(job, includeSystemJobs);
}

export {
  Re ,
  outputBlobIds ,
  logBlobIds ,
  jobMatchesFilter$1 as jobMatchesFilter,
  
}
/* JobJs Not a pure module */
