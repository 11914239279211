import React, { Component } from "react";
import PropTypes from "prop-types";
import sizeMe from "react-sizeme";
import { select, mouse } from "d3-selection";
import { extent, max, range } from "d3-array";
import { scaleLinear, scaleSequential } from "d3-scale";
import { axisBottom, axisLeft, axisRight } from "d3-axis";
import { hexbin as hexbinGenerator } from "d3-hexbin";
import { interpolateGnBu } from "d3-scale-chromatic";
import { format } from "d3-format";

import { getNiceDomain } from "components/Brushing";
import Tooltip, {
  TooltipValue
} from "containers/Entities/TaxonomicAnalysis/Biom/Tooltip";
import measureTextWidth from "lib/measureTextWidth";

import styles from "table/TableHexbinPlot.css";

function translate(x = 0, y = 0) {
  return `translate(${x} ${y})`;
}

function hexbinCount(data) {
  return data.length;
}

class HexbinPlot extends Component {
  static propTypes = {
    size: PropTypes.shape({
      width: PropTypes.number
    }).isRequired,
    points: PropTypes.arrayOf(
      PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired
      })
    ).isRequired,
    xAxisName: PropTypes.string.isRequired,
    yAxisName: PropTypes.string.isRequired
  };

  plotRootRef = React.createRef();
  tooltipRootRef = React.createRef();
  tooltipValueRef = React.createRef();

  renderD3Legend = (svg, color, width) => {
    const legendWidth = 20;
    const legendHeight = 200;
    const [domainStart, domainEnd] = color.domain();

    const legendSvg = svg
      .append("g")
      .attr("transform", translate(width, 0))
      .attr("data-testid", "legend");

    // Append gradient bar.
    const gradient = legendSvg
      .append("defs")
      .append("linearGradient")
      .attr("id", "gradient")
      .attr("x1", "0%") // bottom
      .attr("y1", "100%")
      .attr("x2", "0%") // to top
      .attr("y2", "0%")
      .attr("spreadMethod", "pad");

    // Programatically generate the gradient for the legend.
    // This creates an array of [offsetPercent, color] pairs as stop
    // values for legend.
    const offsetsWithColors = range(0, 1.01, 0.01).map(fraction => {
      const percent = fraction * 100;
      const value = domainStart + fraction * domainEnd;
      return [percent + "%", color(value)];
    });
    offsetsWithColors.forEach(([offset, color]) => {
      gradient
        .append("stop")
        .attr("offset", offset)
        .attr("stop-color", color)
        .attr("stop-opacity", 1);
    });

    // Draw the legend color bar.
    legendSvg
      .append("rect")
      .attr("x1", 0)
      .attr("y1", 0)
      .attr("width", legendWidth)
      .attr("height", legendHeight)
      .style("fill", "url(#gradient)");

    // Create a scale and axis for the legend.
    const legendScale = scaleLinear()
      .domain([domainStart, domainEnd])
      .range([legendHeight, 0]);

    const tickValues = legendScale
      .ticks(2)
      .filter(tick => Number.isInteger(tick));
    if (!tickValues.includes(domainStart)) tickValues.unshift(domainStart);
    if (!tickValues.includes(domainEnd)) tickValues.unshift(domainEnd);

    const legendAxis = axisRight()
      .scale(legendScale)
      .tickValues(tickValues)
      .tickFormat(format(",d"));

    // Draw the legend axis.
    legendSvg
      .append("g")
      .attr("class", "legend-axis")
      .attr("transform", translate(legendWidth, 0))
      .call(legendAxis);

    // Draw the legend label
    legendSvg
      .append("text")
      .attr("text-anchor", "middle")
      .attr("transform", `${translate(-10, legendHeight / 2)} rotate(-90)`)
      .text("counts");
  };

  renderD3Tooltip = (svg, hexbins) => {
    const tooltip = select(this.tooltipRootRef.current)
      .style("opacity", 0)
      .style("position", "absolute");
    const tooltipValue = select(this.tooltipValueRef.current);

    const mouseover = function(d) {
      tooltip.style("opacity", 1);
      select(this).style("stroke-width", "2");
    };
    const mousemove = function(d) {
      const position = mouse(svg.node());
      tooltip
        .style("left", position[0] + 70 + "px")
        .style("top", position[1] + "px");
      tooltipValue.text(hexbinCount(d));
    };
    const mouseleave = function(d) {
      tooltip.style("opacity", 0);
      select(this).style("stroke-width", "1");
    };
    hexbins
      .on("mouseover", mouseover)
      .on("mousemove", mousemove)
      .on("mouseleave", mouseleave);
  };

  renderD3Hexbin = () => {
    const { points, size, xAxisName, yAxisName } = this.props;
    const totalWidth = Math.min(size.width, size.height) || 600;
    const totalHeight = totalWidth;

    // Set the dimensions and margins of the plot area.
    const margin = { top: 60, right: 60, bottom: 60, left: 60 };
    const width = totalWidth - margin.left - margin.right;
    const height = totalHeight - margin.top - margin.bottom;

    const selection = select(this.plotRootRef.current);

    // Clear the existing visualization.
    // Only remove the <svg> node, because the container has other nodes which are used for size detection.
    selection.selectAll("svg").remove();

    // Append and position the svg node in the container.
    const svg = selection
      .append("svg")
      .attr("width", totalWidth)
      .attr("height", totalHeight)
      .append("g")
      .attr("transform", translate(margin.left, margin.top));

    // Always fit a certain number of hexbins into the plot.
    const hexbinsPerAxis = 75;
    const hexbinRadius = width / hexbinsPerAxis;

    // Add padding to both ends of the axis to avoid clipping hexbins on the boundary.
    const axisPadding = hexbinRadius;

    // Add X axis.
    const xDomain = getNiceDomain(extent(points, p => p.x));
    const x = scaleLinear()
      .domain(xDomain)
      .range([axisPadding, width - axisPadding]);
    const xTicks = x.ticks();
    const xTicksFormatted = xTicks.map(tick => x.tickFormat()(tick));
    const xAxis = axisBottom(x).tickValues(xTicks);
    // Rotate x axis ticks 90 degrees if they would overlap.
    const maxXTickWidth = max(xTicksFormatted, tick => measureTextWidth(tick));
    const rotateXTicks = maxXTickWidth > width / xTicks.length;
    const xTicksRotation = rotateXTicks ? "translate(-14, 10) rotate(-90)" : "";
    const xTicksAnchor = rotateXTicks ? "end" : "middle";
    const xAxisLabelOffset = rotateXTicks ? maxXTickWidth + 40 : 40;
    // Add bottom margin to the chart to fit the x axis label.
    selection.style(
      "margin-bottom",
      Math.max(0, xAxisLabelOffset - margin.bottom) + "px"
    );
    svg
      .append("g")
      .attr("transform", translate(0, height))
      .attr("class", "x-axis")
      .call(xAxis)
      .selectAll("text")
      .attr("transform", xTicksRotation)
      .attr("text-anchor", xTicksAnchor);
    svg
      .append("text")
      .attr("transform", translate(width / 2, height + xAxisLabelOffset))
      .attr("text-anchor", "middle")
      .attr("data-testid", "x-axis-label")
      .text(xAxisName);

    // Add Y axis.
    const yDomain = getNiceDomain(extent(points, p => p.y));
    const y = scaleLinear()
      .domain(yDomain)
      .range([height - axisPadding, axisPadding]);
    const yTicks = y.ticks();
    const yTicksFormatted = yTicks.map(tick => y.tickFormat()(tick));
    const yAxis = axisLeft(y).tickValues(yTicks);
    const maxYTickWidth = max(yTicksFormatted, tick => measureTextWidth(tick));
    const yAxisLabelOffset = -maxYTickWidth - 20;
    svg
      .append("g")
      .attr("class", "y-axis")
      .call(yAxis);
    svg
      .append("text")
      .attr(
        "transform",
        `${translate(yAxisLabelOffset, height / 2)} rotate(-90)`
      )
      .attr("text-anchor", "middle")
      .attr("data-testid", "y-axis-label")
      .text(yAxisName);

    // Connect the axes at coordinate origin.
    svg
      .append("path")
      .attr(
        "d",
        `M 0.5 ${height - axisPadding + 0.5} v ${axisPadding} h ${axisPadding}`
      )
      .attr("stroke-width", "1")
      .attr("stroke", "#000")
      .attr("fill", "transparent");

    // Reformat the data: d3.hexbin() needs a specific format.
    const hexbinInput = points.map(d => [x(d.x), y(d.y)]);

    // Compute the hexbin data.
    const hexbin = hexbinGenerator()
      .radius(hexbinRadius)
      .extent([[0, 0], [width, height]]);

    const hexbinData = hexbin(hexbinInput);

    // Prepare a color palette.
    const maxCount = max(hexbinData, hexbinCount);
    // Boost the lower values in interpolated 0 - 1 range.
    // This results in shifting the color scale and darker colors for lower values.
    const colorInterpolatorBoost = scaleLinear()
      .domain([0, 1])
      .range([0.15, 1]);
    const colorInterpolator = t => interpolateGnBu(colorInterpolatorBoost(t));
    const color = scaleSequential()
      .domain([1, maxCount])
      .interpolator(colorInterpolator);

    // Plot the hexbins.
    svg
      .append("clipPath")
      .attr("id", "clip")
      .append("rect")
      .attr("width", width)
      .attr("height", height);
    const hexbins = svg
      .append("g")
      .attr("clip-path", "url(#clip)")
      .selectAll("path")
      .data(hexbinData)
      .enter()
      .append("path")
      .attr("d", hexbin.hexagon())
      .attr("transform", d => translate(d.x, d.y))
      .attr("fill", d => color(hexbinCount(d)))
      .attr("stroke", "#000")
      .attr("stroke-width", "1")
      .attr("stroke-opacity", "0.2")
      .attr("data-testid", "hexbin");

    this.renderD3Legend(svg, color, width);
    this.renderD3Tooltip(svg, hexbins);
  };

  componentDidMount() {
    this.renderD3Hexbin();
  }

  componentDidUpdate() {
    this.renderD3Hexbin();
  }

  render() {
    return (
      <div className={styles.plotRoot} ref={this.plotRootRef}>
        <div ref={this.tooltipRootRef}>
          <Tooltip>
            <TooltipValue>
              Count: <span ref={this.tooltipValueRef} />
            </TooltipValue>
          </Tooltip>
        </div>
      </div>
    );
  }
}

const SizedHexbinPlot = sizeMe({
  refreshRate: 100,
  noPlaceholder: process.env.NODE_ENV === "test",
  monitorWidth: true,
  monitorHeight: true
})(HexbinPlot);

// Injects the style into sizeMe placeholder, for correct size measurement
export default props => (
  <SizedHexbinPlot className={styles.plotRoot} {...props} />
);
