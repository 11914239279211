

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Import from "../features/import.bs.js";
import * as Std$Bolt from "bolt/./std.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as AreaChartJs from "./AreaChart.js";
import * as Root$SolvuuApi from "solvuu-api/./root.bs.js";
import * as Js_null_undefined from "bs-platform/lib/es6/js_null_undefined.js";

function measuresOfString(string) {
  switch (string) {
    case "Absolute" :
        return /* `Ok */[
                17724,
                /* Absolute */491227799
              ];
    case "Relative" :
        return /* `Ok */[
                17724,
                /* Relative */260471020
              ];
    default:
      return /* Invalid */144245463;
  }
}

function ofRecord(record) {
  return Curry._1(Std$Bolt.Res.join, Curry._2(Root$SolvuuApi.Area_chart.T.of_record, record, (function (areas, groups, measures) {
                    var match = measuresOfString(measures);
                    if (typeof match === "number") {
                      return /* `Error */[
                              106380200,
                              /* :: */[
                                /* tuple */[
                                  Root$SolvuuApi.Area_chart.T.Const.Label.measures,
                                  /* Invalid */144245463
                                ],
                                /* [] */0
                              ]
                            ];
                    } else {
                      var areas$1 = Curry._1(Import.List.toArray, Curry._2(Import.List.map, areas, (function (param) {
                                  return {
                                          name: param[0],
                                          data: Belt_Array.map(param[1], Js_null_undefined.fromOption)
                                        };
                                })));
                      var groups$1 = Curry._1(Import.List.toArray, Curry._2(Import.List.map, groups, (function (param) {
                                  return {
                                          name: param[0],
                                          sum: param[1]
                                        };
                                })));
                      return /* `Ok */[
                              17724,
                              {
                                areas: areas$1,
                                groups: groups$1,
                                measures: match[1]
                              }
                            ];
                    }
                  })));
}

function ofTerm(term) {
  if (typeof term === "number" || term[0] !== 847309489) {
    return /* `Error */[
            106380200,
            /* Expected_record */-756637544
          ];
  } else {
    var res = ofRecord(term[1]);
    return Curry._2(Std$Bolt.Res.map_error, res, (function (e) {
                  return /* `Record */[
                          847309489,
                          e
                        ];
                }));
  }
}

var Props = {
  absolute: /* Absolute */491227799,
  relative: /* Relative */260471020,
  measuresOfString: measuresOfString,
  ofRecord: ofRecord,
  ofTerm: ofTerm
};

var make = AreaChartJs.default;

export {
  Props ,
  make ,
  
}
/* make Not a pure module */
