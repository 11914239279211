

import * as Link from "../containers/Link.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Import from "../features/import.bs.js";
import * as $$String from "bs-platform/lib/es6/string.js";
import * as Pervasives from "bs-platform/lib/es6/pervasives.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as ReactExtras from "../lib/solvuu/ReactExtras.bs.js";
import * as ReactBootstrap from "../lib/ReactBootstrap.bs.js";
import * as StyleCss from "./Button/style.css";
import * as Lib from "react-bootstrap/lib";
import * as SubmitButtonCss from "./Button/SubmitButton.css";

var styles = StyleCss.default;

var styles$1 = SubmitButtonCss.default;

var Variant = {
  primary: /* Primary */-791844958,
  secondary: /* Secondary */67972948,
  text: /* Text */936573133
};

function make(className, variant, disabled, param) {
  return {
          className: className,
          variant: variant,
          disabled: disabled
        };
}

var Basic = {
  make: make
};

function make$1($staropt$star, $staropt$star$1, disabled, $staropt$star$2, param) {
  var isSubmitting = $staropt$star !== undefined ? $staropt$star : false;
  var className = $staropt$star$1 !== undefined ? $staropt$star$1 : styles$1.root;
  var variant = $staropt$star$2 !== undefined ? $staropt$star$2 : /* Primary */-791844958;
  return {
          className: className,
          variant: variant,
          disabled: disabled,
          isSubmitting: isSubmitting
        };
}

var Submit = {
  make: make$1
};

function basic(x) {
  return /* `Basic */[
          -1058563442,
          x
        ];
}

function getBasicExn(x) {
  if (x[0] >= -828715976) {
    return Pervasives.failwith("ERROR: Expected Basic");
  } else {
    return x[1];
  }
}

function submit(x) {
  return /* `Submit */[
          -828715976,
          x
        ];
}

function getSubmitExn(x) {
  if (x[0] >= -828715976) {
    return x[1];
  } else {
    return Pervasives.failwith("ERROR: Expected Submit");
  }
}

function getClassName(className, $staropt$star, param) {
  var variant = $staropt$star !== undefined ? $staropt$star : /* Secondary */67972948;
  return $$String.concat(" ", Curry._2(Import.List.keepMap, /* :: */[
                  className,
                  /* :: */[
                    styles.root,
                    /* :: */[
                      variant !== 67972948 ? (
                          variant >= 936573133 ? styles.text : styles.primary
                        ) : styles.secondary,
                      /* [] */0
                    ]
                  ]
                ], (function (x) {
                    return x;
                  })));
}

function makeProps(onClick, dataTestId, type_, children, param) {
  return {
          onClick: onClick,
          "data-testid": dataTestId,
          type: type_,
          children: children
        };
}

function make$2(props) {
  var match = props.type;
  var type_ = match !== undefined && match[0] >= -828715976 ? "submit" : "button";
  var match$1 = props.type;
  var disabled;
  if (match$1 !== undefined) {
    var match$2 = match$1;
    if (match$2[0] >= -828715976) {
      var match$3 = match$2[1];
      var disabled$1 = match$3.disabled;
      disabled = disabled$1 !== undefined && disabled$1 ? disabled$1 : match$3.isSubmitting;
    } else {
      disabled = match$2[1].disabled;
    }
  } else {
    disabled = undefined;
  }
  var match$4 = props.type;
  var glyphicon;
  if (match$4 !== undefined) {
    var match$5 = match$4;
    glyphicon = match$5[0] >= -828715976 && match$5[1].isSubmitting ? React.createElement(Lib.Glyphicon, ReactBootstrap.Glyphicon.makeProps(/* refresh */-316614181, /* () */0)) : React.createElement(React.Fragment, undefined);
  } else {
    glyphicon = React.createElement(React.Fragment, undefined);
  }
  var match$6 = props.type;
  var tmp;
  if (match$6 !== undefined) {
    var match$7 = match$6;
    tmp = match$7[0] >= -828715976 ? match$7[1].className : match$7[1].className;
  } else {
    tmp = undefined;
  }
  var match$8 = props.type;
  var tmp$1;
  if (match$8 !== undefined) {
    var match$9 = match$8;
    tmp$1 = match$9[0] >= -828715976 ? match$9[1].variant : match$9[1].variant;
  } else {
    tmp$1 = undefined;
  }
  var className = getClassName(tmp, tmp$1, /* () */0);
  var tmp$2 = {
    className: className,
    type: type_
  };
  if (disabled !== undefined) {
    tmp$2.disabled = Caml_option.valFromOption(disabled);
  }
  if (props.onClick !== undefined) {
    tmp$2.onClick = Caml_option.valFromOption(props.onClick);
  }
  var elem = React.createElement("button", tmp$2, glyphicon, props.children);
  var match$10 = props["data-testid"];
  if (match$10 !== undefined) {
    return ReactExtras.withTestId(match$10, elem);
  } else {
    return elem;
  }
}

var Type = {
  button: /* Button */242538002,
  submit: /* Submit */-828715976
};

function makeProps$1(dataTestId, to_, variant, type_, className, children, param) {
  return {
          "data-testid": dataTestId,
          to: to_,
          variant: variant,
          type: type_,
          className: className,
          children: children
        };
}

function make$3(props) {
  var className = getClassName(props.className, props.variant, /* () */0);
  var match = props.type;
  var type_ = match !== undefined && match < 242538002 ? "submit" : "button";
  var elem = React.createElement(Link.make, {
        to: props.to,
        className: className,
        type: type_,
        children: props.children
      });
  var match$1 = props["data-testid"];
  if (match$1 !== undefined) {
    return ReactExtras.withTestId(match$1, elem);
  } else {
    return elem;
  }
}

var Link$1 = {
  Type: Type,
  makeProps: makeProps$1,
  make: make$3
};

var Type$1 = {
  Basic: Basic,
  Submit: Submit,
  basic: basic,
  submit: submit,
  getBasicExn: getBasicExn,
  getSubmitExn: getSubmitExn
};

export {
  Variant ,
  Type$1 as Type,
  getClassName ,
  makeProps ,
  make$2 as make,
  Link$1 as Link,
  
}
/* styles Not a pure module */
