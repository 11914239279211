/* eslint-disable react/style-prop-object */
import React, { Component } from "react";
import {
  Area,
  AreaChart as RechartsAreaChart,
  CartesianGrid,
  Legend,
  Text,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import sizeMe from "react-sizeme";
import { FormattedNumber } from "react-intl";
import { max } from "d3-array";
import { mergeAll } from "ramda";

import { categoricalSimple as categorical } from "lib/colors";
import measureTextWidth from "lib/measureTextWidth";
import * as LangAreaChart from "lang/AreaChart.bs.js";

class AreaChart extends Component {
  render() {
    const { areas, groups, measures, size } = this.props;

    const areaNames = areas.map(area => area.name);
    const groupNames = groups.map(group => group.name);
    const groupNameKey = "$__name__";

    // Prepare the data in a format required by recharts.
    const rechartsData = groups.map((group, groupIdx) => {
      const groupData = areas.map(area => ({
        [area.name]: area.data[groupIdx]
      }));
      const groupNameData = { [groupNameKey]: group.name };
      return mergeAll([groupNameData, ...groupData]);
    });
    const rechartsStackOffset =
      measures === LangAreaChart.Props.relative ? "expand" : "none";

    // Rescale the x-axis height based on the tick text width.
    const tickFontSize = 14;
    const maxGroupNameWidth = max(groupNames, name =>
      measureTextWidth(name, tickFontSize)
    );
    const groupAxisHeight = maxGroupNameWidth + 20;

    // Always rotate x-axis ticks.
    const groupAxisTick = { angle: -90, textAnchor: "end", dx: -5 };

    // Add right margin to avoid clipping of the rightmost x-axis tick.
    const margin = { right: 10 };
    const width = size.width || 1000;
    const height = 300 + groupAxisHeight;

    // Construct a hash table for fast group by name lookup.
    const groupsByName = groups.reduce(
      (acc, group) => ({ ...acc, [group.name]: group }),
      {}
    );

    function tooltipFormatter(value, _name, tooltipProps) {
      const groupName = tooltipProps.payload[groupNameKey];
      const groupSum = groupsByName[groupName].sum;
      // It does not make sense to show percentage when:
      // The sum is 0 - the percentage is not defined
      // There's just one area - the percentage is always going to be 100%
      const showPercentage = groupSum > 0 && areas.length > 1;
      const percentage = value / groupSum;

      return (
        <span>
          <FormattedNumber value={value} />
          {showPercentage && (
            <span>
              {" "}
              (
              <FormattedNumber
                style="percent"
                minimuFractionDigits={2}
                value={percentage}
              />
              )
            </span>
          )}
        </span>
      );
    }

    function yAxisTick(props) {
      if (measures === LangAreaChart.Props.relative) {
        return (
          <FormattedNumber
            style="percent"
            minimuFractionDigits={2}
            value={props.payload.value}
          >
            {text => <Text {...props}>{text}</Text>}
          </FormattedNumber>
        );
      } else {
        return (
          <FormattedNumber value={props.payload.value}>
            {text => <Text {...props}>{text}</Text>}
          </FormattedNumber>
        );
      }
    }

    const yAxisLabel =
      measures === LangAreaChart.Props.relative ? "Share" : "Value";

    // Top-level div element helps react-sizeme to measure the width
    return (
      <div>
        <RechartsAreaChart
          width={width}
          height={height}
          margin={margin}
          data={rechartsData}
          stackOffset={rechartsStackOffset}
        >
          <XAxis
            dataKey={groupNameKey}
            height={groupAxisHeight}
            tick={groupAxisTick}
            type="category"
            interval={0}
          />
          <YAxis
            type="number"
            width={100}
            label={{
              value: yAxisLabel,
              angle: -90,
              position: "insideLeft",
              dy: 20
            }}
            tick={yAxisTick}
          />
          <Tooltip formatter={tooltipFormatter} />
          <Legend />
          <CartesianGrid
            horizontal={true}
            vertical={false}
            strokeDasharray="3 3"
          />
          {areaNames.map((areaName, areaIdx) => {
            const color = categorical[areaIdx];
            return (
              <Area
                key={areaName}
                dataKey={areaName}
                fill={color}
                stroke={color}
                strokeWidth={0}
                stackId="1"
                isAnimationActive={false}
                activeDot={{ strokeWidth: 0, stroke: color }}
              />
            );
          })}
        </RechartsAreaChart>
      </div>
    );
  }
}

const noPlaceholder = process.env.NODE_ENV === "test";
const refreshRate = 100;
const enhance = sizeMe({ refreshRate, noPlaceholder });

export default enhance(AreaChart);
