import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactModal from "react-modal";

import styles from "./style.css";

export default class Modal extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    onClose: PropTypes.func.isRequired,
    maxWidth: PropTypes.number.isRequired
  };

  static defaultProps = {
    open: true,
    maxWidth: 600
  };

  render() {
    const { header, open, onClose, maxWidth, children } = this.props;

    return (
      <ReactModal
        isOpen={open}
        onRequestClose={onClose}
        className={styles.modal}
        overlayClassName={styles.overlay}
        style={{ content: { maxWidth: maxWidth } }}
      >
        {header && React.cloneElement(header, { onClose })}
        {children}
      </ReactModal>
    );
  }
}

export class ModalBody extends Component {
  render() {
    return (
      <div className={styles.body} data-testid="modal-body">
        {this.props.children}
      </div>
    );
  }
}

export class ModalHeader extends Component {
  render() {
    return (
      <div className={styles.header} data-testid="modal-header">
        {this.props.children}
        <button
          type="button"
          className={styles.close}
          title="Close"
          onClick={this.props.onClose}
          data-testid="modal-close"
        />
      </div>
    );
  }
}

export class ModalFooter extends Component {
  render() {
    return <footer className={styles.footer}>{this.props.children}</footer>;
  }
}

ReactModal.setAppElement(process.env.NODE_ENV === "test" ? "body" : "#root");
