

import * as Op from "../../features/Op.bs.js";
import * as Auth from "../../features/Auth.bs.js";
import * as Link from "../Link.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Button from "../../components/Button.bs.js";
import * as FormGroup from "../../components/FormGroup/FormGroup.bs.js";
import * as FormLayout from "../../components/FormLayout/FormLayout.bs.js";
import * as ReactIntl from "react-intl";
import * as StyleCss from "./style.css";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as PublicActionCard from "../../components/PublicActionCard/PublicActionCard.bs.js";
import * as ReasonReactRouter from "reason-react/src/ReasonReactRouter.js";

var AmazonCognitoIdentityJS = { };

var styles = StyleCss.default;

function Login$PasswordForm(Props) {
  var state = Props.state;
  var onSubmit = Belt_Option.map(Op.run(state), (function (run, $$event) {
          $$event.preventDefault();
          return Curry._1(run, /* () */0);
        }));
  var match = Auth.Password.error(state);
  var passwordFieldErrors = match !== undefined ? /* array */[{
        field: "password",
        message: match
      }] : /* array */[];
  var match$1 = Auth.Password.error(state);
  var validationState = match$1 !== undefined ? "error" : null;
  var tmp = { };
  if (onSubmit !== undefined) {
    tmp.onSubmit = Caml_option.valFromOption(onSubmit);
  }
  return React.createElement("form", tmp, React.createElement(FormLayout.VerticalFormFields.make, {
                  children: null
                }, React.createElement(FormLayout.VerticalFormField.make, {
                      children: React.createElement(FormGroup.ControlledFormGroup.make, {
                            value: Auth.Password.username(state),
                            type: "text",
                            onFieldChange: (function (param) {
                                return Auth.Password.setUsername(state, param);
                              }),
                            name: "username",
                            required: true,
                            validationState: validationState,
                            label: React.createElement(ReactIntl.FormattedMessage, {
                                  id: "login.username"
                                })
                          })
                    }), React.createElement(FormLayout.VerticalFormField.make, {
                      children: React.createElement(FormGroup.ControlledFormGroup.make, {
                            value: Auth.Password.password(state),
                            type: "password",
                            onFieldChange: (function (param) {
                                return Auth.Password.setPassword(state, param);
                              }),
                            name: "password",
                            errors: passwordFieldErrors,
                            required: true,
                            validationState: validationState,
                            label: React.createElement(ReactIntl.FormattedMessage, {
                                  id: "login.password"
                                })
                          })
                    })), React.createElement(FormLayout.VerticalFormActions.make, {
                  children: React.createElement("div", {
                        className: styles.actions
                      }, React.createElement(Button.make, Button.makeProps(undefined, undefined, /* `Submit */[
                                -828715976,
                                Curry._5(Button.Type.Submit.make, Op.isRunning(state), undefined, undefined, undefined, /* () */0)
                              ], React.createElement(ReactIntl.FormattedMessage, {
                                    id: "login.submit"
                                  }), /* () */0)), React.createElement("p", {
                            className: styles.linkActions
                          }, React.createElement(Link.make, {
                                to: /* `PasswordReset */[
                                  -799423340,
                                  /* Request */299239215
                                ],
                                className: styles.linkAction,
                                children: React.createElement(ReactIntl.FormattedMessage, {
                                      id: "login.passwordResetLink"
                                    })
                              }), " | ", React.createElement(Link.make, {
                                to: /* `Registration */[
                                  251200025,
                                  /* Request */299239215
                                ],
                                className: styles.linkAction,
                                children: React.createElement(ReactIntl.FormattedMessage, {
                                      id: "login.registrationLink"
                                    })
                              })))
                }));
}

var PasswordForm = {
  make: Login$PasswordForm
};

function Login$MFAForm(Props) {
  var state = Props.state;
  var errors = Belt_Option.mapWithDefault(Auth.Mfa.error(state), /* array */[], (function (error) {
          return /* array */[{
                    field: "mfaCode",
                    message: error
                  }];
        }));
  var onSubmit = Belt_Option.map(Op.run(state), (function (run, $$event) {
          $$event.preventDefault();
          return Curry._1(run, /* () */0);
        }));
  var tmp = { };
  if (onSubmit !== undefined) {
    tmp.onSubmit = Caml_option.valFromOption(onSubmit);
  }
  return React.createElement("form", tmp, React.createElement(FormLayout.VerticalFormFields.make, {
                  children: React.createElement(FormLayout.VerticalFormField.make, {
                        children: React.createElement(FormGroup.ControlledFormGroup.make, {
                              value: Auth.Mfa.code(state),
                              type: "text",
                              onFieldChange: (function (param) {
                                  return Auth.Mfa.setCode(state, param);
                                }),
                              name: "mfaCode",
                              errors: errors,
                              required: true,
                              info: React.createElement(ReactIntl.FormattedMessage, {
                                    id: "login.mfaCode.info",
                                    values: {
                                      destination: Auth.Mfa.destination(state)
                                    }
                                  }),
                              validationState: null,
                              label: React.createElement(ReactIntl.FormattedMessage, {
                                    id: "login.mfaCode"
                                  })
                            })
                      })
                }), React.createElement(FormLayout.VerticalFormActions.make, {
                  children: React.createElement(Button.make, Button.makeProps(undefined, undefined, /* `Submit */[
                            -828715976,
                            Curry._5(Button.Type.Submit.make, Op.isRunning(state), undefined, undefined, undefined, /* () */0)
                          ], React.createElement(ReactIntl.FormattedMessage, {
                                id: "login.submitMFA"
                              }), /* () */0))
                }));
}

var MFAForm = {
  make: Login$MFAForm
};

function Login(Props) {
  var state = Props.state;
  var variant = state[0];
  var tmp;
  if (variant >= 578936635) {
    if (variant >= 648407695) {
      ReasonReactRouter.push("/");
      tmp = React.createElement(React.Fragment, undefined);
    } else {
      var state$1 = state[1];
      tmp = state$1[0] >= 951752159 ? React.createElement(PublicActionCard.make, {
              children: null
            }, React.createElement(PublicActionCard.Title.make, {
                  id: "login.header"
                }), React.createElement(Login$PasswordForm, {
                  state: state$1
                })) : React.createElement(PublicActionCard.make, {
              children: null
            }, React.createElement(PublicActionCard.Title.make, {
                  id: "login.header"
                }), React.createElement(Login$PasswordForm, {
                  state: state$1
                }));
    }
  } else if (variant >= 43282345) {
    tmp = "Loading...";
  } else {
    var state$2 = state[1];
    tmp = state$2[0] >= 951752159 ? React.createElement(PublicActionCard.make, {
            children: null
          }, React.createElement(PublicActionCard.Title.make, {
                id: "login.header"
              }), React.createElement(Login$MFAForm, {
                state: state$2
              })) : React.createElement(PublicActionCard.make, {
            children: null
          }, React.createElement(PublicActionCard.Title.make, {
                id: "login.header"
              }), React.createElement(Login$MFAForm, {
                state: state$2
              }));
  }
  return React.createElement("div", {
              className: styles.root
            }, tmp);
}

var Cognito = /* alias */0;

var make = Login;

export {
  Cognito ,
  AmazonCognitoIdentityJS ,
  styles ,
  PasswordForm ,
  MFAForm ,
  make ,
  
}
/* styles Not a pure module */
