import React, { Component } from "react";

import Breadcrumbs from "./Breadcrumbs";
import LoadingStatus from "./LoadingStatus";
import styles from "./style.css";

class PageHeader extends Component {
  render() {
    return <section className={styles.root}>{this.props.children}</section>;
  }
}

class FillSpace extends Component {
  render() {
    return <div className={styles.fillSpace} />;
  }
}

class Slot extends Component {
  render() {
    return <div className={styles.slot}>{this.props.children}</div>;
  }
}

class Name extends Component {
  render() {
    return <div className={styles.name}>{this.props.children}</div>;
  }
}

PageHeader.Breadcrumbs = Breadcrumbs;
PageHeader.FillSpace = FillSpace;
PageHeader.LoadingStatus = LoadingStatus;
PageHeader.Name = Name;
PageHeader.Slot = Slot;

export default PageHeader;
