

import * as Err from "../../components/Err.bs.js";
import * as Card from "../../components/Card/Card.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Latch from "../../components/Latch/Latch.bs.js";
import * as React from "react";
import * as Button from "../../components/Button.bs.js";
import * as Import from "../../features/import.bs.js";
import * as $$String from "bs-platform/lib/es6/string.js";
import * as Loading from "../../components/Loading.bs.js";
import * as Upload0 from "./Upload0.bs.js";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as UploadRow from "./UploadRow/UploadRow.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as WithTestId from "../WithTestId.bs.js";
import * as ReactIntl from "react-intl";
import * as StyleCss from "./style.css";
import * as NewURIModal from "./NewURIModal/NewURIModal.bs.js";
import * as ReactExtras from "../../lib/solvuu/ReactExtras.bs.js";
import * as VerticalLayout from "../../components/Layout/VerticalLayout.bs.js";
import * as Layout from "components/Layout";
import * as PageHeader from "components/PageHeader";

var styles = StyleCss.default;

function renderNewFilesButton(handleFilesSelection, fileInputRef, handleAddNewFilesClick, blobsCreateFromFiles) {
  return React.createElement(React.Fragment, undefined, React.createElement(WithTestId.make, {
                  id: "uploads-new-files",
                  children: React.createElement(Button.make, Button.makeProps(handleAddNewFilesClick, undefined, /* `Submit */[
                            -828715976,
                            Curry._5(Button.Type.Submit.make, blobsCreateFromFiles.pending, undefined, undefined, undefined, /* () */0)
                          ], React.createElement(ReactIntl.FormattedMessage, {
                                id: "upload.actions.newFiles"
                              }), /* () */0))
                }), React.createElement(WithTestId.make, {
                  id: "uploads-new-files-input",
                  children: React.createElement("input", {
                        ref: fileInputRef,
                        className: styles.fileInput,
                        multiple: true,
                        type: "file",
                        onChange: handleFilesSelection
                      })
                }));
}

function initialState(prevSucc) {
  return {
          uriModal: false,
          prevSucc: prevSucc
        };
}

var State = {
  initialState: initialState
};

function renderNewURIButton(handleAddNewURIClick, handleAddNewURIClose, handleURISubmit, uriModal, status) {
  return React.createElement(React.Fragment, undefined, React.createElement(WithTestId.make, {
                  id: "uploads-new-uri",
                  children: React.createElement(Button.make, Button.makeProps(handleAddNewURIClick, undefined, /* `Submit */[
                            -828715976,
                            Curry._5(Button.Type.Submit.make, false, undefined, undefined, undefined, /* () */0)
                          ], React.createElement(ReactIntl.FormattedMessage, {
                                id: "upload.actions.newURI"
                              }), /* () */0))
                }), uriModal ? React.createElement(NewURIModal.make, {
                    onSubmit: handleURISubmit,
                    onClose: handleAddNewURIClose,
                    status: status
                  }) : null);
}

function renderOperationErrors(blobsCreateFromFiles) {
  var match = blobsCreateFromFiles.failure;
  if (match) {
    var match$1 = blobsCreateFromFiles.errors;
    var message = (match$1 == null) ? "No errors" : match$1.join(" ");
    return React.createElement(Layout.VerticalLayoutItem, {
                children: React.createElement(Err.Operation.make, {
                      message: message
                    })
              });
  } else {
    return null;
  }
}

function renderUploads(uploads, uploadIds, blobsFetch, apiClient, loaded) {
  var match = blobsFetch.failure;
  if (match) {
    return React.createElement(Loading.$$Error.make, { });
  } else {
    var match$1 = !loaded;
    if (match$1) {
      return React.createElement(Loading.make, { });
    } else {
      var match$2 = uploadIds.length !== 0;
      var content = match$2 ? React.createElement(Layout.VerticalLayout, VerticalLayout.makeProps(/* x2 */26810, ReactExtras.listToElement(Curry._1(Import.List.fromArray, Belt_Array.map(uploadIds, (function (id) {
                                var match = Belt_List.getAssoc(uploads, id, $$String.equal);
                                if (match !== undefined) {
                                  var match$1 = match;
                                  var meta = match$1.meta;
                                  var totalBytes = meta.totalBytes;
                                  var uploadedBytes = meta.uploadedBytes;
                                  var progressPercentage;
                                  if (totalBytes !== undefined) {
                                    var totalBytes$1 = totalBytes;
                                    progressPercentage = totalBytes$1 !== 0 ? uploadedBytes * 100 / totalBytes$1 : 100;
                                  } else {
                                    progressPercentage = 100;
                                  }
                                  var meta_uploadedBytes = meta.uploadedBytes;
                                  var meta_totalBytes = meta.totalBytes;
                                  var meta_isUploading = meta.isUploading;
                                  var meta_isUploaded = meta.isUploaded;
                                  var meta_uploadError = meta.uploadError;
                                  var meta$1 = {
                                    uploadedBytes: meta_uploadedBytes,
                                    totalBytes: meta_totalBytes,
                                    progressPercentage: progressPercentage,
                                    isUploading: meta_isUploading,
                                    isUploaded: meta_isUploaded,
                                    uploadError: meta_uploadError
                                  };
                                  return React.createElement(Layout.VerticalLayoutItem, {
                                              children: React.createElement(Card.make, {
                                                    children: React.createElement(UploadRow.make, {
                                                          id: id,
                                                          location: undefined,
                                                          originalName: match$1.originalName,
                                                          checksum: undefined,
                                                          meta: meta$1,
                                                          apiClient: apiClient
                                                        }),
                                                    key: id
                                                  }),
                                              key: id
                                            });
                                } else {
                                  return null;
                                }
                              })))), /* () */0)) : React.createElement("div", {
              className: styles.empty
            }, React.createElement(ReactIntl.FormattedMessage, {
                  id: "upload.uploads.empty"
                }));
      return React.createElement(WithTestId.make, {
                  id: "uploads",
                  children: React.createElement("div", undefined, content)
                });
    }
  }
}

function Upload(Props) {
  var fetchBlobs = Props.fetchBlobs;
  var uploadBlobsFromFiles = Props.uploadBlobsFromFiles;
  var createBlobFromURI = Props.createBlobFromURI;
  var uploadIds = Props.uploadIds;
  var uploads = Props.uploads;
  var meta = Props.meta;
  var apiClient = Props.apiClient;
  var match = React.useState((function () {
          return {
                  uriModal: false,
                  prevSucc: meta.blobCreateFromURI.success
                };
        }));
  var setState = match[1];
  var state = match[0];
  React.useEffect((function () {
          Curry._1(fetchBlobs, /* () */0);
          return ;
        }), /* array */[]);
  React.useEffect((function () {
          var match = !state.prevSucc && meta.blobCreateFromURI.success;
          if (match) {
            Curry._1(setState, (function (param) {
                    return {
                            uriModal: false,
                            prevSucc: true
                          };
                  }));
          }
          return ;
        }), /* array */[
        state.prevSucc,
        meta.blobCreateFromURI.success
      ]);
  var fileInputRef = React.default.createRef();
  var handleAddNewFilesClick = function (_event) {
    fileInputRef.current.click();
    return /* () */0;
  };
  var handleFilesSelection = function ($$event) {
    Curry._1(uploadBlobsFromFiles, Array.from($$event.target.files));
    $$event.target.value = null;
    return /* () */0;
  };
  var handleAddNewURIClick = function (param) {
    return Curry._1(setState, (function (param) {
                  return {
                          uriModal: true,
                          prevSucc: state.prevSucc
                        };
                }));
  };
  var handleAddNewURIClose = function (param) {
    return Curry._1(setState, (function (param) {
                  return {
                          uriModal: false,
                          prevSucc: state.prevSucc
                        };
                }));
  };
  var handleFilesDrop = function ($$event) {
    var files = $$event.dataTransfer.files.slice(0);
    if (files.length !== 0) {
      return Curry._1(uploadBlobsFromFiles, files);
    } else {
      return 0;
    }
  };
  var partial_arg = meta.blobsFetch;
  return React.createElement("div", {
              className: styles.root
            }, React.createElement(PageHeader.default, {
                  children: null
                }, React.createElement(PageHeader.default.Name, {
                      children: React.createElement(ReactIntl.FormattedMessage, {
                            id: "upload.header"
                          })
                    }), React.createElement(PageHeader.default.FillSpace, { }), React.createElement(PageHeader.default.Slot, {
                      children: renderNewFilesButton(handleFilesSelection, fileInputRef, handleAddNewFilesClick, meta.blobsCreateFromFiles)
                    }), React.createElement(PageHeader.default.Slot, {
                      children: renderNewURIButton(handleAddNewURIClick, handleAddNewURIClose, createBlobFromURI, state.uriModal, meta.blobCreateFromURI)
                    })), React.createElement(Layout.VerticalLayout, VerticalLayout.makeProps(/* x2 */26810, null, /* () */0), renderOperationErrors(meta.blobsCreateFromFiles), React.createElement(Layout.VerticalLayoutItem, {
                      children: React.createElement(Latch.make, {
                            value: meta.blobsFetch.success,
                            render: (function (param) {
                                return renderUploads(uploads, uploadIds, partial_arg, apiClient, param);
                              })
                          })
                    })), React.createElement(Upload0.WindowDragAndDrop.make, {
                  onDrop: handleFilesDrop
                }));
}

var $$Error = Upload0.$$Error;

var WindowDragAndDrop = Upload0.WindowDragAndDrop;

var Operations = Upload0.Operations;

var UploadT = Upload0.UploadT;

var make = Upload;

export {
  $$Error ,
  WindowDragAndDrop ,
  Operations ,
  UploadT ,
  styles ,
  renderNewFilesButton ,
  State ,
  renderNewURIButton ,
  renderOperationErrors ,
  renderUploads ,
  make ,
  
}
/* styles Not a pure module */
