import { combineReducers } from "redux";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";

import { getAuthenticatedUser } from "features/aws.js";
import {
  createOperation,
  createReducer,
  fallbackReducer,
  cognitoToPromise
} from "features/utils";
import mfaSettingsReducer, {
  initialState as mfaInitialState
} from "./mfaSettings";

const PROFILE_FORM_CHANGE = "PROFILE/PROFILE_FORM_CHANGE";
export const fetchOperation = createOperation("PROFILE/FETCH");
export const updateOperation = createOperation("PROFILE/UPDATE");

export function fetchCurrentProfile() {
  const { start, success, failure } = fetchOperation.actionCreators;

  return async function(dispatch, getState) {
    dispatch(start());
    try {
      const cognitoUser = await getAuthenticatedUser();
      const result = await cognitoToPromise(
        cognitoUser.getUserAttributes.bind(cognitoUser)
      );

      function getAttributeValue(name) {
        const attribute = result.find(a => a.getName() === name);
        return attribute ? attribute.getValue() : null;
      }

      const data = {};
      data.username = cognitoUser.getUsername();
      data.name = getAttributeValue("name");
      data.email = getAttributeValue("email");

      dispatch(success(data));
    } catch (e) {
      dispatch(failure());
    }
  };
}

export function profileFormChange(field, value) {
  return {
    type: PROFILE_FORM_CHANGE,
    payload: { field, value }
  };
}

export function updateCurrentProfile() {
  const { start, success, failure } = updateOperation.actionCreators;

  return async function(dispatch, getState) {
    dispatch(start());
    const { form } = profileSelector(getState());
    try {
      const cognitoUser = await getAuthenticatedUser();

      const attributeList = [];
      attributeList.push(
        new CognitoUserAttribute({ Name: "name", Value: form.name })
      );
      attributeList.push(
        new CognitoUserAttribute({ Name: "email", Value: form.email })
      );

      await cognitoToPromise(
        cognitoUser.updateAttributes.bind(cognitoUser),
        attributeList
      );
      dispatch(success(form));
    } catch (err) {
      dispatch(failure(extractErrorsFromUpdateResponse(err)));
    }
  };
}

export function updatePassword() {
  const { start, success, failure } = updateOperation.actionCreators;

  return async function(dispatch, getState) {
    dispatch(start());
    const { form } = profileSelector(getState());

    // Client validation
    const validationErrors = validateForm(form);
    if (validationErrors.length) {
      dispatch(failure(validationErrors));
      return;
    }

    // Perform the request
    try {
      const cognitoUser = await getAuthenticatedUser();
      await cognitoToPromise(
        cognitoUser.changePassword.bind(cognitoUser),
        form.currentPassword,
        form.newPassword
      );
      dispatch(success(form));
    } catch (e) {
      dispatch(failure(extractErrorsFromUpdateResponse(e)));
    }
  };
}

function validateForm(form) {
  let errors = [];
  if (form.newPassword !== form.newPasswordConfirmation) {
    errors.push({
      id:
        "settings.profile.changePassword.errors.newPasswordConfirmation.notMatching",
      field: "newPasswordConfirmation"
    });
  }

  return errors;
}

function extractErrorsFromUpdateResponse(error) {
  let errors = [];
  if (error.message) {
    errors.push({ message: error.message, field: null });
  }
  if (!errors.length) {
    errors.push({ id: "settings.profile.errors.unknown", field: null });
  }
  return errors;
}

export const initialState = {
  data: {
    name: null,
    username: null,
    email: null
  },
  form: {
    name: "",
    username: "",
    email: "",
    currentPassword: "",
    newPassword: "",
    newPasswordConfirmation: ""
  },
  meta: {
    fetch: fetchOperation.initialState,
    update: updateOperation.initialState
  },
  mfaSettings: mfaInitialState
};

export function profileSelector(state) {
  const { profile } = state;
  return profile;
}

export function currentProfileDataSelector(state) {
  return profileSelector(state).data;
}

function setProfileAttributesFromAction(state, action) {
  return {
    ...state,
    username: action.payload.username,
    name: action.payload.name,
    email: action.payload.email
  };
}

function setFormFieldFromAction(state, action) {
  return {
    ...state,
    [action.payload.field]: action.payload.value
  };
}

const dataReducer = createReducer(initialState.data, {
  [fetchOperation.actionTypes.SUCCESS]: setProfileAttributesFromAction,
  [updateOperation.actionTypes.SUCCESS]: setProfileAttributesFromAction
});

const formReducer = createReducer(initialState.form, {
  [fetchOperation.actionTypes.SUCCESS]: setProfileAttributesFromAction,
  [updateOperation.actionTypes.SUCCESS]: setProfileAttributesFromAction,
  [PROFILE_FORM_CHANGE]: setFormFieldFromAction
});

const updateOperationReducer = fallbackReducer(updateOperation.reducer, {
  [PROFILE_FORM_CHANGE]: state => ({ ...state, success: false })
});

const metaReducer = combineReducers({
  fetch: fetchOperation.reducer,
  update: updateOperationReducer
});

export default combineReducers({
  data: dataReducer,
  form: formReducer,
  meta: metaReducer,
  mfaSettings: mfaSettingsReducer
});
