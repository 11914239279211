

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Pervasives from "bs-platform/lib/es6/pervasives.js";
import * as TermRenderContext from "../../../lang/TermRenderContext.bs.js";

function EntityContents$Record(Props) {
  Props.path;
  return Pervasives.failwith("EntityContents.Record.make: Not implemented");
}

var Record = {
  make: EntityContents$Record
};

function EntityContents(Props) {
  var term = Props.term;
  var type_ = Props.type_;
  var path = Props.path;
  Props.name;
  var apiClient = Props.apiClient;
  var renderTerm = Props.renderTerm;
  if (typeof term === "number" || term[0] !== 3405101) {
    return Curry._4(renderTerm, term, type_, TermRenderContext.root, apiClient);
  } else {
    return React.createElement(EntityContents$Record, {
                path: path
              });
  }
}

var make = EntityContents;

export {
  Record ,
  make ,
  
}
/* react Not a pure module */
