

import * as AnalysisJs from "./Analysis.js";

var make = AnalysisJs.default;

export {
  make ,
  
}
/* make Not a pure module */
