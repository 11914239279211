

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as BlobJs from "../../lib/solvuu/blobJs.bs.js";
import * as Import from "../import.bs.js";
import * as TermJs from "../../lib/solvuu/termJs.bs.js";
import * as TypeJs from "../../lib/solvuu/typeJs.bs.js";
import * as Js_dict from "bs-platform/lib/es6/js_dict.js";
import * as BlobUtils from "../../lib/solvuu/blobUtils.bs.js";
import * as PathUtils from "../../lib/solvuu/pathUtils.bs.js";
import * as TypeUtils from "../../lib/solvuu/typeUtils.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Pervasives from "bs-platform/lib/es6/pervasives.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";

function constructEntities(term, type_, path, name, termJs, param) {
  if (typeof term !== "number" && term[0] === 3405101) {
    var term$1 = term;
    var type_$1 = type_;
    var path$1 = path;
    var name$1 = name;
    var data = typeof term$1 === "number" ? Pervasives.failwith("`Dir term expected, got " + TermJs.stringify(TermJs.convertToJs(term$1))) : (
        term$1[0] !== 3405101 ? Pervasives.failwith("`Dir term expected, got " + TermJs.stringify(TermJs.convertToJs(term$1))) : term$1[1].data
      );
    var match;
    if (data !== undefined) {
      var bindingTypesByName = typeof type_$1 === "number" ? Pervasives.failwith("`Dir term expected of type `Record, got type " + TypeJs.stringify(Curry._1(TypeJs.convertToJs, type_$1))) : (
          type_$1[0] !== 847309489 ? Pervasives.failwith("`Dir term expected of type `Record, got type " + TypeJs.stringify(Curry._1(TypeJs.convertToJs, type_$1))) : Js_dict.fromList(type_$1[1][0])
        );
      var match$1 = Curry._1(Import.List.unzip, Curry._2(Import.List.map, data, (function (param) {
                  var label = param[0];
                  var bindingName = label[0] >= 699531161 ? label[1] : String(label[1]);
                  var bindingPath = path$1 + ("/" + bindingName);
                  var bindingType = Belt_Option.getExn(Js_dict.get(bindingTypesByName, bindingName));
                  var entities = constructEntities(param[1], bindingType, bindingPath, bindingName, undefined, /* () */0);
                  return /* tuple */[
                          bindingPath,
                          entities
                        ];
                })));
      var nestedPaths = Curry._1(Import.List.toArray, match$1[0]);
      var __x = Curry._2(Import.List.map, match$1[1], Js_dict.entries);
      var nestedEntitiesByPath = Js_dict.fromList(Curry._1(Import.List.flatten, Curry._2(Import.List.map, __x, Import.List.fromArray)));
      match = /* tuple */[
        nestedPaths,
        nestedEntitiesByPath
      ];
    } else {
      match = /* tuple */[
        undefined,
        { }
      ];
    }
    var tmp = {
      name: name$1,
      path: path$1,
      term: TermJs.convertToJs(term$1),
      type: Curry._1(TypeJs.convertToJs, type_$1)
    };
    var tmp$1 = match[0];
    if (tmp$1 !== undefined) {
      tmp.nested = Caml_option.valFromOption(tmp$1);
    }
    var entity = tmp;
    var nestedEntities = Js_dict.entries(match[1]);
    return Js_dict.fromArray(Belt_Array.concat(/* array */[/* tuple */[
                      path$1,
                      entity
                    ]], nestedEntities));
  }
  var term$2 = term;
  var type_$2 = type_;
  var path$2 = path;
  var name$2 = name;
  var termJs$1 = termJs;
  var entityTermJs = termJs$1 !== undefined ? Caml_option.valFromOption(termJs$1) : TermJs.convertToJs(term$2);
  var entityTypeJs = Curry._1(TypeJs.convertToJs, type_$2);
  var entity$1 = {
    name: name$2,
    path: path$2,
    term: entityTermJs,
    type: entityTypeJs
  };
  return Js_dict.fromArray(/* array */[/* tuple */[
                path$2,
                entity$1
              ]]);
}

function parseResponse(responseJs, rootPath) {
  var termJs = Json_decode.field("term", TermJs.parseFromJson, responseJs);
  var typeJs = Json_decode.field("typ", TypeJs.parseFromJson, responseJs);
  var term = TermJs.convertFromJs(termJs);
  var type_ = TypeJs.convertFromJs(typeJs);
  var name = PathUtils.lastEntityName(rootPath);
  return constructEntities(term, type_, rootPath, name, Caml_option.some(termJs), /* () */0);
}

function parseBlobs(blobsJs, rootPath) {
  var blobs = Curry._2(Import.List.map, Curry._1(Import.List.fromArray, blobsJs), BlobJs.convertFromJs);
  var blobsWithBasePaths = Curry._2(Import.List.keepMap, Curry._2(Import.List.keepMap, blobs, (function (blob) {
              var match = BlobUtils.Re.sourceUri(blob);
              if (match !== undefined) {
                return /* tuple */[
                        blob,
                        match
                      ];
              }
              
            })), (function (param) {
          var uri = param[1];
          var match = uri.startsWith("file:/");
          if (match) {
            var basePath = uri.replace("file:", "");
            return /* tuple */[
                    param[0],
                    basePath
                  ];
          }
          
        }));
  var merge = function (_dir, term, components) {
    while(true) {
      var dir = _dir;
      if (typeof dir === "number" || dir[0] !== 3405101) {
        return Pervasives.failwith("Bug: expected a Dir");
      } else {
        var match = dir[1];
        var match$1 = match.data;
        var id = match.id;
        if (match$1 !== undefined) {
          var contents = match$1;
          if (components) {
            var rest = components[1];
            var last = components[0];
            if (rest) {
              var binding = Curry._2(Import.List.getBy, contents, (function(last){
                  return function (param) {
                    var label = param[0];
                    var variant = label[0];
                    if (variant !== 699531161) {
                      if (variant >= 948354667) {
                        return Pervasives.failwith("Bug: did not expect an user label");
                      } else {
                        return Pervasives.failwith("Bug: did not expect a positional label");
                      }
                    } else {
                      return label[1] === last.name;
                    }
                  }
                  }(last)));
              var newContents;
              if (binding !== undefined) {
                var binding$1 = binding;
                var label = binding$1[0];
                var newDir = merge(binding$1[1], term, rest);
                newContents = Curry._2(Import.List.map, contents, (function(label,binding$1,newDir){
                    return function (content) {
                      var match = content === binding$1;
                      if (match) {
                        return /* tuple */[
                                label,
                                newDir
                              ];
                      } else {
                        return content;
                      }
                    }
                    }(label,binding$1,newDir)));
              } else {
                var dirId = rootPath + last.path;
                var newDir$1 = merge(/* `Dir */[
                      3405101,
                      {
                        id: dirId,
                        data: undefined
                      }
                    ], term, rest);
                newContents = Curry._2(Import.List.concat, contents, /* :: */[
                      /* tuple */[
                        /* `Named */[
                          699531161,
                          last.name
                        ],
                        newDir$1
                      ],
                      /* [] */0
                    ]);
              }
              return /* `Dir */[
                      3405101,
                      {
                        id: id,
                        data: newContents
                      }
                    ];
            } else {
              var newContents_000 = /* tuple */[
                /* `Named */[
                  699531161,
                  last.name
                ],
                term
              ];
              var newContents$1 = /* :: */[
                newContents_000,
                contents
              ];
              return /* `Dir */[
                      3405101,
                      {
                        id: id,
                        data: newContents$1
                      }
                    ];
            }
          } else {
            return Pervasives.failwith("Bug: did not expect path components list to be empty");
          }
        } else {
          _dir = /* `Dir */[
            3405101,
            {
              id: id,
              data: /* [] */0
            }
          ];
          continue ;
        }
      }
    };
  };
  var rootDir_001 = {
    id: rootPath,
    data: /* [] */0
  };
  var rootDir = /* `Dir */[
    3405101,
    rootDir_001
  ];
  var term = Curry._3(Import.List.reduce, blobsWithBasePaths, rootDir, (function (dir, param) {
          var components = Curry._1(Import.List.fromArray, PathUtils.splitPathIntoComponents(param[1]));
          return merge(dir, /* `Blob_id */[
                      295523485,
                      param[0].id
                    ], components);
        }));
  var type_ = TypeUtils.inferTermTypeRe(term, /* Type_not_available */-883458824);
  var name = PathUtils.lastEntityName(rootPath);
  return constructEntities(term, type_, rootPath, name, undefined, /* () */0);
}

var $$default = parseResponse;

export {
  parseResponse ,
  $$default ,
  $$default as default,
  parseBlobs ,
  
}
/* BlobJs Not a pure module */
