

import * as Link from "../../Link.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Flags from "../../../flags.bs.js";
import * as Hooks from "../../../Hooks.bs.js";
import * as Paths from "../../../Paths.bs.js";
import * as React from "react";
import * as Import from "../../../features/import.bs.js";
import * as NavLink from "../../NavLink.bs.js";
import * as ReactIntl from "react-intl";
import * as ReactExtras from "../../../lib/solvuu/ReactExtras.bs.js";
import * as GeneRegulation from "../../Marketing/Science/Item/GeneRegulation.bs.js";
import * as Transcriptomics from "../../Marketing/Science/Item/Transcriptomics.bs.js";
import * as VariantDiscovery from "../../Marketing/Science/Item/VariantDiscovery.bs.js";
import * as MicrobialGenomics from "../../Marketing/Science/Item/MicrobialGenomics.bs.js";
import * as SingleCellGenomics from "../../Marketing/Science/Item/SingleCellGenomics.bs.js";
import * as NavbarActionsDropdown from "../AuthenticatedLayout/NavbarActionsDropdown/NavbarActionsDropdown.bs.js";
import * as StyleCss from "containers/Layout/PublicLayout/style.css";

var logo = require("../logo-color.svg");

var logoWhiteWordmark = require("../logo-white-wordmark.svg");

var socialTwitter = require("./social-twitter.svg");

var socialLinkedin = require("./social-linkedin.svg");

function PublicLayout2$NavLink(Props) {
  var _to = Props.to;
  var children = Props.children;
  return React.createElement(NavLink.make, {
              to: _to,
              className: StyleCss.default.navLink,
              activeClassName: StyleCss.default.navLinkActive,
              children: children
            });
}

var NavLink$1 = {
  make: PublicLayout2$NavLink
};

function PublicLayout2$Buttons(Props) {
  var profile = Props.profile;
  var loginState = Props.loginState;
  var logout = Props.logout;
  if (typeof loginState !== "number" && loginState[0] === 648407695) {
    return React.createElement("div", {
                className: StyleCss.default.navbarDropdownWrapper
              }, React.createElement(NavbarActionsDropdown.make, {
                    profile: profile,
                    logout: logout,
                    background: "light"
                  }));
  }
  if (Flags.disableSaasApp) {
    return null;
  } else {
    return React.createElement(PublicLayout2$NavLink, {
                to: /* Login */203508041,
                children: React.createElement(ReactIntl.FormattedMessage, {
                      id: "navigation.login"
                    })
              });
  }
}

var Buttons = {
  make: PublicLayout2$Buttons
};

function PublicLayout2$Navigation(Props) {
  var profile = Props.profile;
  var loginState = Props.loginState;
  var handleResponsiveMenuToggle = Props.handleResponsiveMenuToggle;
  var responsiveMenuToggled = Props.responsiveMenuToggled;
  var logout = Props.logout;
  return React.createElement(React.Fragment, undefined, React.createElement("input", {
                  className: StyleCss.default.navCheckbox,
                  id: "menu-trigger",
                  type: "checkbox",
                  onChange: (function (param) {
                      return Curry._1(handleResponsiveMenuToggle, /* () */0);
                    })
                }), React.createElement("label", {
                  "aria-label": "Toggle navigation menu",
                  "aria-expanded": responsiveMenuToggled,
                  "aria-controls": "nav-links",
                  className: StyleCss.default.navTrigger,
                  role: "button",
                  htmlFor: "menu-trigger"
                }), React.createElement("div", {
                  className: StyleCss.default.navLinks,
                  id: "nav-links"
                }, React.createElement(PublicLayout2$NavLink, {
                      to: /* Product */1062278927,
                      children: "Product"
                    }), React.createElement(PublicLayout2$NavLink, {
                      to: /* `Science */[
                        -923566716,
                        /* Main */858743897
                      ],
                      children: "Science"
                    }), React.createElement(PublicLayout2$NavLink, {
                      to: /* Company */-793101027,
                      children: "Company"
                    }), React.createElement(PublicLayout2$NavLink, {
                      to: /* `Blog */[
                        737307010,
                        /* Main */858743897
                      ],
                      children: "Blog"
                    }), React.createElement(PublicLayout2$Buttons, {
                      profile: profile,
                      loginState: loginState,
                      logout: logout
                    })));
}

var Navigation = {
  make: PublicLayout2$Navigation
};

function renderNavbar(profile, loginState, handleResponsiveMenuToggle, responsiveMenuToggled, logout) {
  return React.createElement("nav", {
              className: StyleCss.default.navbar
            }, React.createElement("div", {
                  className: StyleCss.default.navbarContainer
                }, React.createElement(Link.make, {
                      to: /* Home */803993151,
                      className: StyleCss.default.brandLink,
                      children: React.createElement(ReactIntl.FormattedMessage, {
                            id: "navigation.brand",
                            children: (function (alt) {
                                return React.createElement("img", {
                                            className: StyleCss.default.brand,
                                            alt: alt,
                                            src: logo
                                          });
                              })
                          })
                    }), React.createElement(PublicLayout2$Navigation, {
                      profile: profile,
                      loginState: loginState,
                      handleResponsiveMenuToggle: handleResponsiveMenuToggle,
                      responsiveMenuToggled: responsiveMenuToggled,
                      logout: logout
                    })));
}

function renderContent(children) {
  return React.createElement("div", {
              className: StyleCss.default.content
            }, React.createElement("div", {
                  className: StyleCss.default.container
                }, children));
}

function renderFooter(param) {
  return React.createElement("div", {
              className: StyleCss.default.footer
            }, React.createElement("div", {
                  className: StyleCss.default.footerContainer
                }, React.createElement("div", {
                      className: StyleCss.default.footerLeft
                    }, React.createElement("img", {
                          className: StyleCss.default.footerBrand,
                          alt: "",
                          src: logoWhiteWordmark
                        }), React.createElement("p", {
                          className: StyleCss.default.footerDescription
                        }, "Exploration Without Limits. A data science platform for life scientists."), React.createElement("div", {
                          className: StyleCss.default.footerSocial
                        }, React.createElement("a", {
                              className: StyleCss.default.socialLink,
                              href: "https://twitter.com/solvuu",
                              rel: "noopener noreferrer",
                              target: "_blank"
                            }, React.createElement("img", {
                                  alt: "Twitter",
                                  src: socialTwitter
                                })), React.createElement("a", {
                              className: StyleCss.default.socialLink,
                              href: "https://www.linkedin.com/company/solvuu",
                              rel: "noopener noreferrer",
                              target: "_blank"
                            }, React.createElement("img", {
                                  alt: "LinkedIn",
                                  src: socialLinkedin
                                })))), React.createElement("div", {
                      className: StyleCss.default.footerRight
                    }, React.createElement("div", {
                          className: StyleCss.default.linksColumn
                        }, React.createElement(Link.make, {
                              to: /* Home */803993151,
                              className: StyleCss.default.footerNavLink,
                              children: "Home"
                            }), React.createElement(Link.make, {
                              to: /* Product */1062278927,
                              className: StyleCss.default.footerNavLink,
                              children: "Product"
                            }), React.createElement(Link.make, {
                              to: /* Company */-793101027,
                              className: StyleCss.default.footerNavLink,
                              children: "Company"
                            }), React.createElement("a", {
                              className: StyleCss.default.footerNavLink,
                              href: Paths.scienceExchange,
                              rel: "noopener noreferrer",
                              target: "_blank"
                            }, "Services"), React.createElement(Link.make, {
                              to: /* `Blog */[
                                737307010,
                                /* Main */858743897
                              ],
                              className: StyleCss.default.footerNavLink,
                              children: "Blog"
                            })), React.createElement("div", {
                          className: StyleCss.default.linksColumn
                        }, React.createElement(Link.make, {
                              to: /* `Science */[
                                -923566716,
                                /* Main */858743897
                              ],
                              className: StyleCss.default.footerNavLink,
                              children: "Science"
                            }), ReactExtras.listToElement(Curry._2(Import.List.map, /* :: */[
                                  MicrobialGenomics,
                                  /* :: */[
                                    Transcriptomics,
                                    /* :: */[
                                      GeneRegulation,
                                      /* :: */[
                                        VariantDiscovery,
                                        /* :: */[
                                          SingleCellGenomics,
                                          /* [] */0
                                        ]
                                      ]
                                    ]
                                  ]
                                ], (function (x) {
                                    return React.createElement(Link.make, {
                                                to: /* `Science */[
                                                  -923566716,
                                                  /* `Item */[
                                                    815329587,
                                                    x.id
                                                  ]
                                                ],
                                                className: StyleCss.default.footerNavLinkSecondary,
                                                children: x.title,
                                                key: x.id
                                              });
                                  })))))), React.createElement("div", {
                  className: StyleCss.default.footerContainer
                }, React.createElement("div", {
                      className: StyleCss.default.footerBottom
                    }, React.createElement("div", undefined, React.createElement(ReactIntl.FormattedMessage, {
                              id: "footer.copyright"
                            })), React.createElement("div", {
                          className: StyleCss.default.footerBottomLegal
                        }, React.createElement(Link.make, {
                              to: /* TermsOfUse */975642249,
                              className: StyleCss.default.footerLegalLink,
                              children: "Terms of Use"
                            }), React.createElement(Link.make, {
                              to: /* PrivacyPolicy */-229792710,
                              className: StyleCss.default.footerLegalLink,
                              children: "Privacy"
                            })))));
}

function PublicLayout2(Props) {
  var profile = Props.profile;
  var loginState = Props.loginState;
  var logout = Props.logout;
  var children = Props.children;
  var match = Hooks.useToggle(false);
  return React.createElement("div", {
              className: StyleCss.default.root
            }, renderNavbar(profile, loginState, match[1], match[0], logout), renderContent(children), renderFooter(/* () */0));
}

var make = PublicLayout2;

export {
  logo ,
  logoWhiteWordmark ,
  socialTwitter ,
  socialLinkedin ,
  NavLink$1 as NavLink,
  Buttons ,
  Navigation ,
  renderNavbar ,
  renderContent ,
  renderFooter ,
  make ,
  
}
/* logo Not a pure module */
