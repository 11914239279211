

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as TermJs from "../../lib/solvuu/termJs.bs.js";
import * as TypeJs from "../../lib/solvuu/typeJs.bs.js";
import * as Icons from "./icons";
import * as StyleCss from "./style.css";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as ReactExtras from "../../lib/solvuu/ReactExtras.bs.js";
import * as Fmt$SolvuuApi from "solvuu-api/./fmt.bs.js";

var styles = StyleCss.default;

var folder = Icons.folder;

var file = Icons.file;

var blankFile = Icons.blankFile;

function iconByType(_type_) {
  while(true) {
    var type_ = _type_;
    if (typeof type_ === "number") {
      if (type_ >= 737157829) {
        if (type_ >= 748194550) {
          if (type_ >= 785140586) {
            if (type_ >= 936769581) {
              return /* File */["TIME"];
            } else {
              return ;
            }
          } else if (type_ >= 758940238) {
            return /* File */["DATE"];
          } else {
            return /* File */["CHAR"];
          }
        } else if (type_ >= 737456202) {
          return /* File */["BOOL"];
        } else {
          return /* File */["BIOM"];
        }
      } else if (type_ !== -883458824) {
        if (type_ >= 365180284) {
          if (type_ >= 642196241) {
            return /* File */["NEWICK"];
          } else {
            return /* File */["FLOAT"];
          }
        } else if (type_ >= 3654863) {
          return /* File */["INT"];
        } else {
          return /* File */["STRING"];
        }
      } else {
        return ;
      }
    } else {
      var variant = type_[0];
      if (variant !== 216537025) {
        if (variant >= 848054398) {
          if (variant >= 925483562) {
            return /* Formula */1;
          } else {
            return /* File */["LIST"];
          }
        } else if (variant >= 847309489) {
          return /* Folder */0;
        } else {
          return ;
        }
      } else {
        _type_ = type_[1];
        continue ;
      }
    }
  };
}

function iconByFmt(_fmt) {
  while(true) {
    var fmt = _fmt;
    if (typeof fmt === "number") {
      if (fmt >= 3556729) {
        if (fmt >= 487611253) {
          if (fmt >= 748691835) {
            if (fmt !== 758940238) {
              if (fmt >= 826371656) {
                if (fmt >= 962246961) {
                  return /* File */["ASCII"];
                } else {
                  return /* File */["JSON"];
                }
              } else if (fmt >= 804241803) {
                return /* File */["HTML"];
              } else {
                return /* File */["CRAM"];
              }
            } else {
              return /* File */["DATE"];
            }
          } else if (fmt >= 736760884) {
            if (fmt >= 737456202) {
              return /* File */["BOOL"];
            } else {
              return /* File */["BASH"];
            }
          } else if (fmt >= 642196241) {
            return /* File */["NEWICK"];
          } else {
            return /* File */["BIGWIG"];
          }
        } else if (fmt >= 4149247) {
          if (fmt !== 4298873) {
            if (fmt >= 365180284) {
              if (fmt >= 486566049) {
                return /* File */["BIGBED"];
              } else {
                return /* File */["FLOAT"];
              }
            } else if (fmt >= 107576104) {
              return ;
            } else {
              return /* File */["SAM"];
            }
          } else {
            return /* File */["VCF"];
          }
        } else if (fmt >= 4002953) {
          if (fmt >= 4055352) {
            return /* File */["QZA"];
          } else {
            return /* File */["PNG"];
          }
        } else if (fmt >= 3654863) {
          return /* File */["INT"];
        } else {
          return /* File */["GTF"];
        }
      } else if (fmt >= -574959090) {
        if (fmt >= -77614959) {
          if (fmt >= 3303854) {
            if (fmt >= 3304737) {
              return /* File */["BED"];
            } else {
              return /* File */["BAM"];
            }
          } else if (fmt !== -50740607) {
            return ;
          } else {
            return /* File */["BAI"];
          }
        } else if (fmt >= -293895564) {
          if (fmt >= -113318020) {
            return /* File */["RDATA"];
          } else {
            return ;
          }
        } else if (fmt >= -522825178) {
          return /* Formula */1;
        } else {
          return /* File */["CRAI"];
        }
      } else if (fmt >= -972455391) {
        if (fmt !== -953902196) {
          if (fmt !== -792364981) {
            return ;
          } else {
            return /* File */["BYTES"];
          }
        } else {
          return /* File */["IDX"];
        }
      } else if (fmt >= -976970511) {
        if (fmt >= -973518485) {
          return /* File */["MULTQC"];
        } else {
          return /* File */["STRING"];
        }
      } else if (fmt >= -1015245230) {
        return ;
      } else {
        return /* File */["FAI"];
      }
    } else {
      var variant = fmt[0];
      if (variant >= 243398181) {
        if (variant >= 374637448) {
          if (variant !== 737157829) {
            _fmt = fmt[1];
            continue ;
          } else {
            return /* File */["BIOM"];
          }
        } else if (variant >= 243398197) {
          return /* File */["FASTQ"];
        } else {
          return /* File */["FASTA"];
        }
      } else if (variant >= 3553607) {
        if (fmt[1] >= 812255803) {
          return /* File */["GFF3"];
        } else {
          return /* File */["GFF"];
        }
      } else {
        return /* File */["CSV"];
      }
    }
  };
}

function iconByTerm(_term) {
  while(true) {
    var term = _term;
    if (typeof term === "number") {
      if (term !== 870828711) {
        if (term >= 914889282) {
          return ;
        } else {
          return /* Formula */1;
        }
      } else {
        return /* File */["NULL"];
      }
    } else {
      var variant = term[0];
      if (variant >= 106380200) {
        if (variant >= 758940238) {
          if (variant >= 858945253) {
            if (variant >= 925978388) {
              if (variant >= 936769581) {
                return /* File */["TIME"];
              } else {
                _term = term[1];
                continue ;
              }
            } else if (variant >= 925483562) {
              return /* Formula */1;
            } else {
              _term = term[1][1];
              continue ;
            }
          } else if (variant !== 847309489) {
            if (variant >= 848054398) {
              return /* File */["LIST"];
            } else {
              return /* File */["DATE"];
            }
          } else {
            return /* Folder */0;
          }
        } else if (variant >= 521729689) {
          if (variant >= 737456202) {
            if (variant >= 748194550) {
              return /* File */["CHAR"];
            } else {
              return /* File */["BOOL"];
            }
          } else if (variant >= 669191267) {
            return ;
          } else {
            return /* Formula */1;
          }
        } else if (variant !== 295523485) {
          if (variant >= 365180284) {
            return /* File */["FLOAT"];
          } else {
            return ;
          }
        } else {
          return /* File */["FILE"];
        }
      } else if (variant >= -37599172) {
        if (variant >= 3503881) {
          if (variant >= 4298439) {
            if (variant >= 89433895) {
              return /* Formula */1;
            }
            
          } else if (variant >= 3654863) {
            return /* File */["INT"];
          } else {
            return /* Formula */1;
          }
        } else if (variant !== 3257473) {
          if (variant >= 3405101) {
            return /* Folder */0;
          } else {
            _term = term[1][0];
            continue ;
          }
        }
        
      } else if (variant >= -976970511) {
        if (variant >= -522825178) {
          if (variant >= -216449554) {
            return ;
          } else {
            return /* Formula */1;
          }
        } else if (variant >= -964680357) {
          _term = term[1][1];
          continue ;
        } else {
          return /* File */["STRING"];
        }
      } else if (variant >= -1019462983) {
        return /* Formula */1;
      } else {
        return /* File */["JOB"];
      }
    }
    var match = Curry._1(Fmt$SolvuuApi.Parse.of_term, term);
    if (typeof match === "number") {
      return ;
    } else {
      return iconByFmt(match[1][0]);
    }
  };
}

var Icons$1 = {
  folder: folder,
  file: file,
  blankFile: blankFile,
  iconByType: iconByType,
  iconByFmt: iconByFmt,
  iconByTerm: iconByTerm
};

function EntityIcon(Props) {
  var term = Props.term;
  var type_ = Props.type_;
  var match = iconByTerm(term);
  var icon = match !== undefined ? match : (
      type_ !== undefined ? Belt_Option.getWithDefault(iconByType(type_), /* BlankFile */2) : /* BlankFile */2
    );
  var match$1;
  if (typeof icon === "number") {
    switch (icon) {
      case /* Folder */0 :
          match$1 = /* tuple */[
            folder,
            {
              className: styles.root,
              name: undefined
            }
          ];
          break;
      case /* Formula */1 :
          match$1 = /* tuple */[
            file,
            {
              className: styles.root,
              name: "FUNC"
            }
          ];
          break;
      case /* BlankFile */2 :
          match$1 = /* tuple */[
            blankFile,
            {
              className: styles.root,
              name: undefined
            }
          ];
          break;
      
    }
  } else {
    match$1 = /* tuple */[
      file,
      {
        className: styles.root,
        name: icon[0]
      }
    ];
  }
  return React.createElement(match$1[0], match$1[1]);
}

function mapProps(props) {
  var term = TermJs.convertFromJs(props.term);
  var type_ = Belt_Option.map(Caml_option.undefined_to_opt(props.type), TypeJs.convertFromJs);
  var tmp = {
    term: term
  };
  if (type_ !== undefined) {
    tmp.type_ = Caml_option.valFromOption(type_);
  }
  return tmp;
}

var $$default = ReactExtras.jsComponent("EntityIcon", EntityIcon, mapProps);

var make = EntityIcon;

export {
  styles ,
  Icons$1 as Icons,
  make ,
  mapProps ,
  $$default ,
  $$default as default,
  
}
/* styles Not a pure module */
