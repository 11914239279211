

import * as List from "bs-platform/lib/es6/list.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Input from "../FormControls/Input.bs.js";
import * as React from "react";
import * as Button from "../Button.bs.js";
import * as Import from "../../features/import.bs.js";
import * as Printf from "bs-platform/lib/es6/printf.js";
import * as Checkbox from "../FormControls/Checkbox.bs.js";
import * as ReactIntl from "react-intl";
import * as StyleCss from "./style.css";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_format from "bs-platform/lib/es6/caml_format.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as ReactExtras from "../../lib/solvuu/ReactExtras.bs.js";
import * as VariantSelect from "./VariantSelect.bs.js";
import * as OnClickOutside from "../OnClickOutside/OnClickOutside.bs.js";
import * as Root$SolvuuApi from "solvuu-api/./root.bs.js";
import * as VerticalLayout from "../Layout/VerticalLayout.bs.js";
import * as HorizontalLayout from "../Layout/HorizontalLayout.bs.js";
import * as Equals from "ramda/src/equals";
import * as Layout from "components/Layout";
import * as Caml_js_exceptions from "bs-platform/lib/es6/caml_js_exceptions.js";
import * as Caml_builtin_exceptions from "bs-platform/lib/es6/caml_builtin_exceptions.js";

var styles = StyleCss.default;

function maybeAtMostOnce(format, set) {
  var x;
  x = typeof format === "number" || format[0] !== 374637448 ? format : format[1];
  if (set) {
    return /* `Maybe */[
            374637448,
            x
          ];
  } else if (typeof format === "number" || format[0] !== 374637448) {
    return format;
  } else {
    return format[1];
  }
}

function gzipAtMostOnce(format, gzip) {
  var x;
  if (typeof format === "number") {
    x = format;
  } else {
    var variant = format[0];
    if (variant !== 374637448) {
      x = variant !== 793449722 ? format : format[1];
    } else {
      return /* `Maybe */[
              374637448,
              gzipAtMostOnce(format[1], gzip)
            ];
    }
  }
  if (gzip) {
    return /* `Gzip */[
            793449722,
            x
          ];
  } else if (typeof format === "number" || format[0] !== 793449722) {
    return format;
  } else {
    return format[1];
  }
}

function csvColumn(index) {
  return Curry._1(Printf.sprintf(/* Format */[
                  /* String_literal */Block.__(11, [
                      "csv-column[",
                      /* Int */Block.__(4, [
                          /* Int_d */0,
                          /* No_padding */0,
                          /* No_precision */0,
                          /* Char_literal */Block.__(12, [
                              /* "]" */93,
                              /* End_of_format */0
                            ])
                        ])
                    ]),
                  "csv-column[%d]"
                ]), index);
}

var editCsvColumn = "edit-column";

var removeCsvColumn = "remove-column";

var TestIds = {
  csvColumn: csvColumn,
  editCsvColumn: editCsvColumn,
  removeCsvColumn: removeCsvColumn
};

var optional = "optional";

var gzipped = "gzipped";

var selectableFormat = "selectableFormat";

var format = "biomFormat";

var Biom = {
  format: format
};

var columnKeep = "keep";

var columnHeader = "header";

var separator = "csvSeparator";

var hasHeader = "hasHeader";

var allowComments = "allowComments";

var shiftHeader = "shiftHeader";

var Csv = {
  columnKeep: columnKeep,
  columnHeader: columnHeader,
  separator: separator,
  hasHeader: hasHeader,
  allowComments: allowComments,
  shiftHeader: shiftHeader
};

var format$1 = "fastqFormat";

var Fastq = {
  format: format$1
};

var format$2 = "gffFormat";

var Gff = {
  format: format$2
};

var FieldName = {
  optional: optional,
  gzipped: gzipped,
  selectableFormat: selectableFormat,
  Biom: Biom,
  Csv: Csv,
  Fastq: Fastq,
  Gff: Gff
};

function FormatEditor$Biom(Props) {
  var biom_version = Props.biom_version;
  var onChange = Props.onChange;
  return React.createElement("div", {
              className: styles.formGroup
            }, React.createElement(VariantSelect.make, {
                  variantsWithValues: Curry._2(Import.List.map, Root$SolvuuApi.Fmt.biom_version_all, (function (x) {
                          return /* tuple */[
                                  x,
                                  Curry._1(Root$SolvuuApi.Fmt.biom_version_to_string_hum, x)
                                ];
                        })),
                  variant: biom_version,
                  onChange: onChange,
                  name: format,
                  label: React.createElement(ReactIntl.FormattedMessage, {
                        id: "entityCreator.fields.biom.format"
                      })
                }));
}

var Biom$1 = {
  make: FormatEditor$Biom
};

function reduce(csv, action) {
  if (typeof action === "number") {
    var columnsCount = Curry._1(Import.List.length, csv.columns);
    var newColumnIdx = columnsCount + 1 | 0;
    var header = Curry._1(Printf.sprintf(/* Format */[
              /* String_literal */Block.__(11, [
                  "Column ",
                  /* Int */Block.__(4, [
                      /* Int_d */0,
                      /* No_padding */0,
                      /* No_precision */0,
                      /* End_of_format */0
                    ])
                ]),
              "Column %d"
            ]), newColumnIdx);
    var columns = List.append(csv.columns, /* :: */[
          {
            header: header,
            format: /* String */-976970511,
            action: /* Keep */836762789
          },
          /* [] */0
        ]);
    return {
            columns: columns,
            separator: csv.separator,
            comment_char: csv.comment_char,
            shift_header: csv.shift_header,
            has_header: csv.has_header,
            quote: csv.quote
          };
  } else {
    switch (action.tag | 0) {
      case /* ChangeCsvOption */0 :
          return action[0];
      case /* RemoveColumn */1 :
          var index = action[0];
          var columns$1 = Curry._2(Import.List.keepWithIndex, csv.columns, (function (param, columnIndex) {
                  return columnIndex !== index;
                }));
          return {
                  columns: columns$1,
                  separator: csv.separator,
                  comment_char: csv.comment_char,
                  shift_header: csv.shift_header,
                  has_header: csv.has_header,
                  quote: csv.quote
                };
      case /* ChangeColumnHeader */2 :
          var value = action[1];
          var index$1 = action[0];
          var columns$2 = Curry._2(Import.List.mapWithIndex, csv.columns, (function (columnIndex, column) {
                  var match = columnIndex === index$1;
                  if (match) {
                    return {
                            header: value,
                            format: column.format,
                            action: column.action
                          };
                  } else {
                    return column;
                  }
                }));
          return {
                  columns: columns$2,
                  separator: csv.separator,
                  comment_char: csv.comment_char,
                  shift_header: csv.shift_header,
                  has_header: csv.has_header,
                  quote: csv.quote
                };
      case /* ChangeColumnFormat */3 :
          var format = action[1];
          var index$2 = action[0];
          var columns$3 = Curry._2(Import.List.mapWithIndex, csv.columns, (function (columnIndex, column) {
                  var match = columnIndex === index$2;
                  if (match) {
                    return {
                            header: column.header,
                            format: format,
                            action: column.action
                          };
                  } else {
                    return column;
                  }
                }));
          return {
                  columns: columns$3,
                  separator: csv.separator,
                  comment_char: csv.comment_char,
                  shift_header: csv.shift_header,
                  has_header: csv.has_header,
                  quote: csv.quote
                };
      case /* ChangeColumnAction */4 :
          var action$1 = action[1];
          var index$3 = action[0];
          var columns$4 = Curry._2(Import.List.mapWithIndex, csv.columns, (function (columnIndex, column) {
                  var match = columnIndex === index$3;
                  if (match) {
                    return {
                            header: column.header,
                            format: column.format,
                            action: action$1
                          };
                  } else {
                    return column;
                  }
                }));
          return {
                  columns: columns$4,
                  separator: csv.separator,
                  comment_char: csv.comment_char,
                  shift_header: csv.shift_header,
                  has_header: csv.has_header,
                  quote: csv.quote
                };
      
    }
  }
}

function FormatEditor$Csv$ColumnFormat(Props) {
  var format = Props.format;
  var onChange = Props.onChange;
  return React.createElement("div", {
              className: styles.formGroupInline
            }, React.createElement(Checkbox.make, {
                  label: React.createElement(ReactIntl.FormattedMessage, {
                        id: "entityCreator.fields.blobs.format.optional"
                      }),
                  name: optional,
                  value: typeof format === "number" ? false : format[0] === 374637448,
                  onChange: (function (x) {
                      return Curry._1(onChange, maybeAtMostOnce(format, x));
                    })
                }), React.createElement(VariantSelect.make, {
                  variantsWithValues: Curry._2(Import.List.map, /* :: */[
                        /* Bool */737456202,
                        /* :: */[
                          /* Bytes */-792364981,
                          /* :: */[
                            /* Date */758940238,
                            /* :: */[
                              /* Float */365180284,
                              /* :: */[
                                /* Int */3654863,
                                /* :: */[
                                  /* String */-976970511,
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]
                        ]
                      ], (function (x) {
                          return /* tuple */[
                                  x,
                                  Curry._1(Root$SolvuuApi.Fmt.Type.to_string_hum, x)
                                ];
                        })),
                  variant: Curry._1(Root$SolvuuApi.Fmt.type_of, format),
                  onChange: (function (x) {
                      return Curry._1(onChange, Curry._1(Root$SolvuuApi.Fmt.Default.of_type, x));
                    }),
                  name: selectableFormat,
                  label: React.createElement(ReactIntl.FormattedMessage, {
                        id: "entityCreator.fields.format"
                      })
                }));
}

function FormatEditor$Csv$Column(Props) {
  var index = Props.index;
  var column = Props.column;
  var dispatch = Props.dispatch;
  var match = React.useReducer((function (state, action) {
          if (state >= -217527048 || action !== 509949892) {
            return /* NotEditing */-348024731;
          } else {
            return /* Editing */-217527048;
          }
        }), /* NotEditing */-348024731);
  var dispatchAction = match[1];
  var match$1 = column.action;
  var state = match$1 >= 925778591 ? /* Skipped */51724176 : (
      match[0] >= -217527048 ? /* Editing */-217527048 : /* NotEditing */-348024731
    );
  var match$2 = column.action;
  var keepCheckboxValue = match$2 < 925778591;
  var className = state >= 51724176 ? styles.csvColumnHeaderLabelSkipped : styles.csvColumnHeaderLabel;
  var readableIndex = index + 1 | 0;
  var label = state !== -217527048 ? (
      state >= 51724176 ? Curry._2(Printf.sprintf(/* Format */[
                  /* Char_literal */Block.__(12, [
                      /* "#" */35,
                      /* Int */Block.__(4, [
                          /* Int_d */0,
                          /* No_padding */0,
                          /* No_precision */0,
                          /* String_literal */Block.__(11, [
                              ": ",
                              /* String */Block.__(2, [
                                  /* No_padding */0,
                                  /* End_of_format */0
                                ])
                            ])
                        ])
                    ]),
                  "#%d: %s"
                ]), readableIndex, column.header) : Curry._3(Printf.sprintf(/* Format */[
                  /* Char_literal */Block.__(12, [
                      /* "#" */35,
                      /* Int */Block.__(4, [
                          /* Int_d */0,
                          /* No_padding */0,
                          /* No_precision */0,
                          /* String_literal */Block.__(11, [
                              ": ",
                              /* String */Block.__(2, [
                                  /* No_padding */0,
                                  /* String_literal */Block.__(11, [
                                      " (",
                                      /* String */Block.__(2, [
                                          /* No_padding */0,
                                          /* Char_literal */Block.__(12, [
                                              /* ")" */41,
                                              /* End_of_format */0
                                            ])
                                        ])
                                    ])
                                ])
                            ])
                        ])
                    ]),
                  "#%d: %s (%s)"
                ]), readableIndex, column.header, Curry._1(Root$SolvuuApi.Fmt.to_string_hum, column.format))
    ) : Curry._1(Printf.sprintf(/* Format */[
              /* String_literal */Block.__(11, [
                  "Editing column #",
                  /* Int */Block.__(4, [
                      /* Int_d */0,
                      /* No_padding */0,
                      /* No_precision */0,
                      /* End_of_format */0
                    ])
                ]),
              "Editing column #%d"
            ]), readableIndex);
  var keepCheckboxLabel = React.createElement("div", {
        className: className
      }, label);
  var columnEditButton = state >= -217527048 ? null : React.createElement(Layout.HorizontalLayoutItem, {
          children: ReactExtras.withTestId(editCsvColumn, React.createElement("button", {
                    className: styles.button,
                    type: "button",
                    onClick: (function (_event) {
                        return Curry._1(dispatchAction, /* ToggleEditing */509949892);
                      })
                  }, React.createElement(ReactIntl.FormattedMessage, {
                        id: "entityCreator.fields.csv.editColumn"
                      })))
        });
  var columnRemoveButton = React.createElement(Layout.HorizontalLayoutItem, {
        children: ReactExtras.withTestId(removeCsvColumn, React.createElement("button", {
                  className: styles.button,
                  type: "button",
                  onClick: (function (_event) {
                      return Curry._1(dispatch, /* RemoveColumn */Block.__(1, [index]));
                    })
                }, React.createElement(ReactIntl.FormattedMessage, {
                      id: "entityCreator.fields.csv.removeColumn"
                    })))
      });
  var headerElement = React.createElement(Layout.VerticalLayoutItem, {
        children: React.createElement("div", {
              className: styles.csvColumnHeader
            }, React.createElement(Checkbox.make, {
                  label: keepCheckboxLabel,
                  name: columnKeep,
                  value: keepCheckboxValue,
                  onChange: (function (checked) {
                      var action = checked ? /* Keep */836762789 : /* Skip */925778591;
                      return Curry._1(dispatch, /* ChangeColumnAction */Block.__(4, [
                                    index,
                                    action
                                  ]));
                    })
                }), React.createElement(Layout.HorizontalLayout, HorizontalLayout.makeProps(/* x2 */26810, null, /* () */0), columnEditButton, columnRemoveButton))
      });
  var formElement = state !== -217527048 ? null : React.createElement(Layout.VerticalLayoutItem, {
          children: React.createElement(OnClickOutside.make, {
                onClickOutside: (function (_event) {
                    return Curry._1(dispatchAction, /* StopEditing */-643671786);
                  }),
                children: (function (containerRef) {
                    return React.createElement("div", {
                                ref: containerRef
                              }, React.createElement(Layout.VerticalLayout, VerticalLayout.makeProps(/* x2 */26810, null, /* () */0), React.createElement(Layout.VerticalLayoutItem, {
                                        children: React.createElement(Input.make, {
                                              name: columnHeader,
                                              label: React.createElement(ReactIntl.FormattedMessage, {
                                                    id: "entityCreator.fields.csv.column.header"
                                                  }),
                                              value: column.header,
                                              onChange: (function (x) {
                                                  return Curry._1(dispatch, /* ChangeColumnHeader */Block.__(2, [
                                                                index,
                                                                x
                                                              ]));
                                                }),
                                              type_: "text"
                                            })
                                      }), React.createElement(Layout.VerticalLayoutItem, {
                                        children: React.createElement(FormatEditor$Csv$ColumnFormat, {
                                              format: column.format,
                                              onChange: (function (x) {
                                                  return Curry._1(dispatch, /* ChangeColumnFormat */Block.__(3, [
                                                                index,
                                                                x
                                                              ]));
                                                })
                                            })
                                      })));
                  })
              })
        });
  var testId = csvColumn(index);
  return ReactExtras.withTestId(testId, React.createElement("div", {
                  className: styles.csvColumn
                }, React.createElement(Layout.VerticalLayout, VerticalLayout.makeProps(/* x2 */26810, null, /* () */0), headerElement, formElement)));
}

function FormatEditor$Csv(Props) {
  var csv = Props.csv;
  var onChange = Props.onChange;
  var dispatch = function (action) {
    return Curry._1(onChange, reduce(csv, action));
  };
  var columnsCount = Curry._1(Import.List.length, csv.columns);
  var columnElements = ReactExtras.mapListToElement((function (index, column) {
          return React.createElement(FormatEditor$Csv$Column, {
                      index: index,
                      column: column,
                      dispatch: dispatch,
                      key: String(index)
                    });
        }), csv.columns);
  var handleChange = function (separator) {
    return Curry._1(onChange, reduce(csv, /* ChangeCsvOption */Block.__(0, [{
                        columns: csv.columns,
                        separator: separator,
                        comment_char: csv.comment_char,
                        shift_header: csv.shift_header,
                        has_header: csv.has_header,
                        quote: csv.quote
                      }])));
  };
  var separatorSelect = React.createElement(VariantSelect.make, {
        variantsWithValues: /* :: */[
          /* tuple */[
            /* "," */44,
            "comma <,>"
          ],
          /* :: */[
            /* tuple */[
              /* ";" */59,
              "semicolon <;>"
            ],
            /* :: */[
              /* tuple */[
                /* "\t" */9,
                "tab"
              ],
              /* [] */0
            ]
          ]
        ],
        variant: csv.separator,
        onChange: handleChange,
        name: separator,
        label: React.createElement(ReactIntl.FormattedMessage, {
              id: "entityCreator.fields.csv.separator"
            })
      });
  var hasHeaderCheckbox = React.createElement(Checkbox.make, {
        label: React.createElement(ReactIntl.FormattedMessage, {
              id: "entityCreator.fields.csv.hasHeader"
            }),
        name: hasHeader,
        value: csv.has_header,
        onChange: (function (has_header) {
            return Curry._1(onChange, reduce(csv, /* ChangeCsvOption */Block.__(0, [{
                                columns: csv.columns,
                                separator: csv.separator,
                                comment_char: csv.comment_char,
                                shift_header: csv.shift_header,
                                has_header: has_header,
                                quote: csv.quote
                              }])));
          })
      });
  var match = csv.comment_char;
  var allowCommentsCheckbox = React.createElement(Checkbox.make, {
        label: React.createElement(ReactIntl.FormattedMessage, {
              id: "entityCreator.fields.csv.allowComments"
            }),
        name: allowComments,
        value: match !== undefined ? match === 35 : false,
        onChange: (function (value) {
            var comment_char = value ? /* "#" */35 : undefined;
            return Curry._1(onChange, reduce(csv, /* ChangeCsvOption */Block.__(0, [{
                                columns: csv.columns,
                                separator: csv.separator,
                                comment_char: comment_char,
                                shift_header: csv.shift_header,
                                has_header: csv.has_header,
                                quote: csv.quote
                              }])));
          })
      });
  var quoteSelect = React.createElement(VariantSelect.make, {
        variantsWithValues: /* :: */[
          /* tuple */[
            undefined,
            "none"
          ],
          /* :: */[
            /* tuple */[
              /* "'" */39,
              "single-quotes <'>"
            ],
            /* :: */[
              /* tuple */[
                /* "\"" */34,
                "double-quotes <\">"
              ],
              /* [] */0
            ]
          ]
        ],
        variant: csv.quote,
        onChange: (function (quote) {
            return Curry._1(onChange, reduce(csv, /* ChangeCsvOption */Block.__(0, [{
                                columns: csv.columns,
                                separator: csv.separator,
                                comment_char: csv.comment_char,
                                shift_header: csv.shift_header,
                                has_header: csv.has_header,
                                quote: quote
                              }])));
          }),
        name: "quote",
        label: React.createElement(ReactIntl.FormattedMessage, {
              id: "entityCreator.fields.csv.quote"
            })
      });
  var shiftHeaderCheckbox = React.createElement(Checkbox.make, {
        label: React.createElement(ReactIntl.FormattedMessage, {
              id: "entityCreator.fields.csv.shiftHeader"
            }),
        name: shiftHeader,
        value: csv.shift_header,
        onChange: (function (shift_header) {
            return Curry._1(onChange, reduce(csv, /* ChangeCsvOption */Block.__(0, [{
                                columns: csv.columns,
                                separator: csv.separator,
                                comment_char: csv.comment_char,
                                shift_header: shift_header,
                                has_header: csv.has_header,
                                quote: csv.quote
                              }])));
          })
      });
  return React.createElement("div", undefined, React.createElement("div", {
                  className: styles.formGroup
                }, separatorSelect), React.createElement("details", {
                  className: styles.details
                }, React.createElement("summary", undefined, React.createElement(ReactIntl.FormattedMessage, {
                          id: "entityCreator.fields.csv.columnsSummary",
                          values: {
                            count: columnsCount
                          }
                        })), React.createElement("div", {
                      className: styles.csvColumns
                    }, columnElements), React.createElement("button", {
                      className: styles.button,
                      type: "button",
                      onClick: (function (_event) {
                          return Curry._1(onChange, reduce(csv, /* AppendColumn */0));
                        })
                    }, React.createElement(ReactIntl.FormattedMessage, {
                          id: "entityCreator.fields.csv.addColumn"
                        }))), React.createElement("details", {
                  className: styles.details,
                  open: true
                }, React.createElement("summary", undefined, React.createElement(ReactIntl.FormattedMessage, {
                          id: "entityCreator.fields.csv.advancedSummary"
                        })), React.createElement("div", {
                      className: styles.formGroup
                    }, quoteSelect, React.createElement("span", {
                          className: styles.fieldInfo
                        }, React.createElement(ReactIntl.FormattedMessage, {
                              id: "entityCreator.fields.csv.quote.description"
                            }))), React.createElement("div", {
                      className: styles.formGroup
                    }, hasHeaderCheckbox, React.createElement("span", {
                          className: styles.fieldInfo
                        }, React.createElement(ReactIntl.FormattedMessage, {
                              id: "entityCreator.fields.csv.hasHeader.description"
                            }))), React.createElement("div", {
                      className: styles.formGroup
                    }, allowCommentsCheckbox, React.createElement("span", {
                          className: styles.fieldInfo
                        }, React.createElement(ReactIntl.FormattedMessage, {
                              id: "entityCreator.fields.csv.allowComments.description"
                            }))), React.createElement("div", {
                      className: styles.formGroup
                    }, shiftHeaderCheckbox, React.createElement("span", {
                          className: styles.fieldInfo
                        }, React.createElement(ReactIntl.FormattedMessage, {
                              id: "entityCreator.fields.csv.shiftHeader.description"
                            })))));
}

function FormatEditor$Fasta(Props) {
  var fasta = Props.fasta;
  var onChange = Props.onChange;
  var match = fasta.alphabet;
  var match$1 = fasta.max_line_length;
  return React.createElement("div", undefined, React.createElement("div", {
                  className: styles.formGroup
                }, React.createElement(Input.make, {
                      name: "alphabet",
                      label: React.createElement(ReactIntl.FormattedMessage, {
                            id: "entityCreator.fields.fasta.alphabet"
                          }),
                      value: match !== undefined ? match : "",
                      onChange: (function (value) {
                          var alphabet = value === "" ? undefined : value;
                          return Curry._1(onChange, {
                                      allow_sharp_comments: fasta.allow_sharp_comments,
                                      allow_semicolon_comments: fasta.allow_semicolon_comments,
                                      allow_empty_lines: fasta.allow_empty_lines,
                                      allow_comments_only_at_top: fasta.allow_comments_only_at_top,
                                      max_line_length: fasta.max_line_length,
                                      alphabet: alphabet
                                    });
                        }),
                      type_: "text"
                    })), React.createElement("div", {
                  className: styles.formGroup
                }, React.createElement(Input.make, {
                      name: "maxLineLength",
                      label: React.createElement(ReactIntl.FormattedMessage, {
                            id: "entityCreator.fields.fasta.maximumLineLength"
                          }),
                      value: match$1 !== undefined ? String(match$1) : "",
                      onChange: (function (value) {
                          var newFasta;
                          if (value === "") {
                            newFasta = {
                              allow_sharp_comments: fasta.allow_sharp_comments,
                              allow_semicolon_comments: fasta.allow_semicolon_comments,
                              allow_empty_lines: fasta.allow_empty_lines,
                              allow_comments_only_at_top: fasta.allow_comments_only_at_top,
                              max_line_length: undefined,
                              alphabet: fasta.alphabet
                            };
                          } else {
                            var exit = 0;
                            var max_line_length;
                            try {
                              max_line_length = Caml_format.caml_int_of_string(value);
                              exit = 1;
                            }
                            catch (raw_exn){
                              var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
                              if (exn[0] === Caml_builtin_exceptions.failure) {
                                newFasta = undefined;
                              } else {
                                throw exn;
                              }
                            }
                            if (exit === 1) {
                              newFasta = {
                                allow_sharp_comments: fasta.allow_sharp_comments,
                                allow_semicolon_comments: fasta.allow_semicolon_comments,
                                allow_empty_lines: fasta.allow_empty_lines,
                                allow_comments_only_at_top: fasta.allow_comments_only_at_top,
                                max_line_length: max_line_length,
                                alphabet: fasta.alphabet
                              };
                            }
                            
                          }
                          if (newFasta !== undefined) {
                            return Curry._1(onChange, newFasta);
                          } else {
                            return /* () */0;
                          }
                        }),
                      type_: "number"
                    })), React.createElement("div", {
                  className: styles.formGroup
                }, React.createElement(Checkbox.make, {
                      label: React.createElement(ReactIntl.FormattedMessage, {
                            id: "entityCreator.fields.fasta.allowSharpComments"
                          }),
                      name: "allowSharpComments",
                      value: fasta.allow_sharp_comments,
                      onChange: (function (allow_sharp_comments) {
                          return Curry._1(onChange, {
                                      allow_sharp_comments: allow_sharp_comments,
                                      allow_semicolon_comments: fasta.allow_semicolon_comments,
                                      allow_empty_lines: fasta.allow_empty_lines,
                                      allow_comments_only_at_top: fasta.allow_comments_only_at_top,
                                      max_line_length: fasta.max_line_length,
                                      alphabet: fasta.alphabet
                                    });
                        })
                    })), React.createElement("div", {
                  className: styles.formGroup
                }, React.createElement(Checkbox.make, {
                      label: React.createElement(ReactIntl.FormattedMessage, {
                            id: "entityCreator.fields.fasta.allowSemicolonComments"
                          }),
                      name: "allowSemicolonComments",
                      value: fasta.allow_semicolon_comments,
                      onChange: (function (allow_semicolon_comments) {
                          return Curry._1(onChange, {
                                      allow_sharp_comments: fasta.allow_sharp_comments,
                                      allow_semicolon_comments: allow_semicolon_comments,
                                      allow_empty_lines: fasta.allow_empty_lines,
                                      allow_comments_only_at_top: fasta.allow_comments_only_at_top,
                                      max_line_length: fasta.max_line_length,
                                      alphabet: fasta.alphabet
                                    });
                        })
                    })), React.createElement("div", {
                  className: styles.formGroup
                }, React.createElement(Checkbox.make, {
                      label: React.createElement(ReactIntl.FormattedMessage, {
                            id: "entityCreator.fields.fasta.allowEmptyLines"
                          }),
                      name: "allowEmptyLines",
                      value: fasta.allow_empty_lines,
                      onChange: (function (allow_empty_lines) {
                          return Curry._1(onChange, {
                                      allow_sharp_comments: fasta.allow_sharp_comments,
                                      allow_semicolon_comments: fasta.allow_semicolon_comments,
                                      allow_empty_lines: allow_empty_lines,
                                      allow_comments_only_at_top: fasta.allow_comments_only_at_top,
                                      max_line_length: fasta.max_line_length,
                                      alphabet: fasta.alphabet
                                    });
                        })
                    })), React.createElement("div", {
                  className: styles.formGroup
                }, React.createElement(Checkbox.make, {
                      label: React.createElement(ReactIntl.FormattedMessage, {
                            id: "entityCreator.fields.fasta.allowCommentsOnlyAtTop"
                          }),
                      name: "allowCommentsOnlyAtTop",
                      value: fasta.allow_comments_only_at_top,
                      onChange: (function (allow_comments_only_at_top) {
                          return Curry._1(onChange, {
                                      allow_sharp_comments: fasta.allow_sharp_comments,
                                      allow_semicolon_comments: fasta.allow_semicolon_comments,
                                      allow_empty_lines: fasta.allow_empty_lines,
                                      allow_comments_only_at_top: allow_comments_only_at_top,
                                      max_line_length: fasta.max_line_length,
                                      alphabet: fasta.alphabet
                                    });
                        })
                    })));
}

var Fasta = {
  make: FormatEditor$Fasta
};

function FormatEditor$Fastq(Props) {
  var fastq = Props.fastq;
  var onChange = Props.onChange;
  return React.createElement("div", {
              className: styles.formGroup
            }, React.createElement(VariantSelect.make, {
                  variantsWithValues: Curry._2(Import.List.map, Root$SolvuuApi.Fmt.fastq_all, (function (x) {
                          return /* tuple */[
                                  x,
                                  Curry._1(Root$SolvuuApi.Fmt.fastq_to_string_hum, x)
                                ];
                        })),
                  variant: fastq,
                  onChange: onChange,
                  name: format$1,
                  label: React.createElement(ReactIntl.FormattedMessage, {
                        id: "entityCreator.fields.fastq.format"
                      })
                }));
}

var Fastq$1 = {
  make: FormatEditor$Fastq
};

function FormatEditor$Gff(Props) {
  var gff_version = Props.gff_version;
  var onChange = Props.onChange;
  return React.createElement("div", {
              className: styles.formGroup
            }, React.createElement(VariantSelect.make, {
                  variantsWithValues: Curry._2(Import.List.map, Root$SolvuuApi.Fmt.gff_version_all, (function (x) {
                          return /* tuple */[
                                  x,
                                  Curry._1(Root$SolvuuApi.Fmt.gff_version_to_string_hum, x)
                                ];
                        })),
                  variant: gff_version,
                  onChange: onChange,
                  name: format$2,
                  label: React.createElement(ReactIntl.FormattedMessage, {
                        id: "entityCreator.fields.gff.format"
                      })
                }));
}

var Gff$1 = {
  make: FormatEditor$Gff
};

function FormatEditor$Base(Props) {
  var format = Props.format;
  var onChange = Props.onChange;
  var match = Props.enableOptional;
  var enableOptional = match !== undefined ? match : true;
  var match$1 = Props.enableGzip;
  var enableGzip = match$1 !== undefined ? match$1 : true;
  var type_ = React.createElement("div", {
        className: styles.formGroup
      }, React.createElement(VariantSelect.make, {
            variantsWithValues: Curry._2(Import.List.map, Root$SolvuuApi.Fmt.Type.all, (function (x) {
                    return /* tuple */[
                            x,
                            Curry._1(Root$SolvuuApi.Fmt.Type.to_string_hum, x)
                          ];
                  })),
            variant: Curry._1(Root$SolvuuApi.Fmt.type_of, format),
            onChange: (function (x) {
                return Curry._1(onChange, Curry._1(Root$SolvuuApi.Fmt.Default.of_type, x));
              }),
            name: selectableFormat,
            label: React.createElement(ReactIntl.FormattedMessage, {
                  id: "entityCreator.fields.format"
                })
          }));
  var match$2 = Curry._1(Root$SolvuuApi.Fmt.base, format);
  var editor;
  var exit = 0;
  if (typeof match$2 === "number") {
    exit = 1;
  } else {
    var variant = match$2[0];
    if (variant >= 243398181) {
      if (variant !== 243398197) {
        if (variant !== 737157829) {
          if (variant >= 243398182) {
            exit = 1;
          } else {
            editor = React.createElement(FormatEditor$Fasta, {
                  fasta: match$2[1],
                  onChange: (function (x) {
                      return Curry._1(onChange, /* `Fasta */[
                                  243398181,
                                  x
                                ]);
                    })
                });
          }
        } else {
          editor = React.createElement(FormatEditor$Biom, {
                biom_version: match$2[1],
                onChange: (function (x) {
                    return Curry._1(onChange, /* `Biom */[
                                737157829,
                                x
                              ]);
                  })
              });
        }
      } else {
        editor = React.createElement(FormatEditor$Fastq, {
              fastq: match$2[1],
              onChange: (function (x) {
                  return Curry._1(onChange, /* `Fastq */[
                              243398197,
                              x
                            ]);
                })
            });
      }
    } else if (variant !== 3357606) {
      if (variant !== 3553607) {
        exit = 1;
      } else {
        editor = React.createElement(FormatEditor$Gff, {
              gff_version: match$2[1],
              onChange: (function (x) {
                  return Curry._1(onChange, /* `Gff */[
                              3553607,
                              x
                            ]);
                })
            });
      }
    } else {
      editor = React.createElement(FormatEditor$Csv, {
            csv: match$2[1],
            onChange: (function (x) {
                return Curry._1(onChange, /* `Csv */[
                            3357606,
                            x
                          ]);
              })
          });
    }
  }
  if (exit === 1) {
    editor = React.createElement(ReactIntl.FormattedMessage, {
          id: "charts.fileSelection.format.notCustomizable"
        });
  }
  var gzip;
  if (enableGzip) {
    var tmp;
    if (typeof format === "number") {
      tmp = false;
    } else {
      var variant$1 = format[0];
      if (variant$1 !== 374637448) {
        tmp = variant$1 === 793449722;
      } else {
        var match$3 = format[1];
        tmp = typeof match$3 === "number" ? false : match$3[0] === 793449722;
      }
    }
    gzip = React.createElement(Checkbox.make, {
          label: React.createElement(ReactIntl.FormattedMessage, {
                id: "entityCreator.fields.blobs.format.gzipped"
              }),
          name: gzipped,
          value: tmp,
          onChange: (function (x) {
              return Curry._1(onChange, gzipAtMostOnce(format, x));
            })
        });
  } else {
    gzip = null;
  }
  var optional$1 = enableOptional ? React.createElement(Checkbox.make, {
          label: React.createElement(ReactIntl.FormattedMessage, {
                id: "entityCreator.fields.blobs.format.optional"
              }),
          name: optional,
          value: typeof format === "number" ? false : format[0] === 374637448,
          onChange: (function (x) {
              return Curry._1(onChange, maybeAtMostOnce(format, x));
            })
        }) : null;
  return React.createElement("div", undefined, type_, editor, React.createElement("div", {
                  className: styles.formGroupInline
                }, gzip, optional$1));
}

var Base = {
  make: FormatEditor$Base
};

function FormatEditor(Props) {
  var format = Props.format;
  var onSubmit = Props.onSubmit;
  var onCancel = Props.onCancel;
  var enableOptional = Props.enableOptional;
  var enableGzip = Props.enableGzip;
  var match = React.useState((function () {
          return Belt_Option.getWithDefault(format, /* `Csv */[
                      3357606,
                      Root$SolvuuApi.Fmt.Default.csv
                    ]);
        }));
  var setFormat = match[1];
  var format$1 = match[0];
  var match$1 = React.useState((function () {
          return false;
        }));
  var setOpened = match$1[1];
  var toggle = React.createElement("summary", {
        className: styles.formatEditorToggle,
        onClick: (function ($$event) {
            $$event.preventDefault();
            return Curry._1(setOpened, (function (x) {
                          return !x;
                        }));
          })
      }, React.createElement(ReactIntl.FormattedMessage, {
            id: "charts.fileSelection.format.header",
            tagName: "strong"
          }));
  var readable = Curry._1(Root$SolvuuApi.Fmt.to_string_hum, format$1);
  var format_hum = React.createElement(Layout.VerticalLayoutItem, {
        children: readable
      });
  var submit = React.createElement(Button.make, Button.makeProps((function (param) {
              Curry._1(setOpened, (function (param) {
                      return false;
                    }));
              return Curry._1(onSubmit, format$1);
            }), undefined, /* `Basic */[
            -1058563442,
            Curry._4(Button.Type.Basic.make, undefined, /* Primary */-791844958, undefined, /* () */0)
          ], React.createElement(ReactIntl.FormattedMessage, {
                id: "charts.fileSelection.actions.changeFormat",
                tagName: "strong"
              }), /* () */0));
  var cancel = React.createElement(Button.make, Button.makeProps((function (param) {
              Curry._1(setOpened, (function (param) {
                      return false;
                    }));
              return Curry._1(onCancel, /* () */0);
            }), undefined, /* `Basic */[
            -1058563442,
            Curry._4(Button.Type.Basic.make, undefined, /* Secondary */67972948, undefined, /* () */0)
          ], React.createElement(ReactIntl.FormattedMessage, {
                id: "charts.fileSelection.actions.cancelFormat"
              }), /* () */0));
  var tmp = {
    format: format$1,
    onChange: (function (x) {
        return Curry._1(setFormat, (function (param) {
                      return x;
                    }));
      })
  };
  if (enableOptional !== undefined) {
    tmp.enableOptional = Caml_option.valFromOption(enableOptional);
  }
  if (enableGzip !== undefined) {
    tmp.enableGzip = Caml_option.valFromOption(enableGzip);
  }
  var editor = React.createElement(React.Fragment, undefined, React.createElement(Layout.VerticalLayoutItem, {
            children: React.createElement(FormatEditor$Base, tmp)
          }), React.createElement(Layout.VerticalLayoutItem, {
            children: React.createElement(Layout.HorizontalLayout, HorizontalLayout.makeProps(/* x3 */26811, null, /* () */0), React.createElement(Layout.HorizontalLayoutItem, {
                      children: submit
                    }), React.createElement(Layout.HorizontalLayoutItem, {
                      children: cancel
                    }))
          }));
  return React.createElement("details", {
              className: styles.formatEditor,
              open: match$1[0]
            }, toggle, React.createElement(Layout.VerticalLayout, VerticalLayout.makeProps(/* x2 */26810, null, /* () */0), format_hum, editor));
}

var make = React.memo(FormatEditor, (function (prim, prim$1) {
        return Equals.default(prim, prim$1);
      }));

var Csv$1 = {
  make: FormatEditor$Csv
};

export {
  make ,
  Base ,
  Biom$1 as Biom,
  Csv$1 as Csv,
  Fasta ,
  Fastq$1 as Fastq,
  Gff$1 as Gff,
  FieldName ,
  TestIds ,
  
}
/* styles Not a pure module */
