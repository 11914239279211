/**
 * Module for handling upload-related actions:
 *
 * * Fetching all uploaded blobs
 * * Fetching an individual blob
 * * Fetching events for individual blob
 * * Starting the upload process
 * * Tracking upload progress (both client and server-side uploads)
 */
import { combineReducers } from "redux";
import update from "immutability-helper";
import { any, partition, values, zip } from "ramda";

import {
  createOperation,
  createReducer,
  getId,
  groupById
} from "features/utils";
import { blobsSaga } from "features/upload/subscriptions";
import { mapApiBlob } from "lib/solvuu/blobUtils";
import * as SolvuuApiWrapper from "lib/solvuu/solvuuApiWrapper.bs";
import S3BlobUpload from "lib/S3BlobUpload";

// CONSTANTS

const UPLOAD_START = "upload/UPLOAD_START";
const UPLOAD_PROGRESS = "upload/UPLOAD_PROGRESS";
const UPLOAD_SUCCESS = "upload/UPLOAD_SUCCESS";
const UPLOAD_FAILURE = "upload/UPLOAD_FAILURE";

const blobFetchOperation = createOperation("UPLOAD/BLOB_FETCH");
const blobsFetchOperation = createOperation("UPLOAD/BLOBS_FETCH");
const blobsCreateFromFilesOperation = createOperation(
  "UPLOAD/BLOBS_CREATE_FROM_FILES"
);
const blobCreateFromURIOperation = createOperation(
  "UPLOAD/BLOB_CREATE_FROM_URI"
);
const eventsFetchOperation = createOperation("UPLOAD/BLOB_EVENTS_FETCH");

// ACTIONS

/**
 * Action fired when files should be directly uploaded as new blobs.
 *
 * @param {Array<File>} files
 *
 * @returns undefined. The individual uploads may succeed or fail.
 * The statuses are tracked in separate slices of the state.
 */
export function uploadBlobsFromFiles(files) {
  const {
    start,
    success,
    failure
  } = blobsCreateFromFilesOperation.actionCreators;

  return async function(dispatch, getState, { solvuuApi }) {
    dispatch(start());
    const requests = files.map(file => solvuuApi.createBlobFromFile(file));
    const responses = await Promise.all(requests);

    const [successful, failed] = partition(
      ([response, _]) => response.ok,
      zip(responses, files)
    );

    await dispatch(fetchBlobs());

    if (failed.length === 0) {
      dispatch(success());
    } else {
      const fileNames = failed.map(([_, file]) => file.name);
      const joinedFileNames = fileNames.join(", ");
      const message = `The following files failed to upload: ${joinedFileNames}`;
      dispatch(failure({ message, fileNames }));
    }

    const uploads = successful.map(([response, file]) => {
      const id = SolvuuApiWrapper.Js.blobCreateResponseToDetails(response)
        .blobId;
      return dispatch(startUpload({ id, file }));
    });
    await Promise.all(uploads);
  };
}

/**
 * Action fired when an URI should be added as a new blob.
 *
 * @param {String} uri
 *
 * @returns undefined
 */
export function createBlobFromURI(uri) {
  const { start, success, failure } = blobCreateFromURIOperation.actionCreators;

  return async function(dispatch, getState, { solvuuApi }) {
    dispatch(start());

    const response = await solvuuApi.createBlobFromURI(uri);
    if (response.ok) {
      await dispatch(fetchBlobs());
      dispatch(success());
    } else {
      const message = "Could not add the URI";
      dispatch(failure({ message, data: response.data }));
    }
  };
}

/**
 * Action fired when a file should be uploaded under a blob ID that is already known.
 * Use case: uploading necessary files after creating a term.
 *
 * @param {Object} options:
 *  - {String} id - Blob ID
 *  - {File} file
 *
 * @returns {Boolean} result - true if the file started uploading
 */
export function startUpload({ id, file }) {
  return async function(dispatch, getState, { solvuuApi }) {
    const service = new S3BlobUpload(id, file, solvuuApi);
    let result = false;
    const onStart = () => {
      result = true;
      dispatch(uploadStart({ id, file }));
    };
    const onProgress = event => {
      dispatch(uploadProgress(id, event.loaded, event.total));
    };
    const onSuccess = async () => {
      dispatch(uploadSuccess(id));
    };
    const onFailure = () => {
      dispatch(uploadFailure(id));
    };
    await service.send({ onStart, onProgress, onSuccess, onFailure });
    return result;
  };
}

export function fetchBlobs() {
  const { start, success, failure } = blobsFetchOperation.actionCreators;

  return async function(dispatch, getState, { solvuuApi }) {
    dispatch(start());
    const response = await solvuuApi.fetchBlobs();
    if (response.ok) {
      const data = response.data.map(mapApiBlob);
      dispatch(success({ data }));
    } else {
      dispatch(failure());
    }
  };
}

export function fetchBlob(blobId) {
  const { start, success, failure } = blobFetchOperation.actionCreators;

  return async function(dispatch, getState, { solvuuApi }) {
    dispatch(start({ blobId }));
    const response = await solvuuApi.fetchBlob(blobId);
    if (response.ok) {
      const details = SolvuuApiWrapper.Js.blobGetResponseToDetails(response);
      const data = mapApiBlob(details.blob);
      dispatch(success({ blobId, data }));
    } else {
      dispatch(failure({ blobId }));
    }
  };
}

export function fetchBlobIfNecessary(blobId) {
  return async function(dispatch, getState) {
    const { blobFetch } = blobMetaSelector(getState(), blobId);
    const blob = uploadSelector(getState(), blobId);

    if (blobFetch.pending) return;
    if (blobFetch.success) return;
    if (blob && blob.meta.isUploading) return;

    return await dispatch(fetchBlob(blobId));
  };
}

export function fetchBlobEvents(blobId) {
  const { start, success, failure } = eventsFetchOperation.actionCreators;
  return async function(dispatch, getState, { solvuuApi }) {
    dispatch(start({ blobId }));
    const response = await solvuuApi.fetchBlobEvents(blobId);
    if (response.ok) {
      const data = response.data;
      dispatch(success({ blobId, data }));
    } else {
      dispatch(failure({ blobId }));
    }
  };
}

// SAGAS

export { blobsSaga };

// Internal

function uploadStart(payload) {
  return {
    type: UPLOAD_START,
    payload
  };
}

function uploadProgress(id, uploadedBytes, totalBytes) {
  return {
    type: UPLOAD_PROGRESS,
    payload: { id, uploadedBytes, totalBytes }
  };
}

function uploadSuccess(id) {
  return {
    type: UPLOAD_SUCCESS,
    payload: { id }
  };
}

function uploadFailure(id) {
  return {
    type: UPLOAD_FAILURE,
    payload: { id }
  };
}

export const test_internalActions = {
  uploadStart,
  uploadProgress,
  uploadSuccess,
  uploadFailure
};

// INITIAL STATE

export const initialState = {
  blobsById: {},
  eventsById: {},
  visibleBlobIds: [],
  visibleEventsByBlobId: {},
  meta: {
    blobsFetch: blobsFetchOperation.initialState,
    blobsCreateFromFiles: blobsCreateFromFilesOperation.initialState,
    blobCreateFromURI: blobCreateFromURIOperation.initialState,
    blobFetch: {}
  }
};

// SELECTORS

export function uploadIdsSelector(state) {
  return state.upload.visibleBlobIds;
}

export function uploadSelector(state, id) {
  return state.upload.blobsById[id];
}

export function blobSelector(state, id) {
  const upload = uploadSelector(state, id);
  return upload ? upload.originalBlob : null;
}

export function uploadProgressPercentageSelector(state, id) {
  const blob = uploadSelector(state, id);
  if (!blob) return null;

  const { totalBytes, uploadedBytes } = blob.meta;
  if (totalBytes === 0) return 100;
  if (totalBytes === null || totalBytes === undefined) return 0;
  if (uploadedBytes === null || uploadedBytes === undefined) return 0;

  return (uploadedBytes * 100) / totalBytes;
}

export function visibleBlobEventsSelector(state, id) {
  const events = state.upload.visibleEventsByBlobId[id];
  return events ? events : initialVisibleEventsByBlobIdState;
}

export function blobEventSelector(state, id) {
  const {
    upload: { eventsById }
  } = state;
  return eventsById[id];
}

export function uploadMetaSelector(state) {
  const {
    blobsFetch,
    blobsCreateFromFiles,
    blobCreateFromURI
  } = state.upload.meta;
  return { blobsFetch, blobsCreateFromFiles, blobCreateFromURI };
}

export function blobMetaSelector(state, blobId) {
  const blobFetch =
    state.upload.meta.blobFetch[blobId] || blobFetchOperation.initialState;

  return {
    blobFetch
  };
}

const anyInProgress = any(u => u.meta.isUploading && u.file);
export function uploadInProgressSelector(state) {
  return anyInProgress(values(state.upload.blobsById));
}

export function blobTotalBytesSelector(state, id) {
  const upload = uploadSelector(state, id);
  if (!upload) return null;

  return upload.meta.totalBytes;
}

// REDUCERS

function blobsByIdReducer(state = initialState.blobsById, action) {
  switch (action.type) {
    case UPLOAD_START: {
      const { payload } = action;
      return {
        ...state,
        [payload.id]: {
          ...state[payload.id],
          id: payload.id,
          originalName: payload.file.name,
          file: payload.file,
          meta: {
            totalBytes: payload.file.size,
            uploadedBytes: 0,
            isUploading: true,
            isUploaded: false,
            uploadError: false
          }
        }
      };
    }
    case UPLOAD_PROGRESS: {
      const {
        payload: { id, uploadedBytes, totalBytes }
      } = action;
      return update(state, {
        [id]: {
          meta: {
            uploadedBytes: { $set: uploadedBytes },
            totalBytes: { $set: totalBytes }
          }
        }
      });
    }
    case UPLOAD_SUCCESS: {
      const { payload } = action;
      return update(state, {
        [payload.id]: {
          meta: {
            isUploading: { $set: false },
            isUploaded: { $set: true }
          }
        }
      });
    }
    case UPLOAD_FAILURE: {
      const { payload } = action;
      return update(state, {
        [payload.id]: {
          meta: {
            isUploading: { $set: false },
            uploadError: { $set: true }
          }
        }
      });
    }
    case blobsFetchOperation.actionTypes.SUCCESS: {
      const blobsById = groupById(action.payload.data);
      return update(state, { $merge: blobsById });
    }
    case blobFetchOperation.actionTypes.SUCCESS: {
      const blobsById = groupById([action.payload.data]);
      return update(state, { $merge: blobsById });
    }
    default:
      return state;
  }
}

function visibleBlobIdsReducer(state = initialState.visibleBlobIds, action) {
  switch (action.type) {
    case blobsFetchOperation.actionTypes.SUCCESS:
      return action.payload.data.map(getId).reverse();
    default:
      return state;
  }
}

function setEvents(state, action) {
  const eventsById = groupById(action.payload.data);
  return { ...state, ...eventsById };
}

function setEventIds(state, action) {
  return action.payload.data.map(getId).reverse();
}

function setNestedEventByBlobId(state, action) {
  const blobId = action.payload.blobId;
  return {
    ...state,
    [blobId]: visibleEventsByBlobIdSingleReducer(state[blobId], action)
  };
}

function setNestedBlobFetch(state, action) {
  const blobId = action.payload.blobId;
  return {
    ...state,
    [blobId]: blobFetchOperation.reducer(state[blobId], action)
  };
}

const eventsByIdReducer = createReducer(initialState.eventsById, {
  [eventsFetchOperation.actionTypes.SUCCESS]: setEvents
});

const initialVisibleEventsByBlobIdState = {
  ids: [],
  meta: {
    fetch: eventsFetchOperation.initialState
  }
};

const visibleEventIdsReducer = createReducer(
  initialVisibleEventsByBlobIdState.ids,
  {
    [eventsFetchOperation.actionTypes.SUCCESS]: setEventIds
  }
);

const visibleEventsByBlobIdSingleReducer = combineReducers({
  ids: visibleEventIdsReducer,
  meta: combineReducers({
    fetch: eventsFetchOperation.reducer
  })
});

const visibleEventsByBlobIdReducer = createReducer(
  initialState.visibleEventsByBlobId,
  {
    [eventsFetchOperation.actionTypes.START]: setNestedEventByBlobId,
    [eventsFetchOperation.actionTypes.SUCCESS]: setNestedEventByBlobId,
    [eventsFetchOperation.actionTypes.FAILURE]: setNestedEventByBlobId
  }
);

const blobFetchReducer = createReducer(initialState.meta.blobFetch, {
  [blobFetchOperation.actionTypes.START]: setNestedBlobFetch,
  [blobFetchOperation.actionTypes.SUCCESS]: setNestedBlobFetch,
  [blobFetchOperation.actionTypes.FAILURE]: setNestedBlobFetch
});

export default combineReducers({
  blobsById: blobsByIdReducer,
  eventsById: eventsByIdReducer,
  visibleBlobIds: visibleBlobIdsReducer,
  visibleEventsByBlobId: visibleEventsByBlobIdReducer,
  meta: combineReducers({
    blobsFetch: blobsFetchOperation.reducer,
    blobsCreateFromFiles: blobsCreateFromFilesOperation.reducer,
    blobCreateFromURI: blobCreateFromURIOperation.reducer,
    blobFetch: blobFetchReducer
  })
});
