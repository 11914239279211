

import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as TableCss from "./Table.css";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as TableElements from "./TableElements.bs.js";

var styles = TableCss.default;

function termToString(_term) {
  while(true) {
    var term = _term;
    if (typeof term === "number") {
      return ;
    } else {
      var variant = term[0];
      if (variant !== -976970511) {
        if (variant !== 106380200) {
          if (variant !== 925978388) {
            return ;
          } else {
            _term = term[1];
            continue ;
          }
        } else {
          var match = term[1];
          if (typeof match === "number") {
            return ;
          } else {
            var variant$1 = match[0];
            if (variant$1 !== 771069884) {
              if (variant$1 !== 937565914) {
                return ;
              } else {
                return match[1][2];
              }
            } else {
              return match[1][1];
            }
          }
        }
      } else {
        return term[1];
      }
    }
  };
}

function termToContents(context, term, apiClient, renderTerm) {
  var element = Curry._6(renderTerm, apiClient, term, /* Type_not_available */-883458824, context, undefined, /* () */0);
  if (typeof term === "number") {
    if (term !== 870828711) {
      return /* Content */Block.__(0, [element]);
    } else {
      return /* Missing */0;
    }
  } else if (term[0] !== 106380200) {
    return /* Content */Block.__(0, [element]);
  } else {
    return /* Error */Block.__(1, [element]);
  }
}

function contentsToElement(contents) {
  if (typeof contents === "number") {
    return React.createElement(ReactIntl.FormattedMessage, {
                id: "table.cell.missing"
              });
  } else {
    return contents[0];
  }
}

function TableCell(Props) {
  var term = Props.term;
  var column = Props.column;
  var context = Props.context;
  var apiClient = Props.apiClient;
  var renderTerm = Props.renderTerm;
  var cellContents = termToContents(context, term, apiClient, renderTerm);
  var cellVariant;
  cellVariant = typeof cellContents === "number" ? /* Missing */2 : (
      cellContents.tag ? /* Error */3 : /* Regular */0
    );
  var cellTitle = termToString(term);
  var cellElement = contentsToElement(cellContents);
  var tmp = {
    testId: column[0],
    variant: cellVariant,
    children: React.createElement("div", {
          className: styles.nowrap
        }, cellElement)
  };
  if (cellTitle !== undefined) {
    tmp.title = Caml_option.valFromOption(cellTitle);
  }
  return React.createElement(TableElements.CellElement.make, tmp);
}

var make = TableCell;

export {
  contentsToElement ,
  termToContents ,
  make ,
  
}
/* styles Not a pure module */
