

import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as SectionCss from "./Section.css";

var styles = SectionCss.default;

function Section(Props) {
  var id = Props.id;
  var children = Props.children;
  var tmp = {
    className: styles.section
  };
  if (id !== undefined) {
    tmp.id = Caml_option.valFromOption(id);
  }
  return React.createElement("section", tmp, children);
}

function Section$Header(Props) {
  var match = Props.inverted;
  var inverted = match !== undefined ? match : false;
  var children = Props.children;
  return React.createElement("h3", {
              className: inverted ? styles.sectionHeaderInverted : styles.sectionHeader
            }, children);
}

var Header = {
  make: Section$Header
};

function Section$BackgroundGradient(Props) {
  return React.createElement("div", {
              className: styles.backgroundGradient
            });
}

var BackgroundGradient = {
  make: Section$BackgroundGradient
};

var make = Section;

export {
  styles ,
  make ,
  Header ,
  BackgroundGradient ,
  
}
/* styles Not a pure module */
