import { entityByPathSelector } from "features/entities";
import { download } from "features/entities/blobViews";
import { blobIdOfTerm, hasBlobIdInTerm } from "lib/solvuu/termUtils";

// ACTIONS

export function requestEntityDownload(path) {
  return async function(dispatch, getState) {
    const entity = entityByPathSelector(getState(), path);
    const blobId = blobIdOfTerm(entity.term);
    await dispatch(download(blobId));
  };
}

// SELECTORS

export function entityDownloadPermissionSelector(state, path) {
  const entity = entityByPathSelector(state, path);

  // Only allow for downloading blobs
  return entity && entity.term && hasBlobIdInTerm(entity.term);
}
