import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import VirtualizedSelect from "react-virtualized-select";
import createFilterOptions from "react-select-fast-filter-options";
import cx from "classnames";
import memoize from "memoize-one";
import { equals } from "ramda";

import styles from "./style.css";

// Don't bother creating an index for less than this many options
const MIN_INDEX_OPTIONS = 2000;

function createOptions(values, valueToOption) {
  const options = values.map(valueToOption);
  const filterOptions =
    options.length > MIN_INDEX_OPTIONS
      ? createFilterOptions({ options })
      : undefined;
  return { options, filterOptions };
}

function defaultValueToOption(value) {
  return { label: value, value };
}

export default class OptimizedSelect extends PureComponent {
  static propTypes = {
    values: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])
    ).isRequired,
    valueToOption: PropTypes.func,
    className: PropTypes.string
  };

  static defaultProps = {
    valueToOption: defaultValueToOption
  };

  createOptions = memoize(createOptions, equals);

  getOptions = () => {
    const { values, valueToOption } = this.props;
    return this.createOptions(values, valueToOption);
  };

  optionHeight = ({ option }) => {
    // If the option label is a React element, it won't have length property
    return Math.ceil(option.label.length / 30) * 35 || 35;
  };

  render() {
    const {
      values,
      className: passedClassName,
      valueToOption,
      ...rest
    } = this.props;
    const { options, filterOptions } = this.getOptions();
    const className = cx(passedClassName, styles.root);

    return (
      <VirtualizedSelect
        options={options}
        filterOptions={filterOptions}
        className={className}
        optionHeight={this.optionHeight}
        {...rest}
      />
    );
  }
}
