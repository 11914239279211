

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Paths from "../../../Paths.bs.js";
import * as React from "react";
import * as Js_dict from "bs-platform/lib/es6/js_dict.js";
import * as Section from "../Section.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Classnames from "classnames";
import * as StyleCss from "./style.css";

var styles = StyleCss.default;

function Company$OutboundLink(Props) {
  var href = Props.href;
  var children = Props.children;
  return React.createElement("a", {
              href: href,
              rel: "noopener noreferrer",
              target: "_blank"
            }, children);
}

var OutboundLink = {
  make: Company$OutboundLink
};

var ashishAgarwal = require("./person-ashish-agarwal.png");

var richardDavison = require("./person-richard-davison.png");

var rajSasidharan = require("./person-raj-sasidharan.png");

var tomaszPewinski = require("./person-tomasz-pewinski.png");

var kevinSchoon = require("./person-kevin-schoon.png");

var bobHarper = require("./person-bob-harper.png");

var nickSocci = require("./person-nick-socci.png");

var tomCirrito = require("./person-tom-cirrito.png");

var $$Image = {
  ashishAgarwal: ashishAgarwal,
  richardDavison: richardDavison,
  rajSasidharan: rajSasidharan,
  tomaszPewinski: tomaszPewinski,
  kevinSchoon: kevinSchoon,
  bobHarper: bobHarper,
  nickSocci: nickSocci,
  tomCirrito: tomCirrito
};

function Company$Person$T(Props) {
  var children = Props.children;
  var match = React.useState((function () {
          return false;
        }));
  var setExpanded = match[1];
  var className = Classnames.default(styles.person, Js_dict.fromArray(/* array */[/* tuple */[
              styles.personMobileExpanded,
              match[0]
            ]]));
  return React.createElement("div", {
              className: styles.personWrapper,
              onClick: (function (param) {
                  return Curry._1(setExpanded, (function (param) {
                                return true;
                              }));
                })
            }, React.createElement("div", {
                  className: className
                }, children));
}

var T = {
  make: Company$Person$T
};

var ashishAgarwal$1 = React.createElement(Company$Person$T, {
      children: null
    }, React.createElement("img", {
          className: styles.personImage,
          alt: "",
          src: ashishAgarwal
        }), React.createElement("span", {
          className: styles.personName
        }, "Ashish Agarwal, PhD"), React.createElement("span", {
          className: styles.personTitle
        }, "Founder & CEO"), React.createElement("div", {
          className: styles.personBio
        }, "Ashish founded Solvuu with a mission to accelerate the pace of\n                innovation in science. He previously worked at New York\n                University where he implemented their new Genomics Core\n                facility’s computer hardware and software. He was the lead\n                bioinformatics scientist for a modENCODE project during his\n                postdoc at Yale, and he held a research faculty position in\n                Yale’s Department of Computer Science. Ashish’s PhD was in the\n                area of programming language theory from Carnegie Mellon\n                University and he studied engineering at UC Berkeley.", React.createElement("ul", {
              className: styles.personLinks
            }, React.createElement("li", undefined, React.createElement(Company$OutboundLink, {
                      href: "https://www.linkedin.com/in/agarwal1975/",
                      children: "LinkedIn"
                    })), React.createElement("li", undefined, React.createElement(Company$OutboundLink, {
                      href: "https://scholar.google.com/citations?user=lwNzZSYAAAAJ",
                      children: "Google Scholar"
                    })))));

var rajSasidharan$1 = React.createElement(Company$Person$T, {
      children: null
    }, React.createElement("img", {
          className: styles.personImage,
          alt: "",
          src: rajSasidharan
        }), React.createElement("span", {
          className: styles.personName
        }, "Raj Sasidharan, PhD"), React.createElement("span", {
          className: styles.personTitle
        }, "Director of Genomics"), React.createElement("div", {
          className: styles.personBio
        }, "Raj provides technical and strategic leadership on\n                bioinformatics and genomics at Solvuu. He has a PhD in\n                Structural Bioinformatics from the University of Cambridge, UK\n                and did postdoctoral work in bioinformatics and genomics at Yale\n                University and UCLA. As a Senior Scientist at BASF, Raj managed\n                data science projects on crop protection, trait research,\n                microbiome-based biologicals and digital agriculture. Raj’s\n                expertise is in developing bioinformatics solutions to make\n                sense of large and complex datasets in the areas of regenerative\n                medicine, immuno-oncology, microbiome research and agricultural\n                solutions.", React.createElement("ul", {
              className: styles.personLinks
            }, React.createElement("li", undefined, React.createElement(Company$OutboundLink, {
                      href: "https://www.linkedin.com/in/raj-sasidharan-7924486/",
                      children: "LinkedIn"
                    })), React.createElement("li", undefined, React.createElement(Company$OutboundLink, {
                      href: "https://scholar.google.com/citations?user=eoOUw84AAAAJ",
                      children: "Google Scholar"
                    })))));

var richardDavison$1 = React.createElement(Company$Person$T, {
      children: null
    }, React.createElement("img", {
          className: styles.personImage,
          alt: "",
          src: richardDavison
        }), React.createElement("span", {
          className: styles.personName
        }, "Richard Davison"), React.createElement("span", {
          className: styles.personTitle
        }, "Software Engineer"), React.createElement("div", {
          className: styles.personBio
        }, "Richard has a Computer Science degree from Stetson University\n                and previously worked as a data engineer in the Ad-tech\n                industry. He enjoys architecting distributed big data software\n                systems using devops and functional programming. When he isn’t\n                herding bits around the internet, he enjoys learning languages\n                and playing the bassoon.", React.createElement("ul", {
              className: styles.personLinks
            }, React.createElement("li", undefined, React.createElement(Company$OutboundLink, {
                      href: "https://www.linkedin.com/in/richardndavison/",
                      children: "LinkedIn"
                    })), React.createElement("li", undefined, React.createElement(Company$OutboundLink, {
                      href: "https://github.com/rdavison",
                      children: "GitHub"
                    })))));

var tomaszPewinski$1 = React.createElement(Company$Person$T, {
      children: null
    }, React.createElement("img", {
          className: styles.personImage,
          alt: "",
          src: tomaszPewinski
        }), React.createElement("span", {
          className: styles.personName
        }, "Tomasz Pewiński"), React.createElement("span", {
          className: styles.personTitle
        }, "Software Engineer"), React.createElement("div", {
          className: styles.personBio
        }, "Tomasz works at the intersection of user interfaces and\n                functional programming and holds a Master’s degree in Computer\n                Science from Poznań University of Technology. He has experience\n                building a number of different products, including front-ends,\n                for financial institutions, market research, social networks,\n                and online gaming. At Solvuu, Tomasz is focused on delivering\n                rich data visualizations and the best user experience to improve\n                the efficiency of data scientists.", React.createElement("ul", {
              className: styles.personLinks
            }, React.createElement("li", undefined, React.createElement(Company$OutboundLink, {
                      href: "https://www.linkedin.com/in/pewniak747/",
                      children: "LinkedIn"
                    })), React.createElement("li", undefined, React.createElement(Company$OutboundLink, {
                      href: "https://github.com/pewniak747",
                      children: "GitHub"
                    })), React.createElement("li", undefined, React.createElement(Company$OutboundLink, {
                      href: "https://pewniak747.info",
                      children: "Blog"
                    })))));

var kevinSchoon$1 = React.createElement(Company$Person$T, {
      children: null
    }, React.createElement("img", {
          className: styles.personImage,
          alt: "",
          src: kevinSchoon
        }), React.createElement("span", {
          className: styles.personName
        }, "Kevin Schoon"), React.createElement("span", {
          className: styles.personTitle
        }, "DevOps Engineer"), React.createElement("div", {
          className: styles.personBio
        }, "Kevin is a software developer with extensive Systems\n                Administration and DevOps experience. He has architected systems\n                for e-discovery, ad-tech, and media. He has traveled extensively\n                across Asia, South America, and Europe.", React.createElement("ul", {
              className: styles.personLinks
            }, React.createElement("li", undefined, React.createElement(Company$OutboundLink, {
                      href: "https://github.com/kevinschoon",
                      children: "GitHub"
                    })))));

var bobHarper$1 = React.createElement(Company$Person$T, {
      children: null
    }, React.createElement("img", {
          className: styles.personImage,
          alt: "",
          src: bobHarper
        }), React.createElement("span", {
          className: styles.personName
        }, "Robert Harper, PhD"), React.createElement("span", {
          className: styles.personTitle
        }, "Advisor"), React.createElement("span", {
          className: styles.personAdvisorTitle
        }, "Professor, Department of Computer Science", React.createElement("br", undefined), "Carnegie Mellon University"), React.createElement("div", {
          className: styles.personBio
        }, "Robert Harper is faculty in the Computer Science Department at\n                Carnegie Mellon University. He has made major contributions to\n                the theoretical foundations of programming languages and type\n                systems. He was named an ACM Fellow in 2005."));

var nickSocci$1 = React.createElement(Company$Person$T, {
      children: null
    }, React.createElement("img", {
          className: styles.personImage,
          alt: "",
          src: nickSocci
        }), React.createElement("span", {
          className: styles.personName
        }, "Nicholas Socci, PhD"), React.createElement("span", {
          className: styles.personTitle
        }, "Advisor"), React.createElement("span", {
          className: styles.personAdvisorTitle
        }, "Head of Engineering and Bioinformatics, Center for Molecular\n                Oncology", React.createElement("br", undefined), "Director, Bioinformatics Core", React.createElement("br", undefined), "Memorial Sloan Kettering Cancer Center"), React.createElement("div", {
          className: styles.personBio
        }, "Nicholas Socci oversees computational and bioinformatics\n                research support for Memorial Sloan Kettering Cancer Center\n                (MSKCC). He develops and deploys research applications and\n                pipelines, provides bioinformatics consulting, and manages\n                high-performance computing resources. He has expertise\n                developing statistical and computational methods for the\n                analysis of high-throughput data in cancer biology and genomics.", React.createElement("ul", {
              className: styles.personLinks
            }, React.createElement("li", undefined, React.createElement(Company$OutboundLink, {
                      href: "https://www.linkedin.com/in/nicholas-socci-b647a72/",
                      children: "LinkedIn"
                    })))));

var tomCirrito$1 = React.createElement(Company$Person$T, {
      children: null
    }, React.createElement("img", {
          className: styles.personImage,
          alt: "",
          src: tomCirrito
        }), React.createElement("span", {
          className: styles.personName
        }, "Tom Cirrito, PhD"), React.createElement("span", {
          className: styles.personTitle
        }, "Advisor"), React.createElement("span", {
          className: styles.personAdvisorTitle
        }, "CEO and Co-founder, Biotagenics", React.createElement("br", undefined), "CIO, Crosswalk Ventures"), React.createElement("div", {
          className: styles.personBio
        }, "Tom Cirrito is a serial biotechnology executive with a history\n                of building successful development strategies for discovery,\n                preclinical and clinical programs. He has a track record of\n                implementing unconventional strategies to produce\n                transformational value to decrease development time, accelerate\n                value creation, or minimize cost and/or risk. Tom led operations\n                and business development through the IPO of Stemline\n                Therapeutics. He has a proven ability to convert novel\n                scientific concepts into actionable programs that incorporate\n                the complexity of scientific, regulatory, financial, clinical\n                and operational elements necessary to build a sustainable\n                biotechnology company.", React.createElement("ul", {
              className: styles.personLinks
            }, React.createElement("li", undefined, React.createElement(Company$OutboundLink, {
                      href: "https://www.linkedin.com/in/tomcirrito/",
                      children: "LinkedIn"
                    })))));

var Person = {
  $$Image: $$Image,
  T: T,
  make: Company$Person$T,
  ashishAgarwal: ashishAgarwal$1,
  rajSasidharan: rajSasidharan$1,
  richardDavison: richardDavison$1,
  tomaszPewinski: tomaszPewinski$1,
  kevinSchoon: kevinSchoon$1,
  bobHarper: bobHarper$1,
  nickSocci: nickSocci$1,
  tomCirrito: tomCirrito$1
};

var all = /* array */[
  {
    title: "Formal Methods Engineer",
    position: "Full Time",
    location: "New York City",
    description: React.createElement("p", undefined, "Our technology is based on ideas from type theory and functional\n        programming. We have a senior position open for someone\n        who can drive the core of our product forward in collaboration\n        with our founder. The ideal candidate would have a PhD in Programming\n        Languages and at least 5 years of experience building production\n        software on a modern stack (AWS, Kubernetes, Docker, etc). Strong verbal\n        and written communication skills and a demonstrated ability to manage a\n        complex software project are essential.")
  },
  {
    title: "Market Analyst",
    position: "Internship (Year-Round)",
    location: "New York City, San Francisco",
    description: React.createElement("p", undefined, "We provide software technology and bioinformatics expertise to life\n        scientists. If you want to be on the business side of science,\n        we would love to hear from you. We have a range of projects such as\n        conducting in-depth market research, working with our design team to\n        create marketing material, and engaging with target customers. An ideal\n        candidate likely has a bachelor\'s degree in Biology\n        and is currently an MBA student or a PhD candidate interested\n        in sales and marketing. We support internships year-round.")
  },
  {
    title: "Data Scientist",
    position: "Internship (Year-Round)",
    location: "New York City, San Francisco",
    description: React.createElement("p", undefined, "Our technology can be applied to a range of life science areas (see our ", React.createElement("a", {
              href: Paths.science,
              rel: "noopener noreferrer",
              target: "_blank"
            }, "Science"), " page). Data science interns leverage our team and technology to\n        conduct scientific analysis at the interface of genomics and computer\n        science. The goal is to enhance our scientific team, guide product\n        development, and engage with customers in that space. Internships\n        culminate in a presentation and written reports. An ideal candidate is\n        likely in their 3rd year or later of a PhD program in computational\n        biology. We support internships year-round.")
  },
  {
    title: "OCaml Programmer",
    position: "Contractor",
    location: "New York City, Remote",
    description: React.createElement("p", undefined, "We are a functional programming shop and use OCaml across our entire\n        stack. We are always looking for great OCaml programmers to augment\n        our team. Depending on your expertise and interests, you could work on\n        our internal code or one of our open source projects. We normally\n        consider only candidates with 3+ years of full-time OCaml experience.\n        Remote work is welcome.")
  },
  {
    title: "ReasonML Programmer",
    position: "Contractor",
    location: "New York City, Remote",
    description: React.createElement("p", undefined, "Web technologies have recently matured, enabling us to hold our\n        front-end programming to the same standards we have always demanded of\n        our back-end. If you’re an experienced front-end developer with a\n        passion for strongly typed functional programming languages, we would\n        love to hear from you. Remote work is welcome.")
  },
  {
    title: "Bioinformatician",
    position: "Contractor",
    location: "New York City, San Francisco, Remote",
    description: React.createElement("p", undefined, "We are looking for highly skilled bioinformaticians with deep expertise\n        in the design, implementation, and iterative enhancement\n        of one or more sequence analysis workflows (variant\n        calling and interpretation, genome-wide transcriptome characterization,\n        microbial genomics, epigenomics, single-cell genomics, and genome\n        assembly and annotation). Experience in the design and implementation\n        of computational algorithms that utilize both statistical and\n        machine learning approaches is preferred. Qualified candidates will have\n        at least a PhD and preferrably 2+ years of additional experience\n        working as a bioinformatician either as a postdoc or in industry.")
  }
];

var JobListing = {
  all: all
};

function Company(Props) {
  var handleKeyDown = function ($$event) {
    if ($$event.keyCode === 32 || $$event.keyCode === 13) {
      $$event.preventDefault();
      $$event.stopPropagation();
      return $$event.target.click();
    } else {
      return 0;
    }
  };
  return React.createElement("div", {
              className: styles.root
            }, React.createElement("header", {
                  className: styles.header
                }, React.createElement("h1", undefined, "Digitizing Life Sciences"), React.createElement("div", {
                      className: styles.headerBackground
                    }, React.createElement("div", {
                          className: styles.headerBackgroundGradient
                        }))), React.createElement(Section.make, {
                  children: React.createElement("div", {
                        className: styles.overview
                      }, React.createElement("p", undefined, "Solvuu is a data science company for life scientists.\n              We provide a cloud-based data management platform with\n              standardized access to data, tools, and visualization across\n              biological data formats. We have defined a system rich enough\n              to harmonize all the myriad data formats used in life sciences,\n              specifically bioinformatics, thereby liberating scientists to\n              focus on science. Our technology abstracts away the format of\n              the data, allowing users to focus on the semantic content\n              of the data."), React.createElement("p", undefined, "Instead of having to learn and utilize numerous tools, each of\n              which works on just one data format, with Solvuu, you can\n              convert, transform, filter, search, analyze and visualize over all\n              data formats through a single framework. Access our framework\n              in three ways: a web UI for ease and interactive visualizations, a\n              CLI for scripting and bulk operations, or an API for building your\n              own applications."))
                }), React.createElement(Section.make, {
                  children: null
                }, React.createElement(Section.BackgroundGradient.make, { }), React.createElement(Section.Header.make, {
                      children: "Team"
                    }), React.createElement("div", {
                      className: styles.people
                    }, ashishAgarwal$1, rajSasidharan$1), React.createElement("div", {
                      className: styles.people
                    }, richardDavison$1, tomaszPewinski$1, kevinSchoon$1, bobHarper$1, nickSocci$1, tomCirrito$1)), React.createElement(Section.make, {
                  children: null
                }, React.createElement(Section.Header.make, {
                      children: "Location"
                    }), React.createElement("div", {
                      className: styles.location
                    }, React.createElement("div", {
                          className: styles.addresses
                        }, React.createElement("div", {
                              className: styles.address
                            }, React.createElement("p", {
                                  className: styles.addressCity
                                }, "NEW YORK ", React.createElement("small", undefined, "(Headquarters)")), React.createElement("p", {
                                  className: styles.addressLine
                                }, "BioLabs"), React.createElement("p", {
                                  className: styles.addressLine
                                }, "180 Varick St., 6th Floor"), React.createElement("p", {
                                  className: styles.addressLine
                                }, "New York, NY 10014")), React.createElement("div", {
                              className: styles.address
                            }, React.createElement("p", {
                                  className: styles.addressCity
                                }, "CALIFORNIA"), React.createElement("p", {
                                  className: styles.addressLine
                                }, "Johnson & Johnson Innovation \xe2\x80\x93 JLABS"), React.createElement("p", {
                                  className: styles.addressLine
                                }, "329 Oyster Point Blvd., 3rd Floor"), React.createElement("p", {
                                  className: styles.addressLine
                                }, "South San Francisco, CA 94080"))), React.createElement("a", {
                          className: styles.contactEmail,
                          href: "mailto:info@solvuu.com"
                        }, "info@solvuu.com"))), React.createElement(Section.make, {
                  children: null
                }, React.createElement("div", {
                      className: styles.jobs
                    }, React.createElement(Section.Header.make, {
                          inverted: true,
                          children: "Jobs"
                        }), React.createElement("div", {
                          className: styles.jobListings
                        }, Belt_Array.map(all, (function (listing) {
                                return React.createElement("div", {
                                            key: listing.title,
                                            className: styles.jobListing
                                          }, React.createElement("div", {
                                                className: styles.jobListingSummary
                                              }, React.createElement("span", {
                                                    className: styles.jobListingTitle
                                                  }, listing.title), React.createElement("span", {
                                                    className: styles.jobListingPosition
                                                  }, listing.position), React.createElement("span", {
                                                    className: styles.jobListingLocation
                                                  }, listing.location), React.createElement("label", {
                                                    className: styles.jobListingDetailsButton,
                                                    role: "button",
                                                    tabIndex: 0,
                                                    htmlFor: "job[" + (listing.title + "]"),
                                                    onKeyDown: handleKeyDown
                                                  }, "See details")), React.createElement("input", {
                                                className: styles.jobListingDetailsShown,
                                                id: "job[" + (listing.title + "]"),
                                                type: "checkbox"
                                              }), React.createElement("div", {
                                                className: styles.jobListingDetails
                                              }, listing.description, React.createElement("a", {
                                                    className: styles.jobListingApply,
                                                    href: "mailto:jobs@solvuu.com?subject=Job application: " + listing.title
                                                  }, "Apply Now")));
                              })))), React.createElement("div", {
                      className: styles.jobsBackground
                    }), React.createElement("div", {
                      className: styles.jobsBackgroundGradient
                    })));
}

var make = Company;

export {
  styles ,
  OutboundLink ,
  Person ,
  JobListing ,
  make ,
  
}
/* styles Not a pure module */
