

import * as Err from "../components/Err.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Import from "../features/import.bs.js";
import * as Record from "../containers/Entities/Record/Record.bs.js";
import * as Select from "../components/Select/Select.bs.js";
import * as Loading from "../components/Loading.bs.js";
import * as FormGroup from "../components/FormGroup/FormGroup.bs.js";
import * as WithTestId from "../containers/WithTestId.bs.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as ReactExtras from "../lib/solvuu/ReactExtras.bs.js";
import * as ReactSelect from "../lib/ReactSelect.bs.js";
import * as Lang$SolvuuApi from "solvuu-api/./lang.bs.js";
import * as Root$SolvuuApi from "solvuu-api/./root.bs.js";
import * as VerticalLayout from "../components/Layout/VerticalLayout.bs.js";
import * as TermRenderContext from "../lang/TermRenderContext.bs.js";
import * as Layout from "components/Layout";
import * as TableVisualizationCard from "./TableVisualizationCard.bs.js";
import * as TableQuantileQuantilePlotCss from "./TableQuantileQuantilePlot.css";

var styles = TableQuantileQuantilePlotCss.default;

function TableQuantileQuantilePlot$Loading(Props) {
  return React.createElement("div", {
              className: styles.centered
            }, React.createElement(Loading.make, { }));
}

function TableQuantileQuantilePlot$State$ColumnSelect(Props) {
  var columnName = Props.columnName;
  var columns = Props.columns;
  var dispatch = Props.dispatch;
  var options = Curry._2(Import.List.map, Curry._2(Import.List.keep, columns, (function (param) {
              return Curry._1(Root$SolvuuApi.Quantile_quantile_plot.Arg.dataTypeAvailable, param[1]);
            })), (function (param) {
          var name = param[0];
          return ReactSelect.$$Option.make(name, name);
        }));
  var onChange = function (options) {
    var update = function (term) {
      return Curry._1(dispatch, /* `UpdateArg */[
                  615103405,
                  /* tuple */[
                    Root$SolvuuApi.Quantile_quantile_plot.Arg.Const.Label.columnName,
                    (function (param) {
                        return term;
                      })
                  ]
                ]);
    };
    if (options !== undefined) {
      return update(/* `Some */[
                  925978388,
                  /* `String */[
                    -976970511,
                    options.value
                  ]
                ]);
    } else {
      return update(/* Null */870828711);
    }
  };
  return React.createElement(Layout.VerticalLayoutItem, {
              children: null
            }, React.createElement(FormGroup.$$Element.make, {
                  name: "column",
                  children: React.createElement(WithTestId.make, {
                        id: "quantile-quantile-plot-column-select",
                        children: React.createElement(Select.make, {
                              label: React.createElement(ReactIntl.FormattedMessage, {
                                    id: "table.quantileQuantilePlot.columnSelect.label"
                                  }),
                              value: Belt_Option.getWithDefault(columnName, ""),
                              onChange: onChange,
                              options: options
                            })
                      })
                }), React.createElement(FormGroup.FieldInfoElement.make, {
                  children: React.createElement(ReactIntl.FormattedMessage, {
                        id: "table.quantileQuantilePlot.columnSelect.info"
                      })
                }));
}

function TableQuantileQuantilePlot$State$TransformSelect(Props) {
  var transform = Props.transform;
  var dispatch = Props.dispatch;
  var label = React.createElement(ReactIntl.FormattedMessage, {
        id: "table.quantileQuantilePlot.transformSelect.noTransform"
      });
  var label$1 = React.createElement(ReactIntl.FormattedMessage, {
        id: "table.quantileQuantilePlot.transformSelect.log2Transform"
      });
  var label$2 = React.createElement(ReactIntl.FormattedMessage, {
        id: "table.quantileQuantilePlot.transformSelect.minusLog10Transform"
      });
  var selectOptions_000 = ReactSelect.$$Option.make("NoTransform", label);
  var selectOptions_001 = /* :: */[
    ReactSelect.$$Option.make("Log2Transform", label$1),
    /* :: */[
      ReactSelect.$$Option.make("MinusLog10Transform", label$2),
      /* [] */0
    ]
  ];
  var selectOptions = /* :: */[
    selectOptions_000,
    selectOptions_001
  ];
  var handleSelect = function (opt) {
    if (opt !== undefined) {
      var transform = opt.value;
      return Curry._1(dispatch, /* `UpdateArg */[
                  615103405,
                  /* tuple */[
                    Root$SolvuuApi.Quantile_quantile_plot.Arg.Const.Label.transform,
                    (function (param) {
                        return /* `String */[
                                -976970511,
                                transform
                              ];
                      })
                  ]
                ]);
    } else {
      return /* () */0;
    }
  };
  var info;
  var exit = 0;
  switch (transform) {
    case "Log2Transform" :
    case "MinusLog10Transform" :
        exit = 1;
        break;
    case "NoTransform" :
        info = null;
        break;
    default:
      info = React.createElement(Err.Operation.make, {
            message: "Unexpected option: " + transform
          });
  }
  if (exit === 1) {
    info = React.createElement(FormGroup.FieldInfoElement.make, {
          children: React.createElement(ReactIntl.FormattedMessage, {
                id: "table.quantileQuantilePlot.transformSelect.logInfo"
              })
        });
  }
  return React.createElement(FormGroup.$$Element.make, {
              name: "transform",
              children: null
            }, React.createElement(WithTestId.make, {
                  id: "quantile-quantile-plot-transform-select",
                  children: React.createElement(Select.make, {
                        label: React.createElement(ReactIntl.FormattedMessage, {
                              id: "table.quantileQuantilePlot.transformSelect.label"
                            }),
                        value: transform,
                        onChange: handleSelect,
                        options: selectOptions
                      })
                }), info);
}

function TableQuantileQuantilePlot$State(Props) {
  var onClose = Props.onClose;
  var state = Props.state;
  var dataType = Props.dataType;
  var dispatch = Props.dispatch;
  var graphic = state.graphic;
  if (typeof dataType !== "number" && dataType[0] === 848054398) {
    var match = dataType[1];
    if (typeof match !== "number" && match[0] === 847309489) {
      var columns = match[1][0];
      return React.createElement(Record.ParsedRecord.make, {
                  children: Curry._2(Root$SolvuuApi.Quantile_quantile_plot.Arg.of_record, state.arg, (function (columnName, transform, param) {
                          var title = React.createElement(ReactIntl.FormattedMessage, {
                                id: "table.quantileQuantilePlot.header"
                              });
                          var controls = React.createElement(Layout.VerticalLayout, VerticalLayout.makeProps(/* x4 */26812, null, /* () */0), React.createElement(Layout.VerticalLayoutItem, {
                                    children: React.createElement(TableQuantileQuantilePlot$State$ColumnSelect, {
                                          columnName: columnName,
                                          columns: columns,
                                          dispatch: dispatch
                                        })
                                  }), React.createElement(Layout.VerticalLayoutItem, {
                                    children: React.createElement(TableQuantileQuantilePlot$State$TransformSelect, {
                                          transform: transform,
                                          dispatch: dispatch
                                        })
                                  }));
                          return React.createElement(TableVisualizationCard.make, {
                                      icon: /* QuantileQuantilePlot */739741055,
                                      title: title,
                                      controls: controls,
                                      onClose: onClose,
                                      children: ReactExtras.withTestId("entity-static-table-quantile-quantile-plot", React.createElement("div", {
                                                className: styles.root
                                              }, graphic))
                                    });
                        }))
                });
    }
    
  }
  return React.createElement(Err.Operation.make, {
              message: "Expected table"
            });
}

function TableQuantileQuantilePlot(Props) {
  var onClose = Props.onClose;
  var data = Props.data;
  var $$eval = Props.eval;
  var apiClient = Props.apiClient;
  var renderTerm = Props.renderTerm;
  var term = data[0];
  var state_arg = Curry._3(Lang$SolvuuApi.Record.update, Root$SolvuuApi.Quantile_quantile_plot.Arg.Const.Default.arg, Root$SolvuuApi.Quantile_quantile_plot.Arg.Const.Label.data, (function (param) {
          return term;
        }));
  var state = {
    arg: state_arg,
    graphic: null
  };
  var match = React.useReducer((function (param, param$1) {
          var apiClient$1 = apiClient;
          var renderTerm$1 = renderTerm;
          var param$2 = param;
          var $$event = param$1;
          var state = param$2[0];
          var evalEffect = function (arg) {
            return /* `Evaluate */[
                    -620618631,
                    /* `App */[
                      3257473,
                      /* tuple */[
                        /* `Var */[
                          4298439,
                          Root$SolvuuApi.quantile_quantile_plot
                        ],
                        /* `Record */[
                          847309489,
                          arg
                        ]
                      ]
                    ]
                  ];
          };
          if ($$event[0] >= 615103405) {
            var match = $$event[1];
            var state_arg = Curry._3(Lang$SolvuuApi.Record.update, state.arg, match[0], match[1]);
            var state_graphic = React.createElement(TableQuantileQuantilePlot$Loading, { });
            var state$1 = {
              arg: state_arg,
              graphic: state_graphic
            };
            var effect = evalEffect(state_arg);
            return /* tuple */[
                    state$1,
                    effect
                  ];
          } else {
            var graphic = Curry._6(renderTerm$1, apiClient$1, $$event[1], /* Type_not_available */-883458824, TermRenderContext.root, undefined, /* () */0);
            var state_arg$1 = state.arg;
            var state$2 = {
              arg: state_arg$1,
              graphic: graphic
            };
            return /* tuple */[
                    state$2,
                    undefined
                  ];
          }
        }), /* tuple */[
        state,
        undefined
      ]);
  var dispatch = match[1];
  var match$1 = match[0];
  var effect = match$1[1];
  React.useEffect((function () {
          if (effect !== undefined) {
            Curry._1($$eval, effect[1]).then((function (term) {
                    Curry._1(dispatch, /* `Graphic */[
                          -1051164024,
                          term
                        ]);
                    return Promise.resolve(/* () */0);
                  }));
            return ;
          }
          
        }), /* array */[effect]);
  return React.createElement(TableQuantileQuantilePlot$State, {
              onClose: onClose,
              state: match$1[0],
              dataType: data[1],
              dispatch: dispatch
            });
}

var make = TableQuantileQuantilePlot;

export {
  make ,
  
}
/* styles Not a pure module */
