

import * as Op from "../../features/Op.bs.js";
import * as Term from "../../lang/Term.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Infer from "../../Infer.bs.js";
import * as React from "react";
import * as Import from "../../features/import.bs.js";
import * as Source from "../Source.bs.js";
import * as Loading from "../../components/Loading.bs.js";
import * as TermEval from "../../lib/solvuu/termEval.bs.js";
import * as TypeUtils from "../../lib/solvuu/typeUtils.bs.js";
import * as ExitPrompt from "../../components/ExitPrompt/ExitPrompt.bs.js";
import * as Pervasives from "bs-platform/lib/es6/pervasives.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as ChartsCss from "./Charts.css";
import * as FormatEditor from "../../components/FormatEditor/FormatEditor.bs.js";
import * as Fmt$SolvuuApi from "solvuu-api/./fmt.bs.js";
import * as VerticalLayout from "../../components/Layout/VerticalLayout.bs.js";
import * as TermRenderContext from "../../lang/TermRenderContext.bs.js";
import * as Layout from "components/Layout";

var styles = ChartsCss.default;

function toFileSelectStatus(x) {
  var variant = x[0];
  if (variant !== -783600662) {
    if (variant >= 951752159) {
      return /* `Running */[
              951752159,
              x[1]
            ];
    } else {
      return /* `Output */[
              -1055554783,
              /* `Ok */[
                17724,
                x[1].data[0]
              ]
            ];
    }
  } else {
    return /* `Input */[
            -783600662,
            x[1].data
          ];
  }
}

function entityContents(apiClient, x, renderTerm) {
  var variant = x[0];
  if (variant !== -783600662) {
    if (variant >= 951752159) {
      return React.createElement(Loading.make, { });
    } else {
      var match = x[1].data[1];
      if (typeof match === "number") {
        return React.createElement(Loading.make, { });
      } else {
        var match$1 = match[1];
        return Curry._6(renderTerm, apiClient, match$1[0], match$1[1], TermRenderContext.root, undefined, /* () */0);
      }
    }
  } else {
    return null;
  }
}

function askBeforeExiting(x, isExitingCharts) {
  if (x[0] !== -783600662) {
    return /* `Always */[
            -76840209,
            {
              message: "Press Ok to discard current results",
              hasLocationChanged: Caml_option.some(isExitingCharts)
            }
          ];
  } else {
    return /* Never */744337004;
  }
}

function useState(apiClient) {
  return Op.useOp(undefined, (function (source) {
                if (source !== undefined) {
                  var source$1 = source;
                  return Import.$great$great$pipe(Import.$great$great$eq(Source.load(source$1), (function (file) {
                                    return Import.$great$great$eq(Term.bytesOfFile(apiClient, file), (function (contents) {
                                                  var apiClient$1 = apiClient;
                                                  var filename = file.name;
                                                  var contents$1 = contents;
                                                  return Import.$great$great$eq(Infer.infer(filename, undefined, apiClient$1, contents$1), (function (inferred) {
                                                                if (typeof inferred === "number" || inferred[0] < 17724) {
                                                                  return Promise.resolve(/* Missing */14018374);
                                                                } else {
                                                                  var match = inferred[1];
                                                                  if (match) {
                                                                    var match$1 = Curry._1(Fmt$SolvuuApi.Parse.of_term, match[0]);
                                                                    if (typeof match$1 === "number") {
                                                                      return Promise.resolve(/* Disabled */-709493348);
                                                                    } else {
                                                                      var format = match$1[1][0];
                                                                      return Import.$great$great$pipe(TermEval.$$eval(apiClient$1)(Curry._2(Fmt$SolvuuApi.Parse.to_term, format, contents$1)), (function (term) {
                                                                                    var type_ = TypeUtils.inferTermTypeRe(term, /* Type_not_available */-883458824);
                                                                                    return /* `Present */[
                                                                                            120414939,
                                                                                            /* tuple */[
                                                                                              term,
                                                                                              type_,
                                                                                              format
                                                                                            ]
                                                                                          ];
                                                                                  }));
                                                                    }
                                                                  } else {
                                                                    return Promise.resolve(/* Missing */14018374);
                                                                  }
                                                                }
                                                              }));
                                                }));
                                  })), (function (format) {
                                return /* `Done */[
                                        759635106,
                                        /* tuple */[
                                          source$1,
                                          format
                                        ]
                                      ];
                              }));
                } else {
                  return Promise.resolve(/* `Retry */[
                              -101284024,
                              undefined
                            ]);
                }
              }));
}

function Charts(Props) {
  var apiClient = Props.apiClient;
  var state = useState(apiClient);
  var clearFile = Belt_Option.getWithDefault(Op.reset(state), (function (param) {
          return /* () */0;
        }));
  var selectFile = function (file) {
    if (state[0] !== -783600662) {
      return /* () */0;
    } else {
      var match = state[1];
      Curry._1(match.setInput, (function (param) {
              return file;
            }));
      return Curry._1(match.run, /* () */0);
    }
  };
  React.useEffect((function () {
          if (state[0] < -783600662) {
            var match = state[1].data;
            var x = match[0];
            if (x[0] >= 781515420) {
              if (match[1] !== 14018374) {
                
              } else {
                Pervasives.failwith("FIXME");
              }
            } else if (match[1] !== 14018374) {
              
            } else {
              selectFile(x);
            }
          }
          return ;
        }), ([]));
  var handleChangeFormat = React.useCallback((function (fmt) {
          return /* () */0;
        }), ([]));
  var isExitingCharts = function (param, param$1) {
    return false;
  };
  var tmp;
  if (state[0] >= -783600662) {
    tmp = null;
  } else {
    var match = state[1].data[1];
    tmp = typeof match === "number" ? (
        match >= 14018374 ? React.createElement(FormatEditor.make, {
                format: undefined,
                onSubmit: handleChangeFormat,
                onCancel: (function (param) {
                    console.log("cancel");
                    return /* () */0;
                  }),
                enableOptional: false,
                enableGzip: true
              }) : null
      ) : React.createElement(FormatEditor.make, {
            format: match[1][2],
            onSubmit: handleChangeFormat,
            onCancel: (function (param) {
                console.log("cancel");
                return /* () */0;
              }),
            enableOptional: false,
            enableGzip: true
          });
  }
  return React.createElement("div", {
              className: styles.root
            }, React.createElement(Layout.VerticalLayout, VerticalLayout.makeProps(/* x2 */26810, null, /* () */0), React.createElement(Layout.VerticalLayoutItem, {
                      children: React.createElement(Source.make, {
                            accept: ".biom,.newick,.nwk,.csv,.tsv,.txt",
                            status: toFileSelectStatus(state),
                            onSelect: selectFile,
                            onClear: (function (param) {
                                if (window.confirm("Are you sure?")) {
                                  return Curry._1(clearFile, /* () */0);
                                } else {
                                  return 0;
                                }
                              })
                          })
                    }), React.createElement(Layout.VerticalLayoutItem, {
                      children: tmp
                    }), React.createElement(Layout.VerticalLayoutItem, {
                      children: entityContents(apiClient, state, Term.render)
                    })), React.createElement(ExitPrompt.make, {
                  when_: askBeforeExiting(state, isExitingCharts)
                }));
}

var make = Charts;

export {
  make ,
  
}
/* styles Not a pure module */
