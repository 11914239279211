

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Import from "./features/import.bs.js";
import * as $$String from "bs-platform/lib/es6/string.js";
import * as V4 from "uuid/v4";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Pervasives from "bs-platform/lib/es6/pervasives.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Lang$SolvuuApi from "solvuu-api/./lang.bs.js";
import * as Root$SolvuuApi from "solvuu-api/./root.bs.js";

function kind(x) {
  if (x >= 270345389) {
    if (x >= 737157829) {
      if (x >= 799219443) {
        if (x >= 954245580) {
          if (x !== 969511896) {
            if (x !== 977193341) {
              if (x >= 988997452) {
                return /* Graphical */711231859;
              } else {
                return /* Tool */937068408;
              }
            } else {
              return /* Statistical */-608833509;
            }
          } else {
            return /* Other */-912009552;
          }
        } else if (x !== 907398765) {
          if (x >= 799219444 && x < 935668598) {
            return /* Tool */937068408;
          } else {
            return /* Graphical */711231859;
          }
        } else {
          return /* Statistical */-608833509;
        }
      } else if (x >= 775314475) {
        if (x >= 790610449) {
          if (x !== 792454412) {
            return /* Tool */937068408;
          } else {
            return /* Format */841155415;
          }
        } else if (x >= 780238890) {
          return /* Graphical */711231859;
        } else {
          return /* Statistical */-608833509;
        }
      } else if (x >= 756135328) {
        if (x >= 758139700) {
          return /* Graphical */711231859;
        } else {
          return /* Tool */937068408;
        }
      } else if (x !== 739741055) {
        return /* Format */841155415;
      } else {
        return /* Graphical */711231859;
      }
    } else if (x >= 484974721) {
      if (x < 653925531) {
        if (x >= 486079769) {
          if (x >= 593715004) {
            if (x >= 642196241) {
              return /* Format */841155415;
            } else {
              return /* Tool */937068408;
            }
          } else if (x >= 586017299) {
            return /* Graphical */711231859;
          } else {
            return /* Tool */937068408;
          }
        } else {
          return /* Format */841155415;
        }
      } else if (x !== 672270517) {
        if (x !== 687725842) {
          return /* Tool */937068408;
        } else {
          return /* Graphical */711231859;
        }
      } else {
        return /* Statistical */-608833509;
      }
    } else if (x >= 366103461) {
      if (x !== 377205848) {
        if (x >= 366103462 && x < 464204031) {
          return /* Tool */937068408;
        } else {
          return /* Graphical */711231859;
        }
      } else {
        return /* Statistical */-608833509;
      }
    } else if (x >= 300511203) {
      if (x >= 360427206) {
        return /* Statistical */-608833509;
      } else {
        return /* Graphical */711231859;
      }
    } else if (x !== 288973297) {
      return /* Tool */937068408;
    } else {
      return /* Graphical */711231859;
    }
  } else if (x >= -265814703) {
    if (x >= 3552952) {
      if (x >= 56500868) {
        if (x >= 83361156) {
          if (x >= 243398181) {
            if (x >= 265105709) {
              return /* Graphical */711231859;
            } else {
              return /* Format */841155415;
            }
          } else if (x >= 135999336) {
            return /* Tool */937068408;
          } else {
            return /* Graphical */711231859;
          }
        } else {
          return /* Tool */937068408;
        }
      } else if (x >= 17082542) {
        if (x >= 56152651) {
          return /* Graphical */711231859;
        } else {
          return /* Tool */937068408;
        }
      } else if (x !== 4292161) {
        return /* Format */841155415;
      } else {
        return /* Tool */937068408;
      }
    } else if (x >= -49423019) {
      if (x !== 3308748 && x >= -967340 && x < 3546223) {
        return /* Format */841155415;
      } else {
        return /* Tool */937068408;
      }
    } else if (x !== -214208189) {
      if (x >= -265545066 && x < -55180330) {
        return /* Tool */937068408;
      } else {
        return /* Graphical */711231859;
      }
    } else {
      return /* Statistical */-608833509;
    }
  } else if (x >= -496309491) {
    if (x >= -338593844) {
      if (x !== -313597417) {
        if (x >= -338593843 && x < -288656400) {
          return /* Graphical */711231859;
        } else {
          return /* Tool */937068408;
        }
      } else {
        return /* Statistical */-608833509;
      }
    } else if (x >= -449223643) {
      if (x !== -427462846) {
        return /* Graphical */711231859;
      } else {
        return /* Tool */937068408;
      }
    } else if (x >= -490342859) {
      return /* Tool */937068408;
    } else {
      return /* Graphical */711231859;
    }
  } else if (x >= -642279940) {
    if (x !== -533179959) {
      if (x !== -508885141 && x >= -642279939) {
        return /* Tool */937068408;
      } else {
        return /* Graphical */711231859;
      }
    } else {
      return /* Statistical */-608833509;
    }
  } else if (x !== -844938357) {
    if (x !== -809444755) {
      return /* Tool */937068408;
    } else {
      return /* Format */841155415;
    }
  } else {
    return /* Graphical */711231859;
  }
}

var varMap_000 = /* tuple */[
  undefined,
  /* Anova */907398765
];

var varMap_001 = /* :: */[
  /* tuple */[
    Root$SolvuuApi.area_chart,
    /* AreaChart */288973297
  ],
  /* :: */[
    /* tuple */[
      Root$SolvuuApi.bar_chart,
      /* BarChart */-298608085
    ],
    /* :: */[
      /* tuple */[
        Root$SolvuuApi.box_plot,
        /* BoxPlot */-412176980
      ],
      /* :: */[
        /* tuple */[
          Root$SolvuuApi.bubble_chart,
          /* BubbleChart */118811026
        ],
        /* :: */[
          /* tuple */[
            undefined,
            /* CircularPlot */359953472
          ],
          /* :: */[
            /* tuple */[
              Root$SolvuuApi.csv,
              /* Csv */3357606
            ],
            /* :: */[
              /* tuple */[
                undefined,
                /* Cytoscape */-265545067
              ],
              /* :: */[
                /* tuple */[
                  undefined,
                  /* Dendrogram */-508885141
                ],
                /* :: */[
                  /* tuple */[
                    Root$SolvuuApi.donut_chart,
                    /* DonutChart */-642279940
                  ],
                  /* :: */[
                    /* tuple */[
                      Root$SolvuuApi.dot_plot,
                      /* DotPlot */-318813910
                    ],
                    /* :: */[
                      /* tuple */[
                        Root$SolvuuApi.filter,
                        /* Filter */969511896
                      ],
                      /* :: */[
                        /* tuple */[
                          undefined,
                          /* FisherTest */-313597417
                        ],
                        /* :: */[
                          /* tuple */[
                            undefined,
                            /* GenomeBrowser */-265814703
                          ],
                          /* :: */[
                            /* tuple */[
                              Root$SolvuuApi.heat_map,
                              /* Heatmap */988997452
                            ],
                            /* :: */[
                              /* tuple */[
                                Root$SolvuuApi.hexbin_plot,
                                /* HexbinPlot */265105709
                              ],
                              /* :: */[
                                /* tuple */[
                                  Root$SolvuuApi.heat_map,
                                  /* HierarchicalClustering */588923761
                                ],
                                /* :: */[
                                  /* tuple */[
                                    Root$SolvuuApi.histogram,
                                    /* Histogram */117882500
                                  ],
                                  /* :: */[
                                    /* tuple */[
                                      Root$SolvuuApi.kernel_density,
                                      /* KernelDensity */-844938357
                                    ],
                                    /* :: */[
                                      /* tuple */[
                                        Root$SolvuuApi.heat_map,
                                        /* KMeansClustering */56152651
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          undefined,
                                          /* KolmogorovSmirnovTest */977193341
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            Root$SolvuuApi.krona_chart,
                                            /* KronaChart */300511203
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              undefined,
                                              /* KrustkallWallisRankSumTest */377205848
                                            ],
                                            /* :: */[
                                              /* tuple */[
                                                Root$SolvuuApi.line_chart,
                                                /* LineChart */780238890
                                              ],
                                              /* :: */[
                                                /* tuple */[
                                                  undefined,
                                                  /* MannWhitneyUTest */672270517
                                                ],
                                                /* :: */[
                                                  /* tuple */[
                                                    undefined,
                                                    /* NetworkDiagram */-449223643
                                                  ],
                                                  /* :: */[
                                                    /* tuple */[
                                                      Root$SolvuuApi.parallel_coordinates,
                                                      /* ParallelCoordinates */-322318508
                                                    ],
                                                    /* :: */[
                                                      /* tuple */[
                                                        undefined,
                                                        /* PathwayMap */316537906
                                                      ],
                                                      /* :: */[
                                                        /* tuple */[
                                                          undefined,
                                                          /* PhylogeneticTree */799219443
                                                        ],
                                                        /* :: */[
                                                          /* tuple */[
                                                            undefined,
                                                            /* PieChart */687725842
                                                          ],
                                                          /* :: */[
                                                            /* tuple */[
                                                              Root$SolvuuApi.principal_coordinates,
                                                              /* PrincipalCoordinates */-496309491
                                                            ],
                                                            /* :: */[
                                                              /* tuple */[
                                                                Root$SolvuuApi.quantile_quantile_plot,
                                                                /* QuantileQuantilePlot */739741055
                                                              ],
                                                              /* :: */[
                                                                /* tuple */[
                                                                  undefined,
                                                                  /* SankeyDiagram */758139700
                                                                ],
                                                                /* :: */[
                                                                  /* tuple */[
                                                                    Root$SolvuuApi.scatter_matrix,
                                                                    /* ScatterMatrix */464204031
                                                                  ],
                                                                  /* :: */[
                                                                    /* tuple */[
                                                                      Root$SolvuuApi.scatter_plot,
                                                                      /* ScatterPlot */266627423
                                                                    ],
                                                                    /* :: */[
                                                                      /* tuple */[
                                                                        undefined,
                                                                        /* SequenceMotifs */366103461
                                                                      ],
                                                                      /* :: */[
                                                                        /* tuple */[
                                                                          Root$SolvuuApi.stats,
                                                                          /* SummaryStatistics */-533179959
                                                                        ],
                                                                        /* :: */[
                                                                          /* tuple */[
                                                                            Root$SolvuuApi.t_test,
                                                                            /* TTest */360427206
                                                                          ],
                                                                          /* :: */[
                                                                            /* tuple */[
                                                                              undefined,
                                                                              /* TSNE */935668598
                                                                            ],
                                                                            /* :: */[
                                                                              /* tuple */[
                                                                                Root$SolvuuApi.venn_diagram,
                                                                                /* VennDiagram */83361156
                                                                              ],
                                                                              /* :: */[
                                                                                /* tuple */[
                                                                                  Root$SolvuuApi.violin_plot,
                                                                                  /* ViolinPlot */-55180330
                                                                                ],
                                                                                /* :: */[
                                                                                  /* tuple */[
                                                                                    Root$SolvuuApi.volcano_plot,
                                                                                    /* VolcanoPlot */586017299
                                                                                  ],
                                                                                  /* :: */[
                                                                                    /* tuple */[
                                                                                      undefined,
                                                                                      /* WelchTTest */-214208189
                                                                                    ],
                                                                                    /* :: */[
                                                                                      /* tuple */[
                                                                                        undefined,
                                                                                        /* WilcoxonSignedRankTest */775314475
                                                                                      ],
                                                                                      /* :: */[
                                                                                        /* tuple */[
                                                                                          undefined,
                                                                                          /* Bam */3303854
                                                                                        ],
                                                                                        /* :: */[
                                                                                          /* tuple */[
                                                                                            undefined,
                                                                                            /* Bcf */3304293
                                                                                          ],
                                                                                          /* :: */[
                                                                                            /* tuple */[
                                                                                              undefined,
                                                                                              /* Bed */3304737
                                                                                            ],
                                                                                            /* :: */[
                                                                                              /* tuple */[
                                                                                                undefined,
                                                                                                /* BedGraph */-809444755
                                                                                              ],
                                                                                              /* :: */[
                                                                                                /* tuple */[
                                                                                                  undefined,
                                                                                                  /* BigBed */484974721
                                                                                                ],
                                                                                                /* :: */[
                                                                                                  /* tuple */[
                                                                                                    undefined,
                                                                                                    /* BigWig */486019925
                                                                                                  ],
                                                                                                  /* :: */[
                                                                                                    /* tuple */[
                                                                                                      undefined,
                                                                                                      /* Biom */737157829
                                                                                                    ],
                                                                                                    /* :: */[
                                                                                                      /* tuple */[
                                                                                                        undefined,
                                                                                                        /* Cra */3357362
                                                                                                      ],
                                                                                                      /* :: */[
                                                                                                        /* tuple */[
                                                                                                          undefined,
                                                                                                          /* Cram */748691835
                                                                                                        ],
                                                                                                        /* :: */[
                                                                                                          /* tuple */[
                                                                                                            undefined,
                                                                                                            /* Fasta */243398181
                                                                                                          ],
                                                                                                          /* :: */[
                                                                                                            /* tuple */[
                                                                                                              undefined,
                                                                                                              /* Fastq */243398197
                                                                                                            ],
                                                                                                            /* :: */[
                                                                                                              /* tuple */[
                                                                                                                undefined,
                                                                                                                /* Gct */3552952
                                                                                                              ],
                                                                                                              /* :: */[
                                                                                                                /* tuple */[
                                                                                                                  undefined,
                                                                                                                  /* Gff3 */792454412
                                                                                                                ],
                                                                                                                /* :: */[
                                                                                                                  /* tuple */[
                                                                                                                    undefined,
                                                                                                                    /* Gff */3553607
                                                                                                                  ],
                                                                                                                  /* :: */[
                                                                                                                    /* tuple */[
                                                                                                                      undefined,
                                                                                                                      /* Gtf */3556729
                                                                                                                    ],
                                                                                                                    /* :: */[
                                                                                                                      /* tuple */[
                                                                                                                        undefined,
                                                                                                                        /* Newick */642196241
                                                                                                                      ],
                                                                                                                      /* :: */[
                                                                                                                        /* tuple */[
                                                                                                                          undefined,
                                                                                                                          /* Qza */4055352
                                                                                                                        ],
                                                                                                                        /* :: */[
                                                                                                                          /* tuple */[
                                                                                                                            undefined,
                                                                                                                            /* Sam */4149247
                                                                                                                          ],
                                                                                                                          /* :: */[
                                                                                                                            /* tuple */[
                                                                                                                              undefined,
                                                                                                                              /* Sra */4153026
                                                                                                                            ],
                                                                                                                            /* :: */[
                                                                                                                              /* tuple */[
                                                                                                                                undefined,
                                                                                                                                /* Tsv */4202999
                                                                                                                              ],
                                                                                                                              /* :: */[
                                                                                                                                /* tuple */[
                                                                                                                                  undefined,
                                                                                                                                  /* Vcf */4298873
                                                                                                                                ],
                                                                                                                                /* :: */[
                                                                                                                                  /* tuple */[
                                                                                                                                    undefined,
                                                                                                                                    /* Wig */4349941
                                                                                                                                  ],
                                                                                                                                  /* :: */[
                                                                                                                                    /* tuple */[
                                                                                                                                      undefined,
                                                                                                                                      /* ANNOVAR */-595336935
                                                                                                                                    ],
                                                                                                                                    /* :: */[
                                                                                                                                      /* tuple */[
                                                                                                                                        undefined,
                                                                                                                                        /* BedTools */-905618118
                                                                                                                                      ],
                                                                                                                                      /* :: */[
                                                                                                                                        /* tuple */[
                                                                                                                                          undefined,
                                                                                                                                          /* Bismark */862746297
                                                                                                                                        ],
                                                                                                                                        /* :: */[
                                                                                                                                          /* tuple */[
                                                                                                                                            undefined,
                                                                                                                                            /* Bowtie2 */-338593844
                                                                                                                                          ],
                                                                                                                                          /* :: */[
                                                                                                                                            /* tuple */[
                                                                                                                                              undefined,
                                                                                                                                              /* BPSC */735908318
                                                                                                                                            ],
                                                                                                                                            /* :: */[
                                                                                                                                              /* tuple */[
                                                                                                                                                undefined,
                                                                                                                                                /* BreakDancer */-193391650
                                                                                                                                              ],
                                                                                                                                              /* :: */[
                                                                                                                                                /* tuple */[
                                                                                                                                                  undefined,
                                                                                                                                                  /* BreaksSeq2 */195738823
                                                                                                                                                ],
                                                                                                                                                /* :: */[
                                                                                                                                                  /* tuple */[
                                                                                                                                                    undefined,
                                                                                                                                                    /* BSMAP */931767627
                                                                                                                                                  ],
                                                                                                                                                  /* :: */[
                                                                                                                                                    /* tuple */[
                                                                                                                                                      undefined,
                                                                                                                                                      /* BSSeeker2 */966293212
                                                                                                                                                    ],
                                                                                                                                                    /* :: */[
                                                                                                                                                      /* tuple */[
                                                                                                                                                        undefined,
                                                                                                                                                        /* BUSCO */954245580
                                                                                                                                                      ],
                                                                                                                                                      /* :: */[
                                                                                                                                                        /* tuple */[
                                                                                                                                                          undefined,
                                                                                                                                                          /* Bwa */3308748
                                                                                                                                                        ],
                                                                                                                                                        /* :: */[
                                                                                                                                                          /* tuple */[
                                                                                                                                                            undefined,
                                                                                                                                                            /* Centrifuge */-978741780
                                                                                                                                                          ],
                                                                                                                                                          /* :: */[
                                                                                                                                                            /* tuple */[
                                                                                                                                                              undefined,
                                                                                                                                                              /* ChromVAR */593715004
                                                                                                                                                            ],
                                                                                                                                                            /* :: */[
                                                                                                                                                              /* tuple */[
                                                                                                                                                                undefined,
                                                                                                                                                                /* CLARK */-968446159
                                                                                                                                                              ],
                                                                                                                                                              /* :: */[
                                                                                                                                                                /* tuple */[
                                                                                                                                                                  undefined,
                                                                                                                                                                  /* CNVnator */486079769
                                                                                                                                                                ],
                                                                                                                                                                /* :: */[
                                                                                                                                                                  /* tuple */[
                                                                                                                                                                    undefined,
                                                                                                                                                                    /* CONCOCT */236342655
                                                                                                                                                                  ],
                                                                                                                                                                  /* :: */[
                                                                                                                                                                    /* tuple */[
                                                                                                                                                                      undefined,
                                                                                                                                                                      /* Cufflinks */674407783
                                                                                                                                                                    ],
                                                                                                                                                                    /* :: */[
                                                                                                                                                                      /* tuple */[
                                                                                                                                                                        undefined,
                                                                                                                                                                        /* DeepTools */-262983633
                                                                                                                                                                      ],
                                                                                                                                                                      /* :: */[
                                                                                                                                                                        /* tuple */[
                                                                                                                                                                          undefined,
                                                                                                                                                                          /* Delly2 */527599674
                                                                                                                                                                        ],
                                                                                                                                                                        /* :: */[
                                                                                                                                                                          /* tuple */[
                                                                                                                                                                            undefined,
                                                                                                                                                                            /* DESeq2 */571755476
                                                                                                                                                                          ],
                                                                                                                                                                          /* :: */[
                                                                                                                                                                            /* tuple */[
                                                                                                                                                                              undefined,
                                                                                                                                                                              /* DETONATE */756135328
                                                                                                                                                                            ],
                                                                                                                                                                            /* :: */[
                                                                                                                                                                              /* tuple */[
                                                                                                                                                                                undefined,
                                                                                                                                                                                /* DFilter */-981655780
                                                                                                                                                                              ],
                                                                                                                                                                              /* :: */[
                                                                                                                                                                                /* tuple */[
                                                                                                                                                                                  undefined,
                                                                                                                                                                                  /* DIAMOND */-605144268
                                                                                                                                                                                ],
                                                                                                                                                                                /* :: */[
                                                                                                                                                                                  /* tuple */[
                                                                                                                                                                                    undefined,
                                                                                                                                                                                    /* EBSeq */-427462846
                                                                                                                                                                                  ],
                                                                                                                                                                                  /* :: */[
                                                                                                                                                                                    /* tuple */[
                                                                                                                                                                                      undefined,
                                                                                                                                                                                      /* EdgeR */-49423019
                                                                                                                                                                                    ],
                                                                                                                                                                                    /* :: */[
                                                                                                                                                                                      /* tuple */[
                                                                                                                                                                                        undefined,
                                                                                                                                                                                        /* EricScript */-905605358
                                                                                                                                                                                      ],
                                                                                                                                                                                      /* :: */[
                                                                                                                                                                                        /* tuple */[
                                                                                                                                                                                          undefined,
                                                                                                                                                                                          /* ExomeCNV */-806855433
                                                                                                                                                                                        ],
                                                                                                                                                                                        /* :: */[
                                                                                                                                                                                          /* tuple */[
                                                                                                                                                                                            undefined,
                                                                                                                                                                                            /* Freebayes */507218044
                                                                                                                                                                                          ],
                                                                                                                                                                                          /* :: */[
                                                                                                                                                                                            /* tuple */[
                                                                                                                                                                                              undefined,
                                                                                                                                                                                              /* GATK */790610449
                                                                                                                                                                                            ],
                                                                                                                                                                                            /* :: */[
                                                                                                                                                                                              /* tuple */[
                                                                                                                                                                                                undefined,
                                                                                                                                                                                                /* GEM */3546223
                                                                                                                                                                                              ],
                                                                                                                                                                                              /* :: */[
                                                                                                                                                                                                /* tuple */[
                                                                                                                                                                                                  undefined,
                                                                                                                                                                                                  /* GOTTCHA */872885588
                                                                                                                                                                                                ],
                                                                                                                                                                                                /* :: */[
                                                                                                                                                                                                  /* tuple */[
                                                                                                                                                                                                    undefined,
                                                                                                                                                                                                    /* GSEA */791502216
                                                                                                                                                                                                  ],
                                                                                                                                                                                                  /* :: */[
                                                                                                                                                                                                    /* tuple */[
                                                                                                                                                                                                      undefined,
                                                                                                                                                                                                      /* HISAT2 */151037165
                                                                                                                                                                                                    ],
                                                                                                                                                                                                    /* :: */[
                                                                                                                                                                                                      /* tuple */[
                                                                                                                                                                                                        undefined,
                                                                                                                                                                                                        /* HOMER */692865363
                                                                                                                                                                                                      ],
                                                                                                                                                                                                      /* :: */[
                                                                                                                                                                                                        /* tuple */[
                                                                                                                                                                                                          undefined,
                                                                                                                                                                                                          /* HoneyBADGER */-119435830
                                                                                                                                                                                                        ],
                                                                                                                                                                                                        /* :: */[
                                                                                                                                                                                                          /* tuple */[
                                                                                                                                                                                                            undefined,
                                                                                                                                                                                                            /* HUMAnN2 */797202193
                                                                                                                                                                                                          ],
                                                                                                                                                                                                          /* :: */[
                                                                                                                                                                                                            /* tuple */[
                                                                                                                                                                                                              undefined,
                                                                                                                                                                                                              /* Kaiju */-277136002
                                                                                                                                                                                                            ],
                                                                                                                                                                                                            /* :: */[
                                                                                                                                                                                                              /* tuple */[
                                                                                                                                                                                                                undefined,
                                                                                                                                                                                                                /* Kallisto */653925531
                                                                                                                                                                                                              ],
                                                                                                                                                                                                              /* :: */[
                                                                                                                                                                                                                /* tuple */[
                                                                                                                                                                                                                  undefined,
                                                                                                                                                                                                                  /* Kraken2 */-463599144
                                                                                                                                                                                                                ],
                                                                                                                                                                                                                /* :: */[
                                                                                                                                                                                                                  /* tuple */[
                                                                                                                                                                                                                    undefined,
                                                                                                                                                                                                                    /* LimmaVoom */-235857605
                                                                                                                                                                                                                  ],
                                                                                                                                                                                                                  /* :: */[
                                                                                                                                                                                                                    /* tuple */[
                                                                                                                                                                                                                      undefined,
                                                                                                                                                                                                                      /* LoFreq */-80373509
                                                                                                                                                                                                                    ],
                                                                                                                                                                                                                    /* :: */[
                                                                                                                                                                                                                      /* tuple */[
                                                                                                                                                                                                                        undefined,
                                                                                                                                                                                                                        /* Lumpy */270345389
                                                                                                                                                                                                                      ],
                                                                                                                                                                                                                      /* :: */[
                                                                                                                                                                                                                        /* tuple */[
                                                                                                                                                                                                                          undefined,
                                                                                                                                                                                                                          /* MACS2 */17082542
                                                                                                                                                                                                                        ],
                                                                                                                                                                                                                        /* :: */[
                                                                                                                                                                                                                          /* tuple */[
                                                                                                                                                                                                                            undefined,
                                                                                                                                                                                                                            /* Manta */374094439
                                                                                                                                                                                                                          ],
                                                                                                                                                                                                                          /* :: */[
                                                                                                                                                                                                                            /* tuple */[
                                                                                                                                                                                                                              undefined,
                                                                                                                                                                                                                              /* MAST */857147637
                                                                                                                                                                                                                            ],
                                                                                                                                                                                                                            /* :: */[
                                                                                                                                                                                                                              /* tuple */[
                                                                                                                                                                                                                                undefined,
                                                                                                                                                                                                                                /* MaxBin */-220391261
                                                                                                                                                                                                                              ],
                                                                                                                                                                                                                              /* :: */[
                                                                                                                                                                                                                                /* tuple */[
                                                                                                                                                                                                                                  undefined,
                                                                                                                                                                                                                                  /* MEGAHIT */624266753
                                                                                                                                                                                                                                ],
                                                                                                                                                                                                                                /* :: */[
                                                                                                                                                                                                                                  /* tuple */[
                                                                                                                                                                                                                                    undefined,
                                                                                                                                                                                                                                    /* MEGAN */61635740
                                                                                                                                                                                                                                  ],
                                                                                                                                                                                                                                  /* :: */[
                                                                                                                                                                                                                                    /* tuple */[
                                                                                                                                                                                                                                      undefined,
                                                                                                                                                                                                                                      /* MEME */857345200
                                                                                                                                                                                                                                    ],
                                                                                                                                                                                                                                    /* :: */[
                                                                                                                                                                                                                                      /* tuple */[
                                                                                                                                                                                                                                        undefined,
                                                                                                                                                                                                                                        /* MetaBAT */-288656400
                                                                                                                                                                                                                                      ],
                                                                                                                                                                                                                                      /* :: */[
                                                                                                                                                                                                                                        /* tuple */[
                                                                                                                                                                                                                                          undefined,
                                                                                                                                                                                                                                          /* MetaPhlAn2 */-504356778
                                                                                                                                                                                                                                        ],
                                                                                                                                                                                                                                        /* :: */[
                                                                                                                                                                                                                                          /* tuple */[
                                                                                                                                                                                                                                            undefined,
                                                                                                                                                                                                                                            /* MetaSPAdes */655533043
                                                                                                                                                                                                                                          ],
                                                                                                                                                                                                                                          /* :: */[
                                                                                                                                                                                                                                            /* tuple */[
                                                                                                                                                                                                                                              undefined,
                                                                                                                                                                                                                                              /* MetaVelvet */235453677
                                                                                                                                                                                                                                            ],
                                                                                                                                                                                                                                            /* :: */[
                                                                                                                                                                                                                                              /* tuple */[
                                                                                                                                                                                                                                                undefined,
                                                                                                                                                                                                                                                /* MOCAT2 */-224558114
                                                                                                                                                                                                                                              ],
                                                                                                                                                                                                                                              /* :: */[
                                                                                                                                                                                                                                                /* tuple */[
                                                                                                                                                                                                                                                  undefined,
                                                                                                                                                                                                                                                  /* Monocle */55558457
                                                                                                                                                                                                                                                ],
                                                                                                                                                                                                                                                /* :: */[
                                                                                                                                                                                                                                                  /* tuple */[
                                                                                                                                                                                                                                                    undefined,
                                                                                                                                                                                                                                                    /* Monovar */56500868
                                                                                                                                                                                                                                                  ],
                                                                                                                                                                                                                                                  /* :: */[
                                                                                                                                                                                                                                                    /* tuple */[
                                                                                                                                                                                                                                                      undefined,
                                                                                                                                                                                                                                                      /* Mothur */-967341
                                                                                                                                                                                                                                                    ],
                                                                                                                                                                                                                                                    /* :: */[
                                                                                                                                                                                                                                                      /* tuple */[
                                                                                                                                                                                                                                                        undefined,
                                                                                                                                                                                                                                                        /* PeakRanger */-901802060
                                                                                                                                                                                                                                                      ],
                                                                                                                                                                                                                                                      /* :: */[
                                                                                                                                                                                                                                                        /* tuple */[
                                                                                                                                                                                                                                                          undefined,
                                                                                                                                                                                                                                                          /* Picard */861529193
                                                                                                                                                                                                                                                        ],
                                                                                                                                                                                                                                                        /* :: */[
                                                                                                                                                                                                                                                          /* tuple */[
                                                                                                                                                                                                                                                            undefined,
                                                                                                                                                                                                                                                            /* Platypus */-834977404
                                                                                                                                                                                                                                                          ],
                                                                                                                                                                                                                                                          /* :: */[
                                                                                                                                                                                                                                                            /* tuple */[
                                                                                                                                                                                                                                                              undefined,
                                                                                                                                                                                                                                                              /* QIIME2 */463720457
                                                                                                                                                                                                                                                            ],
                                                                                                                                                                                                                                                            /* :: */[
                                                                                                                                                                                                                                                              /* tuple */[
                                                                                                                                                                                                                                                                undefined,
                                                                                                                                                                                                                                                                /* RayMeta */-114511441
                                                                                                                                                                                                                                                              ],
                                                                                                                                                                                                                                                              /* :: */[
                                                                                                                                                                                                                                                                /* tuple */[
                                                                                                                                                                                                                                                                  undefined,
                                                                                                                                                                                                                                                                  /* RSEM */913487465
                                                                                                                                                                                                                                                                ],
                                                                                                                                                                                                                                                                /* :: */[
                                                                                                                                                                                                                                                                  /* tuple */[
                                                                                                                                                                                                                                                                    undefined,
                                                                                                                                                                                                                                                                    /* SAMtools */-134774340
                                                                                                                                                                                                                                                                  ],
                                                                                                                                                                                                                                                                  /* :: */[
                                                                                                                                                                                                                                                                    /* tuple */[
                                                                                                                                                                                                                                                                      undefined,
                                                                                                                                                                                                                                                                      /* Scater */-255823664
                                                                                                                                                                                                                                                                    ],
                                                                                                                                                                                                                                                                    /* :: */[
                                                                                                                                                                                                                                                                      /* tuple */[
                                                                                                                                                                                                                                                                        undefined,
                                                                                                                                                                                                                                                                        /* SCDE */923781137
                                                                                                                                                                                                                                                                      ],
                                                                                                                                                                                                                                                                      /* :: */[
                                                                                                                                                                                                                                                                        /* tuple */[
                                                                                                                                                                                                                                                                          undefined,
                                                                                                                                                                                                                                                                          /* Seurat */616846914
                                                                                                                                                                                                                                                                        ],
                                                                                                                                                                                                                                                                        /* :: */[
                                                                                                                                                                                                                                                                          /* tuple */[
                                                                                                                                                                                                                                                                            undefined,
                                                                                                                                                                                                                                                                            /* SICER */-88748902
                                                                                                                                                                                                                                                                          ],
                                                                                                                                                                                                                                                                          /* :: */[
                                                                                                                                                                                                                                                                            /* tuple */[
                                                                                                                                                                                                                                                                              undefined,
                                                                                                                                                                                                                                                                              /* Sourmash */-957409406
                                                                                                                                                                                                                                                                            ],
                                                                                                                                                                                                                                                                            /* :: */[
                                                                                                                                                                                                                                                                              /* tuple */[
                                                                                                                                                                                                                                                                                undefined,
                                                                                                                                                                                                                                                                                /* SnpEff */-806396784
                                                                                                                                                                                                                                                                              ],
                                                                                                                                                                                                                                                                              /* :: */[
                                                                                                                                                                                                                                                                                /* tuple */[
                                                                                                                                                                                                                                                                                  undefined,
                                                                                                                                                                                                                                                                                  /* SnpSift */717546841
                                                                                                                                                                                                                                                                                ],
                                                                                                                                                                                                                                                                                /* :: */[
                                                                                                                                                                                                                                                                                  /* tuple */[
                                                                                                                                                                                                                                                                                    undefined,
                                                                                                                                                                                                                                                                                    /* SNVer */-32349048
                                                                                                                                                                                                                                                                                  ],
                                                                                                                                                                                                                                                                                  /* :: */[
                                                                                                                                                                                                                                                                                    /* tuple */[
                                                                                                                                                                                                                                                                                      undefined,
                                                                                                                                                                                                                                                                                      /* Stampy */982189878
                                                                                                                                                                                                                                                                                    ],
                                                                                                                                                                                                                                                                                    /* :: */[
                                                                                                                                                                                                                                                                                      /* tuple */[
                                                                                                                                                                                                                                                                                        undefined,
                                                                                                                                                                                                                                                                                        /* STAR */924625874
                                                                                                                                                                                                                                                                                      ],
                                                                                                                                                                                                                                                                                      /* :: */[
                                                                                                                                                                                                                                                                                        /* tuple */[
                                                                                                                                                                                                                                                                                          undefined,
                                                                                                                                                                                                                                                                                          /* Taxonomer */-490342859
                                                                                                                                                                                                                                                                                        ],
                                                                                                                                                                                                                                                                                        /* :: */[
                                                                                                                                                                                                                                                                                          /* tuple */[
                                                                                                                                                                                                                                                                                            undefined,
                                                                                                                                                                                                                                                                                            /* TopHat2 */862607756
                                                                                                                                                                                                                                                                                          ],
                                                                                                                                                                                                                                                                                          /* :: */[
                                                                                                                                                                                                                                                                                            /* tuple */[
                                                                                                                                                                                                                                                                                              undefined,
                                                                                                                                                                                                                                                                                              /* Trinity */-137196277
                                                                                                                                                                                                                                                                                            ],
                                                                                                                                                                                                                                                                                            /* :: */[
                                                                                                                                                                                                                                                                                              /* tuple */[
                                                                                                                                                                                                                                                                                                undefined,
                                                                                                                                                                                                                                                                                                /* TransDecoder */-544416676
                                                                                                                                                                                                                                                                                              ],
                                                                                                                                                                                                                                                                                              /* :: */[
                                                                                                                                                                                                                                                                                                /* tuple */[
                                                                                                                                                                                                                                                                                                  undefined,
                                                                                                                                                                                                                                                                                                  /* Trinotate */-273821654
                                                                                                                                                                                                                                                                                                ],
                                                                                                                                                                                                                                                                                                /* :: */[
                                                                                                                                                                                                                                                                                                  /* tuple */[
                                                                                                                                                                                                                                                                                                    undefined,
                                                                                                                                                                                                                                                                                                    /* VarAFT */135999336
                                                                                                                                                                                                                                                                                                  ],
                                                                                                                                                                                                                                                                                                  /* :: */[
                                                                                                                                                                                                                                                                                                    /* tuple */[
                                                                                                                                                                                                                                                                                                      undefined,
                                                                                                                                                                                                                                                                                                      /* VarDict */298093533
                                                                                                                                                                                                                                                                                                    ],
                                                                                                                                                                                                                                                                                                    /* :: */[
                                                                                                                                                                                                                                                                                                      /* tuple */[
                                                                                                                                                                                                                                                                                                        undefined,
                                                                                                                                                                                                                                                                                                        /* VarScan */464138212
                                                                                                                                                                                                                                                                                                      ],
                                                                                                                                                                                                                                                                                                      /* :: */[
                                                                                                                                                                                                                                                                                                        /* tuple */[
                                                                                                                                                                                                                                                                                                          undefined,
                                                                                                                                                                                                                                                                                                          /* VEP */4292161
                                                                                                                                                                                                                                                                                                        ],
                                                                                                                                                                                                                                                                                                        /* :: */[
                                                                                                                                                                                                                                                                                                          /* tuple */[
                                                                                                                                                                                                                                                                                                            undefined,
                                                                                                                                                                                                                                                                                                            /* VCFtools */708460290
                                                                                                                                                                                                                                                                                                          ],
                                                                                                                                                                                                                                                                                                          /* :: */[
                                                                                                                                                                                                                                                                                                            /* tuple */[
                                                                                                                                                                                                                                                                                                              undefined,
                                                                                                                                                                                                                                                                                                              /* VizBin */681208128
                                                                                                                                                                                                                                                                                                            ],
                                                                                                                                                                                                                                                                                                            /* :: */[
                                                                                                                                                                                                                                                                                                              /* tuple */[
                                                                                                                                                                                                                                                                                                                undefined,
                                                                                                                                                                                                                                                                                                                /* WCGNA */-1000609874
                                                                                                                                                                                                                                                                                                              ],
                                                                                                                                                                                                                                                                                                              /* [] */0
                                                                                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                ]
                                                                                                                                                                                                              ]
                                                                                                                                                                                                            ]
                                                                                                                                                                                                          ]
                                                                                                                                                                                                        ]
                                                                                                                                                                                                      ]
                                                                                                                                                                                                    ]
                                                                                                                                                                                                  ]
                                                                                                                                                                                                ]
                                                                                                                                                                                              ]
                                                                                                                                                                                            ]
                                                                                                                                                                                          ]
                                                                                                                                                                                        ]
                                                                                                                                                                                      ]
                                                                                                                                                                                    ]
                                                                                                                                                                                  ]
                                                                                                                                                                                ]
                                                                                                                                                                              ]
                                                                                                                                                                            ]
                                                                                                                                                                          ]
                                                                                                                                                                        ]
                                                                                                                                                                      ]
                                                                                                                                                                    ]
                                                                                                                                                                  ]
                                                                                                                                                                ]
                                                                                                                                                              ]
                                                                                                                                                            ]
                                                                                                                                                          ]
                                                                                                                                                        ]
                                                                                                                                                      ]
                                                                                                                                                    ]
                                                                                                                                                  ]
                                                                                                                                                ]
                                                                                                                                              ]
                                                                                                                                            ]
                                                                                                                                          ]
                                                                                                                                        ]
                                                                                                                                      ]
                                                                                                                                    ]
                                                                                                                                  ]
                                                                                                                                ]
                                                                                                                              ]
                                                                                                                            ]
                                                                                                                          ]
                                                                                                                        ]
                                                                                                                      ]
                                                                                                                    ]
                                                                                                                  ]
                                                                                                                ]
                                                                                                              ]
                                                                                                            ]
                                                                                                          ]
                                                                                                        ]
                                                                                                      ]
                                                                                                    ]
                                                                                                  ]
                                                                                                ]
                                                                                              ]
                                                                                            ]
                                                                                          ]
                                                                                        ]
                                                                                      ]
                                                                                    ]
                                                                                  ]
                                                                                ]
                                                                              ]
                                                                            ]
                                                                          ]
                                                                        ]
                                                                      ]
                                                                    ]
                                                                  ]
                                                                ]
                                                              ]
                                                            ]
                                                          ]
                                                        ]
                                                      ]
                                                    ]
                                                  ]
                                                ]
                                              ]
                                            ]
                                          ]
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]
  ]
];

var varMap = /* :: */[
  varMap_000,
  varMap_001
];

function toVar(viz) {
  var x = Curry._2(Import.List.getBy, varMap, (function (param) {
          return viz === param[1];
        }));
  if (x !== undefined) {
    var match = x[0];
    if (match !== undefined) {
      return match;
    } else {
      return ;
    }
  }
  
}

function ofVar($$var) {
  return Belt_Option.map(Curry._2(Import.List.getBy, varMap, (function (param) {
                    var var$prime = param[0];
                    if (var$prime !== undefined) {
                      return Curry._2(Lang$SolvuuApi.Var.equal, var$prime, $$var);
                    } else {
                      return false;
                    }
                  })), (function (prim) {
                return prim[1];
              }));
}

function ofTerm(x) {
  if (typeof x === "number") {
    return ;
  } else {
    var variant = x[0];
    if (variant !== 3257473) {
      if (variant !== 4298439) {
        return ;
      } else {
        return ofVar(x[1]);
      }
    } else {
      var match = x[1][0];
      if (typeof match === "number" || match[0] !== 4298439) {
        return ;
      } else {
        return ofVar(match[1]);
      }
    }
  }
}

function firstMatch(terms, x) {
  var match = toVar(x);
  if (match !== undefined) {
    var v = match;
    return Curry._2(Import.List.getBy, terms, (function (term) {
                  if (typeof term === "number") {
                    return false;
                  } else {
                    var variant = term[0];
                    if (variant !== 3257473) {
                      if (variant !== 4298439) {
                        return false;
                      } else {
                        return Curry._2(Lang$SolvuuApi.Var.equal, v, term[1]);
                      }
                    } else {
                      var match = term[1][0];
                      if (typeof match === "number" || match[0] !== 4298439) {
                        return false;
                      } else {
                        return Curry._2(Lang$SolvuuApi.Var.equal, v, match[1]);
                      }
                    }
                  }
                }));
  }
  
}

function id(x) {
  if (x >= 117882500) {
    if (x >= 672270517) {
      if (x >= 799219443) {
        if (x >= 923781137) {
          if (x >= 966293212) {
            if (x !== 969511896) {
              if (x >= 982189878) {
                if (x >= 988997452) {
                  return "Heatmap";
                } else {
                  return "Stampy";
                }
              } else if (x >= 977193341) {
                return "kolmogorovSmirnovTest";
              } else {
                return "BS-Seeker2";
              }
            } else {
              return "filter";
            }
          } else if (x !== 924625874) {
            if (x >= 935668598) {
              if (x >= 954245580) {
                return "BUSCO";
              } else {
                return "tSNE";
              }
            } else if (x >= 931767627) {
              return "BSMAP";
            } else {
              return "SCDE";
            }
          } else {
            return "STAR";
          }
        } else if (x >= 862607756) {
          if (x !== 862746297) {
            if (x >= 907398765) {
              if (x >= 913487465) {
                return "RSEM";
              } else {
                return "anova";
              }
            } else if (x >= 872885588) {
              return "GOTTCHA";
            } else {
              return "TopHat2";
            }
          } else {
            return "Bismark";
          }
        } else if (x >= 857345200) {
          if (x >= 861529193) {
            return "Picard";
          } else {
            return "MEME";
          }
        } else if (x >= 857147637) {
          return "MAST";
        } else {
          return "phylogeneticTree";
        }
      } else if (x >= 739741055) {
        if (x >= 780238890) {
          if (x !== 790610449) {
            if (x >= 792454412) {
              if (x >= 797202193) {
                return "HUMAnN2";
              } else {
                return "gff3";
              }
            } else if (x >= 791502216) {
              return "GSEA";
            } else {
              return "lineChart";
            }
          } else {
            return "GATK";
          }
        } else if (x !== 748691835) {
          if (x >= 758139700) {
            if (x >= 775314475) {
              return "wilcoxonSignedRankTest";
            } else {
              return "sankeyDiagram";
            }
          } else if (x >= 756135328) {
            return "";
          } else {
            return "quantileQuantilePlot";
          }
        } else {
          return "cram";
        }
      } else if (x >= 692865363) {
        if (x !== 708460290) {
          if (x >= 735908318) {
            if (x >= 737157829) {
              return "biom";
            } else {
              return "BPSC";
            }
          } else if (x >= 717546841) {
            return "SnpSift";
          } else {
            return "HOMER";
          }
        } else {
          return "VCFtools";
        }
      } else if (x >= 681208128) {
        if (x >= 687725842) {
          return "pie";
        } else {
          return "VizBin";
        }
      } else if (x >= 674407783) {
        return "Cufflinks";
      } else {
        return "mannWhitneyUTest";
      }
    } else if (x >= 374094439) {
      if (x >= 527599674) {
        if (x >= 616846914) {
          if (x !== 624266753) {
            if (x >= 653925531) {
              if (x >= 655533043) {
                return "MetaSPAdes";
              } else {
                return "Kallisto";
              }
            } else if (x >= 642196241) {
              return "newick";
            } else {
              return "Seurat";
            }
          } else {
            return "MEGAHIT";
          }
        } else if (x !== 571755476) {
          if (x >= 588923761) {
            if (x >= 593715004) {
              return "chromVAR";
            } else {
              return "hierarchicalClustering";
            }
          } else if (x >= 586017299) {
            return "volcanoPlot";
          } else {
            return "Delly2";
          }
        } else {
          return "DESeq2";
        }
      } else if (x >= 464204031) {
        if (x !== 484974721) {
          if (x >= 486079769) {
            if (x >= 507218044) {
              return "Freebayes";
            } else {
              return "CNVnator";
            }
          } else if (x >= 486019925) {
            return "bigWig";
          } else {
            return "scatterMatrix";
          }
        } else {
          return "bigBed";
        }
      } else if (x >= 463720457) {
        if (x >= 464138212) {
          return "VarScan";
        } else {
          return "QIIME2";
        }
      } else if (x >= 377205848) {
        return "kruskallWallisRankSumTest";
      } else {
        return "Manta";
      }
    } else if (x >= 265105709) {
      if (x >= 300511203) {
        if (x !== 316537906) {
          if (x >= 360427206) {
            if (x >= 366103461) {
              return "sequenceMotifs";
            } else {
              return "tTest";
            }
          } else if (x >= 359953472) {
            return "circularPlot";
          } else {
            return "kronaChart";
          }
        } else {
          return "pathwayMap";
        }
      } else if (x !== 266627423) {
        if (x >= 288973297) {
          if (x >= 298093533) {
            return "VarDict";
          } else {
            return "areaChart";
          }
        } else if (x >= 270345389) {
          return "Lumpy";
        } else {
          return "hexbinPlot";
        }
      } else {
        return "scatterPlot";
      }
    } else if (x >= 195738823) {
      if (x !== 235453677) {
        if (x >= 243398181) {
          if (x >= 243398197) {
            return "fastq";
          } else {
            return "fasta";
          }
        } else if (x >= 236342655) {
          return "CONCOCT";
        } else {
          return "BreakSeq2";
        }
      } else {
        return "MetaVelvet";
      }
    } else if (x >= 135999336) {
      if (x >= 151037165) {
        return "HISAT2";
      } else {
        return "VarAFT";
      }
    } else if (x >= 118811026) {
      return "bubbleChart";
    } else {
      return "histogram";
    }
  } else if (x >= -235857605) {
    if (x >= 3357362) {
      if (x >= 4202999) {
        if (x >= 55558457) {
          if (x !== 56152651) {
            if (x >= 61635740) {
              if (x >= 83361156) {
                return "vennDiagram";
              } else {
                return "MEGAN";
              }
            } else if (x >= 56500868) {
              return "Monovar";
            } else {
              return "Monocle";
            }
          } else {
            return "kMeansClustering";
          }
        } else if (x !== 4292161) {
          if (x >= 4349941) {
            if (x >= 17082542) {
              return "MACS2";
            } else {
              return "wig";
            }
          } else if (x >= 4298873) {
            return "vcf";
          } else {
            return "tsv";
          }
        } else {
          return "VEP";
        }
      } else if (x >= 3553607) {
        if (x !== 3556729) {
          if (x >= 4149247) {
            if (x >= 4153026) {
              return "sra";
            } else {
              return "sam";
            }
          } else if (x >= 4055352) {
            return "qza";
          } else {
            return "gff";
          }
        } else {
          return "gtf";
        }
      } else if (x >= 3546223) {
        if (x >= 3552952) {
          return "gct";
        } else {
          return "GEM";
        }
      } else if (x >= 3357606) {
        return "csv";
      } else {
        return "cra";
      }
    } else if (x >= -88748902) {
      if (x >= -967341) {
        if (x !== 3303854) {
          if (x >= 3304737) {
            if (x >= 3308748) {
              return "bwa";
            } else {
              return "bed";
            }
          } else if (x >= 3304293) {
            return "bcf";
          } else {
            return "Mothur";
          }
        } else {
          return "bam";
        }
      } else if (x !== -80373509) {
        if (x >= -49423019) {
          if (x >= -32349048) {
            return "SNVer";
          } else {
            return "EdgeR";
          }
        } else if (x >= -55180330) {
          return "violinPlot";
        } else {
          return "SICER";
        }
      } else {
        return "LoFreq";
      }
    } else if (x >= -193391650) {
      if (x !== -137196277) {
        if (x >= -119435830) {
          if (x >= -114511441) {
            return "RayMeta";
          } else {
            return "HoneyBADGER";
          }
        } else if (x >= -134774340) {
          return "SAMtools";
        } else {
          return "BreakDancer";
        }
      } else {
        return "Trinity";
      }
    } else if (x >= -220391261) {
      if (x >= -214208189) {
        return "welchTTest";
      } else {
        return "MaxBin";
      }
    } else if (x >= -224558114) {
      return "MOCAT2";
    } else {
      return "LimmaVoom";
    }
  } else if (x >= -504356778) {
    if (x >= -318813910) {
      if (x >= -273821654) {
        if (x !== -265814703) {
          if (x >= -262983633) {
            if (x >= -255823664) {
              return "Scater";
            } else {
              return "deepTools";
            }
          } else if (x >= -265545067) {
            return "Cytoscape";
          } else {
            return "Trinotate";
          }
        } else {
          return "genomeBrowser";
        }
      } else if (x !== -313597417) {
        if (x >= -288656400) {
          if (x >= -277136002) {
            return "Kaiju";
          } else {
            return "MetaBAT";
          }
        } else if (x >= -298608085) {
          return "barChart";
        } else {
          return "dotPlot";
        }
      } else {
        return "fisherTest";
      }
    } else if (x >= -449223643) {
      if (x !== -427462846) {
        if (x >= -338593844) {
          if (x >= -322318508) {
            return "parallelCoordinates";
          } else {
            return "bowtie2";
          }
        } else if (x >= -412176980) {
          return "boxPlot";
        } else {
          return "networkDiagram";
        }
      } else {
        return "EBSeq";
      }
    } else if (x >= -490342859) {
      if (x >= -463599144) {
        return "Kraken2";
      } else {
        return "Taxonomer";
      }
    } else if (x >= -496309491) {
      return "principalCoordinates";
    } else {
      return "MetaPhlAn2";
    }
  } else if (x >= -834977404) {
    if (x >= -605144268) {
      if (x !== -595336935) {
        if (x >= -533179959) {
          if (x >= -508885141) {
            return "Dendrogram";
          } else {
            return "summaryStatistics";
          }
        } else if (x >= -544416676) {
          return "TransDecoder";
        } else {
          return "DIAMOND";
        }
      } else {
        return "ANNOVAR";
      }
    } else if (x !== -809444755) {
      if (x >= -806396784) {
        if (x >= -642279940) {
          return "donutChart";
        } else {
          return "SnpEff";
        }
      } else if (x >= -806855433) {
        return "ExomeCNV";
      } else {
        return "Platypus";
      }
    } else {
      return "bedGraph";
    }
  } else if (x >= -957409406) {
    if (x !== -905618118) {
      if (x >= -901802060) {
        if (x >= -844938357) {
          return "kernelDensity";
        } else {
          return "PeakRanger";
        }
      } else if (x >= -905605358) {
        return "EricScript";
      } else {
        return "Sourmash";
      }
    } else {
      return "bedtools";
    }
  } else if (x >= -978741780) {
    if (x >= -968446159) {
      return "CLARK";
    } else {
      return "Centrifuge";
    }
  } else if (x >= -981655780) {
    return "DFilter";
  } else {
    return "WGCNA";
  }
}

function toStringHum(x) {
  if (x >= 300511204) {
    if (x >= 687725843) {
      if (x >= 857345200) {
        if (x !== 935668598) {
          if (x !== 966293212) {
            if (x >= 857345201) {
              return id(x);
            } else {
              return "MEME Suite";
            }
          } else {
            return "BS-Seeker2";
          }
        } else {
          return "t-SNE plot";
        }
      } else if (x !== 758139700) {
        if (x !== 799219443) {
          return id(x);
        } else {
          return "Phylogenetic tree";
        }
      } else {
        return "Sankey diagram";
      }
    } else if (x >= 366103461) {
      if (x !== 586017299) {
        if (x >= 366103462) {
          if (x >= 687725842) {
            return "Pie chart";
          } else {
            return id(x);
          }
        } else {
          return "Sequence motif";
        }
      } else {
        return "Volcano plot";
      }
    } else if (x !== 316537906) {
      if (x !== 359953472) {
        return id(x);
      } else {
        return "Circular plot";
      }
    } else {
      return "Pathway map";
    }
  } else if (x >= -298608084) {
    if (x >= 83361156) {
      if (x !== 118811026) {
        if (x >= 83361157) {
          if (x >= 300511203) {
            return "Krona chart";
          } else {
            return id(x);
          }
        } else {
          return "Venn diagram";
        }
      } else {
        return "Bubble chart";
      }
    } else if (x !== -265814703) {
      if (x !== -235857605) {
        return id(x);
      } else {
        return "limma/voom";
      }
    } else {
      return "Genome browser";
    }
  } else if (x >= -449223643) {
    if (x !== -322318508) {
      if (x >= -449223642) {
        if (x >= -298608085) {
          return "Bar chart";
        } else {
          return id(x);
        }
      } else {
        return "Network";
      }
    } else {
      return "Parallel plot";
    }
  } else if (x !== -642279940) {
    if (x !== -496309491) {
      return id(x);
    } else {
      return "Principal Component Analysis (PCA)";
    }
  } else {
    return "Donut partition chart";
  }
}

function keywords(x) {
  if (x >= 270345389) {
    if (x >= 687725842) {
      if (x >= 857147637) {
        if (x >= 954245580) {
          if (x !== 969511896) {
            if (x !== 977193341) {
              if (x >= 988997452) {
                return "heatmap gene expression hierarchical kmeans k-means clustering";
              } else {
                return toStringHum(x);
              }
            } else {
              return "kolmogorov smirnov test";
            }
          } else {
            return "filter";
          }
        } else if (x !== 907398765) {
          if (x >= 935668598) {
            return "t-sne t-distributed stochastic nearest neighbor";
          } else {
            return toStringHum(x);
          }
        } else {
          return "anova";
        }
      } else if (x >= 758139700) {
        if (x !== 775314475) {
          if (x >= 790610449) {
            if (x >= 799219443) {
              return "phylogentic tree";
            } else {
              return toStringHum(x);
            }
          } else if (x >= 780238890) {
            return "line lines chart series trend";
          } else {
            return "sankey diagram";
          }
        } else {
          return "wilcoxon signed rank test";
        }
      } else if (x !== 739741055) {
        if (x >= 687725843) {
          return toStringHum(x);
        } else {
          return "pie chart";
        }
      } else {
        return "q-q plot qq plot quantile quantile plot";
      }
    } else if (x < 377205848) {
      if (x >= 316537906) {
        if (x !== 359953472) {
          if (x >= 366103461) {
            if (x >= 374094439) {
              return toStringHum(x);
            } else {
              return "sequence motifs";
            }
          } else if (x >= 360427206) {
            return "t-test statistics";
          } else {
            return "pathway map";
          }
        } else {
          return "circular plot";
        }
      } else if (x !== 288973297) {
        if (x >= 300511203) {
          return "krona chart microbiome";
        } else {
          return toStringHum(x);
        }
      } else {
        return "area chart";
      }
    } else if (x >= 586017299) {
      if (x >= 593715004) {
        if (x !== 672270517) {
          return toStringHum(x);
        } else {
          return "mann whitney u test";
        }
      } else if (x >= 588923761) {
        return "heatmap gene expression hierarchical clustering";
      } else {
        return "volcano plot gene expression";
      }
    } else if (x !== 464204031) {
      if (x >= 377205849) {
        return toStringHum(x);
      } else {
        return "kruskall wallis rank sum test";
      }
    } else {
      return "2d scatter matrix";
    }
  } else if (x >= -288656400) {
    if (x >= 3546223) {
      if (x >= 117882500) {
        if (x !== 118811026) {
          if (x >= 265105709) {
            if (x >= 266627423) {
              return "2d scatter plot";
            } else {
              return "hexbin plot scatter";
            }
          } else if (x >= 117882501) {
            return toStringHum(x);
          } else {
            return "histogram";
          }
        } else {
          return "bubble chart";
        }
      } else if (x !== 56152651) {
        if (x >= 83361156) {
          return "venn diagram";
        } else {
          return toStringHum(x);
        }
      } else {
        return "heatmap gene expression kmeans k-means clustering";
      }
    } else if (x >= -214208189) {
      if (x !== -55180330) {
        if (x >= -214208188) {
          if (x >= 3357606) {
            return "csv";
          } else {
            return toStringHum(x);
          }
        } else {
          return "welch t-test";
        }
      } else {
        return "violin plot";
      }
    } else if (x !== -265814703) {
      if (x !== -265545067) {
        return toStringHum(x);
      } else {
        return "cytoscape network";
      }
    } else {
      return "genome browser";
    }
  } else if (x >= -490342859) {
    if (x >= -338593844) {
      if (x !== -322318508) {
        if (x >= -313597417) {
          if (x >= -298608085) {
            return "bar bars chart series";
          } else {
            return "fisher test";
          }
        } else if (x >= -318813910) {
          return "dot plot";
        } else {
          return toStringHum(x);
        }
      } else {
        return "parallel coordinates gene expression";
      }
    } else if (x !== -449223643) {
      if (x >= -412176980) {
        return "tukey box plot";
      } else {
        return toStringHum(x);
      }
    } else {
      return "network diagram";
    }
  } else if (x >= -605144268) {
    if (x !== -533179959) {
      if (x !== -508885141) {
        if (x >= -496309491) {
          return "principal coordinates pca pcoa mds";
        } else {
          return toStringHum(x);
        }
      } else {
        return "dendrogram clustering";
      }
    } else {
      return "summary statistics descriptive statistics mean average median standard deviation";
    }
  } else if (x !== -844938357) {
    if (x >= -642279940) {
      return "donut chart pie chart";
    } else {
      return toStringHum(x);
    }
  } else {
    return "kernel density plot";
  }
}

function add(t, tids) {
  var __x = V4().split("-");
  var id = Belt_Array.getExn(__x, 0);
  return Pervasives.$at(tids, /* :: */[
              {
                id: id,
                t: t
              },
              /* [] */0
            ]);
}

function update(id, t, tids) {
  return Curry._2(Import.List.map, tids, (function (v) {
                var match = $$String.equal(id, v.id);
                if (match) {
                  return {
                          id: v.id,
                          t: t
                        };
                } else {
                  return v;
                }
              }));
}

function remove(id, tids) {
  return Curry._2(Import.List.keep, tids, (function (v) {
                return !$$String.equal(id, v.id);
              }));
}

var WithId = {
  add: add,
  update: update,
  remove: remove
};

import { ReactComponent as _Bam } from "icons/root/icon-bam.svg"
;

var make = _Bam;

import { ReactComponent as _Bcf } from "icons/root/icon-bcf.svg"
;

var make$1 = _Bcf;

import { ReactComponent as _Bed } from "icons/root/icon-bed.svg"
;

var make$2 = _Bed;

import { ReactComponent as _BedGraph } from "icons/root/icon-bed-graph.svg"
;

var make$3 = _BedGraph;

import { ReactComponent as _BigBed } from "icons/root/icon-big-bed.svg"
;

var make$4 = _BigBed;

import { ReactComponent as _BigWig } from "icons/root/icon-big-wig.svg"
;

var make$5 = _BigWig;

import { ReactComponent as _Biom } from "icons/root/icon-biom.svg"
;

var make$6 = _Biom;

import { ReactComponent as _Cra } from "icons/root/icon-cra.svg"
;

var make$7 = _Cra;

import { ReactComponent as _Cram } from "icons/root/icon-cram.svg"
;

var make$8 = _Cram;

import { ReactComponent as _Csv } from "icons/root/icon-csv.svg"
;

var make$9 = _Csv;

import { ReactComponent as _Fasta } from "icons/root/icon-fasta.svg"
;

var make$10 = _Fasta;

import { ReactComponent as _Fastq } from "icons/root/icon-fastq.svg"
;

var make$11 = _Fastq;

import { ReactComponent as _Gct } from "icons/root/icon-gct.svg"
;

var make$12 = _Gct;

import { ReactComponent as _Gff3 } from "icons/root/icon-gff3.svg"
;

var make$13 = _Gff3;

import { ReactComponent as _Gff } from "icons/root/icon-gff.svg"
;

var make$14 = _Gff;

import { ReactComponent as _Gtf } from "icons/root/icon-gtf.svg"
;

var make$15 = _Gtf;

import { ReactComponent as _Newick } from "icons/root/icon-newick.svg"
;

var make$16 = _Newick;

import { ReactComponent as _Qza } from "icons/root/icon-qza.svg"
;

var make$17 = _Qza;

import { ReactComponent as _Sam } from "icons/root/icon-sam.svg"
;

var make$18 = _Sam;

import { ReactComponent as _Sra } from "icons/root/icon-sra.svg"
;

var make$19 = _Sra;

import { ReactComponent as _Tsv } from "icons/root/icon-tsv.svg"
;

var make$20 = _Tsv;

import { ReactComponent as _Vcf } from "icons/root/icon-vcf.svg"
;

var make$21 = _Vcf;

import { ReactComponent as _Wig } from "icons/root/icon-wig.svg"
;

var make$22 = _Wig;

function Root$Icon$BarChart(Props) {
  return React.createElement("img", {
              alt: "",
              src: require("icons/root/icon-bar.svg")
            });
}

function Root$Icon$BubbleChart(Props) {
  return React.createElement("img", {
              alt: "",
              src: require("icons/root/icon-bubble.svg")
            });
}

function Root$Icon$CircularPlot(Props) {
  return React.createElement("img", {
              alt: "",
              src: require("icons/root/icon-circular.svg")
            });
}

function Root$Icon$Cytoscape(Props) {
  return React.createElement("img", {
              alt: "",
              src: require("icons/root/icon-cytoscape.svg")
            });
}

function Root$Icon$Dendrogram(Props) {
  return React.createElement("img", {
              alt: "",
              src: require("icons/root/icon-dendrogram.svg")
            });
}

function Root$Icon$DonutChart(Props) {
  return React.createElement("img", {
              alt: "",
              src: require("icons/root/icon-donut.svg")
            });
}

function Root$Icon$GenomeBrowser(Props) {
  return React.createElement("img", {
              alt: "",
              src: require("icons/root/icon-genome-browser.svg")
            });
}

function Root$Icon$Heatmap(Props) {
  return React.createElement("img", {
              alt: "",
              src: require("icons/root/icon-heatmap.svg")
            });
}

function Root$Icon$KronaChart(Props) {
  return React.createElement("img", {
              alt: "",
              src: require("icons/root/icon-krona.svg")
            });
}

function Root$Icon$NetworkDiagram(Props) {
  return React.createElement("img", {
              alt: "",
              src: require("icons/root/icon-network.svg")
            });
}

function Root$Icon$ParallelCoordinates(Props) {
  return React.createElement("img", {
              alt: "",
              src: require("icons/root/icon-parallel-coordinates.svg")
            });
}

function Root$Icon$PathwayMap(Props) {
  return React.createElement("img", {
              alt: "",
              src: require("icons/root/icon-pathway-map.svg")
            });
}

function Root$Icon$PrincipalCoordinates(Props) {
  return React.createElement("img", {
              alt: "",
              src: require("icons/root/icon-pca.svg")
            });
}

function Root$Icon$PhylogeneticTree(Props) {
  return React.createElement("img", {
              alt: "",
              src: require("icons/root/icon-phylogenetic-tree.svg")
            });
}

function Root$Icon$PieChart(Props) {
  return React.createElement("img", {
              alt: "",
              src: require("icons/root/icon-pie.svg")
            });
}

function Root$Icon$SankeyDiagram(Props) {
  return React.createElement("img", {
              alt: "",
              src: require("icons/root/icon-sankey.svg")
            });
}

function Root$Icon$SequenceMotifs(Props) {
  return React.createElement("img", {
              alt: "",
              src: require("icons/root/icon-sequence-motifs.svg")
            });
}

function Root$Icon$SummaryStatistics(Props) {
  return React.createElement("img", {
              alt: "",
              src: require("icons/root/icon-stats.svg")
            });
}

function Root$Icon$TSNE(Props) {
  return React.createElement("img", {
              alt: "",
              src: require("icons/root/icon-t-sne.svg")
            });
}

function Root$Icon$VennDiagram(Props) {
  return React.createElement("img", {
              alt: "",
              src: require("icons/root/icon-venn.svg")
            });
}

function Root$Icon$VolcanoPlot(Props) {
  return React.createElement("img", {
              alt: "",
              src: require("icons/root/icon-volcano.svg")
            });
}

function Root$Icon(Props) {
  var icon = Props.icon;
  var exit = 0;
  if (icon >= 117882500) {
    if (icon >= 642196241) {
      if (icon >= 790610449) {
        if (icon >= 913487465) {
          if (icon !== 935668598) {
            if (icon >= 982189878) {
              if (icon >= 988997452) {
                return React.createElement(Root$Icon$Heatmap, { });
              } else {
                exit = 1;
              }
            } else {
              exit = icon >= 969511896 ? 2 : 1;
            }
          } else {
            return React.createElement(Root$Icon$TSNE, { });
          }
        } else if (icon !== 792454412) {
          if (icon !== 799219443) {
            exit = icon >= 907398765 ? 2 : 1;
          } else {
            return React.createElement(Root$Icon$PhylogeneticTree, { });
          }
        } else {
          return React.createElement(make$13, { });
        }
      } else if (icon >= 737157829) {
        if (icon >= 748691835) {
          if (icon >= 758139700) {
            if (icon >= 775314475) {
              exit = 2;
            } else {
              return React.createElement(Root$Icon$SankeyDiagram, { });
            }
          } else if (icon >= 756135328) {
            exit = 1;
          } else {
            return React.createElement(make$8, { });
          }
        } else if (icon >= 739741055) {
          exit = 2;
        } else {
          return React.createElement(make$6, { });
        }
      } else if (icon !== 672270517) {
        if (icon !== 687725842) {
          if (icon >= 642196242) {
            exit = 1;
          } else {
            return React.createElement(make$16, { });
          }
        } else {
          return React.createElement(Root$Icon$PieChart, { });
        }
      } else {
        exit = 2;
      }
    } else if (icon >= 360427206) {
      if (icon >= 484974721) {
        if (icon !== 586017299) {
          if (icon >= 486079769) {
            exit = icon !== 588923761 ? 1 : 2;
          } else if (icon >= 486019925) {
            return React.createElement(make$5, { });
          } else {
            return React.createElement(make$4, { });
          }
        } else {
          return React.createElement(Root$Icon$VolcanoPlot, { });
        }
      } else if (icon !== 366103461) {
        exit = icon >= 463720457 ? (
            icon >= 464204031 ? 2 : 1
          ) : (
            icon !== 374094439 ? 2 : 1
          );
      } else {
        return React.createElement(Root$Icon$SequenceMotifs, { });
      }
    } else if (icon >= 270345389) {
      if (icon !== 288973297) {
        if (icon >= 316537906) {
          if (icon >= 359953472) {
            return React.createElement(Root$Icon$CircularPlot, { });
          } else {
            return React.createElement(Root$Icon$PathwayMap, { });
          }
        } else if (icon >= 300511203) {
          return React.createElement(Root$Icon$KronaChart, { });
        } else {
          exit = 1;
        }
      } else {
        exit = 2;
      }
    } else if (icon >= 135999336) {
      if (icon >= 243398197) {
        if (icon >= 265105709) {
          exit = 2;
        } else {
          return React.createElement(make$11, { });
        }
      } else if (icon >= 243398181) {
        return React.createElement(make$10, { });
      } else {
        exit = 1;
      }
    } else if (icon >= 118811026) {
      return React.createElement(Root$Icon$BubbleChart, { });
    } else {
      exit = 2;
    }
  } else if (icon >= -193391650) {
    if (icon >= 3556729) {
      if (icon >= 4298873) {
        if (icon !== 56152651) {
          if (icon >= 4349942) {
            if (icon >= 83361156) {
              return React.createElement(Root$Icon$VennDiagram, { });
            } else {
              exit = 1;
            }
          } else if (icon >= 4349941) {
            return React.createElement(make$22, { });
          } else {
            return React.createElement(make$21, { });
          }
        } else {
          exit = 2;
        }
      } else if (icon >= 4149247) {
        if (icon >= 4202999) {
          if (icon >= 4292161) {
            exit = 1;
          } else {
            return React.createElement(make$20, { });
          }
        } else if (icon >= 4153026) {
          return React.createElement(make$19, { });
        } else {
          return React.createElement(make$18, { });
        }
      } else if (icon >= 4055352) {
        return React.createElement(make$17, { });
      } else {
        return React.createElement(make$15, { });
      }
    } else if (icon >= 3308748) {
      if (icon !== 3357362) {
        if (icon >= 3552952) {
          if (icon >= 3553607) {
            return React.createElement(make$14, { });
          } else {
            return React.createElement(make$12, { });
          }
        } else if (icon !== 3357606) {
          exit = 1;
        } else {
          return React.createElement(make$9, { });
        }
      } else {
        return React.createElement(make$7, { });
      }
    } else if (icon !== -55180330) {
      if (icon >= 3304293) {
        if (icon >= 3304737) {
          return React.createElement(make$2, { });
        } else {
          return React.createElement(make$1, { });
        }
      } else if (icon >= 3303854) {
        return React.createElement(make, { });
      } else {
        exit = 1;
      }
    } else {
      exit = 2;
    }
  } else if (icon >= -449223643) {
    if (icon >= -298608085) {
      if (icon >= -265814703) {
        if (icon >= -262983633) {
          exit = icon >= -214208189 ? 2 : 1;
        } else if (icon >= -265545067) {
          return React.createElement(Root$Icon$Cytoscape, { });
        } else {
          return React.createElement(Root$Icon$GenomeBrowser, { });
        }
      } else if (icon >= -288656400) {
        exit = 1;
      } else {
        return React.createElement(Root$Icon$BarChart, { });
      }
    } else if (icon !== -412176980) {
      if (icon >= -322318508) {
        if (icon >= -318813910) {
          exit = 2;
        } else {
          return React.createElement(Root$Icon$ParallelCoordinates, { });
        }
      } else if (icon >= -449223642) {
        exit = 1;
      } else {
        return React.createElement(Root$Icon$NetworkDiagram, { });
      }
    } else {
      exit = 2;
    }
  } else if (icon >= -605144268) {
    if (icon !== -533179959) {
      if (icon !== -508885141) {
        if (icon !== -496309491) {
          exit = 1;
        } else {
          return React.createElement(Root$Icon$PrincipalCoordinates, { });
        }
      } else {
        return React.createElement(Root$Icon$Dendrogram, { });
      }
    } else {
      return React.createElement(Root$Icon$SummaryStatistics, { });
    }
  } else if (icon !== -844938357) {
    if (icon !== -809444755) {
      if (icon >= -642279940) {
        return React.createElement(Root$Icon$DonutChart, { });
      } else {
        exit = 1;
      }
    } else {
      return React.createElement(make$3, { });
    }
  } else {
    exit = 2;
  }
  switch (exit) {
    case 1 :
        return React.createElement(Root$Icon$SummaryStatistics, { });
    case 2 :
        return React.createElement(Root$Icon$BarChart, { });
    
  }
}

var all = /* :: */[
  /* Anova */907398765,
  /* :: */[
    /* AreaChart */288973297,
    /* :: */[
      /* BarChart */-298608085,
      /* :: */[
        /* Bam */3303854,
        /* :: */[
          /* Bcf */3304293,
          /* :: */[
            /* Bed */3304737,
            /* :: */[
              /* BedGraph */-809444755,
              /* :: */[
                /* BigBed */484974721,
                /* :: */[
                  /* BigWig */486019925,
                  /* :: */[
                    /* Biom */737157829,
                    /* :: */[
                      /* BoxPlot */-412176980,
                      /* :: */[
                        /* BubbleChart */118811026,
                        /* :: */[
                          /* Cra */3357362,
                          /* :: */[
                            /* Cram */748691835,
                            /* :: */[
                              /* CircularPlot */359953472,
                              /* :: */[
                                /* Csv */3357606,
                                /* :: */[
                                  /* Cytoscape */-265545067,
                                  /* :: */[
                                    /* Dendrogram */-508885141,
                                    /* :: */[
                                      /* DonutChart */-642279940,
                                      /* :: */[
                                        /* DotPlot */-318813910,
                                        /* :: */[
                                          /* Fasta */243398181,
                                          /* :: */[
                                            /* Fastq */243398197,
                                            /* :: */[
                                              /* Filter */969511896,
                                              /* :: */[
                                                /* FisherTest */-313597417,
                                                /* :: */[
                                                  /* Gct */3552952,
                                                  /* :: */[
                                                    /* GenomeBrowser */-265814703,
                                                    /* :: */[
                                                      /* Gff */3553607,
                                                      /* :: */[
                                                        /* Gff3 */792454412,
                                                        /* :: */[
                                                          /* Gtf */3556729,
                                                          /* :: */[
                                                            /* Heatmap */988997452,
                                                            /* :: */[
                                                              /* HexbinPlot */265105709,
                                                              /* :: */[
                                                                /* HierarchicalClustering */588923761,
                                                                /* :: */[
                                                                  /* Histogram */117882500,
                                                                  /* :: */[
                                                                    /* KernelDensity */-844938357,
                                                                    /* :: */[
                                                                      /* KMeansClustering */56152651,
                                                                      /* :: */[
                                                                        /* KolmogorovSmirnovTest */977193341,
                                                                        /* :: */[
                                                                          /* KronaChart */300511203,
                                                                          /* :: */[
                                                                            /* KrustkallWallisRankSumTest */377205848,
                                                                            /* :: */[
                                                                              /* LineChart */780238890,
                                                                              /* :: */[
                                                                                /* MannWhitneyUTest */672270517,
                                                                                /* :: */[
                                                                                  /* NetworkDiagram */-449223643,
                                                                                  /* :: */[
                                                                                    /* Newick */642196241,
                                                                                    /* :: */[
                                                                                      /* ParallelCoordinates */-322318508,
                                                                                      /* :: */[
                                                                                        /* PathwayMap */316537906,
                                                                                        /* :: */[
                                                                                          /* PrincipalCoordinates */-496309491,
                                                                                          /* :: */[
                                                                                            /* PhylogeneticTree */799219443,
                                                                                            /* :: */[
                                                                                              /* PieChart */687725842,
                                                                                              /* :: */[
                                                                                                /* QuantileQuantilePlot */739741055,
                                                                                                /* :: */[
                                                                                                  /* Qza */4055352,
                                                                                                  /* :: */[
                                                                                                    /* Sam */4149247,
                                                                                                    /* :: */[
                                                                                                      /* SankeyDiagram */758139700,
                                                                                                      /* :: */[
                                                                                                        /* ScatterMatrix */464204031,
                                                                                                        /* :: */[
                                                                                                          /* ScatterPlot */266627423,
                                                                                                          /* :: */[
                                                                                                            /* SequenceMotifs */366103461,
                                                                                                            /* :: */[
                                                                                                              /* Sra */4153026,
                                                                                                              /* :: */[
                                                                                                                /* SummaryStatistics */-533179959,
                                                                                                                /* :: */[
                                                                                                                  /* TSNE */935668598,
                                                                                                                  /* :: */[
                                                                                                                    /* Tsv */4202999,
                                                                                                                    /* :: */[
                                                                                                                      /* TTest */360427206,
                                                                                                                      /* :: */[
                                                                                                                        /* Vcf */4298873,
                                                                                                                        /* :: */[
                                                                                                                          /* VennDiagram */83361156,
                                                                                                                          /* :: */[
                                                                                                                            /* ViolinPlot */-55180330,
                                                                                                                            /* :: */[
                                                                                                                              /* VolcanoPlot */586017299,
                                                                                                                              /* :: */[
                                                                                                                                /* WelchTTest */-214208189,
                                                                                                                                /* :: */[
                                                                                                                                  /* Wig */4349941,
                                                                                                                                  /* :: */[
                                                                                                                                    /* WilcoxonSignedRankTest */775314475,
                                                                                                                                    /* :: */[
                                                                                                                                      /* ANNOVAR */-595336935,
                                                                                                                                      /* :: */[
                                                                                                                                        /* BedTools */-905618118,
                                                                                                                                        /* :: */[
                                                                                                                                          /* Bismark */862746297,
                                                                                                                                          /* :: */[
                                                                                                                                            /* Bowtie2 */-338593844,
                                                                                                                                            /* :: */[
                                                                                                                                              /* BPSC */735908318,
                                                                                                                                              /* :: */[
                                                                                                                                                /* BreakDancer */-193391650,
                                                                                                                                                /* :: */[
                                                                                                                                                  /* BreaksSeq2 */195738823,
                                                                                                                                                  /* :: */[
                                                                                                                                                    /* BSMAP */931767627,
                                                                                                                                                    /* :: */[
                                                                                                                                                      /* BSSeeker2 */966293212,
                                                                                                                                                      /* :: */[
                                                                                                                                                        /* BUSCO */954245580,
                                                                                                                                                        /* :: */[
                                                                                                                                                          /* Bwa */3308748,
                                                                                                                                                          /* :: */[
                                                                                                                                                            /* Centrifuge */-978741780,
                                                                                                                                                            /* :: */[
                                                                                                                                                              /* ChromVAR */593715004,
                                                                                                                                                              /* :: */[
                                                                                                                                                                /* CLARK */-968446159,
                                                                                                                                                                /* :: */[
                                                                                                                                                                  /* CNVnator */486079769,
                                                                                                                                                                  /* :: */[
                                                                                                                                                                    /* CONCOCT */236342655,
                                                                                                                                                                    /* :: */[
                                                                                                                                                                      /* Cufflinks */674407783,
                                                                                                                                                                      /* :: */[
                                                                                                                                                                        /* DeepTools */-262983633,
                                                                                                                                                                        /* :: */[
                                                                                                                                                                          /* Delly2 */527599674,
                                                                                                                                                                          /* :: */[
                                                                                                                                                                            /* DESeq2 */571755476,
                                                                                                                                                                            /* :: */[
                                                                                                                                                                              /* DETONATE */756135328,
                                                                                                                                                                              /* :: */[
                                                                                                                                                                                /* DFilter */-981655780,
                                                                                                                                                                                /* :: */[
                                                                                                                                                                                  /* DIAMOND */-605144268,
                                                                                                                                                                                  /* :: */[
                                                                                                                                                                                    /* EBSeq */-427462846,
                                                                                                                                                                                    /* :: */[
                                                                                                                                                                                      /* EdgeR */-49423019,
                                                                                                                                                                                      /* :: */[
                                                                                                                                                                                        /* EricScript */-905605358,
                                                                                                                                                                                        /* :: */[
                                                                                                                                                                                          /* ExomeCNV */-806855433,
                                                                                                                                                                                          /* :: */[
                                                                                                                                                                                            /* Freebayes */507218044,
                                                                                                                                                                                            /* :: */[
                                                                                                                                                                                              /* GATK */790610449,
                                                                                                                                                                                              /* :: */[
                                                                                                                                                                                                /* GEM */3546223,
                                                                                                                                                                                                /* :: */[
                                                                                                                                                                                                  /* GOTTCHA */872885588,
                                                                                                                                                                                                  /* :: */[
                                                                                                                                                                                                    /* GSEA */791502216,
                                                                                                                                                                                                    /* :: */[
                                                                                                                                                                                                      /* HISAT2 */151037165,
                                                                                                                                                                                                      /* :: */[
                                                                                                                                                                                                        /* HOMER */692865363,
                                                                                                                                                                                                        /* :: */[
                                                                                                                                                                                                          /* HoneyBADGER */-119435830,
                                                                                                                                                                                                          /* :: */[
                                                                                                                                                                                                            /* HUMAnN2 */797202193,
                                                                                                                                                                                                            /* :: */[
                                                                                                                                                                                                              /* Kaiju */-277136002,
                                                                                                                                                                                                              /* :: */[
                                                                                                                                                                                                                /* Kallisto */653925531,
                                                                                                                                                                                                                /* :: */[
                                                                                                                                                                                                                  /* Kraken2 */-463599144,
                                                                                                                                                                                                                  /* :: */[
                                                                                                                                                                                                                    /* LimmaVoom */-235857605,
                                                                                                                                                                                                                    /* :: */[
                                                                                                                                                                                                                      /* LoFreq */-80373509,
                                                                                                                                                                                                                      /* :: */[
                                                                                                                                                                                                                        /* Lumpy */270345389,
                                                                                                                                                                                                                        /* :: */[
                                                                                                                                                                                                                          /* MACS2 */17082542,
                                                                                                                                                                                                                          /* :: */[
                                                                                                                                                                                                                            /* Manta */374094439,
                                                                                                                                                                                                                            /* :: */[
                                                                                                                                                                                                                              /* MAST */857147637,
                                                                                                                                                                                                                              /* :: */[
                                                                                                                                                                                                                                /* MaxBin */-220391261,
                                                                                                                                                                                                                                /* :: */[
                                                                                                                                                                                                                                  /* MEGAHIT */624266753,
                                                                                                                                                                                                                                  /* :: */[
                                                                                                                                                                                                                                    /* MEGAN */61635740,
                                                                                                                                                                                                                                    /* :: */[
                                                                                                                                                                                                                                      /* MEME */857345200,
                                                                                                                                                                                                                                      /* :: */[
                                                                                                                                                                                                                                        /* MetaBAT */-288656400,
                                                                                                                                                                                                                                        /* :: */[
                                                                                                                                                                                                                                          /* MetaPhlAn2 */-504356778,
                                                                                                                                                                                                                                          /* :: */[
                                                                                                                                                                                                                                            /* MetaSPAdes */655533043,
                                                                                                                                                                                                                                            /* :: */[
                                                                                                                                                                                                                                              /* MetaVelvet */235453677,
                                                                                                                                                                                                                                              /* :: */[
                                                                                                                                                                                                                                                /* MOCAT2 */-224558114,
                                                                                                                                                                                                                                                /* :: */[
                                                                                                                                                                                                                                                  /* Monocle */55558457,
                                                                                                                                                                                                                                                  /* :: */[
                                                                                                                                                                                                                                                    /* Monovar */56500868,
                                                                                                                                                                                                                                                    /* :: */[
                                                                                                                                                                                                                                                      /* Mothur */-967341,
                                                                                                                                                                                                                                                      /* :: */[
                                                                                                                                                                                                                                                        /* PeakRanger */-901802060,
                                                                                                                                                                                                                                                        /* :: */[
                                                                                                                                                                                                                                                          /* Picard */861529193,
                                                                                                                                                                                                                                                          /* :: */[
                                                                                                                                                                                                                                                            /* Platypus */-834977404,
                                                                                                                                                                                                                                                            /* :: */[
                                                                                                                                                                                                                                                              /* QIIME2 */463720457,
                                                                                                                                                                                                                                                              /* :: */[
                                                                                                                                                                                                                                                                /* RayMeta */-114511441,
                                                                                                                                                                                                                                                                /* :: */[
                                                                                                                                                                                                                                                                  /* RSEM */913487465,
                                                                                                                                                                                                                                                                  /* :: */[
                                                                                                                                                                                                                                                                    /* SAMtools */-134774340,
                                                                                                                                                                                                                                                                    /* :: */[
                                                                                                                                                                                                                                                                      /* Scater */-255823664,
                                                                                                                                                                                                                                                                      /* :: */[
                                                                                                                                                                                                                                                                        /* SCDE */923781137,
                                                                                                                                                                                                                                                                        /* :: */[
                                                                                                                                                                                                                                                                          /* Seurat */616846914,
                                                                                                                                                                                                                                                                          /* :: */[
                                                                                                                                                                                                                                                                            /* SICER */-88748902,
                                                                                                                                                                                                                                                                            /* :: */[
                                                                                                                                                                                                                                                                              /* Sourmash */-957409406,
                                                                                                                                                                                                                                                                              /* :: */[
                                                                                                                                                                                                                                                                                /* SnpEff */-806396784,
                                                                                                                                                                                                                                                                                /* :: */[
                                                                                                                                                                                                                                                                                  /* SnpSift */717546841,
                                                                                                                                                                                                                                                                                  /* :: */[
                                                                                                                                                                                                                                                                                    /* SNVer */-32349048,
                                                                                                                                                                                                                                                                                    /* :: */[
                                                                                                                                                                                                                                                                                      /* Stampy */982189878,
                                                                                                                                                                                                                                                                                      /* :: */[
                                                                                                                                                                                                                                                                                        /* STAR */924625874,
                                                                                                                                                                                                                                                                                        /* :: */[
                                                                                                                                                                                                                                                                                          /* Taxonomer */-490342859,
                                                                                                                                                                                                                                                                                          /* :: */[
                                                                                                                                                                                                                                                                                            /* TopHat2 */862607756,
                                                                                                                                                                                                                                                                                            /* :: */[
                                                                                                                                                                                                                                                                                              /* Trinity */-137196277,
                                                                                                                                                                                                                                                                                              /* :: */[
                                                                                                                                                                                                                                                                                                /* TransDecoder */-544416676,
                                                                                                                                                                                                                                                                                                /* :: */[
                                                                                                                                                                                                                                                                                                  /* Trinotate */-273821654,
                                                                                                                                                                                                                                                                                                  /* :: */[
                                                                                                                                                                                                                                                                                                    /* VarAFT */135999336,
                                                                                                                                                                                                                                                                                                    /* :: */[
                                                                                                                                                                                                                                                                                                      /* VarDict */298093533,
                                                                                                                                                                                                                                                                                                      /* :: */[
                                                                                                                                                                                                                                                                                                        /* VarScan */464138212,
                                                                                                                                                                                                                                                                                                        /* :: */[
                                                                                                                                                                                                                                                                                                          /* VEP */4292161,
                                                                                                                                                                                                                                                                                                          /* :: */[
                                                                                                                                                                                                                                                                                                            /* VCFtools */708460290,
                                                                                                                                                                                                                                                                                                            /* :: */[
                                                                                                                                                                                                                                                                                                              /* VizBin */681208128,
                                                                                                                                                                                                                                                                                                              /* :: */[
                                                                                                                                                                                                                                                                                                                /* WCGNA */-1000609874,
                                                                                                                                                                                                                                                                                                                /* [] */0
                                                                                                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                                ]
                                                                                                                                                                                                                              ]
                                                                                                                                                                                                                            ]
                                                                                                                                                                                                                          ]
                                                                                                                                                                                                                        ]
                                                                                                                                                                                                                      ]
                                                                                                                                                                                                                    ]
                                                                                                                                                                                                                  ]
                                                                                                                                                                                                                ]
                                                                                                                                                                                                              ]
                                                                                                                                                                                                            ]
                                                                                                                                                                                                          ]
                                                                                                                                                                                                        ]
                                                                                                                                                                                                      ]
                                                                                                                                                                                                    ]
                                                                                                                                                                                                  ]
                                                                                                                                                                                                ]
                                                                                                                                                                                              ]
                                                                                                                                                                                            ]
                                                                                                                                                                                          ]
                                                                                                                                                                                        ]
                                                                                                                                                                                      ]
                                                                                                                                                                                    ]
                                                                                                                                                                                  ]
                                                                                                                                                                                ]
                                                                                                                                                                              ]
                                                                                                                                                                            ]
                                                                                                                                                                          ]
                                                                                                                                                                        ]
                                                                                                                                                                      ]
                                                                                                                                                                    ]
                                                                                                                                                                  ]
                                                                                                                                                                ]
                                                                                                                                                              ]
                                                                                                                                                            ]
                                                                                                                                                          ]
                                                                                                                                                        ]
                                                                                                                                                      ]
                                                                                                                                                    ]
                                                                                                                                                  ]
                                                                                                                                                ]
                                                                                                                                              ]
                                                                                                                                            ]
                                                                                                                                          ]
                                                                                                                                        ]
                                                                                                                                      ]
                                                                                                                                    ]
                                                                                                                                  ]
                                                                                                                                ]
                                                                                                                              ]
                                                                                                                            ]
                                                                                                                          ]
                                                                                                                        ]
                                                                                                                      ]
                                                                                                                    ]
                                                                                                                  ]
                                                                                                                ]
                                                                                                              ]
                                                                                                            ]
                                                                                                          ]
                                                                                                        ]
                                                                                                      ]
                                                                                                    ]
                                                                                                  ]
                                                                                                ]
                                                                                              ]
                                                                                            ]
                                                                                          ]
                                                                                        ]
                                                                                      ]
                                                                                    ]
                                                                                  ]
                                                                                ]
                                                                              ]
                                                                            ]
                                                                          ]
                                                                        ]
                                                                      ]
                                                                    ]
                                                                  ]
                                                                ]
                                                              ]
                                                            ]
                                                          ]
                                                        ]
                                                      ]
                                                    ]
                                                  ]
                                                ]
                                              ]
                                            ]
                                          ]
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]
  ]
];

var Icon = {
  make: Root$Icon
};

export {
  all ,
  kind ,
  toVar ,
  ofVar ,
  ofTerm ,
  firstMatch ,
  keywords ,
  id ,
  toStringHum ,
  WithId ,
  Icon ,
  
}
/*  Not a pure module */
