

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Import from "../features/import.bs.js";
import * as ErrCss from "./Err.css";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as ReactExtras from "../lib/solvuu/ReactExtras.bs.js";
import * as Lang$SolvuuApi from "solvuu-api/./lang.bs.js";
import * as ReactBootstrap from "../lib/ReactBootstrap.bs.js";
import * as Lib from "react-bootstrap/lib";
import * as ErrBoundaryJs from "components/ErrBoundary.js";

var styles = ErrCss.default;

var iconError = (require("icons/icon-error-red.svg"));

function Err$Alert(Props) {
  var title = Props.title;
  var content = Props.content;
  var contentElement = content !== undefined ? ReactExtras.withTestId("error-alert-contents", React.createElement("div", undefined, Caml_option.valFromOption(content))) : null;
  var innerClassName = content !== undefined ? Cn.make(/* :: */[
          styles.inner,
          /* :: */[
            styles.innerWithContents,
            /* [] */0
          ]
        ]) : styles.inner;
  return ReactExtras.withTestId("error-alert", React.createElement("div", {
                  className: styles.root
                }, React.createElement("div", {
                      className: styles.icon
                    }, React.createElement("img", {
                          alt: "Error",
                          src: iconError
                        })), React.createElement("div", {
                      className: innerClassName
                    }, ReactExtras.withTestId("error-alert-title", React.createElement("div", {
                              className: styles.title
                            }, title)), contentElement)));
}

var Boundary = { };

function Err$Operation$ErrorDetails(Props) {
  Props.term;
  return React.createElement("details", {
              className: styles.details
            }, React.createElement("summary", undefined, React.createElement(ReactIntl.FormattedMessage, {
                      id: "operationError.showDetails",
                      tagName: "strong"
                    })), React.createElement("br", undefined), ReactExtras.withTestId("error-details", React.createElement("div", {
                      className: styles.detailsTerm
                    }, React.createElement(ErrBoundaryJs.default, {
                          children: "<Term>"
                        }))));
}

function Err$Operation(Props) {
  var message = Props.message;
  var errorDetails = Props.errorDetails;
  var readableMessage = message !== undefined ? (
      errorDetails !== undefined ? message + (": " + JSON.stringify(Curry._1(Lang$SolvuuApi.Term.json_of_t, errorDetails))) : message
    ) : (
      errorDetails !== undefined ? JSON.stringify(Curry._1(Lang$SolvuuApi.Term.json_of_t, errorDetails)) : undefined
    );
  var errorTitle = readableMessage !== undefined ? React.createElement(ReactIntl.FormattedMessage, {
          id: "operationError.expected"
        }) : React.createElement(ReactIntl.FormattedMessage, {
          id: "operationError.unexpected"
        });
  var errorMessage;
  if (readableMessage !== undefined) {
    var param = React.createElement("div", {
          className: styles.message
        }, readableMessage);
    errorMessage = ReactExtras.withTestId("error-alert-message", param);
  } else {
    var param$1 = React.createElement("div", undefined, React.createElement(ReactIntl.FormattedMessage, {
              id: "operationError.defaultMessage"
            }));
    errorMessage = ReactExtras.withTestId("error-alert-message", param$1);
  }
  var errorDetails$1 = errorDetails !== undefined ? React.createElement(Err$Operation$ErrorDetails, {
          term: errorDetails
        }) : null;
  var content = React.createElement(React.Fragment, undefined, errorMessage, errorDetails$1);
  return React.createElement(Err$Alert, {
              title: errorTitle,
              content: content
            });
}

function Err$Form(Props) {
  var match = Props.titleId;
  var titleId = match !== undefined ? match : "form.errors.title";
  var errors = Props.errors;
  var errors$1 = Curry._2(Import.List.keep, errors, (function (e) {
          return Belt_Option.isNone(e.field);
        }));
  if (errors$1) {
    var alertContents = Curry._1(Import.List.toArray, Curry._2(Import.List.mapWithIndex, errors$1, (function (idx, error) {
                var key = String(idx);
                var match = error.message;
                if (match === "") {
                  var id = Belt_Option.getWithDefault(error.id, "form.errors.unknown");
                  return React.createElement(ReactIntl.FormattedMessage, {
                              id: id,
                              tagName: "div",
                              key: key
                            });
                } else {
                  return React.createElement("div", {
                              key: key
                            }, error.message);
                }
              })));
    var alertTitle = React.createElement(ReactIntl.FormattedMessage, {
          id: titleId
        });
    return React.createElement("div", {
                className: styles.formRoot
              }, React.createElement(Err$Alert, {
                    title: alertTitle,
                    content: alertContents
                  }));
  } else {
    return null;
  }
}

var Form = {
  make: Err$Form
};

function Err$WorkInProgress(Props) {
  var children = Props.children;
  return React.createElement(Lib.Alert, ReactBootstrap.Alert.makeProps(/* warning */164354652, undefined, null, undefined, /* () */0), React.createElement("strong", undefined, React.createElement(Lib.Glyphicon, ReactBootstrap.Glyphicon.makeProps(/* wrench */-79926039, /* () */0)), "Work in progress"), React.createElement("span", undefined, "- this feature is not fully implemented yet"), children);
}

var WorkInProgress = {
  make: Err$WorkInProgress
};

var Alert = {
  make: Err$Alert
};

var Operation = {
  make: Err$Operation
};

export {
  Alert ,
  Boundary ,
  Operation ,
  Form ,
  WorkInProgress ,
  
}
/* styles Not a pure module */
