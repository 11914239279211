

import * as React from "react";
import * as ByteSize from "../../lib/ByteSize.bs.js";
import * as ReactIntl from "../../lib/ReactIntl.bs.js";
import * as Caml_int64 from "bs-platform/lib/es6/caml_int64.js";
import * as ReactIntl$1 from "react-intl";
import * as StyleCss from "./style.css";

var styles = StyleCss.default;

function toStringHum(bytes) {
  var match = ByteSize.byteSize(/* Iec */3652839, 1, bytes);
  var formattedValue = match.value.replace((/.0$/), "");
  var formattedUnit = match.unit.replace("i", "").toUpperCase();
  return "" + (String(formattedValue) + ("" + (String(formattedUnit) + "")));
}

function FormattedBytes(Props) {
  var value = Props.value;
  var value$1 = Caml_int64.to_float(value);
  var readableBytes = toStringHum(value$1);
  return React.createElement(ReactIntl$1.FormattedNumber, ReactIntl.FormattedNumber.makeProps(value$1, undefined, undefined, (function (formattedBytesValue) {
                    return React.createElement("abbr", {
                                className: styles.abbr,
                                title: formattedBytesValue + "B"
                              }, readableBytes);
                  }), undefined, /* () */0));
}

var make = FormattedBytes;

export {
  toStringHum ,
  make ,
  
}
/* styles Not a pure module */
