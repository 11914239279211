

import * as GenomeBrowserReduxJs from "./GenomeBrowserRedux.js";

var make = GenomeBrowserReduxJs.default;

export {
  make ,
  
}
/* make Not a pure module */
