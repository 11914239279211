import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Alert } from "react-bootstrap";
import { VennDiagram as vennDiagram, sortAreas } from "venn.js";
import * as d3 from "d3-selection";
import { select } from "d3-selection";
import { scaleOrdinal } from "d3-scale";
import { equals, toPairs, union } from "ramda";

import { categorical } from "lib/colors";

import styles from "table/TableVennDiagram.css";

export function setToString(set) {
  return set.join(" ∩ ");
}

export default class VennDiagram extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(
      PropTypes.shape({
        sets: PropTypes.arrayOf(PropTypes.string).isRequired,
        size: PropTypes.number.isRequired
      })
    ).isRequired
  };

  state = {
    disjointSets: null
  };

  diagramRef = React.createRef();
  tooltipRef = React.createRef();

  drawDiagram(data) {
    const node = this.diagramRef.current;
    const selection = select(node);

    // Reset the chart
    selection.selectAll("*").remove();

    // Draw the chart
    const allSets = data.map(d => d.sets).reduce(union);
    const colorScale = scaleOrdinal()
      .domain(allSets)
      .range(categorical);

    const chart = vennDiagram().colours(key => colorScale(key[0]));
    const solution = chart(selection.datum(data));

    selection.selectAll(".label").style("font-size", "12px");

    this.drawTooltip(selection);
    this.calculateDisjointSets(data, solution);
  }

  // https://github.com/benfred/venn.js#adding-tooltips
  drawTooltip(selection) {
    // add a tooltip
    const tooltip = select(this.tooltipRef.current);

    // add listeners to all the groups to display tooltip on mouseover
    selection
      .selectAll("g")
      .on("mouseover", function(d) {
        // sort all the areas relative to the current item
        sortAreas(selection, d);

        // Display a tooltip with the current size
        tooltip.style("opacity", 1.0);
        tooltip.selectAll("*").remove();
        tooltip.append("div").text(setToString(d.sets));
        tooltip.append("div").text("Size: " + d.size);

        // highlight the current path
        const current = select(this);
        current
          .select("path")
          .style("stroke-width", 3)
          .style("stroke", "#666")
          .style("fill-opacity", d.sets.length === 1 ? 0.4 : 0.1)
          .style("stroke-opacity", 1);
      })
      .on("mousemove", function() {
        tooltip
          .style("left", d3.event.clientX + 10 + "px")
          .style("top", d3.event.clientY + 10 + "px");
      })
      .on("mouseout", function(d, i) {
        tooltip.style("opacity", 0);
        const current = select(this);
        current
          .select("path")
          .style("stroke-width", 0)
          .style("fill-opacity", d.sets.length === 1 ? 0.25 : 0.0)
          .style("stroke-opacity", 0);
      });
  }

  calculateDisjointSets(data, solution) {
    const disjointSets = toPairs(solution.textCentres)
      .filter(([text, data]) => data.disjoint)
      .map(([text, data]) => text.split(","))
      .filter(sets => {
        return data.find(subset => equals(subset.sets, sets)).size > 0;
      });

    this.setState({ disjointSets });
  }

  componentDidMount() {
    this.drawDiagram(this.props.data);
  }

  componentDidUpdate(prevProps) {
    if (!equals(this.props.data, prevProps.data)) {
      this.drawDiagram(this.props.data);
    }
  }

  renderDisjointSetsWarning() {
    const { disjointSets } = this.state;
    if (disjointSets === null) return null;
    if (disjointSets.length === 0) return null;

    return (
      <Alert bsStyle="warning">
        <FormattedMessage
          id="table.vennDiagram.disjointSetsWarning"
          tagName="p"
        />
        {disjointSets.map(set => {
          const text = setToString(set);
          return <p key={text}>{text}</p>;
        })}
      </Alert>
    );
  }

  render() {
    const warning = this.renderDisjointSetsWarning();
    return (
      <Fragment>
        <div ref={this.diagramRef} className={styles.diagramRoot} />
        <div ref={this.tooltipRef} className={styles.diagramTooltip} />
        {warning}
      </Fragment>
    );
  }
}
