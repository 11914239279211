import React, { Component } from "react";

import { Checkbox } from "components/FormControls/FormControls.js";
import OnClickOutside from "components/OnClickOutside/OnClickOutside.js";
import iconMore from "icons/icon-more-black.svg";

import styles from "./style.css";

class JobsFiltersDropdown extends Component {
  state = {
    open: false
  };

  handleClickOutside = event => {
    this.setState({ open: false });
  };

  handleButtonClick = event => {
    this.setState({ open: !this.state.open });
  };

  handleCheckboxChange = event => {
    this.props.handleIncludeSystemJobs(event.target.checked);
  };

  render() {
    const { includeSystemJobs } = this.props;

    return (
      <OnClickOutside onClickOutside={this.handleClickOutside}>
        {containerRef => (
          <div className={styles.root}>
            <button
              type="button"
              onClick={this.handleButtonClick}
              className={styles.button}
            >
              <img src={iconMore} alt="jobs filters" />
            </button>
            {this.state.open && (
              <div className={styles.menu}>
                <Checkbox
                  name="includeSystemJobs"
                  label="Include system jobs"
                  checked={includeSystemJobs}
                  onChange={this.handleCheckboxChange}
                />
              </div>
            )}
          </div>
        )}
      </OnClickOutside>
    );
  }
}

export default JobsFiltersDropdown;
