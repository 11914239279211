

import * as Err from "../components/Err.bs.js";
import * as Let from "./Let.bs.js";
import * as Biom from "../containers/Entities/TaxonomicAnalysis/Biom/Biom.bs.js";
import * as Card from "../components/Card/Card.bs.js";
import * as HTML from "../containers/Entities/Blob/HTML/HTML.bs.js";
import * as Link from "../containers/Link.bs.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as $$Image from "../containers/Entities/Blob/Image/Image.bs.js";
import * as Table from "../table/Table.bs.js";
import * as Term0 from "./Term0.bs.js";
import * as React from "react";
import * as Base64 from "../lib/Base64.bs.js";
import * as Hexbin from "./Hexbin.bs.js";
import * as Import from "../features/import.bs.js";
import * as Record from "../containers/Entities/Record/Record.bs.js";
import * as $$String from "bs-platform/lib/es6/string.js";
import * as BoxPlot from "./BoxPlot.bs.js";
import * as Bubbles from "./Bubbles.bs.js";
import * as DotPlot from "./DotPlot.bs.js";
import * as Heatmap from "./Heatmap.bs.js";
import * as BarChart from "./BarChart.bs.js";
import * as Std$Bolt from "bolt/./std.bs.js";
import * as TermEval from "../lib/solvuu/termEval.bs.js";
import * as AreaChart from "./AreaChart.bs.js";
import * as Histogram from "./Histogram.bs.js";
import * as LineChart from "./LineChart.bs.js";
import * as ReactIntl from "../lib/ReactIntl.bs.js";
import * as TermUtils from "../lib/solvuu/termUtils.bs.js";
import * as DonutChart from "./DonutChart.bs.js";
import * as $$FileReader from "../lib/FileReader.bs.js";
import * as KronaChart from "./KronaChart.bs.js";
import * as NewickTree from "./NewickTree.bs.js";
import * as SolvuuBlob from "../containers/Entities/Blob/SolvuuBlob.bs.js";
import * as ViolinPlot from "./ViolinPlot.bs.js";
import * as ReactIntl$1 from "react-intl";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as ReactExtras from "../lib/solvuu/ReactExtras.bs.js";
import * as ScatterPlot from "./ScatterPlot.bs.js";
import * as VennDiagram from "./VennDiagram.bs.js";
import * as VolcanoPlot from "./VolcanoPlot.bs.js";
import * as KernelDensity from "./KernelDensity.bs.js";
import * as ScatterMatrix from "./ScatterMatrix.bs.js";
import * as TableElements from "../table/TableElements.bs.js";
import * as Lang$SolvuuApi from "solvuu-api/./lang.bs.js";
import * as Root$SolvuuApi from "solvuu-api/./root.bs.js";
import * as VerticalLayout from "../components/Layout/VerticalLayout.bs.js";
import * as TermRenderContext from "./TermRenderContext.bs.js";
import * as Layout from "components/Layout";
import * as ParallelCoordinates from "./ParallelCoordinates.bs.js";
import * as PrincipalCoordinates from "./PrincipalCoordinates.bs.js";
import * as QuantileQuantilePlot from "./QuantileQuantilePlot.bs.js";
import * as PhylogeneticTree from "containers/Entities/PhylogeneticAnalysis/PhylogeneticTree";

function bytesOfFile(apiClient, file) {
  return Import.$great$great$eq($$FileReader.$$ArrayBuffer.read(file), (function (arrayBuffer) {
                var b64 = Base64.encode(arrayBuffer);
                return TermEval.$$eval(apiClient)(/* `App */[
                            3257473,
                            /* tuple */[
                              /* `Var */[
                                4298439,
                                Root$SolvuuApi.base64_decode
                              ],
                              /* `String */[
                                -976970511,
                                b64
                              ]
                            ]
                          ]);
              }));
}

function termDescription(term) {
  if (typeof term === "number") {
    if (term !== 870828711) {
      if (term >= 914889282) {
        return "Root";
      } else {
        return "Function";
      }
    } else {
      return "Null";
    }
  } else {
    var variant = term[0];
    if (variant >= 89433895) {
      if (variant >= 748194550) {
        if (variant >= 858945253) {
          if (variant >= 925978388) {
            if (variant >= 936769581) {
              return "Time";
            } else {
              return "Some";
            }
          } else if (variant >= 925483562) {
            return "S-expression";
          } else {
            return "Meta";
          }
        } else if (variant >= 847309489) {
          if (variant >= 848054398) {
            return "List";
          } else {
            return "Record";
          }
        } else if (variant >= 758940238) {
          return "Date";
        } else {
          return "Character";
        }
      } else if (variant >= 365180284) {
        if (variant >= 669191267) {
          if (variant >= 737456202) {
            return "Boolean";
          } else {
            return "Future";
          }
        } else if (variant >= 521729689) {
          return "If condition";
        } else {
          return "Float";
        }
      } else if (variant !== 106380200) {
        if (variant >= 295523485) {
          return "Blob Id";
        } else {
          return "Lambda";
        }
      } else {
        return "Error";
      }
    } else if (variant >= -37599172) {
      if (variant >= 3503881) {
        if (variant >= 4298439) {
          if (variant >= 4300231) {
            return "Visualization";
          } else {
            return "Variable";
          }
        } else if (variant >= 3654863) {
          return "Integer";
        } else {
          return "Foreign Function";
        }
      } else if (variant !== 3257473) {
        if (variant >= 3405101) {
          return "Directory";
        } else {
          return "Type ascription";
        }
      } else {
        return "Function application";
      }
    } else if (variant >= -964680357) {
      if (variant >= -452137819) {
        if (variant >= -216449554) {
          return "Term";
        } else {
          return "Intrinsic function";
        }
      } else if (variant >= -522825178) {
        return "Solvuu expression";
      } else {
        return "Closure";
      }
    } else if (variant !== -1019462983) {
      if (variant >= -976970511) {
        if (TermUtils.Re.isBiomDataframeTerm(term)) {
          return "BIOM data";
        } else if (TermUtils.Re.isNewickTerm(term)) {
          return "Phylogenetic tree";
        } else {
          return "String";
        }
      } else {
        return "Job Id";
      }
    } else {
      return "Projection";
    }
  }
}

function typeDescription(type_) {
  if (typeof type_ === "number") {
    if (type_ >= 737157829) {
      if (type_ >= 748194550) {
        if (type_ >= 785140586) {
          if (type_ >= 936769581) {
            return "Time";
          } else {
            return "Unknown";
          }
        } else if (type_ >= 758940238) {
          return "Date";
        } else {
          return "Char";
        }
      } else if (type_ >= 737456202) {
        return "Boolean";
      } else {
        return "Biom";
      }
    } else if (type_ !== -883458824) {
      if (type_ >= 365180284) {
        if (type_ >= 642196241) {
          return "Newick";
        } else {
          return "Float";
        }
      } else if (type_ >= 3654863) {
        return "Int";
      } else {
        return "String";
      }
    } else {
      return "Not available";
    }
  } else {
    var variant = type_[0];
    if (variant >= 848054398) {
      if (variant >= 937413395) {
        if (variant >= 951904681) {
          return "Arrow";
        } else {
          return "Tvar";
        }
      } else if (variant >= 925483562) {
        return "Sexp";
      } else {
        return "List";
      }
    } else if (variant !== 216537025) {
      if (variant >= 847309489) {
        return "Record";
      } else {
        return "Solvuu type";
      }
    } else {
      return "Nullable";
    }
  }
}

function make(Props) {
  var term = Props.term;
  var type_ = Props.type_;
  var context = Props.context;
  var apiClient = Props.apiClient;
  var make$1 = function (term, type_, context, key, param) {
    var tmp = {
      term: term,
      type_: type_,
      context: context,
      apiClient: apiClient
    };
    if (key !== undefined) {
      tmp.key = Caml_option.valFromOption(key);
    }
    return make(tmp);
  };
  var header = termDescription(term);
  var scalar = function (element) {
    var match = TermRenderContext.parent(context);
    if (match !== 112487626 && match < 914889282) {
      return element;
    }
    return React.createElement(Term0.RootCard.make, {
                header: header,
                children: element
              });
  };
  if (typeof term === "number") {
    if (term !== 870828711) {
      if (term >= 914889282) {
        var element = React.createElement("em", undefined, header);
        var match = TermRenderContext.parent(context);
        if (match !== 112487626 && match < 914889282) {
          return element;
        }
        return React.createElement(Card.make, {
                    children: element
                  });
      } else {
        var match$1 = TermRenderContext.parent(context);
        if (match$1 !== 112487626 && match$1 < 914889282) {
          return React.createElement("em", undefined, header);
        }
        return React.createElement(Term0.RootCard.make, {
                    header: header,
                    children: React.createElement("em", undefined, "Unknown function")
                  });
      }
    } else {
      var element$1 = React.createElement("em", undefined, "Missing value");
      var match$2 = TermRenderContext.parent(context);
      if (match$2 !== 112487626 && match$2 < 914889282) {
        return element$1;
      }
      return React.createElement(Card.make, {
                  children: element$1
                });
    }
  } else {
    var variant = term[0];
    if (variant >= 89433895) {
      if (variant >= 748194550) {
        if (variant >= 858945253) {
          if (variant >= 925978388) {
            if (variant >= 936769581) {
              return scalar(term[1]);
            } else {
              return make$1(term[1], type_, context, undefined, /* () */0);
            }
          } else if (variant >= 925483562) {
            var element$2 = React.createElement("code", undefined, term[1]);
            var match$3 = TermRenderContext.parent(context);
            if (match$3 !== 112487626 && match$3 < 914889282) {
              return element$2;
            }
            return React.createElement(Term0.RootCard.make, {
                        header: header,
                        children: element$2
                      });
          } else {
            return make$1(term[1][1], type_, context, undefined, /* () */0);
          }
        } else if (variant >= 847309489) {
          if (variant >= 848054398) {
            var rows = term[1];
            var list = function (subterms) {
              TermRenderContext.parent(context);
              return React.createElement(TableElements.TableElement.make, {
                          children: React.createElement("tbody", undefined, ReactExtras.listToElement(Curry._2(Import.List.mapWithIndex, subterms, (function (idx, term) {
                                          return React.createElement(TableElements.RowElement.make, {
                                                      children: React.createElement("td", {
                                                            style: {
                                                              padding: "20px"
                                                            }
                                                          }, make$1(term, /* Type_not_available */-883458824, TermRenderContext.into(context, /* ListElement */562477534), undefined, /* () */0)),
                                                      key: String(idx)
                                                    });
                                        }))))
                        });
            };
            if (rows) {
              var match$4 = rows[0];
              if (typeof match$4 === "number" || match$4[0] !== 847309489) {
                return list(rows);
              } else {
                var onlyNamedFields = Curry._3(Import.List.reduce, match$4[1], true, (function (acc, param) {
                        var label = param[0];
                        if (typeof label === "number" || label[0] !== 699531161 || !acc) {
                          return false;
                        } else {
                          return true;
                        }
                      }));
                if (onlyNamedFields) {
                  return React.createElement(Table.make, {
                              term: term,
                              apiClient: apiClient,
                              renderTerm: render
                            });
                } else {
                  return list(rows);
                }
              }
            } else {
              return "Empty list";
            }
          } else {
            var map = term[1];
            var match$5 = Curry._1(Import.List.unzip, map);
            Curry._2(Import.List.map, match$5[0], (function (label) {
                    if (label[0] >= 699531161) {
                      return label[1];
                    } else {
                      return String(label[1]);
                    }
                  }));
            TermRenderContext.parent(context);
            return React.createElement(Layout.VerticalLayout, VerticalLayout.makeProps(/* x1 */26809, ReactExtras.listToElement(Curry._2(Import.List.mapWithIndex, map, (function (i, param) {
                                      var key = param[0];
                                      Curry._1(Lang$SolvuuApi.Label.to_string, key);
                                      return React.createElement(Layout.VerticalLayoutItem, {
                                                  children: React.createElement("div", {
                                                        style: {
                                                          padding: "16px"
                                                        }
                                                      }, React.createElement("div", undefined, "./" + Curry._1(Lang$SolvuuApi.Label.to_string, key)), React.createElement("div", undefined, make$1(param[1], /* Type_not_available */-883458824, TermRenderContext.into(context, /* RecordElement */425137163), undefined, /* () */0))),
                                                  key: String(i)
                                                });
                                    }))), /* () */0));
          }
        } else if (variant >= 758940238) {
          return scalar(term[1]);
        } else {
          return scalar($$String.make(1, term[1]));
        }
      } else if (variant >= 365180284) {
        if (variant >= 669191267) {
          if (variant >= 737456202) {
            return scalar(JSON.stringify(term[1]));
          } else {
            var waitingElement = make$1(term[1], /* Type_not_available */-883458824, TermRenderContext.into(context, /* Inline */-841728391), undefined, /* () */0);
            var match$6 = TermRenderContext.parent(context);
            if (match$6 !== 112487626 && match$6 < 914889282) {
              return React.createElement("span", undefined, waitingElement, " - pending");
            }
            return React.createElement(Term0.RootCard.make, {
                        header: header,
                        children: null
                      }, "Waiting for ", waitingElement);
          }
        } else if (variant >= 521729689) {
          var match$7 = term[1];
          var conditionElement = React.createElement("div", undefined, "Condition: ", make$1(match$7[0], /* Type_not_available */-883458824, TermRenderContext.into(context, /* Inline */-841728391), undefined, /* () */0));
          var trueBranchElement = React.createElement("div", undefined, "If true: ", make$1(match$7[1], /* Type_not_available */-883458824, TermRenderContext.into(context, /* Inline */-841728391), undefined, /* () */0));
          var falseBranchElement = React.createElement("div", undefined, "If true: ", make$1(match$7[2], /* Type_not_available */-883458824, TermRenderContext.into(context, /* Inline */-841728391), undefined, /* () */0));
          var match$8 = TermRenderContext.parent(context);
          if (match$8 !== 112487626 && match$8 < 914889282) {
            return conditionElement;
          }
          return React.createElement(Term0.RootCard.make, {
                      header: header,
                      children: null
                    }, conditionElement, trueBranchElement, falseBranchElement);
        } else {
          return scalar(React.createElement(ReactIntl$1.FormattedNumber, ReactIntl.FormattedNumber.makeProps(term[1], undefined, undefined, undefined, undefined, /* () */0)));
        }
      } else if (variant !== 106380200) {
        if (variant >= 295523485) {
          var blobId = term[1];
          var match$9 = TermRenderContext.parent(context);
          if (match$9 !== 112487626 && match$9 < 914889282) {
            return React.createElement("span", undefined, "blob ", React.createElement(Link.make, {
                            to: /* `Drive */[
                              -219555606,
                              /* `Id */[
                                16379,
                                blobId
                              ]
                            ],
                            children: "#" + blobId
                          }));
          }
          return React.createElement(SolvuuBlob.make, {
                      blobId: blobId,
                      apiClient: apiClient
                    });
        } else {
          var match$10 = term[1];
          return React.createElement(Term0.RootCard.make, {
                      header: header,
                      children: React.createElement("div", undefined, React.createElement("div", undefined, React.createElement("em", undefined, match$10[0]), " ->"), React.createElement("div", undefined, make$1(match$10[1], /* Type_not_available */-883458824, TermRenderContext.into(context, /* Inline */-841728391), undefined, /* () */0)))
                    });
        }
      } else {
        var payload = term[1];
        var variant$1 = payload[0];
        var match$11;
        if (variant$1 >= 771069884) {
          if (variant$1 >= 937565914) {
            var match$12 = payload[1];
            match$11 = /* tuple */[
              match$12[2],
              match$12[0]
            ];
          } else {
            var match$13 = payload[1];
            match$11 = /* tuple */[
              match$13[1],
              match$13[0]
            ];
          }
        } else {
          match$11 = variant$1 >= 295523485 ? /* tuple */[
              "Error processing blob #" + payload[1],
              undefined
            ] : /* tuple */[
              "Error processing job #" + payload[1],
              undefined
            ];
        }
        var message = match$11[0];
        var match$14 = TermRenderContext.parent(context);
        if (match$14 !== 112487626 && match$14 < 914889282) {
          return message;
        }
        var tmp = {
          message: message
        };
        var tmp$1 = match$11[1];
        if (tmp$1 !== undefined) {
          tmp.errorDetails = Caml_option.valFromOption(tmp$1);
        }
        return React.createElement(Err.Operation.make, tmp);
      }
    } else if (variant >= -37599172) {
      if (variant >= 3503881) {
        if (variant >= 4298439) {
          if (variant >= 4300231) {
            var payload$1 = term[1];
            var graphic = function (propsOfTerm, make, term) {
              var match = Curry._1(propsOfTerm, term);
              if (match[0] >= 106380200) {
                var match$1 = match[1];
                if (typeof match$1 === "number") {
                  return React.createElement(Err.Operation.make, {
                              message: "Argument error: Expected record",
                              errorDetails: term
                            });
                } else {
                  var __x = Curry._1(Import.List.toArray, Curry._2(Import.List.map, match$1[1], (function (param) {
                                return "Field " + (Curry._1(Lang$SolvuuApi.Label.to_string, param[0]) + ((
                                            param[1] >= 144245463 ? " was invalid" : " was not found"
                                          ) + "."));
                              }))).join(" ");
                  var message = Curry._1(Import.sprintf(/* Format */[
                            /* String_literal */Block.__(11, [
                                "Argument error: ",
                                /* String */Block.__(2, [
                                    /* No_padding */0,
                                    /* End_of_format */0
                                  ])
                              ]),
                            "Argument error: %s"
                          ]), __x);
                  return React.createElement(Err.Operation.make, {
                              message: message,
                              errorDetails: term
                            });
                }
              } else {
                return React.createElement(make, match[1]);
              }
            };
            var element$3;
            var exit = 0;
            if (typeof payload$1 === "number" || payload$1[0] !== 847309489) {
              exit = 1;
            } else {
              element$3 = React.createElement(Record.ParsedRecord.make, {
                    children: Curry._2(Std$Bolt.Res.map, Curry._2(Lang$SolvuuApi.Record.Param.Tagged.parse, payload$1[1], Lang$SolvuuApi.Record.Param.Arg_type.data), (function (param) {
                            var term = param[1];
                            var tag = param[0];
                            var found;
                            if ($$String.equal(tag, Root$SolvuuApi.area_chart)) {
                              found = Caml_option.some(graphic(AreaChart.Props.ofTerm, AreaChart.make, term));
                            } else if ($$String.equal(tag, Root$SolvuuApi.bar_chart)) {
                              found = Caml_option.some(graphic(BarChart.Props.ofTerm, BarChart.make, term));
                            } else if ($$String.equal(tag, Root$SolvuuApi.box_plot)) {
                              found = Caml_option.some(graphic(BoxPlot.Props.ofTerm, BoxPlot.make, term));
                            } else if ($$String.equal(tag, Root$SolvuuApi.bubble_chart)) {
                              found = Caml_option.some(graphic(Bubbles.Props.ofTerm, Bubbles.make, term));
                            } else if ($$String.equal(tag, Root$SolvuuApi.donut_chart)) {
                              found = Caml_option.some(graphic(DonutChart.Props.ofTerm, DonutChart.make, term));
                            } else if ($$String.equal(tag, Root$SolvuuApi.dot_plot)) {
                              found = Caml_option.some(graphic(DotPlot.Props.ofTerm, DotPlot.make, term));
                            } else if ($$String.equal(tag, Root$SolvuuApi.heat_map)) {
                              found = Caml_option.some(graphic(Heatmap.Props.ofTerm, Heatmap.make, term));
                            } else if ($$String.equal(tag, Root$SolvuuApi.hexbin_plot)) {
                              found = Caml_option.some(graphic(Hexbin.Props.ofTerm, Hexbin.make, term));
                            } else if ($$String.equal(tag, Root$SolvuuApi.histogram)) {
                              if (typeof term === "number" || term[0] !== 847309489) {
                                found = undefined;
                              } else {
                                var match = Curry._2(Lang$SolvuuApi.Record.Param.Tagged.parse, term[1], Lang$SolvuuApi.Record.Param.Arg_type.data);
                                if (match[0] >= 106380200) {
                                  found = undefined;
                                } else {
                                  var match$1 = match[1];
                                  var term$1 = match$1[1];
                                  var tag$1 = match$1[0];
                                  found = $$String.equal(tag$1, Root$SolvuuApi.Histogram.T.Numerical.Const.tag) ? Caml_option.some(graphic(Histogram.Histogram.Props.ofTerm, Histogram.Histogram.make, term$1)) : (
                                      $$String.equal(tag$1, Root$SolvuuApi.Histogram.T.Categorical.Const.tag) ? Caml_option.some(graphic(Histogram.BarChart.Props.ofTerm, Histogram.BarChart.make, term$1)) : undefined
                                    );
                                }
                              }
                            } else {
                              found = $$String.equal(tag, Root$SolvuuApi.kernel_density) ? Caml_option.some(graphic(KernelDensity.Props.ofTerm, KernelDensity.make, term)) : (
                                  $$String.equal(tag, Root$SolvuuApi.krona_chart) ? Caml_option.some(graphic(KronaChart.Props.ofTerm, KronaChart.make, term)) : (
                                      $$String.equal(tag, Root$SolvuuApi.line_chart) ? Caml_option.some(graphic(LineChart.Props.ofTerm, LineChart.make, term)) : (
                                          $$String.equal(tag, Root$SolvuuApi.parallel_coordinates) ? Caml_option.some(graphic(ParallelCoordinates.Props.ofTerm, ParallelCoordinates.make, term)) : (
                                              $$String.equal(tag, Root$SolvuuApi.principal_coordinates) ? Caml_option.some(graphic(PrincipalCoordinates.Props.ofTerm, PrincipalCoordinates.make, term)) : (
                                                  $$String.equal(tag, Root$SolvuuApi.quantile_quantile_plot) ? Caml_option.some(graphic(QuantileQuantilePlot.Props.ofTerm, QuantileQuantilePlot.make, term)) : (
                                                      $$String.equal(tag, Root$SolvuuApi.scatter_plot) ? Caml_option.some(graphic(ScatterPlot.Props.ofTerm, ScatterPlot.make, term)) : (
                                                          $$String.equal(tag, Root$SolvuuApi.scatter_matrix) ? Caml_option.some(graphic(ScatterMatrix.Props.ofTerm, ScatterMatrix.make, term)) : (
                                                              $$String.equal(tag, Root$SolvuuApi.venn_diagram) ? Caml_option.some(graphic(VennDiagram.Props.ofTerm, VennDiagram.make, term)) : (
                                                                  $$String.equal(tag, Root$SolvuuApi.violin_plot) ? Caml_option.some(graphic(ViolinPlot.Props.ofTerm, ViolinPlot.make, term)) : (
                                                                      $$String.equal(tag, Root$SolvuuApi.volcano_plot) ? Caml_option.some(graphic(VolcanoPlot.Props.ofTerm, VolcanoPlot.make, term)) : undefined
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                );
                            }
                            if (found !== undefined) {
                              return Caml_option.valFromOption(found);
                            } else {
                              return React.createElement(Err.Operation.make, {
                                          message: "No renderer found for " + tag
                                        });
                            }
                          }))
                  });
            }
            if (exit === 1) {
              element$3 = React.createElement(Err.Operation.make, {
                    message: "Invalid visualization",
                    errorDetails: payload$1
                  });
            }
            var match$15 = TermRenderContext.parent(context);
            if (match$15 !== 112487626 && match$15 < 914889282) {
              return React.createElement("em", undefined, header);
            }
            return React.createElement(Term0.RootCard.make, {
                        header: header,
                        children: element$3
                      });
          } else {
            var element$4 = React.createElement("em", undefined, term[1]);
            var match$16 = TermRenderContext.parent(context);
            if (match$16 !== 112487626 && match$16 < 914889282) {
              return element$4;
            }
            return React.createElement(Term0.RootCard.make, {
                        header: header,
                        children: element$4
                      });
          }
        } else if (variant >= 3654863) {
          return scalar(React.createElement(ReactIntl$1.FormattedNumber, ReactIntl.FormattedNumber.makeProps(term[1], undefined, undefined, undefined, undefined, /* () */0)));
        } else {
          var element$5 = React.createElement("em", undefined, term[1]);
          var match$17 = TermRenderContext.parent(context);
          if (match$17 !== 112487626 && match$17 < 914889282) {
            return element$5;
          }
          return React.createElement(Term0.RootCard.make, {
                      header: header,
                      children: element$5
                    });
        }
      } else if (variant !== 3257473) {
        if (variant >= 3405101) {
          var match$18 = term[1];
          var data = match$18.data;
          var element$6 = "Directory " + match$18.id;
          var contentsElement = data !== undefined ? Curry._1(Import.sprintf(/* Format */[
                      /* String_literal */Block.__(11, [
                          "Contents: ",
                          /* Int */Block.__(4, [
                              /* Int_d */0,
                              /* No_padding */0,
                              /* No_precision */0,
                              /* String_literal */Block.__(11, [
                                  " items",
                                  /* End_of_format */0
                                ])
                            ])
                        ]),
                      "Contents: %d items"
                    ]), Curry._1(Import.List.length, data)) : "Contents: unknown";
          var match$19 = TermRenderContext.parent(context);
          if (match$19 !== 112487626 && match$19 < 914889282) {
            return element$6;
          }
          return React.createElement(Term0.RootCard.make, {
                      header: header,
                      children: null
                    }, element$6, contentsElement);
        } else {
          var match$20 = term[1];
          var termElement = make$1(match$20[0], /* Type_not_available */-883458824, TermRenderContext.into(context, /* Inline */-841728391), undefined, /* () */0);
          var typeElement = typeDescription(match$20[1]);
          var match$21 = TermRenderContext.parent(context);
          if (match$21 !== 112487626 && match$21 < 914889282) {
            return React.createElement("span", undefined, "Type ascription of ", typeElement, " to ", termElement);
          }
          return React.createElement(Term0.RootCard.make, {
                      header: header,
                      children: null
                    }, React.createElement("div", undefined, "Term: ", termElement), React.createElement("div", undefined, "Type: ", typeElement));
        }
      } else {
        var match$22 = term[1];
        var f = match$22[0];
        if (typeof f !== "number") {
          var variant$2 = f[0];
          if (variant$2 !== -1019462983) {
            if (variant$2 === 89433895) {
              return React.createElement(Let.make, {
                          apiClient: apiClient,
                          renderTerm: render,
                          showButtons: true,
                          term: term,
                          context: context
                        });
            }
            
          } else {
            var x = f[1];
            var field = x[0] >= 699531161 ? x[1] : String(x[1]);
            return React.createElement(Card.make, {
                        children: React.createElement(React.Fragment, undefined, "Project " + (field + "out of "), make$1(match$22[1], /* Type_not_available */-883458824, TermRenderContext.into(context, /* Inline */-841728391), undefined, /* () */0))
                      });
          }
        }
        var arg = match$22[1];
        var $$default = function (param) {
          return React.createElement(Layout.VerticalLayout, VerticalLayout.makeProps(/* x1 */26809, null, /* () */0), React.createElement(Layout.VerticalLayoutItem, {
                          children: make$1(f, /* Type_not_available */-883458824, TermRenderContext.into(context, /* Inline */-841728391), undefined, /* () */0)
                        }), React.createElement(Layout.VerticalLayoutItem, {
                          children: make$1(arg, /* Type_not_available */-883458824, TermRenderContext.into(context, /* Inline */-841728391), undefined, /* () */0)
                        }));
        };
        if (typeof f === "number" || f[0] !== 4298439) {
          return $$default(/* () */0);
        } else {
          var func = f[1];
          if (typeof arg === "number" || arg[0] !== 295523485) {
            return $$default(/* () */0);
          } else {
            var blobId$1 = arg[1];
            if (Curry._2(Lang$SolvuuApi.Var.equal, func, Root$SolvuuApi.html)) {
              return React.createElement(HTML.make, {
                          name: "",
                          blobId: blobId$1,
                          apiClient: apiClient
                        });
            } else if (Curry._2(Lang$SolvuuApi.Var.equal, func, Root$SolvuuApi.png)) {
              return React.createElement($$Image.make, {
                          name: "",
                          blobId: blobId$1,
                          apiClient: apiClient
                        });
            } else {
              return $$default(/* () */0);
            }
          }
        }
      }
    } else if (variant >= -964680357) {
      if (variant >= -452137819) {
        if (variant >= -216449554) {
          var element$7 = "Term #" + term[1];
          var match$23 = TermRenderContext.parent(context);
          if (match$23 !== 112487626 && match$23 < 914889282) {
            return element$7;
          }
          return React.createElement(Term0.RootCard.make, {
                      header: header,
                      children: element$7
                    });
        } else {
          var match$24 = TermRenderContext.parent(context);
          if (match$24 !== 112487626 && match$24 < 914889282) {
            return React.createElement("em", undefined, header);
          }
          return React.createElement(Term0.RootCard.make, {
                      header: header,
                      children: React.createElement("em", undefined, "Unknown intrinsic function")
                    });
        }
      } else if (variant >= -522825178) {
        var element$8 = React.createElement("code", undefined, term[1]);
        var match$25 = TermRenderContext.parent(context);
        if (match$25 !== 112487626 && match$25 < 914889282) {
          return element$8;
        }
        return React.createElement(Term0.RootCard.make, {
                    header: header,
                    children: element$8
                  });
      } else {
        var termElement$1 = make$1(term[1][1], /* Type_not_available */-883458824, TermRenderContext.into(context, /* Inline */-841728391), undefined, /* () */0);
        var element$9 = React.createElement("div", undefined, "Closure over ", termElement$1);
        var match$26 = TermRenderContext.parent(context);
        if (match$26 !== 112487626 && match$26 < 914889282) {
          return element$9;
        }
        return React.createElement(Term0.RootCard.make, {
                    header: header,
                    children: element$9
                  });
      }
    } else if (variant !== -1019462983) {
      if (variant >= -976970511) {
        if (TermUtils.Re.isBiomDataframeTerm(term)) {
          var match$27 = TermRenderContext.parent(context);
          if (match$27 !== 112487626) {
            if (match$27 >= 914889282) {
              return React.createElement(Biom.make, {
                          term: term
                        });
            } else {
              return React.createElement("em", undefined, header);
            }
          } else {
            return React.createElement(Biom.make, {
                        term: term
                      });
          }
        } else if (TermUtils.Re.isNewickTerm(term)) {
          var match$28 = TermRenderContext.parent(context);
          if (match$28 !== 112487626 && match$28 < 914889282) {
            return React.createElement("em", undefined, header);
          }
          return React.createElement(Term0.RootCard.make, {
                      header: header,
                      children: React.createElement(PhylogeneticTree.default, NewickTree.makeProps(term, /* () */0))
                    });
        } else {
          return scalar(term[1]);
        }
      } else {
        var jobId = term[1];
        var link = React.createElement(Link.make, {
              to: /* `Jobs */[
                826169846,
                /* `Id */[
                  16379,
                  jobId
                ]
              ],
              children: "#" + jobId
            });
        var match$29 = TermRenderContext.parent(context);
        if (match$29 !== 112487626 && match$29 < 914889282) {
          return React.createElement("span", undefined, "job ", link);
        }
        return React.createElement(Term0.RootCard.make, {
                    header: header,
                    children: link
                  });
      }
    } else {
      var label = term[1];
      var target = label[0] >= 699531161 ? label[1] : String(label[1]);
      var element$10 = "Projection of " + target;
      var match$30 = TermRenderContext.parent(context);
      if (match$30 !== 112487626 && match$30 < 914889282) {
        return element$10;
      }
      return React.createElement(Term0.RootCard.make, {
                  header: header,
                  children: element$10
                });
    }
  }
}

function render(apiClient, term, type_, context, key, param) {
  var tmp = {
    term: term,
    type_: type_,
    context: context,
    apiClient: apiClient
  };
  if (key !== undefined) {
    tmp.key = Caml_option.valFromOption(key);
  }
  return make(tmp);
}

var RootCard = Term0.RootCard;

export {
  RootCard ,
  bytesOfFile ,
  make ,
  render ,
  
}
/* Err Not a pure module */
