

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as $$String from "bs-platform/lib/es6/string.js";
import * as Bigdata from "./bigdata.bs.js";
import * as Hashtbl from "bs-platform/lib/es6/hashtbl.js";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Dataframe$SolvuuApi from "solvuu-api/./dataframe.bs.js";
import * as Dataframe_bs$SolvuuApi0 from "solvuu-api0/./dataframe_bs.bs.js";

var init = Belt_List.makeBy;

function find_map_exn(t, f) {
  return Belt_List.headExn(Belt_List.keepMap(t, f));
}

function find_a_dup(compare, l) {
  var sorted = Belt_List.sort(l, compare);
  var _l = sorted;
  while(true) {
    var l$1 = _l;
    if (l$1) {
      var match = l$1[1];
      if (match) {
        var hd1 = l$1[0];
        if (Curry._2(compare, hd1, match[0]) === 0) {
          return Caml_option.some(hd1);
        } else {
          _l = match[1];
          continue ;
        }
      } else {
        return ;
      }
    } else {
      return ;
    }
  };
}

function compare(cmp, _a, _b) {
  while(true) {
    var b = _b;
    var a = _a;
    if (a) {
      if (b) {
        var n = Curry._2(cmp, a[0], b[0]);
        if (n === 0) {
          _b = b[1];
          _a = a[1];
          continue ;
        } else {
          return n;
        }
      } else {
        return 1;
      }
    } else if (b) {
      return -1;
    } else {
      return 0;
    }
  };
}

var List = {
  init: init,
  find_map_exn: find_map_exn,
  find_a_dup: find_a_dup,
  compare: compare,
  length: Belt_List.length,
  size: Belt_List.size,
  head: Belt_List.head,
  headExn: Belt_List.headExn,
  tail: Belt_List.tail,
  tailExn: Belt_List.tailExn,
  add: Belt_List.add,
  get: Belt_List.get,
  getExn: Belt_List.getExn,
  make: Belt_List.make,
  makeByU: Belt_List.makeByU,
  makeBy: Belt_List.makeBy,
  shuffle: Belt_List.shuffle,
  drop: Belt_List.drop,
  take: Belt_List.take,
  splitAt: Belt_List.splitAt,
  concat: Belt_List.concat,
  concatMany: Belt_List.concatMany,
  reverseConcat: Belt_List.reverseConcat,
  flatten: Belt_List.flatten,
  mapU: Belt_List.mapU,
  map: Belt_List.map,
  zip: Belt_List.zip,
  zipByU: Belt_List.zipByU,
  zipBy: Belt_List.zipBy,
  mapWithIndexU: Belt_List.mapWithIndexU,
  mapWithIndex: Belt_List.mapWithIndex,
  fromArray: Belt_List.fromArray,
  toArray: Belt_List.toArray,
  reverse: Belt_List.reverse,
  mapReverseU: Belt_List.mapReverseU,
  mapReverse: Belt_List.mapReverse,
  forEachU: Belt_List.forEachU,
  forEach: Belt_List.forEach,
  forEachWithIndexU: Belt_List.forEachWithIndexU,
  forEachWithIndex: Belt_List.forEachWithIndex,
  reduceU: Belt_List.reduceU,
  reduce: Belt_List.reduce,
  reduceWithIndexU: Belt_List.reduceWithIndexU,
  reduceWithIndex: Belt_List.reduceWithIndex,
  reduceReverseU: Belt_List.reduceReverseU,
  reduceReverse: Belt_List.reduceReverse,
  mapReverse2U: Belt_List.mapReverse2U,
  mapReverse2: Belt_List.mapReverse2,
  forEach2U: Belt_List.forEach2U,
  forEach2: Belt_List.forEach2,
  reduce2U: Belt_List.reduce2U,
  reduce2: Belt_List.reduce2,
  reduceReverse2U: Belt_List.reduceReverse2U,
  reduceReverse2: Belt_List.reduceReverse2,
  everyU: Belt_List.everyU,
  every: Belt_List.every,
  someU: Belt_List.someU,
  some: Belt_List.some,
  every2U: Belt_List.every2U,
  every2: Belt_List.every2,
  some2U: Belt_List.some2U,
  some2: Belt_List.some2,
  cmpByLength: Belt_List.cmpByLength,
  cmpU: Belt_List.cmpU,
  cmp: Belt_List.cmp,
  eqU: Belt_List.eqU,
  eq: Belt_List.eq,
  hasU: Belt_List.hasU,
  has: Belt_List.has,
  getByU: Belt_List.getByU,
  getBy: Belt_List.getBy,
  keepU: Belt_List.keepU,
  keep: Belt_List.keep,
  filter: Belt_List.filter,
  keepWithIndexU: Belt_List.keepWithIndexU,
  keepWithIndex: Belt_List.keepWithIndex,
  filterWithIndex: Belt_List.filterWithIndex,
  keepMapU: Belt_List.keepMapU,
  keepMap: Belt_List.keepMap,
  partitionU: Belt_List.partitionU,
  partition: Belt_List.partition,
  unzip: Belt_List.unzip,
  getAssocU: Belt_List.getAssocU,
  getAssoc: Belt_List.getAssoc,
  hasAssocU: Belt_List.hasAssocU,
  hasAssoc: Belt_List.hasAssoc,
  removeAssocU: Belt_List.removeAssocU,
  removeAssoc: Belt_List.removeAssoc,
  setAssocU: Belt_List.setAssocU,
  setAssoc: Belt_List.setAssoc,
  sortU: Belt_List.sortU,
  sort: Belt_List.sort
};

var partial_arg = {
  compare: compare,
  find_a_dup: find_a_dup,
  find_map_exn: find_map_exn,
  init: init
};

var partial_arg$1 = Dataframe$SolvuuApi.Make;

var M = (function (param) {
      return partial_arg$1(partial_arg, param);
    })({
      Numeric: Bigdata.Numeric,
      Strings: Bigdata.Strings,
      foldi: Bigdata.foldi,
      get: Bigdata.get,
      num_dims: Bigdata.num_dims,
      nth_dim: Bigdata.nth_dim,
      dims: Bigdata.dims,
      int_exn: Bigdata.int_exn,
      float_exn: Bigdata.float_exn,
      string_exn: Bigdata.string_exn,
      length: Bigdata.length,
      slice_left: Bigdata.slice_left,
      sub_left: Bigdata.sub_left,
      pp_hum: Bigdata.pp_hum,
      set: Bigdata.set,
      cell_format: Bigdata.cell_format
    });

function compare$1(t, t$prime) {
  return compare($$String.compare, Belt_List.sort(t, $$String.compare), Belt_List.sort(t$prime, $$String.compare));
}

function hash(t) {
  var __x = Belt_List.sort(t, $$String.compare);
  return Hashtbl.hash($$String.concat("", __x));
}

function write_t(t) {
  return Dataframe_bs$SolvuuApi0.write_labels(Belt_List.sort(t, $$String.compare));
}

function singleton(s) {
  return /* :: */[
          s,
          /* [] */0
        ];
}

var T = {
  compare: compare$1,
  hash: hash,
  write_t: write_t,
  read_t: Dataframe_bs$SolvuuApi0.read_labels,
  singleton: singleton
};

var Labels = {
  T: T,
  compare: compare$1,
  hash: hash,
  write_t: write_t,
  read_t: Dataframe_bs$SolvuuApi0.read_labels,
  singleton: singleton
};

var $$let = M.Frames;

var write_t$1 = Dataframe_bs$SolvuuApi0.write_frames(Bigdata.Numeric.write_genarray, Bigdata.Numeric.write_genarray, Bigdata.Numeric.write_genarray, Bigdata.Numeric.write_genarray);

var read_t = Dataframe_bs$SolvuuApi0.read_frames(Bigdata.Numeric.read_genarray, Bigdata.Numeric.read_genarray, Bigdata.Numeric.read_genarray, Bigdata.Numeric.read_genarray);

var read_term = Dataframe_bs$SolvuuApi0.read_frames_term(Bigdata.Numeric.read_genarray, Bigdata.Numeric.read_genarray, Bigdata.Numeric.read_genarray, Bigdata.Numeric.read_genarray);

var write_term = Dataframe_bs$SolvuuApi0.write_frames_term(Bigdata.Numeric.write_genarray, Bigdata.Numeric.write_genarray, Bigdata.Numeric.write_genarray, Bigdata.Numeric.write_genarray);

var Frames_to_alist = $$let.to_alist;

var Frames_to_array = $$let.to_array;

var Frames_of_alist = $$let.of_alist;

var Frames_of_alist_exn = $$let.of_alist_exn;

var Frames_singleton = $$let.singleton;

var Frames_num_frames = $$let.num_frames;

var Frames_to_term = $$let.to_term;

var Frames_of_term = $$let.of_term;

var Frames_to_string_alist = $$let.to_string_alist;

var Frames_formats = $$let.formats;

var Frames = {
  Labels: Labels,
  to_alist: Frames_to_alist,
  to_array: Frames_to_array,
  of_alist: Frames_of_alist,
  of_alist_exn: Frames_of_alist_exn,
  singleton: Frames_singleton,
  num_frames: Frames_num_frames,
  to_term: Frames_to_term,
  of_term: Frames_of_term,
  to_string_alist: Frames_to_string_alist,
  formats: Frames_formats,
  write_t: write_t$1,
  read_t: read_t,
  read_term: read_term,
  write_term: write_term
};

var include = M.Indexed;

var read_t$1 = Dataframe_bs$SolvuuApi0.read_indexed(Bigdata.Numeric.read_genarray, Bigdata.Numeric.read_genarray, Bigdata.Numeric.read_genarray, Bigdata.Numeric.read_genarray);

var write_t$2 = Dataframe_bs$SolvuuApi0.write_indexed(Bigdata.Numeric.write_genarray, Bigdata.Numeric.write_genarray, Bigdata.Numeric.write_genarray, Bigdata.Numeric.write_genarray);

var read_term$1 = Dataframe_bs$SolvuuApi0.read_indexed_term(Bigdata.Numeric.read_genarray, Bigdata.Numeric.read_genarray, Bigdata.Numeric.read_genarray, Bigdata.Numeric.read_genarray);

var write_term$1 = Dataframe_bs$SolvuuApi0.write_indexed_term(Bigdata.Numeric.write_genarray, Bigdata.Numeric.write_genarray, Bigdata.Numeric.write_genarray, Bigdata.Numeric.write_genarray);

var Indexed_create = include.create;

var Indexed_num_index = include.num_index;

var Indexed_num_indexed = include.num_indexed;

var Indexed_num_data = include.num_data;

var Indexed_to_term = include.to_term;

var Indexed_of_term = include.of_term;

var Indexed_index = include.index;

var Indexed_group_by_frame_name = include.group_by_frame_name;

var Indexed_to_frames = include.to_frames;

var Indexed = {
  create: Indexed_create,
  num_index: Indexed_num_index,
  num_indexed: Indexed_num_indexed,
  num_data: Indexed_num_data,
  to_term: Indexed_to_term,
  of_term: Indexed_of_term,
  index: Indexed_index,
  group_by_frame_name: Indexed_group_by_frame_name,
  to_frames: Indexed_to_frames,
  read_t: read_t$1,
  write_t: write_t$2,
  read_term: read_term$1,
  write_term: write_term$1
};

var read_t$2 = Dataframe_bs$SolvuuApi0.read_term(Bigdata.Numeric.read_genarray, Bigdata.Numeric.read_genarray, Bigdata.Numeric.read_genarray, Bigdata.Numeric.read_genarray);

var write_t$3 = Dataframe_bs$SolvuuApi0.write_term(Bigdata.Numeric.write_genarray, Bigdata.Numeric.write_genarray, Bigdata.Numeric.write_genarray, Bigdata.Numeric.write_genarray);

var pp_hum = M.pp_hum;

var create_indexed = M.create_indexed;

var create_frames = M.create_frames;

export {
  List ,
  M ,
  Frames ,
  Indexed ,
  pp_hum ,
  create_indexed ,
  create_frames ,
  read_t$2 as read_t,
  write_t$3 as write_t,
  
}
/* M Not a pure module */
