import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import * as Card from "components/Card/Card.bs";
import * as Err from "components/Err.bs";
import PageHeader from "components/PageHeader";
import * as Button from "components/Button.bs";

import styles from "./style.css";

import IGVBrowser from "./IGVBrowser";

const CUSTOM = "Custom";
const presets = [
  {
    id: CUSTOM,
    path: "",
    indexPath: ""
  },
  {
    id: "wgEncodeCshlLongRnaSeqA549CellLongnonpolyaAlnRep2.bam",
    path:
      "/ashish/ENCODE-IGV/wgEncodeCshlLongRnaSeqA549CellLongnonpolyaAlnRep2.bam",
    indexPath:
      "/ashish/ENCODE-IGV/wgEncodeCshlLongRnaSeqA549CellLongnonpolyaAlnRep2.bam.bai"
  },
  {
    id: "wgEncodeRegDnaseClustered.bed",
    path: "/ashish/ENCODE-IGV/wgEncodeRegDnaseClustered.bed",
    indexPath: ""
  },
  {
    id: "wgEncodeDukeMapabilityRegionsExcludable.bed",
    path: "/ashish/ENCODE-IGV/wgEncodeDukeMapabilityRegionsExcludable.bed",
    indexPath: ""
  },
  {
    id: "ALL.wgs.integrated_phase1_v3.20101123.snps_indels_sv.sites.vcf",
    path:
      "/ashish/ENCODE-IGV/ALL.wgs.integrated_phase1_v3.20101123.snps_indels_sv.sites.vcf.gz",
    indexPath:
      "/ashish/ENCODE-IGV/ALL.wgs.integrated_phase1_v3.20101123.snps_indels_sv.sites.vcf.gz.tbi"
  },
  {
    id: "gencode.v19.annotation.sorted.gff3",
    path: "/ashish/ENCODE-IGV/gencode.v19.annotation.sorted.gff3",
    indexPath: "/ashish/ENCODE-IGV/gencode.v19.annotation.sorted.gff3.idx"
  },
  {
    id: "wgEncodeCshlLongRnaSeqA549CellPamExonsGencV7.gtf",
    path: "/ashish/ENCODE-IGV/wgEncodeCshlLongRnaSeqA549CellPamExonsGencV7.gtf",
    indexPath: ""
  },
  {
    id: "wgEncodeCshlLongRnaSeqA549CellPapMinusRawSigRep1.bigWig",
    path:
      "/ashish/ENCODE-IGV/wgEncodeCshlLongRnaSeqA549CellPapMinusRawSigRep1.bigWig",
    indexPath: ""
  }
];

class GenomeBrowser extends Component {
  static propTypes = {
    onInit: PropTypes.func.isRequired,
    onTeardown: PropTypes.func.isRequired,
    onFieldChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    initialTracks: PropTypes.arrayOf(
      PropTypes.shape({
        path: PropTypes.string.isRequired,
        indexPath: PropTypes.string
      })
    ).isRequired,
    form: PropTypes.shape({
      reference: PropTypes.shape({}).isRequired,
      tracks: PropTypes.arrayOf(PropTypes.shape({})).isRequired
    }).isRequired,
    data: PropTypes.shape({
      reference: PropTypes.shape({}),
      tracks: PropTypes.arrayOf(PropTypes.shape({}))
    }).isRequired,
    meta: PropTypes.shape({
      submit: PropTypes.shape({}).isRequired
    }).isRequired
  };

  static defaultProps = {
    initialTracks: [
      {
        name: "track",
        path: "",
        indexPath: ""
      }
    ]
  };

  state = {
    currentPresetId: CUSTOM
  };

  componentDidMount() {
    const reference = {
      path: "/ashish/ENCODE-IGV/male.hg19.fa",
      indexPath: "/ashish/ENCODE-IGV/male.hg19.fa.fai"
    };
    const tracks = this.props.initialTracks.map(track => {
      const preset = presets.find(p => p.path === track.path);
      return {
        ...track,
        indexPath: preset ? preset.indexPath : ""
      };
    });
    this.props.onInit(reference, tracks);
  }

  componentWillUnmount() {
    this.props.onTeardown();
  }

  handleFormSubmit = event => {
    event.preventDefault();
    this.props.onSubmit();
  };

  handleFieldChange = field => event => {
    this.props.onFieldChange(field, event.target.value);
  };

  handlePresetIdChange = event => {
    const preset = presets.find(p => p.id === event.target.value);
    this.setState({ currentPresetId: preset.id });
    this.props.onFieldChange(["tracks", 0, "name"], preset.id);
    this.props.onFieldChange(["tracks", 0, "path"], preset.path);
    this.props.onFieldChange(["tracks", 0, "indexPath"], preset.indexPath);
  };

  render() {
    const {
      form,
      data: { reference, tracks },
      meta: { submit }
    } = this.props;
    const { currentPresetId } = this.state;

    const showBrowser = reference && tracks;

    return (
      <div>
        <PageHeader>
          <PageHeader.Name>
            <FormattedMessage id="genomeBrowser.header" />
          </PageHeader.Name>
        </PageHeader>
        <Card.make>
          {submit.failure && (
            <Err.Operation.make message={submit.errors.message} />
          )}
          <form className={styles.form} onSubmit={this.handleFormSubmit}>
            <label>
              <span>Reference genome</span>
              <input
                value={form.reference.path}
                onChange={this.handleFieldChange("reference.path")}
                required
              />
            </label>
            <label>
              <span>Reference genome index</span>
              <input
                value={form.reference.indexPath}
                onChange={this.handleFieldChange("reference.indexPath")}
                required
              />
            </label>
            <h4>Track</h4>
            <select
              value={currentPresetId}
              onChange={this.handlePresetIdChange}
            >
              {presets.map(preset => (
                <option key={preset.id} value={preset.id}>
                  {preset.id}
                </option>
              ))}
            </select>
            {form.tracks.map((track, index) => (
              <div key={index}>
                <label>
                  <span>Track #{index}</span>
                  <input
                    value={track.path}
                    onChange={this.handleFieldChange(["tracks", index, "path"])}
                    required
                  />
                </label>
                <label>
                  <span>Track #{index} index</span>
                  <input
                    value={track.indexPath}
                    onChange={this.handleFieldChange([
                      "tracks",
                      index,
                      "indexPath"
                    ])}
                  />
                </label>
              </div>
            ))}
            <Button.make
              type={Button.Type.submit({ isSubmitting: submit.pending })}
            >
              Browse genome
            </Button.make>
          </form>
          {showBrowser && <IGVBrowser tracks={tracks} reference={reference} />}
        </Card.make>
      </div>
    );
  }
}

export default GenomeBrowser;
