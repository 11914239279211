

import * as Err from "../../../components/Err.bs.js";
import * as Jobs from "../Jobs.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as $$Event from "../../Event.bs.js";
import * as Hooks from "../../../Hooks.bs.js";
import * as Jobs0 from "../Jobs0.bs.js";
import * as React from "react";
import * as Button from "../../../components/Button.bs.js";
import * as Import from "../../../features/import.bs.js";
import * as JobForm from "../../../features/jobs/form/jobForm.bs.js";
import * as JobLogs from "./Logs/JobLogs.bs.js";
import * as Loading from "../../../components/Loading.bs.js";
import * as JobStatus from "../JobStatus/JobStatus.bs.js";
import * as JobDetails from "./JobDetails.bs.js";
import * as ReactIntl from "react-intl";
import * as StyleCss from "./style.css";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as JobDetails0 from "./JobDetails0.bs.js";
import * as ReactExtras from "../../../lib/solvuu/ReactExtras.bs.js";
import * as FullWidthCard from "../../Layout/AuthenticatedLayout/FullWidthCard.bs.js";
import * as Job$SolvuuApi from "solvuu-api/./job.bs.js";
import * as VerticalLayout from "../../../components/Layout/VerticalLayout.bs.js";
import * as FormattedRelative from "../../../components/FormattedRelative/FormattedRelative.bs.js";
import * as Time$SolvuuClient from "solvuu-client/./time.bs.js";
import * as Layout from "components/Layout";
import * as Lib from "react-bootstrap/lib";
import * as PageHeader from "components/PageHeader";

var styles = StyleCss.default;

function JobShow$JobEvents(Props) {
  var events = Props.events;
  var fetchStatus = Props.fetchStatus;
  var match = React.useReducer((function (state, action) {
          return {
                  maxEvents: state.maxEvents + 30 | 0
                };
        }), {
        maxEvents: 30
      });
  var send = match[1];
  var maxEvents = match[0].maxEvents;
  var hasMoreEvents = maxEvents < Curry._1(Import.List.length, events);
  var showMoreEventsButton = React.createElement(Button.make, Button.makeProps((function (param) {
              return Curry._1(send, /* ShowMoreEvents */0);
            }), undefined, /* `Basic */[
            -1058563442,
            Curry._4(Button.Type.Basic.make, styles.showMoreEventsButton, /* Text */936573133, undefined, /* () */0)
          ], "Show more events", /* () */0));
  var title = React.createElement(ReactIntl.FormattedMessage, {
        id: "jobs.show.events"
      });
  var table = React.createElement(React.Fragment, undefined, React.createElement(Lib.Table, {
            bordered: true,
            bsClass: styles.table,
            responsive: true,
            children: null
          }, React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("th", undefined, React.createElement(ReactIntl.FormattedMessage, {
                            id: "settings.log.eventId"
                          })), React.createElement("th", undefined, React.createElement(ReactIntl.FormattedMessage, {
                            id: "settings.log.timestamp"
                          })), React.createElement("th", undefined, React.createElement(ReactIntl.FormattedMessage, {
                            id: "settings.log.by"
                          })), React.createElement("th", {
                        colSpan: 2
                      }, React.createElement(ReactIntl.FormattedMessage, {
                            id: "settings.log.info"
                          })))), React.createElement("tbody", undefined, ReactExtras.listToElement(Curry._1(Import.List.reverse, Curry._2(Import.List.map, Belt_Option.getWithDefault(Curry._2(Import.List.take, events, maxEvents), events), (function ($$event) {
                              var id = $$event.id;
                              var timestamp = $$event.timestamp;
                              var user = $$event.user;
                              var info = $$event.info;
                              var infoToJson = Job$SolvuuApi.$$Event.Json.write_info;
                              return React.createElement($$Event.make, {
                                          id: id,
                                          timestamp: timestamp,
                                          user: user,
                                          info: info,
                                          infoToJson: infoToJson,
                                          key: id
                                        });
                            })))))), hasMoreEvents ? showMoreEventsButton : null);
  var contents;
  var exit = 0;
  if (events || typeof fetchStatus === "number") {
    exit = 1;
  } else {
    switch (fetchStatus.tag | 0) {
      case /* Pending */0 :
          contents = React.createElement(Loading.make, { });
          break;
      case /* Success */1 :
          exit = 1;
          break;
      case /* Failure */2 :
          contents = React.createElement(Err.Operation.make, { });
          break;
      
    }
  }
  if (exit === 1) {
    contents = typeof fetchStatus === "number" || fetchStatus.tag !== /* Failure */2 ? table : React.createElement(Layout.VerticalLayout, VerticalLayout.makeProps(/* x2 */26810, null, /* () */0), React.createElement(Layout.VerticalLayoutItem, {
                children: React.createElement(Err.Operation.make, { })
              }), React.createElement(Layout.VerticalLayoutItem, {
                children: table
              }));
  }
  return React.createElement(JobDetails0.JobDetailCategory.make, {
              title: title,
              children: contents
            });
}

var JobEvents = {
  make: JobShow$JobEvents
};

var Action = { };

var initialState_meta = {
  job: {
    fetch: Jobs0.Operations.Job.Fetch.initialState,
    eventsFetch: Jobs0.Operations.Job.EventsFetch.initialState,
    outputFetch: Jobs0.Operations.Job.OutputFetch.initialState,
    metricsFetch: Jobs0.Operations.Job.MetricsFetch.initialState
  }
};

var initialState = {
  job: undefined,
  currentTab: /* Summary */0,
  form: /* Hidden */1,
  events: /* [] */0,
  meta: initialState_meta
};

function reducer(state, action) {
  if (typeof action === "number") {
    var match = state.form;
    if (match) {
      return {
              job: state.job,
              currentTab: state.currentTab,
              form: /* Visible */0,
              events: state.events,
              meta: state.meta
            };
    } else {
      return {
              job: state.job,
              currentTab: state.currentTab,
              form: /* Hidden */1,
              events: state.events,
              meta: state.meta
            };
    }
  } else if (action[0] >= 1071179429) {
    return {
            job: state.job,
            currentTab: action[1],
            form: state.form,
            events: state.events,
            meta: state.meta
          };
  } else {
    var match$1 = action[1];
    var variant = match$1[0];
    if (variant >= 287802394) {
      if (variant >= 905840985) {
        var outputFetch = Curry._2(Jobs0.Operations.Job.OutputFetch.reducer, state.meta.job.outputFetch, match$1[1]);
        var init = state.meta.job;
        var job_fetch = init.fetch;
        var job_eventsFetch = init.eventsFetch;
        var job_metricsFetch = init.metricsFetch;
        var job = {
          fetch: job_fetch,
          eventsFetch: job_eventsFetch,
          outputFetch: outputFetch,
          metricsFetch: job_metricsFetch
        };
        var meta = {
          job: job
        };
        return {
                job: state.job,
                currentTab: state.currentTab,
                form: state.form,
                events: state.events,
                meta: meta
              };
      } else {
        var action$1 = match$1[1];
        var $$fetch = Curry._2(Jobs0.Operations.Job.Fetch.reducer, state.meta.job.fetch, action$1);
        var init$1 = state.meta.job;
        var jobMeta_eventsFetch = init$1.eventsFetch;
        var jobMeta_outputFetch = init$1.outputFetch;
        var jobMeta_metricsFetch = init$1.metricsFetch;
        var jobMeta = {
          fetch: $$fetch,
          eventsFetch: jobMeta_eventsFetch,
          outputFetch: jobMeta_outputFetch,
          metricsFetch: jobMeta_metricsFetch
        };
        var meta$1 = {
          job: jobMeta
        };
        var job$1 = typeof action$1 === "number" || action$1[0] !== 94326179 ? state.job : action$1[1].job;
        return {
                job: job$1,
                currentTab: state.currentTab,
                form: state.form,
                events: state.events,
                meta: meta$1
              };
      }
    } else if (variant >= 253588033) {
      var action$2 = match$1[1];
      var eventsFetch = Curry._2(Jobs0.Operations.Job.EventsFetch.reducer, state.meta.job.eventsFetch, action$2);
      var init$2 = state.meta.job;
      var job_fetch$1 = init$2.fetch;
      var job_outputFetch = init$2.outputFetch;
      var job_metricsFetch$1 = init$2.metricsFetch;
      var job$2 = {
        fetch: job_fetch$1,
        eventsFetch: eventsFetch,
        outputFetch: job_outputFetch,
        metricsFetch: job_metricsFetch$1
      };
      var meta$2 = {
        job: job$2
      };
      var events = typeof action$2 === "number" || action$2[0] !== 94326179 ? state.events : action$2[1].data;
      return {
              job: state.job,
              currentTab: state.currentTab,
              form: state.form,
              events: events,
              meta: meta$2
            };
    } else {
      var metricsFetch = Curry._2(Jobs0.Operations.Job.MetricsFetch.reducer, state.meta.job.metricsFetch, match$1[1]);
      var init$3 = state.meta.job;
      var job_fetch$2 = init$3.fetch;
      var job_eventsFetch$1 = init$3.eventsFetch;
      var job_outputFetch$1 = init$3.outputFetch;
      var job$3 = {
        fetch: job_fetch$2,
        eventsFetch: job_eventsFetch$1,
        outputFetch: job_outputFetch$1,
        metricsFetch: metricsFetch
      };
      var meta$3 = {
        job: job$3
      };
      return {
              job: state.job,
              currentTab: state.currentTab,
              form: state.form,
              events: state.events,
              meta: meta$3
            };
    }
  }
}

var State = {
  initialState: initialState,
  reducer: reducer
};

function fetchJob(thisId, apiClient) {
  var $$let = Job$SolvuuApi.Get_by_id.$$Request;
  var $$let$1 = Job$SolvuuApi.Get_by_id.$$Response;
  return Import.$great$great$pipe(Curry._3(apiClient.f, /* GET */3546230, {
                  entity: Job$SolvuuApi.Get_by_id.entity,
                  service: Job$SolvuuApi.Get_by_id.service,
                  $$Request: {
                    path: $$let.path,
                    body: $$let.body,
                    info: $$let.info
                  },
                  $$Response: {
                    ok_of_json: $$let$1.ok_of_json,
                    json_of_ok: $$let$1.json_of_ok,
                    bad_request_of_json: $$let$1.bad_request_of_json,
                    json_of_bad_request: $$let$1.json_of_bad_request,
                    not_found_of_json: $$let$1.not_found_of_json,
                    json_of_not_found: $$let$1.json_of_not_found
                  }
                }, {
                  id: thisId
                }), (function (response) {
                if (typeof response === "number") {
                  return /* `Error */[
                          106380200,
                          /* Unauthorized */324098644
                        ];
                } else {
                  var info = response[1].info;
                  var variant = info[0];
                  if (variant !== -99004555) {
                    if (variant >= 17692) {
                      return /* `Ok */[
                              17724,
                              info[1]
                            ];
                    } else {
                      return /* `Error */[
                              106380200,
                              /* Not_found */-358247754
                            ];
                    }
                  } else {
                    return /* `Error */[
                            106380200,
                            /* Bad_request */-99004555
                          ];
                  }
                }
              }));
}

function fetchJobWithDispatch(id, dispatch, apiClient) {
  var start = function (x) {
    return Curry._1(Jobs0.Operations.Job.Fetch.start, x);
  };
  var success = function (x) {
    return Curry._1(Jobs0.Operations.Job.Fetch.success, x);
  };
  var failure = function (x) {
    return Curry._1(Jobs0.Operations.Job.Fetch.failure, x);
  };
  Curry._1(dispatch, start(/* () */0));
  return Import.$great$great$pipe(fetchJob(id, apiClient), (function (response) {
                if (response[0] >= 106380200) {
                  return Curry._1(dispatch, failure(/* array */[response[1]]));
                } else {
                  return Curry._1(dispatch, success({
                                  jobId: id,
                                  job: response[1],
                                  timestamp: Date.now()
                                }));
                }
              }));
}

function fetchJobEvents(thisId, apiClient) {
  var $$let = Job$SolvuuApi.Get_events.$$Request;
  var $$let$1 = Job$SolvuuApi.Get_events.$$Response;
  return Import.$great$great$pipe(Curry._3(apiClient.f, /* GET */3546230, {
                  entity: Job$SolvuuApi.Get_events.entity,
                  service: Job$SolvuuApi.Get_events.service,
                  $$Request: {
                    path: $$let.path,
                    body: $$let.body,
                    info: $$let.info
                  },
                  $$Response: {
                    ok_of_json: $$let$1.ok_of_json,
                    json_of_ok: $$let$1.json_of_ok,
                    bad_request_of_json: $$let$1.bad_request_of_json,
                    json_of_bad_request: $$let$1.json_of_bad_request,
                    not_found_of_json: $$let$1.not_found_of_json,
                    json_of_not_found: $$let$1.json_of_not_found
                  }
                }, {
                  id: thisId
                }), (function (response) {
                if (typeof response === "number") {
                  return /* `Error */[
                          106380200,
                          /* Unauthorized */324098644
                        ];
                } else {
                  var info = response[1].info;
                  var variant = info[0];
                  if (variant !== -99004555) {
                    if (variant >= 17692) {
                      return /* `Ok */[
                              17724,
                              info[1]
                            ];
                    } else {
                      return /* `Error */[
                              106380200,
                              /* Not_found */-358247754
                            ];
                    }
                  } else {
                    return /* `Error */[
                            106380200,
                            /* Bad_request */-99004555
                          ];
                  }
                }
              }));
}

function fetchJobEventsWithDispatch(id, dispatch, apiClient) {
  var start = function (x) {
    return Curry._1(Jobs0.Operations.Job.EventsFetch.start, x);
  };
  var success = function (x) {
    return Curry._1(Jobs0.Operations.Job.EventsFetch.success, x);
  };
  var failure = function (x) {
    return Curry._1(Jobs0.Operations.Job.EventsFetch.failure, x);
  };
  Curry._1(dispatch, start(/* () */0));
  return Import.$great$great$pipe(fetchJobEvents(id, apiClient), (function (response) {
                if (response[0] >= 106380200) {
                  return Curry._1(dispatch, failure(/* array */[response[1]]));
                } else {
                  return Curry._1(dispatch, success({
                                  jobId: id,
                                  data: response[1]
                                }));
                }
              }));
}

function JobShow(Props) {
  var id = Props.id;
  var apiClient = Props.apiClient;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var match$1 = match[0];
  var meta = match$1.meta;
  var form = match$1.form;
  var currentTab = match$1.currentTab;
  var job = match$1.job;
  var fetchStatus = Curry._1(Jobs0.Operations.Job.Fetch.Status.convertFromOperation, meta.job.fetch);
  var fetchEventsStatus = Curry._1(Jobs0.Operations.Job.EventsFetch.Status.convertFromOperation, meta.job.eventsFetch);
  var refreshData = function (param) {
    Promise.all(/* array */[
          fetchJobWithDispatch(id, dispatch, apiClient),
          fetchJobEventsWithDispatch(id, dispatch, apiClient)
        ]);
    return /* () */0;
  };
  Hooks.useWithInterval(undefined, 5000, refreshData);
  var handleRefreshClick = React.useCallback((function (param) {
          return refreshData(/* () */0);
        }), ([]));
  React.useEffect((function () {
          refreshData(/* () */0);
          return ;
        }), ([]));
  var jobStatusSlot = job !== undefined ? React.createElement("div", {
          className: styles.jobStatus
        }, React.createElement(PageHeader.default.Slot, {
              children: React.createElement(JobStatus.make, {
                    job: job
                  })
            })) : null;
  var lastFetchStatusElement;
  var exit = 0;
  var timestamp;
  var exit$1 = 0;
  if (typeof fetchStatus === "number") {
    lastFetchStatusElement = null;
  } else {
    switch (fetchStatus.tag | 0) {
      case /* Success */1 :
          timestamp = fetchStatus[0].lastSuccessAt;
          exit = 1;
          break;
      case /* Pending */0 :
      case /* Failure */2 :
          exit$1 = 2;
          break;
      
    }
  }
  if (exit$1 === 2) {
    var match$2 = fetchStatus[0].lastSuccessAt;
    if (match$2 !== undefined) {
      timestamp = match$2;
      exit = 1;
    } else {
      lastFetchStatusElement = null;
    }
  }
  if (exit === 1) {
    lastFetchStatusElement = React.createElement("span", {
          className: styles.fetchStatus
        }, React.createElement(ReactIntl.FormattedMessage, {
              id: "jobs.show.lastSuccessAt"
            }), React.createElement(FormattedRelative.make, {
              value: Time$SolvuuClient.of_span_since_epoch(Curry._1(Time$SolvuuClient.Span.of_ms, timestamp)),
              updateInterval: /* `Seconds */[
                668917343,
                5
              ]
            }));
  }
  var refreshButtonDisabled;
  refreshButtonDisabled = typeof fetchStatus === "number" || fetchStatus.tag ? false : true;
  var refreshButtonElement = React.createElement("button", {
        className: styles.refreshButton,
        disabled: refreshButtonDisabled,
        type: "button",
        onClick: handleRefreshClick
      }, React.createElement(ReactIntl.FormattedMessage, {
            id: "jobs.actions.refresh"
          }));
  var fetchStatusSlot = React.createElement(PageHeader.default.Slot, {
        children: null
      }, lastFetchStatusElement, refreshButtonElement);
  var retryButtonSlot;
  if (job !== undefined) {
    var match$3 = job.status;
    var message = match$3 !== -113393609 && match$3 < 486291009 ? "Retry job" : "Clone job";
    var handleButtonClick = function (param) {
      return Curry._1(dispatch, /* ToggleForm */-769152232);
    };
    var button = React.createElement(Button.make, Button.makeProps(handleButtonClick, undefined, /* `Basic */[
              -1058563442,
              Curry._4(Button.Type.Basic.make, undefined, /* Secondary */67972948, undefined, /* () */0)
            ], message, /* () */0));
    retryButtonSlot = React.createElement(PageHeader.default.Slot, {
          children: button
        });
  } else {
    retryButtonSlot = null;
  }
  var pageHeaderElement = React.createElement(PageHeader.default, {
        children: null
      }, React.createElement(PageHeader.default.Name, {
            children: React.createElement(ReactIntl.FormattedMessage, {
                  id: "jobs.show.header",
                  values: {
                    jobId: id
                  }
                })
          }), jobStatusSlot, React.createElement(PageHeader.default.FillSpace, { }), fetchStatusSlot, retryButtonSlot);
  var fetchStatusElement;
  var exit$2 = 0;
  if (job !== undefined || typeof fetchStatus === "number" || fetchStatus.tag) {
    exit$2 = 1;
  } else {
    fetchStatusElement = React.createElement(Layout.VerticalLayoutItem, {
          children: React.createElement("div", {
                className: styles.loading
              }, React.createElement(Loading.make, { }))
        });
  }
  if (exit$2 === 1) {
    fetchStatusElement = typeof fetchStatus === "number" || fetchStatus.tag !== /* Failure */2 ? null : React.createElement(Layout.VerticalLayoutItem, {
            children: React.createElement(Err.Operation.make, { })
          });
  }
  var jobElement;
  if (job !== undefined) {
    var job$1 = job;
    var jobSummary = React.createElement(Layout.VerticalLayout, VerticalLayout.makeProps(/* x3 */26811, null, /* () */0), React.createElement(Layout.VerticalLayoutItem, {
              children: React.createElement(JobDetails.make, {
                    job: job$1,
                    apiClient: apiClient
                  })
            }), React.createElement(Layout.VerticalLayoutItem, {
              children: React.createElement(JobShow$JobEvents, {
                    events: match$1.events,
                    fetchStatus: fetchEventsStatus
                  })
            }));
    var jobLogs = React.createElement(JobLogs.make, {
          job: job$1,
          apiClient: apiClient
        });
    var tabContent = currentTab ? jobLogs : jobSummary;
    var tabClassName = function (tab) {
      var match = tab === currentTab;
      if (match) {
        return styles.tabActive;
      } else {
        return styles.tab;
      }
    };
    jobElement = React.createElement(Layout.VerticalLayoutItem, {
          children: null
        }, ReactExtras.withTestId("job", React.createElement("div", {
                  className: styles.tabs
                }, ReactExtras.withTestId("job-summary-tab", React.createElement("button", {
                          className: tabClassName(/* Summary */0),
                          onClick: (function (param) {
                              return Curry._1(dispatch, /* `ChangeTab */[
                                          1071179429,
                                          /* Summary */0
                                        ]);
                            })
                        }, "Summary")), ReactExtras.withTestId("job-logs-tab", React.createElement("button", {
                          className: tabClassName(/* Logs */1),
                          onClick: (function (param) {
                              return Curry._1(dispatch, /* `ChangeTab */[
                                          1071179429,
                                          /* Logs */1
                                        ]);
                            })
                        }, "Logs")))), tabContent);
  } else {
    jobElement = null;
  }
  var formElement;
  if (form || job === undefined) {
    formElement = null;
  } else {
    var initialForm = JobForm.fromSpec(job.spec);
    var fetchJobs = function (param) {
      return /* () */0;
    };
    formElement = React.createElement(Layout.VerticalLayoutItem, {
          children: React.createElement("div", {
                className: styles.formSection
              }, React.createElement(Jobs.Form.make, {
                    form: initialForm,
                    apiClient: apiClient,
                    fetchJobs: fetchJobs
                  }))
        });
  }
  var jobClassName = form && !(typeof fetchStatus === "number" || fetchStatus.tag !== /* Failure */2) ? styles.jobWithTopPadding : styles.job;
  return React.createElement("div", {
              className: styles.root
            }, pageHeaderElement, React.createElement(FullWidthCard.make, {
                  children: React.createElement("div", {
                        className: jobClassName
                      }, React.createElement(Layout.VerticalLayout, VerticalLayout.makeProps(/* x2 */26810, null, /* () */0), formElement, fetchStatusElement, jobElement))
                }));
}

var make = JobShow;

export {
  styles ,
  JobEvents ,
  Action ,
  State ,
  fetchJob ,
  fetchJobWithDispatch ,
  fetchJobEvents ,
  fetchJobEventsWithDispatch ,
  make ,
  
}
/* styles Not a pure module */
