

import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Input from "../../../components/FormControls/Input.bs.js";
import * as Int64 from "bs-platform/lib/es6/int64.js";
import * as React from "react";
import * as Import from "../../../features/import.bs.js";
import * as Caml_int64 from "bs-platform/lib/es6/caml_int64.js";
import * as Pervasives from "bs-platform/lib/es6/pervasives.js";
import * as StyleCss from "./style.css";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_format from "bs-platform/lib/es6/caml_format.js";
import * as ReactExtras from "../../../lib/solvuu/ReactExtras.bs.js";

var styles = StyleCss.default;

function byteUnitToString(byteUnit) {
  switch (byteUnit) {
    case /* Byte */0 :
        return "B";
    case /* Kilobyte */1 :
        return "KB";
    case /* Megabyte */2 :
        return "MB";
    case /* Gigabyte */3 :
        return "GB";
    
  }
}

function stringToByteUnit(str) {
  switch (str) {
    case "B" :
        return /* Byte */0;
    case "GB" :
        return /* Gigabyte */3;
    case "KB" :
        return /* Kilobyte */1;
    case "MB" :
        return /* Megabyte */2;
    default:
      return Pervasives.failwith("Invalid byte unit: " + str);
  }
}

function stringToInt(str) {
  var value;
  try {
    value = Caml_format.caml_int64_of_string(str);
  }
  catch (exn){
    return ;
  }
  return value;
}

var multiplier = /* int64 */{
  hi: 0,
  lo: 1024
};

var bytesInMegabyte = Caml_int64.mul(multiplier, multiplier);

var bytesInGigabyte = Caml_int64.mul(bytesInMegabyte, multiplier);

function bytesToUnits(bytes) {
  return /* :: */[
          /* tuple */[
            /* Byte */0,
            bytes
          ],
          /* :: */[
            /* tuple */[
              /* Kilobyte */1,
              Caml_int64.div(bytes, multiplier)
            ],
            /* :: */[
              /* tuple */[
                /* Megabyte */2,
                Caml_int64.div(bytes, bytesInMegabyte)
              ],
              /* :: */[
                /* tuple */[
                  /* Gigabyte */3,
                  Caml_int64.div(bytes, bytesInGigabyte)
                ],
                /* [] */0
              ]
            ]
          ]
        ];
}

function toBytes(amount, byteUnit) {
  switch (byteUnit) {
    case /* Byte */0 :
        return amount;
    case /* Kilobyte */1 :
        return Caml_int64.mul(amount, multiplier);
    case /* Megabyte */2 :
        return Caml_int64.mul(amount, bytesInMegabyte);
    case /* Gigabyte */3 :
        return Caml_int64.mul(amount, bytesInGigabyte);
    
  }
}

function toAmount(value, byteUnit) {
  var match = stringToInt(value);
  if (match !== undefined) {
    return Int64.to_string(Belt_Option.getExn(Curry._3(Import.List.getAssoc, bytesToUnits(match), byteUnit, (function (prim, prim$1) {
                          return prim === prim$1;
                        }))));
  } else {
    return value;
  }
}

var initialState = {
  byteUnit: /* Megabyte */2
};

function BytesInput(Props) {
  var name = Props.name;
  var label = Props.label;
  var value = Props.value;
  var onChange = Props.onChange;
  var required = Props.required;
  var validationState = Props.validationState;
  var match = React.useReducer((function (state, action) {
          if (action.tag) {
            return {
                    byteUnit: action[0]
                  };
          } else {
            return state;
          }
        }), initialState);
  var sendAction = match[1];
  var state = match[0];
  var send = function (action) {
    var newValue = function (amount, byteUnit) {
      var match = stringToInt(amount);
      if (match !== undefined) {
        return Int64.to_string(toBytes(match, byteUnit));
      } else {
        return amount;
      }
    };
    if (action.tag) {
      var amount = toAmount(value, state.byteUnit);
      Curry._1(onChange, newValue(amount, action[0]));
    } else {
      Curry._1(onChange, newValue(action[0], state.byteUnit));
    }
    return Curry._1(sendAction, action);
  };
  var handleInputChange = function (amount) {
    return send(/* ChangeAmount */Block.__(0, [amount]));
  };
  var handleSelectChange = function ($$event) {
    var value = ReactExtras.eventTargetValue($$event);
    return send(/* ChangeByteUnit */Block.__(1, [stringToByteUnit(value)]));
  };
  var label$1 = React.createElement(React.Fragment, undefined, label, required ? "*" : null);
  var byteUnit = state.byteUnit;
  var inputValue = toAmount(value, byteUnit);
  var selectOptions = ReactExtras.listToElement(Curry._2(Import.List.map, Curry._2(Import.List.map, /* :: */[
                /* Byte */0,
                /* :: */[
                  /* Kilobyte */1,
                  /* :: */[
                    /* Megabyte */2,
                    /* :: */[
                      /* Gigabyte */3,
                      /* [] */0
                    ]
                  ]
                ]
              ], byteUnitToString), (function (value) {
              return React.createElement("option", {
                          key: value,
                          value: value
                        }, value);
            })));
  var selectValue = byteUnitToString(byteUnit);
  return React.createElement("div", {
              className: styles.bytesInput
            }, React.createElement(Input.make, {
                  name: name,
                  label: label$1,
                  value: inputValue,
                  onChange: handleInputChange,
                  type_: "number",
                  validationState: validationState
                }), React.createElement("select", {
                  className: styles.bytesUnit,
                  value: selectValue,
                  onChange: handleSelectChange
                }, selectOptions));
}

function mapProps(props) {
  var name = props.name;
  var label = props.label;
  var value = props.value;
  var onChange = props.onChange;
  var required = props.required;
  var validationState = props.validationState;
  return {
          name: name,
          label: label,
          value: value,
          onChange: onChange,
          required: required,
          validationState: validationState
        };
}

var $$default = ReactExtras.jsComponent("Jobs.Form.BytesInput", BytesInput, mapProps);

var bytesInKilobyte = multiplier;

var make = BytesInput;

export {
  styles ,
  byteUnitToString ,
  stringToByteUnit ,
  stringToInt ,
  multiplier ,
  bytesInKilobyte ,
  bytesInMegabyte ,
  bytesInGigabyte ,
  bytesToUnits ,
  toBytes ,
  toAmount ,
  initialState ,
  make ,
  mapProps ,
  $$default ,
  $$default as default,
  
}
/* styles Not a pure module */
