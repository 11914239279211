

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Int64 from "bs-platform/lib/es6/int64.js";
import * as BlobJs from "./blobJs.bs.js";
import * as Import from "../../features/import.bs.js";
import * as Caml_int64 from "bs-platform/lib/es6/caml_int64.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Blob$SolvuuApi from "solvuu-api/./blob.bs.js";
import * as Js_null_undefined from "bs-platform/lib/es6/js_null_undefined.js";

function downloadable(blob) {
  var match = blob.state;
  return match[0] === -250904213;
}

function checksum(blob) {
  var match = blob.state;
  if (match[0] !== -250904213) {
    return ;
  } else {
    var match$1 = match[1];
    if (match$1[0] >= 425081257) {
      var match$2 = match$1[1].checksum;
      if (match$2 !== undefined) {
        return match$2[1];
      } else {
        return ;
      }
    } else {
      return match$1[1].checksum[1];
    }
  }
}

function sourceUri(blob) {
  return Blob$SolvuuApi.source(blob.state);
}

function totalBytesOfState(_state) {
  while(true) {
    var state = _state;
    var variant = state[0];
    if (variant >= 40653089) {
      if (variant >= 479410653) {
        _state = state[1][1].last_known_good_state;
        continue ;
      } else {
        return state[1].size_claimed;
      }
    } else if (variant >= -250904213) {
      var match = state[1];
      if (match[0] >= 425081257) {
        return match[1].size;
      } else {
        return match[1].size;
      }
    } else {
      return state[1].size_claimed;
    }
  };
}

function totalBytes(blob) {
  return totalBytesOfState(blob.state);
}

function uploadedPartsBytes(parts) {
  return Curry._3(Import.List.reduce, Curry._2(Import.List.keepMap, parts, (function (p) {
                    return Belt_Option.map(p.size, Caml_int64.of_int32);
                  })), Int64.zero, Caml_int64.add);
}

function uploadedBytesOfState(_state) {
  while(true) {
    var state = _state;
    var variant = state[0];
    if (variant >= 40653089) {
      if (variant >= 479410653) {
        _state = state[1][1].last_known_good_state;
        continue ;
      } else {
        return state[1].bytes_received;
      }
    } else if (variant >= -250904213) {
      var match = state[1];
      if (match[0] >= 425081257) {
        return match[1].size;
      } else {
        return match[1].size;
      }
    } else {
      return ;
    }
  };
}

function uploadedBytes(blob) {
  return uploadedBytesOfState(blob.state);
}

function isFinished(blob) {
  var match = blob.state;
  var variant = match[0];
  if (variant !== -250904213) {
    return variant >= 479410653;
  } else {
    return true;
  }
}

function isUploaded(blob) {
  var match = blob.state;
  return match[0] === -250904213;
}

function uploadError(blob) {
  var match = blob.state;
  return match[0] >= 479410653;
}

var Re = {
  downloadable: downloadable,
  checksum: checksum,
  sourceUri: sourceUri,
  totalBytesOfState: totalBytesOfState,
  totalBytes: totalBytes,
  uploadedPartsBytes: uploadedPartsBytes,
  uploadedBytesOfState: uploadedBytesOfState,
  uploadedBytes: uploadedBytes,
  isFinished: isFinished,
  isUploaded: isUploaded,
  uploadError: uploadError
};

function mapApiBlob(blobJs) {
  var blob = Curry._1(BlobJs.convertFromJs, blobJs);
  var isUploaded$1 = isUploaded(blob);
  var uploadError$1 = uploadError(blob);
  var uploadedBytes = Js_null_undefined.fromOption(Belt_Option.map(uploadedBytesOfState(blob.state), Caml_int64.to_float));
  var totalBytes = Js_null_undefined.fromOption(Belt_Option.map(totalBytesOfState(blob.state), Caml_int64.to_float));
  var originalName = Js_null_undefined.fromOption(Blob$SolvuuApi.source(blob.state));
  var checksum$1 = Js_null_undefined.fromOption(checksum(blob));
  return {
          id: blob.id,
          originalName: originalName,
          checksum: checksum$1,
          originalBlob: blobJs,
          meta: {
            totalBytes: totalBytes,
            uploadedBytes: uploadedBytes,
            isUploaded: isUploaded$1,
            isUploading: !isUploaded$1,
            uploadError: uploadError$1
          }
        };
}

function shouldSubscribeToUpdates(blobJs) {
  var blob = Curry._1(BlobJs.convertFromJs, blobJs);
  var match = Blob$SolvuuApi.source(blob.state);
  var match$1 = isFinished(blob);
  if (match !== undefined && !match$1) {
    return !match.startsWith("file:///");
  } else {
    return false;
  }
}

export {
  Re ,
  mapApiBlob ,
  shouldSubscribeToUpdates ,
  
}
/* BlobJs Not a pure module */
