

import * as Util from "aws-sdk/lib/util";

function encode(prim) {
  return Util.base64.encode(prim);
}

export {
  encode ,
  
}
/* aws-sdk/lib/util Not a pure module */
