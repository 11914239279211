

import * as Op2 from "../features/Op2.bs.js";
import * as Link from "./Link.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as $$Event from "./Event.bs.js";
import * as React from "react";
import * as Import from "../features/import.bs.js";
import * as Loading from "../components/Loading.bs.js";
import * as BlobUtils from "../lib/solvuu/blobUtils.bs.js";
import * as ReactIntl from "react-intl";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as ReactExtras from "../lib/solvuu/ReactExtras.bs.js";
import * as Blob$SolvuuApi from "solvuu-api/./blob.bs.js";
import * as FormattedBytes from "../components/FormattedBytes/FormattedBytes.bs.js";
import * as BlobEventsCss from "./BlobEvents.css";
import * as Lib from "react-bootstrap/lib";

var styles = BlobEventsCss.default;

function BlobEvents$Info(Props) {
  var info = Props.info;
  var match;
  if (typeof info === "number") {
    match = info >= -560624458 ? /* tuple */[
        "Upload validated",
        undefined
      ] : /* tuple */[
        "Upload completed",
        undefined
      ];
  } else {
    var variant = info[0];
    if (variant >= 301598491) {
      if (variant >= 816044828) {
        match = variant >= 925682913 ? /* tuple */[
            "Changed size to",
            Caml_option.some(React.createElement(FormattedBytes.make, {
                      value: info[1]
                    }))
          ] : /* tuple */[
            "Blob created",
            undefined
          ];
      } else if (variant >= 773206832) {
        var match$1 = info[1];
        match = /* tuple */[
          "Started job",
          Caml_option.some(React.createElement(Link.make, {
                    to: /* `Jobs */[
                      826169846,
                      /* `Id */[
                        16379,
                        match$1.job_id
                      ]
                    ],
                    children: match$1.name
                  }))
        ];
      } else {
        match = /* tuple */[
          "Changed storage location",
          undefined
        ];
      }
    } else if (variant >= 112490979) {
      match = /* tuple */[
        "Changed checksum to",
        Caml_option.some(info[1][1])
      ];
    } else {
      var match$2 = info[1][1];
      if (match$2[0] >= 466640422) {
        var formattedBytes = React.createElement(React.Fragment, undefined, React.createElement(FormattedBytes.make, {
                  value: BlobUtils.Re.uploadedPartsBytes(match$2[1].parts)
                }), " uploaded");
        match = /* tuple */[
          "Upload progress",
          Caml_option.some(formattedBytes)
        ];
      } else {
        match = /* tuple */[
          "Created S3 upload",
          undefined
        ];
      }
    }
  }
  var argument = match[1];
  var argumentElement = argument !== undefined ? React.createElement(React.Fragment, undefined, ": ", Caml_option.valFromOption(argument)) : null;
  return React.createElement(React.Fragment, undefined, match[0], argumentElement);
}

function BlobEvents(Props) {
  var blobId = Props.blobId;
  var apiClient = Props.apiClient;
  var match = Op2.useOp(undefined, blobId, (function (blobId) {
          var $$let = Blob$SolvuuApi.Get_events.$$Request;
          var $$let$1 = Blob$SolvuuApi.Get_events.$$Response;
          return Curry._3(apiClient.f, /* GET */3546230, {
                      entity: Blob$SolvuuApi.Get_events.entity,
                      service: Blob$SolvuuApi.Get_events.service,
                      $$Request: {
                        path: $$let.path,
                        body: $$let.body,
                        info: $$let.info
                      },
                      $$Response: {
                        ok_of_json: $$let$1.ok_of_json,
                        json_of_ok: $$let$1.json_of_ok,
                        bad_request_of_json: $$let$1.bad_request_of_json,
                        json_of_bad_request: $$let$1.json_of_bad_request,
                        not_found_of_json: $$let$1.not_found_of_json,
                        json_of_not_found: $$let$1.json_of_not_found
                      }
                    }, {
                      id: blobId
                    });
        }), /* () */0);
  var run = match.run;
  React.useEffect((function () {
          Curry._1(run, /* () */0);
          return ;
        }), /* array */[blobId]);
  var match$1 = Op2.State.status(match.state);
  if (typeof match$1 === "number") {
    return React.createElement(Loading.make, { });
  } else if (match$1[0] >= -783600662) {
    return React.createElement(Loading.make, { });
  } else {
    var match$2 = match$1[1];
    if (typeof match$2 === "number") {
      return React.createElement(Loading.$$Error.make, { });
    } else {
      var match$3 = match$2[1].info;
      if (match$3[0] >= 17692) {
        return React.createElement(Lib.Table, {
                    bordered: true,
                    bsClass: styles.table,
                    responsive: true,
                    children: null
                  }, React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("th", undefined, React.createElement(ReactIntl.FormattedMessage, {
                                    id: "settings.log.eventId"
                                  })), React.createElement("th", undefined, React.createElement(ReactIntl.FormattedMessage, {
                                    id: "settings.log.timestamp"
                                  })), React.createElement("th", undefined, React.createElement(ReactIntl.FormattedMessage, {
                                    id: "settings.log.by"
                                  })), React.createElement("th", {
                                colSpan: 2
                              }, React.createElement(ReactIntl.FormattedMessage, {
                                    id: "settings.log.info"
                                  })))), React.createElement("tbody", undefined, ReactExtras.listToElement(Curry._2(Import.List.map, match$3[1], (function ($$event) {
                                  return React.createElement($$Event.make, {
                                              id: $$event.id,
                                              timestamp: $$event.timestamp,
                                              user: $$event.user,
                                              info: $$event.info,
                                              infoToJson: Blob$SolvuuApi.$$Event.Json.write_info,
                                              infoComponent: BlobEvents$Info,
                                              key: $$event.id
                                            });
                                })))));
      } else {
        return React.createElement(Loading.$$Error.make, { });
      }
    }
  }
}

var make = BlobEvents;

export {
  make ,
  
}
/* styles Not a pure module */
