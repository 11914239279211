import React, { Component } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";

import * as NotFound from "containers/PageNotFound/PageNotFound.bs";
import * as DocumentTitle from "components/DocumentTitle.bs";
import { getFilteredPosts } from "features/blog";
import * as Paths from "Paths";

import FormattedDate from "./components/FormattedDate";
import LinkedinShareLink from "./components/LinkedinShareLink";
import TwitterShareLink from "./components/TwitterShareLink";
import * as Route from "Route.bs";
import * as Link from "containers/Link.bs";

import { BLOGPOSTS, IMAGES } from "./data";
import socialTwitter from "./social-twitter.svg";
import socialLinkedin from "./social-linkedin.svg";
import styles from "./style.css";

const COMPONENTS = {
  BLASTChart: () =>
    import("./components/BLASTChart" /* webpackChunkName:"blast-chart" */)
};

const blogpostPropType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  __content: PropTypes.string.isRequired
});

export class BlogPost extends Component {
  getPost = () => {
    const { slug } = this.props;
    return BLOGPOSTS.find(p => p.slug === slug);
  };

  componentDidMount() {
    const post = this.getPost();
    if (!post) return;

    const nodes = this.article.querySelectorAll("[data-solvuu-component]");
    nodes.forEach(node => {
      const componentName = node.dataset.solvuuComponent;
      COMPONENTS[componentName]().then(({ default: Component }) => {
        ReactDOM.render(<Component />, node);
      });
    });
  }

  render() {
    const post = this.getPost();
    if (!post) return <NotFound.make />;

    const { __content, title, slug } = post;
    const image = IMAGES[slug];

    const ogMeta = [{ name: "og:title", content: title }];
    if (image) {
      ogMeta.push({
        name: "og:image",
        content: window.location.origin + image
      });
    }

    return (
      <div className={styles.postRoot}>
        <DocumentTitle.make
          id="marketing.blog.post.documentTitle"
          values={{ title }}
        />
        <Helmet meta={ogMeta} />
        <div className={styles.postHeader}>
          <h1 className={styles.postTitle}>{post.title}</h1>
          <div className={styles.postDetails}>
            {post.author && (
              <span className={styles.postAuthor}>{post.author}</span>
            )}
            <FormattedDate value={post.date}>
              {date => <span className={styles.postDate}>{date}</span>}
            </FormattedDate>
          </div>
        </div>
        {image && <img src={image} alt="" className={styles.postImage} />}
        <div
          dangerouslySetInnerHTML={{ __html: __content }}
          className={styles.post}
          ref={el => (this.article = el)}
        />
        <div className={styles.postSocial}>
          <LinkedinShareLink>
            {href => (
              <a href={href} target="_blank" rel="noopener noreferrer">
                <img
                  src={socialLinkedin}
                  alt="Share on LinkedIn"
                  title="Share on LinkedIn"
                />
              </a>
            )}
          </LinkedinShareLink>
          <TwitterShareLink>
            {href => (
              <a href={href} target="_blank" rel="noopener noreferrer">
                <img
                  src={socialTwitter}
                  alt="Share on Twitter"
                  title="Share on Twitter"
                />
              </a>
            )}
          </TwitterShareLink>
        </div>
      </div>
    );
  }
}

class BlogpostListItem extends Component {
  static propTypes = {
    blogpost: blogpostPropType.isRequired
  };

  render() {
    const { blogpost } = this.props;
    const image = IMAGES[blogpost.slug];
    const to = Route.Js.blogPost(blogpost.slug);
    const href = Route.toUrlString(Route.Js.toUrl(to));
    const link = window.location.origin + href;

    return (
      <li className={styles.postListItem}>
        {image && (
          <img src={image} className={styles.postListItemImage} alt="" />
        )}
        <div className={styles.postListItemDetails}>
          {blogpost.author && (
            <span className={styles.postListItemAuthor}>{blogpost.author}</span>
          )}
          <FormattedDate value={blogpost.date}>
            {date => <span className={styles.postListItemDate}>{date}</span>}
          </FormattedDate>
          <div className={styles.postListItemSocial}>
            <LinkedinShareLink link={link}>
              {href => (
                <a href={href} target="_blank" rel="noopener noreferrer">
                  <img src={socialLinkedin} alt="LinkedIn" />
                </a>
              )}
            </LinkedinShareLink>
            <TwitterShareLink link={link}>
              {href => (
                <a href={href} target="_blank" rel="noopener noreferrer">
                  <img src={socialTwitter} alt="Twitter" />
                </a>
              )}
            </TwitterShareLink>
          </div>
        </div>
        <Link.make to={to} className={styles.postListItemTitle}>
          {blogpost.title}
        </Link.make>
        <div
          className={styles.postListItemContent}
          dangerouslySetInnerHTML={{ __html: blogpost.__content }}
        />
      </li>
    );
  }
}

class BlogpostList extends Component {
  static propTypes = {
    blogposts: PropTypes.arrayOf(blogpostPropType).isRequired
  };

  render() {
    const { blogposts } = this.props;

    return (
      <ul className={styles.postList}>
        {blogposts.map(blogpost => (
          <BlogpostListItem blogpost={blogpost} key={blogpost.slug} />
        ))}
      </ul>
    );
  }
}

export default class Blog extends Component {
  static propTypes = {
    category: PropTypes.string
  };

  getPosts = () => {
    const { category } = this.props;
    return getFilteredPosts(category, BLOGPOSTS);
  };

  render() {
    const blogposts = this.getPosts();
    const { category } = this.props;

    return (
      <div className={styles.root}>
        {category && (
          <DocumentTitle.make
            id="marketing.blog.category.documentTitle"
            values={{ category }}
          />
        )}
        <header className={styles.header}>
          <h1 className={styles.blogHeader}>Blog</h1>
        </header>
        <BlogpostList blogposts={blogposts} />
      </div>
    );
  }
}
