

import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Js_null_undefined from "bs-platform/lib/es6/js_null_undefined.js";

function Operation(M) {
  var initialState_errors = null;
  var initialState_lastSuccessAt = null;
  var initialState = {
    success: false,
    pending: false,
    failure: false,
    errors: initialState_errors,
    lastSuccessAt: initialState_lastSuccessAt
  };
  var start = function (x) {
    return Curry._1(M.make, /* `Start */[
                389604418,
                x
              ]);
  };
  var success = function (x) {
    return Curry._1(M.make, /* `Success */[
                94326179,
                x
              ]);
  };
  var failure = function (x) {
    return Curry._1(M.make, /* `Failure */[
                -464807894,
                x
              ]);
  };
  var reducer = function (state, action) {
    var variant = action[0];
    if (variant !== 94326179) {
      if (variant >= 389604418) {
        return {
                success: false,
                pending: true,
                failure: false,
                errors: null,
                lastSuccessAt: state.lastSuccessAt
              };
      } else {
        return {
                success: false,
                pending: false,
                failure: true,
                errors: Js_null_undefined.fromOption(action[1]),
                lastSuccessAt: state.lastSuccessAt
              };
      }
    } else {
      return {
              success: true,
              pending: false,
              failure: false,
              errors: null,
              lastSuccessAt: Date.now()
            };
    }
  };
  var convertFromOperation = function (prop) {
    var lastSuccessAt = prop.lastSuccessAt;
    var lastSuccessAt$1 = (lastSuccessAt == null) ? undefined : Caml_option.some(lastSuccessAt);
    var match = prop.pending;
    var match$1 = prop.success;
    var match$2 = prop.failure;
    var match$3 = prop.errors;
    if (match) {
      return /* Pending */Block.__(0, [{
                  lastSuccessAt: lastSuccessAt$1
                }]);
    } else if (match$1) {
      return /* Success */Block.__(1, [{
                  lastSuccessAt: Belt_Option.getExn(lastSuccessAt$1)
                }]);
    } else if (match$2) {
      var errors = (match$3 == null) ? /* array */[] : match$3;
      return /* Failure */Block.__(2, [{
                  lastSuccessAt: lastSuccessAt$1,
                  errors: errors
                }]);
    } else {
      return /* Initial */0;
    }
  };
  var Status = {
    convertFromOperation: convertFromOperation
  };
  return {
          initialState: initialState,
          start: start,
          success: success,
          failure: failure,
          reducer: reducer,
          Status: Status
        };
}

export {
  Operation ,
  
}
/* No side effect */
