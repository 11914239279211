

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Spread from "../../Spread.bs.js";
import * as Js_dict from "bs-platform/lib/es6/js_dict.js";
import * as Section from "../Section.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Classnames from "classnames";
import * as StyleCss from "./style.css";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as ReactSizeMe from "../../../lib/ReactSizeMe.bs.js";
import * as ReactSizeme from "react-sizeme";

var styles = StyleCss.default;

var chartsMP4 = require("./media/charts-720p.mp4");

var chartsWEBM = require("./media/charts-720p.webm");

var computePlaceholder = require("./media/compute.png");

var bam = require("./icons/BAM.svg");

var barChart = require("./icons/Bar Chart.svg");

var bed = require("./icons/BED.svg");

var biom = require("./icons/BIOM.svg");

var bubbleChart = require("./icons/Bubble chart.svg");

var circularPlot = require("./icons/Circular plots.svg");

var csv = require("./icons/CSV.svg");

var cytoscape = require("./icons/Cytoscape.svg");

var dendrogram = require("./icons/Dendograms.svg");

var donutChart = require("./icons/Donut partition.svg");

var fastq = require("./icons/FASTQ.svg");

var genomeBrowser = require("./icons/Genome browser.svg");

var gff3 = require("./icons/GFF3.svg");

var heatmap = require("./icons/heatmap.svg");

var kronaChart = require("./icons/Krona chart.svg");

var mmCIF = require("./icons/mmCIF.svg");

var networks = require("./icons/Networks.svg");

var parallelCoordinates = require("./icons/Parallel Plots.svg");

var pathwayMap = require("./icons/Pathway.svg");

var phylogeneticTree = require("./icons/Phylogenetic tree.svg");

var pieChart = require("./icons/Pie Chart.svg");

var principalCoordinates = require("./icons/Principal Coordinate.svg");

var sankeyDiagram = require("./icons/Sankey diagram.svg");

var sequenceMotifs = require("./icons/Sequence Motifs.svg");

var tSNE = require("./icons/t-SNE.svg");

var vcf = require("./icons/VCF.svg");

var vennDiagram = require("./icons/Venn Diagram.svg");

var volcanoPlot = require("./icons/volcano.svg");

var all = /* array */[
  {
    x: -700,
    y: 15,
    src: barChart
  },
  {
    x: -580,
    y: -110,
    src: volcanoPlot
  },
  {
    x: -540,
    y: 70,
    src: csv
  },
  {
    x: -405,
    y: -70,
    src: kronaChart
  },
  {
    x: -305,
    y: 70,
    src: heatmap
  },
  {
    x: -215,
    y: -90,
    src: gff3
  },
  {
    x: -75,
    y: 30,
    src: phylogeneticTree
  },
  {
    x: 20,
    y: -100,
    src: sankeyDiagram
  },
  {
    x: 120,
    y: 60,
    src: fastq
  },
  {
    x: 235,
    y: -85,
    src: circularPlot
  },
  {
    x: 335,
    y: 85,
    src: genomeBrowser
  },
  {
    x: 430,
    y: -50,
    src: bed
  },
  {
    x: 545,
    y: 60,
    src: tSNE
  },
  {
    x: 615,
    y: -90,
    src: principalCoordinates
  },
  {
    x: 725,
    y: 10,
    src: vcf
  },
  {
    x: 850,
    y: -100,
    src: dendrogram
  },
  {
    x: 895,
    y: 70,
    src: parallelCoordinates
  },
  {
    x: 1025,
    y: -65,
    src: biom
  },
  {
    x: 1125,
    y: 75,
    src: pieChart
  },
  {
    x: 1225,
    y: -95,
    src: pathwayMap
  },
  {
    x: 1355,
    y: 30,
    src: mmCIF
  },
  {
    x: 1455,
    y: -95,
    src: cytoscape
  },
  {
    x: 1555,
    y: 60,
    src: bubbleChart
  },
  {
    x: 1665,
    y: -80,
    src: networks
  },
  {
    x: 1775,
    y: 85,
    src: sequenceMotifs
  },
  {
    x: 1860,
    y: -55,
    src: bam
  },
  {
    x: 1975,
    y: 65,
    src: donutChart
  },
  {
    x: 2045,
    y: -90,
    src: vennDiagram
  }
];

function Product$Icon(Props) {
  var x = Props.x;
  var y = Props.y;
  var src = Props.src;
  var animate = Props.animate;
  var match = React.useState((function () {
          return 0;
        }));
  var setTicks = match[1];
  React.useLayoutEffect((function () {
          Curry._1(setTicks, (function (x) {
                  return x + 1;
                }));
          return ;
        }), /* array */[animate]);
  var handleTransitionEnd = function ($$event) {
    if ($$event.propertyName.includes("transform")) {
      return Curry._1(setTicks, (function (x) {
                    return x + 1;
                  }));
    } else {
      return 0;
    }
  };
  var existenceRange = (Math.abs(Belt_Array.getExn(all, 0).x) + Math.abs(Belt_Array.getExn(all, all.length - 1 | 0).x) + 100) / 2;
  var loop = function (_x) {
    while(true) {
      var x = _x;
      if (x < -existenceRange) {
        _x = x + 2 * existenceRange;
        continue ;
      } else {
        return x;
      }
    };
  };
  var x$1 = loop(x - match[0] * 100);
  var y$1 = y + Math.sin(x$1 / 100) * 10;
  var scale = 1 - Math.abs(Math.sin(x$1 / 100)) * 0.2;
  var match$1 = -1000 < x$1 && x$1 < 1000;
  var opacity = (
      match$1 ? 1.0 : 0.0
    ).toString();
  var transform = "translate(" + (String(x$1) + ("px, " + (String(y$1) + ("px) scale(" + (String(scale) + ")")))));
  var className = Classnames.default(styles.heroIcon, Js_dict.fromArray(/* array */[/* tuple */[
              styles.heroIconAnimated,
              animate
            ]]));
  return React.createElement("div", {
              className: className,
              style: {
                opacity: opacity,
                transform: transform
              },
              onTransitionEnd: handleTransitionEnd
            }, React.createElement("img", {
                  alt: "",
                  src: src
                }));
}

var Icon = {
  bam: bam,
  barChart: barChart,
  bed: bed,
  biom: biom,
  bubbleChart: bubbleChart,
  circularPlot: circularPlot,
  csv: csv,
  cytoscape: cytoscape,
  dendrogram: dendrogram,
  donutChart: donutChart,
  fastq: fastq,
  genomeBrowser: genomeBrowser,
  gff3: gff3,
  heatmap: heatmap,
  kronaChart: kronaChart,
  mmCIF: mmCIF,
  networks: networks,
  parallelCoordinates: parallelCoordinates,
  pathwayMap: pathwayMap,
  phylogeneticTree: phylogeneticTree,
  pieChart: pieChart,
  principalCoordinates: principalCoordinates,
  sankeyDiagram: sankeyDiagram,
  sequenceMotifs: sequenceMotifs,
  tSNE: tSNE,
  vcf: vcf,
  vennDiagram: vennDiagram,
  volcanoPlot: volcanoPlot,
  all: all,
  make: Product$Icon
};

function Product$HeroIcons(Props) {
  return React.createElement(ReactSizeme.SizeMe, ReactSizeMe.makeProps(true, undefined, 500, undefined, true, (function (param) {
                    var width = Belt_Option.getExn(Caml_option.null_to_opt(param.size.width));
                    var animate = width > 700;
                    return React.createElement("div", {
                                className: styles.heroIcons
                              }, React.createElement("div", {
                                    className: styles.heroIconAnchor
                                  }, Belt_Array.mapWithIndex(all, (function (idx, icon) {
                                          return React.createElement(Product$Icon, {
                                                      x: icon.x,
                                                      y: icon.y,
                                                      src: icon.src,
                                                      animate: animate,
                                                      key: String(idx)
                                                    });
                                        }))));
                  }), undefined, /* () */0));
}

var HeroIcons = {
  make: Product$HeroIcons
};

function Product(Props) {
  return React.createElement("div", {
              className: styles.root
            }, React.createElement("header", {
                  className: styles.hero
                }, React.createElement(Product$HeroIcons, { }), React.createElement("div", {
                      className: styles.heroHeaders
                    }, React.createElement("h1", {
                          className: styles.header
                        }, "Turn your data into knowledge."), React.createElement("h2", {
                          className: styles.subheader
                        }, "Explore your data with our large set of visual analytics tools.\n              Run all bioinformatics algorithms on our scalable infrastructure\n              built on AWS.")), React.createElement("div", {
                      className: styles.heroBackground
                    })), React.createElement(Section.make, {
                  children: React.createElement("div", {
                        className: styles.productCharts
                      }, React.createElement("div", {
                            className: styles.productAside
                          }, React.createElement("h3", {
                                className: styles.productHeader
                              }, "Solvuu Charts"), React.createElement("h4", {
                                className: styles.productSubheader
                              }, "Upload and interactively visualize data"), React.createElement("div", {
                                className: styles.productDescription
                              }, React.createElement("p", undefined, "We infer and automatically parse all file formats produced\n                  by sequencers and bioinformatics algorithms. Our platform\n                  interprets the data semantically and offers a large suite\n                  of visual analytics tools to interpret your data."), React.createElement("p", undefined, "Filter and transform the data exactly as you need,\n                  and visualizations update instantly. Our beautiful UI\n                  is simple to use and fully customizable when needed."))), React.createElement("div", {
                            className: styles.productAnimation
                          }, React.createElement(Spread.make, {
                                props: {
                                  playsInline: true
                                },
                                children: React.createElement("video", {
                                      className: styles.productAnimationMedia,
                                      autoPlay: true,
                                      loop: true,
                                      muted: true
                                    }, React.createElement("source", {
                                          src: chartsWEBM,
                                          type: "video/webm"
                                        }), React.createElement("source", {
                                          src: chartsMP4,
                                          type: "video/mp4"
                                        }))
                              })))
                }), React.createElement(Section.make, {
                  children: null
                }, React.createElement(Section.BackgroundGradient.make, { }), React.createElement("div", {
                      className: styles.productCompute
                    }, React.createElement("div", {
                          className: styles.productAside
                        }, React.createElement("h3", {
                              className: styles.productHeader
                            }, "Solvuu Compute"), React.createElement("h4", {
                              className: styles.productSubheader
                            }, "Upload data, run bioinformatics jobs at scale"), React.createElement("div", {
                              className: styles.productDescription
                            }, React.createElement("p", undefined, "We know bioinformatics algorithms. We make them all available\n                  at your fingertips. Run 1 job or thousands with\n                  equal ease. All your favorite open source tools are available\n                  already and you can securely run your proprietary scripts\n                  and algorithms on our scalable AWS infrastructure."), React.createElement("p", undefined, "Use our API or CLI for programmatic job submission. We\n                  optimize job execution times and provide detailed monitoring\n                  statistics for cost analysis. All output files are seamlessly\n                  integrated with our visual analytics tools."))), React.createElement("div", {
                          className: styles.productAnimation
                        }, React.createElement("img", {
                              className: styles.productAnimationMedia,
                              alt: "",
                              src: computePlaceholder
                            })))));
}

var make = Product;

export {
  styles ,
  chartsMP4 ,
  chartsWEBM ,
  computePlaceholder ,
  Icon ,
  HeroIcons ,
  make ,
  
}
/* styles Not a pure module */
