

import * as React from "react";
import * as StyleCss from "./style.css";

var styles = StyleCss.default;

function PageNotFound(Props) {
  return React.createElement("div", {
              className: styles.root
            }, React.createElement("p", undefined, "404: Page not found..."));
}

var make = PageNotFound;

export {
  styles ,
  make ,
  
}
/* styles Not a pure module */
