import { combineReducers } from "redux";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";

import { getAuthenticatedUser } from "features/aws.js";
import {
  createOperation,
  createReducer,
  cognitoToPromise
} from "features/utils";

const MFA_SETTINGS_FORM_CHANGE = "PROFILE/MFA_SETTINGS_FORM_CHANGE";
export const fetchOperation = createOperation("MFA_SETTINGS/FETCH");
export const updateOperation = createOperation("MFA_SETTINGS/UPDATE");

const COGNITO_PHONE_NUMBER_ATTRIBUTE = "phone_number";

export function fetchMFASettings() {
  const { start, success, failure } = fetchOperation.actionCreators;

  return async function(dispatch, getState) {
    dispatch(start());
    try {
      const cognitoUser = await getAuthenticatedUser();
      const mfaOptions = await cognitoToPromise(
        cognitoUser.getMFAOptions.bind(cognitoUser)
      );
      const userAttributes = await cognitoToPromise(
        cognitoUser.getUserAttributes.bind(cognitoUser)
      );

      const phoneAttribute = userAttributes.find(
        a => a.getName() === COGNITO_PHONE_NUMBER_ATTRIBUTE
      );
      const enabled = !!(mfaOptions && mfaOptions.length);
      const phoneNumber = phoneAttribute && phoneAttribute.getValue();

      dispatch(success({ enabled, phoneNumber }));
    } catch (err) {
      dispatch(failure(extractErrorsFromCognitoResponse(err)));
    }
  };
}

export function mfaFormChange(field, value) {
  return {
    type: MFA_SETTINGS_FORM_CHANGE,
    payload: { field, value }
  };
}

export function updateMFASettings() {
  const { start, success, failure } = updateOperation.actionCreators;

  return async function(dispatch, getState) {
    dispatch(start());
    try {
      const { form } = mfaSettingsSelector(getState());
      const cognitoUser = await getAuthenticatedUser();

      if (form.enabled) {
        // Update phone number
        const attributeList = [
          new CognitoUserAttribute({
            Name: COGNITO_PHONE_NUMBER_ATTRIBUTE,
            Value: sanitizePhoneNumber(form.phoneNumber)
          })
        ];
        await cognitoToPromise(
          cognitoUser.updateAttributes.bind(cognitoUser),
          attributeList
        );
      }

      const operation = form.enabled
        ? cognitoUser.enableMFA
        : cognitoUser.disableMFA;
      await cognitoToPromise(operation.bind(cognitoUser));

      dispatch(success(form));
    } catch (err) {
      dispatch(failure(extractErrorsFromCognitoResponse(err)));
    }
  };
}

// Internal

function sanitizePhoneNumber(phoneNumber) {
  if (!phoneNumber) return "";

  return phoneNumber.replace(/[- ()]/g, "");
}

function extractErrorsFromCognitoResponse(error) {
  let errors = [];
  if (error.message) {
    let message = error.message;
    if (message.includes("Invalid phone number format")) {
      message =
        "Invalid phone number format. Phone number needs to start with + country code, and contain no extra characters. Example: +12223334444";
    }
    errors.push({ message, field: null });
  }
  if (!errors.length) {
    errors.push({ id: "form.errors.unknown", field: null });
  }
  return errors;
}

export const initialState = {
  data: {
    enabled: false,
    phoneNumber: null
  },
  form: {
    enabled: false,
    phoneNumber: ""
  },
  meta: {
    fetch: fetchOperation.initialState,
    update: updateOperation.initialState
  }
};

export function mfaSettingsSelector(state) {
  const {
    profile: { mfaSettings }
  } = state;
  return mfaSettings;
}

function setMFAAttributesFromAction(state, action) {
  return {
    ...state,
    enabled: action.payload.enabled,
    phoneNumber: action.payload.phoneNumber
  };
}

function setFormFieldFromAction(state, action) {
  return {
    ...state,
    [action.payload.field]: action.payload.value
  };
}

const dataReducer = createReducer(initialState.data, {
  [fetchOperation.actionTypes.SUCCESS]: setMFAAttributesFromAction,
  [updateOperation.actionTypes.SUCCESS]: setMFAAttributesFromAction
});

const formReducer = createReducer(initialState.form, {
  [fetchOperation.actionTypes.SUCCESS]: setMFAAttributesFromAction,
  [updateOperation.actionTypes.SUCCESS]: setMFAAttributesFromAction,
  [MFA_SETTINGS_FORM_CHANGE]: setFormFieldFromAction
});

const metaReducer = combineReducers({
  fetch: fetchOperation.reducer,
  update: updateOperation.reducer
});

export default combineReducers({
  data: dataReducer,
  form: formReducer,
  meta: metaReducer
});
