

import * as IndexJs from "./index.js";

var make = IndexJs.default;

export {
  make ,
  
}
/* make Not a pure module */
