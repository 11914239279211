import { connect } from "react-redux";

import { chooseFilesForUpload } from "features/entities/create/blobs";

import FileDrop from "containers/FileDrop/FileDrop.js";

function mapDispatchToProps(dispatch, ownProps) {
  return {
    chooseFiles(files) {
      dispatch(chooseFilesForUpload(files, ownProps.entityPath));
    }
  };
}

export default connect(
  null,
  mapDispatchToProps
)(FileDrop);
