

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Import from "../features/import.bs.js";
import * as Std$Bolt from "bolt/./std.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Lang$SolvuuApi from "solvuu-api/./lang.bs.js";
import * as Root$SolvuuApi from "solvuu-api/./root.bs.js";
import * as Science$SolvuuApi from "solvuu-api/./science.bs.js";

function $$eval(f, t, simpleServerSideEval) {
  if (typeof t === "number" || t[0] !== 847309489) {
    return Promise.resolve(Curry._2(Lang$SolvuuApi.Term.eval_error, "Expected record", t));
  } else {
    var result = Curry._2(Root$SolvuuApi.Kernel_density.Arg.of_record, t[1], (function (columnName, data) {
            if (columnName !== undefined) {
              var columnName$1 = columnName;
              var label = /* `Named */[
                699531161,
                columnName$1
              ];
              var x = Curry._1(Lang$SolvuuApi.Var.Exn.of_string, "x");
              var data_001 = /* tuple */[
                /* `Var */[
                  4298439,
                  Root$SolvuuApi.map
                ],
                /* `Record */[
                  847309489,
                  /* :: */[
                    /* tuple */[
                      /* `Positional */[
                        -300665356,
                        1
                      ],
                      data
                    ],
                    /* :: */[
                      /* tuple */[
                        /* `Positional */[
                          -300665356,
                          2
                        ],
                        /* `Lambda */[
                          89433895,
                          /* tuple */[
                            Curry._1(Lang$SolvuuApi.Var.to_string, x),
                            /* `App */[
                              3257473,
                              /* tuple */[
                                /* `Project */[
                                  -1019462983,
                                  label
                                ],
                                /* `Var */[
                                  4298439,
                                  x
                                ]
                              ]
                            ]
                          ]
                        ]
                      ],
                      /* [] */0
                    ]
                  ]
                ]
              ];
              var data$1 = /* `App */[
                3257473,
                data_001
              ];
              return /* `Ok */[
                      17724,
                      Curry._1(simpleServerSideEval, data$1).then((function (data) {
                              if (typeof data === "number" || data[0] !== 848054398) {
                                return Promise.resolve(Curry._2(Lang$SolvuuApi.Term.eval_error, "Expected list", data));
                              } else {
                                var match = Curry._1(Std$Bolt.Res.all, Curry._2(Import.List.map, data[1], (function (x) {
                                            if (typeof x === "number") {
                                              return /* `Error */[
                                                      106380200,
                                                      /* Invalid */144245463
                                                    ];
                                            } else {
                                              var variant = x[0];
                                              if (variant !== 3654863 && variant !== 365180284) {
                                                return /* `Error */[
                                                        106380200,
                                                        /* Invalid */144245463
                                                      ];
                                              } else {
                                                return /* `Ok */[
                                                        17724,
                                                        x[1]
                                                      ];
                                              }
                                            }
                                          })));
                                if (match[0] >= 106380200) {
                                  return Promise.resolve(Curry._2(Lang$SolvuuApi.Term.eval_error, "Expected list of floats", data));
                                } else {
                                  var points = Curry._1(Import.List.fromArray, Belt_Array.map(Science$SolvuuApi.numericSeriesToKernelDensity(Curry._1(Import.List.toArray, match[1])), (function (point) {
                                              return /* `Record */[
                                                      847309489,
                                                      /* :: */[
                                                        /* tuple */[
                                                          Root$SolvuuApi.Kernel_density.T.Point.Const.Label.x,
                                                          /* `Float */[
                                                            365180284,
                                                            point.x
                                                          ]
                                                        ],
                                                        /* :: */[
                                                          /* tuple */[
                                                            Root$SolvuuApi.Kernel_density.T.Point.Const.Label.y,
                                                            /* `Float */[
                                                              365180284,
                                                              point.y
                                                            ]
                                                          ],
                                                          /* [] */0
                                                        ]
                                                      ]
                                                    ];
                                            })));
                                  var payload_001 = /* :: */[
                                    /* tuple */[
                                      Root$SolvuuApi.Kernel_density.T.Const.Label.points,
                                      /* `List */[
                                        848054398,
                                        points
                                      ]
                                    ],
                                    /* :: */[
                                      /* tuple */[
                                        Root$SolvuuApi.Kernel_density.T.Const.Label.xAxisLabel,
                                        /* `String */[
                                          -976970511,
                                          columnName$1
                                        ]
                                      ],
                                      /* [] */0
                                    ]
                                  ];
                                  var payload = /* `Record */[
                                    847309489,
                                    payload_001
                                  ];
                                  return Promise.resolve(/* `Viz */[
                                              4300231,
                                              /* `Record */[
                                                847309489,
                                                Curry._2(Lang$SolvuuApi.Record.tagged, f, payload)
                                              ]
                                            ]);
                                }
                              }
                            }))
                    ];
            } else {
              return /* `Error */[
                      106380200,
                      /* :: */[
                        /* tuple */[
                          Root$SolvuuApi.Kernel_density.Arg.Const.Label.columnName,
                          /* Invalid */144245463
                        ],
                        /* [] */0
                      ]
                    ];
            }
          }));
    var match = Curry._1(Std$Bolt.Res.join, result);
    if (match[0] >= 106380200) {
      var message = Curry._1(Import.List.toArray, Curry._2(Import.List.map, match[1], (function (param) {
                    var label = Curry._1(Lang$SolvuuApi.Label.to_string, param[0]);
                    var reason = param[1] >= 144245463 ? "invalid" : "not_found";
                    return "(" + (label + (", " + (reason + ") ")));
                  }))).join(" ");
      return Promise.resolve(Curry._2(Lang$SolvuuApi.Term.eval_error, message, t));
    } else {
      return match[1];
    }
  }
}

export {
  $$eval ,
  
}
/* Import Not a pure module */
