import * as ApiRoot from "solvuu-api/root.bs.js";
import * as TermJs from "lib/solvuu/termJs";
import * as TypeJs from "lib/solvuu/typeJs";

export function assertFormat(propValue) {
  try {
    ApiRoot.Fmt.t_of_json(propValue);
  } catch (e) {
    throw new Error(
      `Invalid value supplied as solvuu format: ${JSON.stringify(
        propValue
      )} (${e})`
    );
  }
}

function formatValidator(props, propName) {
  const propValue = props[propName];
  try {
    ApiRoot.Fmt.t_of_json(propValue);
  } catch (e) {
    return new Error(
      `Invalid value supplied to prop ${propName}: ${JSON.stringify(
        propValue
      )} (${e})`
    );
  }
}

function termValidator(props, propName) {
  const propValue = props[propName];
  try {
    TermJs.convertFromJs(propValue);
  } catch (e) {
    return new Error(
      `Invalid value supplied to prop ${propName}: ${JSON.stringify(
        propValue
      )} (${e})`
    );
  }
}

function typeValidator(props, propName) {
  const propValue = props[propName];
  try {
    TypeJs.convertFromJs(propValue);
  } catch (e) {
    return new Error(
      `Invalid value supplied to prop ${propName}: ${JSON.stringify(
        propValue
      )} (${e})`
    );
  }
}

function requiredValidator(innerValidator) {
  return function(props, propName, componentName) {
    const prop = props[propName];
    const propString = String(prop);
    if (prop === null || prop === undefined) {
      return new Error(
        `The '${propName}' prop is marked as required in '${componentName}', but its value is '${propString}'.`
      );
    } else {
      return innerValidator(props, propName, componentName);
    }
  };
}

const format = formatValidator;
format.isRequired = requiredValidator(formatValidator);

const term = termValidator;
term.isRequired = requiredValidator(termValidator);

const type = typeValidator;
type.isRequired = requiredValidator(typeValidator);

export default {
  format,
  term,
  type
};
