import React, { Component } from "react";

import TaxonomySummaryTable from "./TaxonomySummaryTable";
import styles from "./style.css";

export default class extends Component {
  render() {
    return (
      <details>
        <summary className={styles.summaryButton}>Show abundance data</summary>
        <div className={styles.summaryTable}>
          <TaxonomySummaryTable {...this.props} />
        </div>
      </details>
    );
  }
}
