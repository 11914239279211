

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Import from "../features/import.bs.js";
import * as $$String from "bs-platform/lib/es6/string.js";
import * as Std$Bolt from "bolt/./std.bs.js";
import * as Pervasives from "bs-platform/lib/es6/pervasives.js";
import * as Lang$SolvuuApi from "solvuu-api/./lang.bs.js";
import * as Root$SolvuuApi from "solvuu-api/./root.bs.js";
import * as Science$SolvuuApi from "solvuu-api/./science.bs.js";

function $$eval(f, t) {
  if (typeof t === "number" || t[0] !== 847309489) {
    return Promise.resolve(Curry._2(Lang$SolvuuApi.Term.eval_error, "Expected record", t));
  } else {
    var result = Curry._2(Root$SolvuuApi.Scatter_plot.Arg.of_record, t[1], (function (xAxisColumnName, yAxisColumnName, labelColumnName, data) {
            if (xAxisColumnName !== undefined) {
              if (yAxisColumnName !== undefined) {
                var yAxisColumnName$1 = yAxisColumnName;
                var xAxisColumnName$1 = xAxisColumnName;
                var numericArgType = Curry._1(Lang$SolvuuApi.Record.Param.Arg_type.create, (function (term) {
                        if (typeof term === "number") {
                          return /* `Ok */[
                                  17724,
                                  undefined
                                ];
                        } else {
                          var variant = term[0];
                          if (variant !== 3654863 && variant !== 365180284) {
                            if (variant !== 925978388) {
                              return /* `Ok */[
                                      17724,
                                      undefined
                                    ];
                            } else {
                              var match = term[1];
                              if (typeof match === "number") {
                                return /* `Ok */[
                                        17724,
                                        undefined
                                      ];
                              } else {
                                var variant$1 = match[0];
                                if (variant$1 !== 3654863 && variant$1 !== 365180284) {
                                  return /* `Ok */[
                                          17724,
                                          undefined
                                        ];
                                } else {
                                  return /* `Ok */[
                                          17724,
                                          match[1]
                                        ];
                                }
                              }
                            }
                          } else {
                            return /* `Ok */[
                                    17724,
                                    term[1]
                                  ];
                          }
                        }
                      }));
                var loop = function (_term) {
                  while(true) {
                    var term = _term;
                    if (typeof term === "number") {
                      return ;
                    } else {
                      var variant = term[0];
                      if (variant >= 365180285) {
                        if (variant !== 737456202) {
                          if (variant !== 748194550) {
                            if (variant !== 925978388) {
                              return ;
                            } else {
                              _term = term[1];
                              continue ;
                            }
                          } else {
                            return $$String.make(1, term[1]);
                          }
                        } else {
                          return Pervasives.string_of_bool(term[1]);
                        }
                      } else if (variant !== -976970511) {
                        if (variant !== 3654863) {
                          if (variant >= 365180284) {
                            return term[1].toString();
                          } else {
                            return ;
                          }
                        } else {
                          return String(term[1]);
                        }
                      } else {
                        return term[1];
                      }
                    }
                  };
                };
                var labelArgType = Curry._1(Lang$SolvuuApi.Record.Param.Arg_type.create, (function (term) {
                        return /* `Ok */[
                                17724,
                                loop(term)
                              ];
                      }));
                var xAxisSeries = Curry._3(Lang$SolvuuApi.Table.project_column, data, /* `Named */[
                      699531161,
                      xAxisColumnName$1
                    ], numericArgType);
                if (xAxisSeries[0] >= 106380200) {
                  return /* `Error */[
                          106380200,
                          /* :: */[
                            /* tuple */[
                              Root$SolvuuApi.Scatter_plot.Arg.Const.Label.data,
                              /* Invalid */144245463
                            ],
                            /* [] */0
                          ]
                        ];
                } else {
                  var xAxisSeries$1 = xAxisSeries[1];
                  var yAxisSeries = Curry._3(Lang$SolvuuApi.Table.project_column, data, /* `Named */[
                        699531161,
                        yAxisColumnName$1
                      ], numericArgType);
                  if (yAxisSeries[0] >= 106380200) {
                    return /* `Error */[
                            106380200,
                            /* :: */[
                              /* tuple */[
                                Root$SolvuuApi.Scatter_plot.Arg.Const.Label.data,
                                /* Invalid */144245463
                              ],
                              /* [] */0
                            ]
                          ];
                  } else {
                    var labelSeries = labelColumnName !== undefined ? Curry._3(Lang$SolvuuApi.Table.project_column, data, /* `Named */[
                            699531161,
                            labelColumnName
                          ], labelArgType) : /* `Ok */[
                        17724,
                        Curry._2(Import.List.make, Curry._1(Import.List.length, xAxisSeries$1), undefined)
                      ];
                    if (labelSeries[0] >= 106380200) {
                      return /* `Error */[
                              106380200,
                              /* :: */[
                                /* tuple */[
                                  Root$SolvuuApi.Scatter_plot.Arg.Const.Label.data,
                                  /* Invalid */144245463
                                ],
                                /* [] */0
                              ]
                            ];
                    } else {
                      var zip3 = function (a, b, c) {
                        return Curry._2(Import.List.map, Curry._2(Import.List.zip, Curry._2(Import.List.zip, a, b), c), (function (param) {
                                      var match = param[0];
                                      return /* tuple */[
                                              match[0],
                                              match[1],
                                              param[1]
                                            ];
                                    }));
                      };
                      var points = Curry._2(Import.List.keepMap, zip3(xAxisSeries$1, yAxisSeries[1], labelSeries[1]), (function (coordinatesWithLabel) {
                              var match = coordinatesWithLabel[0];
                              if (match !== undefined) {
                                var match$1 = coordinatesWithLabel[1];
                                if (match$1 !== undefined) {
                                  return /* tuple */[
                                          match,
                                          match$1,
                                          coordinatesWithLabel[2]
                                        ];
                                } else {
                                  return ;
                                }
                              }
                              
                            }));
                      var x = Curry._1(Import.List.toArray, Curry._2(Import.List.map, points, (function (param) {
                                  return param[0];
                                })));
                      var y = Curry._1(Import.List.toArray, Curry._2(Import.List.map, points, (function (param) {
                                  return param[1];
                                })));
                      var pearsonCorrelationCoefficient = Science$SolvuuApi.pearsonCorrelationCoefficient(x, y);
                      return /* `Ok */[
                              17724,
                              /* `Viz */[
                                4300231,
                                /* `Record */[
                                  847309489,
                                  Curry._2(Lang$SolvuuApi.Record.tagged, f, /* `Record */[
                                        847309489,
                                        /* :: */[
                                          /* tuple */[
                                            Root$SolvuuApi.Scatter_plot.T.Const.Label.points,
                                            /* `List */[
                                              848054398,
                                              Curry._2(Import.List.map, points, (function (param) {
                                                      return /* `Record */[
                                                              847309489,
                                                              Curry._3(Root$SolvuuApi.Scatter_plot.T.Point.to_record, param[0], param[1], param[2])
                                                            ];
                                                    }))
                                            ]
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              Root$SolvuuApi.Scatter_plot.T.Const.Label.xAxisName,
                                              /* `String */[
                                                -976970511,
                                                xAxisColumnName$1
                                              ]
                                            ],
                                            /* :: */[
                                              /* tuple */[
                                                Root$SolvuuApi.Scatter_plot.T.Const.Label.yAxisName,
                                                /* `String */[
                                                  -976970511,
                                                  yAxisColumnName$1
                                                ]
                                              ],
                                              /* :: */[
                                                /* tuple */[
                                                  Root$SolvuuApi.Scatter_plot.T.Const.Label.pearsonCorrelationCoefficient,
                                                  pearsonCorrelationCoefficient !== undefined ? /* `Some */[
                                                      925978388,
                                                      /* `Float */[
                                                        365180284,
                                                        pearsonCorrelationCoefficient
                                                      ]
                                                    ] : /* Null */870828711
                                                ],
                                                /* [] */0
                                              ]
                                            ]
                                          ]
                                        ]
                                      ])
                                ]
                              ]
                            ];
                    }
                  }
                }
              } else {
                return /* `Error */[
                        106380200,
                        /* :: */[
                          /* tuple */[
                            Root$SolvuuApi.Scatter_plot.Arg.Const.Label.yAxisColumnName,
                            /* Invalid */144245463
                          ],
                          /* [] */0
                        ]
                      ];
              }
            } else {
              return /* `Error */[
                      106380200,
                      /* :: */[
                        /* tuple */[
                          Root$SolvuuApi.Scatter_plot.Arg.Const.Label.xAxisColumnName,
                          /* Invalid */144245463
                        ],
                        /* [] */0
                      ]
                    ];
            }
          }));
    var match = Curry._1(Std$Bolt.Res.join, result);
    if (match[0] >= 106380200) {
      var message = Curry._1(Import.List.toArray, Curry._2(Import.List.map, match[1], (function (param) {
                    var label = Curry._1(Lang$SolvuuApi.Label.to_string, param[0]);
                    var reason = param[1] >= 144245463 ? "invalid" : "not_found";
                    return "(" + (label + (", " + (reason + ") ")));
                  }))).join(" ");
      return Promise.resolve(Curry._2(Lang$SolvuuApi.Term.eval_error, message, t));
    } else {
      return Promise.resolve(match[1]);
    }
  }
}

export {
  $$eval ,
  
}
/* Import Not a pure module */
