

import * as Js from "bs-platform/lib/es6/js.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Printf from "bs-platform/lib/es6/printf.js";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Webapi__Url from "bs-webapi/src/Webapi/Webapi__Url.js";

var SolvuuApi = { };

var SolvuuClient = { };

var SolvuuLang = { };

var Stdlib = {
  $$Array: /* alias */0,
  List: /* alias */0
};

function dropWhile(_lst, predicate) {
  while(true) {
    var lst = _lst;
    if (lst && Curry._1(predicate, lst[0])) {
      _lst = lst[1];
      continue ;
    } else {
      return lst;
    }
  };
}

function takeAtMost(lst, elems) {
  var match = Belt_List.take(lst, elems);
  if (match !== undefined) {
    return match;
  } else {
    return lst;
  }
}

var List = {
  length: Belt_List.length,
  size: Belt_List.size,
  head: Belt_List.head,
  headExn: Belt_List.headExn,
  tail: Belt_List.tail,
  tailExn: Belt_List.tailExn,
  add: Belt_List.add,
  get: Belt_List.get,
  getExn: Belt_List.getExn,
  make: Belt_List.make,
  makeByU: Belt_List.makeByU,
  makeBy: Belt_List.makeBy,
  shuffle: Belt_List.shuffle,
  drop: Belt_List.drop,
  take: Belt_List.take,
  splitAt: Belt_List.splitAt,
  concat: Belt_List.concat,
  concatMany: Belt_List.concatMany,
  reverseConcat: Belt_List.reverseConcat,
  flatten: Belt_List.flatten,
  mapU: Belt_List.mapU,
  map: Belt_List.map,
  zip: Belt_List.zip,
  zipByU: Belt_List.zipByU,
  zipBy: Belt_List.zipBy,
  mapWithIndexU: Belt_List.mapWithIndexU,
  mapWithIndex: Belt_List.mapWithIndex,
  fromArray: Belt_List.fromArray,
  toArray: Belt_List.toArray,
  reverse: Belt_List.reverse,
  mapReverseU: Belt_List.mapReverseU,
  mapReverse: Belt_List.mapReverse,
  forEachU: Belt_List.forEachU,
  forEach: Belt_List.forEach,
  forEachWithIndexU: Belt_List.forEachWithIndexU,
  forEachWithIndex: Belt_List.forEachWithIndex,
  reduceU: Belt_List.reduceU,
  reduce: Belt_List.reduce,
  reduceWithIndexU: Belt_List.reduceWithIndexU,
  reduceWithIndex: Belt_List.reduceWithIndex,
  reduceReverseU: Belt_List.reduceReverseU,
  reduceReverse: Belt_List.reduceReverse,
  mapReverse2U: Belt_List.mapReverse2U,
  mapReverse2: Belt_List.mapReverse2,
  forEach2U: Belt_List.forEach2U,
  forEach2: Belt_List.forEach2,
  reduce2U: Belt_List.reduce2U,
  reduce2: Belt_List.reduce2,
  reduceReverse2U: Belt_List.reduceReverse2U,
  reduceReverse2: Belt_List.reduceReverse2,
  everyU: Belt_List.everyU,
  every: Belt_List.every,
  someU: Belt_List.someU,
  some: Belt_List.some,
  every2U: Belt_List.every2U,
  every2: Belt_List.every2,
  some2U: Belt_List.some2U,
  some2: Belt_List.some2,
  cmpByLength: Belt_List.cmpByLength,
  cmpU: Belt_List.cmpU,
  cmp: Belt_List.cmp,
  eqU: Belt_List.eqU,
  eq: Belt_List.eq,
  hasU: Belt_List.hasU,
  has: Belt_List.has,
  getByU: Belt_List.getByU,
  getBy: Belt_List.getBy,
  keepU: Belt_List.keepU,
  keep: Belt_List.keep,
  filter: Belt_List.filter,
  keepWithIndexU: Belt_List.keepWithIndexU,
  keepWithIndex: Belt_List.keepWithIndex,
  filterWithIndex: Belt_List.filterWithIndex,
  keepMapU: Belt_List.keepMapU,
  keepMap: Belt_List.keepMap,
  partitionU: Belt_List.partitionU,
  partition: Belt_List.partition,
  unzip: Belt_List.unzip,
  getAssocU: Belt_List.getAssocU,
  getAssoc: Belt_List.getAssoc,
  hasAssocU: Belt_List.hasAssocU,
  hasAssoc: Belt_List.hasAssoc,
  removeAssocU: Belt_List.removeAssocU,
  removeAssoc: Belt_List.removeAssoc,
  setAssocU: Belt_List.setAssocU,
  setAssoc: Belt_List.setAssoc,
  sortU: Belt_List.sortU,
  sort: Belt_List.sort,
  dropWhile: dropWhile,
  takeAtMost: takeAtMost
};

var $$Location = { };

var $$Window = {
  $$Location: $$Location
};

var Js$1 = {
  MapperRt: Js.MapperRt,
  Internal: Js.Internal,
  Null: Js.Null,
  Undefined: Js.Undefined,
  Nullable: Js.Nullable,
  Null_undefined: Js.Null_undefined,
  Exn: Js.Exn,
  $$Array: Js.$$Array,
  Array2: Js.Array2,
  $$String: Js.$$String,
  String2: Js.String2,
  Re: Js.Re,
  $$Promise: Js.$$Promise,
  $$Date: Js.$$Date,
  Dict: Js.Dict,
  Global: Js.Global,
  Json: Js.Json,
  $$Math: Js.$$Math,
  Obj: Js.Obj,
  Typed_array: Js.Typed_array,
  TypedArray2: Js.TypedArray2,
  Types: Js.Types,
  Float: Js.Float,
  Int: Js.Int,
  $$Option: Js.$$Option,
  Result: Js.Result,
  List: Js.List,
  Vector: Js.Vector,
  Console: Js.Console,
  $$Window: $$Window
};

function useDebounce(value, delay) {
  var match = React.useState((function () {
          return value;
        }));
  var setDebounced = match[1];
  React.useEffect((function () {
          var id = setTimeout((function (param) {
                  return Curry._1(setDebounced, (function (param) {
                                return value;
                              }));
                }), delay);
          return (function (param) {
                    clearTimeout(id);
                    return /* () */0;
                  });
        }), /* tuple */[
        value,
        delay
      ]);
  return match[0];
}

function $$return(prim) {
  return Promise.resolve(prim);
}

function $great$great$eq(m, f) {
  return m.then(Curry.__1(f));
}

function $great$great$pipe(m, f) {
  return m.then((function (x) {
                return Promise.resolve(Curry._1(f, x));
              }));
}

var Js_promise_monad_infix = {
  $$return: $$return,
  $great$great$eq: $great$great$eq,
  $great$great$pipe: $great$great$pipe
};

var Prototype = { };

var match = Blob.prototype.slice;

var _sliceOrWebkitSlice = (match == null) ? Caml_option.nullable_to_opt(Blob.prototype.webkitSlice) : Caml_option.some(match);

var Slice = {
  _sliceOrWebkitSlice: _sliceOrWebkitSlice
};

var $$Blob = {
  Prototype: Prototype,
  Slice: Slice
};

var $$File = { };

var Url = Webapi__Url;

var Webapi = {
  $$Blob: $$Blob,
  $$File: $$File,
  Url: Url
};

var Api = /* alias */0;

var Bolt = /* alias */0;

var Client = /* alias */0;

var Lang = /* alias */0;

var $$Array = /* alias */0;

var $$Map = /* alias */0;

var $$Option = /* alias */0;

var $$Set = /* alias */0;

var sprintf = Printf.sprintf;

export {
  Api ,
  Bolt ,
  SolvuuApi ,
  Client ,
  SolvuuClient ,
  Lang ,
  SolvuuLang ,
  Stdlib ,
  $$Array ,
  List ,
  $$Map ,
  $$Option ,
  $$Set ,
  Js$1 as Js,
  sprintf ,
  useDebounce ,
  Js_promise_monad_infix ,
  $$return ,
  $great$great$eq ,
  $great$great$pipe ,
  Webapi ,
  
}
/* match Not a pure module */
