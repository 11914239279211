import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  BarChart as RechartsBarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip
} from "recharts";

import ResponsiveContainer from "components/ResponsiveContainer";
import { primary } from "lib/colors";

export default class BarChart extends Component {
  static propTypes = {
    bins: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        count: PropTypes.number.isRequired
      })
    ).isRequired,
    xAxisLabel: PropTypes.string.isRequired
  };

  render() {
    const data = this.props.bins;
    const xAxisLabel = this.props.xAxisLabel;

    const width = "100%";
    const height = 200;
    const margin = { bottom: 20 };

    return (
      <ResponsiveContainer width={width} height={height}>
        <RechartsBarChart data={data} margin={margin}>
          <XAxis
            dataKey="name"
            label={{ value: xAxisLabel, position: "bottom" }}
          />
          <YAxis
            dataKey="count"
            label={{
              value: "Count",
              angle: -90,
              position: "insideLeft",
              dy: 20
            }}
          />
          <Tooltip />
          <Bar dataKey="count" fill={primary} isAnimationActive={false} />
        </RechartsBarChart>
      </ResponsiveContainer>
    );
  }
}
