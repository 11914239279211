

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as ReactExtras from "../../lib/solvuu/ReactExtras.bs.js";
import * as FormControls from "./FormControls.bs.js";

function Checkbox(Props) {
  var label = Props.label;
  var name = Props.name;
  var value = Props.value;
  var onChange = Props.onChange;
  var handleChange = function ($$event) {
    return Curry._1(onChange, ReactExtras.eventTargetChecked($$event));
  };
  return React.createElement(FormControls.Checkbox.make, FormControls.Checkbox.makeProps(label, name, value, handleChange, undefined, /* () */0));
}

var make = Checkbox;

export {
  make ,
  
}
/* react Not a pure module */
