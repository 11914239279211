

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Import from "../features/import.bs.js";
import * as Std$Bolt from "bolt/./std.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as BarChartJs from "./BarChart.js";
import * as Root$SolvuuApi from "solvuu-api/./root.bs.js";
import * as Js_null_undefined from "bs-platform/lib/es6/js_null_undefined.js";

function layoutOfString(string) {
  switch (string) {
    case "Horizontal" :
        return /* `Ok */[
                17724,
                /* Horizontal */208994564
              ];
    case "Vertical" :
        return /* `Ok */[
                17724,
                /* Vertical */-1010337642
              ];
    default:
      return /* Invalid */144245463;
  }
}

function ofRecord(record) {
  return Curry._1(Std$Bolt.Res.join, Curry._2(Root$SolvuuApi.Bar_chart.T.of_record, record, (function (series, groups, barGrouping, layout) {
                    var series$1 = Curry._1(Import.List.toArray, Curry._2(Import.List.map, series, (function (param) {
                                return {
                                        name: param[0],
                                        data: Belt_Array.map(param[1], Js_null_undefined.fromOption)
                                      };
                              })));
                    var groups$1 = Curry._1(Import.List.toArray, Curry._2(Import.List.map, groups, (function (param) {
                                return {
                                        name: param[0],
                                        sum: param[1]
                                      };
                              })));
                    var match = layoutOfString(layout);
                    if (typeof match === "number") {
                      return /* `Error */[
                              106380200,
                              /* :: */[
                                /* tuple */[
                                  Root$SolvuuApi.Bar_chart.T.Const.Label.layout,
                                  /* Invalid */144245463
                                ],
                                /* [] */0
                              ]
                            ];
                    } else {
                      var stack;
                      switch (barGrouping) {
                        case "Grouped" :
                            stack = false;
                            break;
                        case "Stacked" :
                            stack = true;
                            break;
                        default:
                          stack = false;
                      }
                      return /* `Ok */[
                              17724,
                              {
                                series: series$1,
                                groups: groups$1,
                                stack: stack,
                                layout: match[1]
                              }
                            ];
                    }
                  })));
}

function ofTerm(term) {
  if (typeof term === "number" || term[0] !== 847309489) {
    return /* `Error */[
            106380200,
            /* Expected_record */-756637544
          ];
  } else {
    var res = ofRecord(term[1]);
    return Curry._2(Std$Bolt.Res.map_error, res, (function (e) {
                  return /* `Record */[
                          847309489,
                          e
                        ];
                }));
  }
}

var Props = {
  vertical: /* Vertical */-1010337642,
  horizontal: /* Horizontal */208994564,
  layoutOfString: layoutOfString,
  ofRecord: ofRecord,
  ofTerm: ofTerm
};

var make = BarChartJs.default;

export {
  Props ,
  make ,
  
}
/* make Not a pure module */
