

import * as Route from "../../../Route.bs.js";
import * as React from "react";
import * as DashboardJobsJs from "./DashboardJobs.js";

var make = DashboardJobsJs.default;

var Internal = {
  make: make
};

function DashboardJobs(Props) {
  var navigateToJobs = function (param) {
    return Route.push(/* `Jobs */[
                826169846,
                /* Main */858743897
              ]);
  };
  return React.createElement(make, {
              navigateToJobs: navigateToJobs
            });
}

var make$1 = DashboardJobs;

export {
  Internal ,
  make$1 as make,
  
}
/* make Not a pure module */
