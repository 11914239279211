


function makeProps(gap, children, param) {
  return {
          gap: gap >= 26811 ? (
              gap >= 26812 ? "4x" : "3x"
            ) : (
              gap >= 26810 ? "2x" : "1x"
            ),
          children: children
        };
}

var Item = { };

export {
  makeProps ,
  Item ,
  
}
/* No side effect */
