import React, { Component } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { findIndex } from "ramda";

import TaxonomyLevelSelect from "../TaxonomyLevelSelect";
import OptimizedSelect from "../OptimizedSelect";
import SankeyDiagramArea from "./SankeyDiagramArea";
import styles from "./style.css";

class SankeyNodeDetailOverlay extends Component {
  render() {
    const { chartData, selectedNodeId, onClickNode, onClose } = this.props;
    const { nodes, links } = chartData;

    const selectedNodeIndex = findIndex(n => n.id === selectedNodeId, nodes);
    const selectedNode = nodes[selectedNodeIndex];

    const taxonomy = selectedNode.label;
    const counts = selectedNode.value;
    const branches = links.filter(l => l.source === selectedNodeIndex).length;

    const formattedTaxonomy = <strong>{taxonomy}</strong>;
    const formattedCounts = (
      <strong>
        <FormattedNumber value={counts} />
      </strong>
    );
    const formattedBranches = (
      <strong>
        <FormattedNumber value={branches} />
      </strong>
    );
    return (
      <div className={styles.selectedOverlayBackground}>
        <div className={styles.selectedOverlay}>
          <span className={styles.closeOverlay} onClick={onClose}>
            &times; close
          </span>
          <p className={styles.overlayTitle}>
            <FormattedMessage
              id="biom.taxonomicAnalysis.sankeyDiagram.overlay.title"
              values={{
                taxonomy: formattedTaxonomy,
                counts: formattedCounts,
                branches: formattedBranches
              }}
            />
          </p>
          <SankeyDiagramArea
            data={chartData}
            onClickNode={onClickNode}
            key={selectedNodeId}
          />
        </div>
      </div>
    );
  }
}

class SankeyDiagram extends Component {
  state = {
    selectedNodeId: null
  };

  sankeyNodeToOption = node => {
    return {
      value: node.id,
      label: node.label
    };
  };

  handleTaxonomyLevelChange = taxonomyLevel => {
    this.props.setChartControls({ taxonomyLevel });
  };

  handleNodeClick = id => {
    this.setState({ selectedNodeId: id });
  };

  handleOverlayClose = () => {
    this.setState({ selectedNodeId: null });
  };

  handleSelectedNodeChange = option => {
    const selectedNodeId = option ? option.value : null;
    this.setState({ selectedNodeId });
  };

  render() {
    const {
      getDataForTaxonomyLevel,
      getDataForNode,
      chartControls
    } = this.props;
    const { selectedNodeId } = this.state;

    const minTaxonomyLevel = 2;
    const taxonomyLevel = Math.max(
      chartControls.taxonomyLevel,
      minTaxonomyLevel
    );
    const { chartData } = getDataForTaxonomyLevel(taxonomyLevel);

    const getDataForIndividualNode = nodeId =>
      getDataForNode(taxonomyLevel, nodeId);

    const formattedLinks = (
      <strong>
        <FormattedNumber value={chartData.links.length} />
      </strong>
    );
    const formattedNodes = (
      <strong>
        <FormattedNumber value={chartData.nodes.length} />
      </strong>
    );

    return (
      <div className={styles.root}>
        <div className={styles.controls}>
          <TaxonomyLevelSelect
            value={taxonomyLevel}
            onChange={this.handleTaxonomyLevelChange}
            minTaxonomyLevel={minTaxonomyLevel}
          />
          <OptimizedSelect
            value={selectedNodeId}
            onChange={this.handleSelectedNodeChange}
            values={chartData.nodes}
            valueToOption={this.sankeyNodeToOption}
            className={styles.nodeSelect}
            clearable
            placeholder={
              <FormattedMessage id="biom.taxonomicAnalysis.sankeyDiagram.search.placeholder" />
            }
          />
        </div>
        <div className={styles.controls}>
          <p className={styles.summary}>
            <FormattedMessage
              id="biom.taxonomicAnalysis.sankeyDiagram.summary"
              values={{
                links: formattedLinks,
                nodes: formattedNodes
              }}
            />
          </p>
        </div>
        <SankeyDiagramArea
          data={chartData}
          onClickNode={this.handleNodeClick}
        />
        {selectedNodeId !== null && (
          <SankeyNodeDetailOverlay
            selectedNodeId={selectedNodeId}
            chartData={getDataForIndividualNode(selectedNodeId).chartData}
            onClickNode={this.handleNodeClick}
            onClose={this.handleOverlayClose}
          />
        )}
      </div>
    );
  }
}

export default SankeyDiagram;
