

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Import from "../features/import.bs.js";
import * as Std$Bolt from "bolt/./std.bs.js";
import * as Lang$SolvuuApi from "solvuu-api/./lang.bs.js";
import * as Root$SolvuuApi from "solvuu-api/./root.bs.js";
import * as Science$SolvuuApi from "solvuu-api/./science.bs.js";

function $$eval(f, t) {
  if (typeof t === "number" || t[0] !== 847309489) {
    return Promise.resolve(Curry._2(Lang$SolvuuApi.Term.eval_error, "Expected record", t));
  } else {
    var resTerm = Curry._1(Std$Bolt.Res.join, Curry._2(Root$SolvuuApi.Violin_plot.Arg.of_record, t[1], (function (columnNames, data) {
                var numeric = Curry._1(Lang$SolvuuApi.Record.Param.Arg_type.create, (function (param) {
                        if (typeof param === "number") {
                          return /* `Ok */[
                                  17724,
                                  undefined
                                ];
                        } else {
                          var variant = param[0];
                          if (variant !== 3654863 && variant !== 365180284) {
                            if (variant !== 925978388) {
                              return /* `Ok */[
                                      17724,
                                      undefined
                                    ];
                            } else {
                              var match = param[1];
                              if (typeof match === "number") {
                                return /* `Ok */[
                                        17724,
                                        undefined
                                      ];
                              } else {
                                var variant$1 = match[0];
                                if (variant$1 !== 3654863 && variant$1 !== 365180284) {
                                  return /* `Ok */[
                                          17724,
                                          undefined
                                        ];
                                } else {
                                  return /* `Ok */[
                                          17724,
                                          match[1]
                                        ];
                                }
                              }
                            }
                          } else {
                            return /* `Ok */[
                                    17724,
                                    param[1]
                                  ];
                          }
                        }
                      }));
                return Curry._2(Std$Bolt.Res.map, Curry._2(Std$Bolt.Res.map_error, Curry._1(Std$Bolt.Res.all, Curry._2(Import.List.map, columnNames, (function (name) {
                                          return Curry._2(Std$Bolt.Res.map, Curry._2(Std$Bolt.Res.map, Curry._3(Lang$SolvuuApi.Table.project_column, data, /* `Named */[
                                                              699531161,
                                                              name
                                                            ], numeric), (function (x) {
                                                            return Curry._2(Import.List.keepMap, x, (function (x) {
                                                                          return x;
                                                                        }));
                                                          })), (function (numericSeries) {
                                                        return /* tuple */[
                                                                numericSeries,
                                                                name
                                                              ];
                                                      }));
                                        }))), (function (param) {
                                  return /* :: */[
                                          /* tuple */[
                                            Root$SolvuuApi.Violin_plot.Arg.Const.Label.data,
                                            /* Invalid */144245463
                                          ],
                                          /* [] */0
                                        ];
                                })), (function (numericSeriesWithLabels) {
                              var series = Curry._2(Import.List.map, numericSeriesWithLabels, (function (param) {
                                      var densities = Curry._2(Import.List.map, Curry._1(Import.List.fromArray, Science$SolvuuApi.numericSeriesToKernelDensity(Curry._1(Import.List.toArray, param[0]))), (function (dataPoint) {
                                              return Curry._2(Root$SolvuuApi.Violin_plot.T.Density_point.to_record, dataPoint.x, dataPoint.y);
                                            }));
                                      return /* `Record */[
                                              847309489,
                                              Curry._2(Root$SolvuuApi.Violin_plot.T.Series.to_record, param[1], densities)
                                            ];
                                    }));
                              return /* `Viz */[
                                      4300231,
                                      /* `Record */[
                                        847309489,
                                        Curry._2(Lang$SolvuuApi.Record.tagged, f, /* `Record */[
                                              847309489,
                                              /* :: */[
                                                /* tuple */[
                                                  Root$SolvuuApi.Violin_plot.T.Const.Label.series,
                                                  /* `List */[
                                                    848054398,
                                                    series
                                                  ]
                                                ],
                                                /* [] */0
                                              ]
                                            ])
                                      ]
                                    ];
                            }));
              })));
    var tmp;
    if (resTerm[0] >= 106380200) {
      var message = Curry._1(Import.List.toArray, Curry._2(Import.List.map, resTerm[1], (function (param) {
                    var label = Curry._1(Lang$SolvuuApi.Label.to_string, param[0]);
                    var reason = param[1] >= 144245463 ? "invalid" : "not_found";
                    return "(" + (label + (", " + (reason + ") ")));
                  }))).join(" ");
      tmp = Curry._2(Lang$SolvuuApi.Term.eval_error, message, t);
    } else {
      tmp = resTerm[1];
    }
    return Promise.resolve(tmp);
  }
}

export {
  $$eval ,
  
}
/* Import Not a pure module */
