

import * as Err from "../components/Err.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Import from "../features/import.bs.js";
import * as Record from "../containers/Entities/Record/Record.bs.js";
import * as Select from "../components/Select/Select.bs.js";
import * as Loading from "../components/Loading.bs.js";
import * as FormGroup from "../components/FormGroup/FormGroup.bs.js";
import * as WithTestId from "../containers/WithTestId.bs.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as MultiSelect from "../components/Select/MultiSelect.bs.js";
import * as ReactExtras from "../lib/solvuu/ReactExtras.bs.js";
import * as ReactSelect from "../lib/ReactSelect.bs.js";
import * as Lang$SolvuuApi from "solvuu-api/./lang.bs.js";
import * as Root$SolvuuApi from "solvuu-api/./root.bs.js";
import * as VerticalLayout from "../components/Layout/VerticalLayout.bs.js";
import * as TermRenderContext from "../lang/TermRenderContext.bs.js";
import * as Layout from "components/Layout";
import * as TableDonutChartCss from "./TableDonutChart.css";
import * as TableVisualizationCard from "./TableVisualizationCard.bs.js";

var styles = TableDonutChartCss.default;

function TableDonutChart$Loading(Props) {
  return React.createElement("div", {
              className: styles.centered
            }, React.createElement(Loading.make, { }));
}

function TableDonutChart$ColumnsSelect(Props) {
  var columnNames = Props.columnNames;
  var columns = Props.columns;
  var dispatch = Props.dispatch;
  var columnsSelectOptions = Curry._2(Import.List.map, Curry._2(Import.List.keep, columns, (function (param) {
              return Curry._1(Root$SolvuuApi.Donut_chart.Arg.dataTypeAvailable, param[1]);
            })), (function (param) {
          var name = param[0];
          return ReactSelect.$$Option.make(name, name);
        }));
  var handleColumnsSelect = function (options) {
    return Curry._1(dispatch, /* `UpdateArg */[
                615103405,
                /* tuple */[
                  Root$SolvuuApi.Donut_chart.Arg.Const.Label.columnNames,
                  (function (param) {
                      if (options !== undefined) {
                        return /* `List */[
                                848054398,
                                Curry._2(Import.List.map, options, (function (param) {
                                        return /* `String */[
                                                -976970511,
                                                param.value
                                              ];
                                      }))
                              ];
                      } else {
                        return /* `List */[
                                848054398,
                                /* [] */0
                              ];
                      }
                    })
                ]
              ]);
  };
  return React.createElement(Layout.VerticalLayoutItem, {
              children: React.createElement(FormGroup.$$Element.make, {
                    name: "columns",
                    children: null
                  }, React.createElement(MultiSelect.make, {
                        label: React.createElement(ReactIntl.FormattedMessage, {
                              id: "table.donutChart.columnsSelect.label"
                            }),
                        value: columnNames,
                        onChange: handleColumnsSelect,
                        options: columnsSelectOptions,
                        removeSelected: true,
                        closeOnSelect: false,
                        testId: "donut-chart-columns-select"
                      }), React.createElement(FormGroup.FieldInfoElement.make, {
                        children: React.createElement(ReactIntl.FormattedMessage, {
                              id: "table.donutChart.columnsSelect.info"
                            })
                      }))
            });
}

function TableDonutChart$LabelColumnSelect(Props) {
  var labelColumnName = Props.labelColumnName;
  var columns = Props.columns;
  var dispatch = Props.dispatch;
  var columnSelectOptions = Curry._2(Import.List.map, Curry._2(Import.List.keep, columns, (function (param) {
              return Curry._1(Root$SolvuuApi.Donut_chart.Arg.labelDataTypeAvailable, param[1]);
            })), (function (param) {
          var name = param[0];
          return ReactSelect.$$Option.make(name, name);
        }));
  var handleColumnSelect = function (opt) {
    return Curry._1(dispatch, /* `UpdateArg */[
                615103405,
                /* tuple */[
                  Root$SolvuuApi.Donut_chart.Arg.Const.Label.labelColumnName,
                  (function (param) {
                      var match = Belt_Option.map(opt, (function (param) {
                              return param.value;
                            }));
                      if (match !== undefined) {
                        return /* `Some */[
                                925978388,
                                /* `String */[
                                  -976970511,
                                  match
                                ]
                              ];
                      } else {
                        return /* Null */870828711;
                      }
                    })
                ]
              ]);
  };
  return React.createElement(Layout.VerticalLayoutItem, {
              children: React.createElement(FormGroup.$$Element.make, {
                    name: "labelColumn",
                    children: React.createElement(WithTestId.make, {
                          id: "donut-chart-label-column-select",
                          children: React.createElement(Select.make, {
                                label: React.createElement(ReactIntl.FormattedMessage, {
                                      id: "table.donutChart.labelColumnSelect.label"
                                    }),
                                value: Belt_Option.getWithDefault(labelColumnName, ""),
                                onChange: handleColumnSelect,
                                options: columnSelectOptions,
                                clearable: true
                              })
                        })
                  })
            });
}

function TableDonutChart$State(Props) {
  var onClose = Props.onClose;
  var state = Props.state;
  var dataType = Props.dataType;
  var dispatch = Props.dispatch;
  var graphic = state.graphic;
  if (typeof dataType !== "number" && dataType[0] === 848054398) {
    var match = dataType[1];
    if (typeof match !== "number" && match[0] === 847309489) {
      var columns = match[1][0];
      return React.createElement(Record.ParsedRecord.make, {
                  children: Curry._2(Root$SolvuuApi.Donut_chart.Arg.of_record, state.arg, (function (columnNames, labelColumnName, param) {
                          var title = React.createElement(ReactIntl.FormattedMessage, {
                                id: "table.donutChart.header"
                              });
                          var controls = React.createElement(Layout.VerticalLayout, VerticalLayout.makeProps(/* x4 */26812, null, /* () */0), React.createElement(TableDonutChart$ColumnsSelect, {
                                    columnNames: columnNames,
                                    columns: columns,
                                    dispatch: dispatch
                                  }), React.createElement(TableDonutChart$LabelColumnSelect, {
                                    labelColumnName: labelColumnName,
                                    columns: columns,
                                    dispatch: dispatch
                                  }));
                          return React.createElement(TableVisualizationCard.make, {
                                      icon: /* DonutChart */-642279940,
                                      title: title,
                                      controls: controls,
                                      onClose: onClose,
                                      children: ReactExtras.withTestId("entity-static-table-donut-chart", React.createElement("div", {
                                                className: styles.root
                                              }, graphic))
                                    });
                        }))
                });
    }
    
  }
  return React.createElement(Err.Operation.make, {
              message: "Expected table"
            });
}

function TableDonutChart(Props) {
  var onClose = Props.onClose;
  var data = Props.data;
  var $$eval = Props.eval;
  var apiClient = Props.apiClient;
  var renderTerm = Props.renderTerm;
  var term = data[0];
  var state_arg = Curry._3(Lang$SolvuuApi.Record.update, Root$SolvuuApi.Donut_chart.Arg.Const.Default.arg, Root$SolvuuApi.Donut_chart.Arg.Const.Label.data, (function (param) {
          return term;
        }));
  var state = {
    arg: state_arg,
    graphic: null
  };
  var match = React.useReducer((function (param, param$1) {
          var apiClient$1 = apiClient;
          var renderTerm$1 = renderTerm;
          var param$2 = param;
          var $$event = param$1;
          var state = param$2[0];
          var evalEffect = function (arg) {
            return /* `Evaluate */[
                    -620618631,
                    /* `App */[
                      3257473,
                      /* tuple */[
                        /* `Var */[
                          4298439,
                          Root$SolvuuApi.donut_chart
                        ],
                        /* `Record */[
                          847309489,
                          arg
                        ]
                      ]
                    ]
                  ];
          };
          if ($$event[0] >= 615103405) {
            var match = $$event[1];
            var state_arg = Curry._3(Lang$SolvuuApi.Record.update, state.arg, match[0], match[1]);
            var state_graphic = React.createElement(TableDonutChart$Loading, { });
            var state$1 = {
              arg: state_arg,
              graphic: state_graphic
            };
            var effect = evalEffect(state_arg);
            return /* tuple */[
                    state$1,
                    effect
                  ];
          } else {
            var graphic = Curry._6(renderTerm$1, apiClient$1, $$event[1], /* Type_not_available */-883458824, TermRenderContext.root, undefined, /* () */0);
            var state_arg$1 = state.arg;
            var state$2 = {
              arg: state_arg$1,
              graphic: graphic
            };
            return /* tuple */[
                    state$2,
                    undefined
                  ];
          }
        }), /* tuple */[
        state,
        undefined
      ]);
  var dispatch = match[1];
  var match$1 = match[0];
  var effect = match$1[1];
  React.useEffect((function () {
          if (effect !== undefined) {
            Curry._1($$eval, effect[1]).then((function (term) {
                    Curry._1(dispatch, /* `Graphic */[
                          -1051164024,
                          term
                        ]);
                    return Promise.resolve(/* () */0);
                  }));
            return ;
          }
          
        }), /* array */[effect]);
  return React.createElement(TableDonutChart$State, {
              onClose: onClose,
              state: match$1[0],
              dataType: data[1],
              dispatch: dispatch
            });
}

var make = TableDonutChart;

export {
  make ,
  
}
/* styles Not a pure module */
