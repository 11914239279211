

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Pervasives from "bs-platform/lib/es6/pervasives.js";

function isRunning(x) {
  if (typeof x === "number") {
    return false;
  } else {
    return x[0] === 951752159;
  }
}

function run(x) {
  if (x[0] !== -783600662) {
    return ;
  } else {
    return x[1].run;
  }
}

function reset(x) {
  if (x[0] >= -783600662) {
    return ;
  } else {
    return x[1].reset;
  }
}

function reduce(state, $$event) {
  var variant = state[0];
  if (variant !== -783600662) {
    if (variant >= 951752159 && typeof $$event !== "number") {
      if ($$event[0] >= 759635106) {
        return /* `Output */[
                -1055554783,
                $$event[1]
              ];
      } else {
        return /* `Input */[
                -783600662,
                $$event[1]
              ];
      }
    } else {
      return Pervasives.failwith("BUG");
    }
  } else if (typeof $$event === "number") {
    return /* `Running */[
            951752159,
            state[1]
          ];
  } else {
    return Pervasives.failwith("BUG");
  }
}

function useOp(init, f) {
  var match = React.useState((function () {
          return /* `Input */[
                  -783600662,
                  init
                ];
        }));
  var setState = match[1];
  var state = match[0];
  React.useEffect((function () {
          if (state[0] >= 951752159) {
            Curry._1(f, state[1]).then((function (e) {
                    Curry._1(setState, (function (param) {
                            return reduce(state, e);
                          }));
                    return Promise.resolve(e);
                  }));
          }
          return ;
        }), /* array */[state]);
  var run = function (param) {
    return Curry._1(setState, (function (state) {
                  return reduce(state, /* Run */4103979);
                }));
  };
  var setInput = function (f) {
    return Curry._1(setState, (function (state) {
                  if (typeof state === "number" || state[0] !== -783600662) {
                    return state;
                  } else {
                    return /* `Input */[
                            -783600662,
                            Curry._1(f, state[1])
                          ];
                  }
                }));
  };
  var reset = function (param) {
    return Curry._1(setState, (function (param) {
                  return /* `Input */[
                          -783600662,
                          init
                        ];
                }));
  };
  var variant = state[0];
  if (variant !== -783600662) {
    if (variant >= 951752159) {
      return /* `Running */[
              951752159,
              state[1]
            ];
    } else {
      return /* `Output */[
              -1055554783,
              {
                data: state[1],
                reset: reset
              }
            ];
    }
  } else {
    return /* `Input */[
            -783600662,
            {
              data: state[1],
              setInput: setInput,
              run: run
            }
          ];
  }
}

function useWait(dep, init, f) {
  var state = useOp(init, (function (input) {
          if (dep[0] >= -783600662) {
            return Promise.resolve(/* `Retry */[
                        -101284024,
                        input
                      ]);
          } else {
            return Curry._2(f, dep[1].data, input);
          }
        }));
  if (state[0] >= -783600662) {
    return state;
  } else {
    var match = state[1];
    var reset = match.reset;
    var reset$1 = function (param) {
      if (dep[0] >= -783600662) {
        return /* () */0;
      } else {
        Curry._1(reset, /* () */0);
        return Curry._1(dep[1].reset, /* () */0);
      }
    };
    return /* `Output */[
            -1055554783,
            {
              data: match.data,
              reset: reset$1
            }
          ];
  }
}

export {
  isRunning ,
  run ,
  reset ,
  useOp ,
  useWait ,
  
}
/* react Not a pure module */
