

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Import from "../features/import.bs.js";
import * as Record from "../containers/Entities/Record/Record.bs.js";
import * as Select from "../components/Select/Select.bs.js";
import * as Loading from "../components/Loading.bs.js";
import * as FormGroup from "../components/FormGroup/FormGroup.bs.js";
import * as WithTestId from "../containers/WithTestId.bs.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as ReactExtras from "../lib/solvuu/ReactExtras.bs.js";
import * as ReactSelect from "../lib/ReactSelect.bs.js";
import * as Lang$SolvuuApi from "solvuu-api/./lang.bs.js";
import * as Root$SolvuuApi from "solvuu-api/./root.bs.js";
import * as TermRenderContext from "../lang/TermRenderContext.bs.js";
import * as TableHistogramCss from "./TableHistogram.css";
import * as TableVisualizationCard from "./TableVisualizationCard.bs.js";

var styles = TableHistogramCss.default;

function TableHistogram$Loading(Props) {
  return React.createElement("div", {
              className: styles.centered
            }, React.createElement(Loading.make, { }));
}

function TableHistogram$State$ColumnSelect(Props) {
  var arg = Props.arg;
  var dataType = Props.dataType;
  var dispatch = Props.dispatch;
  var apiClient = Props.apiClient;
  var renderTerm = Props.renderTerm;
  if (typeof dataType !== "number" && dataType[0] === 848054398) {
    var match = dataType[1];
    if (typeof match !== "number" && match[0] === 847309489) {
      var options = Curry._2(Import.List.map, Curry._2(Import.List.keep, match[1][0], (function (param) {
                  return Curry._1(Root$SolvuuApi.Histogram.Arg.dataTypeAvailable, param[1]);
                })), (function (param) {
              var name = param[0];
              return ReactSelect.$$Option.make(name, name);
            }));
      var onChange = function (options) {
        if (options !== undefined) {
          var label_001 = options.value;
          var label = /* `Named */[
            699531161,
            label_001
          ];
          return Curry._1(dispatch, /* `SelectColumn */[
                      -220885230,
                      label
                    ]);
        } else {
          return /* () */0;
        }
      };
      return React.createElement(Record.ParsedRecord.make, {
                  children: Curry._3(Root$SolvuuApi.Histogram.Arg.of_record, arg, Curry._2(Lang$SolvuuApi.Record.Param.flag1, Root$SolvuuApi.Histogram.Arg.Const.Label.data, Curry._1(Lang$SolvuuApi.Record.Param.Flag.optional, Lang$SolvuuApi.Record.Param.Arg_type.data)), (function (param, param$1, param$2, columnName, param$3) {
                          return React.createElement(FormGroup.$$Element.make, {
                                      name: "columns",
                                      children: null
                                    }, React.createElement(WithTestId.make, {
                                          id: "histogram-columns-select",
                                          children: React.createElement(Select.make, {
                                                label: React.createElement(ReactIntl.FormattedMessage, {
                                                      id: "table.histogram.columnSelect.label"
                                                    }),
                                                value: Belt_Option.getWithDefault(columnName, ""),
                                                onChange: onChange,
                                                options: options
                                              })
                                        }), React.createElement(FormGroup.FieldInfoElement.make, {
                                          children: React.createElement(ReactIntl.FormattedMessage, {
                                                id: "table.histogram.columnsSelect.info"
                                              })
                                        }));
                        }))
                });
    }
    
  }
  var term = Curry._3(Lang$SolvuuApi.Term.type_error, dataType, "Invalid", /* `Record */[
        847309489,
        arg
      ]);
  return Curry._6(renderTerm, apiClient, term, dataType, TermRenderContext.root, undefined, /* () */0);
}

function TableHistogram$State(Props) {
  var dataType = Props.dataType;
  var state = Props.state;
  var onClose = Props.onClose;
  var dispatch = Props.dispatch;
  var apiClient = Props.apiClient;
  var renderTerm = Props.renderTerm;
  var title = React.createElement(ReactIntl.FormattedMessage, {
        id: "table.histogram.header"
      });
  var controls = React.createElement(TableHistogram$State$ColumnSelect, {
        arg: state.arg,
        dataType: dataType,
        dispatch: dispatch,
        apiClient: apiClient,
        renderTerm: renderTerm
      });
  return React.createElement(TableVisualizationCard.make, {
              icon: /* Histogram */117882500,
              title: title,
              controls: controls,
              onClose: onClose,
              children: ReactExtras.withTestId("entity-static-table-histogram", React.createElement("div", {
                        className: styles.root
                      }, state.graphic))
            });
}

function TableHistogram(Props) {
  var onClose = Props.onClose;
  var data = Props.data;
  var $$eval = Props.eval;
  var apiClient = Props.apiClient;
  var renderTerm = Props.renderTerm;
  var term = data[0];
  var arg = Curry._3(Lang$SolvuuApi.Record.update, Curry._3(Lang$SolvuuApi.Record.update, Root$SolvuuApi.Histogram.Arg.Const.Default.arg, Root$SolvuuApi.Histogram.Arg.Const.Label.data, (function (param) {
              return term;
            })), Root$SolvuuApi.Histogram.Arg.Const.Label.numBins, (function (param) {
          return /* `Some */[
                  925978388,
                  /* `Int */[
                    3654863,
                    20
                  ]
                ];
        }));
  var state = {
    arg: arg,
    graphic: null
  };
  var match = React.useReducer((function (param, param$1) {
          var apiClient$1 = apiClient;
          var renderTerm$1 = renderTerm;
          var param$2 = param;
          var $$event = param$1;
          var state = param$2[0];
          if ($$event[0] >= -220885230) {
            var argLabel = $$event[1];
            var arg = Curry._3(Lang$SolvuuApi.Record.update, state.arg, Root$SolvuuApi.Histogram.Arg.Const.Label.columnName, (function (param) {
                    return /* `Some */[
                            925978388,
                            /* `String */[
                              -976970511,
                              Curry._1(Lang$SolvuuApi.Label.to_string, argLabel)
                            ]
                          ];
                  }));
            var graphic = React.createElement(TableHistogram$Loading, { });
            var state$1 = {
              arg: arg,
              graphic: graphic
            };
            var effect = /* `Evaluate */[
              -620618631,
              /* `App */[
                3257473,
                /* tuple */[
                  /* `Var */[
                    4298439,
                    Root$SolvuuApi.histogram
                  ],
                  /* `Record */[
                    847309489,
                    arg
                  ]
                ]
              ]
            ];
            return /* tuple */[
                    state$1,
                    effect
                  ];
          } else {
            var graphic$1 = Curry._6(renderTerm$1, apiClient$1, $$event[1], /* Type_not_available */-883458824, TermRenderContext.root, undefined, /* () */0);
            var state_arg = state.arg;
            var state$2 = {
              arg: state_arg,
              graphic: graphic$1
            };
            return /* tuple */[
                    state$2,
                    undefined
                  ];
          }
        }), /* tuple */[
        state,
        undefined
      ]);
  var dispatch = match[1];
  var match$1 = match[0];
  var effect = match$1[1];
  React.useEffect((function () {
          if (effect !== undefined) {
            Curry._1($$eval, effect[1]).then((function (term) {
                    Curry._1(dispatch, /* `Graphic */[
                          -1051164024,
                          term
                        ]);
                    return Promise.resolve(/* () */0);
                  }));
            return ;
          }
          
        }), /* array */[effect]);
  return React.createElement(TableHistogram$State, {
              dataType: data[1],
              state: match$1[0],
              onClose: onClose,
              dispatch: dispatch,
              apiClient: apiClient,
              renderTerm: renderTerm
            });
}

var make = TableHistogram;

export {
  make ,
  
}
/* styles Not a pure module */
