

import * as React from "react";
import * as SolvuuBlob from "../../Entities/Blob/SolvuuBlob.bs.js";
import * as StyleCss from "./style.css";

var styles = StyleCss.default;

function UploadShow(Props) {
  var blobId = Props.blobId;
  var apiClient = Props.apiClient;
  return React.createElement("div", {
              className: styles.root
            }, React.createElement(SolvuuBlob.make, {
                  blobId: blobId,
                  apiClient: apiClient
                }));
}

var make = UploadShow;

export {
  styles ,
  make ,
  
}
/* styles Not a pure module */
