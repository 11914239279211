

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as BlobJs from "./blobJs.bs.js";
import * as Import from "../../features/import.bs.js";
import * as TermJs from "./termJs.bs.js";
import * as JobForm from "../../features/jobs/form/jobForm.bs.js";
import * as Caml_int64 from "bs-platform/lib/es6/caml_int64.js";
import * as Pervasives from "bs-platform/lib/es6/pervasives.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Fmt$SolvuuApi from "solvuu-api/./fmt.bs.js";
import * as Job$SolvuuApi from "solvuu-api/./job.bs.js";
import * as Blob$SolvuuApi from "solvuu-api/./blob.bs.js";
import * as Root$SolvuuApi from "solvuu-api/./root.bs.js";
import * as Term$SolvuuApi from "solvuu-api/./term.bs.js";
import * as Basic$SolvuuApi from "solvuu-api/./basic.bs.js";
import * as Job_metrics$SolvuuApi from "solvuu-api/./job_metrics.bs.js";
import * as Term_get_request_bs$SolvuuApi0 from "solvuu-api0/./term_get_request_bs.bs.js";
import * as Job_create_request_bs$SolvuuApi0 from "solvuu-api0/./job_create_request_bs.bs.js";
import * as Term_mkdir_request_bs$SolvuuApi0 from "solvuu-api0/./term_mkdir_request_bs.bs.js";
import * as Blob_create_request_bs$SolvuuApi0 from "solvuu-api0/./blob_create_request_bs.bs.js";
import * as Term_create_request_bs$SolvuuApi0 from "solvuu-api0/./term_create_request_bs.bs.js";
import * as Term_create_response_bs$SolvuuApi0 from "solvuu-api0/./term_create_response_bs.bs.js";
import * as Fmt_format_of_request_bs$SolvuuApi0 from "solvuu-api0/./fmt_format_of_request_bs.bs.js";
import * as Blob_get_by_id_request_bs$SolvuuApi0 from "solvuu-api0/./blob_get_by_id_request_bs.bs.js";
import * as Fmt_format_of_response_bs$SolvuuApi0 from "solvuu-api0/./fmt_format_of_response_bs.bs.js";

function createSolvuuApi(api) {
  var requestPathToString = function (components) {
    return Curry._1(Import.List.toArray, components).join("/");
  };
  var get = function (path) {
    var apiPath = requestPathToString(path);
    return api.get(apiPath);
  };
  var post = function (path, body, options, param) {
    var apiPath = requestPathToString(path);
    var apiOptions = Belt_Option.getWithDefault(options, undefined);
    return api.post(apiPath, body, apiOptions);
  };
  var fetchBlobs = function (param) {
    return get(Curry._1(Blob$SolvuuApi.Get_all.$$Request.path, /* () */0));
  };
  var fetchBlob = function (blobId) {
    var info = {
      id: blobId,
      instructions: undefined
    };
    var path = Curry._1(Blob$SolvuuApi.Get_by_id.$$Request.path, info);
    var body = Curry._1(Blob_get_by_id_request_bs$SolvuuApi0.write_info, info);
    return post(path, body, undefined, /* () */0);
  };
  var fetchBlobEvents = function (blobId) {
    var info = {
      id: blobId
    };
    return get(Curry._1(Blob$SolvuuApi.Get_events.$$Request.path, info));
  };
  var createBlobRe = function (src, sizeBytes) {
    var source = src;
    var size = Belt_Option.map(sizeBytes, Caml_int64.of_float);
    var info = {
      source: source,
      size: size,
      checksum: undefined
    };
    var path = Curry._1(Blob$SolvuuApi.Create.$$Request.path, info);
    var body = Curry._1(Blob_create_request_bs$SolvuuApi0.write_info, info);
    return post(path, body, undefined, /* () */0);
  };
  var createBlobFromURI = function (uri) {
    return createBlobRe(uri, undefined);
  };
  var createBlobFromFile = function (file) {
    var src = "file:///" + file.name;
    var sizeBytes = file.size;
    return createBlobRe(src, sizeBytes);
  };
  var requestBlobDownload = function (blobId) {
    var info_instructions = /* Download */-344447064;
    var info = {
      id: blobId,
      instructions: info_instructions
    };
    var path = Curry._1(Blob$SolvuuApi.Get_by_id.$$Request.path, info);
    var body = Curry._1(Blob_get_by_id_request_bs$SolvuuApi0.write_info, info);
    return post(path, body, undefined, /* () */0);
  };
  var requestBlobUpload = function (blobId) {
    var info_instructions = /* Upload */-1058128351;
    var info = {
      id: blobId,
      instructions: info_instructions
    };
    var path = Curry._1(Blob$SolvuuApi.Get_by_id.$$Request.path, info);
    var body = Curry._1(Blob_get_by_id_request_bs$SolvuuApi0.write_info, info);
    return post(path, body, undefined, /* () */0);
  };
  var inferFormat = function (fileName, bytes) {
    var uri = "file:///" + fileName;
    var info = {
      uri: uri,
      bytes: bytes,
      response_term_fmt: /* Solvuu_eval_json */-1015245230
    };
    var path = Curry._1(Fmt$SolvuuApi.Format_of.$$Request.path, info);
    var body = Curry._1(Fmt_format_of_request_bs$SolvuuApi0.write_info, info);
    var options = {
      timeout: 60000
    };
    return post(path, body, Caml_option.some(options), /* () */0);
  };
  var fetchJobs = function (param) {
    return get(Curry._1(Job$SolvuuApi.Get_all.$$Request.path, /* () */0));
  };
  var fetchJob = function (jobId) {
    var info = {
      id: jobId
    };
    return get(Curry._1(Job$SolvuuApi.Get_by_id.$$Request.path, info));
  };
  var fetchJobEvents = function (jobId) {
    var info = {
      id: jobId
    };
    return get(Curry._1(Job$SolvuuApi.Get_events.$$Request.path, info));
  };
  var fetchJobMetrics = function (jobId) {
    var info = {
      id: jobId
    };
    return get(Curry._1(Job_metrics$SolvuuApi.Get_by_id.$$Request.path, info));
  };
  var createSmallJob = function (validForm) {
    var info = JobForm.toSolvuuSpec(validForm);
    var path = Curry._1(Job$SolvuuApi.Create.$$Request.path, info);
    var body = Curry._1(Job_create_request_bs$SolvuuApi0.write_info, info);
    return post(path, body, undefined, /* () */0);
  };
  var termEval = function (termJs, options) {
    var term = TermJs.convertFromJs(termJs);
    var info = {
      term: term,
      response_term_fmt: /* Json */826371656
    };
    var path = Curry._1(Term$SolvuuApi.Get.$$Request.path, info);
    var body = Curry._1(Term_get_request_bs$SolvuuApi0.write_info, info);
    return post(path, body, Caml_option.some(options), /* () */0);
  };
  var createTerm = function (bindingPath, termJs) {
    var term = TermJs.convertFromJs(termJs);
    var info = {
      path: bindingPath,
      term: term,
      type_: undefined
    };
    var path = Curry._1(Term$SolvuuApi.Create.$$Request.path, info);
    var body = Curry._1(Term_create_request_bs$SolvuuApi0.write_info, info);
    return post(path, body, undefined, /* () */0);
  };
  var createDirectory = function (bindingPath, initTermJs) {
    var __x = (initTermJs == null) ? undefined : Caml_option.some(initTermJs);
    var init = Belt_Option.map(__x, TermJs.convertFromJs);
    var info = {
      path: bindingPath,
      p: false,
      init: init,
      typ: undefined
    };
    var path = Curry._1(Term$SolvuuApi.Mkdir.$$Request.path, info);
    var body = Curry._1(Term_mkdir_request_bs$SolvuuApi0.write_info, info);
    return post(path, body, undefined, /* () */0);
  };
  var fetchTermEvents = function (termId) {
    var info = {
      id: termId
    };
    return get(Curry._1(Term$SolvuuApi.Get_events.$$Request.path, info));
  };
  var ping = function (param) {
    return get(Curry._1(Basic$SolvuuApi.Ping.$$Request.path, /* () */0));
  };
  return {
          fetchBlobs: fetchBlobs,
          fetchBlob: fetchBlob,
          fetchBlobEvents: fetchBlobEvents,
          createBlobFromFile: createBlobFromFile,
          createBlobFromURI: createBlobFromURI,
          requestBlobDownload: requestBlobDownload,
          requestBlobUpload: requestBlobUpload,
          inferFormat: inferFormat,
          fetchJobs: fetchJobs,
          fetchJob: fetchJob,
          fetchJobEvents: fetchJobEvents,
          fetchJobMetrics: fetchJobMetrics,
          createSmallJob: createSmallJob,
          fetchTermEvents: fetchTermEvents,
          createTerm: createTerm,
          createDirectory: createDirectory,
          termEval: termEval,
          ping: ping
        };
}

function blobGetResponseToDetails(response) {
  var ok = Curry._1(Blob$SolvuuApi.Get_by_id.$$Response.Json.read_ok, Belt_Option.getExn(response.data));
  return {
          blobId: ok.blob.id,
          blob: Curry._1(BlobJs.convertToJs, ok.blob)
        };
}

function blobCreateResponseToDetails(response) {
  var blob = Curry._1(Blob$SolvuuApi.Create.$$Response.Json.read_ok, Belt_Option.getExn(response.data));
  return {
          blobId: blob.id
        };
}

function blobDownloadRequestToDetails1(ok) {
  var match = ok.instructions;
  var match$1;
  if (match !== undefined) {
    var match$2 = match;
    if (match$2[0] >= 549625211) {
      match$1 = Pervasives.failwith("Blob can't be downloaded");
    } else {
      var match$3 = match$2[1][1];
      if (match$3[0] >= -344447064) {
        var match$4 = match$3[1];
        match$1 = /* tuple */[
          match$4.location,
          match$4.credentials[1]
        ];
      } else {
        match$1 = Pervasives.failwith("Blob can't be downloaded");
      }
    }
  } else {
    match$1 = Pervasives.failwith("Blob can't be downloaded");
  }
  var credentials = match$1[1];
  var $$location = match$1[0];
  return {
          credentials: {
            accessKeyId: credentials.access_key_id,
            secretAccessKey: credentials.secret_access_key,
            sessionToken: credentials.session_token,
            region: credentials.region
          },
          bucket: $$location.bucket,
          key: $$location.key
        };
}

function blobUploadRequestToDetails1(ok) {
  var match = ok.instructions;
  var match$1;
  if (match !== undefined) {
    var match$2 = match;
    if (match$2[0] >= 549625211) {
      match$1 = Pervasives.failwith("Blob can't be downloaded");
    } else {
      var match$3 = match$2[1][1];
      if (match$3[0] >= -344447064) {
        match$1 = Pervasives.failwith("Blob can't be downloaded");
      } else {
        var match$4 = match$3[1];
        var match$5 = match$4.instructions;
        match$1 = /* tuple */[
          match$4.upload_id,
          match$5.location,
          match$5.credentials[1]
        ];
      }
    }
  } else {
    match$1 = Pervasives.failwith("Blob can't be downloaded");
  }
  var credentials = match$1[2];
  var $$location = match$1[1];
  return {
          credentials: {
            accessKeyId: credentials.access_key_id,
            secretAccessKey: credentials.secret_access_key,
            sessionToken: credentials.session_token,
            region: credentials.region
          },
          bucket: $$location.bucket,
          key: $$location.key,
          uploadId: match$1[0],
          blobId: ok.blob.id
        };
}

function blobDownloadRequestToDetails(response) {
  return blobDownloadRequestToDetails1(Curry._1(Blob$SolvuuApi.Get_by_id.$$Response.Json.read_ok, Belt_Option.getExn(response.data)));
}

function blobUploadRequestToDetails(response) {
  return blobUploadRequestToDetails1(Curry._1(Blob$SolvuuApi.Get_by_id.$$Response.Json.read_ok, Belt_Option.getExn(response.data)));
}

function termCreateRequestToBlobId(response) {
  var param = Curry._1(Term_create_response_bs$SolvuuApi0.read_ok, Belt_Option.getExn(response.data));
  return Curry._1(Import.List.headExn, Curry._2(Import.List.keepMap, param.futures, (function (future) {
                    var match = future[1];
                    if (match[0] >= 295523485) {
                      return match[1];
                    }
                    
                  })));
}

function formatInferenceResponseToFormat(response) {
  var param = Curry._1(Fmt$SolvuuApi.Parse.of_term, TermJs.convertFromJs(TermJs.parseFromString(Curry._1(Import.List.headExn, Fmt_format_of_response_bs$SolvuuApi0.read_ok(Belt_Option.getExn(response.data))))));
  if (typeof param === "number") {
    return Pervasives.failwith("formatInferenceResponseToFormat");
  } else {
    return Curry._1(Root$SolvuuApi.Fmt.json_of_t, param[1][0]);
  }
}

var Js = {
  defaultPostOptions: undefined,
  createSolvuuApi: createSolvuuApi,
  blobGetResponseToDetails: blobGetResponseToDetails,
  blobCreateResponseToDetails: blobCreateResponseToDetails,
  blobDownloadRequestToDetails1: blobDownloadRequestToDetails1,
  blobUploadRequestToDetails1: blobUploadRequestToDetails1,
  blobDownloadRequestToDetails: blobDownloadRequestToDetails,
  blobUploadRequestToDetails: blobUploadRequestToDetails,
  termCreateRequestToBlobId: termCreateRequestToBlobId,
  formatInferenceResponseToFormat: formatInferenceResponseToFormat
};

export {
  Js ,
  
}
/* BlobJs Not a pure module */
