

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as TermJs from "../../../../lib/solvuu/termJs.bs.js";
import * as StyleCss from "./style.css";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as ReactExtras from "../../../../lib/solvuu/ReactExtras.bs.js";
import * as EntitySidebar from "../EntitySidebar/EntitySidebar.bs.js";
import * as Js_null_undefined from "bs-platform/lib/es6/js_null_undefined.js";
import * as PageHeader from "components/PageHeader";

var styles = StyleCss.default;

function EntitySidebarToggles(Props) {
  var term = Props.term;
  var openedSidebar = Props.openedSidebar;
  var onChangeOpenedSidebar = Props.onChangeOpenedSidebar;
  if (typeof term === "number" || term[0] !== 3405101) {
    return null;
  } else {
    var className = function (sidebar) {
      var baseClassName;
      switch (sidebar) {
        case /* Info */0 :
            baseClassName = styles.toggleInfo;
            break;
        case /* Comments */1 :
            baseClassName = styles.toggleComments;
            break;
        case /* Activity */2 :
            baseClassName = styles.toggleActivity;
            break;
        
      }
      var modifierClassName = openedSidebar !== undefined && sidebar === openedSidebar ? styles.toggleActive : undefined;
      return Cn.make(/* :: */[
                  baseClassName,
                  /* :: */[
                    Cn.unpack(modifierClassName),
                    /* [] */0
                  ]
                ]);
    };
    var testId = function (sidebar) {
      var sidebarId = EntitySidebar.Sidebar.id(sidebar);
      return "entity-sidebar-toggle[" + (String(sidebarId) + "]");
    };
    var title = function (sidebar) {
      switch (sidebar) {
        case /* Info */0 :
            return "Info";
        case /* Comments */1 :
            return "Comments";
        case /* Activity */2 :
            return "Activity";
        
      }
    };
    var button = function (sidebar) {
      return ReactExtras.withTestId(testId(sidebar), React.createElement("button", {
                      className: className(sidebar),
                      title: title(sidebar),
                      type: "button",
                      onClick: (function (param) {
                          var clickedSidebar = sidebar;
                          if (openedSidebar !== undefined && openedSidebar === clickedSidebar) {
                            return Curry._1(onChangeOpenedSidebar, undefined);
                          } else {
                            return Curry._1(onChangeOpenedSidebar, clickedSidebar);
                          }
                        })
                    }));
    };
    return React.createElement(React.Fragment, undefined, React.createElement(PageHeader.default.Slot, {
                    children: button(/* Info */0)
                  }), React.createElement(PageHeader.default.Slot, {
                    children: button(/* Comments */1)
                  }), React.createElement(PageHeader.default.Slot, {
                    children: button(/* Activity */2)
                  }));
  }
}

var sidebarFromJs = EntitySidebar.Sidebar.sidebarFromJs;

var sidebarToJs = EntitySidebar.Sidebar.sidebarToJs;

function mapProps(props) {
  var term = TermJs.convertFromJs(props.term);
  var openedSidebar = Belt_Option.map(Caml_option.nullable_to_opt(props.openedSidebar), sidebarFromJs);
  var onChangeOpenedSidebar = function (optSidebar) {
    var sidebarJs = Js_null_undefined.fromOption(Belt_Option.map(optSidebar, sidebarToJs));
    return props.onChangeOpenedSidebar(sidebarJs);
  };
  return {
          term: term,
          openedSidebar: openedSidebar,
          onChangeOpenedSidebar: onChangeOpenedSidebar
        };
}

var JsProps = {
  sidebarFromJs: sidebarFromJs,
  sidebarToJs: sidebarToJs,
  mapProps: mapProps
};

var $$default = ReactExtras.jsComponent("Entity.EntitySidebarToggles", EntitySidebarToggles, mapProps);

var make = EntitySidebarToggles;

export {
  styles ,
  make ,
  JsProps ,
  $$default ,
  $$default as default,
  
}
/* styles Not a pure module */
