

import * as EntityCreatorReduxJs from "./EntityCreatorRedux.js";

var make = EntityCreatorReduxJs.default;

export {
  make ,
  
}
/* make Not a pure module */
