

import * as Err from "../components/Err.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Import from "../features/import.bs.js";
import * as Record from "../containers/Entities/Record/Record.bs.js";
import * as Select from "../components/Select/Select.bs.js";
import * as Loading from "../components/Loading.bs.js";
import * as FormGroup from "../components/FormGroup/FormGroup.bs.js";
import * as Pervasives from "bs-platform/lib/es6/pervasives.js";
import * as ReactIntl from "react-intl";
import * as ReactExtras from "../lib/solvuu/ReactExtras.bs.js";
import * as ReactSelect from "../lib/ReactSelect.bs.js";
import * as Lang$SolvuuApi from "solvuu-api/./lang.bs.js";
import * as Root$SolvuuApi from "solvuu-api/./root.bs.js";
import * as VerticalLayout from "../components/Layout/VerticalLayout.bs.js";
import * as TableTTestCss from "./TableTTest.css";
import * as TermRenderContext from "../lang/TermRenderContext.bs.js";
import * as Layout from "components/Layout";
import * as TableVisualizationCard from "./TableVisualizationCard.bs.js";
import * as Caml_builtin_exceptions from "bs-platform/lib/es6/caml_builtin_exceptions.js";

var styles = TableTTestCss.default;

function TableTTest$Loading(Props) {
  return React.createElement("div", {
              className: styles.centered
            }, React.createElement(Loading.make, { }));
}

function TableTTest$State$TypeSelect(Props) {
  var type_ = Props.type_;
  var dispatch = Props.dispatch;
  var options_000 = ReactSelect.$$Option.make("OneSample", "One Sample");
  var options_001 = /* :: */[
    ReactSelect.$$Option.make("PairedSamples", "Paired Samples"),
    /* :: */[
      ReactSelect.$$Option.make("UnpairedSamples", "Unpaired Samples"),
      /* [] */0
    ]
  ];
  var options = /* :: */[
    options_000,
    options_001
  ];
  var onChange = function (opt) {
    return Curry._1(dispatch, /* `UpdateArg */[
                615103405,
                /* tuple */[
                  Root$SolvuuApi.T_test.Arg.Const.Label.type_,
                  (function (param) {
                      if (opt !== undefined) {
                        return /* `String */[
                                -976970511,
                                opt.value
                              ];
                      } else {
                        throw [
                              Caml_builtin_exceptions.assert_failure,
                              /* tuple */[
                                "TableTTest.re",
                                45,
                                24
                              ]
                            ];
                      }
                    })
                ]
              ]);
  };
  return React.createElement(Layout.VerticalLayoutItem, {
              children: React.createElement(FormGroup.$$Element.make, {
                    name: Curry._1(Lang$SolvuuApi.Label.to_string, Root$SolvuuApi.T_test.Arg.Const.Label.type_),
                    children: null
                  }, React.createElement(Select.make, {
                        label: React.createElement(ReactIntl.FormattedMessage, {
                              id: "table.tTest.typeSelect.label"
                            }),
                        value: type_,
                        onChange: onChange,
                        options: options,
                        clearable: false
                      }), React.createElement(FormGroup.FieldInfoElement.make, {
                        children: React.createElement(ReactIntl.FormattedMessage, {
                              id: "table.tTest.typeSelect.info"
                            })
                      }))
            });
}

function TableTTest$State$Column1Select(Props) {
  var column1 = Props.column1;
  var columns = Props.columns;
  var dispatch = Props.dispatch;
  var numericColumns = Curry._2(Import.List.map, Curry._2(Import.List.keep, columns, (function (param) {
              return Curry._1(Root$SolvuuApi.T_test.Arg.dataTypeAvailable, param[1]);
            })), (function (param) {
          var name = param[0];
          return ReactSelect.$$Option.make(name, name);
        }));
  var handleColumnSelect = function (opt) {
    return Curry._1(dispatch, /* `UpdateArg */[
                615103405,
                /* tuple */[
                  Root$SolvuuApi.T_test.Arg.Const.Label.column1,
                  (function (param) {
                      if (opt !== undefined) {
                        return /* `Some */[
                                925978388,
                                /* `String */[
                                  -976970511,
                                  opt.value
                                ]
                              ];
                      } else {
                        throw [
                              Caml_builtin_exceptions.assert_failure,
                              /* tuple */[
                                "TableTTest.re",
                                93,
                                24
                              ]
                            ];
                      }
                    })
                ]
              ]);
  };
  var columnSelectValue = column1 !== undefined ? column1 : "";
  return React.createElement(Layout.VerticalLayoutItem, {
              children: React.createElement(FormGroup.$$Element.make, {
                    name: Curry._1(Lang$SolvuuApi.Label.to_string, Root$SolvuuApi.T_test.Arg.Const.Label.column1),
                    children: null
                  }, React.createElement(Select.make, {
                        label: React.createElement(ReactIntl.FormattedMessage, {
                              id: "table.tTest.columnSelect.label"
                            }),
                        value: columnSelectValue,
                        onChange: handleColumnSelect,
                        options: numericColumns,
                        clearable: false
                      }), React.createElement(FormGroup.FieldInfoElement.make, {
                        children: React.createElement(ReactIntl.FormattedMessage, {
                              id: "table.tTest.columnSelect.info"
                            })
                      }))
            });
}

function TableTTest$State$Column2Select(Props) {
  var column2 = Props.column2;
  var type_ = Props.type_;
  var columns = Props.columns;
  var dispatch = Props.dispatch;
  var numericColumns = Curry._2(Import.List.map, Curry._2(Import.List.keep, columns, (function (param) {
              return Curry._1(Root$SolvuuApi.T_test.Arg.dataTypeAvailable, param[1]);
            })), (function (param) {
          var name = param[0];
          return ReactSelect.$$Option.make(name, name);
        }));
  var handleColumnSelect = function (opt) {
    return Curry._1(dispatch, /* `UpdateArg */[
                615103405,
                /* tuple */[
                  Root$SolvuuApi.T_test.Arg.Const.Label.column2,
                  (function (param) {
                      if (opt !== undefined) {
                        return /* `Some */[
                                925978388,
                                /* `String */[
                                  -976970511,
                                  opt.value
                                ]
                              ];
                      } else {
                        throw [
                              Caml_builtin_exceptions.assert_failure,
                              /* tuple */[
                                "TableTTest.re",
                                145,
                                24
                              ]
                            ];
                      }
                    })
                ]
              ]);
  };
  var columnSelectValue = column2 !== undefined ? column2 : "";
  switch (type_) {
    case "OneSample" :
        return null;
    case "PairedSamples" :
    case "UnpairedSamples" :
        break;
    default:
      return Pervasives.failwith("BUG: Unrecognized type: " + type_);
  }
  return React.createElement(Layout.VerticalLayoutItem, {
              children: React.createElement(FormGroup.$$Element.make, {
                    name: Curry._1(Lang$SolvuuApi.Label.to_string, Root$SolvuuApi.T_test.Arg.Const.Label.column2),
                    children: null
                  }, React.createElement(Select.make, {
                        label: React.createElement(ReactIntl.FormattedMessage, {
                              id: "table.tTest.column2Select.label"
                            }),
                        value: columnSelectValue,
                        onChange: handleColumnSelect,
                        options: numericColumns,
                        clearable: false
                      }), React.createElement(FormGroup.FieldInfoElement.make, {
                        children: React.createElement(ReactIntl.FormattedMessage, {
                              id: "table.tTest.column2Select.info"
                            })
                      }))
            });
}

function TableTTest$State(Props) {
  var onClose = Props.onClose;
  var state = Props.state;
  var dataType = Props.dataType;
  var dispatch = Props.dispatch;
  var graphic = state.graphic;
  if (typeof dataType !== "number" && dataType[0] === 848054398) {
    var match = dataType[1];
    if (typeof match !== "number" && match[0] === 847309489) {
      var columns = match[1][0];
      return React.createElement(Record.ParsedRecord.make, {
                  children: Curry._2(Root$SolvuuApi.T_test.Arg.of_record, state.arg, (function (type_, column1, column2, param) {
                          var title = React.createElement(ReactIntl.FormattedMessage, {
                                id: "table.tTest.header"
                              });
                          var controls = React.createElement(Layout.VerticalLayout, VerticalLayout.makeProps(/* x4 */26812, null, /* () */0), React.createElement(TableTTest$State$TypeSelect, {
                                    type_: type_,
                                    dispatch: dispatch
                                  }), React.createElement(TableTTest$State$Column1Select, {
                                    column1: column1,
                                    columns: columns,
                                    dispatch: dispatch
                                  }), React.createElement(TableTTest$State$Column2Select, {
                                    column2: column2,
                                    type_: type_,
                                    columns: columns,
                                    dispatch: dispatch
                                  }));
                          return React.createElement(TableVisualizationCard.make, {
                                      icon: /* TTest */360427206,
                                      title: title,
                                      controls: controls,
                                      onClose: onClose,
                                      children: ReactExtras.withTestId("entity-static-table-t-test", React.createElement("div", {
                                                className: styles.root
                                              }, graphic))
                                    });
                        }))
                });
    }
    
  }
  return React.createElement(Err.Operation.make, {
              message: "Expected table"
            });
}

function TableTTest(Props) {
  var onClose = Props.onClose;
  var data = Props.data;
  var $$eval = Props.eval;
  var apiClient = Props.apiClient;
  var renderTerm = Props.renderTerm;
  var term = data[0];
  var state_arg = Curry._3(Lang$SolvuuApi.Record.update, Root$SolvuuApi.T_test.Arg.Const.Default.arg, Root$SolvuuApi.T_test.Arg.Const.Label.data, (function (param) {
          return term;
        }));
  var state = {
    arg: state_arg,
    graphic: null
  };
  var match = React.useReducer((function (param, param$1) {
          var apiClient$1 = apiClient;
          var renderTerm$1 = renderTerm;
          var param$2 = param;
          var $$event = param$1;
          var state = param$2[0];
          var evalEffect = function (arg) {
            return /* `Evaluate */[
                    -620618631,
                    /* `App */[
                      3257473,
                      /* tuple */[
                        /* `Var */[
                          4298439,
                          Root$SolvuuApi.t_test
                        ],
                        /* `Record */[
                          847309489,
                          arg
                        ]
                      ]
                    ]
                  ];
          };
          if ($$event[0] >= 615103405) {
            var match = $$event[1];
            var state_arg = Curry._3(Lang$SolvuuApi.Record.update, state.arg, match[0], match[1]);
            var state_graphic = React.createElement(TableTTest$Loading, { });
            var state$1 = {
              arg: state_arg,
              graphic: state_graphic
            };
            var effect = evalEffect(state_arg);
            return /* tuple */[
                    state$1,
                    effect
                  ];
          } else {
            var graphic = Curry._6(renderTerm$1, apiClient$1, $$event[1], /* Type_not_available */-883458824, TermRenderContext.root, undefined, /* () */0);
            var state_arg$1 = state.arg;
            var state$2 = {
              arg: state_arg$1,
              graphic: graphic
            };
            return /* tuple */[
                    state$2,
                    undefined
                  ];
          }
        }), /* tuple */[
        state,
        undefined
      ]);
  var dispatch = match[1];
  var match$1 = match[0];
  var effect = match$1[1];
  React.useEffect((function () {
          if (effect !== undefined) {
            Curry._1($$eval, effect[1]).then((function (term) {
                    Curry._1(dispatch, /* `Graphic */[
                          -1051164024,
                          term
                        ]);
                    return Promise.resolve(/* () */0);
                  }));
            return ;
          }
          
        }), /* array */[effect]);
  return React.createElement(TableTTest$State, {
              onClose: onClose,
              state: match$1[0],
              dataType: data[1],
              dispatch: dispatch
            });
}

var make = TableTTest;

export {
  make ,
  
}
/* styles Not a pure module */
