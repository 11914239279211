

import * as Err from "../../../../components/Err.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Int64 from "bs-platform/lib/es6/int64.js";
import * as React from "react";
import * as Button from "../../../../components/Button.bs.js";
import * as Import from "../../../../features/import.bs.js";
import * as Js_dict from "bs-platform/lib/es6/js_dict.js";
import * as Loading from "../../../../components/Loading.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Caml_int64 from "bs-platform/lib/es6/caml_int64.js";
import * as ReactIntl from "react-intl";
import * as StyleCss from "./style.css";
import * as Blob$SolvuuApi from "solvuu-api/./blob.bs.js";
import * as S3 from "aws-sdk/clients/s3";
import * as Caml_builtin_exceptions from "bs-platform/lib/es6/caml_builtin_exceptions.js";

var styles = StyleCss.default;

var non_printable_regex = (/[\x00-\x08\x0E-\x1F]/);

function blobToString(blob) {
  var body = new Blob(/* array */[blob]);
  return new Promise((function (resolve, reject) {
                var reader = new FileReader();
                reader.onload = (function ($$event) {
                    return resolve($$event.target.result);
                  });
                reader.onerror = (function (error) {
                    return reject(error);
                  });
                reader.readAsText(body);
                return /* () */0;
              }));
}

function downloadBlobFromS3(instructions, objectParamsOverrides) {
  var match = instructions.credentials;
  var credentials = match[1];
  var bucket = instructions.location.bucket;
  var key = instructions.location.key;
  var s3 = new S3.default({
        accessKeyId: credentials.access_key_id,
        secretAccessKey: credentials.secret_access_key,
        sessionToken: credentials.session_token,
        region: credentials.region,
        maxRetries: undefined,
        s3DisableBodySigning: undefined,
        params: undefined
      });
  var downloadResponse = new Promise((function (resolve, reject) {
          var getObjectParams = Js_dict.fromArray(Belt_Array.concat(/* array */[
                    /* tuple */[
                      "Bucket",
                      bucket
                    ],
                    /* tuple */[
                      "Key",
                      key
                    ],
                    /* tuple */[
                      "IfNoneMatch",
                      "NOMATCH"
                    ]
                  ], Js_dict.entries(objectParamsOverrides)));
          s3.getObject(getObjectParams, (function (err, data) {
                  if (err == null) {
                    return resolve(data);
                  } else {
                    return reject(err);
                  }
                }));
          return /* () */0;
        }));
  return Import.$great$great$pipe(downloadResponse, (function (x) {
                return x.Body;
              }));
}

function getBlob(blobId, apiClient) {
  var $$let = Blob$SolvuuApi.Get_by_id.$$Request;
  var $$let$1 = Blob$SolvuuApi.Get_by_id.$$Response;
  return Import.$great$great$pipe(Curry._3(apiClient.f, /* POST */891112544, {
                  entity: Blob$SolvuuApi.Get_by_id.entity,
                  service: Blob$SolvuuApi.Get_by_id.service,
                  $$Request: {
                    path: $$let.path,
                    body: $$let.body,
                    info: $$let.info
                  },
                  $$Response: {
                    ok_of_json: $$let$1.ok_of_json,
                    json_of_ok: $$let$1.json_of_ok,
                    bad_request_of_json: $$let$1.bad_request_of_json,
                    json_of_bad_request: $$let$1.json_of_bad_request,
                    not_found_of_json: $$let$1.not_found_of_json,
                    json_of_not_found: $$let$1.json_of_not_found
                  }
                }, {
                  id: blobId,
                  instructions: /* Download */-344447064
                }), (function (response) {
                if (typeof response === "number") {
                  return /* `Error */[
                          106380200,
                          "Unauthorized"
                        ];
                } else {
                  var info = response[1].info;
                  var variant = info[0];
                  if (variant !== -99004555) {
                    if (variant >= 17692) {
                      return /* `Ok */[
                              17724,
                              info[1]
                            ];
                    } else {
                      return /* `Error */[
                              106380200,
                              "Not Found"
                            ];
                    }
                  } else {
                    return /* `Error */[
                            106380200,
                            "Bad Request"
                          ];
                  }
                }
              }));
}

var Default = {
  preview_bytes_limit: 10240
};

function usePreview(blobId, apiClient) {
  var match = React.useState((function () {
          return /* Initial */418396260;
        }));
  var setState = match[1];
  var state = match[0];
  React.useEffect((function () {
          if (state === 418396260) {
            Curry._1(setState, (function (param) {
                    return /* Loading */-700189092;
                  }));
            Import.$great$great$eq(getBlob(blobId, apiClient), (function (blob) {
                      if (blob[0] >= 106380200) {
                        var e = blob[1];
                        Curry._1(setState, (function (param) {
                                return /* `Error */[
                                        106380200,
                                        e
                                      ];
                              }));
                        return Import.$$return(/* () */0);
                      } else {
                        var ok = blob[1];
                        var match = ok.instructions;
                        if (match !== undefined) {
                          var instructions = match;
                          var match$1 = ok.blob.state;
                          var size;
                          if (match$1[0] !== -250904213) {
                            size = undefined;
                          } else {
                            var completed = match$1[1];
                            size = completed[0] >= 425081257 ? completed[1].size : completed[1].size;
                          }
                          var $$default = /* int64 */{
                            hi: 0,
                            lo: 10239
                          };
                          var rangeLimit;
                          if (size !== undefined) {
                            var size$1 = size;
                            rangeLimit = Int64.compare(size$1, $$default) <= 0 ? Caml_int64.sub(size$1, Int64.one) : $$default;
                          } else {
                            rangeLimit = $$default;
                          }
                          if (instructions[0] >= 549625211) {
                            Curry._1(setState, (function (param) {
                                    return /* `Error */[
                                            106380200,
                                            "Denied Access"
                                          ];
                                  }));
                            return Import.$$return(/* () */0);
                          } else {
                            var instructions$1 = instructions[1][1];
                            if (instructions$1[0] >= -344447064) {
                              return Import.$great$great$eq(downloadBlobFromS3(instructions$1[1], Js_dict.fromArray(/* array */[/* tuple */[
                                                    "Range",
                                                    "bytes=0-" + Int64.to_string(rangeLimit)
                                                  ]])), (function (blob) {
                                            return Import.$great$great$pipe(blobToString(blob), (function (content) {
                                                          return Curry._1(setState, (function (param) {
                                                                        return /* `Preview */[
                                                                                153880552,
                                                                                /* tuple */[
                                                                                  ok.blob,
                                                                                  content
                                                                                ]
                                                                              ];
                                                                      }));
                                                        }));
                                          }));
                            } else {
                              throw [
                                    Caml_builtin_exceptions.assert_failure,
                                    /* tuple */[
                                      "Preview.re",
                                      155,
                                      36
                                    ]
                                  ];
                            }
                          }
                        } else {
                          Curry._1(setState, (function (param) {
                                  return /* `Error */[
                                          106380200,
                                          "Blob can't be downloaded"
                                        ];
                                }));
                          return Import.$$return(/* () */0);
                        }
                      }
                    })).catch((function (error) {
                    var error$1 = JSON.stringify(error);
                    Curry._1(setState, (function (param) {
                            return /* `Error */[
                                    106380200,
                                    error$1
                                  ];
                          }));
                    return Import.$$return(/* () */0);
                  }));
          }
          return ;
        }), /* tuple */[
        state,
        blobId
      ]);
  return state;
}

function renderBinaryContentWarning(handleConfirmShowBinary) {
  return React.createElement("div", {
              className: styles.contentShell
            }, React.createElement("div", {
                  className: styles.binaryWarning
                }, React.createElement(ReactIntl.FormattedMessage, {
                      id: "entities.blob.preview.binary",
                      tagName: "p"
                    })), React.createElement(Button.make, Button.makeProps(handleConfirmShowBinary, undefined, /* `Basic */[
                      -1058563442,
                      Curry._4(Button.Type.Basic.make, undefined, /* Secondary */67972948, undefined, /* () */0)
                    ], React.createElement(ReactIntl.FormattedMessage, {
                          id: "entities.blob.preview.confirmBinary"
                        }), /* () */0)));
}

function renderSnippedWarning(previewContentLen) {
  return React.createElement("div", {
              className: styles.snippedWarning
            }, React.createElement(ReactIntl.FormattedMessage, {
                  id: "entities.blob.preview.snipped",
                  values: {
                    bytes: previewContentLen
                  },
                  tagName: "p"
                }));
}

function Preview(Props) {
  var blobId = Props.blobId;
  var apiClient = Props.apiClient;
  var match = React.useState((function () {
          return false;
        }));
  var setShowBinary = match[1];
  var preview = usePreview(blobId, apiClient);
  if (typeof preview === "number") {
    return React.createElement(Loading.make, { });
  } else if (preview[0] >= 153880552) {
    var match$1 = preview[1];
    var previewContent = match$1[1];
    var previewContentLen = previewContent.length;
    var check = function (size) {
      var match = Caml_int64.gt(size, Caml_int64.of_int32(previewContentLen));
      if (match) {
        return /* Yes */4448519;
      } else {
        return /* No */17505;
      }
    };
    var match$2 = match$1[0].state;
    var snipped;
    if (match$2[0] !== -250904213) {
      snipped = /* Unknown */785140586;
    } else {
      var completed = match$2[1];
      if (completed[0] >= 425081257) {
        var match$3 = completed[1].size;
        snipped = match$3 !== undefined ? check(match$3) : /* Unknown */785140586;
      } else {
        snipped = check(completed[1].size);
      }
    }
    var binary = non_printable_regex.test(previewContent);
    var handleConfirmShowBinary = function (_event) {
      return Curry._1(setShowBinary, (function (param) {
                    return true;
                  }));
    };
    var match$4 = binary && !match[0];
    if (match$4) {
      return renderBinaryContentWarning(handleConfirmShowBinary);
    } else {
      return React.createElement("div", undefined, snipped !== 4448519 ? null : renderSnippedWarning(previewContentLen), React.createElement("div", {
                      className: styles.content
                    }, previewContent));
    }
  } else {
    return React.createElement(Err.Operation.make, {
                message: preview[1]
              });
  }
}

var make = Preview;

export {
  styles ,
  non_printable_regex ,
  blobToString ,
  downloadBlobFromS3 ,
  getBlob ,
  Default ,
  usePreview ,
  renderBinaryContentWarning ,
  renderSnippedWarning ,
  make ,
  
}
/* styles Not a pure module */
