

import * as Aws from "../../../features/Aws.bs.js";
import * as Err from "../../../components/Err.bs.js";
import * as Card from "../../../components/Card/Card.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Button from "../../../components/Button.bs.js";
import * as Import from "../../../features/import.bs.js";
import * as Loading from "../../../components/Loading.bs.js";
import * as Caml_obj from "bs-platform/lib/es6/caml_obj.js";
import * as FormGroup from "../../../components/FormGroup/FormGroup.bs.js";
import * as FormLayout from "../../../components/FormLayout/FormLayout.bs.js";
import * as Pervasives from "bs-platform/lib/es6/pervasives.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as MFASettings from "./MFASettings/MFASettings.bs.js";
import * as ReactExtras from "../../../lib/solvuu/ReactExtras.bs.js";
import * as ReactBootstrap from "../../../lib/ReactBootstrap.bs.js";
import * as VerticalLayout from "../../../components/Layout/VerticalLayout.bs.js";
import * as Layout from "components/Layout";
import * as Lib from "react-bootstrap/lib";
import * as AmazonCognitoIdentityJS from "../../../lib/AmazonCognitoIdentityJS.bs.js";
import * as AmazonCognitoIdentityJs from "amazon-cognito-identity-js";

function ok_exn(x) {
  if (x[0] >= 106380200) {
    console.log(x[1]);
    return Pervasives.failwith("");
  } else {
    return x[1];
  }
}

var State = { };

function ofAmazonCognitoIdentityJS(cognitoUser) {
  return Import.$great$great$pipe(AmazonCognitoIdentityJS.User.getUserAttributes(cognitoUser), (function (result) {
                var getAttributeValue = function (name) {
                  var attribute = Curry._2(Import.List.getBy, Curry._1(Import.List.fromArray, ok_exn(result)), (function (a) {
                          return a.getName() === name;
                        }));
                  return Belt_Option.map(attribute, (function (prim) {
                                return prim.getValue();
                              }));
                };
                return {
                        username: cognitoUser.getUsername(),
                        email: getAttributeValue("email"),
                        name: getAttributeValue("name")
                      };
              }));
}

function useProfile(param) {
  var match = React.useState((function () {
          return /* Initial */418396260;
        }));
  var setState = match[1];
  var state = match[0];
  React.useEffect((function () {
          if (typeof state !== "number") {
            if (state[0] !== -700189092) {
              
            } else {
              Import.$great$great$pipe(Import.$great$great$eq(Aws.getAuthenticatedUser(/* () */0), ofAmazonCognitoIdentityJS), (function (profile) {
                      return Curry._1(setState, (function (param) {
                                    return /* `Success */[
                                            94326179,
                                            profile
                                          ];
                                  }));
                    }));
            }
          }
          return ;
        }), /* array */[state]);
  var refresh = function (param) {
    return Curry._1(setState, (function (param) {
                  return /* `Loading */[
                          -700189092,
                          /* () */0
                        ];
                }));
  };
  return /* tuple */[
          state,
          refresh
        ];
}

var initial = {
  username: undefined,
  name: undefined,
  email: undefined
};

function useUpdate(param) {
  var match = React.useState((function () {
          return /* Initial */418396260;
        }));
  var setState = match[1];
  var state = match[0];
  React.useEffect((function () {
          if (typeof state !== "number" && state[0] === -700189092) {
            var form = state[1];
            var match = form.name;
            if (match !== undefined) {
              var name = match;
              var match$1 = form.email;
              if (match$1 !== undefined) {
                var email = match$1;
                Import.$great$great$eq(Aws.getAuthenticatedUser(/* () */0), (function (cognitoUser) {
                        var attributes = /* array */[
                          new AmazonCognitoIdentityJs.CognitoUserAttribute({
                                Name: "name",
                                Value: name
                              }),
                          new AmazonCognitoIdentityJs.CognitoUserAttribute({
                                Name: "email",
                                Value: email
                              })
                        ];
                        return Import.$great$great$pipe(AmazonCognitoIdentityJS.User.updateAttributes(cognitoUser, attributes), (function (param) {
                                      return Curry._1(setState, (function (param) {
                                                    return /* `Success */[
                                                            94326179,
                                                            /* () */0
                                                          ];
                                                  }));
                                    }));
                      }));
              } else {
                Curry._1(setState, (function (param) {
                        return /* `Error */[
                                106380200,
                                "Email required"
                              ];
                      }));
              }
            } else {
              Curry._1(setState, (function (param) {
                      return /* `Error */[
                              106380200,
                              "Name required"
                            ];
                    }));
            }
          }
          return ;
        }), /* array */[state]);
  var run = function (form) {
    return Curry._1(setState, (function (param) {
                  return /* `Loading */[
                          -700189092,
                          form
                        ];
                }));
  };
  return /* tuple */[
          state,
          run
        ];
}

function Profile$Form(Props) {
  var profile = Props.profile;
  var refreshProfile = Props.refreshProfile;
  Props.setBanner;
  var match = React.useState((function () {
          return initial;
        }));
  var setForm = match[1];
  var form = match[0];
  var match$1 = useUpdate(/* () */0);
  var updateProfile = match$1[1];
  var updateStatus = match$1[0];
  React.useEffect((function () {
          if (typeof profile !== "number" && profile[0] === 94326179) {
            var profile$1 = profile[1];
            Curry._1(setForm, (function (state) {
                    var match = Caml_obj.caml_equal(state, initial);
                    if (match) {
                      var profile$2 = profile$1;
                      return {
                              username: profile$2.username,
                              name: profile$2.name,
                              email: profile$2.email
                            };
                    } else {
                      return state;
                    }
                  }));
          }
          return ;
        }), /* array */[profile]);
  React.useEffect((function () {
          if (typeof updateStatus !== "number") {
            if (updateStatus[0] !== 94326179) {
              
            } else {
              Curry._1(refreshProfile, /* () */0);
            }
          }
          return ;
        }), /* array */[updateStatus]);
  var isSubmitting = typeof updateStatus === "number" ? false : updateStatus[0] === -700189092;
  var onSubmit = function ($$event) {
    $$event.preventDefault();
    return Curry._1(updateProfile, form);
  };
  if (typeof profile === "number") {
    return React.createElement(Loading.make, { });
  } else {
    var variant = profile[0];
    if (variant !== -464807894) {
      if (variant >= 94326179) {
        return React.createElement("form", {
                    onSubmit: onSubmit
                  }, React.createElement(FormLayout.VerticalFormFields.make, {
                        children: null
                      }, React.createElement(FormLayout.VerticalFormField.make, {
                            children: React.createElement(FormGroup.ControlledFormGroup.make, {
                                  value: form.username,
                                  onFieldChange: (function (username) {
                                      return Curry._1(setForm, (function (form) {
                                                    return {
                                                            username: username,
                                                            name: form.name,
                                                            email: form.email
                                                          };
                                                  }));
                                    }),
                                  name: "username",
                                  info: React.createElement(ReactIntl.FormattedMessage, {
                                        id: "settings.profile.username.description"
                                      }),
                                  label: React.createElement(ReactIntl.FormattedMessage, {
                                        id: "settings.profile.username"
                                      }),
                                  disabled: true
                                })
                          }), React.createElement(FormLayout.VerticalFormField.make, {
                            children: React.createElement(FormGroup.ControlledFormGroup.make, {
                                  value: form.email,
                                  type: "email",
                                  onFieldChange: (function (email) {
                                      return Curry._1(setForm, (function (form) {
                                                    return {
                                                            username: form.username,
                                                            name: form.name,
                                                            email: email
                                                          };
                                                  }));
                                    }),
                                  name: "email",
                                  required: true,
                                  label: React.createElement(ReactIntl.FormattedMessage, {
                                        id: "settings.profile.email"
                                      })
                                })
                          }), React.createElement(FormLayout.VerticalFormField.make, {
                            children: React.createElement(FormGroup.ControlledFormGroup.make, {
                                  value: form.name,
                                  onFieldChange: (function (name) {
                                      return Curry._1(setForm, (function (form) {
                                                    return {
                                                            username: form.username,
                                                            name: name,
                                                            email: form.email
                                                          };
                                                  }));
                                    }),
                                  name: "name",
                                  label: React.createElement(ReactIntl.FormattedMessage, {
                                        id: "settings.profile.name"
                                      })
                                })
                          })), React.createElement(FormLayout.VerticalFormActions.make, {
                        children: React.createElement(Button.make, Button.makeProps(undefined, undefined, /* `Submit */[
                                  -828715976,
                                  Curry._5(Button.Type.Submit.make, isSubmitting, undefined, undefined, undefined, /* () */0)
                                ], React.createElement(ReactIntl.FormattedMessage, {
                                      id: "settings.profile.submit"
                                    }), /* () */0))
                      }));
      } else {
        return React.createElement(Loading.make, { });
      }
    } else {
      return React.createElement(Loading.$$Error.make, { });
    }
  }
}

function useUpdate$1(setBanner) {
  var match = React.useState((function () {
          return /* Initial */418396260;
        }));
  var setState = match[1];
  var state = match[0];
  React.useEffect((function () {
          if (typeof state !== "number") {
            var variant = state[0];
            if (variant !== -700189092) {
              if (variant !== 94326179) {
                if (variant !== 106380200) {
                  
                } else {
                  Curry._1(setBanner, /* `Error */[
                        106380200,
                        /* :: */[
                          state[1],
                          /* [] */0
                        ]
                      ]);
                }
              } else {
                Curry._1(setBanner, /* Success */94326179);
              }
            } else {
              var form = state[1];
              var match = form.currentPassword;
              if (match !== undefined) {
                var currentPassword = match;
                var match$1 = form.newPassword;
                if (match$1 !== undefined) {
                  var newPassword = match$1;
                  Import.$great$great$eq(Aws.getAuthenticatedUser(/* () */0), (function (cognitoUser) {
                          return Import.$great$great$pipe(AmazonCognitoIdentityJS.User.changePassword(cognitoUser, currentPassword, newPassword), (function (result) {
                                        if (result[0] >= 106380200) {
                                          var match = result[1].message;
                                          if (match == null) {
                                            return /* () */0;
                                          } else {
                                            return Curry._1(setState, (function (param) {
                                                          return /* `Error */[
                                                                  106380200,
                                                                  match
                                                                ];
                                                        }));
                                          }
                                        } else {
                                          var onFieldChange = function (f) {
                                            return Curry._1(setState, (function (state) {
                                                          if (typeof state === "number" || state[0] !== 94326179) {
                                                            return /* `Success */[
                                                                    94326179,
                                                                    /* tuple */[
                                                                      form,
                                                                      onFieldChange
                                                                    ]
                                                                  ];
                                                          } else {
                                                            return /* `Success */[
                                                                    94326179,
                                                                    /* tuple */[
                                                                      Curry._1(f, state[1][0]),
                                                                      onFieldChange
                                                                    ]
                                                                  ];
                                                          }
                                                        }));
                                          };
                                          return Curry._1(setState, (function (param) {
                                                        return /* `Success */[
                                                                94326179,
                                                                /* tuple */[
                                                                  form,
                                                                  onFieldChange
                                                                ]
                                                              ];
                                                      }));
                                        }
                                      }));
                        }));
                } else {
                  Curry._1(setState, (function (param) {
                          return /* `Error */[
                                  106380200,
                                  "New Password Required"
                                ];
                        }));
                }
              } else {
                Curry._1(setState, (function (param) {
                        return /* `Error */[
                                106380200,
                                "Current Password Required"
                              ];
                      }));
              }
            }
          }
          return ;
        }), /* array */[state]);
  var run = function (form) {
    return Curry._1(setState, (function (param) {
                  return /* `Loading */[
                          -700189092,
                          form
                        ];
                }));
  };
  return /* tuple */[
          state,
          run
        ];
}

function Profile$Password(Props) {
  var match = Props.setBanner;
  var setBanner = match !== undefined ? match : (function (param) {
        return /* () */0;
      });
  var match$1 = React.useState((function () {
          return {
                  currentPassword: undefined,
                  newPassword: undefined,
                  newPasswordConfirmation: undefined
                };
        }));
  var setForm = match$1[1];
  var form = match$1[0];
  var match$2 = useUpdate$1(setBanner);
  var updateProfile = match$2[1];
  var updateStatus = match$2[0];
  var isSubmitting = typeof updateStatus === "number" ? false : updateStatus[0] === -700189092;
  var onSubmit = function ($$event) {
    $$event.preventDefault();
    return Curry._1(updateProfile, form);
  };
  return React.createElement("form", {
              onSubmit: onSubmit
            }, React.createElement(FormLayout.VerticalFormFields.make, {
                  children: null
                }, React.createElement(FormLayout.VerticalFormField.make, {
                      children: React.createElement(FormGroup.ControlledFormGroup.make, {
                            value: form.currentPassword,
                            type: "password",
                            onFieldChange: (function (currentPassword) {
                                return Curry._1(setForm, (function (state) {
                                              return {
                                                      currentPassword: currentPassword,
                                                      newPassword: state.newPassword,
                                                      newPasswordConfirmation: state.newPasswordConfirmation
                                                    };
                                            }));
                              }),
                            name: "currentPassword",
                            required: true,
                            label: React.createElement(ReactIntl.FormattedMessage, {
                                  id: "settings.profile.changePassword.currentPassword"
                                })
                          })
                    }), React.createElement(FormLayout.VerticalFormField.make, {
                      children: React.createElement(FormGroup.ControlledFormGroup.make, {
                            value: form.newPassword,
                            type: "password",
                            onFieldChange: (function (newPassword) {
                                return Curry._1(setForm, (function (state) {
                                              return {
                                                      currentPassword: state.currentPassword,
                                                      newPassword: newPassword,
                                                      newPasswordConfirmation: state.newPasswordConfirmation
                                                    };
                                            }));
                              }),
                            name: "newPassword",
                            required: true,
                            label: React.createElement(ReactIntl.FormattedMessage, {
                                  id: "settings.profile.changePassword.newPassword"
                                })
                          })
                    }), React.createElement(FormLayout.VerticalFormField.make, {
                      children: React.createElement(FormGroup.ControlledFormGroup.make, {
                            value: form.newPasswordConfirmation,
                            type: "password",
                            onFieldChange: (function (newPasswordConfirmation) {
                                return Curry._1(setForm, (function (state) {
                                              return {
                                                      currentPassword: state.currentPassword,
                                                      newPassword: state.newPassword,
                                                      newPasswordConfirmation: newPasswordConfirmation
                                                    };
                                            }));
                              }),
                            name: "newPasswordConfirmation",
                            required: true,
                            label: React.createElement(ReactIntl.FormattedMessage, {
                                  id: "settings.profile.changePassword.newPasswordConfirmation"
                                })
                          })
                    })), React.createElement(FormLayout.VerticalFormActions.make, {
                  children: React.createElement(Button.make, Button.makeProps(undefined, undefined, /* `Submit */[
                            -828715976,
                            Curry._5(Button.Type.Submit.make, isSubmitting, undefined, undefined, undefined, /* () */0)
                          ], React.createElement(ReactIntl.FormattedMessage, {
                                id: "settings.profile.submit"
                              }), /* () */0))
                }));
}

function Profile$Page(Props) {
  var apiClient = Props.apiClient;
  var profile = Props.profile;
  var refreshProfile = Props.refreshProfile;
  var match = React.useState((function () {
          return /* None */870530776;
        }));
  var setBanner = match[1];
  var showBanner = match[0];
  var setBanner$1 = function (x) {
    return Curry._1(setBanner, (function (param) {
                  return x;
                }));
  };
  return React.createElement("div", undefined, typeof showBanner === "number" ? (
                showBanner >= 870530776 ? null : React.createElement(Lib.Alert, ReactBootstrap.Alert.makeProps(/* success */944645571, undefined, null, undefined, /* () */0), React.createElement(ReactIntl.FormattedMessage, {
                            id: "settings.profile.success.title",
                            tagName: "h4"
                          }), React.createElement(ReactIntl.FormattedMessage, {
                            id: "settings.profile.success.message",
                            tagName: "p"
                          }))
              ) : React.createElement(Lib.Alert, ReactBootstrap.Alert.makeProps(/* danger */512905955, undefined, ReactExtras.listToElement(Curry._2(Import.List.map, showBanner[1], (function (message) {
                                  return React.createElement(React.Fragment, undefined, React.createElement("h4", undefined, "Error!"), React.createElement("p", undefined, message));
                                }))), undefined, /* () */0)), React.createElement(Err.Form.make, {
                  titleId: "settings.profile.errors.title",
                  errors: /* [] */0
                }), React.createElement(Layout.VerticalLayout, VerticalLayout.makeProps(/* x2 */26810, null, /* () */0), React.createElement(Layout.VerticalLayoutItem, {
                      children: React.createElement(Card.make, {
                            header: React.createElement(Card.Header.make, {
                                  children: React.createElement(ReactIntl.FormattedMessage, {
                                        id: "settings.profile.header",
                                        tagName: "strong"
                                      })
                                }),
                            children: React.createElement(Profile$Form, {
                                  profile: profile,
                                  refreshProfile: refreshProfile,
                                  setBanner: setBanner$1
                                })
                          })
                    }), React.createElement(Layout.VerticalLayoutItem, {
                      children: React.createElement(MFASettings.Page.make, {
                            apiClient: apiClient
                          })
                    }), React.createElement(Layout.VerticalLayoutItem, {
                      children: React.createElement(Card.make, {
                            header: React.createElement(Card.Header.make, {
                                  children: React.createElement(ReactIntl.FormattedMessage, {
                                        id: "settings.profile.changePassword.header",
                                        tagName: "strong"
                                      })
                                }),
                            children: React.createElement(Profile$Password, {
                                  setBanner: setBanner$1
                                })
                          })
                    })));
}

var Page = {
  make: Profile$Page
};

export {
  State ,
  useProfile ,
  Page ,
  
}
/* Aws Not a pure module */
