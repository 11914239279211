

import * as Op from "./Op.bs.js";
import * as Aws from "./Aws.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Flags from "../flags.bs.js";
import * as Import from "./import.bs.js";
import * as Pervasives from "bs-platform/lib/es6/pervasives.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as ReasonReactRouter from "reason-react/src/ReasonReactRouter.js";
import * as AmazonCognitoIdentityJS from "../lib/AmazonCognitoIdentityJS.bs.js";
import * as AmazonCognitoIdentityJs from "amazon-cognito-identity-js";

function preload(param) {
  return Aws.getAuthenticatedUser(/* () */0).then((function (cognitoUser) {
                  var username = cognitoUser.getUsername();
                  var session = cognitoUser.getSignInUserSession();
                  var token = session.getAccessToken();
                  Aws.saveCognitoCredentials(token.getJwtToken());
                  return Promise.resolve(/* tuple */[
                              username,
                              token
                            ]);
                })).catch((function (_error) {
                return Promise.resolve(undefined);
              }));
}

function make(x) {
  return {
          contents: Belt_Option.map(x, (function (x) {
                  return {
                          token: x.getJwtToken(),
                          expiration: x.getExpiration()
                        };
                }))
        };
}

function get(t) {
  var contentsOfPreloaded = function (preloaded) {
    return Belt_Option.map(preloaded, (function (preloaded) {
                  var accessToken = preloaded[1];
                  return {
                          token: accessToken.getJwtToken(),
                          expiration: accessToken.getExpiration()
                        };
                }));
  };
  var match = t.contents;
  var contents;
  if (match !== undefined) {
    var contents$1 = match;
    var expired = contents$1.expiration < Date.now() / 1000;
    contents = expired ? Import.$great$great$pipe(preload(/* () */0), contentsOfPreloaded) : Import.$$return(contents$1);
  } else {
    contents = Import.$great$great$pipe(preload(/* () */0), contentsOfPreloaded);
  }
  return Import.$great$great$pipe(contents, (function (contents) {
                t.contents = contents;
                return Belt_Option.map(t.contents, (function (accessToken) {
                              return accessToken.token;
                            }));
              }));
}

function expiration(t) {
  return Belt_Option.map(t.contents, (function (x) {
                return x.expiration;
              }));
}

var Token = {
  make: make,
  get: get,
  expiration: expiration
};

function usePreload(param) {
  return Op.useOp(/* () */0, (function (param) {
                return Import.$great$great$pipe(preload(/* () */0), (function (preloaded) {
                              return /* `Done */[
                                      759635106,
                                      Belt_Option.map(preloaded, (function (param) {
                                              return {
                                                      username: param[0],
                                                      token: make(Caml_option.some(param[1]))
                                                    };
                                            }))
                                    ];
                            }));
              }));
}

function username(x) {
  var variant = x[0];
  if (variant !== -783600662) {
    if (variant >= 951752159) {
      return x[1].username;
    } else {
      return "";
    }
  } else {
    return x[1].data.username;
  }
}

function setUsername(x, username) {
  if (x[0] !== -783600662) {
    return /* () */0;
  } else {
    return Curry._1(x[1].setInput, (function (prev) {
                  return {
                          username: username,
                          password: prev.password,
                          error: prev.error
                        };
                }));
  }
}

function password(x) {
  var variant = x[0];
  if (variant !== -783600662) {
    if (variant >= 951752159) {
      return x[1].password;
    } else {
      return "";
    }
  } else {
    return x[1].data.password;
  }
}

function setPassword(x, password) {
  if (x[0] !== -783600662) {
    return /* () */0;
  } else {
    return Curry._1(x[1].setInput, (function (prev) {
                  return {
                          username: prev.username,
                          password: password,
                          error: prev.error
                        };
                }));
  }
}

function error(x) {
  var variant = x[0];
  if (variant !== -783600662) {
    if (variant >= 951752159) {
      var error$1 = x[1].error;
      if (error$1 === "") {
        return ;
      } else {
        return error$1;
      }
    } else {
      return ;
    }
  } else {
    var error$2 = x[1].data.error;
    if (error$2 === "") {
      return ;
    } else {
      return error$2;
    }
  }
}

function onSuccess(username, session) {
  var token = session.getAccessToken();
  Aws.saveCognitoCredentials(token.getJwtToken());
  return /* `Authenticated */[
          648407695,
          {
            username: username,
            token: make(Caml_option.some(token))
          }
        ];
}

function onFailure(x) {
  return /* `AuthenticationFailed */[
          -214572683,
          x.message
        ];
}

function authenticate(username, password) {
  var authenticationDetails = new AmazonCognitoIdentityJs.AuthenticationDetails({
        Username: username,
        Password: password
      });
  var match = Flags.t.SOLVUU_COGNITO_USER_POOL_ID;
  var match$1 = Flags.t.SOLVUU_COGNITO_CLIENT_ID;
  var userPool = new AmazonCognitoIdentityJs.CognitoUserPool({
        UserPoolId: match !== undefined ? match : Pervasives.failwith("ERROR:SOLVUU_COGNITO_USER_POOL_ID is not defined"),
        ClientId: match$1 !== undefined ? match$1 : Pervasives.failwith("ERROR: SOLVUU_COGNITO_CLIENT_ID is not defined")
      });
  var cognitoUser = new AmazonCognitoIdentityJs.CognitoUser({
        Username: username,
        Pool: userPool
      });
  var onMFARequired = function (username, param) {
    return /* `MFARequired */[
            -224198585,
            {
              username: username,
              user: cognitoUser,
              destination: param.CODE_DELIVERY_DESTINATION,
              error: undefined
            }
          ];
  };
  return AmazonCognitoIdentityJS.User.authenticateUser(cognitoUser, authenticationDetails, (function (param) {
                return onSuccess(username, param);
              }), onFailure, onMFARequired);
}

function usePassword(preload) {
  return Op.useWait(preload, {
              username: "",
              password: "",
              error: undefined
            }, (function (preload, param) {
                if (preload !== undefined) {
                  return Promise.resolve(/* `Done */[
                              759635106,
                              /* `Authenticated */[
                                648407695,
                                preload
                              ]
                            ]);
                } else {
                  var password = param.password;
                  var username = param.username;
                  return authenticate(username, password).then((function (x) {
                                return Promise.resolve(x[0] !== -214572683 ? /* `Done */[
                                              759635106,
                                              x
                                            ] : /* `Retry */[
                                              -101284024,
                                              {
                                                username: username,
                                                password: password,
                                                error: x[1]
                                              }
                                            ]);
                              }));
                }
              }));
}

var Password = {
  username: username,
  setUsername: setUsername,
  password: password,
  setPassword: setPassword,
  error: error,
  onSuccess: onSuccess,
  onFailure: onFailure,
  authenticate: authenticate,
  usePassword: usePassword
};

function code(x) {
  var variant = x[0];
  if (variant !== -783600662) {
    if (variant >= 951752159) {
      return x[1].code;
    } else {
      return "";
    }
  } else {
    return x[1].data.code;
  }
}

function setCode(x, code) {
  if (x[0] !== -783600662) {
    return /* () */0;
  } else {
    return Curry._1(x[1].setInput, (function (prev) {
                  return {
                          code: code,
                          destination: prev.destination,
                          error: prev.error
                        };
                }));
  }
}

function destination(x) {
  var variant = x[0];
  if (variant !== -783600662) {
    if (variant >= 951752159) {
      return x[1].destination;
    } else {
      return "";
    }
  } else {
    return x[1].data.destination;
  }
}

function error$1(x) {
  var variant = x[0];
  if (variant !== -783600662) {
    if (variant >= 951752159) {
      var error$2 = x[1].error;
      if (error$2 === "") {
        return ;
      } else {
        return error$2;
      }
    } else {
      return ;
    }
  } else {
    var error$3 = x[1].data.error;
    if (error$3 === "") {
      return ;
    } else {
      return error$3;
    }
  }
}

function sendMFACode(username, user, code) {
  return AmazonCognitoIdentityJS.User.sendMFACode(user, code, (function (param) {
                return onSuccess(username, param);
              }), onFailure);
}

function useMFA(password) {
  return Op.useWait(password, {
              code: "",
              destination: "",
              error: undefined
            }, (function (password, param) {
                if (password[0] >= 648407695) {
                  return Promise.resolve(/* `Done */[
                              759635106,
                              password[1]
                            ]);
                } else {
                  var match = password[1];
                  var destination = match.destination;
                  var code = param.code;
                  return sendMFACode(match.username, match.user, AmazonCognitoIdentityJS.MFACode.make_unsafe(code)).then((function (x) {
                                return Promise.resolve(x[0] >= 648407695 ? /* `Done */[
                                              759635106,
                                              x[1]
                                            ] : /* `Retry */[
                                              -101284024,
                                              {
                                                code: code,
                                                destination: destination,
                                                error: x[1]
                                              }
                                            ]);
                              }));
                }
              }));
}

var Mfa = {
  code: code,
  setCode: setCode,
  destination: destination,
  error: error$1,
  sendMFACode: sendMFACode,
  useMFA: useMFA
};

function useAuth(param) {
  var resetState = {
    contents: (function (param) {
        return /* () */0;
      })
  };
  var preloadState = usePreload(/* () */0);
  if (preloadState[0] === -783600662) {
    Curry._1(preloadState[1].run, /* () */0);
  }
  var passwordState = usePassword(preloadState);
  var mfaState = useMFA(passwordState);
  var state;
  if (preloadState[0] >= -783600662) {
    state = /* `Preload */[
      43282345,
      preloadState
    ];
  } else {
    var match = preloadState[1];
    var match$1 = match.data;
    if (match$1 !== undefined) {
      resetState.contents = match.reset;
      state = /* `Authenticated */[
        648407695,
        match$1
      ];
    } else if (passwordState[0] >= -783600662) {
      state = /* `Password */[
        578936635,
        passwordState
      ];
    } else {
      var match$2 = passwordState[1];
      var match$3 = match$2.data;
      if (match$3[0] >= 648407695) {
        resetState.contents = match$2.reset;
        state = /* `Authenticated */[
          648407695,
          match$3[1]
        ];
      } else if (mfaState[0] >= -783600662) {
        state = /* `MFA */[
          3844808,
          mfaState
        ];
      } else {
        var match$4 = mfaState[1];
        resetState.contents = match$4.reset;
        state = /* `Authenticated */[
          648407695,
          match$4.data
        ];
      }
    }
  }
  var logout = function (param) {
    Aws.clearCognitoCredentials(/* () */0);
    ReasonReactRouter.push("/");
    return Curry._1(resetState.contents, /* () */0);
  };
  return /* tuple */[
          state,
          logout
        ];
}

export {
  Token ,
  Password ,
  Mfa ,
  useAuth ,
  
}
/* Op Not a pure module */
