

import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Import from "../../import.bs.js";
import * as TermJs from "../../../lib/solvuu/termJs.bs.js";
import * as Js_json from "bs-platform/lib/es6/js_json.js";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Pervasives from "bs-platform/lib/es6/pervasives.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_format from "bs-platform/lib/es6/caml_format.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.bs.js";
import * as Caml_primitive from "bs-platform/lib/es6/caml_primitive.js";
import * as Lang$SolvuuApi from "solvuu-api/./lang.bs.js";
import * as Root$SolvuuApi from "solvuu-api/./root.bs.js";

var blankCriterion = /* tuple */[
  /* AnyColumn */0,
  /* Contain */Block.__(0, [""])
];

var defaultCriteria = /* :: */[
  blankCriterion,
  /* [] */0
];

function matcherCompatibleWithType(matcher, _type_) {
  while(true) {
    var type_ = _type_;
    if (typeof type_ === "number") {
      if (type_ >= 3654864) {
        if (type_ !== 365180284) {
          if (type_ === 737456202) {
            if (typeof matcher === "number" || !matcher.tag) {
              return true;
            } else {
              return false;
            }
          }
          
        } else {
          var exit = 0;
          if (typeof matcher === "number") {
            return false;
          } else {
            switch (matcher.tag | 0) {
              case /* Equal */1 :
              case /* NotEqual */2 :
                  exit = 2;
                  break;
              case /* Contain */0 :
              case /* EqualAnyOf */7 :
                  return false;
              default:
                return true;
            }
          }
          if (exit === 2) {
            var match = matcher[0];
            if (typeof match === "number") {
              return false;
            } else {
              return match[0] === 365180284;
            }
          }
          
        }
      } else if (type_ !== -976970511) {
        if (type_ >= 3654863) {
          var exit$1 = 0;
          if (typeof matcher === "number") {
            return false;
          } else {
            switch (matcher.tag | 0) {
              case /* Equal */1 :
              case /* NotEqual */2 :
                  exit$1 = 2;
                  break;
              case /* GreaterThan */3 :
              case /* LessThan */4 :
              case /* GreaterThanOrEqualTo */5 :
              case /* LessThanOrEqualTo */6 :
                  return true;
              default:
                return false;
            }
          }
          if (exit$1 === 2) {
            var match$1 = matcher[0];
            if (typeof match$1 === "number") {
              return false;
            } else {
              return match$1[0] === 3654863;
            }
          }
          
        }
        
      } else {
        var exit$2 = 0;
        if (typeof matcher === "number") {
          return false;
        } else {
          switch (matcher.tag | 0) {
            case /* Equal */1 :
            case /* NotEqual */2 :
                exit$2 = 2;
                break;
            case /* Contain */0 :
            case /* EqualAnyOf */7 :
                return true;
            default:
              return false;
          }
        }
        if (exit$2 === 2) {
          var match$2 = matcher[0];
          if (typeof match$2 === "number") {
            return false;
          } else {
            return match$2[0] === -976970511;
          }
        }
        
      }
    } else if (type_[0] === 216537025) {
      _type_ = type_[1];
      continue ;
    }
    if (typeof matcher === "number" || matcher.tag) {
      return false;
    } else {
      return true;
    }
  };
}

function addCriterion(criteria) {
  return Pervasives.$at(criteria, /* :: */[
              blankCriterion,
              /* [] */0
            ]);
}

function removeCriterion(criteria, index) {
  return Curry._2(Import.List.map, Curry._2(Import.List.keep, Curry._2(Import.List.mapWithIndex, criteria, (function (index, item) {
                        return /* tuple */[
                                index,
                                item
                              ];
                      })), (function (param) {
                    return param[0] !== index;
                  })), (function (param) {
                return param[1];
              }));
}

function changeCriterionScope(criteria, index, scope) {
  return Curry._2(Import.List.mapWithIndex, criteria, (function (criterionIndex, criterion) {
                var match = criterionIndex === index;
                if (match) {
                  return /* tuple */[
                          scope,
                          criterion[1]
                        ];
                } else {
                  return criterion;
                }
              }));
}

function changeCriterionMatcher(criteria, index, matcher) {
  return Curry._2(Import.List.mapWithIndex, criteria, (function (criterionIndex, criterion) {
                var match = criterionIndex === index;
                if (match) {
                  return /* tuple */[
                          criterion[0],
                          matcher
                        ];
                } else {
                  return criterion;
                }
              }));
}

var Actions = {
  addCriterion: addCriterion,
  removeCriterion: removeCriterion,
  changeCriterionScope: changeCriterionScope,
  changeCriterionMatcher: changeCriterionMatcher
};

function criterionToJs(criterion) {
  var matcher = criterion[1];
  var column = criterion[0];
  var columnFields = column ? /* :: */[
      /* tuple */[
        "column",
        column[0]
      ],
      /* [] */0
    ] : /* [] */0;
  var queryFields;
  var exit = 0;
  var query;
  var query$1;
  var query$2;
  if (typeof matcher === "number") {
    queryFields = /* :: */[
      /* tuple */[
        "query",
        ""
      ],
      /* [] */0
    ];
  } else {
    switch (matcher.tag | 0) {
      case /* Contain */0 :
          query = matcher[0];
          exit = 1;
          break;
      case /* Equal */1 :
          var match = matcher[0];
          var variant = match[0];
          if (variant !== 3654863) {
            if (variant >= 365180284) {
              query$2 = match[1];
              exit = 3;
            } else {
              query = match[1];
              exit = 1;
            }
          } else {
            query$1 = match[1];
            exit = 2;
          }
          break;
      case /* NotEqual */2 :
          var match$1 = matcher[0];
          var variant$1 = match$1[0];
          if (variant$1 !== 3654863) {
            if (variant$1 >= 365180284) {
              query$2 = match$1[1];
              exit = 3;
            } else {
              query = match$1[1];
              exit = 1;
            }
          } else {
            query$1 = match$1[1];
            exit = 2;
          }
          break;
      case /* EqualAnyOf */7 :
          var query$3 = Curry._1(Import.List.toArray, matcher[0]).join("\n");
          queryFields = /* :: */[
            /* tuple */[
              "query",
              query$3
            ],
            /* [] */0
          ];
          break;
      default:
        queryFields = /* :: */[
          /* tuple */[
            "query",
            matcher[0].toString()
          ],
          /* [] */0
        ];
    }
  }
  switch (exit) {
    case 1 :
        queryFields = /* :: */[
          /* tuple */[
            "query",
            query
          ],
          /* [] */0
        ];
        break;
    case 2 :
        queryFields = /* :: */[
          /* tuple */[
            "query",
            query$1.toString()
          ],
          /* :: */[
            /* tuple */[
              "queryType",
              "int"
            ],
            /* [] */0
          ]
        ];
        break;
    case 3 :
        queryFields = /* :: */[
          /* tuple */[
            "query",
            query$2.toString()
          ],
          /* :: */[
            /* tuple */[
              "queryType",
              "float"
            ],
            /* [] */0
          ]
        ];
        break;
    
  }
  var matcherFields;
  if (typeof matcher === "number") {
    matcherFields = matcher === /* IsTrue */0 ? /* :: */[
        /* tuple */[
          "matcher",
          "true"
        ],
        /* [] */0
      ] : /* :: */[
        /* tuple */[
          "matcher",
          "false"
        ],
        /* [] */0
      ];
  } else {
    switch (matcher.tag | 0) {
      case /* Contain */0 :
          matcherFields = /* :: */[
            /* tuple */[
              "matcher",
              "contain"
            ],
            /* [] */0
          ];
          break;
      case /* Equal */1 :
          matcherFields = /* :: */[
            /* tuple */[
              "matcher",
              "equal"
            ],
            /* [] */0
          ];
          break;
      case /* NotEqual */2 :
          matcherFields = /* :: */[
            /* tuple */[
              "matcher",
              "not_equal"
            ],
            /* [] */0
          ];
          break;
      case /* GreaterThan */3 :
          matcherFields = /* :: */[
            /* tuple */[
              "matcher",
              "greater_than"
            ],
            /* [] */0
          ];
          break;
      case /* LessThan */4 :
          matcherFields = /* :: */[
            /* tuple */[
              "matcher",
              "less_than"
            ],
            /* [] */0
          ];
          break;
      case /* GreaterThanOrEqualTo */5 :
          matcherFields = /* :: */[
            /* tuple */[
              "matcher",
              "greater_than_or_equal_to"
            ],
            /* [] */0
          ];
          break;
      case /* LessThanOrEqualTo */6 :
          matcherFields = /* :: */[
            /* tuple */[
              "matcher",
              "less_than_or_equal_to"
            ],
            /* [] */0
          ];
          break;
      case /* EqualAnyOf */7 :
          matcherFields = /* :: */[
            /* tuple */[
              "matcher",
              "equal_any_of"
            ],
            /* [] */0
          ];
          break;
      
    }
  }
  return Json_encode.object_(Pervasives.$at(columnFields, Pervasives.$at(queryFields, matcherFields)));
}

function criteriaToJs(criteria) {
  return Curry._1(Import.List.toArray, Curry._2(Import.List.map, criteria, criterionToJs));
}

function criterionFromJs(criterionJs) {
  var columnJs = Json_decode.optional((function (param) {
          return Json_decode.field("column", Json_decode.string, param);
        }), criterionJs);
  var __x = Json_decode.optional((function (param) {
          return Json_decode.field("query", Json_decode.string, param);
        }), criterionJs);
  var queryJs = Belt_Option.getWithDefault(__x, "");
  var matcherJs = Json_decode.field("matcher", Json_decode.string, criterionJs);
  var queryTypeJs = Json_decode.optional((function (param) {
          return Json_decode.field("queryType", Json_decode.string, param);
        }), criterionJs);
  var column = columnJs !== undefined ? /* SingleColumn */[columnJs] : /* AnyColumn */0;
  var matcher;
  switch (matcherJs) {
    case "contain" :
        matcher = /* Contain */Block.__(0, [queryJs]);
        break;
    case "equal" :
        if (queryTypeJs !== undefined) {
          switch (queryTypeJs) {
            case "float" :
                matcher = /* Equal */Block.__(1, [/* `Float */[
                      365180284,
                      Caml_format.caml_float_of_string(queryJs)
                    ]]);
                break;
            case "int" :
                matcher = /* Equal */Block.__(1, [/* `Int */[
                      3654863,
                      Caml_format.caml_int_of_string(queryJs)
                    ]]);
                break;
            case "string" :
                matcher = /* Equal */Block.__(1, [/* `String */[
                      -976970511,
                      queryJs
                    ]]);
                break;
            default:
              matcher = Pervasives.failwith("Unknown matcher: " + matcherJs);
          }
        } else {
          matcher = /* Equal */Block.__(1, [/* `String */[
                -976970511,
                queryJs
              ]]);
        }
        break;
    case "equal_any_of" :
        var queries = Curry._1(Import.List.fromArray, queryJs.split("\n"));
        matcher = /* EqualAnyOf */Block.__(7, [queries]);
        break;
    case "false" :
        matcher = /* IsFalse */1;
        break;
    case "greater_than" :
        matcher = /* GreaterThan */Block.__(3, [Caml_format.caml_float_of_string(queryJs)]);
        break;
    case "greater_than_or_equal_to" :
        matcher = /* GreaterThanOrEqualTo */Block.__(5, [Caml_format.caml_float_of_string(queryJs)]);
        break;
    case "less_than" :
        matcher = /* LessThan */Block.__(4, [Caml_format.caml_float_of_string(queryJs)]);
        break;
    case "less_than_or_equal_to" :
        matcher = /* LessThanOrEqualTo */Block.__(6, [Caml_format.caml_float_of_string(queryJs)]);
        break;
    case "not_equal" :
        if (queryTypeJs !== undefined) {
          switch (queryTypeJs) {
            case "float" :
                matcher = /* NotEqual */Block.__(2, [/* `Float */[
                      365180284,
                      Caml_format.caml_float_of_string(queryJs)
                    ]]);
                break;
            case "int" :
                matcher = /* NotEqual */Block.__(2, [/* `Int */[
                      3654863,
                      Caml_format.caml_int_of_string(queryJs)
                    ]]);
                break;
            case "string" :
                matcher = /* NotEqual */Block.__(2, [/* `String */[
                      -976970511,
                      queryJs
                    ]]);
                break;
            default:
              matcher = Pervasives.failwith("Unknown matcher: " + matcherJs);
          }
        } else {
          matcher = /* NotEqual */Block.__(2, [/* `String */[
                -976970511,
                queryJs
              ]]);
        }
        break;
    case "true" :
        matcher = /* IsTrue */0;
        break;
    default:
      matcher = Pervasives.failwith("Unknown matcher: " + matcherJs);
  }
  return /* tuple */[
          column,
          matcher
        ];
}

function criteriaFromJs(criteriaJs) {
  var match = Js_json.classify(criteriaJs);
  if (typeof match === "number") {
    if (match === /* JSONNull */2) {
      return defaultCriteria;
    } else {
      return Pervasives.failwith("Invalid filter criteria");
    }
  } else if (match.tag === /* JSONArray */3) {
    return Curry._1(Import.List.fromArray, Belt_Array.map(match[0], criterionFromJs));
  } else {
    return Pervasives.failwith("Invalid filter criteria");
  }
}

var defaultCriteriaJs = criteriaToJs(defaultCriteria);

function matchColumnName(columnName, param) {
  var label = param[0];
  if (typeof label === "number" || label[0] !== 699531161) {
    return false;
  } else {
    return label[1] === columnName;
  }
}

function containsCaseInsensitive(needle, haystack) {
  if (haystack !== undefined) {
    var needleLowercase = needle.toLowerCase();
    var haystackLowercase = haystack.toLowerCase();
    return haystackLowercase.includes(needleLowercase);
  } else {
    return false;
  }
}

function termContainsQuery(_term, query) {
  while(true) {
    var term = _term;
    if (typeof term === "number") {
      return false;
    } else {
      var variant = term[0];
      if (variant >= 365180284) {
        if (variant !== 737456202) {
          if (variant !== 925978388) {
            if (variant >= 365180285) {
              return false;
            } else {
              return containsCaseInsensitive(query, JSON.stringify(term[1]));
            }
          } else {
            _term = term[1];
            continue ;
          }
        } else {
          return containsCaseInsensitive(query, JSON.stringify(term[1]));
        }
      } else if (variant !== -976970511) {
        if (variant !== 3654863) {
          return false;
        } else {
          return containsCaseInsensitive(query, JSON.stringify(term[1]));
        }
      } else {
        return containsCaseInsensitive(query, term[1]);
      }
    }
  };
}

function termEqualsQuery(_term, _query) {
  while(true) {
    var query = _query;
    var term = _term;
    if (typeof term === "number") {
      return false;
    } else {
      var variant = term[0];
      if (variant >= 3654864) {
        if (variant !== 365180284) {
          if (variant !== 925978388) {
            return false;
          } else {
            _term = term[1];
            continue ;
          }
        } else if (typeof query === "number") {
          return false;
        } else {
          var variant$1 = query[0];
          var contents = term[1];
          if (variant$1 !== -976970511) {
            if (variant$1 !== 365180284) {
              return false;
            } else {
              return Math.abs(contents - query[1]) <= Pervasives.epsilon_float;
            }
          } else {
            return contents.toString() === query[1];
          }
        }
      } else if (variant !== -976970511) {
        if (variant >= 3654863) {
          var variant$2 = query[0];
          var contents$1 = term[1];
          if (variant$2 !== 3654863) {
            if (variant$2 >= 365180284) {
              _query = /* `Float */[
                365180284,
                query[1]
              ];
              _term = /* `Float */[
                365180284,
                contents$1
              ];
              continue ;
            } else {
              return contents$1.toString() === query[1];
            }
          } else {
            return contents$1 === query[1];
          }
        } else {
          return false;
        }
      } else if (typeof query === "number" || query[0] !== -976970511) {
        return false;
      } else {
        return term[1] === query[1];
      }
    }
  };
}

function termEqualsBoolean(_term, query) {
  while(true) {
    var term = _term;
    if (typeof term === "number") {
      return false;
    } else {
      var variant = term[0];
      if (variant !== 737456202) {
        if (variant !== 925978388) {
          return false;
        } else {
          _term = term[1];
          continue ;
        }
      } else {
        return term[1] === query;
      }
    }
  };
}

function compareTermWithNumber(_term, query) {
  while(true) {
    var term = _term;
    if (typeof term === "number") {
      return 0;
    } else {
      var variant = term[0];
      if (variant !== 3654863 && variant !== 365180284) {
        if (variant !== 925978388) {
          return 0;
        } else {
          _term = term[1];
          continue ;
        }
      } else {
        return Caml_primitive.caml_float_compare(term[1], query);
      }
    }
  };
}

function termGreaterThan(term, query) {
  return compareTermWithNumber(term, query) > 0;
}

function termLessThan(term, query) {
  return compareTermWithNumber(term, query) < 0;
}

function termGreaterThanOrEqualTo(term, query) {
  if (termEqualsQuery(term, /* `Float */[
          365180284,
          query
        ])) {
    return true;
  } else {
    return termGreaterThan(term, query);
  }
}

function termLessThanOrEqualTo(term, query) {
  if (termEqualsQuery(term, /* `Float */[
          365180284,
          query
        ])) {
    return true;
  } else {
    return termLessThan(term, query);
  }
}

function constructRowPredicate(scope, cellPredicate) {
  if (scope) {
    var columnName = scope[0];
    return (function (param) {
        var row = param[1];
        if (typeof row === "number" || row[0] !== 847309489) {
          return Pervasives.failwith("Expected the List to contain Records");
        } else {
          return Curry._2(Import.List.some, row[1], (function (column) {
                        if (matchColumnName(columnName, column)) {
                          return Curry._1(cellPredicate, column);
                        } else {
                          return false;
                        }
                      }));
        }
      });
  } else {
    return (function (param) {
        var row = param[1];
        if (typeof row === "number" || row[0] !== 847309489) {
          return Pervasives.failwith("Expected the List to contain Records");
        } else {
          return Curry._2(Import.List.some, row[1], cellPredicate);
        }
      });
  }
}

function cellPredicate(matcher, thing) {
  var make = function (f, query) {
    return /* `App */[
            3257473,
            /* tuple */[
              /* `Var */[
                4298439,
                f
              ],
              /* `Record */[
                847309489,
                /* :: */[
                  /* tuple */[
                    /* `Positional */[
                      -300665356,
                      1
                    ],
                    thing
                  ],
                  /* :: */[
                    /* tuple */[
                      /* `Positional */[
                        -300665356,
                        2
                      ],
                      query
                    ],
                    /* [] */0
                  ]
                ]
              ]
            ]
          ];
  };
  if (typeof matcher === "number") {
    if (matcher === /* IsTrue */0) {
      return make(Root$SolvuuApi.equal, /* `Bool */[
                  737456202,
                  true
                ]);
    } else {
      return make(Root$SolvuuApi.equal, /* `Bool */[
                  737456202,
                  false
                ]);
    }
  } else {
    switch (matcher.tag | 0) {
      case /* Contain */0 :
          return Pervasives.failwith("Contain: Not supported");
      case /* Equal */1 :
          return make(Root$SolvuuApi.equal, matcher[0]);
      case /* NotEqual */2 :
          return make(Root$SolvuuApi.not_equal, matcher[0]);
      case /* GreaterThan */3 :
          return make(Root$SolvuuApi.gt, /* `Float */[
                      365180284,
                      matcher[0]
                    ]);
      case /* LessThan */4 :
          return make(Root$SolvuuApi.lt, /* `Float */[
                      365180284,
                      matcher[0]
                    ]);
      case /* GreaterThanOrEqualTo */5 :
          return make(Root$SolvuuApi.gte, /* `Float */[
                      365180284,
                      matcher[0]
                    ]);
      case /* LessThanOrEqualTo */6 :
          return make(Root$SolvuuApi.lte, /* `Float */[
                      365180284,
                      matcher[0]
                    ]);
      case /* EqualAnyOf */7 :
          return Belt_List.reduce(Curry._2(Import.List.map, matcher[0], (function (query) {
                            return make(Root$SolvuuApi.equal, /* `String */[
                                        -976970511,
                                        query
                                      ]);
                          })), /* `Bool */[
                      737456202,
                      false
                    ], (function (acc, x) {
                        return /* `App */[
                                3257473,
                                /* tuple */[
                                  /* `Var */[
                                    4298439,
                                    Root$SolvuuApi.or_
                                  ],
                                  /* `Record */[
                                    847309489,
                                    /* :: */[
                                      /* tuple */[
                                        /* `Positional */[
                                          -300665356,
                                          1
                                        ],
                                        acc
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          /* `Positional */[
                                            -300665356,
                                            2
                                          ],
                                          x
                                        ],
                                        /* [] */0
                                      ]
                                    ]
                                  ]
                                ]
                              ];
                      }));
      
    }
  }
}

function rowPredicate(rowVar, scope, cellPredicate) {
  if (scope) {
    return Curry._1(cellPredicate, /* `App */[
                3257473,
                /* tuple */[
                  /* `Project */[
                    -1019462983,
                    /* `Named */[
                      699531161,
                      scope[0]
                    ]
                  ],
                  /* `Var */[
                    4298439,
                    rowVar
                  ]
                ]
              ]);
  } else {
    return Pervasives.failwith("AnyColumn: Not supported");
  }
}

function toTerm(table, criteria) {
  var rowVar = Curry._1(Lang$SolvuuApi.Var.Exn.of_string, "row");
  return /* `App */[
          3257473,
          /* tuple */[
            /* `Var */[
              4298439,
              Root$SolvuuApi.filter
            ],
            /* `Record */[
              847309489,
              /* :: */[
                /* tuple */[
                  /* `Positional */[
                    -300665356,
                    1
                  ],
                  table
                ],
                /* :: */[
                  /* tuple */[
                    /* `Positional */[
                      -300665356,
                      2
                    ],
                    /* `Lambda */[
                      89433895,
                      /* tuple */[
                        rowVar,
                        Curry._3(Import.List.reduce, Curry._2(Import.List.map, criteria, (function (param) {
                                    var rowVar$1 = rowVar;
                                    var criterion = param;
                                    var matcher = criterion[1];
                                    var cellPredicate$1 = function (param) {
                                      return cellPredicate(matcher, param);
                                    };
                                    return rowPredicate(rowVar$1, criterion[0], cellPredicate$1);
                                  })), /* `Bool */[
                              737456202,
                              true
                            ], (function (acc, x) {
                                return /* `App */[
                                        3257473,
                                        /* tuple */[
                                          /* `Var */[
                                            4298439,
                                            Root$SolvuuApi.and_
                                          ],
                                          /* `Record */[
                                            847309489,
                                            /* :: */[
                                              /* tuple */[
                                                /* `Positional */[
                                                  -300665356,
                                                  1
                                                ],
                                                acc
                                              ],
                                              /* :: */[
                                                /* tuple */[
                                                  /* `Positional */[
                                                    -300665356,
                                                    2
                                                  ],
                                                  x
                                                ],
                                                /* [] */0
                                              ]
                                            ]
                                          ]
                                        ]
                                      ];
                              }))
                      ]
                    ]
                  ],
                  /* [] */0
                ]
              ]
            ]
          ]
        ];
}

var New = {
  cellPredicate: cellPredicate,
  rowPredicate: rowPredicate,
  toTerm: toTerm
};

function filter(table, criteria) {
  var reduceCriterion = function (indicesWithRows, criterion) {
    var matcher = criterion[1];
    var cellPredicate = function (param) {
      var term = param[1];
      if (typeof matcher === "number") {
        if (matcher === /* IsTrue */0) {
          return termEqualsBoolean(term, true);
        } else {
          return termEqualsBoolean(term, false);
        }
      } else {
        switch (matcher.tag | 0) {
          case /* Contain */0 :
              var query = matcher[0];
              if (query === "") {
                return true;
              } else {
                return termContainsQuery(term, query);
              }
          case /* Equal */1 :
              return termEqualsQuery(term, matcher[0]);
          case /* NotEqual */2 :
              return !termEqualsQuery(term, matcher[0]);
          case /* GreaterThan */3 :
              return termGreaterThan(term, matcher[0]);
          case /* LessThan */4 :
              return termLessThan(term, matcher[0]);
          case /* GreaterThanOrEqualTo */5 :
              return termGreaterThanOrEqualTo(term, matcher[0]);
          case /* LessThanOrEqualTo */6 :
              return termLessThanOrEqualTo(term, matcher[0]);
          case /* EqualAnyOf */7 :
              return Curry._2(Import.List.some, matcher[0], (function (query) {
                            return termEqualsQuery(term, /* `String */[
                                        -976970511,
                                        query
                                      ]);
                          }));
          
        }
      }
    };
    var rowPredicate = constructRowPredicate(criterion[0], cellPredicate);
    return Curry._2(Import.List.keep, indicesWithRows, rowPredicate);
  };
  var extractRows = function (table) {
    if (typeof table === "number" || table[0] !== 848054398) {
      return Pervasives.failwith("Cannot filter a non-List term");
    } else {
      return Curry._2(Import.List.mapWithIndex, table[1], (function (index, row) {
                    return /* tuple */[
                            index,
                            row
                          ];
                  }));
    }
  };
  var __x = extractRows(table);
  var indicesWithRows = Curry._3(Import.List.reduce, criteria, __x, reduceCriterion);
  return Curry._2(Import.List.map, indicesWithRows, (function (param) {
                return param[0];
              }));
}

function filterTableWithCriteria(tableJs, criteriaJs) {
  var table = TermJs.convertFromJs(tableJs);
  var criteria = criteriaFromJs(criteriaJs);
  return Curry._1(Import.List.toArray, filter(table, criteria));
}

function pickRowsWithIndices(rows, indices) {
  var rowsArray = Curry._1(Import.List.toArray, rows);
  return Curry._2(Import.List.map, indices, (function (index) {
                return Belt_Array.getExn(rowsArray, index);
              }));
}

function findCellForColumn(columnName, rowContents) {
  return Belt_Option.getExn(Curry._2(Import.List.getBy, rowContents, (function (param) {
                    return matchColumnName(columnName, param);
                  })));
}

export {
  blankCriterion ,
  defaultCriteria ,
  matcherCompatibleWithType ,
  Actions ,
  criterionToJs ,
  criteriaToJs ,
  criterionFromJs ,
  criteriaFromJs ,
  defaultCriteriaJs ,
  matchColumnName ,
  containsCaseInsensitive ,
  termContainsQuery ,
  termEqualsQuery ,
  termEqualsBoolean ,
  compareTermWithNumber ,
  termGreaterThan ,
  termLessThan ,
  termGreaterThanOrEqualTo ,
  termLessThanOrEqualTo ,
  constructRowPredicate ,
  New ,
  filter ,
  filterTableWithCriteria ,
  pickRowsWithIndices ,
  findCellForColumn ,
  
}
/* defaultCriteriaJs Not a pure module */
