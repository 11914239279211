

import * as BlogJs from "./Blog.js";

var make = BlogJs.BlogPost;

var Post = {
  make: make
};

var make$1 = BlogJs.default;

export {
  Post ,
  make$1 as make,
  
}
/* make Not a pure module */
