

import * as Err from "../components/Err.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Import from "../features/import.bs.js";
import * as Record from "../containers/Entities/Record/Record.bs.js";
import * as Select from "../components/Select/Select.bs.js";
import * as Loading from "../components/Loading.bs.js";
import * as Checkbox from "../components/FormControls/Checkbox.bs.js";
import * as FormGroup from "../components/FormGroup/FormGroup.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as WithTestId from "../containers/WithTestId.bs.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_format from "bs-platform/lib/es6/caml_format.js";
import * as ReactExtras from "../lib/solvuu/ReactExtras.bs.js";
import * as ReactSelect from "../lib/ReactSelect.bs.js";
import * as Lang$SolvuuApi from "solvuu-api/./lang.bs.js";
import * as Root$SolvuuApi from "solvuu-api/./root.bs.js";
import * as VerticalLayout from "../components/Layout/VerticalLayout.bs.js";
import * as TermRenderContext from "../lang/TermRenderContext.bs.js";
import * as Layout from "components/Layout";
import * as ReactDebounceInput from "react-debounce-input";
import * as TableVolcanoPlotCss from "./TableVolcanoPlot.css";
import * as TableVisualizationCard from "./TableVisualizationCard.bs.js";

var styles = TableVolcanoPlotCss.default;

function TableVolcanoPlot$Loading(Props) {
  return React.createElement("div", {
              className: styles.centered
            }, React.createElement(Loading.make, { }));
}

function TableVolcanoPlot$XAxisColumnSelect(Props) {
  var xAxisColumnName = Props.xAxisColumnName;
  var columns = Props.columns;
  var dispatch = Props.dispatch;
  var columnSelectOptions = Curry._2(Import.List.map, Curry._2(Import.List.keep, columns, (function (param) {
              return Curry._1(Root$SolvuuApi.Volcano_plot.Arg.dataTypeAvailable, param[1]);
            })), (function (param) {
          var name = param[0];
          return ReactSelect.$$Option.make(name, name);
        }));
  var xAxisColumnSelectValue = xAxisColumnName !== undefined ? xAxisColumnName : "";
  var handleXAxisColumnSelect = function (opt) {
    var value = Belt_Option.map(opt, (function (x) {
            return x.value;
          }));
    return Curry._1(dispatch, /* `UpdateArg */[
                615103405,
                /* tuple */[
                  Root$SolvuuApi.Volcano_plot.Arg.Const.Label.xAxisColumnName,
                  (function (param) {
                      if (value !== undefined) {
                        return /* `Some */[
                                925978388,
                                /* `String */[
                                  -976970511,
                                  value
                                ]
                              ];
                      } else {
                        return /* Null */870828711;
                      }
                    })
                ]
              ]);
  };
  var label = React.createElement(ReactIntl.FormattedMessage, {
        id: "table.volcanoPlot.xAxisSelect.label"
      });
  var info = React.createElement(ReactIntl.FormattedMessage, {
        id: "table.volcanoPlot.xAxisSelect.info"
      });
  return React.createElement(FormGroup.$$Element.make, {
              name: "xAxisColumn",
              children: null
            }, React.createElement(WithTestId.make, {
                  id: "volcano-plot-x-axis-column-select",
                  children: React.createElement(Select.make, {
                        label: label,
                        value: xAxisColumnSelectValue,
                        onChange: handleXAxisColumnSelect,
                        options: columnSelectOptions,
                        clearable: true
                      })
                }), React.createElement(FormGroup.FieldInfoElement.make, {
                  children: info
                }));
}

function TableVolcanoPlot$YAxisColumnSelect(Props) {
  var yAxisColumnName = Props.yAxisColumnName;
  var columns = Props.columns;
  var dispatch = Props.dispatch;
  var columnSelectOptions = Curry._2(Import.List.map, Curry._2(Import.List.keep, columns, (function (param) {
              return Curry._1(Root$SolvuuApi.Volcano_plot.Arg.dataTypeAvailable, param[1]);
            })), (function (param) {
          var name = param[0];
          return ReactSelect.$$Option.make(name, name);
        }));
  var yAxisColumnSelectValue = yAxisColumnName !== undefined ? yAxisColumnName : "";
  var handleYAxisColumnSelect = function (opt) {
    var value = Belt_Option.map(opt, (function (x) {
            return x.value;
          }));
    return Curry._1(dispatch, /* `UpdateArg */[
                615103405,
                /* tuple */[
                  Root$SolvuuApi.Volcano_plot.Arg.Const.Label.yAxisColumnName,
                  (function (param) {
                      if (value !== undefined) {
                        return /* `Some */[
                                925978388,
                                /* `String */[
                                  -976970511,
                                  value
                                ]
                              ];
                      } else {
                        return /* Null */870828711;
                      }
                    })
                ]
              ]);
  };
  var label = React.createElement(ReactIntl.FormattedMessage, {
        id: "table.volcanoPlot.yAxisSelect.label"
      });
  var info = React.createElement(ReactIntl.FormattedMessage, {
        id: "table.volcanoPlot.yAxisSelect.info"
      });
  return React.createElement(FormGroup.$$Element.make, {
              name: "yAxisColumn",
              children: null
            }, React.createElement(WithTestId.make, {
                  id: "volcano-plot-y-axis-column-select",
                  children: React.createElement(Select.make, {
                        label: label,
                        value: yAxisColumnSelectValue,
                        onChange: handleYAxisColumnSelect,
                        options: columnSelectOptions,
                        clearable: true
                      })
                }), React.createElement(FormGroup.FieldInfoElement.make, {
                  children: info
                }));
}

function TableVolcanoPlot$LabelColumnSelect(Props) {
  var labelColumnName = Props.labelColumnName;
  var columns = Props.columns;
  var dispatch = Props.dispatch;
  var selectValue = labelColumnName !== undefined ? labelColumnName : "";
  var selectOptions = Curry._2(Import.List.map, Curry._2(Import.List.keep, columns, (function (param) {
              return Curry._1(Root$SolvuuApi.Volcano_plot.Arg.labelDataTypeAvailable, param[1]);
            })), (function (param) {
          var name = param[0];
          return ReactSelect.$$Option.make(name, name);
        }));
  var handleChange = function (opt) {
    var value = Belt_Option.map(opt, (function (param) {
            return param.value;
          }));
    return Curry._1(dispatch, /* `UpdateArg */[
                615103405,
                /* tuple */[
                  Root$SolvuuApi.Volcano_plot.Arg.Const.Label.labelColumnName,
                  (function (param) {
                      if (value !== undefined) {
                        return /* `Some */[
                                925978388,
                                /* `String */[
                                  -976970511,
                                  value
                                ]
                              ];
                      } else {
                        return /* Null */870828711;
                      }
                    })
                ]
              ]);
  };
  var label = React.createElement(ReactIntl.FormattedMessage, {
        id: "table.scatterMatrix.labelSelect.label"
      });
  var info = React.createElement(ReactIntl.FormattedMessage, {
        id: "table.scatterMatrix.labelSelect.info"
      });
  return React.createElement(FormGroup.$$Element.make, {
              name: "labelColumn",
              children: null
            }, React.createElement(WithTestId.make, {
                  id: "scatter-matrix-label-select",
                  children: React.createElement(Select.make, {
                        label: label,
                        value: selectValue,
                        onChange: handleChange,
                        options: selectOptions,
                        clearable: true
                      })
                }), React.createElement(FormGroup.FieldInfoElement.make, {
                  children: info
                }));
}

function TableVolcanoPlot$LogTransformXAxisCheckbox(Props) {
  var logTransformXAxis = Props.logTransformXAxis;
  var dispatch = Props.dispatch;
  var handleLogTransformXAxisChange = function (checked) {
    return Curry._1(dispatch, /* `UpdateArg */[
                615103405,
                /* tuple */[
                  Root$SolvuuApi.Volcano_plot.Arg.Const.Label.logTransformXAxis,
                  (function (param) {
                      return /* `Bool */[
                              737456202,
                              checked
                            ];
                    })
                ]
              ]);
  };
  var label = React.createElement(ReactIntl.FormattedMessage, {
        id: "table.volcanoPlot.logTransformXAxis.label"
      });
  return React.createElement(Checkbox.make, {
              label: label,
              name: "logTransformXAxis",
              value: logTransformXAxis,
              onChange: handleLogTransformXAxisChange
            });
}

function makeProps(debounceTimeout, value, onChange, children, param) {
  var element = function (renderProp) {
    var value = renderProp.value;
    var onChange = renderProp.onChange;
    return Curry._2(children, value, onChange);
  };
  return {
          debounceTimeout: debounceTimeout,
          value: value,
          onChange: onChange,
          element: element
        };
}

function TableVolcanoPlot$PValueThresholdSlider(Props) {
  var pValueThreshold = Props.pValueThreshold;
  var dispatch = Props.dispatch;
  var predefinedPValues = /* array */[
    0.0,
    0.000001,
    0.00001,
    0.0001,
    0.01,
    0.02,
    0.03,
    0.04,
    0.05,
    0.1,
    1.0
  ];
  var pValueThresholdOf = function (sliderValue) {
    return Belt_Array.getExn(predefinedPValues, sliderValue - 1 | 0);
  };
  var pValueThresholdSliderValueOf = function (pValue) {
    return predefinedPValues.indexOf(pValue) + 1 | 0;
  };
  var handlePValueThresholdSliderChange = function (evt) {
    var newPValueThreshold = pValueThresholdOf(Caml_format.caml_int_of_string(ReactExtras.eventTargetValue(evt)));
    return Curry._1(dispatch, /* `UpdateArg */[
                615103405,
                /* tuple */[
                  Root$SolvuuApi.Volcano_plot.Arg.Const.Label.pValueThreshold,
                  (function (param) {
                      return /* `Float */[
                              365180284,
                              newPValueThreshold
                            ];
                    })
                ]
              ]);
  };
  var last = function (a) {
    return Belt_Array.getExn(a, a.length - 1 | 0);
  };
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: styles.thresholdLabel
                }, React.createElement(ReactIntl.FormattedMessage, {
                      id: "table.volcanoPlot.pValueThresholdSlider.label"
                    })), React.createElement(ReactDebounceInput.DebounceInput, makeProps(300, String(pValueThresholdSliderValueOf(pValueThreshold)), handlePValueThresholdSliderChange, (function (value, onChange) {
                        return React.createElement("div", {
                                    className: styles.threshold
                                  }, React.createElement("input", {
                                        className: styles.thresholdSlider,
                                        max: String(pValueThresholdSliderValueOf(last(predefinedPValues))),
                                        min: pValueThresholdSliderValueOf(Belt_Array.getExn(predefinedPValues, 0)),
                                        type: "range",
                                        value: value,
                                        onChange: onChange
                                      }), React.createElement("div", {
                                        className: styles.thresholdValue
                                      }, pValueThresholdOf(Caml_format.caml_int_of_string(value)).toString()));
                      }), /* () */0)));
}

function TableVolcanoPlot$LogFCThresholdSlider(Props) {
  var logFCThreshold = Props.logFCThreshold;
  var dispatch = Props.dispatch;
  var handleLogFCThresholdSliderChange = function (evt) {
    var value = Caml_format.caml_float_of_string(ReactExtras.eventTargetValue(evt));
    return Curry._1(dispatch, /* `UpdateArg */[
                615103405,
                /* tuple */[
                  Root$SolvuuApi.Volcano_plot.Arg.Const.Label.logFCThreshold,
                  (function (param) {
                      return /* `Float */[
                              365180284,
                              value
                            ];
                    })
                ]
              ]);
  };
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: styles.thresholdLabel
                }, React.createElement(ReactIntl.FormattedMessage, {
                      id: "table.volcanoPlot.logFCThresholdSlider.label"
                    })), React.createElement(ReactDebounceInput.DebounceInput, makeProps(300, logFCThreshold.toString(), handleLogFCThresholdSliderChange, (function (value, onChange) {
                        return React.createElement("div", {
                                    className: styles.threshold
                                  }, React.createElement("input", {
                                        className: styles.thresholdSlider,
                                        max: "10",
                                        min: 0,
                                        step: 0.01,
                                        type: "range",
                                        value: value,
                                        onChange: onChange
                                      }), React.createElement("div", {
                                        className: styles.thresholdValue
                                      }, value));
                      }), /* () */0)));
}

function TableVolcanoPlot$State(Props) {
  var onClose = Props.onClose;
  var state = Props.state;
  var dataType = Props.dataType;
  var dispatch = Props.dispatch;
  var graphic = state.graphic;
  if (typeof dataType !== "number" && dataType[0] === 848054398) {
    var match = dataType[1];
    if (typeof match !== "number" && match[0] === 847309489) {
      var columns = match[1][0];
      return React.createElement(Record.ParsedRecord.make, {
                  children: Curry._2(Root$SolvuuApi.Volcano_plot.Arg.of_record, state.arg, (function (xAxisColumnName, yAxisColumnName, labelColumnName, logTransformXAxis, logFCThreshold, pValueThreshold, param) {
                          var title = React.createElement(ReactIntl.FormattedMessage, {
                                id: "table.volcanoPlot.header"
                              });
                          var controls = React.createElement(Layout.VerticalLayout, VerticalLayout.makeProps(/* x4 */26812, null, /* () */0), React.createElement(Layout.VerticalLayoutItem, {
                                    children: React.createElement(TableVolcanoPlot$XAxisColumnSelect, {
                                          xAxisColumnName: xAxisColumnName,
                                          columns: columns,
                                          dispatch: dispatch
                                        })
                                  }), xAxisColumnName !== undefined ? React.createElement(Layout.VerticalLayoutItem, {
                                      children: React.createElement(TableVolcanoPlot$LogTransformXAxisCheckbox, {
                                            logTransformXAxis: logTransformXAxis,
                                            dispatch: dispatch
                                          })
                                    }) : null, React.createElement(Layout.VerticalLayoutItem, {
                                    children: React.createElement(TableVolcanoPlot$YAxisColumnSelect, {
                                          yAxisColumnName: yAxisColumnName,
                                          columns: columns,
                                          dispatch: dispatch
                                        })
                                  }), React.createElement(Layout.VerticalLayoutItem, {
                                    children: React.createElement(TableVolcanoPlot$LabelColumnSelect, {
                                          labelColumnName: labelColumnName,
                                          columns: columns,
                                          dispatch: dispatch
                                        })
                                  }), React.createElement(Layout.VerticalLayoutItem, {
                                    children: React.createElement(TableVolcanoPlot$PValueThresholdSlider, {
                                          pValueThreshold: pValueThreshold,
                                          dispatch: dispatch
                                        })
                                  }), React.createElement(Layout.VerticalLayoutItem, {
                                    children: React.createElement(TableVolcanoPlot$LogFCThresholdSlider, {
                                          logFCThreshold: logFCThreshold,
                                          dispatch: dispatch
                                        })
                                  }));
                          return React.createElement(TableVisualizationCard.make, {
                                      icon: /* VolcanoPlot */586017299,
                                      title: title,
                                      controls: controls,
                                      onClose: onClose,
                                      children: ReactExtras.withTestId("entity-static-table-volcano-plot", React.createElement("div", {
                                                className: styles.root
                                              }, graphic))
                                    });
                        }))
                });
    }
    
  }
  return React.createElement(Err.Operation.make, {
              message: "Expected table"
            });
}

function TableVolcanoPlot(Props) {
  var onClose = Props.onClose;
  var data = Props.data;
  var $$eval = Props.eval;
  var apiClient = Props.apiClient;
  var renderTerm = Props.renderTerm;
  var term = data[0];
  var state_arg = Curry._3(Lang$SolvuuApi.Record.update, Root$SolvuuApi.Volcano_plot.Arg.Const.Default.arg, Root$SolvuuApi.Volcano_plot.Arg.Const.Label.data, (function (param) {
          return term;
        }));
  var state = {
    arg: state_arg,
    graphic: null
  };
  var match = React.useReducer((function (param, param$1) {
          var apiClient$1 = apiClient;
          var renderTerm$1 = renderTerm;
          var param$2 = param;
          var $$event = param$1;
          var state = param$2[0];
          var evalEffect = function (arg) {
            return /* `Evaluate */[
                    -620618631,
                    /* `App */[
                      3257473,
                      /* tuple */[
                        /* `Var */[
                          4298439,
                          Root$SolvuuApi.volcano_plot
                        ],
                        /* `Record */[
                          847309489,
                          arg
                        ]
                      ]
                    ]
                  ];
          };
          if ($$event[0] >= 615103405) {
            var match = $$event[1];
            var state_arg = Curry._3(Lang$SolvuuApi.Record.update, state.arg, match[0], match[1]);
            var state_graphic = React.createElement(TableVolcanoPlot$Loading, { });
            var state$1 = {
              arg: state_arg,
              graphic: state_graphic
            };
            var effect = evalEffect(state_arg);
            return /* tuple */[
                    state$1,
                    effect
                  ];
          } else {
            var graphic = Curry._6(renderTerm$1, apiClient$1, $$event[1], /* Type_not_available */-883458824, TermRenderContext.root, undefined, /* () */0);
            var state_arg$1 = state.arg;
            var state$2 = {
              arg: state_arg$1,
              graphic: graphic
            };
            return /* tuple */[
                    state$2,
                    undefined
                  ];
          }
        }), /* tuple */[
        state,
        undefined
      ]);
  var dispatch = match[1];
  var match$1 = match[0];
  var effect = match$1[1];
  React.useEffect((function () {
          if (effect !== undefined) {
            Curry._1($$eval, effect[1]).then((function (term) {
                    Curry._1(dispatch, /* `Graphic */[
                          -1051164024,
                          term
                        ]);
                    return Promise.resolve(/* () */0);
                  }));
            return ;
          }
          
        }), /* array */[effect]);
  return React.createElement(TableVolcanoPlot$State, {
              onClose: onClose,
              state: match$1[0],
              dataType: data[1],
              dispatch: dispatch
            });
}

var make = TableVolcanoPlot;

export {
  make ,
  
}
/* styles Not a pure module */
