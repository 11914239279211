import React, { Component } from "react";

import styles from "./style.css";

export default class SidebarSection extends Component {
  render() {
    return <div className={styles.root}>{this.props.children}</div>;
  }
}

export class SectionHeader extends Component {
  render() {
    return <div className={styles.header}>{this.props.children}</div>;
  }
}
