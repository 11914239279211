

import * as Bigarray_bs from "bs-bigarray/src/bigarray_bs.bs.js";
import * as Bigdata$SolvuuApi from "solvuu-api/./bigdata.bs.js";
import * as Bigdata_bs$SolvuuApi0 from "solvuu-api0/./bigdata_bs.bs.js";
import * as Bigdata_numeric_bs$SolvuuApi0 from "solvuu-api0/./bigdata_numeric_bs.bs.js";
import * as Bigdata_strings_bs$SolvuuApi0 from "solvuu-api0/./bigdata_strings_bs.bs.js";

var read_genarray = Bigarray_bs.Genarray.fromJs;

var write_genarray = Bigarray_bs.Genarray.toJs;

function Make_bigarray(T) {
  var kind = T.kind;
  var slice_left = Bigarray_bs.Genarray.slice_left;
  var sub_left = Bigarray_bs.Genarray.sub_left;
  var set = Bigarray_bs.Genarray.set;
  var get = Bigarray_bs.Genarray.get;
  var size_in_bytes = Bigarray_bs.Genarray.size_in_bytes;
  var nth_dim = Bigarray_bs.Genarray.nth_dim;
  var dims = Bigarray_bs.Genarray.dims;
  var num_dims = Bigarray_bs.Genarray.num_dims;
  var create = function (dims) {
    return Bigarray_bs.Genarray.create(kind, Bigarray_bs.c_layout, dims);
  };
  return {
          kind: kind,
          slice_left: slice_left,
          sub_left: sub_left,
          set: set,
          get: get,
          size_in_bytes: size_in_bytes,
          nth_dim: nth_dim,
          dims: dims,
          num_dims: num_dims,
          create: create
        };
}

var slice_left = Bigarray_bs.Genarray.slice_left;

var sub_left = Bigarray_bs.Genarray.sub_left;

var set = Bigarray_bs.Genarray.set;

var get = Bigarray_bs.Genarray.get;

var size_in_bytes = Bigarray_bs.Genarray.size_in_bytes;

var nth_dim = Bigarray_bs.Genarray.nth_dim;

var dims = Bigarray_bs.Genarray.dims;

var num_dims = Bigarray_bs.Genarray.num_dims;

function create(dims) {
  return Bigarray_bs.Genarray.create(Bigarray_bs.float32, Bigarray_bs.c_layout, dims);
}

var partial_arg = {
  create: create,
  num_dims: num_dims,
  dims: dims,
  nth_dim: nth_dim,
  size_in_bytes: size_in_bytes,
  get: get,
  set: set,
  sub_left: sub_left,
  slice_left: slice_left
};

var slice_left$1 = Bigarray_bs.Genarray.slice_left;

var sub_left$1 = Bigarray_bs.Genarray.sub_left;

var set$1 = Bigarray_bs.Genarray.set;

var get$1 = Bigarray_bs.Genarray.get;

var size_in_bytes$1 = Bigarray_bs.Genarray.size_in_bytes;

var nth_dim$1 = Bigarray_bs.Genarray.nth_dim;

var dims$1 = Bigarray_bs.Genarray.dims;

var num_dims$1 = Bigarray_bs.Genarray.num_dims;

function create$1(dims) {
  return Bigarray_bs.Genarray.create(Bigarray_bs.int64, Bigarray_bs.c_layout, dims);
}

var partial_arg$1 = {
  create: create$1,
  num_dims: num_dims$1,
  dims: dims$1,
  nth_dim: nth_dim$1,
  size_in_bytes: size_in_bytes$1,
  get: get$1,
  set: set$1,
  sub_left: sub_left$1,
  slice_left: slice_left$1
};

var slice_left$2 = Bigarray_bs.Genarray.slice_left;

var sub_left$2 = Bigarray_bs.Genarray.sub_left;

var set$2 = Bigarray_bs.Genarray.set;

var get$2 = Bigarray_bs.Genarray.get;

var size_in_bytes$2 = Bigarray_bs.Genarray.size_in_bytes;

var nth_dim$2 = Bigarray_bs.Genarray.nth_dim;

var dims$2 = Bigarray_bs.Genarray.dims;

var num_dims$2 = Bigarray_bs.Genarray.num_dims;

function create$2(dims) {
  return Bigarray_bs.Genarray.create(Bigarray_bs.int32, Bigarray_bs.c_layout, dims);
}

var partial_arg$2 = {
  create: create$2,
  num_dims: num_dims$2,
  dims: dims$2,
  nth_dim: nth_dim$2,
  size_in_bytes: size_in_bytes$2,
  get: get$2,
  set: set$2,
  sub_left: sub_left$2,
  slice_left: slice_left$2
};

var partial_arg$3 = Bigdata$SolvuuApi.Make;

var slice_left$3 = Bigarray_bs.Genarray.slice_left;

var sub_left$3 = Bigarray_bs.Genarray.sub_left;

var set$3 = Bigarray_bs.Genarray.set;

var get$3 = Bigarray_bs.Genarray.get;

var size_in_bytes$3 = Bigarray_bs.Genarray.size_in_bytes;

var nth_dim$3 = Bigarray_bs.Genarray.nth_dim;

var dims$3 = Bigarray_bs.Genarray.dims;

var num_dims$3 = Bigarray_bs.Genarray.num_dims;

function create$3(dims) {
  return Bigarray_bs.Genarray.create(Bigarray_bs.float64, Bigarray_bs.c_layout, dims);
}

var M = (function (param) {
      var param$1 = partial_arg;
      var param$2 = param;
      var param$3 = partial_arg$1;
      var param$4 = param$1;
      var param$5 = param$2;
      return partial_arg$3(partial_arg$2, param$3, param$4, param$5);
    })({
      create: create$3,
      num_dims: num_dims$3,
      dims: dims$3,
      nth_dim: nth_dim$3,
      size_in_bytes: size_in_bytes$3,
      get: get$3,
      set: set$3,
      sub_left: sub_left$3,
      slice_left: slice_left$3
    });

var include = M.Numeric;

var read_t = Bigdata_numeric_bs$SolvuuApi0.read_t$prime(read_genarray, read_genarray, read_genarray, read_genarray);

var Numeric_create_float32 = include.create_float32;

var Numeric_create_float64 = include.create_float64;

var Numeric_create_int32 = include.create_int32;

var Numeric_create_int64 = include.create_int64;

var Numeric_int_exn = include.int_exn;

var Numeric_float_exn = include.float_exn;

var Numeric_empty_float32 = include.empty_float32;

var Numeric_get_float = include.get_float;

var Numeric_get_int = include.get_int;

var Numeric_get = include.get;

var Numeric_set_int = include.set_int;

var Numeric_set_float = include.set_float;

var Numeric_set = include.set;

var Numeric_map = include.map;

var Numeric_num_dims = include.num_dims;

var Numeric_size_in_bytes = include.size_in_bytes;

var Numeric_nth_dim = include.nth_dim;

var Numeric_dims = include.dims;

var Numeric_is_empty = include.is_empty;

var Numeric_foldi = include.foldi;

var Numeric_to_term = include.to_term;

var Numeric_sub_left = include.sub_left;

var Numeric_sub_left_int = include.sub_left_int;

var Numeric_sub_left_float = include.sub_left_float;

var Numeric_slice_left = include.slice_left;

var Numeric_slice_left_int = include.slice_left_int;

var Numeric_slice_left_float = include.slice_left_float;

var Numeric = {
  create_float32: Numeric_create_float32,
  create_float64: Numeric_create_float64,
  create_int32: Numeric_create_int32,
  create_int64: Numeric_create_int64,
  int_exn: Numeric_int_exn,
  float_exn: Numeric_float_exn,
  empty_float32: Numeric_empty_float32,
  get_float: Numeric_get_float,
  get_int: Numeric_get_int,
  get: Numeric_get,
  set_int: Numeric_set_int,
  set_float: Numeric_set_float,
  set: Numeric_set,
  map: Numeric_map,
  num_dims: Numeric_num_dims,
  size_in_bytes: Numeric_size_in_bytes,
  nth_dim: Numeric_nth_dim,
  dims: Numeric_dims,
  is_empty: Numeric_is_empty,
  foldi: Numeric_foldi,
  to_term: Numeric_to_term,
  sub_left: Numeric_sub_left,
  sub_left_int: Numeric_sub_left_int,
  sub_left_float: Numeric_sub_left_float,
  slice_left: Numeric_slice_left,
  slice_left_int: Numeric_slice_left_int,
  slice_left_float: Numeric_slice_left_float,
  read_genarray: read_genarray,
  write_genarray: write_genarray,
  read_t: read_t
};

var include$1 = M.Strings;

var Strings_singleton = include$1.singleton;

var Strings_empty = include$1.empty;

var Strings_of_array = include$1.of_array;

var Strings_of_matrix = include$1.of_matrix;

var Strings_create = include$1.create;

var Strings_get = include$1.get;

var Strings_num_dims = include$1.num_dims;

var Strings_dims = include$1.dims;

var Strings_nth_dim = include$1.nth_dim;

var Strings_get_array = include$1.get_array;

var Strings_get_matrix = include$1.get_matrix;

var Strings_map = include$1.map;

var Strings_foldi = include$1.foldi;

var Strings_to_term = include$1.to_term;

var Strings_reverse_index = include$1.reverse_index;

var Strings_size_in_bytes = include$1.size_in_bytes;

var Strings_is_empty = include$1.is_empty;

var Strings_length = include$1.length;

var Strings_sub_left = include$1.sub_left;

var Strings_slice_left = include$1.slice_left;

var Strings_set = include$1.set;

var Strings = {
  singleton: Strings_singleton,
  empty: Strings_empty,
  of_array: Strings_of_array,
  of_matrix: Strings_of_matrix,
  create: Strings_create,
  get: Strings_get,
  num_dims: Strings_num_dims,
  dims: Strings_dims,
  nth_dim: Strings_nth_dim,
  get_array: Strings_get_array,
  get_matrix: Strings_get_matrix,
  map: Strings_map,
  foldi: Strings_foldi,
  to_term: Strings_to_term,
  reverse_index: Strings_reverse_index,
  size_in_bytes: Strings_size_in_bytes,
  is_empty: Strings_is_empty,
  length: Strings_length,
  sub_left: Strings_sub_left,
  slice_left: Strings_slice_left,
  set: Strings_set,
  read_string_matrix: Bigdata_strings_bs$SolvuuApi0.read_string_matrix,
  write_string_matrix: Bigdata_strings_bs$SolvuuApi0.write_string_matrix,
  read_string_array: Bigdata_strings_bs$SolvuuApi0.read_string_array,
  write_string_array: Bigdata_strings_bs$SolvuuApi0.write_string_array,
  read_t: Bigdata_strings_bs$SolvuuApi0.read_t,
  write_t: Bigdata_strings_bs$SolvuuApi0.write_t,
  read_term: Bigdata_strings_bs$SolvuuApi0.read_term,
  write_term: Bigdata_strings_bs$SolvuuApi0.write_term
};

var read_t$1 = Bigdata_bs$SolvuuApi0.read_t$prime(read_genarray, read_genarray, read_genarray, read_genarray);

var foldi = M.foldi;

var get$4 = M.get;

var num_dims$4 = M.num_dims;

var nth_dim$4 = M.nth_dim;

var dims$4 = M.dims;

var int_exn = M.int_exn;

var float_exn = M.float_exn;

var string_exn = M.string_exn;

var length = M.length;

var slice_left$4 = M.slice_left;

var sub_left$4 = M.sub_left;

var pp_hum = M.pp_hum;

var set$4 = M.set;

var cell_format = M.cell_format;

export {
  read_genarray ,
  write_genarray ,
  Make_bigarray ,
  M ,
  Numeric ,
  Strings ,
  foldi ,
  get$4 as get,
  num_dims$4 as num_dims,
  nth_dim$4 as nth_dim,
  dims$4 as dims,
  int_exn ,
  float_exn ,
  string_exn ,
  length ,
  slice_left$4 as slice_left,
  sub_left$4 as sub_left,
  pp_hum ,
  set$4 as set,
  cell_format ,
  read_t$1 as read_t,
  
}
/* M Not a pure module */
