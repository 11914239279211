

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Pervasives from "bs-platform/lib/es6/pervasives.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

function make(i) {
  return /* :: */[
          /* `Input */[
            -783600662,
            i
          ],
          /* [] */0
        ];
}

function append(t, x) {
  if (t) {
    var match = t[0];
    if (typeof match === "number" || match[0] !== -783600662 || typeof x === "number" || x[0] !== -783600662) {
      return /* :: */[
              x,
              t
            ];
    } else {
      return /* :: */[
              /* `Input */[
                -783600662,
                x[1]
              ],
              t[1]
            ];
    }
  } else {
    return /* :: */[
            x,
            t
          ];
  }
}

function input(_l) {
  while(true) {
    var l = _l;
    if (l) {
      var match = l[0];
      if (typeof match !== "number" && match[0] >= -783600662) {
        return match[1];
      }
      var rest = l[1];
      if (rest) {
        _l = rest;
        continue ;
      } else {
        return Pervasives.failwith("BUG");
      }
    } else {
      return Pervasives.failwith("BUG");
    }
  };
}

function output(_l) {
  while(true) {
    var l = _l;
    if (l) {
      var match = l[0];
      if (typeof match === "number") {
        if (!l[1]) {
          return Pervasives.failwith("BUG");
        }
        
      } else if (match[0] >= -783600662) {
        if (!l[1]) {
          return ;
        }
        
      } else if (!l[1]) {
        return Pervasives.failwith("BUG");
      }
      if (typeof match === "number") {
        _l = l[1];
        continue ;
      } else if (match[0] >= -783600662) {
        _l = l[1];
        continue ;
      } else {
        return Caml_option.some(match[1]);
      }
    } else {
      return Pervasives.failwith("BUG");
    }
  };
}

function status(t) {
  if (t) {
    return t[0];
  } else {
    return Pervasives.failwith("BUG");
  }
}

function prevStatus(t) {
  if (t) {
    var rest = t[1];
    if (rest) {
      return rest;
    } else {
      return ;
    }
  } else {
    return Pervasives.failwith("BUG");
  }
}

function hasLatestRun(t) {
  if (t) {
    var match = t[0];
    if (typeof match === "number") {
      if (!t[1]) {
        return Pervasives.failwith("BUG");
      }
      
    } else if (match[0] >= -783600662) {
      if (!t[1]) {
        return false;
      }
      
    } else if (!t[1]) {
      return Pervasives.failwith("BUG");
    }
    if (typeof match === "number") {
      return false;
    } else {
      return match[0] < -783600662;
    }
  } else {
    return Pervasives.failwith("BUG");
  }
}

function isRunning(t) {
  var match = status(t);
  if (typeof match === "number") {
    return true;
  } else {
    return false;
  }
}

var State = {
  make: make,
  append: append,
  input: input,
  output: output,
  status: status,
  prevStatus: prevStatus,
  hasLatestRun: hasLatestRun,
  isRunning: isRunning
};

function useOp(onChange, init, f, param) {
  var match = React.useReducer((function (param, param$1) {
          var $staropt$star = onChange;
          var state = param;
          var $$event = param$1;
          var onChange$1 = $staropt$star !== undefined ? $staropt$star : (function (param) {
                return /* () */0;
              });
          var match = status(state);
          var next;
          var exit = 0;
          var exit$1 = 0;
          if (typeof match === "number") {
            if (typeof $$event === "number" || $$event[0] !== 759635106) {
              exit$1 = 2;
            } else {
              next = /* `Output */[
                -1055554783,
                $$event[1]
              ];
            }
          } else if (match[0] >= -783600662) {
            if (typeof $$event === "number") {
              if ($$event !== 4103979) {
                exit = 1;
              } else {
                next = /* Running */951752159;
              }
            } else if ($$event[0] !== 637790504) {
              exit = 1;
            } else {
              next = /* `Input */[
                -783600662,
                Curry._1($$event[1], match[1])
              ];
            }
          } else if (typeof $$event === "number" || $$event[0] !== -101336657) {
            exit$1 = 2;
          } else {
            next = /* `Input */[
              -783600662,
              $$event[1]
            ];
          }
          if (exit$1 === 2) {
            if (typeof $$event === "number") {
              if ($$event !== 4103979) {
                exit = 1;
              } else {
                next = undefined;
              }
            } else if ($$event[0] !== 637790504) {
              exit = 1;
            } else {
              next = undefined;
            }
          }
          if (exit === 1) {
            var exit$2 = 0;
            if (typeof match === "number") {
              next = undefined;
            } else {
              var variant = match[0];
              if (variant !== -1055554783 && variant !== -783600662) {
                next = undefined;
              } else {
                exit$2 = 2;
              }
            }
            if (exit$2 === 2) {
              next = typeof $$event === "number" || $$event[0] !== 759635106 ? undefined : Pervasives.failwith("BUG");
            }
            
          }
          if (next !== undefined) {
            var state$1 = append(state, next);
            Curry._1(onChange$1, state$1);
            return state$1;
          } else {
            return state;
          }
        }), make(init));
  var dispatch = match[1];
  var state = match[0];
  var status$1 = status(state);
  var input$1 = input(state);
  React.useEffect((function () {
          if (typeof status$1 === "number") {
            Curry._1(f, input(state)).then((function (x) {
                    Curry._1(dispatch, /* `Done */[
                          759635106,
                          x
                        ]);
                    return Promise.resolve(/* () */0);
                  }));
          }
          return ;
        }), /* array */[status$1]);
  return {
          state: state,
          setInput: (function (f) {
              return Curry._1(dispatch, /* `SetInput */[
                          637790504,
                          f
                        ]);
            }),
          run: (function (param) {
              return Curry._1(dispatch, /* Run */4103979);
            }),
          reset: (function (param) {
              return Curry._1(dispatch, /* `Reset */[
                          -101336657,
                          input$1
                        ]);
            })
        };
}

export {
  State ,
  useOp ,
  
}
/* react Not a pure module */
