

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as TermJs from "./termJs.bs.js";
import * as Pervasives from "bs-platform/lib/es6/pervasives.js";
import * as Fmt$SolvuuApi from "solvuu-api/./fmt.bs.js";
import * as Root$SolvuuApi from "solvuu-api/./root.bs.js";

function igvTrackFormatOf(format) {
  if (typeof format === "number") {
    if (format >= 3556729) {
      if (format !== 486566049) {
        if (format !== 487611253) {
          if (format >= 3556730) {
            return Pervasives.failwith("Format is not supported by IGV");
          } else {
            return "gtf";
          }
        } else {
          return "bigWig";
        }
      } else {
        return "bigBed";
      }
    } else if (format !== 3303854) {
      if (format !== 3304737) {
        return Pervasives.failwith("Format is not supported by IGV");
      } else {
        return "bed";
      }
    } else {
      return "bam";
    }
  } else if (format[0] !== 3553607) {
    return Pervasives.failwith("Format is not supported by IGV");
  } else if (format[1] >= 812255803) {
    return "gff3";
  } else {
    return "gff";
  }
}

function maxBytes(format) {
  if (typeof format === "number") {
    if (format !== 642196241) {
      return 10000000;
    } else {
      return 250000;
    }
  } else if (format[0] >= 737157829) {
    return 2000000;
  } else {
    return 15000000;
  }
}

function ofFormat(format) {
  var support;
  var exit = 0;
  if (typeof format === "number") {
    if (format !== 4002953 && format !== 642196241 && format !== 804241803) {
      support = /* Unsupported */-18684779;
    } else {
      exit = 1;
    }
  } else {
    var variant = format[0];
    if (variant !== 3357606 && variant !== 737157829) {
      support = /* Unsupported */-18684779;
    } else {
      exit = 1;
    }
  }
  if (exit === 1) {
    var maxBytes$1 = maxBytes(format);
    var supported = {
      format: format,
      maxBytes: maxBytes$1
    };
    support = /* `Supported */[
      920430094,
      supported
    ];
  }
  var standaloneSupport;
  var exit$1 = 0;
  if (typeof format === "number") {
    if (format !== 642196241) {
      standaloneSupport = /* Unsupported */-18684779;
    } else {
      exit$1 = 1;
    }
  } else {
    var variant$1 = format[0];
    if (variant$1 !== 3357606 && variant$1 !== 737157829) {
      standaloneSupport = /* Unsupported */-18684779;
    } else {
      exit$1 = 1;
    }
  }
  if (exit$1 === 1) {
    var maxBytes$2 = maxBytes(format);
    var supported$1 = {
      format: format,
      maxBytes: maxBytes$2
    };
    standaloneSupport = /* `Supported */[
      920430094,
      supported$1
    ];
  }
  return {
          support: support,
          standaloneSupport: standaloneSupport
        };
}

function formatExpressionOfTerm(dataJs, formatJs) {
  var data = TermJs.convertFromJs(dataJs);
  var format = Curry._1(Root$SolvuuApi.Fmt.t_of_json, formatJs);
  return TermJs.convertToJs(Curry._2(Fmt$SolvuuApi.Parse.to_term, format, data));
}

function formatExpressionOfUri(uriString, formatJs) {
  var data_001 = /* tuple */[
    /* `Var */[
      4298439,
      Root$SolvuuApi.uri
    ],
    /* `String */[
      -976970511,
      uriString
    ]
  ];
  var data = /* `App */[
    3257473,
    data_001
  ];
  var dataJs = TermJs.convertToJs(data);
  return formatExpressionOfTerm(dataJs, formatJs);
}

function formatOfTerm(termJs) {
  var term = TermJs.convertFromJs(termJs);
  var match = Curry._1(Fmt$SolvuuApi.Parse.of_term, term);
  if (typeof match === "number") {
    return Pervasives.failwith("formatOfTerm");
  } else {
    return Curry._1(Root$SolvuuApi.Fmt.json_of_t, match[1][0]);
  }
}

function formatVisualizationSupport(formatJs) {
  var format = Curry._1(Root$SolvuuApi.Fmt.t_of_json, formatJs);
  var match = ofFormat(format);
  var support = match.support;
  var supported = typeof support === "number" ? false : true;
  var supportedStandalone = typeof match.standaloneSupport === "number" ? false : true;
  var maxBytes = typeof support === "number" ? 0.0 : support[1].maxBytes;
  return {
          supported: supported,
          supportedStandalone: supportedStandalone,
          maxBytes: maxBytes
        };
}

var formats = {
  optional: (function (format) {
      return Curry._1(Root$SolvuuApi.Fmt.json_of_t, /* `Maybe */[
                  374637448,
                  Curry._1(Root$SolvuuApi.Fmt.t_of_json, format)
                ]);
    }),
  gzipped: (function (format) {
      return Curry._1(Root$SolvuuApi.Fmt.json_of_t, /* `Gzip */[
                  793449722,
                  Curry._1(Root$SolvuuApi.Fmt.t_of_json, format)
                ]);
    }),
  string: Curry._1(Root$SolvuuApi.Fmt.json_of_t, /* String */-976970511),
  int: Curry._1(Root$SolvuuApi.Fmt.json_of_t, /* Int */3654863),
  float: Curry._1(Root$SolvuuApi.Fmt.json_of_t, /* Float */365180284),
  bool: Curry._1(Root$SolvuuApi.Fmt.json_of_t, /* Bool */737456202),
  bam: Curry._1(Root$SolvuuApi.Fmt.json_of_t, /* Bam */3303854),
  bamIndex: Curry._1(Root$SolvuuApi.Fmt.json_of_t, /* Bam_index */-50740607),
  bed: Curry._1(Root$SolvuuApi.Fmt.json_of_t, /* Bed */3304737),
  bigBed: Curry._1(Root$SolvuuApi.Fmt.json_of_t, /* Bigbed */486566049),
  bigWig: Curry._1(Root$SolvuuApi.Fmt.json_of_t, /* Bigwig */487611253),
  biom: {
    v2_1: Curry._1(Root$SolvuuApi.Fmt.json_of_t, /* `Biom */[
          737157829,
          /* Version2_1 */648863660
        ]),
    json: Curry._1(Root$SolvuuApi.Fmt.json_of_t, /* `Biom */[
          737157829,
          /* Json */826371656
        ])
  },
  bytes: Curry._1(Root$SolvuuApi.Fmt.json_of_t, /* Bytes */-792364981),
  fasta: Curry._1(Root$SolvuuApi.Fmt.json_of_t, /* `Fasta */[
        243398181,
        Root$SolvuuApi.Fmt.Default.fasta
      ]),
  fastaIndex: Curry._1(Root$SolvuuApi.Fmt.json_of_t, /* Fasta_index */-1040940488),
  fastqPhred33: Curry._1(Root$SolvuuApi.Fmt.json_of_t, /* `Fastq */[
        243398197,
        /* Phred33 */-91267303
      ]),
  fastqPhred64: Curry._1(Root$SolvuuApi.Fmt.json_of_t, /* `Fastq */[
        243398197,
        /* Phred64 */-91266633
      ]),
  fastqSolexa: Curry._1(Root$SolvuuApi.Fmt.json_of_t, /* `Fastq */[
        243398197,
        /* Solexa */-523669922
      ]),
  gffVersion2: Curry._1(Root$SolvuuApi.Fmt.json_of_t, /* `Gff */[
        3553607,
        /* Version2 */812255802
      ]),
  gffVersion3: Curry._1(Root$SolvuuApi.Fmt.json_of_t, /* `Gff */[
        3553607,
        /* Version3 */812255803
      ]),
  gtf: Curry._1(Root$SolvuuApi.Fmt.json_of_t, /* Gtf */3556729),
  html: Curry._1(Root$SolvuuApi.Fmt.json_of_t, /* Html */804241803),
  multiQC: Curry._1(Root$SolvuuApi.Fmt.json_of_t, /* MultiQC */-973518485),
  newick: Curry._1(Root$SolvuuApi.Fmt.json_of_t, /* Newick */642196241),
  png: Curry._1(Root$SolvuuApi.Fmt.json_of_t, /* Png */4002953),
  sam: Curry._1(Root$SolvuuApi.Fmt.json_of_t, /* Sam */4149247),
  vcf: Curry._1(Root$SolvuuApi.Fmt.json_of_t, /* Vcf */4298873),
  vcfIndex: Curry._1(Root$SolvuuApi.Fmt.json_of_t, /* Vcf_index */-953902196),
  solvuuEvalJson: Curry._1(Root$SolvuuApi.Fmt.json_of_t, /* Solvuu_eval_json */-1015245230)
};

var Re_VisualizationSupport = {
  ofFormat: ofFormat
};

var Re = {
  igvTrackFormatOf: igvTrackFormatOf,
  VisualizationSupport: Re_VisualizationSupport
};

export {
  Re ,
  formatOfTerm ,
  formatExpressionOfTerm ,
  formatExpressionOfUri ,
  formatVisualizationSupport ,
  formats ,
  
}
/* formats Not a pure module */
