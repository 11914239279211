

import * as S3 from "../s3.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Import from "../../features/import.bs.js";
import * as Blob$SolvuuApi from "solvuu-api/./blob.bs.js";
import * as S3$1 from "aws-sdk/clients/s3";
import * as Caml_builtin_exceptions from "bs-platform/lib/es6/caml_builtin_exceptions.js";

function make(id, file, apiClient) {
  return {
          id: id,
          file: file,
          apiClient: apiClient
        };
}

function send($$this, onStart, onProgress, onSuccess, onFailure) {
  var $$let = Blob$SolvuuApi.Get_by_id.$$Request;
  var $$let$1 = Blob$SolvuuApi.Get_by_id.$$Response;
  return Import.$great$great$eq(Curry._3($$this.apiClient.f, /* POST */891112544, {
                  entity: Blob$SolvuuApi.Get_by_id.entity,
                  service: Blob$SolvuuApi.Get_by_id.service,
                  $$Request: {
                    path: $$let.path,
                    body: $$let.body,
                    info: $$let.info
                  },
                  $$Response: {
                    ok_of_json: $$let$1.ok_of_json,
                    json_of_ok: $$let$1.json_of_ok,
                    bad_request_of_json: $$let$1.bad_request_of_json,
                    json_of_bad_request: $$let$1.json_of_bad_request,
                    not_found_of_json: $$let$1.not_found_of_json,
                    json_of_not_found: $$let$1.json_of_not_found
                  }
                }, {
                  id: $$this.id,
                  instructions: /* Upload */-1058128351
                }), (function (response) {
                var result;
                if (typeof response === "number") {
                  result = /* `Error */[
                    106380200,
                    /* () */0
                  ];
                } else {
                  var info = response[1].info;
                  result = info[0] >= 17692 ? /* `Ok */[
                      17724,
                      info[1]
                    ] : /* `Error */[
                      106380200,
                      /* () */0
                    ];
                }
                if (result[0] >= 106380200) {
                  return Import.$$return(/* () */0);
                } else {
                  var match = result[1].instructions;
                  if (match !== undefined) {
                    var instructions = match;
                    if (instructions[0] >= 549625211) {
                      return Import.$$return(/* () */0);
                    } else {
                      var instructions$1 = instructions[1][1];
                      if (instructions$1[0] >= -344447064) {
                        throw [
                              Caml_builtin_exceptions.assert_failure,
                              /* tuple */[
                                "S3BlobUpload.re",
                                47,
                                32
                              ]
                            ];
                      }
                      var match$1 = instructions$1[1];
                      var instructions$2 = match$1.instructions;
                      var match$2 = instructions$2.credentials;
                      var credentials = match$2[1];
                      var s3 = new S3$1.default({
                            accessKeyId: credentials.access_key_id,
                            secretAccessKey: credentials.secret_access_key,
                            sessionToken: credentials.session_token,
                            region: credentials.region,
                            maxRetries: 10,
                            s3DisableBodySigning: false,
                            params: {
                              UploadId: match$1.upload_id
                            }
                          });
                      var onUploadProgress = Curry.__1(onProgress);
                      var s3Callback = function (err, data) {
                        if (S3.errBool(err)) {
                          return Curry._1(onFailure, err);
                        } else {
                          return Curry._1(onSuccess, data);
                        }
                      };
                      var upload = s3.upload({
                            Bucket: instructions$2.location.bucket,
                            Key: instructions$2.location.key,
                            Body: $$this.file
                          }, {
                            partSize: 5242880
                          }, s3Callback);
                      upload.on("httpUploadProgress", onUploadProgress);
                      Curry._1(onStart, {
                            blobId: $$this.id
                          });
                      return Import.$$return(/* () */0);
                    }
                  } else {
                    return Import.$$return(/* () */0);
                  }
                }
              }));
}

var mb = 1048576;

export {
  mb ,
  make ,
  send ,
  
}
/* Import Not a pure module */
