import { connect } from "react-redux";

import {
  fetchEntity,
  entityByPathSelector,
  entityFetchByPathSelector,
  entityLoadedByPathSelector,
  entityDisplayTypeSelector
} from "features/entities";
import {
  selectedItemPathSelector,
  resetSelection
} from "features/entities/recordViews";
import Entity from "containers/Entities/Entity/Entity.js";

function mapStateToProps(state, ownProps) {
  const entityPath = ownProps.entityPath;

  return {
    entityPath,
    entity: entityByPathSelector(state, entityPath),
    displayType: entityDisplayTypeSelector(state, entityPath),
    selectedEntityPath: selectedItemPathSelector(state, entityPath),
    meta: {
      fetch: entityFetchByPathSelector(state, entityPath),
      loaded: entityLoadedByPathSelector(state, entityPath)
    }
  };
}

const mapDispatchToProps = { fetchEntity, resetSelection };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Entity);
