

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Import from "./features/import.bs.js";
import * as $$String from "bs-platform/lib/es6/string.js";
import * as TermJs from "./lib/solvuu/termJs.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Fmt$SolvuuApi from "solvuu-api/./fmt.bs.js";
import * as Root$SolvuuApi from "solvuu-api/./root.bs.js";
import * as Caml_js_exceptions from "bs-platform/lib/es6/caml_js_exceptions.js";
import * as Caml_builtin_exceptions from "bs-platform/lib/es6/caml_builtin_exceptions.js";

var Default = {
  inference_bytes_limit: 10240
};

function infer(filename, $staropt$star, apiClient, term) {
  var inferenceBytesLimit = $staropt$star !== undefined ? $staropt$star : 10240;
  if (typeof term === "number") {
    return Import.$$return(/* `Ok */[
                17724,
                /* [] */0
              ]);
  } else {
    var variant = term[0];
    if (variant !== -976970511) {
      if (variant !== 848054398) {
        return Import.$$return(/* `Ok */[
                    17724,
                    /* [] */0
                  ]);
      } else {
        var rows = term[1];
        if (rows) {
          var hd = rows[0];
          if (typeof hd === "number" || hd[0] !== 847309489) {
            return Import.$$return(/* `Ok */[
                        17724,
                        /* [] */0
                      ]);
          } else {
            var record = hd[1];
            if (record) {
              var onlyContainsNamedFields = Curry._3(Import.List.reduce, record, true, (function (acc, param) {
                      var label = param[0];
                      if (typeof label === "number" || label[0] !== 699531161 || !acc) {
                        return false;
                      } else {
                        return true;
                      }
                    }));
              if (onlyContainsNamedFields) {
                return Import.$$return(/* `Ok */[
                            17724,
                            Curry._2(Import.List.map, /* :: */[
                                  Root$SolvuuApi.area_chart,
                                  /* :: */[
                                    Root$SolvuuApi.bar_chart,
                                    /* :: */[
                                      Root$SolvuuApi.box_plot,
                                      /* :: */[
                                        Root$SolvuuApi.bubble_chart,
                                        /* :: */[
                                          Root$SolvuuApi.donut_chart,
                                          /* :: */[
                                            Root$SolvuuApi.dot_plot,
                                            /* :: */[
                                              Root$SolvuuApi.filter,
                                              /* :: */[
                                                Root$SolvuuApi.heat_map,
                                                /* :: */[
                                                  Root$SolvuuApi.hexbin_plot,
                                                  /* :: */[
                                                    Root$SolvuuApi.histogram,
                                                    /* :: */[
                                                      Root$SolvuuApi.kernel_density,
                                                      /* :: */[
                                                        Root$SolvuuApi.krona_chart,
                                                        /* :: */[
                                                          Root$SolvuuApi.line_chart,
                                                          /* :: */[
                                                            Root$SolvuuApi.parallel_coordinates,
                                                            /* :: */[
                                                              Root$SolvuuApi.principal_coordinates,
                                                              /* :: */[
                                                                Root$SolvuuApi.quantile_quantile_plot,
                                                                /* :: */[
                                                                  Root$SolvuuApi.scatter_plot,
                                                                  /* :: */[
                                                                    Root$SolvuuApi.scatter_matrix,
                                                                    /* :: */[
                                                                      Root$SolvuuApi.stats,
                                                                      /* :: */[
                                                                        Root$SolvuuApi.summary_statistics,
                                                                        /* :: */[
                                                                          Root$SolvuuApi.t_test,
                                                                          /* :: */[
                                                                            Root$SolvuuApi.venn_diagram,
                                                                            /* :: */[
                                                                              Root$SolvuuApi.violin_plot,
                                                                              /* :: */[
                                                                                Root$SolvuuApi.volcano_plot,
                                                                                /* [] */0
                                                                              ]
                                                                            ]
                                                                          ]
                                                                        ]
                                                                      ]
                                                                    ]
                                                                  ]
                                                                ]
                                                              ]
                                                            ]
                                                          ]
                                                        ]
                                                      ]
                                                    ]
                                                  ]
                                                ]
                                              ]
                                            ]
                                          ]
                                        ]
                                      ]
                                    ]
                                  ]
                                ], (function (v) {
                                    return /* `Var */[
                                            4298439,
                                            v
                                          ];
                                  }))
                          ]);
              } else {
                return Import.$$return(/* `Ok */[
                            17724,
                            /* [] */0
                          ]);
              }
            } else {
              return Import.$$return(/* `Ok */[
                          17724,
                          /* [] */0
                        ]);
            }
          }
        } else {
          return Import.$$return(/* `Ok */[
                      17724,
                      /* [] */0
                    ]);
        }
      }
    } else {
      var raw = term[1];
      var bytes;
      try {
        bytes = $$String.sub(raw, 0, inferenceBytesLimit);
      }
      catch (raw_exn){
        var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
        if (exn[0] === Caml_builtin_exceptions.invalid_argument) {
          bytes = raw;
        } else {
          throw exn;
        }
      }
      var uri = Belt_Option.map(filename, (function (name) {
              return "file:///" + name;
            }));
      return Import.$great$great$pipe(Curry._3(apiClient.f, /* POST */891112544, Fmt$SolvuuApi.Format_of, {
                      uri: uri,
                      bytes: bytes,
                      response_term_fmt: /* Solvuu_eval_json */-1015245230
                    }), (function (response) {
                    if (typeof response === "number") {
                      return /* Unauthorized */324098644;
                    } else {
                      var info = response[1].info;
                      var variant = info[0];
                      if (variant !== -99004555) {
                        if (variant >= 17692) {
                          return /* `Ok */[
                                  17724,
                                  Curry._2(Import.List.map, info[1], (function (format) {
                                          return TermJs.convertFromJs(TermJs.parseFromString(format));
                                        }))
                                ];
                        } else {
                          return /* `NotFound */[
                                  -296251313,
                                  info[1]
                                ];
                        }
                      } else {
                        return /* `BadRequest */[
                                -365540310,
                                info[1]
                              ];
                      }
                    }
                  }));
    }
  }
}

export {
  Default ,
  infer ,
  
}
/* Import Not a pure module */
