

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Import from "../features/import.bs.js";
import * as Std$Bolt from "bolt/./std.bs.js";
import * as Root$SolvuuApi from "solvuu-api/./root.bs.js";
import * as BubbleChartJs from "./BubbleChart.js";

function ofRecord(record) {
  return Curry._1(Std$Bolt.Res.join, Curry._2(Root$SolvuuApi.Bubble_chart.T.of_record, record, (function (records, sum) {
                    var bubbles = Curry._1(Std$Bolt.Res.all, Curry._2(Import.List.map, records, (function (record) {
                                return Curry._2(Root$SolvuuApi.Bubble_chart.T.Bubble.of_record, record, (function (id, value, label) {
                                              return {
                                                      id: id,
                                                      value: value,
                                                      label: label
                                                    };
                                            }));
                              })));
                    if (bubbles[0] >= 106380200) {
                      return bubbles;
                    } else {
                      var bubbles$1 = bubbles[1];
                      if (bubbles$1) {
                        return /* `Ok */[
                                17724,
                                {
                                  bubbles: Curry._1(Import.List.toArray, bubbles$1),
                                  sum: sum
                                }
                              ];
                      } else {
                        return /* `Error */[
                                106380200,
                                /* :: */[
                                  /* tuple */[
                                    Root$SolvuuApi.Bubble_chart.T.Const.Label.bubbles,
                                    /* Invalid */144245463
                                  ],
                                  /* [] */0
                                ]
                              ];
                      }
                    }
                  })));
}

function ofTerm(term) {
  if (typeof term === "number" || term[0] !== 847309489) {
    return /* `Error */[
            106380200,
            /* Expected_record */-756637544
          ];
  } else {
    var res = ofRecord(term[1]);
    return Curry._2(Std$Bolt.Res.map_error, res, (function (e) {
                  return /* `Record */[
                          847309489,
                          e
                        ];
                }));
  }
}

var Props = {
  ofRecord: ofRecord,
  ofTerm: ofTerm
};

var make = BubbleChartJs.default;

export {
  Props ,
  make ,
  
}
/* make Not a pure module */
