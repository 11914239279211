

import * as React from "react";

var icon = require("./variant-discovery.png");

var tools = /* array */[
  {
    term: /* Bowtie2 */-338593844,
    description: "Ultra-fast, sensitive gapped, short-read aligner."
  },
  {
    term: /* Bwa */3308748,
    description: "Software package for mapping low-divergent sequences against a large reference genome."
  },
  {
    term: /* Stampy */982189878,
    description: "A statistical algorithm for sensitive and fast mapping of Illumina sequence reads."
  },
  {
    term: /* Picard */861529193,
    description: "A set of Java command line tools for manipulating high-throughput sequencing data in various formats such as such as FASTQ, SAM, BAM, CRAM and VCF."
  },
  {
    term: /* GATK */790610449,
    description: "Genome Analysis Toolkit, wide variety of tools with a primary focus on variant discovery and genotyping in high-throughput sequencing data."
  },
  {
    term: /* SAMtools */-134774340,
    description: "Various utilities for manipulating alignments in the SAM format, including sorting, merging, indexing and generating alignments in a per-position format."
  },
  {
    term: /* VCFtools */708460290,
    description: "Easily accessible methods for working with complex genetic variation data in the form of VCF files."
  },
  {
    term: /* Freebayes */507218044,
    description: "A Bayesian haplotype-based variant detector."
  },
  {
    term: /* VarScan */464138212,
    description: "Platform-independent mutation caller for targeted, exome, and whole-genome resequencing data generated from NGS platforms."
  },
  {
    term: /* Platypus */-834977404,
    description: "A haplotype-based variant called for data from NGS platforms."
  },
  {
    term: /* VarDict */298093533,
    description: "A novel and versatile variant caller for next-generation sequencing in cancer research."
  },
  {
    term: /* SNVer */-32349048,
    description: "A statistical tool for variant calling in analysis of pooled or individual NGS data."
  },
  {
    term: /* LoFreq */-80373509,
    description: "Fast and sensitive variant-caller for inferring SNVs and indels from next-generation sequencing data."
  },
  {
    term: /* CNVnator */486079769,
    description: "Discover, genotype, and characterize typical and atypical CNVs from family and population genome sequencing."
  },
  {
    term: /* ExomeCNV */-806855433,
    description: "Detect CNV and LOH from exome sequencing data."
  },
  {
    term: /* BreakDancer */-193391650,
    description: "Genome-wide detection of structural variants from next generation paired-end sequencing reads."
  },
  {
    term: /* Delly2 */527599674,
    description: "Integrated SV prediction method to discover, genotype and visualize deletions, tandem duplications, inversions and translocations at single-nucleotide resolution in next-gen sequencing data."
  },
  {
    term: /* BreaksSeq2 */195738823,
    description: "A junction-mapping algorithm for rapid and enhanced SV detection and genotyping, estimates zygosity of the calls, supports the SAM/BAM file format."
  },
  {
    term: /* Manta */374094439,
    description: "Call SVs and indels from mapped paired-end sequencing reads, optimized for analysis of germline variation in small sets of individuals and somatic variation in tumor/normal sample pairs."
  },
  {
    term: /* Lumpy */270345389,
    description: "A novel and general probabilistic SV discovery framework that naturally integrates multiple SV detection signals."
  },
  {
    term: /* ANNOVAR */-595336935,
    description: "Fast and easy variant annotation, including gene-based, region-based and filter-based annotations on a variant call format (VCF) file generated from human genomes."
  },
  {
    term: /* VEP */4292161,
    description: "Determines the effect of variants (SNPs, insertions, deletions, CNVs or structural variants) on genes, transcripts, and protein sequence, as well as regulatory regions."
  },
  {
    term: /* SnpEff */-806396784,
    description: "Genomic variant annotation and functional effect prediction toolbox."
  },
  {
    term: /* SnpSift */717546841,
    description: "Annotate genomic variants using databases, filters, and manipulates annotated variants."
  },
  {
    term: /* VarAFT */135999336,
    description: "Annotate and pinpoint human disease-causing mutations through access to multiple layers of information"
  }
];

var fileTypes = /* array */[
  /* Csv */3357606,
  /* Tsv */4202999,
  /* Sam */4149247,
  /* Bam */3303854,
  /* Cram */748691835,
  /* Fasta */243398181,
  /* Fastq */243398197,
  /* Bed */3304737,
  /* Wig */4349941,
  /* BigBed */484974721,
  /* BigWig */486019925,
  /* BedGraph */-809444755,
  /* Gtf */3556729,
  /* Gff3 */792454412,
  /* Vcf */4298873
];

var visualAnalytics = /* array */[
  {
    icon: /* PieChart */687725842,
    description: "Slice and dice variants based on various features."
  },
  {
    icon: /* BarChart */-298608085,
    description: "Slice and dice variants based on various features."
  },
  {
    icon: /* VennDiagram */83361156,
    description: "Generate Venn diagrams of shared variants across cohorts."
  },
  {
    icon: /* Heatmap */988997452,
    description: "Visualize SV breakpoints."
  },
  {
    icon: /* CircularPlot */359953472,
    description: "Visualize balanced SVs, unbalanced SVs and non-canonical complex SVs (cxSVs)."
  },
  {
    icon: /* PhylogeneticTree */799219443,
    description: "Evolutionary relationships of cultivars or lines mapped to a reference strain."
  },
  {
    icon: /* GenomeBrowser */-265814703,
    description: "Visualize your alignments and variants overlaid with annotation."
  }
];

var description = React.createElement("p", undefined, "With rapidly falling sequencing costs, whole-exome sequencing (WES) and\n    whole-genome sequencing (WGS) using NGS technologies have become de facto\n    standards to characterize individual genomic landscapes to identify causal\n    genomic variants relevant for understanding disease mechanisms, diagnosis\n    and therapy.");

var overview = React.createElement(React.Fragment, undefined, React.createElement("p", undefined, "Understand the role of genetic variation in human disease, ancestry, and\n        evolution using community standard GATK-based best practices workflows,\n        or your aligner and variant caller of choice for analyzing Whole Exome\n        Sequencing (WES) and Whole Genome Sequencing (WGS) data. Our intelligent\n        execution engine can handle any number of samples, as it automatically\n        parallelizes jobs and minimizes IO. Our workflows can capture both large\n        and small variants, indels, copy number changes, and large structural\n        variants and help you identify potential causative variants for further\n        analysis."), React.createElement("p", undefined, "Use popular tools like ANNOVAR, VEP, VarAFT, SnpEff, etc for variant\n        annotation. Upload your VCF files, annotate and filter your variants\n        with gene and transcript features, functional information, allele\n        frequency, evolutionary features, etc. Integrate your results with\n        curated resources like Online Mendelian Inheritance in Man (OMIM), Human\n        Phenotype Ontology (HPO), catalog of cancer-specific databases like\n        COSMIC, CIViC, ClinVar, cBioPortal, NCI Genomic Data Commons, etc."), React.createElement("p", undefined, "Overlay filtered variants with other omics datasets, namely, expression\n        (coding and non-coding genes), epigenomics (methylation, histone\n        modifications), chromatin accessibility, etc and visualize using our\n        genome browser."));

var id = "variant-discovery";

var title = "Variant Discovery";

export {
  id ,
  title ,
  icon ,
  overview ,
  description ,
  tools ,
  fileTypes ,
  visualAnalytics ,
  
}
/* icon Not a pure module */
