import React, { Component } from "react";
import PropTypes from "prop-types";
import onClickOutside from "react-onclickoutside";

class OnClickOutside extends Component {
  static propTypes = {
    onClickOutside: PropTypes.func.isRequired,
    children: PropTypes.func.isRequired
  };

  containerRef = React.createRef();

  // This handler is being called by react-onclickoutside
  handleClickOutside = event => {
    this.props.onClickOutside(event);
  };

  // This callback is being called by react-onclickoutside
  setClickOutsideRef = () => {
    return this.containerRef.current;
  };

  render() {
    return this.props.children(this.containerRef);
  }
}

export default onClickOutside(OnClickOutside);
