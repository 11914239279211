

import * as Flags from "../flags.bs.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as ReactHelmetAsync from "../lib/ReactHelmetAsync.bs.js";

function DocumentTitle(Props) {
  var id = Props.id;
  var values = Props.values;
  var match = Flags.t.NODE_ENV;
  var defer = match === "test" ? false : true;
  var tmp = {
    id: id,
    children: (function (title) {
        return React.createElement(ReactHelmetAsync.Helmet.make, {
                    title: title,
                    defer: defer
                  });
      })
  };
  if (values !== undefined) {
    tmp.values = Caml_option.valFromOption(values);
  }
  return React.createElement(ReactIntl.FormattedMessage, tmp);
}

var make = DocumentTitle;

export {
  make ,
  
}
/* Flags Not a pure module */
