import React, { Component } from "react";
import PropTypes from "prop-types";

import SolvuuPropTypes from "lib/solvuu/solvuuPropTypes";
import * as FormatUtils from "lib/solvuu/formatUtils.bs";

class IGVBrowser extends Component {
  static propTypes = {
    reference: PropTypes.shape({
      url: PropTypes.string.isRequired,
      indexURL: PropTypes.string.isRequired
    }).isRequired,
    tracks: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        format: SolvuuPropTypes.format.isRequired,
        url: PropTypes.string.isRequired,
        indexURL: PropTypes.string.isRequired
      })
    ).isRequired
  };

  componentDidMount() {
    this.renderIGVBrowser(this.container);
  }

  shouldComponentUpdate(nextProps) {
    return (
      this.props.reference !== nextProps.reference ||
      this.props.tracks !== nextProps.tracks
    );
  }

  componentDidUpdate() {
    this.renderIGVBrowser(this.container);
  }

  generateIGVReference = () => {
    return {
      fastaURL: this.props.reference.url,
      indexURL: this.props.reference.indexURL
    };
  };

  generateIGVTrackFormat = track => {
    return FormatUtils.Re.igvTrackFormatOf(track.format);
  };

  generateIGVTracks = () => {
    return this.props.tracks.map(track => ({
      url: track.url,
      indexURL: track.indexURL,
      name: track.name,
      format: this.generateIGVTrackFormat(track)
    }));
  };

  generateIGVLocus = () => {
    // HACK: focus on interesting region for these particular files
    if (
      this.props.tracks.find(track =>
        track.url.includes("wgEncodeCshlLongRnaSeqA549CellLongnonpolyaAlnRep2")
      )
    ) {
      return "chr1:186,905,614-186,920,827";
    } else if (
      this.props.tracks.find(track =>
        track.url.includes(
          "ALL.wgs.integrated_phase1_v3.20101123.snps_indels_sv.sites"
        )
      )
    ) {
      return "chr1:206,239,588-206,300,003";
    }
  };

  renderIGVBrowser = div => {
    const igvOptions = {
      showNavigation: true,
      showRuler: true,
      locus: this.generateIGVLocus(),
      reference: this.generateIGVReference(),
      tracks: this.generateIGVTracks(),
      trackDefaults: {
        annotation: {
          displayMode: "EXPANDED"
        },
        alignment: {
          displayMode: "EXPANDED"
        }
      }
    };
    // prettier-ignore
    Promise.all([
      import("igv-beta/igv-beta.js" /* webpackChunkName:"igv" */),
      import("!style-loader!css-loader?modules=false!igv-beta/igv-beta.css" /* webpackChunkName:"igv" */),
      import("!style-loader!css-loader?modules=false!font-awesome/css/font-awesome.css" /* webpackChunkName:"igv" */)
    ])
      .then(([igv]) => {
        igv.createBrowser(div, igvOptions);
      })
      .catch(e => {
        console.warn("Failed to initialize IGV.js", e);
      });
  };

  render() {
    return <div ref={el => (this.container = el)} />;
  }
}

export default IGVBrowser;
