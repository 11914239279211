

import * as Pervasives from "bs-platform/lib/es6/pervasives.js";
import * as IntlUtils from "@formatjs/intl-utils";

function toString(x) {
  if (x >= -754457900) {
    if (x >= 4994652) {
      if (x >= 207603520) {
        return "month";
      } else {
        return "day";
      }
    } else if (x >= -465055884) {
      return "second";
    } else {
      return "minute";
    }
  } else if (x >= -822779916) {
    if (x >= -800601667) {
      return "year";
    } else {
      return "week";
    }
  } else if (x >= -934569940) {
    return "quarter";
  } else {
    return "hour";
  }
}

function ofString(x) {
  switch (x) {
    case "day" :
        return /* day */4994652;
    case "hour" :
        return /* hour */-988622556;
    case "minute" :
        return /* minute */-754457900;
    case "month" :
        return /* month */207603520;
    case "quarter" :
        return /* quarter */-934569940;
    case "second" :
        return /* second */-465055884;
    case "week" :
        return /* week */-822779916;
    case "year" :
        return /* year */-800601667;
    default:
      return Pervasives.failwith("BUG: Invalid unit " + x);
  }
}

var Unit = {
  toString: toString,
  ofString: ofString
};

function selectUnit(to_, from) {
  var x = IntlUtils.selectUnit(from, to_);
  return {
          value: x.value,
          unit: ofString(x.unit)
        };
}

var IntlUtils$1 = {
  Unit: Unit,
  selectUnit: selectUnit
};

export {
  IntlUtils$1 as IntlUtils,
  
}
/* @formatjs/intl-utils Not a pure module */
