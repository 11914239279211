

import * as React from "react";

var icon = require("./gene-regulation.png");

var tools = /* array */[
  {
    term: /* Bowtie2 */-338593844,
    description: "Ultra-fast, sensitive gapped, short-read aligner."
  },
  {
    term: /* Bwa */3308748,
    description: "Software package for mapping low-divergent sequences against a large reference genome."
  },
  {
    term: /* MACS2 */17082542,
    description: "Model-based Analysis of ChIP-Seq (MACS), for identifying transcription factor binding sites."
  },
  {
    term: /* DFilter */-981655780,
    description: "Generalized signal detection tool for analyzing NGS data by using ROC-AUC maximizing linear filter for detecting peaks."
  },
  {
    term: /* SICER */-88748902,
    description: "Spatial Clustering for Identification of ChIP-Enriched Regions for epigenomic data."
  },
  {
    term: /* PeakRanger */-901802060,
    description: "Multi-purpose software suite for analyzing ChIP-seq data - estimate noise, calculate library complexity, call narrow and broad peaks, generate coverage files, HTML-based annotation reports, etc. Tools include Ranger, BCP, CCAT, etc."
  },
  {
    term: /* GEM */3546223,
    description: "High resolution peak calling and motif discovery for ChIP-seq and ChIP-exo data."
  },
  {
    term: /* MEME */857345200,
    description: "Motif-based sequence analysis tools for motif discovery, motif enrichment analysis, motif scanning, motif comparison, etc."
  },
  {
    term: /* HOMER */692865363,
    description: "Hypergeometric Optimization of Motif EnRichment) is a suite of tools for motif discovery and for analyzing ChIP-Seq, GRO-Seq, RNA-Seq, DNase-Seq, Hi-C and numerous other types of functional genomics sequencing data sets."
  },
  {
    term: /* BedTools */-905618118,
    description: "Tool to intersect, merge, count, complement, and shuffle genomic intervals from multiple files in widely-used genomic file formats such as BAM, BED, GFF/GTF, VCF."
  },
  {
    term: /* DeepTools */-262983633,
    description: "Suite of python tools particularly developed for the efficient analysis of high-throughput sequencing data, such as ChIP-seq, MNase-seq, etc."
  },
  {
    term: /* BSSeeker2 */966293212,
    description: "Accurate, versatile, ultra-fast pipeline for processing bisulfite-converted reads."
  },
  {
    term: /* Bismark */862746297,
    description: "A flexible aligner and methylation caller for Bisulfite-Seq applications."
  },
  {
    term: /* BSMAP */931767627,
    description: "Whole genome bisulfite sequence mapping program."
  }
];

var fileTypes = /* array */[
  /* Csv */3357606,
  /* Tsv */4202999,
  /* Sam */4149247,
  /* Bam */3303854,
  /* Cram */748691835,
  /* Fasta */243398181,
  /* Fastq */243398197,
  /* Bed */3304737,
  /* Wig */4349941,
  /* BigBed */484974721,
  /* BigWig */486019925,
  /* BedGraph */-809444755,
  /* Gtf */3556729,
  /* Gff3 */792454412
];

var visualAnalytics = /* array */[
  {
    icon: /* Heatmap */988997452,
    description: "Visualize signal intensities of epigenomic markers across tissue types or conditions, and expresses similarities in genes."
  },
  {
    icon: /* VennDiagram */83361156,
    description: "Compare peak lists across various epigenomic markers and tissue types."
  },
  {
    icon: /* VolcanoPlot */586017299,
    description: "Plot differentially methylated genes and regions."
  },
  {
    icon: /* PrincipalCoordinates */-496309491,
    description: "Reduce dimension of data, filter noise, visualize similarities between the biological samples and relate to experimental conditions."
  },
  {
    icon: /* SequenceMotifs */366103461,
    description: "Discover and analyze sequence motifs representing DNA binding sites, protein interaction domains, etc."
  },
  {
    icon: /* GenomeBrowser */-265814703,
    description: "Overlay various epigenomic signals with expression data and reference annotation."
  },
  {
    icon: /* NetworkDiagram */-449223643,
    description: "Understand gene regulation integrating expression with TF binding data, and other interaction data."
  }
];

var description = React.createElement("p", undefined, "Eukaryotic gene regulatory mechanisms that induce or repress the expression\n    of a gene include structural and chemical changes to the chromatin and the\n    DNA, binding of proteins to specific DNA elements, or mechanisms that\n    modulate translation of mRNA.");

var overview = React.createElement(React.Fragment, undefined, React.createElement("p", undefined, "Epigenetic mechanisms regulate many important biological processes\n        during normal growth and development, and these mechanisms are\n        deregulated in diseases such as cancer and diabetes."), React.createElement("p", undefined, "Several techniques for characterizing the numerous regulatory and\n        epigenomic modifications have been coupled with massively parallel\n        sequencing strategies (NGS methods) to understand gene regulation. These\n        include methods for characterizing interactions between protein, DNA,\n        and RNA to survey transcription factor binding sites and histone\n        modifications (ChIP-Seq), understand DNA methylation (Whole-Genome\n        Bisulfite Sequencing (WGBS), Reduced Representation Bisulfite Sequencing\n        (RRBS), Methylated DNA Immunoprecipitation Sequencing (MeDIP-Seq),\n        understand DNA accessibility changes within the chromatin (ATAC-seq,\n        FAIRE-seq, DNase-seq), chromatin conformation capture methods (3C, 4C,\n        5C, Hi-C), nucleosome occupancy and positioning (MNase-Seq, CC-Seq and\n        ChIP-Seq), etc."), React.createElement("p", undefined, "Explore and integrate results from your favorite peak callers by simply\n        uploading your peaks, filter and annotate peaks, search over and subset\n        your data, perform statistical analyses and visualize the data."));

var id = "gene-regulation";

var title = "Gene Regulation";

export {
  id ,
  title ,
  icon ,
  overview ,
  description ,
  tools ,
  fileTypes ,
  visualAnalytics ,
  
}
/* icon Not a pure module */
