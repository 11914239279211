import { combineReducers } from "redux";
import { values } from "ramda";
import { createReducer } from "features/utils";
import { jobMetricsFetchOperation } from "features/jobs/operations";

// ACTIONS

export function fetchJobMetrics(jobId) {
  const { start, success, failure } = jobMetricsFetchOperation.actionCreators;

  return async function(dispatch, getState, { solvuuApi }) {
    dispatch(start({ jobId }));
    const response = await solvuuApi.fetchJobMetrics(jobId);
    if (response.ok) {
      const metrics = response.data;
      dispatch(success({ jobId, metrics }));
    } else {
      dispatch(failure({ jobId }));
    }
  };
}

// INTIAL STATE

const initialByJobIdState = {
  metrics: null,
  meta: {
    fetch: jobMetricsFetchOperation.initialState
  }
};

// INITIAL STATE

export const initialState = {};

// SELECTORS

export function jobMetricsByJobIdSelector(state, jobId) {
  const byJobId = state.jobs.metrics[jobId] || initialByJobIdState;

  return {
    metrics: byJobId.metrics,
    meta: byJobId.meta
  };
}

// STATE TRANSFORMATIONS

function setByJobIdState(state, action) {
  const { jobId } = action.payload;

  return {
    ...state,
    [jobId]: byJobIdReducer(state[jobId], action)
  };
}

// REDUCERS

const metricsReducer = createReducer(initialByJobIdState.metrics, {
  [jobMetricsFetchOperation.actionTypes.SUCCESS]: (state, action) =>
    action.payload.metrics
});

const byJobIdReducer = combineReducers({
  metrics: metricsReducer,
  meta: combineReducers({
    fetch: jobMetricsFetchOperation.reducer
  })
});

const updateViewActions = [...values(jobMetricsFetchOperation.actionTypes)];

export default function(state = initialState, action) {
  if (updateViewActions.includes(action.type)) {
    return setByJobIdState(state, action);
  } else {
    return state;
  }
}
