import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { scaleOrdinal } from "d3-scale";

import TaxonomyLevelSelect from "../TaxonomyLevelSelect";
import TaxonomySummaryTable from "../TaxonomySummaryTable";
import OptimizedSelect from "../OptimizedSelect";
import AreaChartArea from "./AreaChartArea";

import { categorical } from "lib/colors";

import styles from "./style.css";

class AreaChart extends Component {
  static propTypes = {
    chartControls: PropTypes.shape({
      taxonomyLevel: PropTypes.number.isRequired
    }).isRequired,
    setChartControls: PropTypes.func.isRequired
  };

  state = {
    selectedTaxon: null,
    hiddenTaxonomies: []
  };

  handleTaxonomyLevelChange = taxonomyLevel => {
    this.setState({ hiddenTaxonomies: [], selectedTaxon: null });
    this.props.setChartControls({ taxonomyLevel });
  };

  handleSelectedTaxonSelectChange = option => {
    const selectedTaxon = option ? option.value : null;
    this.handleSelectedTaxonChange(selectedTaxon);
  };

  handleSelectedTaxonChange = selectedTaxon => {
    this.setState({ selectedTaxon });
  };

  handleHiddenTaxonomiesChange = hiddenTaxonomies => {
    this.setState({ hiddenTaxonomies });
  };

  render() {
    const { getDataForChart, navigateToSample, chartControls } = this.props;
    const { taxonomyLevel } = chartControls;

    const { selectedTaxon, hiddenTaxonomies } = this.state;

    const {
      chartData,
      sampleIds,
      taxonomies,
      getTaxonomyDistributionInDataset,
      getTaxonomyDistributionInSample,
      getTaxonomiesTotalCounts
    } = getDataForChart(taxonomyLevel);

    const visibleTaxonomies = taxonomies.filter(
      taxonomy => hiddenTaxonomies.indexOf(taxonomy) === -1
    );

    const getTaxonomyColor = scaleOrdinal()
      .domain(taxonomies)
      .range(categorical);

    const formattedCounts = (
      <strong>
        <FormattedNumber value={getTaxonomiesTotalCounts(visibleTaxonomies)} />
      </strong>
    );
    const formattedTaxonomies =
      visibleTaxonomies.length === taxonomies.length ? (
        <strong>
          <FormattedNumber value={taxonomies.length} />
        </strong>
      ) : (
        <strong>
          <FormattedNumber value={visibleTaxonomies.length} />
          {" / "}
          <FormattedNumber value={taxonomies.length} />
        </strong>
      );
    const formattedSamples = (
      <strong>
        <FormattedNumber value={sampleIds.length} />
      </strong>
    );

    return (
      <div className={styles.root} data-testid="area-chart">
        <div className={styles.controls}>
          <TaxonomyLevelSelect
            value={taxonomyLevel}
            onChange={this.handleTaxonomyLevelChange}
          />
        </div>
        <div className={styles.controls}>
          <p className={styles.summary}>
            <FormattedMessage
              id="biom.taxonomicAnalysis.areaChart.summary"
              values={{
                samples: formattedSamples,
                taxonomies: formattedTaxonomies,
                counts: formattedCounts
              }}
            />
          </p>
          <OptimizedSelect
            value={selectedTaxon}
            onChange={this.handleSelectedTaxonSelectChange}
            values={taxonomies}
            clearable
            placeholder={
              <FormattedMessage id="biom.taxonomicAnalysis.areaChart.search.placeholder" />
            }
          />
        </div>
        <div className={styles.chart}>
          <AreaChartArea
            key={`${taxonomyLevel}-${hiddenTaxonomies.join("-")}`}
            data={chartData}
            selectedTaxon={selectedTaxon}
            sampleIds={sampleIds}
            taxonomies={visibleTaxonomies}
            getTaxonomyDistributionInDataset={getTaxonomyDistributionInDataset}
            getTaxonomyDistributionInSample={getTaxonomyDistributionInSample}
            getTaxonomyColor={getTaxonomyColor}
            onSelectedTaxonChange={this.handleSelectedTaxonChange}
            navigateToSample={navigateToSample}
          />
        </div>
        <div className={styles.item}>
          <TaxonomySummaryTable
            taxonomies={taxonomies}
            sampleIds={sampleIds}
            getTaxonomyDistributionInDataset={getTaxonomyDistributionInDataset}
            getTaxonomyDistributionInSample={getTaxonomyDistributionInSample}
            getTaxonomyColor={getTaxonomyColor}
            hiddenTaxonomies={hiddenTaxonomies}
            onChangeHiddenTaxonomies={this.handleHiddenTaxonomiesChange}
            selectedTaxonomy={selectedTaxon}
          />
        </div>
      </div>
    );
  }
}

export default AreaChart;
