/**
 * We're using CommonJS modules here instead of ESM, since the file is loaded both
 * by the browser application and node build script.
 */
const pipe = require("ramda/src/pipe");
const filter = require("ramda/src/filter");
const sortBy = require("ramda/src/sortBy");

function matchesCategory(requestedCategory, blogpostCategories) {
  if (!requestedCategory)
    // Show news and science if no specific category was requested
    return (
      matchesCategory("news", blogpostCategories) ||
      matchesCategory("science", blogpostCategories)
    );
  if (!blogpostCategories) return false;
  if (blogpostCategories.includes(requestedCategory)) {
    return true;
  }
  return false;
}

const getFilteredPosts = (category, posts) =>
  pipe(
    filter(blogpost => matchesCategory(category, blogpost.categories)),
    sortBy(blogpost => -Date.parse(blogpost.date))
  )(posts);

exports.getFilteredPosts = getFilteredPosts;
