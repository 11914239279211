/**
 * Jobs represent long-running sequences of tasks.
 * They can have multiple events associated with them.
 *
 * Actions:
 * * Creating jobs
 * * Fetching all jobs
 * * Fetching one job by ID
 * * Fetching events for an individual job
 * * Fetching output blobs for an individual job
 * * Filtering the jobs list
 *
 * Notes:
 * * The timestamp of last successful fetch (of all jobs / individual job)
 * is also tracked in the state.
 * * Job sagas periodically poll for updates while the job view is displayed.
 */
import { combineReducers } from "redux";
import { indexBy, prop, values } from "ramda";
import { createSelector } from "reselect";
import createCachedSelector from "re-reselect";

import { createReducer, fallbackReducer, groupById } from "features/utils";
import { fetchBlob, blobSelector } from "features/upload";
import {
  jobCreateOperation,
  jobFetchOperation,
  jobEventsFetchOperation,
  jobOutputFetchOperation,
  jobsFetchOperation
} from "features/jobs/operations";
import metricsReducer, {
  initialState as initialMetricsState
} from "features/jobs/metrics";
import { jobsSubscriptionSaga } from "features/jobs/subscriptions";
import {
  outputBlobIds,
  logBlobIds,
  jobMatchesFilter
} from "lib/solvuu/jobUtils";

// CONSTANTS
const CHANGE_JOBS_FILTERS = "jobs/CHANGE_JOBS_FILTERS";

// ACTIONS

export function fetchJobs() {
  const { start, success, failure } = jobsFetchOperation.actionCreators;

  return async function(dispatch, getState, { solvuuApi }) {
    dispatch(start());
    const response = await solvuuApi.fetchJobs();
    if (response.ok) {
      const jobs = response.data;
      dispatch(success({ jobs, timestamp: Date.now() }));
    } else {
      dispatch(failure());
    }
  };
}

export function fetchJob(jobId) {
  const { start, success, failure } = jobFetchOperation.actionCreators;

  return async function(dispatch, getState, { solvuuApi }) {
    dispatch(start({ jobId }));
    const response = await solvuuApi.fetchJob(jobId);
    if (response.ok) {
      const job = response.data;
      dispatch(success({ jobId, job, timestamp: Date.now() }));
    } else {
      dispatch(failure({ jobId }));
    }
  };
}

export function fetchJobEvents(jobId) {
  const { start, success, failure } = jobEventsFetchOperation.actionCreators;

  return async function(dispatch, getState, { solvuuApi }) {
    dispatch(start({ jobId }));
    const response = await solvuuApi.fetchJobEvents(jobId);
    if (response.ok) {
      const data = response.data;
      dispatch(success({ jobId, data }));
    } else {
      dispatch(failure({ jobId }));
    }
  };
}

export function fetchJobOutput(jobId) {
  const { start, success, failure } = jobOutputFetchOperation.actionCreators;

  return async function(dispatch, getState) {
    // Don't re-fetch if ouput blobs already downloaded
    if (jobOutputBlobsSelector(getState(), jobId)) return;

    dispatch(start({ jobId }));
    await dispatch(fetchJob(jobId));

    const blobIds = jobOutputBlobIdsSelector(getState(), jobId);
    if (!blobIds) {
      const message = `Could not fetch job #${jobId}`;
      dispatch(failure({ jobId, message }));
      return;
    }

    await Promise.all(blobIds.map(blobId => dispatch(fetchBlob(blobId))));

    const blobs = jobOutputBlobsSelector(getState(), jobId);
    if (!blobs) {
      const message = `Could not fetch output files for job #${jobId}`;
      dispatch(failure({ jobId, message }));
      return;
    }

    dispatch(success({ jobId }));
  };
}

export function changeJobsFilters({ includeSystemJobs }) {
  return {
    type: CHANGE_JOBS_FILTERS,
    payload: { includeSystemJobs }
  };
}

export const test_internalActions = {
  jobsFetchSuccess: jobsFetchOperation.actionCreators.success
};

// SAGAS

export const jobsSaga = jobsSubscriptionSaga;

// INITIAL STATE

export const initialState = {
  metrics: initialMetricsState,
  jobsById: {},
  visibleJobIds: [],
  eventsById: {},
  visibleEventIds: {},
  jobsFilters: { includeSystemJobs: false },
  meta: {
    jobCreate: jobCreateOperation.initialState,
    jobsFetch: jobsFetchOperation.initialState,
    jobFetch: {},
    eventsFetch: {},
    outputFetch: {}
  }
};

// SELECTORS

function jobsSelector(state) {
  const { jobs } = state;
  return jobs;
}

export function jobsFiltersSelector(state) {
  return jobsSelector(state).jobsFilters;
}

export const jobsListVisibleIdsSelector = createSelector(
  state => jobsSelector(state).visibleJobIds,
  state => jobsSelector(state).jobsById,
  state => jobsFiltersSelector(state),
  (jobIds, jobsById, jobsFilters) => {
    return jobIds.filter(jobId => {
      const job = jobsById[jobId];
      const includeSystemJobs = jobsFilters.includeSystemJobs;
      return jobMatchesFilter(job, includeSystemJobs);
    });
  }
);

export function jobFormSelector(state) {
  return jobsSelector(state).form;
}

export function jobSelector(state, id) {
  return jobsSelector(state).jobsById[id];
}

export function jobEventSelector(state, id) {
  return jobsSelector(state).eventsById[id];
}

export function visibleEventIdsSelector(state, jobId) {
  return jobsSelector(state).visibleEventIds[jobId] || [];
}

export const jobMetaSelector = createCachedSelector(
  (state, jobId) => jobId,
  (state, jobId) => jobsSelector(state).meta,
  (jobId, jobsMeta) => {
    const jobFetch = jobsMeta.jobFetch[jobId] || jobFetchOperation.initialState;
    const eventsFetch =
      jobsMeta.eventsFetch[jobId] || jobEventsFetchOperation.initialState;
    const outputFetch =
      jobsMeta.outputFetch[jobId] || jobOutputFetchOperation.initialState;

    return {
      jobFetch,
      eventsFetch,
      outputFetch
    };
  }
)((state, jobId) => jobId);

export const jobsMetaSelector = createSelector(
  state => jobsSelector(state).meta,
  jobsMeta => ({
    jobsFetch: jobsMeta.jobsFetch,
    jobCreate: jobsMeta.jobCreate
  })
);

export function jobOutputBlobIdsSelector(state, jobId) {
  const job = jobSelector(state, jobId);
  if (!job) return null;
  return outputBlobIds(job);
}

export function jobOutputBlobsSelector(state, jobId) {
  const blobIds = jobOutputBlobIdsSelector(state, jobId);
  if (!blobIds) return null;

  const blobs = blobIds.map(blobId => blobSelector(state, blobId));
  if (blobs.some(blob => !blob)) return null;

  return blobs;
}

export function jobLogBlobIdsSelector(state, jobId) {
  const blobs = jobOutputBlobsSelector(state, jobId);
  if (!blobs) return null;

  return logBlobIds(blobs);
}

// REDUCERS

const getJobId = prop("id");

const jobsByIdReducer = createReducer(initialState.jobsById, {
  [jobsFetchOperation.actionTypes.SUCCESS]: (state, action) => {
    const groupByJobId = indexBy(getJobId);
    const jobsById = groupByJobId(action.payload.jobs);
    return { ...state, ...jobsById };
  },
  [jobFetchOperation.actionTypes.SUCCESS]: (state, action) => {
    const { job } = action.payload;
    return { ...state, [getJobId(job)]: job };
  }
});

const visibleJobIdsReducer = createReducer(initialState.visibleJobIds, {
  [jobsFetchOperation.actionTypes.SUCCESS]: (state, action) =>
    action.payload.jobs.map(getJobId).reverse()
});

function jobsFiltersReducer(state = initialState.jobsFilters, action) {
  switch (action.type) {
    case CHANGE_JOBS_FILTERS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

const eventsByIdReducer = createReducer(initialState.eventsById, {
  [jobEventsFetchOperation.actionTypes.SUCCESS]: (state, action) => {
    const eventsById = groupById(action.payload.data);
    return { ...state, ...eventsById };
  }
});

const visibleEventIdsReducer = createReducer(initialState.visibleEventIds, {
  [jobEventsFetchOperation.actionTypes.SUCCESS]: (state, action) => ({
    ...state,
    [action.payload.jobId]: action.payload.data.map(j => j.id).reverse()
  })
});

const singleJobFetchReducer = fallbackReducer(jobFetchOperation.reducer, {
  [jobFetchOperation.actionTypes.SUCCESS]: (state, action) => ({
    ...jobFetchOperation.reducer(state, action),
    lastSuccessAt: action.payload.timestamp
  })
});

function jobFetchReducer(state = initialState.meta.jobFetch, action) {
  if (values(jobFetchOperation.actionTypes).includes(action.type)) {
    const id = action.payload.jobId;
    const subState = state[id];
    return {
      ...state,
      [id]: singleJobFetchReducer(subState, action)
    };
  }

  return state;
}

function jobEventsFetchReducer(state = initialState.meta.eventsFetch, action) {
  if (values(jobEventsFetchOperation.actionTypes).includes(action.type)) {
    const id = action.payload.jobId;
    const subState = state[id];
    return {
      ...state,
      [id]: jobEventsFetchOperation.reducer(subState, action)
    };
  }

  return state;
}

function jobOutputFetchReducer(state = initialState.meta.outputFetch, action) {
  if (values(jobOutputFetchOperation.actionTypes).includes(action.type)) {
    const id = action.payload.jobId;
    const subState = state[id];
    return {
      ...state,
      [id]: jobOutputFetchOperation.reducer(subState, action)
    };
  }

  return state;
}

const jobsFetchReducer = fallbackReducer(jobsFetchOperation.reducer, {
  [jobsFetchOperation.actionTypes.SUCCESS]: (state, action) => ({
    ...jobsFetchOperation.reducer(state, action),
    lastSuccessAt: action.payload.timestamp
  })
});

export default combineReducers({
  metrics: metricsReducer,
  jobsById: jobsByIdReducer,
  visibleJobIds: visibleJobIdsReducer,
  eventsById: eventsByIdReducer,
  visibleEventIds: visibleEventIdsReducer,
  jobsFilters: jobsFiltersReducer,
  meta: combineReducers({
    jobCreate: jobCreateOperation.reducer,
    jobsFetch: jobsFetchReducer,
    jobFetch: jobFetchReducer,
    eventsFetch: jobEventsFetchReducer,
    outputFetch: jobOutputFetchReducer
  })
});
