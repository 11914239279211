import React from "react";
import ReactDOM from "react-dom";
import * as App from "./App.bs";

import "./index.global.css";
import "./analytics";

const root = document.getElementById("root");

/**
 * Doing ReactDOM.render instead of ReactDOM.hydrate, because we're not using
 * ReactDOMServer to do Server Side Rendering (using simple prerendering with
 * react-snap instead). Doing hydrate here leads to subtle bugs when the
 * prerendered markup does not match current markup (e.g. on non-existing pages
 * prerendered as homepage, when it should be 404 not found.
 */
ReactDOM.render(<App.make />, root);

if (module.hot) {
  module.hot.accept(["./Intl.bs"], () => {
    ReactDOM.render(<App />, root);
  });
}
