import React, { Component } from "react";
import PropTypes from "prop-types";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid } from "recharts";
import { scaleLinear } from "d3-scale";
import { max, extent } from "d3-array";
import { area, curveMonotoneY } from "d3-shape";
import { flatten } from "ramda";

import { primary } from "lib/colors";
import Brushing, { VerticalBrush, getNiceDomain } from "components/Brushing";
import ResponsiveContainer from "components/ResponsiveContainer";

class ViolinShape extends Component {
  static propTypes = {
    x: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    payload: PropTypes.shape({
      densities: PropTypes.array.isRequired
    }).isRequired,
    fill: PropTypes.string.isRequired,
    xScale: PropTypes.func.isRequired,
    yScale: PropTypes.func.isRequired
  };

  render() {
    const {
      x,
      index,
      width,
      payload,
      fill,
      yScale,
      xScale: passedXScale
    } = this.props;

    const xScale = passedXScale.copy().range([x, x + width]);

    const violinArea = area()
      .curve(curveMonotoneY)
      .y(d => yScale(d.value))
      // Adding 0.5 point on either side so even densities near to 0 are visible
      .x0(d => xScale(-d.density) - 0.5)
      .x1(d => xScale(d.density) + 0.5);

    const d = violinArea(payload.densities);
    const [yDomainMin, yDomainMax] = yScale.domain();
    const clipPathId = `clip-${index}`;

    return (
      <g data-testid="violin-plot-shape">
        <clipPath id={clipPathId}>
          <rect
            x={x}
            width={width}
            y={yScale(yDomainMax)}
            height={yScale(yDomainMin) - yScale(yDomainMax)}
          />
        </clipPath>
        <path d={d} fill={fill} clipPath={`url(#${clipPathId})`} />
      </g>
    );
  }
}

class ViolinPlot extends Component {
  static propTypes = {
    series: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        densities: PropTypes.arrayOf(
          PropTypes.shape({
            value: PropTypes.number.isRequired,
            density: PropTypes.number.isRequired
          })
        ).isRequired
      })
    ).isRequired
  };

  render() {
    const { series } = this.props;

    const width = "100%";
    const height = 400;
    const yAxisWidth = 40;
    const xAxisHeight = 30;
    const brushWidth = VerticalBrush.defaultWidth;
    const brushHeight = height - xAxisHeight;
    const margin = { left: yAxisWidth + brushWidth };

    const allPoints = flatten(series.map(s => s.densities));
    const rawYDomain = extent(allPoints, p => p.value);
    const yDomain = getNiceDomain(rawYDomain);

    return (
      <Brushing domain={yDomain}>
        {({ selectedDomain: yDomain, onChange, brushKey }) => {
          const maxDensity = max(allPoints, p => p.density);
          const xDomain = [-maxDensity, maxDensity];
          const yScale = scaleLinear().domain(yDomain);
          const xScale = scaleLinear().domain(xDomain);

          return (
            <ResponsiveContainer width={width} height={height}>
              <BarChart data={series} margin={margin}>
                <XAxis dataKey="label" interval={0} />
                <YAxis
                  dataKey="value"
                  scale={yScale}
                  domain={yScale.domain()}
                  label={{
                    value: "Value",
                    angle: -90,
                    position: "left",
                    dy: -10
                  }}
                />
                <CartesianGrid strokeDasharray="3 3" vertical={false} />
                <Bar
                  dataKey="value"
                  fill={primary}
                  isAnimationActive={false}
                  shape={props => (
                    <ViolinShape {...props} yScale={yScale} xScale={xScale} />
                  )}
                />
                <g>
                  <VerticalBrush
                    key={brushKey}
                    x={0}
                    y={0}
                    width={brushWidth}
                    height={brushHeight}
                    scale={yScale}
                    onChange={onChange}
                  />
                </g>
              </BarChart>
            </ResponsiveContainer>
          );
        }}
      </Brushing>
    );
  }
}

export default ViolinPlot;
