

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Import from "../../features/import.bs.js";

function listToElement(input) {
  return Curry._1(Import.List.toArray, input);
}

function mapListToElement(mapper, elements) {
  return Curry._1(Import.List.toArray, Curry._2(Import.List.mapWithIndex, elements, mapper));
}

function withDataAttribute(attribute, value, element) {
  var props = { };
  props[attribute] = value;
  return React.cloneElement(element, props);
}

function withTestId(testId, element) {
  return withDataAttribute("data-testid", testId, element);
}

function withOptionalTestId(testId, element) {
  if (testId !== undefined) {
    return withDataAttribute("data-testid", testId, element);
  } else {
    return element;
  }
}

function eventTargetValue($$event) {
  return $$event.target.value;
}

function eventTargetChecked($$event) {
  return $$event.target.checked;
}

function jsComponent(name, make, mapProps) {
  var component = function (jsProps) {
    var props = Curry._1(mapProps, jsProps);
    return React.createElement(make, props);
  };
  component.displayName = name;
  return component;
}

export {
  listToElement ,
  mapListToElement ,
  withDataAttribute ,
  withTestId ,
  withOptionalTestId ,
  eventTargetValue ,
  eventTargetChecked ,
  jsComponent ,
  
}
/* react Not a pure module */
