

import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";

function makeProps(bsStyle, bsClass, children, key, param) {
  return {
          bsStyle: Belt_Option.map(bsStyle, (function (x) {
                  if (x >= 512905955) {
                    if (x >= 944645571) {
                      return "success";
                    } else {
                      return "danger";
                    }
                  } else if (x >= 164354652) {
                    return "warning";
                  } else {
                    return "info";
                  }
                })),
          bsClass: bsClass,
          children: children,
          key: key
        };
}

var Alert = {
  makeProps: makeProps
};

function makeProps$1(glyph, param) {
  return {
          glyph: glyph >= -79926039 ? (
              glyph >= 87367608 ? "filter" : "wrench"
            ) : (
              glyph >= -316614181 ? "refresh" : "plus"
            )
        };
}

var Glyphicon = {
  makeProps: makeProps$1
};

function makeProps$2(bsStyle, className, children, param) {
  return {
          bsStyle: Belt_Option.map(bsStyle, (function (x) {
                  if (x >= 164354652) {
                    if (x >= 512905955) {
                      if (x >= 944645571) {
                        return "success";
                      } else {
                        return "danger";
                      }
                    } else if (x >= 465819841) {
                      return "default";
                    } else {
                      return "warning";
                    }
                  } else if (x >= 58474434) {
                    return "primary";
                  } else {
                    return "info";
                  }
                })),
          className: className,
          children: children
        };
}

var Label = {
  makeProps: makeProps$2
};

var ProgressBar = { };

var Table = { };

export {
  Alert ,
  Glyphicon ,
  Label ,
  ProgressBar ,
  Table ,
  
}
/* No side effect */
