

import * as Link from "../../../Link.bs.js";
import * as React from "react";
import * as Js_dict from "bs-platform/lib/es6/js_dict.js";
import * as EntityIcon from "../../../../components/EntityIcon/EntityIcon.bs.js";
import * as WithTestId from "../../../WithTestId.bs.js";
import * as Classnames from "classnames";
import * as ReactIntl from "react-intl";
import * as StyleCss from "./style.css";
import * as FormattedBytes from "../../../../components/FormattedBytes/FormattedBytes.bs.js";

var styles = StyleCss.default;

function Item$EntityActionsDropdown(Props) {
  Props.entityPath;
  return null;
}

var EntityActionsDropdown = {
  make: Item$EntityActionsDropdown
};

function Item(Props) {
  var name = Props.name;
  var route = Props.route;
  var match = React.useState((function () {
          return false;
        }));
  var handleClick = function ($$event) {
    return /* () */0;
  };
  var handleLinkClick = function ($$event) {
    return /* () */0;
  };
  var handleKeyDown = function ($$event) {
    return /* () */0;
  };
  var handleDrag = function ($$event) {
    return /* () */0;
  };
  var handleDrop = function ($$event) {
    return /* () */0;
  };
  var handleDragLeave = function ($$event) {
    return /* () */0;
  };
  return React.createElement(WithTestId.make, {
              id: "record-list-item[" + (name + "]"),
              children: React.createElement("tr", {
                    className: Classnames.default(styles.root, Js_dict.fromArray(/* array */[
                              /* tuple */[
                                styles.rootDragging,
                                match[0]
                              ],
                              /* tuple */[
                                styles.rootSelected,
                                false
                              ]
                            ])),
                    role: "link",
                    tabIndex: 0,
                    onKeyDown: handleKeyDown,
                    onClick: handleClick,
                    onDragEnter: handleDrag,
                    onDragLeave: handleDragLeave,
                    onDragOver: handleDrag,
                    onDrop: handleDrop
                  }, React.createElement("td", {
                        className: styles.cellDragging,
                        colSpan: 3
                      }, React.createElement("div", {
                            className: styles.dragging
                          }, React.createElement(ReactIntl.FormattedMessage, {
                                id: "entities.record.actions.dropFile"
                              }))), React.createElement("td", {
                        className: styles.cellFirst
                      }, React.createElement("div", {
                            className: styles.name
                          }, React.createElement("div", {
                                className: styles.icon
                              }, React.createElement(EntityIcon.make, {
                                    term: /* `String */[
                                      -976970511,
                                      "term"
                                    ],
                                    type_: /* String */-976970511
                                  })), React.createElement(Link.make, {
                                to: route,
                                className: styles.link,
                                onClick: handleLinkClick,
                                tabIndex: -1,
                                children: name
                              }))), null)
            });
}

var make = Item;

export {
  styles ,
  EntityActionsDropdown ,
  make ,
  
}
/* styles Not a pure module */
