import { delay } from "redux-saga";
import { call, cancel, fork, put, take, takeEvery } from "redux-saga/effects";
import createCachedSelector from "re-reselect";

import { shouldSubscribeToUpdates } from "lib/solvuu/blobUtils";
import { fetchBlob, fetchBlobEvents, blobSelector } from "features/upload";

// CONSTANTS

const BLOB_SUBSCRIBE = "upload/BLOB_SUBSCRIBE";
const BLOB_UNSUBSCRIBE = "upload/BLOB_UNSUBSCRIBE";
const BLOB_EVENTS_SUBSCRIBE = "upload/BLOB_EVENTS_SUBSCRIBE";
const BLOB_EVENTS_UNSUBSCRIBE = "upload/BLOB_EVENTS_UNSUBSCRIBE";

// ACTIONS

export function subscribeToUpdates(id) {
  return {
    type: BLOB_SUBSCRIBE,
    payload: { id }
  };
}

export function unsubscribeFromUpdates(id) {
  return {
    type: BLOB_UNSUBSCRIBE,
    payload: { id }
  };
}

export function subscribeToEventUpdates(id) {
  return {
    type: BLOB_EVENTS_SUBSCRIBE,
    payload: { id }
  };
}

export function unsubscribeFromEventUpdates(id) {
  return {
    type: BLOB_EVENTS_UNSUBSCRIBE,
    payload: { id }
  };
}

// SELECTORS

export const shouldSubscribeToUpdatesSelector = createCachedSelector(
  (state, id) => blobSelector(state, id),
  blob => (blob ? shouldSubscribeToUpdates(blob) : false)
)((state, id) => id);

// SAGAS

function getPollingSaga(pollAction, unsubscribeAction) {
  return function* pollingSaga(action) {
    const {
      payload: { id }
    } = action;

    function pollDelayMs(index) {
      if (index < 10) return 3000;
      else return 3000 + (index - 10) * 2000; // Wait 2s longer on each attempt
    }

    function* poll() {
      let pollIndex = 0;
      while (true) {
        const delayMs = pollDelayMs(pollIndex);
        yield call(delay, delayMs);
        yield put.resolve(pollAction(id));
        pollIndex += 1;
      }
    }

    yield fork(poll);
    yield take(
      action => action.type === unsubscribeAction && action.payload.id === id
    );
    yield cancel();
  };
}

export function* blobsSaga() {
  yield takeEvery(BLOB_SUBSCRIBE, getPollingSaga(fetchBlob, BLOB_UNSUBSCRIBE));
  yield takeEvery(
    BLOB_EVENTS_SUBSCRIBE,
    getPollingSaga(fetchBlobEvents, BLOB_EVENTS_UNSUBSCRIBE)
  );
}
