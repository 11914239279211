

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Import from "../../features/import.bs.js";
import * as StyleCss from "./style.css";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as ReactSelect from "react-select";

var styles = StyleCss.default;

function Select(Props) {
  var label = Props.label;
  var value = Props.value;
  var onChange = Props.onChange;
  var options = Props.options;
  var className = Props.className;
  var match = Props.clearable;
  var clearable = match !== undefined ? match : false;
  var match$1 = Props.disabled;
  var disabled = match$1 !== undefined ? match$1 : false;
  return React.createElement("div", {
              className: styles.wrapper
            }, React.createElement(ReactSelect.default, {
                  value: value,
                  onChange: onChange,
                  options: Curry._1(Import.List.toArray, options),
                  className: Cn.make(/* :: */[
                        styles.select,
                        /* :: */[
                          Cn.unpack(className),
                          /* [] */0
                        ]
                      ]),
                  clearable: clearable,
                  disabled: disabled,
                  placeholder: null
                }), label !== undefined ? React.createElement("label", {
                    className: styles.label
                  }, Caml_option.valFromOption(label)) : null);
}

function Select$Creatable(Props) {
  var label = Props.label;
  var value = Props.value;
  var onChange = Props.onChange;
  var options = Props.options;
  var className = Props.className;
  var match = Props.clearable;
  var clearable = match !== undefined ? match : false;
  var match$1 = Props.disabled;
  var disabled = match$1 !== undefined ? match$1 : false;
  var promptTextCreator = Props.promptTextCreator;
  var tmp = {
    value: value,
    onChange: onChange,
    options: Curry._1(Import.List.toArray, options),
    className: Cn.make(/* :: */[
          styles.select,
          /* :: */[
            Cn.unpack(className),
            /* [] */0
          ]
        ]),
    clearable: clearable,
    disabled: disabled,
    placeholder: null
  };
  if (promptTextCreator !== undefined) {
    tmp.promptTextCreator = Caml_option.valFromOption(promptTextCreator);
  }
  return React.createElement("div", {
              className: styles.wrapper
            }, React.createElement(ReactSelect.Creatable, tmp), label !== undefined ? React.createElement("label", {
                    className: styles.label
                  }, Caml_option.valFromOption(label)) : null);
}

var Creatable = {
  make: Select$Creatable
};

var make = Select;

export {
  styles ,
  make ,
  Creatable ,
  
}
/* styles Not a pure module */
