import React, { Component } from "react";
import ReactDOM from "react-dom";

import { ReactComponent as DragAndDropBanner } from "./drag-and-drop-banner.svg";
import styles from "./style.css";

export class WindowDragAndDrop extends Component {
  state = {
    active: false
  };

  handleDragOver = event => {
    event.preventDefault();
    this.setState(({ active }) => (active ? null : { active: true }));
  };

  handleDragLeave = event => {
    this.setState({ active: false });
  };

  handleDragEnd = event => {
    this.setState({ active: false });
  };

  handleDrop = event => {
    event.preventDefault();
    this.setState({ active: false });
    this.props.onDrop(event);
  };

  componentDidMount() {
    this.target = document.body;
    this.node = document.createElement("div");
    this.target.addEventListener("dragover", this.handleDragOver);
    this.target.addEventListener("drop", this.handleDrop);
    this.target.appendChild(this.node);
  }

  componentWillUnmount() {
    this.target.removeEventListener("dragover", this.handleDragOver);
    this.target.removeEventListener("drop", this.handleDrop);
    this.target.removeChild(this.node);
  }

  render() {
    return this.state.active
      ? ReactDOM.createPortal(
          <div
            className={styles.dragOverlay}
            onDragEnd={this.handleDragEnd}
            onDragLeave={this.handleDragLeave}
          >
            <DragAndDropBanner className={styles.dragOverlayBanner} />
            <span className={styles.dragOverlayMessage}>
              Drop the files to upload to Solvuu
            </span>
          </div>,
          this.node
        )
      : null;
  }
}
