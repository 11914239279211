/**
 * Module for managing actions related to displaying blobs:
 *
 * * Downloading a preview of the blob
 * * Getting a signed URL from S3 for inline display
 * * Downloading the blob to user's machine
 *
 * This state is keyed by blob's id - so each blob has its own separate slice of state:
 * {
 *   "1": viewStateForBlobID1,
 *   "2": viewStateForBlobID2
 * }
 */
import { combineReducers } from "redux";
import { values } from "ramda";
import S3 from "aws-sdk/clients/s3";

import { createReducer, createOperation } from "features/utils";
import { fetchBlobIfNecessary, uploadSelector } from "features/upload";
import { saveEntityInStore } from "features/entities";
import {
  inferFileFormat,
  inferFormatSelector,
  INFERENCE_BYTES_LIMIT
} from "features/entities/create/formats";
import * as SolvuuApiWrapper from "lib/solvuu/solvuuApiWrapper.bs";
import {
  formatExpressionOfTerm,
  formatVisualizationSupport
} from "lib/solvuu/formatUtils";
import { Terms } from "lib/solvuu/termUtils";

// ACTION TYPES

export const getDownloadURLOperation = createOperation(
  "ENTITIES/BLOB/GET_DOWNLOAD_URL"
);
export const visualizeAsEntityOperation = createOperation(
  "ENTITIES/BLOB/VISUALIZE"
);
export const getPreviewOperation = createOperation("ENTITIES/BLOB/GET_PREVIEW");
export const downloadOperation = createOperation("ENTITIES/BLOB/DOWNLOAD");

// ACTIONS

/**
 * Constructs a signed URL for the display of the Blob from S3.
 *
 * Note: currently used for embedding MultiQC/Krona HTML or images into an <iframe src="...">
 */
export function getDownloadURL(blobId) {
  const { start, success, failure } = getDownloadURLOperation.actionCreators;

  return async function(dispatch, getState) {
    dispatch(start({ blobId }));

    try {
      const { credentials, bucket, key } = await dispatch(
        getDetailsForDownload(blobId)
      );
      const s3 = new S3({
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey,
        sessionToken: credentials.sessionToken,
        region: credentials.region
      });
      const url = s3.getSignedUrl("getObject", {
        Key: key,
        Bucket: bucket,
        ResponseContentType: "text/html",
        ResponseContentDisposition: "inline"
      });
      dispatch(success({ blobId, url }));
    } catch (e) {
      dispatch(failure({ blobId, message: e.message }));
    }
  };
}

const PREVIEW_BYTES_LIMIT = 1024 * 10;

/**
 * Downloads a small subset of blob data.
 *
 * It's used in the UI for displaying a "preview",
 * so that the user has an overview of blob contents.
 */
export function getPreview(blobId) {
  const { start, success, failure } = getPreviewOperation.actionCreators;

  return async function(dispatch, getState) {
    dispatch(start({ blobId }));

    try {
      const downloadDetails = await dispatch(getDetailsForDownload(blobId));
      const blob = await downloadBlobFromS3(downloadDetails, {
        Range: `bytes=0-${PREVIEW_BYTES_LIMIT - 1}`
      });
      const content = await blobToString(blob);
      const contentBytes = blob.length;
      dispatch(success({ blobId, content, contentBytes }));
    } catch (e) {
      dispatch(failure({ blobId, message: e.message }));
    }
  };
}

/**
 * Downloads and saves the blob on the user's machine (via browser download dialog).
 */
export function download(blobId) {
  const { start, success, failure } = downloadOperation.actionCreators;

  return async function(dispatch, getState) {
    dispatch(start({ blobId }));

    try {
      const { credentials, bucket, key } = await dispatch(
        getDetailsForDownload(blobId)
      );
      const s3 = new S3({
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey,
        sessionToken: credentials.sessionToken,
        region: credentials.region
      });
      const url = s3.getSignedUrl("getObject", {
        Key: key,
        Bucket: bucket,
        ResponseContentDisposition: "attachment"
      });

      // Force the browser to download the file
      const a = document.createElement("a");
      a.href = url;
      a.hidden = true;
      document.body.appendChild(a);
      a.click();

      dispatch(success({ blobId }));
    } catch (e) {
      dispatch(failure({ blobId, message: e.message }));
    }
  };
}

/**
 * Infers the format of the blob and saves it in the store as an entity.
 */
export function visualizeAsEntity(blobId, path) {
  const { start, success, failure } = visualizeAsEntityOperation.actionCreators;

  return async function(dispatch, getState) {
    dispatch(start({ blobId }));
    await fetchBlobIfNecessary(blobId);
    const upload = uploadSelector(getState(), blobId);
    const downloadDetails = await dispatch(getDetailsForDownload(blobId));
    let buffer;
    try {
      buffer = await downloadBlobFromS3(downloadDetails, {
        Range: `bytes=0-${INFERENCE_BYTES_LIMIT - 1}`
      });
    } catch (e) {
      dispatch(failure({ blobId, message: e.message }));
      return;
    }
    const file = new File([buffer], upload.originalName);
    await dispatch(inferFileFormat(path, file));
    const inference = inferFormatSelector(getState(), path);
    if (!inference.format) {
      dispatch(failure({ blobId, message: "Could not infer the file format" }));
      return;
    }

    // Visualize a parsed file only if its size is below the max limit we can display.
    let term = Terms.blobId(blobId);
    const support = formatVisualizationSupport(inference.format);
    if (support.supported && upload.meta.totalBytes <= support.maxBytes) {
      term = formatExpressionOfTerm(Terms.blobId(blobId), inference.format);
    }

    dispatch(
      saveEntityInStore({
        path,
        term,
        name: blobId,
        type: "Type_not_available"
      })
    );
    dispatch(success({ blobId }));
  };
}

// Internal action utilities

function getDetailsForDownload(blobId) {
  return async function(dispatch, getState, { solvuuApi, intl }) {
    const credentialsResponse = await solvuuApi.requestBlobDownload(blobId);

    if (!credentialsResponse.ok) {
      throw new Error(
        intl.formatMessage({
          id: "entities.blob.actions.download.errors.credentials"
        })
      );
    }

    return SolvuuApiWrapper.Js.blobDownloadRequestToDetails(
      credentialsResponse
    );
  };
}

/*
 * @returns Blob
 */
async function downloadBlobFromS3(details, objectParamsOverrides = {}) {
  const { credentials, bucket, key } = details;
  const s3 = new S3({
    accessKeyId: credentials.accessKeyId,
    secretAccessKey: credentials.secretAccessKey,
    sessionToken: credentials.sessionToken,
    region: credentials.region
  });
  const downloadResponse = await new Promise((resolve, reject) => {
    const getObjectParams = {
      Bucket: bucket,
      Key: key,
      // This property sets an invalid If-None-Match header, which circumvents browser caching mechanisms.
      // Without this, we're getting a SignatureError from S3 for small files (<10KB) when using a Range header.
      // I suspect that this SignatureError appears due to some caching mechanism messing with the Range header and
      // re-setting it to the size of the file.
      IfNoneMatch: "NOMATCH",
      ...objectParamsOverrides
    };
    s3.getObject(getObjectParams, (err, data) => {
      if (err) reject(err);
      else resolve(data);
    });
  });
  return downloadResponse.Body;
}

async function blobToString(blob) {
  const body = new Blob([blob]);
  const content = await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = event => resolve(event.target.result);
    reader.onerror = error => reject(error);
    reader.readAsText(body);
  });
  return content;
}

// SELECTORS

export function blobViewByIdSelector(state, blobId) {
  return state.entities.blobViews[blobId] || initialViewState;
}

// INITIAL STATE

export const initialState = {};

const initialViewState = {
  downloadURL: "",
  preview: {},
  meta: {
    visualizeAsEntity: visualizeAsEntityOperation.initialState,
    getDownloadURL: getDownloadURLOperation.initialState,
    getPreview: getPreviewOperation.initialState,
    download: downloadOperation.initialState
  }
};

// STATE TRANSFORMATIONS

function setBlobViewOnUpdate(state, action) {
  const { blobId } = action.payload;

  return {
    ...state,
    [blobId]: blobViewReducer(state[blobId], action)
  };
}

function setDownloadURL(state, action) {
  return action.payload.url;
}

function setPreview(state, action) {
  const {
    payload: { content, contentBytes }
  } = action;
  return { content, contentBytes };
}

// REDUCERS

const downloadURLReducer = createReducer(initialViewState.downloadURL, {
  [getDownloadURLOperation.actionTypes.SUCCESS]: setDownloadURL
});

const previewReducer = createReducer(initialViewState.preview, {
  [getPreviewOperation.actionTypes.SUCCESS]: setPreview
});

const blobViewReducer = combineReducers({
  downloadURL: downloadURLReducer,
  preview: previewReducer,
  meta: combineReducers({
    visualizeAsEntity: visualizeAsEntityOperation.reducer,
    getDownloadURL: getDownloadURLOperation.reducer,
    getPreview: getPreviewOperation.reducer,
    download: getPreviewOperation.reducer
  })
});

const updateViewActions = [
  ...values(visualizeAsEntityOperation.actionTypes),
  ...values(getDownloadURLOperation.actionTypes),
  ...values(getPreviewOperation.actionTypes),
  ...values(downloadOperation.actionTypes)
];

export default function(state = {}, action) {
  if (updateViewActions.includes(action.type)) {
    return setBlobViewOnUpdate(state, action);
  } else {
    return state;
  }
}
