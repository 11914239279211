import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { throttle } from "lodash";

import Form from "components/Form";
import { Textarea } from "components/FormControls/FormControls.js";
import FormGroup, { renderInput } from "components/FormGroup/FormGroup.js";
import {
  VerticalFormFields as FormFields,
  VerticalFormField as FormField,
  VerticalFormActions as FormActions
} from "components/FormLayout";
import * as Err from "components/Err.bs";
import * as Button from "components/Button.bs";

import BytesInput from "./BytesInput";
import ImageSelect from "./ImageSelect";
import styles from "./style.css";

class CommandTextarea extends Component {
  inputRef = React.createRef();

  expandTextarea = () => {
    // https://gomakethings.com/automatically-expand-a-textarea-as-the-user-types-using-vanilla-javascript
    const field = this.inputRef.current;
    field.style.height = "inherit";
    const computed = window.getComputedStyle(field);
    const height =
      parseInt(computed.getPropertyValue("border-top-width"), 10) +
      field.scrollHeight +
      parseInt(computed.getPropertyValue("border-bottom-width"), 10);
    field.style.height = height + "px";
  };

  throttledExpandTextarea = throttle(this.expandTextarea, 100);

  componentDidMount() {
    this.expandTextarea();
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.throttledExpandTextarea();
    }
  }

  handleChange = event => {
    // disallow newlines in the command textarea
    const value = event.target.value.replace("\n", "");
    this.props.onChange(value);
  };

  render() {
    const props = this.props;

    return (
      <Textarea
        {...props}
        className={styles.commandTextarea}
        value={props.value || ""}
        onChange={this.handleChange}
        rows={1}
        spellCheck={false}
        autoFocus={true}
        inputRef={this.inputRef}
      />
    );
  }
}

export class JobForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    form: PropTypes.shape({}).isRequired,
    meta: PropTypes.shape({
      jobCreate: PropTypes.shape({
        pending: PropTypes.bool.isRequired,
        failure: PropTypes.bool.isRequired,
        errors: PropTypes.arrayOf(PropTypes.shape({}))
      }).isRequired
    }).isRequired
  };

  getFormContext() {
    return {
      form: this.props.form,
      errors: this.props.meta.jobCreate.errors || [],
      onFieldChange: this.props.onChange
    };
  }

  handleFormSubmit = event => {
    event.preventDefault();
    this.props.onSubmit();
  };

  render() {
    const {
      meta: { jobCreate }
    } = this.props;

    return (
      <Form.Provider value={this.getFormContext()}>
        <form onSubmit={this.handleFormSubmit} data-testid="job-form">
          <Err.Form.make />
          <FormFields>
            <FormField>
              <FormGroup
                name="commandValue"
                label={<FormattedMessage id="jobs.form.command" />}
                required
                renderControl={props => <CommandTextarea {...props} />}
              />
            </FormField>
            <div className={styles.fieldsHorizontal}>
              <div className={styles.imageField}>
                <FormField>
                  <FormGroup
                    name="image"
                    label={<FormattedMessage id="jobs.form.image" />}
                    required
                    renderControl={props => <ImageSelect {...props} />}
                  />
                </FormField>
              </div>
              <div className={styles.limitField}>
                <FormField>
                  <FormGroup
                    name="resources.cpu"
                    label={<FormattedMessage id="jobs.form.resources.cpu" />}
                    required
                    renderControl={props =>
                      renderInput({
                        ...props,
                        type: "number",
                        min: 0.1,
                        step: 0.1
                      })
                    }
                  />
                </FormField>
              </div>
              <div className={styles.limitField}>
                <FormField>
                  <FormGroup
                    name="resources.mem"
                    label={<FormattedMessage id="jobs.form.resources.mem" />}
                    required
                    renderControl={props => <BytesInput {...props} />}
                  />
                </FormField>
              </div>
              <div className={styles.limitField}>
                <FormField>
                  <FormGroup
                    name="resources.disk"
                    label={<FormattedMessage id="jobs.form.resources.disk" />}
                    required
                    renderControl={props => <BytesInput {...props} />}
                  />
                </FormField>
              </div>
            </div>
          </FormFields>
          <FormActions>
            <Button.make
              type={Button.Type.submit({ isSubmitting: jobCreate.pending })}
            >
              <FormattedMessage id="jobs.form.submit" />
            </Button.make>
          </FormActions>
        </form>
      </Form.Provider>
    );
  }
}
