

import * as Route from "../../../Route.bs.js";
import * as React from "react";
import * as DashboardStorageJs from "./DashboardStorage.js";

var make = DashboardStorageJs.default;

var Internal = {
  make: make
};

function DashboardStorage(Props) {
  var navigateToUploads = function (param) {
    return Route.push(/* Home */803993151);
  };
  return React.createElement(make, {
              navigateToUploads: navigateToUploads
            });
}

var make$1 = DashboardStorage;

export {
  Internal ,
  make$1 as make,
  
}
/* make Not a pure module */
