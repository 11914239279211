import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  BarChart as RechartsBarChart,
  Bar as RechartsBar,
  ScatterChart as RechartsScatterChart,
  Scatter as RechartsScatter,
  LabelList,
  CartesianGrid,
  XAxis,
  YAxis
} from "recharts";
import sizeMe from "react-sizeme";
import invariant from "invariant";
import { takeWhile } from "ramda";
import { max } from "d3-array";
import { scaleLinear } from "d3-scale";

import { primary } from "lib/colors";
import {
  DualBrushing,
  VerticalBrush,
  HorizontalBrush,
  getNiceTicks,
  getNiceDomain
} from "components/Brushing";
import ResponsiveContainer from "components/ResponsiveContainer";

import styles from "table/TablePrincipalCoordinates.css";

export class ExplainedVarianceBarChart extends Component {
  static propTypes = {
    explainedVariance: PropTypes.arrayOf(PropTypes.number).isRequired
  };

  render() {
    const { explainedVariance } = this.props;

    const width = "100%";
    const height = 200;
    const margin = { left: 20, right: 20, bottom: 20, top: 20 };
    const usefulExplainedVariance = takeWhile(
      e => e >= 0.01,
      explainedVariance
    );
    const chartData = usefulExplainedVariance.map((explained, idx) => ({
      dimension: idx + 1,
      value: explained
    }));

    function formatValue(value, fractionalDigits = 2) {
      return (value * 100).toFixed(fractionalDigits) + "%";
    }

    return (
      <div className={styles.explainedVarianceChartRoot}>
        <ResponsiveContainer width={width} height={height}>
          <RechartsBarChart margin={margin} data={chartData}>
            <XAxis
              dataKey="dimension"
              label={{ value: "dimension", position: "bottom" }}
            />
            <YAxis
              dataKey="value"
              domain={[0, 1]}
              label={{
                value: "% explained variance",
                angle: -90,
                position: "left",
                dy: -60
              }}
              tickFormatter={value => formatValue(value, 0)}
            />
            <RechartsBar
              dataKey="value"
              fill={primary}
              isAnimationActive={false}
            >
              <LabelList
                dataKey="value"
                position="top"
                formatter={formatValue}
              />
            </RechartsBar>
          </RechartsBarChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

class PrincipalCoordinates extends Component {
  static propTypes = {
    size: PropTypes.shape({
      width: PropTypes.number
    }).isRequired,
    labels: PropTypes.arrayOf(PropTypes.string).isRequired,
    vectors: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired
  };

  render() {
    const { labels, vectors, size } = this.props;

    invariant(
      labels.length === vectors.length,
      "Expected exact same number of labels and vectors"
    );

    const points = vectors.map((vector, idx) => ({
      x: vector[0],
      y: vector[1],
      label: labels[idx]
    }));

    const maxXExtent = max(points.map(p => p.x), Math.abs);
    const maxYExtent = max(points.map(p => p.y), Math.abs);
    const xDomain = getNiceDomain([-maxXExtent, maxXExtent]);
    const yDomain = getNiceDomain([-maxYExtent, maxYExtent]);
    const xScale = scaleLinear().domain(xDomain);
    const yScale = scaleLinear().domain(yDomain);

    const width = size.width;
    const height = width;
    const margin = { left: 60, right: 20, bottom: 60, top: 20 };
    const axisPadding = 20; // Add padding to avoid clipping points

    const xAxisHeight = 40;
    const yAxisWidth = 40;

    const yBrushHeight = height - xAxisHeight - margin.top - margin.bottom;
    const yBrushWidth = VerticalBrush.defaultWidth;
    const xBrushHeight = HorizontalBrush.defaultHeight;
    const xBrushWidth = width - yAxisWidth - margin.left - margin.right;
    const xBrushX = margin.left + yAxisWidth;
    const xBrushY = height - xBrushHeight - 7;
    const yBrushX = 0;
    const yBrushY = margin.top;

    return (
      <div className={styles.scatterPlotRoot}>
        <DualBrushing xDomain={xDomain} yDomain={yDomain}>
          {({ xBrushing, yBrushing }) => {
            const xTicks = getNiceTicks(xBrushing.selectedDomain, 10);
            const yTicks = getNiceTicks(yBrushing.selectedDomain, 10);

            return (
              <RechartsScatterChart
                margin={margin}
                width={width}
                height={height}
              >
                <XAxis
                  dataKey="x"
                  type="number"
                  label={{ value: "1st dimension", position: "bottom" }}
                  domain={xBrushing.selectedDomain}
                  allowDataOverflow={true}
                  ticks={xTicks}
                  height={xAxisHeight}
                  padding={{ left: axisPadding, right: axisPadding }}
                />
                <YAxis
                  dataKey="y"
                  type="number"
                  label={{
                    value: "2nd dimension",
                    angle: -90,
                    position: "left",
                    dy: -40
                  }}
                  domain={yBrushing.selectedDomain}
                  allowDataOverflow={true}
                  ticks={yTicks}
                  width={yAxisWidth}
                  padding={{ top: axisPadding, bottom: axisPadding }}
                />
                <CartesianGrid strokeDasharray="3 3" />
                <RechartsScatter
                  data={points}
                  fill={primary}
                  isAnimationActive={false}
                >
                  <LabelList dataKey="label" position="top" />
                </RechartsScatter>
                <g>
                  <VerticalBrush
                    x={yBrushX}
                    y={yBrushY}
                    width={yBrushWidth}
                    height={yBrushHeight}
                    onChange={yBrushing.onChange}
                    scale={yScale}
                    throttleMs={100}
                    key={yBrushing.brushKey + width}
                  />
                </g>
                <g>
                  <HorizontalBrush
                    x={xBrushX}
                    y={xBrushY}
                    width={xBrushWidth}
                    height={xBrushHeight}
                    onChange={xBrushing.onChange}
                    scale={xScale}
                    throttleMs={100}
                    key={xBrushing.brushKey + height}
                  />
                </g>
              </RechartsScatterChart>
            );
          }}
        </DualBrushing>
      </div>
    );
  }
}

const noPlaceholder = process.env.NODE_ENV === "test";
const refreshRate = 100;
const SizedPrincipalCoordinates = sizeMe({ refreshRate, noPlaceholder })(
  PrincipalCoordinates
);

// Injects the style into sizeMe placeholder, for correct size measurement
export default props => (
  <SizedPrincipalCoordinates className={styles.scatterPlotRoot} {...props} />
);
