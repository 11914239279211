


function makeProps(gap, children, param) {
  return {
          gap: gap !== 26810 ? (
              gap >= 26811 ? "3x" : "1x"
            ) : "2x",
          children: children
        };
}

var Item = { };

export {
  makeProps ,
  Item ,
  
}
/* No side effect */
