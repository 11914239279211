

import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

function makeProps(prim, prim$1, prim$2, prim$3, prim$4, prim$5) {
  var tmp = {
    onClose: prim$2,
    children: prim$4
  };
  if (prim !== undefined) {
    tmp.header = Caml_option.valFromOption(prim);
  }
  if (prim$1 !== undefined) {
    tmp.open = Caml_option.valFromOption(prim$1);
  }
  if (prim$3 !== undefined) {
    tmp.maxWidth = Caml_option.valFromOption(prim$3);
  }
  return tmp;
}

var Header = { };

var Body = { };

var Footer = { };

export {
  makeProps ,
  Header ,
  Body ,
  Footer ,
  
}
/* No side effect */
