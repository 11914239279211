import React, { Component } from "react";
import PropTypes from "prop-types";
import { hierarchy, cluster } from "d3-hierarchy";
import sizeMe from "react-sizeme";
import { max } from "ramda";

class TreeArea extends Component {
  static propTypes = {
    tree: PropTypes.shape({
      name: PropTypes.string.isRequired,
      length: PropTypes.number,
      branchset: PropTypes.array.isRequired
    }).isRequired,
    circular: PropTypes.bool.isRequired,
    size: PropTypes.shape({
      width: PropTypes.number
    }).isRequired
  };

  render() {
    const { tree, size, circular } = this.props;

    // Hierarchy
    const root = hierarchy(tree, node => node.branchset)
      .each(node => {
        if (node.parent) {
          node.data.totalLength =
            node.parent.data.totalLength + node.data.length;
        } else {
          node.data.totalLength = 0;
        }
      })
      .sort((a, b) => a.data.totalLength - b.data.totalLength);

    const layout = cluster()
      .size([1, 1])
      .separation(() => 10);
    const result = layout(root);

    const nodes = [];
    let idx = 0;
    result.each(node => {
      node.data.id = idx;
      nodes.push(node);
      idx += 1;
    });
    const links = result.links();
    const leaves = result.leaves();

    // Positioning
    const leafWidth =
      6 * leaves.map(leaf => leaf.data.name.length).reduce(max, 0);
    const trackWidth = leafWidth;
    const padding = 10;
    const maxTotalLength = nodes.map(n => n.data.totalLength).reduce(max, 0);

    function nodeX(node) {
      return (node.data.totalLength / maxTotalLength) * width;
    }

    function nodeY(node) {
      return node.x * height;
    }

    function leafX(node) {
      return node.y * width;
    }

    function leafY(node) {
      return nodeY(node);
    }

    function nodeAngle(node) {
      return node.x * 360;
    }

    function nodeRadius(node) {
      return ((node.data.totalLength / maxTotalLength) * width) / 2;
    }

    function leafAngle(node) {
      return nodeAngle(node);
    }

    function leafRadius(node) {
      return (node.y * width) / 2;
    }

    const svgWidth = size.width || 500;
    const svgHeight = circular ? svgWidth : root.leaves().length * 13;

    const width = circular
      ? svgWidth - 2 * trackWidth - 2 * padding
      : svgWidth - trackWidth - 2 * padding;
    const height = circular ? width : svgHeight - 2 * padding;

    const centerX = width / 2 + trackWidth;
    const centerY = height / 2 + trackWidth;
    const center = `${centerX} ${centerY}`;

    const smallerText = nodes.length > 360;
    const showText = nodes.length < 1000;

    function svgAttributes() {
      return {
        width: svgWidth,
        height: svgHeight,
        viewBox: `-${padding} -${padding} ${svgWidth} ${svgHeight}`
      };
    }

    function leafAttributes(node) {
      if (circular) {
        return {
          transform: `
            translate(${center})
            rotate(${leafAngle(node)})
            translate(${leafRadius(node)})
          `
        };
      } else {
        return {
          transform: `translate(${leafX(node)} ${leafY(node)})`
        };
      }
    }

    function leafLabelAttributes(node) {
      if (circular) {
        const flip = leafAngle(node) > 90 && leafAngle(node) < 270;
        return {
          transform: `
            ${flip ? "rotate(180)" : ""}
          `,
          dx: flip ? -6 : 6,
          textAnchor: flip ? "end" : "start",
          clipPath: flip ? "url(#cut-off-text-flipped)" : "url(#cut-off-text)",
          fontSize: smallerText ? 8 : 12,
          visibility: showText ? null : "hidden"
        };
      } else {
        return {
          clipPath: "url(#cut-off-text)"
        };
      }
    }

    function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
      const angleInRadians = (angleInDegrees * Math.PI) / 180;
      const x = centerX + radius * Math.cos(angleInRadians);
      const y = centerY + radius * Math.sin(angleInRadians);
      return [x, y];
    }

    function linkAttributes(link) {
      const { source, target } = link;
      if (circular) {
        const arcAngle = nodeAngle(source) - nodeAngle(target);
        const sweep = arcAngle > 0 ? 1 : 0;
        const [x, y] = polarToCartesian(0, 0, nodeRadius(source), arcAngle);
        return {
          d: `
            M ${nodeRadius(target)} 0
            L ${nodeRadius(source)} 0
            A ${nodeRadius(source)} ${nodeRadius(source)} 0 0 ${sweep} ${x} ${y}
          `,
          transform: `
            translate(${center})
            rotate(${nodeAngle(target)})
          `,
          fill: "transparent"
        };
      } else {
        return {
          d: `
            M ${nodeX(source)} ${nodeY(source)}
            L ${nodeX(source)} ${nodeY(target)}
            L ${nodeX(target)} ${nodeY(target)}
          `
        };
      }
    }

    function leafExtensionAttributes(node) {
      if (circular) {
        return {
          x1: 0,
          y1: 0,
          x2: leafRadius(node) - nodeRadius(node),
          y2: 0,
          transform: `
            translate(${center})
            rotate(${nodeAngle(node)})
            translate(${nodeRadius(node)})
          `
        };
      } else {
        return {
          x1: nodeX(node),
          y1: nodeY(node),
          x2: leafX(node),
          y2: leafY(node)
        };
      }
    }

    function nodeAttributes(node) {
      if (circular) {
        return {
          transform: `
            translate(${center})
            rotate(${nodeAngle(node)})
            translate(${nodeRadius(node)})
          `,
          r: 2
        };
      } else {
        return {
          cx: nodeX(node),
          cy: nodeY(node)
        };
      }
    }

    return (
      <div style={{ width: "100%" }}>
        <svg {...svgAttributes()}>
          <g data-testid="links">
            {links.map(link => (
              <g key={`${link.source.data.id}-${link.target.data.id}`}>
                <path
                  {...linkAttributes(link)}
                  stroke="black"
                  fill="transparent"
                />
              </g>
            ))}
          </g>
          <g data-testid="leaf-extensions">
            {leaves.map(node => (
              <line
                key={node.data.id}
                stroke="#000"
                strokeOpacity={0.3}
                {...leafExtensionAttributes(node)}
              />
            ))}
          </g>
          <g data-testid="nodes">
            {nodes
              .filter(node => node.parent)
              .map(node => (
                <circle
                  key={node.data.id}
                  r={3}
                  fill={node.children ? "#fff" : "#000"}
                  stroke="#000"
                  {...nodeAttributes(node)}
                />
              ))}
          </g>
          <g data-testid="leaves">
            <defs>
              <clipPath id="cut-off-text">
                <rect x={0} y={-5} width={leafWidth} height={12} />
              </clipPath>
              <clipPath id="cut-off-text-flipped">
                <rect x={-leafWidth} y={-5} width={leafWidth} height={12} />
              </clipPath>
            </defs>
            {leaves.map(node => (
              <g key={node.data.id} {...leafAttributes(node)}>
                <text
                  key={node.data.id}
                  dy={4}
                  dx={6}
                  fontSize={12}
                  {...leafLabelAttributes(node)}
                >
                  {node.data.name}
                </text>
              </g>
            ))}
          </g>
        </svg>
      </div>
    );
  }
}

const noPlaceholder = process.env.NODE_ENV === "test";
const refreshRate = 100;
export default sizeMe({ refreshRate, noPlaceholder })(TreeArea);
