

import * as AwsJs from "./aws.js";

function getUserPool(prim) {
  return AwsJs.getUserPool();
}

function saveCognitoCredentials(prim) {
  AwsJs.saveCognitoCredentials(prim);
  return /* () */0;
}

function clearCognitoCredentials(prim) {
  AwsJs.clearCognitoCredentials();
  return /* () */0;
}

function getAuthenticatedUser(prim) {
  return AwsJs.getAuthenticatedUser();
}

export {
  getUserPool ,
  saveCognitoCredentials ,
  clearCognitoCredentials ,
  getAuthenticatedUser ,
  
}
/* ./aws.js Not a pure module */
