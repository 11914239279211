import React, { Component } from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import { FormattedMessage } from "react-intl";

import * as Button from "components/Button.bs";
import iconUpload from "./icon-upload.svg";
import styles from "./style.css";

class FileDrop extends Component {
  static propTypes = {
    chooseFiles: PropTypes.func.isRequired
  };

  handleDrop = files => {
    this.props.chooseFiles(files);
  };

  render() {
    return (
      <div className={styles.root} data-testid="file-drop">
        <Dropzone
          onDrop={this.handleDrop}
          className={styles.dropzone}
          activeClassName={styles.dropzoneActive}
        >
          <div className={styles.message}>
            <img src={iconUpload} alt="" />
            <FormattedMessage id="entities.record.actions.dropFile" />
          </div>
          <Button.make>
            {"+ "}
            <FormattedMessage id="entities.record.actions.addFile" />
          </Button.make>
        </Dropzone>
      </div>
    );
  }
}

export default FileDrop;
