

import * as Card from "../../components/Card/Card.bs.js";
import * as Root from "../../Root.bs.js";
import * as Term from "../../lang/Term.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Infer from "../../Infer.bs.js";
import * as Table from "../../table/Table.bs.js";
import * as React from "react";
import * as Import from "../../features/import.bs.js";
import * as Source from "../Source.bs.js";
import * as $$String from "bs-platform/lib/es6/string.js";
import * as Loading from "../../components/Loading.bs.js";
import * as Caml_obj from "bs-platform/lib/es6/caml_obj.js";
import * as Std$Bolt from "bolt/./std.bs.js";
import * as TermEval from "../../lib/solvuu/termEval.bs.js";
import * as ExitPrompt from "../../components/ExitPrompt/ExitPrompt.bs.js";
import * as Pervasives from "bs-platform/lib/es6/pervasives.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as ReactExtras from "../../lib/solvuu/ReactExtras.bs.js";
import * as ChartsCss from "./Charts.css";
import * as FormatEditor from "../../components/FormatEditor/FormatEditor.bs.js";
import * as Fmt$SolvuuApi from "solvuu-api/./fmt.bs.js";
import * as TableFilters2 from "../../table/TableFilters2.bs.js";
import * as Lang$SolvuuApi from "solvuu-api/./lang.bs.js";
import * as Root$SolvuuApi from "solvuu-api/./root.bs.js";
import * as VerticalLayout from "../../components/Layout/VerticalLayout.bs.js";
import * as HorizontalLayout from "../../components/Layout/HorizontalLayout.bs.js";
import * as TermRenderContext from "../../lang/TermRenderContext.bs.js";
import * as Layout from "components/Layout";
import * as FeaturesTableFilters from "../../features/entities/tables/FeaturesTableFilters.bs.js";
import * as TableVisualizationsLauncher from "../../table/TableVisualizationsLauncher.bs.js";

var styles = ChartsCss.default;

function dontWaitFor(prim) {
  return /* () */0;
}

function Charts2$Suggestions(Props) {
  var suggestions = Props.suggestions;
  var onSelect = Props.onSelect;
  if (typeof suggestions === "number") {
    if (suggestions >= 494222878) {
      return React.createElement(Loading.make, { });
    } else {
      return null;
    }
  } else {
    var suggestions$1 = suggestions[1];
    if (suggestions$1) {
      return React.createElement(TableVisualizationsLauncher.make, {
                  visualizations: Curry._2(Import.List.keepMap, suggestions$1, Root.ofTerm),
                  message: "Suggestions",
                  onVisualizationAdd: (function (viz) {
                      var match = Root.firstMatch(suggestions$1, viz);
                      if (match !== undefined) {
                        return Curry._1(onSelect, match);
                      } else {
                        return /* () */0;
                      }
                    })
                });
    } else {
      return React.createElement(Card.make, {
                  children: "No suggestions"
                });
    }
  }
}

var Suggestions = {
  make: Charts2$Suggestions
};

function lookupVar(lets, $$var) {
  var loop = function (l) {
    if (l) {
      var let_ = l[0];
      var match = loop(l[1]);
      if (match !== undefined) {
        return match;
      } else {
        var match$1 = let_.var === $$var;
        if (match$1) {
          return let_["expr'"];
        } else {
          return ;
        }
      }
    }
    
  };
  return loop(lets);
}

function guessType(args, lookupVar) {
  if (typeof args === "number") {
    return /* Type_not_available */-883458824;
  } else {
    var variant = args[0];
    if (variant >= 4300231) {
      if (variant !== 365180284) {
        if (variant !== 925978388) {
          return /* Type_not_available */-883458824;
        } else {
          var match = args[1];
          if (typeof match === "number") {
            return /* Type_not_available */-883458824;
          } else {
            var variant$1 = match[0];
            if (variant$1 !== -976970511) {
              if (variant$1 !== 3654863) {
                if (variant$1 !== 365180284) {
                  return /* Type_not_available */-883458824;
                } else {
                  return /* `Nullable */[
                          216537025,
                          /* Float */365180284
                        ];
                }
              } else {
                return /* `Nullable */[
                        216537025,
                        /* Int */3654863
                      ];
              }
            } else {
              return /* `Nullable */[
                      216537025,
                      /* String */-976970511
                    ];
            }
          }
        }
      } else {
        return /* Float */365180284;
      }
    } else if (variant >= 3654863) {
      if (variant >= 4298439) {
        var dataLet = Curry._1(lookupVar, args[1]);
        if (dataLet !== undefined) {
          var match$1 = dataLet;
          if (typeof match$1 === "number") {
            return /* Type_not_available */-883458824;
          } else {
            var match$2 = match$1[1];
            if (typeof match$2 === "number" || match$2[0] !== 848054398) {
              return /* Type_not_available */-883458824;
            } else {
              var match$3 = match$2[1];
              if (match$3) {
                var match$4 = match$3[0];
                if (typeof match$4 === "number" || match$4[0] !== 847309489) {
                  return /* Type_not_available */-883458824;
                } else {
                  var columns = Curry._1(Std$Bolt.Res.all, Curry._2(Import.List.map, match$4[1], (function (param) {
                              var label = param[0];
                              if (typeof label === "number" || label[0] !== 699531161) {
                                return /* `Error */[
                                        106380200,
                                        /* NotSupported */-1050747205
                                      ];
                              } else {
                                return /* `Ok */[
                                        17724,
                                        /* tuple */[
                                          label[1],
                                          guessType(param[1], lookupVar)
                                        ]
                                      ];
                              }
                            })));
                  return /* `List */[
                          848054398,
                          columns[0] >= 106380200 ? /* Type_not_available */-883458824 : /* `Record */[
                              847309489,
                              /* tuple */[
                                columns[1],
                                undefined
                              ]
                            ]
                        ];
                }
              } else {
                return /* Type_not_available */-883458824;
              }
            }
          }
        } else {
          return /* Type_not_available */-883458824;
        }
      } else {
        return /* Int */3654863;
      }
    } else if (variant !== -976970511) {
      return /* Type_not_available */-883458824;
    } else {
      return /* String */-976970511;
    }
  }
}

function Charts2$Let(Props) {
  var apiClient = Props.apiClient;
  var let_ = Props.let_;
  var lookupVar = Props.lookupVar;
  var onClickSuggestion = Props.onClickSuggestion;
  var onReplaceSelf = Props.onReplaceSelf;
  var onEval = Props.onEval;
  var expr$prime = let_["expr'"];
  var expr = let_.expr;
  var $$var = let_.var;
  var suggestions = React.createElement(Layout.VerticalLayoutItem, {
        children: React.createElement(Charts2$Suggestions, {
              suggestions: let_.suggestions,
              onSelect: onClickSuggestion
            }),
        key: "suggestions"
      });
  var evalButton = React.createElement("button", {
        onClick: onEval
      }, "Eval");
  if (typeof expr !== "number" && expr[0] === 3257473) {
    var match = expr[1];
    var args = match[1];
    var func = match[0];
    var tmp;
    var exit = 0;
    if (typeof func === "number" || func[0] !== 4298439) {
      exit = 2;
    } else {
      tmp = React.createElement("strong", undefined, func[1]);
    }
    if (exit === 2) {
      tmp = React.createElement(Term.make, {
            term: func,
            type_: /* Type_not_available */-883458824,
            context: {
              meta: undefined,
              parent: /* LetBinding */112487626
            },
            apiClient: apiClient
          });
    }
    var tmp$1;
    if (typeof expr$prime === "number") {
      tmp$1 = expr$prime >= 891311606 ? React.createElement(Loading.make, { }) : null;
    } else {
      var term = expr$prime[1];
      var match$1 = Caml_obj.caml_equal(term, expr);
      tmp$1 = match$1 ? null : React.createElement(Layout.HorizontalLayoutItem, {
              children: React.createElement(Term.make, {
                    term: term,
                    type_: /* Type_not_available */-883458824,
                    context: TermRenderContext.root,
                    apiClient: apiClient
                  })
            });
    }
    var $$default = function (param) {
      return React.createElement(Term.make, {
                  term: args,
                  type_: /* Type_not_available */-883458824,
                  context: {
                    meta: undefined,
                    parent: /* LetBinding */112487626
                  },
                  apiClient: apiClient
                });
    };
    var match$2 = Curry._1(Fmt$SolvuuApi.Parse.of_term, expr);
    var tmp$2;
    if (typeof match$2 === "number") {
      if (typeof func === "number" || func[0] !== 4298439) {
        tmp$2 = $$default(/* () */0);
      } else {
        var func$1 = func[1];
        if (Curry._2(Lang$SolvuuApi.Var.equal, func$1, Root$SolvuuApi.filter)) {
          var match$3 = guessType(args, lookupVar);
          if (typeof match$3 === "number" || match$3[0] !== 847309489) {
            tmp$2 = $$default(/* () */0);
          } else {
            var onSubmit = function (criteria) {
              var newExpr = FeaturesTableFilters.New.toTerm(args, criteria);
              return Curry._3(onReplaceSelf, $$var, expr, newExpr);
            };
            tmp$2 = React.createElement(TableFilters2.make, {
                  columns: match$3[1][0],
                  onSubmit: onSubmit
                });
          }
        } else {
          var match$4 = Root.ofVar(func$1);
          tmp$2 = match$4 !== undefined ? Table.staticTable(match$4, $$var, /* tuple */[
                  args,
                  guessType(args, lookupVar)
                ], (function (param) {
                    return /* () */0;
                  }), TermEval.$$eval(apiClient), apiClient, Term.render) : $$default(/* () */0);
        }
      }
    } else {
      var match$5 = match$2[1];
      var data = match$5[1];
      tmp$2 = React.createElement(FormatEditor.make, {
            format: match$5[0],
            onSubmit: (function (fmt) {
                var newExpr = Curry._2(Fmt$SolvuuApi.Parse.to_term, fmt, data);
                return Curry._3(onReplaceSelf, $$var, expr, newExpr);
              }),
            onCancel: (function (param) {
                console.log("cancel2");
                return /* () */0;
              }),
            enableOptional: false,
            enableGzip: true
          });
    }
    return React.createElement(Term.RootCard.make, {
                header: React.createElement("div", undefined, evalButton, $$var),
                children: React.createElement(Layout.VerticalLayout, VerticalLayout.makeProps(/* x3 */26811, null, /* () */0), React.createElement(Layout.VerticalLayoutItem, {
                          children: tmp,
                          key: "functionName"
                        }), React.createElement(Layout.VerticalLayoutItem, {
                          children: React.createElement(Layout.HorizontalLayout, HorizontalLayout.makeProps(/* x3 */26811, null, /* () */0), tmp$1, React.createElement(Layout.HorizontalLayoutItem, {
                                    children: tmp$2
                                  })),
                          key: "functionBody"
                        }), suggestions)
              });
  }
  return React.createElement(Layout.VerticalLayout, VerticalLayout.makeProps(/* x3 */26811, null, /* () */0), React.createElement(Layout.VerticalLayoutItem, {
                  children: evalButton,
                  key: "evalButton"
                }), React.createElement(Layout.VerticalLayoutItem, {
                  children: React.createElement(Term.make, {
                        term: expr,
                        type_: /* Type_not_available */-883458824,
                        context: {
                          meta: undefined,
                          parent: /* LetBinding */112487626
                        },
                        apiClient: apiClient
                      }),
                  key: "term"
                }), suggestions);
}

var Let = {
  lookupVar: lookupVar,
  guessType: guessType,
  make: Charts2$Let
};

var $$Event = { };

var Effect = { };

var initial = /* tuple */[
  /* Initial */418396260,
  undefined
];

function nextStateFromInitial($$event) {
  if (typeof $$event === "number") {
    return initial;
  } else {
    var variant = $$event[0];
    if (variant !== -572978464) {
      if (variant !== -569515071) {
        return /* tuple */[
                /* Initial */418396260,
                undefined
              ];
      } else {
        var source = $$event[1];
        return /* tuple */[
                /* `LoadingSource */[
                  -484620201,
                  source
                ],
                /* `LoadSource */[
                  -569515071,
                  source
                ]
              ];
      }
    } else {
      return /* tuple */[
              /* Initial */418396260,
              /* `ErrorMessage */[
                324232959,
                "Unable to reinitialize data"
              ]
            ];
    }
  }
}

function nextStateFromLoadingSource(source, $$event) {
  if (typeof $$event === "number") {
    return initial;
  } else {
    var variant = $$event[0];
    if (variant !== -572978464) {
      if (variant !== -569515071) {
        return /* tuple */[
                /* `LoadingSource */[
                  -484620201,
                  source
                ],
                undefined
              ];
      } else {
        return /* tuple */[
                /* `LoadingSource */[
                  -484620201,
                  source
                ],
                /* `ErrorMessage */[
                  324232959,
                  "A file is currently loading."
                ]
              ];
      }
    } else {
      var match = $$event[1];
      var varData = Curry._1(Lang$SolvuuApi.Var.Exn.of_string, "data");
      var init_source = /* `File */[
        781515420,
        match[0]
      ];
      var init_lets = /* :: */[
        {
          var: varData,
          expr: match[1],
          "expr'": /* Initial */418396260,
          suggestions: /* Initial */418396260
        },
        /* [] */0
      ];
      var init = {
        source: init_source,
        lets: init_lets
      };
      var t = /* `Lets */[
        847855704,
        init
      ];
      return /* tuple */[
              t,
              undefined
            ];
    }
  }
}

function nextStateFromLets(l, $$event) {
  if (typeof $$event === "number") {
    return initial;
  } else {
    var variant = $$event[0];
    if (variant >= -293677017) {
      if (variant >= 771069884) {
        if (variant >= 999946793) {
          var t_001 = {
            source: l.source,
            lets: $$event[1]
          };
          var t = /* `Lets */[
            847855704,
            t_001
          ];
          return /* tuple */[
                  t,
                  undefined
                ];
        } else {
          var let_ = $$event[1];
          var expr$prime = let_["expr'"];
          var expr = let_.expr;
          var $$var = let_.var;
          if (typeof expr$prime === "number" && expr$prime >= 891311606) {
            return /* tuple */[
                    /* `Lets */[
                      847855704,
                      l
                    ],
                    undefined
                  ];
          }
          var p = function (x) {
            if (x.var === $$var) {
              return x.expr === expr;
            } else {
              return false;
            }
          };
          var loop = function (l) {
            if (l) {
              var x = l[0];
              var match = loop(l[1]);
              if (match !== undefined) {
                var match$1 = match;
                return /* tuple */[
                        /* :: */[
                          /* tuple */[
                            x.var,
                            x.expr
                          ],
                          match$1[0]
                        ],
                        match$1[1]
                      ];
              } else {
                var match$2 = p(x);
                if (match$2) {
                  return /* tuple */[
                          /* [] */0,
                          x.expr
                        ];
                } else {
                  return ;
                }
              }
            }
            
          };
          var fullExpr = Belt_Option.map(loop(l.lets), Lang$SolvuuApi.Term.Let.to_term);
          if (fullExpr !== undefined) {
            return /* tuple */[
                    /* `Lets */[
                      847855704,
                      {
                        source: l.source,
                        lets: Curry._2(Import.List.map, l.lets, (function (x) {
                                var match = x === let_;
                                if (match) {
                                  return {
                                          var: let_.var,
                                          expr: let_.expr,
                                          "expr'": /* Evaluating */891311606,
                                          suggestions: let_.suggestions
                                        };
                                } else {
                                  return x;
                                }
                              }))
                      }
                    ],
                    /* `Eval */[
                      771069884,
                      /* tuple */[
                        let_,
                        fullExpr
                      ]
                    ]
                  ];
          } else {
            return /* tuple */[
                    /* `Lets */[
                      847855704,
                      l
                    ],
                    undefined
                  ];
          }
        }
      } else if (variant >= -52218367) {
        return /* tuple */[
                /* `Lets */[
                  847855704,
                  {
                    source: l.source,
                    lets: Pervasives.$at(l.lets, /* :: */[
                          $$event[1],
                          /* [] */0
                        ])
                  }
                ],
                undefined
              ];
      } else {
        return /* tuple */[
                /* `Lets */[
                  847855704,
                  {
                    source: l.source,
                    lets: Curry._2(Import.List.map, l.lets, $$event[1])
                  }
                ],
                undefined
              ];
      }
    } else if (variant !== -572978464) {
      if (variant >= -569515071) {
        return /* tuple */[
                /* `LoadingSource */[
                  -484620201,
                  $$event[1]
                ],
                undefined
              ];
      } else {
        var let_$1 = $$event[1];
        var match = let_$1.suggestions;
        if (typeof match === "number" && match >= 494222878) {
          return /* tuple */[
                  /* `Lets */[
                    847855704,
                    l
                  ],
                  undefined
                ];
        }
        return /* tuple */[
                /* `Lets */[
                  847855704,
                  {
                    source: l.source,
                    lets: Curry._2(Import.List.map, l.lets, (function (x) {
                            var match = x === let_$1;
                            if (match) {
                              return {
                                      var: let_$1.var,
                                      expr: let_$1.expr,
                                      "expr'": let_$1["expr'"],
                                      suggestions: /* Inferring */494222878
                                    };
                            } else {
                              return x;
                            }
                          }))
                  }
                ],
                /* `Infer */[
                  -784101522,
                  let_$1
                ]
              ];
      }
    } else {
      return /* tuple */[
              /* `Lets */[
                847855704,
                l
              ],
              /* `ErrorMessage */[
                324232959,
                "Unable to reinitialize data"
              ]
            ];
    }
  }
}

function reducer(param, $$event) {
  var t = param[0];
  if (typeof t === "number") {
    return nextStateFromInitial($$event);
  } else if (t[0] >= 847855704) {
    return nextStateFromLets(t[1], $$event);
  } else {
    return nextStateFromLoadingSource(t[1], $$event);
  }
}

var WithEffect = {
  initial: initial,
  nextStateFromInitial: nextStateFromInitial,
  nextStateFromLoadingSource: nextStateFromLoadingSource,
  nextStateFromLets: nextStateFromLets,
  reducer: reducer
};

var State = {
  $$Event: $$Event,
  Effect: Effect,
  WithEffect: WithEffect
};

function useToast(delay) {
  var errors = React.useRef(/* [] */0);
  var setError = function (message) {
    console.log("Set Error: " + message);
    errors.current = Pervasives.$at(errors.current, /* :: */[
          message,
          /* [] */0
        ]);
    setTimeout((function (param) {
            console.log("Delete Error: " + message);
            errors.current = Curry._2(Import.List.keep, errors.current, (function (x) {
                    return !$$String.equal(message, x);
                  }));
            return /* () */0;
          }), delay);
    return /* () */0;
  };
  return /* tuple */[
          errors,
          setError
        ];
}

function Charts2(Props) {
  var apiClient = Props.apiClient;
  var match = React.useReducer(reducer, initial);
  var dispatch = match[1];
  var match$1 = match[0];
  var effect = match$1[1];
  var t = match$1[0];
  useToast(5000);
  var fileSelectStatus = typeof t === "number" ? /* `Input */[
      -783600662,
      undefined
    ] : (
      t[0] >= 847855704 ? /* `Output */[
          -1055554783,
          /* `Ok */[
            17724,
            t[1].source
          ]
        ] : /* `Running */[
          951752159,
          t[1]
        ]
    );
  React.useEffect((function () {
          if (effect !== undefined) {
            var effect$1 = effect;
            var variant = effect$1[0];
            if (variant >= 324232959) {
              if (variant >= 771069884) {
                var match = effect$1[1];
                var let_ = match[0];
                var expr = let_.expr;
                var $$var = let_.var;
                Import.$great$great$pipe(TermEval.$$eval(apiClient)(match[1]), (function (expr$prime) {
                        return Curry._1(dispatch, /* `ReplaceLet */[
                                    -293677017,
                                    (function (aLet_) {
                                        var match = aLet_.var === $$var && aLet_.expr === expr;
                                        if (match) {
                                          return {
                                                  var: let_.var,
                                                  expr: let_.expr,
                                                  "expr'": /* `Evaluated */[
                                                    -959001141,
                                                    expr$prime
                                                  ],
                                                  suggestions: let_.suggestions
                                                };
                                        } else {
                                          return aLet_;
                                        }
                                      })
                                  ]);
                      }));
              } else {
                console.log("ERROR: " + effect$1[1]);
              }
            } else if (variant >= -569515071) {
              Import.$great$great$eq(Source.load(effect$1[1]), (function (file) {
                      return Import.$great$great$pipe(Term.bytesOfFile(apiClient, file), (function (contents) {
                                    return Curry._1(dispatch, /* `LoadedSource */[
                                                -572978464,
                                                /* tuple */[
                                                  file,
                                                  contents
                                                ]
                                              ]);
                                  }));
                    }));
            } else {
              var let_$1 = effect$1[1];
              var expr$prime = let_$1["expr'"];
              var expr$1 = let_$1.expr;
              var $$var$1 = let_$1.var;
              if (typeof expr$prime !== "number") {
                Import.$great$great$pipe(Infer.infer(undefined, undefined, apiClient, expr$prime[1]), (function (result) {
                        if (typeof result === "number" || result[0] < 17724) {
                          return /* () */0;
                        } else {
                          var suggestions = result[1];
                          return Curry._1(dispatch, /* `ReplaceLet */[
                                      -293677017,
                                      (function (aLet_) {
                                          var match = aLet_.var === $$var$1 && aLet_.expr === expr$1;
                                          if (match) {
                                            return {
                                                    var: let_$1.var,
                                                    expr: let_$1.expr,
                                                    "expr'": let_$1["expr'"],
                                                    suggestions: /* `Inferred */[
                                                      -382983517,
                                                      suggestions
                                                    ]
                                                  };
                                          } else {
                                            return aLet_;
                                          }
                                        })
                                    ]);
                        }
                      }));
              }
              
            }
          } else if (typeof t !== "number" && t[0] >= 847855704) {
            var needsSuggestions = Curry._2(Import.List.getBy, t[1].lets, (function (let_) {
                    var match = let_.suggestions;
                    return match === 418396260;
                  }));
            if (needsSuggestions !== undefined) {
              var let_$2 = needsSuggestions;
              var match$1 = let_$2["expr'"];
              if (typeof match$1 !== "number") {
                Curry._1(dispatch, /* `Infer */[
                      -784101522,
                      let_$2
                    ]);
              }
              
            }
            
          }
          return ;
        }), /* tuple */[
        effect,
        t
      ]);
  var onSelect = function (source) {
    return Curry._1(dispatch, /* `LoadSource */[
                -569515071,
                source
              ]);
  };
  var onClear = function (param) {
    return Curry._1(dispatch, /* Reset */-101336657);
  };
  var contents;
  if (typeof t === "number") {
    contents = null;
  } else if (t[0] >= 847855704) {
    var lets = t[1].lets;
    contents = React.createElement(Layout.VerticalLayout, VerticalLayout.makeProps(/* x3 */26811, ReactExtras.listToElement(Curry._2(Import.List.mapWithIndex, lets, (function (i, let_) {
                        var onEval = function (param) {
                          return Curry._1(dispatch, /* `Eval */[
                                      771069884,
                                      let_
                                    ]);
                        };
                        var onClickSuggestion = function (suggestion) {
                          if (typeof t === "number" || t[0] < 847855704) {
                            return /* () */0;
                          } else {
                            var l = t[1];
                            var match = Curry._1(Fmt$SolvuuApi.Parse.of_term, suggestion);
                            if (typeof match === "number") {
                              if (typeof suggestion === "number" || suggestion[0] !== 4298439) {
                                return /* () */0;
                              } else {
                                var f = suggestion[1];
                                if (Curry._2(Lang$SolvuuApi.Var.equal, f, Root$SolvuuApi.filter)) {
                                  var expr_001 = /* tuple */[
                                    /* `Var */[
                                      4298439,
                                      f
                                    ],
                                    /* `Var */[
                                      4298439,
                                      Curry._1(Lang$SolvuuApi.Var.Exn.of_string, let_.var)
                                    ]
                                  ];
                                  var expr = /* `App */[
                                    3257473,
                                    expr_001
                                  ];
                                  return Curry._1(dispatch, /* `ExtendLet */[
                                              -52218367,
                                              {
                                                var: "var" + String(Curry._1(Import.List.length, l.lets)),
                                                expr: expr,
                                                "expr'": /* Initial */418396260,
                                                suggestions: /* Initial */418396260
                                              }
                                            ]);
                                } else {
                                  var expr_001$1 = /* tuple */[
                                    /* `Var */[
                                      4298439,
                                      f
                                    ],
                                    /* `Var */[
                                      4298439,
                                      Curry._1(Lang$SolvuuApi.Var.Exn.of_string, let_.var)
                                    ]
                                  ];
                                  var expr$1 = /* `App */[
                                    3257473,
                                    expr_001$1
                                  ];
                                  return Curry._1(dispatch, /* `ExtendLet */[
                                              -52218367,
                                              {
                                                var: "var" + String(Curry._1(Import.List.length, l.lets)),
                                                expr: expr$1,
                                                "expr'": /* Initial */418396260,
                                                suggestions: /* Initial */418396260
                                              }
                                            ]);
                                }
                              }
                            } else {
                              var expr$2 = Curry._2(Fmt$SolvuuApi.Parse.to_term, match[1][0], /* `Var */[
                                    4298439,
                                    Curry._1(Lang$SolvuuApi.Var.Exn.of_string, let_.var)
                                  ]);
                              return Curry._1(dispatch, /* `ExtendLet */[
                                          -52218367,
                                          {
                                            var: "var" + String(Curry._1(Import.List.length, l.lets)),
                                            expr: expr$2,
                                            "expr'": /* Initial */418396260,
                                            suggestions: /* Initial */418396260
                                          }
                                        ]);
                            }
                          }
                        };
                        var onReplaceSelf = function ($$var, expr1, expr2) {
                          return Curry._1(dispatch, /* `ReplaceLet */[
                                      -293677017,
                                      (function (aLet_) {
                                          var match = aLet_.var === $$var && Caml_obj.caml_equal(aLet_.expr, expr1);
                                          if (match) {
                                            return {
                                                    var: aLet_.var,
                                                    expr: expr2,
                                                    "expr'": aLet_["expr'"],
                                                    suggestions: aLet_.suggestions
                                                  };
                                          } else {
                                            return aLet_;
                                          }
                                        })
                                    ]);
                        };
                        return React.createElement(Layout.VerticalLayoutItem, {
                                    children: React.createElement(Charts2$Let, {
                                          apiClient: apiClient,
                                          let_: let_,
                                          lookupVar: (function ($$var) {
                                              return lookupVar(lets, $$var);
                                            }),
                                          onClickSuggestion: onClickSuggestion,
                                          onReplaceSelf: onReplaceSelf,
                                          onEval: onEval
                                        }),
                                    key: String(i)
                                  });
                      }))), /* () */0));
  } else {
    contents = React.createElement(Loading.make, { });
  }
  var askBeforeExiting = typeof t === "number" ? /* Never */744337004 : /* `Always */[
      -76840209,
      {
        message: "Press Ok to discard current results",
        hasLocationChanged: undefined
      }
    ];
  return React.createElement("div", {
              className: styles.root
            }, React.createElement(Layout.VerticalLayout, VerticalLayout.makeProps(/* x2 */26810, null, /* () */0), React.createElement(Layout.VerticalLayoutItem, {
                      children: React.createElement(Source.make, {
                            accept: ".biom,.newick,.nwk,.csv,.tsv,.txt",
                            status: fileSelectStatus,
                            onSelect: onSelect,
                            onClear: onClear
                          })
                    }), React.createElement(Layout.VerticalLayoutItem, {
                      children: contents
                    })), React.createElement(ExitPrompt.make, {
                  when_: askBeforeExiting
                }));
}

var make = Charts2;

export {
  styles ,
  dontWaitFor ,
  Suggestions ,
  Let ,
  State ,
  useToast ,
  make ,
  
}
/* styles Not a pure module */
