import { connect } from "react-redux";

import {
  entityByPathSelector,
  entityDisplayTypeSelector,
  entityBytesSizeByPathSelector,
  projectInformationByPathSelector
} from "features/entities";

import * as EntitySidebar from "containers/Entities/Entity/EntitySidebar/EntitySidebar.bs";

function mapStateToProps(state, { path }) {
  const entity = entityByPathSelector(state, path);
  const displayType = entityDisplayTypeSelector(state, path);
  const bytesSize = entityBytesSizeByPathSelector(state, path);
  const projectInformation = projectInformationByPathSelector(state, path);

  return { entity, displayType, bytesSize, projectInformation };
}

export default connect(mapStateToProps)(EntitySidebar.make);
