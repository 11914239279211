/* eslint-disable react/style-prop-object */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedNumber } from "react-intl";
import { pack, hierarchy } from "d3-hierarchy";
import { scaleOrdinal } from "d3-scale";
import sizeMe from "react-sizeme";
import memoize from "memoize-one";
import { descend, prop, clamp } from "ramda";

import { categorical } from "lib/colors";

import Tooltip, {
  TooltipValue
} from "containers/Entities/TaxonomicAnalysis/Biom/Tooltip";

import styles from "table/TableBubbleChart.css";

class BubbleTooltip extends Component {
  static propTypes = {
    bubble: PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
      r: PropTypes.number.isRequired,
      data: PropTypes.shape({
        label: PropTypes.string
      }).isRequired
    }),
    containerWidth: PropTypes.number.isRequired
  };

  rootRef = React.createRef();

  componentDidMount() {
    this.repositionTooltip();
  }

  componentDidUpdate() {
    this.repositionTooltip();
  }

  repositionTooltip() {
    const { bubble, containerWidth } = this.props;
    const { clientWidth, clientHeight } = this.rootRef.current;

    const tooltipPadding = 5;
    const maxLeft = Math.max(0, containerWidth - clientWidth);

    const topAbove = bubble.y - bubble.r - clientHeight - tooltipPadding;
    const topBelow = bubble.y + bubble.r + tooltipPadding;
    const top = topAbove > 0 ? topAbove : topBelow;
    const left = clamp(0, maxLeft, bubble.x - clientWidth / 2);

    this.rootRef.current.style.top = `${top}px`;
    this.rootRef.current.style.left = `${left}px`;
  }

  render() {
    const { bubble, sum } = this.props;
    const { label, value } = bubble.data;

    const valueFraction = value / sum;

    const formattedValue = <FormattedNumber value={value} />;
    const formattedPercentage = (
      <FormattedNumber
        style="percent"
        minimuFractionDigits={2}
        value={valueFraction}
      />
    );

    return (
      <div className={styles.tooltipRoot} ref={this.rootRef}>
        <Tooltip>
          {label && <TooltipValue>{label}</TooltipValue>}
          <TooltipValue>
            {formattedValue} ({formattedPercentage})
          </TooltipValue>
        </Tooltip>
      </div>
    );
  }
}

function calculateLayout(data, size) {
  const width = Math.max(500, size.width || 0);
  const height = 500;

  const d3Layout = pack()
    .size([width, height])
    .padding(3);
  const d3Hierarchy = hierarchy({
    children: data
  })
    .sum(prop("value"))
    .sort(descend(prop("value")));
  const bubbles = d3Layout(d3Hierarchy).children;

  return {
    width,
    height,
    bubbles
  };
}

function sizesEqual(s1, s2) {
  return s1.width === s2.width;
}

function calculateColorScale(bubbles) {
  const domain = bubbles.map(bubble => bubble.data.id);
  return scaleOrdinal()
    .domain(domain)
    .range(categorical);
}

class BubbleChart extends Component {
  state = {
    hoveredBubbleId: null
  };

  calculateLayout = memoize(calculateLayout);

  calculateSize = memoize(size => size, sizesEqual);

  calculateColorScale = memoize(calculateColorScale);

  getLayout = () => {
    const { bubbles, size: rawSize } = this.props;
    const size = this.calculateSize(rawSize);
    return this.calculateLayout(bubbles, size);
  };

  getColorScale = () => {
    const { bubbles } = this.getLayout();
    return this.calculateColorScale(bubbles);
  };

  handleBubbleMouseEnter = bubble => event => {
    this.setState({ hoveredBubbleId: bubble.data.id });
  };

  handleBubbleMouseLeave = bubble => event => {
    this.setState({ hoveredBubbleId: null });
  };

  renderBubble = bubble => {
    const { hoveredBubbleId } = this.state;

    const colorScale = this.getColorScale();

    return (
      <circle
        cx={bubble.x}
        cy={bubble.y}
        r={Math.max(bubble.r, 1)}
        fill={colorScale(bubble.data.id)}
        fillOpacity={0.7}
        stroke={colorScale(bubble.data.id)}
        strokeWidth={2}
        strokeOpacity={hoveredBubbleId === bubble.data.id ? 1.0 : 0.0}
        key={bubble.data.id}
        data-testid={`bubble[${bubble.data.label}]`}
        onMouseEnter={this.handleBubbleMouseEnter(bubble)}
        onMouseLeave={this.handleBubbleMouseLeave(bubble)}
      />
    );
  };

  render() {
    const { sum } = this.props;
    const { hoveredBubbleId } = this.state;

    const { width, height, bubbles } = this.getLayout();
    const viewBox = `0 0 ${width} ${height}`;
    const hoveredBubble = bubbles.find(b => b.data.id === hoveredBubbleId);

    return (
      // Empty top-level div spans 100% width and is used for
      // measurement with react-sizeme
      <div>
        <div className={styles.plotRoot} style={{ width }}>
          <svg width={width} height={height} viewBox={viewBox}>
            {bubbles.map(this.renderBubble)}
          </svg>
          {hoveredBubble && (
            <BubbleTooltip
              bubble={hoveredBubble}
              containerWidth={width}
              sum={sum}
            />
          )}
        </div>
      </div>
    );
  }
}

export default sizeMe({
  refreshRate: 100,
  noPlaceholder: process.env.NODE_ENV === "test"
})(BubbleChart);
