

import * as React from "react";
import * as StyleCss from "./style.css";
import * as ReactExtras from "../../../lib/solvuu/ReactExtras.bs.js";
import * as Job$SolvuuApi from "solvuu-api/./job.bs.js";
import * as ReactBootstrap from "../../../lib/ReactBootstrap.bs.js";
import * as Lib from "react-bootstrap/lib";

var styles = StyleCss.default;

function jobToStatusString(job) {
  var match = Job$SolvuuApi.status(job);
  if (match >= 479410653) {
    if (match >= 785140586) {
      if (match >= 951752159) {
        return "Running";
      } else {
        return "Unknown";
      }
    } else if (match >= 486291009) {
      return "Succeeded";
    } else {
      return "Failed";
    }
  } else if (match >= -113393609) {
    return "Pending";
  } else {
    return "Canceled";
  }
}

function jobStatusToLabelStyle(job) {
  var match = Job$SolvuuApi.status(job);
  if (match !== -113393609) {
    if (match >= 785140586) {
      if (match >= 951752159) {
        return /* info */-977586066;
      } else {
        return /* warning */164354652;
      }
    } else if (match >= 486291009) {
      return /* success */944645571;
    } else {
      return /* danger */512905955;
    }
  } else {
    return /* info */-977586066;
  }
}

function JobStatus(Props) {
  var job = Props.job;
  return ReactExtras.withTestId("job-status", React.createElement("div", {
                  className: styles.root
                }, React.createElement(Lib.Label, ReactBootstrap.Label.makeProps(jobStatusToLabelStyle(job), undefined, jobToStatusString(job), /* () */0))));
}

var make = JobStatus;

export {
  styles ,
  jobToStatusString ,
  jobStatusToLabelStyle ,
  make ,
  
}
/* styles Not a pure module */
