

import * as Card from "../components/Card/Card.bs.js";
import * as React from "react";

function Term0$RootCard(Props) {
  var header = Props.header;
  var children = Props.children;
  return React.createElement(Card.make, {
              header: React.createElement(Card.Header.make, {
                    children: React.createElement("strong", undefined, header)
                  }),
              children: children
            });
}

var RootCard = {
  make: Term0$RootCard
};

export {
  RootCard ,
  
}
/* Card Not a pure module */
