/* Module for managing actions related to displaying records.
 *
 * Records contain a collection of named sub-entities nested within them.
 * They can be thought of as folders/directories in a filesystem.
 *
 * Actions:
 * * Storing the sorting status (attribute and direction)
 * * Getting the record contents correctly sorted
 *
 * Notes:
 *
 * The state is keyed by record's path:
 * {
 *   "/record1": viewStateForRecord1,
 *   "/record1": viewStateForRecord2
 * }
 */
import { createReducer } from "features/utils";
import { projectFields } from "features/entities/create";
import { map, filter, pipe, sort, descend, ascend } from "ramda";

import {
  entityByPathSelector,
  entityLoadedByPathSelector,
  entityDisplayTypeSelector,
  entityBytesSizeByPathSelector,
  displayTypes
} from ".";
import { isRecordType, naturalOrderForType } from "lib/solvuu/typeUtils";

// CONSTANTS

export const SORT_ASCENDING = "ASC";
export const SORT_DESCENDING = "DESC";

// ACTION TYPES

const RECORD_VIEW_SORT = "ENTITIES/RECORD/VIEW/SORT";
const RECORD_VIEW_SELECT_ITEM = "ENTITIES/RECORD/VIEW/SELECT_ITEM";
const RECORD_VIEW_RESET_SELECTION = "ENTITIES/RECORD/VIEW/RESET_SELECTION";

// ACTIONS

export function sortView(path, attribute, direction) {
  return {
    type: RECORD_VIEW_SORT,
    payload: { path, attribute, direction }
  };
}

export function selectItem(path, itemPath) {
  return {
    type: RECORD_VIEW_SELECT_ITEM,
    payload: { path, itemPath }
  };
}

export function resetSelection(path) {
  return {
    type: RECORD_VIEW_RESET_SELECTION,
    payload: { path }
  };
}

// SELECTORS

const defaultViewOptions = { sort: true };
const defaultViewSorting = { attribute: "type", direction: SORT_DESCENDING };

/* Returns the view of the record under a given path.
 *
 * The view consists of:
 * * currently active sorting settings (attribute name and direction)
 * * a list of correctly sorted paths of record sub-entities
 */
export function recordViewByPathSelector(
  state,
  path,
  { sort } = defaultViewOptions
) {
  const entity = entityByPathSelector(state, path);
  const loaded = entityLoadedByPathSelector(state, path);

  if (!loaded) return undefined;
  if (!isRecordType(entity.type)) return undefined;

  const view = state.entities.recordViews[path] || initialViewState;
  const { attribute, direction } = sort ? view.sorting : defaultViewSorting;
  const displayType = entityDisplayTypeSelector(state, path);

  const getViewPaths = pipe(
    map(p => ({
      ...entityByPathSelector(state, p),
      displayType: entityDisplayTypeSelector(state, p),
      bytesSize: entityBytesSizeByPathSelector(state, p)
    })),
    filterEntities(displayType),
    sortEntitiesBy(attribute, direction),
    map(e => e.path)
  );

  const paths = getViewPaths(entity.nested);
  return { ...view, paths };
}

function filterEntities(recordDisplayType) {
  if (recordDisplayType !== displayTypes.PROJECT) return entities => entities;

  // For projects, hide all internal project fields from view
  return filter(entity => !projectFields.includes(entity.name));
}

function sortEntitiesBy(attribute, direction) {
  const ordering = createOrdering(attribute);
  const directed = direction === SORT_DESCENDING ? descend : ascend;
  return sort(directed(ordering));
}

function createOrdering(attribute) {
  if (attribute === "bytesSize") {
    return entity => {
      if (entity.bytesSize === null) return -Infinity;
      return entity.bytesSize;
    };
  }

  if (attribute === "type") {
    return entity => {
      // Show projects first when sorting by type
      if (entity.displayType === displayTypes.PROJECT) return Infinity;
      // Then, show everything else
      return naturalOrderForType(entity.type);
    };
  }

  return entity => String(entity[attribute]).toLowerCase();
}

export function selectedItemPathSelector(state, path) {
  const view = state.entities.recordViews[path] || initialViewState;
  return view.selectedItemPath;
}

// INITIAL STATE

export const initialState = {};

export const initialViewState = {
  selectedItemPath: null,
  sorting: { attribute: "name", direction: SORT_ASCENDING }
};

// STATE TRANSFORMATIONS

function setViewSorting(state, action) {
  const { attribute, direction } = action.payload;
  return {
    ...state,
    sorting: {
      ...state.sorting,
      attribute,
      direction
    }
  };
}

function setViewItem(state, action) {
  const { itemPath } = action.payload;
  return {
    ...state,
    selectedItemPath: itemPath
  };
}

function resetViewItem(state, action) {
  return {
    ...state,
    selectedItemPath: null
  };
}

function setRecordViewOnUpdate(state, action) {
  const { path } = action.payload;
  return {
    ...state,
    [path]: recordViewReducer(state[path], action)
  };
}

// REDUCERS

const recordViewReducer = createReducer(initialViewState, {
  [RECORD_VIEW_SORT]: setViewSorting,
  [RECORD_VIEW_SELECT_ITEM]: setViewItem,
  [RECORD_VIEW_RESET_SELECTION]: resetViewItem
});

export default createReducer(initialState, {
  [RECORD_VIEW_SORT]: setRecordViewOnUpdate,
  [RECORD_VIEW_SELECT_ITEM]: setRecordViewOnUpdate,
  [RECORD_VIEW_RESET_SELECTION]: setRecordViewOnUpdate
});
