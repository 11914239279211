

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Button from "../../../components/Button.bs.js";
import * as Import from "../../../features/import.bs.js";
import * as Command from "../Command/Command.bs.js";
import * as JobUtils from "../../../lib/solvuu/jobUtils.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Pervasives from "bs-platform/lib/es6/pervasives.js";
import * as ReactIntl from "react-intl";
import * as StyleCss from "./style.css";
import * as JobDuration from "../JobDuration/JobDuration.bs.js";
import * as ReactExtras from "../../../lib/solvuu/ReactExtras.bs.js";
import * as Blob$SolvuuApi from "solvuu-api/./blob.bs.js";
import * as FormattedBytes from "../../../components/FormattedBytes/FormattedBytes.bs.js";
import * as VerticalLayout from "../../../components/Layout/VerticalLayout.bs.js";
import * as FormattedRelative from "../../../components/FormattedRelative/FormattedRelative.bs.js";
import * as Layout from "components/Layout";

var styles = StyleCss.default;

function JobDetails0$EmptyValue(Props) {
  return React.createElement("div", {
              className: styles.empty
            }, "none");
}

function orList(elements) {
  if (elements) {
    return elements;
  } else {
    return /* :: */[
            React.createElement(JobDetails0$EmptyValue, {
                  key: "0"
                }),
            /* [] */0
          ];
  }
}

var EmptyValue = {
  make: JobDetails0$EmptyValue,
  orList: orList
};

function JobDetails0$JobDetailCategory(Props) {
  var title = Props.title;
  var match = Props.variant;
  var variant = match !== undefined ? match : /* Secondary */67972948;
  var children = Props.children;
  var className = variant >= 67972948 ? styles.detailCategory : Cn.make(/* :: */[
          styles.detailCategory,
          /* :: */[
            styles.detailCategoryPrimary,
            /* [] */0
          ]
        ]);
  return React.createElement("div", {
              className: className
            }, React.createElement("div", {
                  className: styles.detailCategoryTitle
                }, title), React.createElement("div", undefined, children));
}

var JobDetailCategory = {
  make: JobDetails0$JobDetailCategory
};

function JobDetails0$JobDetail(Props) {
  var title = Props.title;
  var content = Props.content;
  return React.createElement("div", {
              className: styles.detail
            }, React.createElement("div", {
                  className: styles.detailTitle
                }, title), React.createElement("div", {
                  className: styles.detailContent
                }, content));
}

var JobDetail = {
  make: JobDetails0$JobDetail
};

function JobDetails0$Columns(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: styles.columns
            }, children);
}

var Columns = {
  make: JobDetails0$Columns
};

function JobDetails0$Column(Props) {
  var match = Props.width;
  var width = match !== undefined ? match : /* Auto */726666127;
  var children = Props.children;
  var className = width >= 726666127 ? styles.column : styles.columnThird;
  return React.createElement("div", {
              className: className
            }, children);
}

var Column = {
  make: JobDetails0$Column
};

var sectionSeparator = React.createElement("div", {
      className: styles.separator
    });

function fetchBlob(thisId, apiClient) {
  var $$let = Blob$SolvuuApi.Get_by_id.$$Request;
  var $$let$1 = Blob$SolvuuApi.Get_by_id.$$Response;
  return Import.$great$great$pipe(Curry._3(apiClient.f, /* POST */891112544, {
                  entity: Blob$SolvuuApi.Get_by_id.entity,
                  service: Blob$SolvuuApi.Get_by_id.service,
                  $$Request: {
                    path: $$let.path,
                    body: $$let.body,
                    info: $$let.info
                  },
                  $$Response: {
                    ok_of_json: $$let$1.ok_of_json,
                    json_of_ok: $$let$1.json_of_ok,
                    bad_request_of_json: $$let$1.bad_request_of_json,
                    json_of_bad_request: $$let$1.json_of_bad_request,
                    not_found_of_json: $$let$1.not_found_of_json,
                    json_of_not_found: $$let$1.json_of_not_found
                  }
                }, {
                  id: thisId,
                  instructions: undefined
                }), (function (response) {
                if (typeof response === "number") {
                  return /* `Error */[
                          106380200,
                          /* Unauthorized */324098644
                        ];
                } else {
                  var info = response[1].info;
                  var variant = info[0];
                  if (variant !== -99004555) {
                    if (variant >= 17692) {
                      return /* `Ok */[
                              17724,
                              info[1].blob
                            ];
                    } else {
                      return /* `Error */[
                              106380200,
                              /* Not_found */-358247754
                            ];
                    }
                  } else {
                    return /* `Error */[
                            106380200,
                            /* Bad_request */-99004555
                          ];
                  }
                }
              }));
}

function useBlobs(blobIds, apiClient) {
  var match = React.useState((function () {
          return /* [] */0;
        }));
  var setState = match[1];
  React.useEffect((function () {
          Import.$great$great$pipe(Promise.all(Belt_Array.map(Curry._1(Import.List.toArray, blobIds), (function (id) {
                          return fetchBlob(id, apiClient);
                        }))), (function (array) {
                  return Curry._1(setState, (function (param) {
                                return Curry._1(Import.List.fromArray, array);
                              }));
                }));
          return ;
        }), /* array */[blobIds]);
  return match[0];
}

function JobDetails0$JobOutputSection(Props) {
  var jobId = Props.jobId;
  var output = Props.output;
  var apiClient = Props.apiClient;
  var blobs = useBlobs(output.blobs, apiClient);
  var blobs$1 = Curry._2(Import.List.keepMap, blobs, (function (result) {
          if (result[0] >= 106380200) {
            return ;
          } else {
            return result[1];
          }
        }));
  var visibleBlobs = JobUtils.Re.visibleOutputBlobs(blobs$1);
  var totalBytes = JobUtils.Re.outputTotalBytes(visibleBlobs);
  var size = React.createElement(FormattedBytes.make, {
        value: totalBytes
      });
  var count = Curry._1(Import.List.length, visibleBlobs);
  var summary = count !== 0 ? (
      count !== 1 ? React.createElement(ReactIntl.FormattedMessage, {
              id: "jobs.show.details.solvuu.output.files.many",
              values: {
                count: count,
                size: size
              }
            }) : React.createElement(ReactIntl.FormattedMessage, {
              id: "jobs.show.details.solvuu.output.files.one",
              values: {
                size: size
              }
            })
    ) : React.createElement(JobDetails0$EmptyValue, { });
  var match = Curry._1(Import.List.length, visibleBlobs);
  var actions;
  if (match !== 0) {
    var browseButton = ReactExtras.withTestId("browse-job-output", React.createElement(Button.Link.make, Button.Link.makeProps(undefined, /* `Jobs */[
                  826169846,
                  /* `Path */[
                    892015045,
                    /* tuple */[
                      jobId,
                      ""
                    ]
                  ]
                ], /* Primary */-791844958, undefined, undefined, "Browse", /* () */0)));
    actions = React.createElement(Layout.VerticalLayoutItem, {
          children: browseButton
        });
  } else {
    actions = null;
  }
  var contents = React.createElement(Layout.VerticalLayout, VerticalLayout.makeProps(/* x2 */26810, null, /* () */0), React.createElement(Layout.VerticalLayoutItem, {
            children: summary
          }), actions);
  return React.createElement(React.Fragment, undefined, contents);
}

var JobOutputSection = {
  fetchBlob: fetchBlob,
  useBlobs: useBlobs,
  make: JobDetails0$JobOutputSection
};

function renderSolvuuDetails(id, spec, output, created, completed, apiClient) {
  var resources = spec.resources;
  var command = spec.command;
  var container = spec.container;
  var title = React.createElement(ReactIntl.FormattedMessage, {
        id: "jobs.show.details.solvuu.container.image"
      });
  var match = container.image;
  var match$1 = match[1];
  var content = Curry._2(Import.sprintf(/* Format */[
            /* String */Block.__(2, [
                /* No_padding */0,
                /* Char_literal */Block.__(12, [
                    /* "/" */47,
                    /* String */Block.__(2, [
                        /* No_padding */0,
                        /* End_of_format */0
                      ])
                  ])
              ]),
            "%s/%s"
          ]), match$1.registry, match$1.name);
  var containerImageDetail = React.createElement(JobDetails0$JobDetail, {
        title: title,
        content: content
      });
  var title$1 = React.createElement(ReactIntl.FormattedMessage, {
        id: "jobs.show.details.solvuu.container.cached"
      });
  var content$1 = Pervasives.string_of_bool(container.cached);
  var containerCachedDetail = React.createElement(JobDetails0$JobDetail, {
        title: title$1,
        content: content$1
      });
  var title$2 = React.createElement(ReactIntl.FormattedMessage, {
        id: "jobs.show.details.solvuu.resources.cpu"
      });
  var content$2 = resources.cpu.toString();
  var cpuDetail = React.createElement(JobDetails0$JobDetail, {
        title: title$2,
        content: content$2
      });
  var title$3 = React.createElement(ReactIntl.FormattedMessage, {
        id: "jobs.show.details.solvuu.resources.mem"
      });
  var content$3 = React.createElement(FormattedBytes.make, {
        value: resources.mem
      });
  var memDetail = React.createElement(JobDetails0$JobDetail, {
        title: title$3,
        content: content$3
      });
  var title$4 = React.createElement(ReactIntl.FormattedMessage, {
        id: "jobs.show.details.solvuu.resources.disk"
      });
  var content$4 = React.createElement(FormattedBytes.make, {
        value: resources.disk
      });
  var diskDetail = React.createElement(JobDetails0$JobDetail, {
        title: title$4,
        content: content$4
      });
  var content$5 = React.createElement(Command.make, {
        spec: /* `Solvuu */[
          -522825178,
          command.spec
        ]
      });
  var commandSpecDetail = React.createElement("div", {
        className: styles.commandWrapper
      }, content$5);
  var title$5 = React.createElement(ReactIntl.FormattedMessage, {
        id: "jobs.show.details.solvuu.time.created"
      });
  var content$6 = React.createElement(FormattedRelative.make, {
        value: created
      });
  var createdTimestampDetail = React.createElement(JobDetails0$JobDetail, {
        title: title$5,
        content: content$6
      });
  var title$6 = React.createElement(ReactIntl.FormattedMessage, {
        id: "jobs.show.details.solvuu.time.duration"
      });
  var content$7 = React.createElement(JobDuration.make, {
        created: created,
        completed: completed
      });
  var durationDetail = React.createElement(JobDetails0$JobDetail, {
        title: title$6,
        content: content$7
      });
  var outputDetail = React.createElement(JobDetails0$JobOutputSection, {
        jobId: id,
        output: output,
        apiClient: apiClient
      });
  return React.createElement(JobDetails0$Columns, {
              children: null
            }, React.createElement(JobDetails0$Column, {
                  children: null
                }, React.createElement(JobDetails0$JobDetailCategory, {
                      title: React.createElement(ReactIntl.FormattedMessage, {
                            id: "jobs.show.details.solvuu.command"
                          }),
                      children: commandSpecDetail
                    }), React.createElement(JobDetails0$JobDetailCategory, {
                      title: React.createElement(ReactIntl.FormattedMessage, {
                            id: "jobs.show.details.solvuu.output"
                          }),
                      children: outputDetail
                    })), React.createElement(JobDetails0$Column, {
                  width: /* OneThird */160213825,
                  children: null
                }, React.createElement(JobDetails0$JobDetailCategory, {
                      title: React.createElement(ReactIntl.FormattedMessage, {
                            id: "jobs.show.details.solvuu.container"
                          }),
                      children: React.createElement("div", {
                            className: styles.detailsHorizontal
                          }, containerImageDetail, containerCachedDetail)
                    }), React.createElement(JobDetails0$JobDetailCategory, {
                      title: React.createElement(ReactIntl.FormattedMessage, {
                            id: "jobs.show.details.solvuu.resources"
                          }),
                      children: React.createElement("div", {
                            className: styles.detailsHorizontal
                          }, cpuDetail, memDetail, diskDetail)
                    }), React.createElement(JobDetails0$JobDetailCategory, {
                      title: React.createElement(ReactIntl.FormattedMessage, {
                            id: "jobs.show.details.solvuu.time"
                          }),
                      children: React.createElement("div", {
                            className: styles.detailsHorizontal
                          }, createdTimestampDetail, durationDetail)
                    })));
}

export {
  styles ,
  EmptyValue ,
  JobDetailCategory ,
  JobDetail ,
  Columns ,
  Column ,
  sectionSeparator ,
  JobOutputSection ,
  renderSolvuuDetails ,
  
}
/* styles Not a pure module */
