

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Lang$SolvuuApi from "solvuu-api/./lang.bs.js";
import * as Term$SolvuuApi from "solvuu-api/./term.bs.js";

function stringify(prim) {
  return JSON.stringify(prim);
}

function parseFromString(prim) {
  return JSON.parse(prim);
}

function parseFromJson(x) {
  return x;
}

var toJsCache = new WeakMap();

var fromJsCache = new WeakMap();

function cacheTransformation(term, termJs) {
  if (term !== -301701320 && term !== 870828711 && term !== 914889282) {
    toJsCache.set(term, termJs);
    fromJsCache.set(termJs, term);
    return /* () */0;
  } else {
    return /* () */0;
  }
}

function convertFromJs(termJs) {
  var match = fromJsCache.get(termJs);
  if (match == null) {
    var term = Curry._1(Lang$SolvuuApi.Term.Json.read_t, termJs);
    cacheTransformation(term, termJs);
    return term;
  } else {
    return match;
  }
}

function convertToJs(term) {
  var match = toJsCache.get(term);
  if (match == null) {
    var termJs = Curry._1(Lang$SolvuuApi.Term.Json.write_t, term);
    cacheTransformation(term, termJs);
    return termJs;
  } else {
    return match;
  }
}

var convertEventInfoToJs = Term$SolvuuApi.$$Event.json_of_info;

var convertEventInfoFromJs = Term$SolvuuApi.$$Event.info_of_json;

export {
  convertFromJs ,
  convertToJs ,
  stringify ,
  parseFromString ,
  parseFromJson ,
  convertEventInfoFromJs ,
  convertEventInfoToJs ,
  
}
/* toJsCache Not a pure module */
