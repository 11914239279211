

import * as EntitySidebarReduxJs from "./EntitySidebarRedux.js";

var make = EntitySidebarReduxJs.default;

export {
  make ,
  
}
/* make Not a pure module */
