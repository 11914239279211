

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Import from "../features/import.bs.js";
import * as Std$Bolt from "bolt/./std.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Lang$SolvuuApi from "solvuu-api/./lang.bs.js";
import * as Root$SolvuuApi from "solvuu-api/./root.bs.js";
import * as Science$SolvuuApi from "solvuu-api/./science.bs.js";
import * as Caml_builtin_exceptions from "bs-platform/lib/es6/caml_builtin_exceptions.js";

function $$eval(f, t) {
  if (typeof t === "number" || t[0] !== 847309489) {
    return Curry._2(Lang$SolvuuApi.Term.eval_error, "typing error", t);
  } else {
    var result = Curry._2(Root$SolvuuApi.Parallel_coordinates.Arg.of_record, t[1], (function (columnNames, data) {
            var match = Curry._1(Import.List.length, columnNames) >= 3;
            if (match) {
              var values = typeof data === "number" || data[0] !== 848054398 ? /* `Error */[
                  106380200,
                  /* ExpectedList */429088534
                ] : Curry._1(Std$Bolt.Res.all, Curry._2(Import.List.map, data[1], (function (row) {
                            if (typeof row === "number" || row[0] !== 847309489) {
                              return /* `Error */[
                                      106380200,
                                      /* ExpectedRecord */980211529
                                    ];
                            } else {
                              var record = row[1];
                              return Curry._1(Std$Bolt.Res.all, Curry._2(Import.List.map, columnNames, (function (columnName) {
                                                var match = Curry._2(Lang$SolvuuApi.Record.Param.R.find, record, /* `Named */[
                                                      699531161,
                                                      columnName
                                                    ]);
                                                if (match !== undefined) {
                                                  var value = match;
                                                  if (typeof value === "number") {
                                                    return /* `Error */[
                                                            106380200,
                                                            /* ExpectedNumeric */-543874283
                                                          ];
                                                  } else {
                                                    var variant = value[0];
                                                    if (variant !== 3654863 && variant !== 365180284) {
                                                      if (variant !== 925978388) {
                                                        return /* `Error */[
                                                                106380200,
                                                                /* ExpectedNumeric */-543874283
                                                              ];
                                                      } else {
                                                        var match$1 = value[1];
                                                        if (typeof match$1 === "number") {
                                                          return /* `Error */[
                                                                  106380200,
                                                                  /* ExpectedNumeric */-543874283
                                                                ];
                                                        } else {
                                                          var variant$1 = match$1[0];
                                                          if (variant$1 !== 3654863 && variant$1 !== 365180284) {
                                                            return /* `Error */[
                                                                    106380200,
                                                                    /* ExpectedNumeric */-543874283
                                                                  ];
                                                          } else {
                                                            return /* `Ok */[
                                                                    17724,
                                                                    match$1[1]
                                                                  ];
                                                          }
                                                        }
                                                      }
                                                    } else {
                                                      return /* `Ok */[
                                                              17724,
                                                              value[1]
                                                            ];
                                                    }
                                                  }
                                                } else {
                                                  return /* `Error */[
                                                          106380200,
                                                          /* `NoColumnWithName */[
                                                            382779560,
                                                            columnName
                                                          ]
                                                        ];
                                                }
                                              })));
                            }
                          })));
              if (values[0] >= 106380200) {
                var e = values[1];
                var message = typeof e === "number" ? (
                    e !== 429088534 ? (
                        e >= 980211529 ? "Expected record" : "Expected numeric"
                      ) : "Expected list"
                  ) : "No column with name: " + e[1];
                return Curry._2(Lang$SolvuuApi.Term.eval_error, message, t);
              } else {
                var values$1 = Curry._2(Import.List.map, Curry._1(Import.List.fromArray, Science$SolvuuApi.normalizeMatrix(Curry._1(Import.List.toArray, Curry._2(Import.List.map, values[1], Import.List.toArray)))), (function (arr) {
                        return /* `List */[
                                848054398,
                                Curry._1(Import.List.fromArray, Belt_Array.map(arr, (function (x) {
                                            return /* `Float */[
                                                    365180284,
                                                    x
                                                  ];
                                          })))
                              ];
                      }));
                var labels = Curry._2(Import.List.map, columnNames, (function (x) {
                        return /* `String */[
                                -976970511,
                                x
                              ];
                      }));
                var match$1 = Curry._1(Lang$SolvuuApi.Record.Param.R.of_alist, /* :: */[
                      /* tuple */[
                        Root$SolvuuApi.Parallel_coordinates.T.Const.Label.labels,
                        /* `List */[
                          848054398,
                          labels
                        ]
                      ],
                      /* :: */[
                        /* tuple */[
                          Root$SolvuuApi.Parallel_coordinates.T.Const.Label.values,
                          /* `List */[
                            848054398,
                            values$1
                          ]
                        ],
                        /* [] */0
                      ]
                    ]);
                if (match$1[0] >= 17724) {
                  return /* `Viz */[
                          4300231,
                          /* `Record */[
                            847309489,
                            Curry._2(Lang$SolvuuApi.Record.tagged, f, /* `Record */[
                                  847309489,
                                  match$1[1]
                                ])
                          ]
                        ];
                } else {
                  throw [
                        Caml_builtin_exceptions.assert_failure,
                        /* tuple */[
                          "RunParallelCoordinates.re",
                          94,
                          35
                        ]
                      ];
                }
              }
            } else {
              return Curry._2(Lang$SolvuuApi.Term.eval_error, "At least 3 columns are required", t);
            }
          }));
    if (result[0] >= 106380200) {
      var message = Curry._1(Import.List.toArray, Curry._2(Import.List.map, result[1], (function (param) {
                    var label = Curry._1(Lang$SolvuuApi.Label.to_string, param[0]);
                    var reason = param[1] >= 144245463 ? "invalid" : "not_found";
                    return "(" + (label + (", " + (reason + ") ")));
                  }))).join(" ");
      return Curry._2(Lang$SolvuuApi.Term.eval_error, message, t);
    } else {
      return result[1];
    }
  }
}

export {
  $$eval ,
  
}
/* Import Not a pure module */
