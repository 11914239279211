

import * as React from "react";

function Spread(Props) {
  var props = Props.props;
  var children = Props.children;
  return React.cloneElement(children, props);
}

var make = Spread;

export {
  make ,
  
}
/* react Not a pure module */
