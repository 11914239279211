

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as FormattedRelative from "../components/FormattedRelative/FormattedRelative.bs.js";
import * as Time$SolvuuClient from "solvuu-client/./time.bs.js";

function $$Event(Props) {
  var id = Props.id;
  var timestamp = Props.timestamp;
  var user = Props.user;
  var info = Props.info;
  var infoToJson = Props.infoToJson;
  var infoComponent = Props.infoComponent;
  var formattedTimestamp = React.createElement(FormattedRelative.make, {
        value: Time$SolvuuClient.of_span_since_epoch(Curry._1(Time$SolvuuClient.Span.of_sec, timestamp)),
        updateInterval: /* `Seconds */[
          668917343,
          3
        ]
      });
  var formattedInfo = JSON.stringify(Curry._1(infoToJson, info), null, 2);
  var formattedUsername = typeof user === "number" ? "System" : user[1].username;
  return React.createElement("tr", undefined, React.createElement("td", undefined, id), React.createElement("td", undefined, formattedTimestamp), React.createElement("td", undefined, formattedUsername), infoComponent !== undefined ? React.createElement("td", undefined, React.createElement(infoComponent, {
                        info: info
                      })) : null, React.createElement("td", undefined, React.createElement("details", undefined, React.createElement(ReactIntl.FormattedMessage, {
                          id: "settings.log.info.show",
                          tagName: "summary"
                        }), React.createElement("pre", undefined, formattedInfo))));
}

var make = $$Event;

export {
  make ,
  
}
/* react Not a pure module */
