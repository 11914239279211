

import * as Link from "../containers/Link.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Import from "../features/import.bs.js";
import * as Js_dict from "bs-platform/lib/es6/js_dict.js";
import * as Caml_obj from "bs-platform/lib/es6/caml_obj.js";
import * as WithTestId from "../containers/WithTestId.bs.js";
import * as Classnames from "classnames";
import * as ReactExtras from "../lib/solvuu/ReactExtras.bs.js";
import * as TabsPrimaryCss from "./TabsPrimary.css";
import * as TabsSecondaryCss from "./TabsSecondary.css";

var primary = TabsPrimaryCss.default;

var secondary = TabsSecondaryCss.default;

function tabs(x) {
  if (x >= 67972948) {
    return secondary.tabs;
  } else {
    return primary.tabs;
  }
}

function tab(x) {
  if (x >= 67972948) {
    return secondary.tab;
  } else {
    return primary.tab;
  }
}

function activeTab(x) {
  if (x >= 67972948) {
    return secondary.activeTab;
  } else {
    return primary.activeTab;
  }
}

function Tabs(Props) {
  var tabs$1 = Props.tabs;
  var init = Props.init;
  var match = Props.to;
  var _to = match !== undefined ? match : (function (param) {
        return /* Void */959246196;
      });
  var toString = Props.toString;
  var match$1 = Props.type_;
  var type_ = match$1 !== undefined ? match$1 : /* Primary */-791844958;
  var match$2 = React.useState((function () {
          return init;
        }));
  var setId = match$2[1];
  var id = match$2[0];
  var tab$1 = Curry._2(Import.List.getBy, tabs$1, (function (param) {
          return Caml_obj.caml_equal(param[0], id);
        }));
  return React.createElement("div", undefined, React.createElement("nav", {
                  className: tabs(type_)
                }, ReactExtras.listToElement(Curry._2(Import.List.map, tabs$1, (function (param) {
                            var id = param[0];
                            return React.createElement(WithTestId.make, {
                                        id: "tab[" + (Curry._1(toString, id) + "]"),
                                        children: React.createElement(Link.make, {
                                              to: Curry._1(_to, id),
                                              className: Classnames.default(tab(type_), Js_dict.fromArray(/* array */[/* tuple */[
                                                          activeTab(type_),
                                                          tab$1 !== undefined ? Caml_obj.caml_equal(id, tab$1[0]) : false
                                                        ]])),
                                              onClick: (function (param) {
                                                  return Curry._1(setId, (function (param) {
                                                                return id;
                                                              }));
                                                }),
                                              children: param[1].title,
                                              key: Curry._1(toString, id)
                                            }),
                                        key: Curry._1(toString, id)
                                      });
                          })))), type_ >= 67972948 ? React.createElement("hr", {
                    className: secondary.divider
                  }) : null, type_ >= 67972948 ? React.createElement(WithTestId.make, {
                    id: "tab-contents",
                    children: React.createElement("section", undefined, tab$1 !== undefined ? Curry._1(tab$1[1].render, /* () */0) : "No such tab")
                  }) : React.createElement("section", {
                    className: primary.tabContentWrapper
                  }, React.createElement(WithTestId.make, {
                        id: "tab-contents",
                        children: React.createElement("div", {
                              className: primary.tabContent
                            }, tab$1 !== undefined ? Curry._1(tab$1[1].render, /* () */0) : "No such tab")
                      })));
}

var make = Tabs;

var primary$1 = /* Primary */-791844958;

var secondary$1 = /* Secondary */67972948;

export {
  make ,
  primary$1 as primary,
  secondary$1 as secondary,
  
}
/* primary Not a pure module */
