

import * as React from "react";
import * as ReactIntl from "react-intl";
import * as PageHeader from "components/PageHeader";

function SettingsComponent(Props) {
  var children = Props.children;
  return React.createElement("div", undefined, React.createElement(PageHeader.default, {
                  children: null
                }, React.createElement(PageHeader.default.Name, {
                      children: React.createElement(ReactIntl.FormattedMessage, {
                            id: "settings.header"
                          })
                    }), children));
}

var make = SettingsComponent;

export {
  make ,
  
}
/* react Not a pure module */
