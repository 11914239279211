

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as ReactExtras from "../../lib/solvuu/ReactExtras.bs.js";
import * as FormControls from "./FormControls.bs.js";

function Input(Props) {
  var name = Props.name;
  var label = Props.label;
  var value = Props.value;
  var onChange = Props.onChange;
  var type_ = Props.type_;
  var disabled = Props.disabled;
  var required = Props.required;
  var autoFocus = Props.autoFocus;
  var validationState = Props.validationState;
  var handleChange = function ($$event) {
    return Curry._1(onChange, ReactExtras.eventTargetValue($$event));
  };
  return React.createElement(FormControls.Input.make, FormControls.Input.makeProps(label, name, type_, value, handleChange, disabled, required, autoFocus, validationState, /* () */0));
}

var make = Input;

export {
  make ,
  
}
/* react Not a pure module */
