

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as MemoizeWeak from "memoize-weak";
import * as Lang$SolvuuApi from "solvuu-api/./lang.bs.js";

var convertToJs = Lang$SolvuuApi.Type.Json.write_t;

var memoizedConvertFromJs = MemoizeWeak.default((function (typeJs) {
        return Curry._1(Lang$SolvuuApi.Type.Json.read_t, typeJs);
      }));

function convertFromJs(typeJs) {
  return memoizedConvertFromJs(typeJs);
}

function stringify(prim) {
  return JSON.stringify(prim);
}

function parseFromJson(x) {
  return x;
}

export {
  convertFromJs ,
  convertToJs ,
  stringify ,
  parseFromJson ,
  
}
/* memoizedConvertFromJs Not a pure module */
