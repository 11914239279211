

import * as ReactHotLoader from "react-hot-loader";

var make = ReactHotLoader.AppContainer;

var AppContainer = {
  make: make
};

export {
  AppContainer ,
  
}
/* make Not a pure module */
