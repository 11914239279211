/* Module for managing entities.
 *
 * Entities are the central concept of this application.
 * They are basically pieces of data that can be stored and manipulated by the user.
 * I.e. simple "terms" like Strings, Integers, Booleans
 * or more complex ones, like Blobs, Records or Tables.
 *
 * See ./parseResponse.js for full list of entity types.
 *
 * Actions:
 * * Fetching an entity located under a solvuu path
 * * Reconciling an entity's data
 * * Evaluating an arbitrary term
 * * Creating entities:
 *   * Simple terms
 *   * Projects
 *   * Tables from CSV files
 *
 * Notes:
 *
 * Every user has a "root" entity associated with them - a Record named the same as their username.
 *
 * Each entity has a "path" associated with it which defines it's location and serves as an unique
 * identifier. E.g. "/darwin/samples".
 */
import { values } from "ramda";
import { push } from "connected-react-router";
import { NETWORK_ERROR, TIMEOUT_ERROR, CONNECTION_ERROR } from "apisauce";

import * as Paths from "Paths";
import { fetchOperation } from "./operations";
import { parseResponse, parseBlobs } from "./parseResponse";
import { authenticationSelector } from "features/authentication";
import { locationSelector } from "features/routing";
import {
  fetchJobOutput,
  jobMetaSelector,
  jobOutputBlobsSelector
} from "features/jobs";
import { blobTotalBytesSelector } from "features/upload";
import * as PathUtils from "lib/solvuu/pathUtils.bs";
import {
  isTermLoaded,
  hasBlobIdInTerm,
  blobIdOfTerm
} from "lib/solvuu/termUtils";
import { isRecordType } from "lib/solvuu/typeUtils";
import { terms } from "lib/solvuu/termEval";

import { projectFields, PROJECT } from "./create";

// CONSTANTS

/**
 * Represents different ways of displaying entities when navigating the app.
 */
export const displayTypes = {
  // Record with particular fields can be displayed as a "Project"
  PROJECT: PROJECT,
  // The rest of entities
  ENTITY: "Entity"
};

export const SAVE_IN_STORE = "entities/SAVE_IN_STORE";
export const CLEAR_FROM_STORE = "entities/CLEAR_FROM_STORE";

// ACTIONS

function evaluatePath(solvuuApi, path) {
  const term = terms.path(path);
  return solvuuApi.termEval(term);
}

export function fetchEntity(path) {
  const { start, success, failure } = fetchOperation.actionCreators;

  async function fetchPersistedPath(dispatch, getState, { solvuuApi }) {
    dispatch(start({ path }));
    const response = await evaluatePath(solvuuApi, path);
    if (response.ok) {
      const entitiesByPath = parseResponse(response.data, path);
      dispatch(success({ path, entitiesByPath }));
    } else {
      const message = extractErrorMessageFromFetchResponse(response);
      dispatch(failure({ path, message }));
    }
  }

  async function fetchJobOutputPath(dispatch, getState, { solvuuApi }) {
    dispatch(start({ path }));

    const { jobId, basePath } = PathUtils.deconstructJobOutputPath(path);

    await dispatch(fetchJobOutput(jobId));

    const blobs = jobOutputBlobsSelector(getState(), jobId);
    if (blobs) {
      const entitiesByPath = parseBlobs(blobs, basePath);
      dispatch(success({ path, entitiesByPath }));
    } else {
      const outputFetch = jobMetaSelector(getState(), jobId).outputFetch;
      const message =
        (outputFetch.errors && outputFetch.errors.message) ||
        `Could not fetch output files for job #${jobId}`;
      dispatch(failure({ path, message }));
    }
  }

  // For job output paths, we fetch job output blobs and construct a faux directory structure
  // based on the blobs source attribute.
  return PathUtils.isJobOutputPath(path)
    ? fetchJobOutputPath
    : fetchPersistedPath;
}

export function reconcileEntity(path) {
  const { success } = fetchOperation.actionCreators;

  return async function(dispatch, getState, { solvuuApi }) {
    const response = await evaluatePath(solvuuApi, path);
    if (response.ok) {
      const entitiesByPath = parseResponse(response.data, path);
      dispatch(success({ path, entitiesByPath }));
    }
  };
}

/**
 * Evaluates an arbitrary term.
 *
 * Unique evaluationPath must be provided (see pathUtils#createEvaluationPath).
 */
const evaluationLock = createActionLock();
export function evaluateEntity(evaluationPath, term) {
  const { start, success, failure } = fetchOperation.actionCreators;

  return async function(dispatch, getState, { solvuuApi }) {
    const token = evaluationLock.getToken(evaluationPath);
    dispatch(start({ path: evaluationPath }));
    const response = await solvuuApi.termEval(term);

    // Another request has been sent in the meantime.
    // Abort now to avoid race conditions.
    if (!evaluationLock.hasToken(evaluationPath, token)) return;

    if (response.ok) {
      const entitiesByPath = parseResponse(response.data, evaluationPath);
      dispatch(success({ path: evaluationPath, entitiesByPath }));
    } else {
      const message = extractErrorMessageFromFetchResponse(response);
      dispatch(failure({ path: evaluationPath, message }));
    }
  };
}

export function request(meth, m, info) {
  return async function(_dispatch, _getState, { solvuuApi2 }) {
    return await solvuuApi2(meth, m, info);
  };
}

export function saveEntityInStore({ path, name, term, type }) {
  const entitiesByPath = { [path]: { path, name, term, type } };
  return {
    type: SAVE_IN_STORE,
    payload: { entitiesByPath }
  };
}

export function clearEntityFromStore(path) {
  return {
    type: CLEAR_FROM_STORE,
    payload: { path }
  };
}

export function navigateToEntity(path) {
  return function(dispatch) {
    dispatch(push(Paths.entity(path)));
  };
}

// Internal

/**
 * A simple mechanism for making sure no other action has started since we grabbed the token.
 */
function createActionLock() {
  const tokens = {};

  function getToken(id) {
    tokens[id] = tokens[id] || 0;
    tokens[id] += 1;
    return tokens[id];
  }

  function hasToken(id, token) {
    return tokens[id] === token;
  }

  return { getToken, hasToken };
}

function extractErrorMessageFromResponse(response) {
  const { problem, data } = response;
  if (problem === TIMEOUT_ERROR) {
    return "The server took too long to respond.";
  }
  if (problem === CONNECTION_ERROR) {
    return "The server could not be reached. Check your internet connection and try again.";
  }
  if (problem === NETWORK_ERROR) {
    return "The network is not available. Check your internet connection and try again.";
  }
  if (data && data.msg) return data.msg;
  return null;
}

function extractErrorMessageFromFetchResponse(response) {
  return extractErrorMessageFromResponse(response);
}

export function extractErrorMessageFromCreateResponse(response) {
  const message = extractErrorMessageFromResponse(response);
  if (message && message.includes("Path_exists")) {
    return "This item already exists: choose another name";
  }
  return message;
}

// SELECTORS

export function entityByPathSelector(state, path) {
  return state.entities.byPath[path];
}

export function entityFetchByPathSelector(state, path) {
  return state.entities.meta.fetch[path] || fetchOperation.initialState;
}

export function entityLoadedByPathSelector(state, path) {
  const entity = entityByPathSelector(state, path);
  return !!entity && !!entity.term && isTermLoaded(entity.term);
}

export function entityDisplayTypeSelector(state, path) {
  const entity = entityByPathSelector(state, path);
  if (!entity) return undefined;

  if (shouldDisplayProject(state, entity)) {
    return displayTypes.PROJECT;
  }
  return displayTypes.ENTITY;
}

export function pathByEntityTermSelector(state, term) {
  let entities = values(state.entities.byPath);
  let entity = entities.find(entity => entity.term === term);
  return entity ? entity.path : null;
}

// Display a record using the Project screen if it has title and status
function shouldDisplayProject(state, entity) {
  const projectInformation = projectInformationByPathSelector(
    state,
    entity.path
  );

  return !!(
    projectInformation &&
    projectInformation.projectTitle &&
    projectInformation.projectStatus
  );
}

export function projectInformationByPathSelector(state, path) {
  const entity = entityByPathSelector(state, path);
  if (!entity) return undefined;
  if (!isRecordType(entity.type)) return undefined;

  const projectInformation = projectFields.reduce(
    (information, fieldName) => ({
      ...information,
      [fieldName]: entityByPathSelector(state, `${entity.path}/${fieldName}`)
    }),
    {}
  );

  return projectInformation;
}

/**
 * Returns the path of the record entity closest to the currently visible entity.
 * Useful for "Add" functionality, which needs a record path to add in.
 */
export function deepestVisibleRecordPathSelector(state) {
  const { pathname: entityPath } = locationSelector(state);
  const { username } = authenticationSelector(state);
  const rootEntityPath = "/" + username;
  const entity = entityByPathSelector(state, entityPath);

  if (entityPath === rootEntityPath) return rootEntityPath;
  if (!entity) return rootEntityPath;
  if (!isRecordType(entity.type)) return PathUtils.getParentPath(entityPath);
  return entityPath;
}

export function entityBytesSizeByPathSelector(state, path) {
  const entity = entityByPathSelector(state, path);

  if (!entity) return null;
  if (!hasBlobIdInTerm(entity.term)) return null;

  const blobId = blobIdOfTerm(entity.term);
  return blobTotalBytesSelector(state, blobId);
}

// REDUCERS

// See src/features/entities/reducer.js
