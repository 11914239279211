

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as React from "react";
import * as TableCss from "./Table.css";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as ReactExtras from "../lib/solvuu/ReactExtras.bs.js";

var styles = TableCss.default;

function TableElements$EmptyElement(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: styles.empty
            }, children);
}

var EmptyElement = {
  make: TableElements$EmptyElement
};

function TableElements$TableElement(Props) {
  var testId = Props.testId;
  var children = Props.children;
  return React.createElement("div", {
              className: styles.fullWidthTableWrapper
            }, React.createElement("div", {
                  className: styles.responsiveTableWrapper
                }, ReactExtras.withOptionalTestId(testId, React.createElement("table", {
                          className: styles.table
                        }, children))));
}

var TableElement = {
  make: TableElements$TableElement
};

function TableElements$RowElement(Props) {
  var testId = Props.testId;
  var children = Props.children;
  return ReactExtras.withOptionalTestId(testId, React.createElement("tr", {
                  className: styles.tableRow
                }, children));
}

var RowElement = {
  make: TableElements$RowElement
};

function TableElements$HeaderElement(Props) {
  var colSpan = Props.colSpan;
  var match = Props.variant;
  var variant = match !== undefined ? match : /* Wide */1;
  var testId = Props.testId;
  var children = Props.children;
  var modifierClassName = variant ? styles.tableColumnWide : styles.tableColumnNarrow;
  var className = Cn.make(/* :: */[
        styles.tableColumn,
        /* :: */[
          modifierClassName,
          /* [] */0
        ]
      ]);
  var tmp = {
    className: className
  };
  if (colSpan !== undefined) {
    tmp.colSpan = Caml_option.valFromOption(colSpan);
  }
  return ReactExtras.withOptionalTestId(testId, React.createElement("th", tmp, children));
}

var HeaderElement = {
  make: TableElements$HeaderElement
};

function TableElements$CellElement(Props) {
  var testId = Props.testId;
  var match = Props.variant;
  var variant = match !== undefined ? match : /* Regular */0;
  var title = Props.title;
  var colSpan = Props.colSpan;
  var children = Props.children;
  var modifierClassName;
  switch (variant) {
    case /* Regular */0 :
        modifierClassName = undefined;
        break;
    case /* Unknown */1 :
        modifierClassName = styles.tableCellUnknown;
        break;
    case /* Missing */2 :
        modifierClassName = styles.tableCellMissing;
        break;
    case /* Error */3 :
        modifierClassName = styles.tableCellError;
        break;
    
  }
  var className = Cn.make(/* :: */[
        styles.tableCell,
        /* :: */[
          Cn.unpack(modifierClassName),
          /* [] */0
        ]
      ]);
  var tmp = {
    className: className
  };
  if (title !== undefined) {
    tmp.title = Caml_option.valFromOption(title);
  }
  if (colSpan !== undefined) {
    tmp.colSpan = Caml_option.valFromOption(colSpan);
  }
  return ReactExtras.withDataAttribute("data-columnid", testId, React.createElement("td", tmp, children));
}

var CellElement = {
  make: TableElements$CellElement
};

export {
  HeaderElement ,
  RowElement ,
  CellElement ,
  TableElement ,
  EmptyElement ,
  
}
/* styles Not a pure module */
