

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Import from "../../../../../../features/import.bs.js";
import * as StyleCss from "./style.css";
import * as SortingControlsJs from "./SortingControls.js";

var sort_ascending = "asc";

var styles = StyleCss.default;

var make = SortingControlsJs.default;

var Internal = {
  make: make
};

function SortingControls(Props) {
  var attributes = Props.attributes;
  var match = React.useState((function () {
          return /* array */[];
        }));
  var setState = match[1];
  var change = function (idx, data) {
    return Curry._1(setState, (function (criteria) {
                  return Curry._1(Import.List.toArray, Curry._2(Import.List.mapWithIndex, Curry._1(Import.List.fromArray, criteria), (function (i, x) {
                                    var match = idx === i;
                                    if (match) {
                                      var x$1 = x;
                                      var a = data;
                                      var match$1 = a.attribute;
                                      var match$2 = a.direction;
                                      return {
                                              attribute: match$1 !== undefined ? match$1 : x$1.attribute,
                                              direction: match$2 !== undefined ? match$2 : x$1.direction
                                            };
                                    } else {
                                      return x;
                                    }
                                  })));
                }));
  };
  var remove = function (idx) {
    return Curry._1(setState, (function (criteria) {
                  return Curry._1(Import.List.toArray, Curry._2(Import.List.keepWithIndex, Curry._1(Import.List.fromArray, criteria), (function (param, i) {
                                    return idx !== i;
                                  })));
                }));
  };
  var add = function (data) {
    return Curry._1(setState, (function (criteria) {
                  return Curry._1(Import.List.toArray, Curry._2(Import.List.concat, Curry._1(Import.List.fromArray, criteria), /* :: */[
                                  {
                                    attribute: data,
                                    direction: sort_ascending
                                  },
                                  /* [] */0
                                ]));
                }));
  };
  return React.createElement(make, {
              criteria: match[0],
              attributes: attributes,
              changeSortingCriterion: change,
              removeSortingCriterion: remove,
              addSortingCriterion: add
            });
}

var sort_descending = "desc";

var sample_metadata_attribute = "metadata";

var taxonomic_abundance_attribute = "taxonomy";

var make$1 = SortingControls;

export {
  sort_descending ,
  sort_ascending ,
  sample_metadata_attribute ,
  taxonomic_abundance_attribute ,
  styles ,
  Internal ,
  make$1 as make,
  
}
/* styles Not a pure module */
