

import * as StyleCss from "./style.css";

var styles = StyleCss.default;

export {
  styles ,
  
}
/* styles Not a pure module */
