import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  ScatterChart as RechartsScatterChart,
  Scatter as RechartsScatter,
  XAxis,
  YAxis,
  ReferenceArea
} from "recharts";
import sizeMe from "react-sizeme";
import { extent } from "d3-array";
import { zip } from "ramda";

import { primary, secondary } from "lib/colors";
import measureTextWidth from "lib/measureTextWidth";
import { getNiceTicks, getNiceDomain } from "components/Brushing";

import styles from "table/TableQuantileQuantilePlot.css";

class QuantileQuantilePlot extends Component {
  static propTypes = {
    dataQuantiles: PropTypes.arrayOf(PropTypes.number),
    theoreticalQuantiles: PropTypes.arrayOf(PropTypes.number),
    sampleSize: PropTypes.number,
    dataAxisLabel: PropTypes.string
  };

  renderReferenceLine = points => {
    // Bail if we're not dealing with percentiles
    if (points.length !== 99) return null;
    // Draw the reference line through 1st and 3rd quartiles
    // Equivalent of 25th and 75th percentiles
    // points[0] === 1st percentile
    const firstQuartile = points[24];
    const thirdQuartile = points[74];
    return (
      <ReferenceArea
        x1={firstQuartile.x}
        y1={firstQuartile.y}
        x2={thirdQuartile.x}
        y2={thirdQuartile.y}
        shape={props => {
          const x1 = props.x;
          const y1 = props.y + props.height;
          const x2 = props.x + props.width;
          const y2 = props.y;

          // Extend the reference line from the reference points
          const extendX = (x, amount) => x + amount;
          const extendY = (y, amount) =>
            y - (props.height / props.width) * amount;

          const stroke = secondary;
          const strokeWidth = 1;
          const clipPath = `url(#${props.clipPathId})`;

          return (
            <line
              x1={extendX(x1, -1000)}
              y1={extendY(y1, -1000)}
              x2={extendX(x2, 1000)}
              y2={extendY(y2, 1000)}
              stroke={stroke}
              strokeWidth={strokeWidth}
              clipPath={clipPath}
            />
          );
        }}
      />
    );
  };

  render() {
    const {
      dataQuantiles,
      theoreticalQuantiles,
      sampleSize,
      size,
      dataAxisLabel = "Data Quantiles",
      theoreticalAxisLabel = "Theoretical Quantiles"
    } = this.props;

    const points = zip(dataQuantiles, theoreticalQuantiles).map(
      ([dataQuantile, theoreticalQuantile]) => ({
        y: dataQuantile,
        x: theoreticalQuantile
      })
    );

    const width = Math.min(size.width, size.height) || 600;
    const height = width;
    const margin = { top: 15, right: 0, bottom: 60, left: 60 };

    const xAxisHeight = 40;
    const yAxisWidth = 60;
    const xAxisLabel = theoreticalAxisLabel;
    const yAxisLabel = dataAxisLabel;
    const yAxisLabelDy = -0.5 * measureTextWidth(yAxisLabel);
    const axisPadding = 10;

    const xDomain = getNiceDomain(extent(points, p => p.x));
    const yDomain = getNiceDomain(extent(points, p => p.y));
    const xTicks = getNiceTicks(xDomain, 11);
    const yTicks = getNiceTicks(yDomain, 11);

    const pointColor = primary;
    const pointRadius = 3;

    return (
      <div className={styles.plotRoot}>
        <div className={styles.plotDetails}>
          <div data-testid="quantile-quantile-plot-sample-size">
            {`n = ${sampleSize}`}
          </div>
        </div>
        <RechartsScatterChart margin={margin} width={width} height={height}>
          <XAxis
            dataKey="x"
            type="number"
            label={{ value: xAxisLabel, position: "bottom" }}
            domain={xDomain}
            ticks={xTicks}
            allowDataOverflow={true}
            height={xAxisHeight}
            padding={{ left: axisPadding, right: axisPadding }}
          />
          <YAxis
            dataKey="y"
            type="number"
            label={{
              value: yAxisLabel,
              angle: -90,
              position: "left",
              dy: yAxisLabelDy
            }}
            domain={yDomain}
            ticks={yTicks}
            allowDataOverflow={true}
            width={yAxisWidth}
            padding={{ top: axisPadding, bottom: axisPadding }}
          />
          <RechartsScatter
            data={points}
            isAnimationActive={false}
            shape={props => {
              return (
                <circle
                  cx={props.cx}
                  cy={props.cy}
                  r={pointRadius}
                  fill={pointColor}
                />
              );
            }}
          />
          {this.renderReferenceLine(points)}
        </RechartsScatterChart>
      </div>
    );
  }
}

const SizedQuantileQuantilePlot = sizeMe({
  refreshRate: 100,
  noPlaceholder: process.env.NODE_ENV === "test",
  monitorWidth: true,
  monitorHeight: true
})(QuantileQuantilePlot);

// Injects the style into sizeMe placeholder, for correct size measurement
export default props => (
  <SizedQuantileQuantilePlot className={styles.plotRoot} {...props} />
);
