import React, { Component } from "react";
import { FormattedMessage } from "react-intl";

import PageHeader from "components/PageHeader";

import tileChipSeq from "./tile-chip-seq.svg";
import tileDNAMethylation from "./tile-dna-methylation.svg";
import tileDeNovoAssembly from "./tile-de-novo-assembly.svg";
import tileMetabolomics from "./tile-metabolomics.svg";
import tileMetagenomics from "./tile-metagenomics.svg";
import tileMetaprofiling from "./tile-metaprofiling.svg";
import tileMetaproteomics from "./tile-metaproteomics.svg";
import tileMetatranscriptomics from "./tile-metatranscriptomics.svg";
import tileVariantCalling from "./tile-variant-calling.svg";
import tileViromics from "./tile-viromics.svg";

import styles from "./style.css";

class Tile extends Component {
  render() {
    const { id, imageSrc } = this.props;

    return (
      <div className={styles.tile} tabIndex="0">
        <img src={imageSrc} alt="" className={styles.tileImage} />
        <strong className={styles.tileTitle}>
          <FormattedMessage id={`analysis.tiles.${id}.title`} />
        </strong>
        <p className={styles.tileContent}>
          <FormattedMessage id={`analysis.tiles.${id}.content`} />
        </p>
      </div>
    );
  }
}

class Analysis extends Component {
  render() {
    return (
      <div className={styles.root}>
        <PageHeader>
          <PageHeader.Name>
            <FormattedMessage id="analysis.header" />
          </PageHeader.Name>
        </PageHeader>
        <div className={styles.tileGroup}>
          <FormattedMessage
            id="analysis.tileGroups.microbialCommunity"
            tagName="h3"
          />
          <div className={styles.tiles}>
            <Tile id="metaprofiling" imageSrc={tileMetaprofiling} />
            <Tile id="metagenomics" imageSrc={tileMetagenomics} />
            <Tile id="metatranscriptomics" imageSrc={tileMetatranscriptomics} />
            <Tile id="metaproteomics" imageSrc={tileMetaproteomics} />
            <Tile id="metabolomics" imageSrc={tileMetabolomics} />
            <Tile id="viromics" imageSrc={tileViromics} />
          </div>
        </div>
        <div className={styles.tileGroup}>
          <FormattedMessage id="analysis.tileGroups.host" tagName="h3" />
          <div className={styles.tiles}>
            <Tile id="ChIPSeq" imageSrc={tileChipSeq} />
            <Tile id="DNAMethylation" imageSrc={tileDNAMethylation} />
            <Tile id="deNovoAssembly" imageSrc={tileDeNovoAssembly} />
            <Tile id="transcriptomics" imageSrc={tileMetatranscriptomics} />
            <Tile id="proteomics" imageSrc={tileMetaproteomics} />
            <Tile id="variantCalling" imageSrc={tileVariantCalling} />
          </div>
        </div>
      </div>
    );
  }
}

export default Analysis;
