

import * as List from "bs-platform/lib/es6/list.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Import from "../../features/import.bs.js";
import * as RunPng from "../../run/RunPng.bs.js";
import * as TermJs from "./termJs.bs.js";
import * as Js_dict from "bs-platform/lib/es6/js_dict.js";
import * as RunHtml from "../../run/RunHtml.bs.js";
import * as Caml_obj from "bs-platform/lib/es6/caml_obj.js";
import * as PathUtils from "./pathUtils.bs.js";
import * as Pervasives from "bs-platform/lib/es6/pervasives.js";
import * as RunHeatmap from "../../run/RunHeatmap.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as RunHistogram from "../../run/RunHistogram.bs.js";
import * as RunLineChart from "../../run/RunLineChart.bs.js";
import * as Fmt$SolvuuApi from "solvuu-api/./fmt.bs.js";
import * as RunKronaChart from "../../run/RunKronaChart.bs.js";
import * as RunViolinPlot from "../../run/RunViolinPlot.bs.js";
import * as Lang$SolvuuApi from "solvuu-api/./lang.bs.js";
import * as Root$SolvuuApi from "solvuu-api/./root.bs.js";
import * as RunScatterPlot from "../../run/RunScatterPlot.bs.js";
import * as RunVennDiagram from "../../run/RunVennDiagram.bs.js";
import * as RunVolcanoPlot from "../../run/RunVolcanoPlot.bs.js";
import * as Term$SolvuuApi from "solvuu-api/./term.bs.js";
import * as RunKernelDensity from "../../run/RunKernelDensity.bs.js";
import * as RunScatterMatrix from "../../run/RunScatterMatrix.bs.js";
import * as Util from "aws-sdk/lib/util";
import * as RunSummaryStatistics from "../../run/RunSummaryStatistics.bs.js";
import * as RunParallelCoordinates from "../../run/RunParallelCoordinates.bs.js";
import * as RunPrincipalCoordinates from "../../run/RunPrincipalCoordinates.bs.js";
import * as RunQuantileQuantilePlot from "../../run/RunQuantileQuantilePlot.bs.js";

function pathToProjection(path) {
  return Curry._3(Import.List.reduce, Curry._2(Import.List.mapWithIndex, Curry._2(Import.List.map, Curry._1(Import.List.fromArray, PathUtils.splitPathIntoComponents(path)), (function (component) {
                        return component.name;
                      })), (function (index, name) {
                    if (index !== 0) {
                      return /* `Named */[
                              699531161,
                              name
                            ];
                    } else {
                      return /* `User */[
                              948354667,
                              name
                            ];
                    }
                  })), /* Root */914889282, (function (acc, label) {
                return /* `App */[
                        3257473,
                        /* tuple */[
                          /* `Project */[
                            -1019462983,
                            label
                          ],
                          acc
                        ]
                      ];
              }));
}

var listAnnotation = /* tuple */[
  "eval",
  /* `String */[
    -976970511,
    "list"
  ]
];

var recordAnnotation = /* tuple */[
  "eval",
  /* `String */[
    -976970511,
    "record"
  ]
];

var scalarAnnotation = /* tuple */[
  "eval",
  /* `String */[
    -976970511,
    "scalar"
  ]
];

function attachAnnotation(_annotation, term) {
  if (typeof term === "number" || term[0] !== 3257473) {
    return Pervasives.failwith("Unexpected term: cannot evaluate non-app terms");
  } else {
    var match = term[1];
    return /* `App */[
            3257473,
            /* tuple */[
              match[0],
              match[1]
            ]
          ];
  }
}

function project(contents) {
  return Curry._1(Lang$SolvuuApi.Term.record, Curry._2(Import.List.map, Curry._2(Import.List.map, contents, (function (param) {
                        return /* tuple */[
                                param[0],
                                Belt_Option.getWithDefault(param[1], "")
                              ];
                      })), (function (param) {
                    return /* tuple */[
                            param[0],
                            /* `String */[
                              -976970511,
                              param[1]
                            ]
                          ];
                  })));
}

function field(name, decode, term) {
  if (typeof term === "number") {
    return Pervasives.failwith("Unexpected term: expected a record to extract field " + name);
  } else if (term[0] !== 847309489) {
    return Pervasives.failwith("Unexpected term: expected a record to extract field " + name);
  } else {
    return Curry._1(decode, List.find((function (param) {
                        return Caml_obj.caml_equal(param[0], /* `Named */[
                                    699531161,
                                    name
                                  ]);
                      }), term[1])[1]);
  }
}

function decodeFloat(term) {
  if (typeof term === "number" || term[0] !== 365180284) {
    return Pervasives.failwith("Unexpected term: expected a float");
  } else {
    return term[1];
  }
}

function decodeInt(term) {
  if (typeof term === "number" || term[0] !== 3654863) {
    return Pervasives.failwith("Unexpected term: expected an int");
  } else {
    return term[1];
  }
}

function decodeList(decodeElement, term) {
  if (typeof term === "number" || term[0] !== 848054398) {
    return Pervasives.failwith("Unexpected term: expected a list");
  } else {
    return Curry._2(Import.List.map, term[1], decodeElement);
  }
}

function apply(name, term) {
  return /* `App */[
          3257473,
          /* tuple */[
            /* `Var */[
              4298439,
              name
            ],
            term
          ]
        ];
}

function encodeContent(content) {
  var encoded = Util.base64.encode(content);
  return apply(Root$SolvuuApi.base64_decode, /* `String */[
              -976970511,
              encoded
            ]);
}

function ofTerm(term) {
  var bins = field("bins", (function (param) {
          return decodeList(decodeFloat, param);
        }), term);
  var counts = field("counts", (function (param) {
          return decodeList(decodeInt, param);
        }), term);
  return {
          bins: bins,
          counts: counts
        };
}

function spec(numBins) {
  return Curry._1(Lang$SolvuuApi.Term.record, /* :: */[
              /* tuple */[
                "num_bins",
                /* `Int */[
                  3654863,
                  numBins
                ]
              ],
              /* [] */0
            ]);
}

function $$eval(numBins, input) {
  var argument = spec(numBins);
  var app = apply(Root$SolvuuApi.histogram, Curry._1(Lang$SolvuuApi.Term.tuple, /* :: */[
            argument,
            /* :: */[
              input,
              /* [] */0
            ]
          ]));
  return attachAnnotation(recordAnnotation, app);
}

function ofTerm$1(term) {
  var size = field("size", decodeInt, term);
  var min = field("min", decodeFloat, term);
  var max = field("max", decodeFloat, term);
  var range = field("range", decodeFloat, term);
  var sum = field("sum", decodeFloat, term);
  var mean = field("mean", decodeFloat, term);
  var median = field("median", decodeFloat, term);
  var variance = field("var", decodeFloat, term);
  var standardDeviation = field("std", decodeFloat, term);
  var firstQuartile = field("first_quartile", decodeFloat, term);
  var thirdQuartile = field("third_quartile", decodeFloat, term);
  var trimmedMin = field("trimmed_min", decodeFloat, term);
  var trimmedMax = field("trimmed_max", decodeFloat, term);
  var outliers = field("outliers", (function (param) {
          return decodeList(decodeFloat, param);
        }), term);
  return {
          size: size,
          min: min,
          max: max,
          range: range,
          sum: sum,
          mean: mean,
          median: median,
          variance: variance,
          standardDeviation: standardDeviation,
          firstQuartile: firstQuartile,
          thirdQuartile: thirdQuartile,
          trimmedMin: trimmedMin,
          trimmedMax: trimmedMax,
          outliers: outliers
        };
}

function $$eval$1(input) {
  var app = apply(Root$SolvuuApi.stats, input);
  return attachAnnotation(recordAnnotation, app);
}

var Stats = {
  ofTerm: ofTerm$1,
  $$eval: $$eval$1
};

function $$eval$2(input) {
  var term = apply(Root$SolvuuApi.indexed_dataframe, input);
  return attachAnnotation(listAnnotation, term);
}

function evalRaw(content) {
  var contentTerm = encodeContent(content);
  return $$eval$2(apply(Root$SolvuuApi.biom, contentTerm));
}

function ofTerm$2(term) {
  if (typeof term !== "number" && term[0] === -976970511) {
    return JSON.parse(term[1]);
  }
  var stringifiedTerm = TermJs.stringify(TermJs.convertToJs(term));
  return Pervasives.failwith("Cannot decode the biom dataframe, expected a string, got " + (String(stringifiedTerm) + ""));
}

function toTerm(t) {
  return /* `String */[
          -976970511,
          JSON.stringify(t)
        ];
}

var BiomDataframe = {
  $$eval: $$eval$2,
  evalRaw: evalRaw,
  ofTerm: ofTerm$2,
  toTerm: toTerm
};

function $$eval$3(input) {
  return attachAnnotation(listAnnotation, input);
}

function evalRaw$1(csv, content) {
  var contentTerm = encodeContent(content);
  return attachAnnotation(listAnnotation, Curry._2(Fmt$SolvuuApi.Parse.to_term, /* `Csv */[
                  3357606,
                  csv
                ], contentTerm));
}

var Csv = {
  $$eval: $$eval$3,
  evalRaw: evalRaw$1
};

function $$eval$4(blobId) {
  return apply(Root$SolvuuApi.string, /* `Blob_id */[
              295523485,
              blobId
            ]);
}

function ofTerm$3(term) {
  if (typeof term === "number" || term[0] !== -976970511) {
    return Pervasives.failwith("Cannot decode job log");
  } else {
    return term[1];
  }
}

var JobLog = {
  $$eval: $$eval$4,
  ofTerm: ofTerm$3
};

function $$eval$5(input) {
  return attachAnnotation(scalarAnnotation, input);
}

function evalRaw$2(content) {
  var contentTerm = encodeContent(content);
  return attachAnnotation(scalarAnnotation, apply(Root$SolvuuApi.newick, contentTerm));
}

function ofTerm$4(term) {
  if (typeof term === "number" || term[0] !== -976970511) {
    return Pervasives.failwith("Cannot decode newick data, expected a string");
  } else {
    return term[1];
  }
}

function toTerm$1(t) {
  return /* `String */[
          -976970511,
          t
        ];
}

var Newick = {
  $$eval: $$eval$5,
  evalRaw: evalRaw$2,
  ofTerm: ofTerm$4,
  toTerm: toTerm$1
};

var terms = {
  solvuu: (function (formula) {
      return TermJs.convertToJs(/* `Solvuu */[
                  -522825178,
                  formula
                ]);
    }),
  blobId: (function (id) {
      return TermJs.convertToJs(/* `Blob_id */[
                  295523485,
                  id
                ]);
    }),
  path: (function (path) {
      return TermJs.convertToJs(attachAnnotation(/* tuple */[
                      "eval",
                      /* `String */[
                        -976970511,
                        "app"
                      ]
                    ], pathToProjection(path)));
    }),
  project: (function (contents) {
      return TermJs.convertToJs(project(Curry._2(Import.List.map, Curry._1(Import.List.fromArray, Js_dict.entries(contents)), (function (param) {
                            return /* tuple */[
                                    param[0],
                                    Caml_option.nullable_to_opt(param[1])
                                  ];
                          }))));
    })
};

function $$eval$6(apiClient) {
  var rawServerSideEval = function (term, f) {
    return Import.$great$great$eq(Curry._3(apiClient.f, /* POST */891112544, Term$SolvuuApi.Get, {
                    term: term,
                    response_term_fmt: /* Json */826371656
                  }), (function (response) {
                  if (typeof response === "number") {
                    return Import.$$return(Curry._2(Lang$SolvuuApi.Term.eval_error, "Unauthorized", term));
                  } else {
                    var info = response[1].info;
                    if (info[0] >= 17692) {
                      return Curry._1(f, info[1]);
                    } else {
                      return Import.$$return(Curry._2(Lang$SolvuuApi.Term.eval_error, info[1].msg, term));
                    }
                  }
                }));
  };
  var simpleServerSideEval = function (term) {
    return rawServerSideEval(term, (function (param) {
                  return Import.$$return(param.term);
                }));
  };
  var loop = function (t) {
    if (typeof t === "number" || t[0] !== 3257473) {
      return simpleServerSideEval(t);
    } else {
      var match = t[1];
      var match$1 = match[0];
      if (typeof match$1 === "number" || match$1[0] !== 4298439) {
        return simpleServerSideEval(t);
      } else {
        var arg = match[1];
        var f = match$1[1];
        if (Curry._2(Lang$SolvuuApi.Var.equal, f, Root$SolvuuApi.principal_coordinates)) {
          return Import.$$return(RunPrincipalCoordinates.$$eval(f, arg));
        } else if (Curry._2(Lang$SolvuuApi.Var.equal, f, Root$SolvuuApi.parallel_coordinates)) {
          return Import.$$return(RunParallelCoordinates.$$eval(f, arg));
        } else if (Curry._2(Lang$SolvuuApi.Var.equal, f, Root$SolvuuApi.heat_map)) {
          return Import.$$return(RunHeatmap.$$eval(f, arg));
        } else if (Curry._2(Lang$SolvuuApi.Var.equal, f, Root$SolvuuApi.histogram)) {
          return Import.$great$great$eq(simpleServerSideEval(arg), (function (__x) {
                        return RunHistogram.$$eval(f, __x, rawServerSideEval, simpleServerSideEval);
                      }));
        } else if (Curry._2(Lang$SolvuuApi.Var.equal, f, Root$SolvuuApi.kernel_density)) {
          return Import.$great$great$eq(simpleServerSideEval(arg), (function (__x) {
                        return RunKernelDensity.$$eval(f, __x, simpleServerSideEval);
                      }));
        } else if (Curry._2(Lang$SolvuuApi.Var.equal, f, Root$SolvuuApi.krona_chart)) {
          return Import.$great$great$eq(simpleServerSideEval(arg), (function (param) {
                        return RunKronaChart.$$eval(f, param);
                      }));
        } else if (Curry._2(Lang$SolvuuApi.Var.equal, f, Root$SolvuuApi.line_chart)) {
          return Import.$great$great$eq(simpleServerSideEval(arg), (function (param) {
                        return RunLineChart.$$eval(f, param);
                      }));
        } else if (Curry._2(Lang$SolvuuApi.Var.equal, f, Root$SolvuuApi.quantile_quantile_plot)) {
          return Import.$great$great$eq(simpleServerSideEval(arg), (function (param) {
                        return RunQuantileQuantilePlot.$$eval(f, param);
                      }));
        } else if (Curry._2(Lang$SolvuuApi.Var.equal, f, Root$SolvuuApi.scatter_plot)) {
          return Import.$great$great$eq(simpleServerSideEval(arg), (function (param) {
                        return RunScatterPlot.$$eval(f, param);
                      }));
        } else if (Curry._2(Lang$SolvuuApi.Var.equal, f, Root$SolvuuApi.scatter_matrix)) {
          return Import.$great$great$eq(simpleServerSideEval(arg), (function (__x) {
                        return RunScatterMatrix.$$eval(f, __x, loop);
                      }));
        } else if (Curry._2(Lang$SolvuuApi.Var.equal, f, Root$SolvuuApi.volcano_plot)) {
          return Import.$great$great$eq(simpleServerSideEval(arg), (function (__x) {
                        return RunVolcanoPlot.$$eval(f, __x, loop);
                      }));
        } else if (Curry._2(Lang$SolvuuApi.Var.equal, f, Root$SolvuuApi.summary_statistics)) {
          return Import.$great$great$eq(simpleServerSideEval(arg), (function (__x) {
                        return RunSummaryStatistics.$$eval(f, __x, simpleServerSideEval);
                      }));
        } else if (Curry._2(Lang$SolvuuApi.Var.equal, f, Root$SolvuuApi.violin_plot)) {
          return Import.$great$great$eq(simpleServerSideEval(arg), (function (param) {
                        return RunViolinPlot.$$eval(f, param);
                      }));
        } else if (Curry._2(Lang$SolvuuApi.Var.equal, f, Root$SolvuuApi.venn_diagram)) {
          return Import.$great$great$eq(simpleServerSideEval(arg), (function (param) {
                        return RunVennDiagram.$$eval(f, param);
                      }));
        } else if (Curry._2(Lang$SolvuuApi.Var.equal, f, Root$SolvuuApi.png)) {
          return RunPng.$$eval(f, t, simpleServerSideEval);
        } else if (Curry._2(Lang$SolvuuApi.Var.equal, f, Root$SolvuuApi.html)) {
          return RunHtml.$$eval(f, t, simpleServerSideEval);
        } else {
          return simpleServerSideEval(t);
        }
      }
    }
  };
  return loop;
}

var Evaluations_Histogram = {
  ofTerm: ofTerm,
  $$eval: $$eval
};

var Evaluations = {
  Histogram: Evaluations_Histogram,
  Stats: Stats,
  Csv: Csv,
  BiomDataframe: BiomDataframe,
  JobLog: JobLog,
  Newick: Newick
};

export {
  Evaluations ,
  terms ,
  $$eval$6 as $$eval,
  
}
/* Import Not a pure module */
