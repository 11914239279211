

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Import from "../features/import.bs.js";
import * as Std$Bolt from "bolt/./std.bs.js";
import * as Root$SolvuuApi from "solvuu-api/./root.bs.js";
import * as HistogramBarChartJs from "./HistogramBarChart.js";
import * as HistogramHistogramJs from "./HistogramHistogram.js";

function ofRecord(record) {
  return Curry._2(Root$SolvuuApi.Histogram.T.Numerical.of_record, record, (function (bins, counts, param, param$1, param$2, xAxisLabel) {
                return {
                        bins: bins,
                        counts: counts,
                        xAxisLabel: xAxisLabel
                      };
              }));
}

function ofTerm(term) {
  if (typeof term === "number" || term[0] !== 847309489) {
    return /* `Error */[
            106380200,
            /* Expected_record */-756637544
          ];
  } else {
    var res = ofRecord(term[1]);
    return Curry._2(Std$Bolt.Res.map_error, res, (function (e) {
                  return /* `Record */[
                          847309489,
                          e
                        ];
                }));
  }
}

var Props = {
  ofRecord: ofRecord,
  ofTerm: ofTerm
};

var make = HistogramHistogramJs.default;

var Histogram = {
  Props: Props,
  make: make
};

function ofRecord$1(record) {
  return Curry._1(Std$Bolt.Res.join, Curry._2(Root$SolvuuApi.Histogram.T.Categorical.of_record, record, (function (bins, xAxisLabel) {
                    var res = Curry._1(Std$Bolt.Res.all, Curry._2(Import.List.map, bins, (function (record) {
                                return Curry._2(Root$SolvuuApi.Histogram.T.Categorical.Bin.of_record, record, (function (name, count) {
                                              return {
                                                      name: name,
                                                      count: count
                                                    };
                                            }));
                              })));
                    if (res[0] >= 106380200) {
                      return res;
                    } else {
                      var bins$1 = Curry._1(Import.List.toArray, res[1]);
                      return /* `Ok */[
                              17724,
                              {
                                bins: bins$1,
                                xAxisLabel: xAxisLabel
                              }
                            ];
                    }
                  })));
}

function ofTerm$1(term) {
  if (typeof term === "number" || term[0] !== 847309489) {
    return /* `Error */[
            106380200,
            /* Expected_record */-756637544
          ];
  } else {
    var res = ofRecord$1(term[1]);
    return Curry._2(Std$Bolt.Res.map_error, res, (function (e) {
                  return /* `Record */[
                          847309489,
                          e
                        ];
                }));
  }
}

var Props$1 = {
  ofRecord: ofRecord$1,
  ofTerm: ofTerm$1
};

var make$1 = HistogramBarChartJs.default;

var BarChart = {
  Props: Props$1,
  make: make$1
};

export {
  Histogram ,
  BarChart ,
  
}
/* make Not a pure module */
