import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  ScatterChart as RechartsScatterChart,
  Scatter as RechartsScatter,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import sizeMe from "react-sizeme";
import { extent, max } from "d3-array";
import { scaleLinear } from "d3-scale";
import { interpolateRdBu } from "d3-scale-chromatic";

import {
  DualBrushing,
  VerticalBrush,
  HorizontalBrush,
  getNiceTicks,
  getNiceDomain
} from "components/Brushing";

import styles from "table/TableVolcanoPlot.css";

class VolcanoPlotTooltip extends Component {
  render() {
    const { payload: payloads } = this.props;
    if (payloads.length === 0) return null;

    const payload = payloads[0].payload;
    const { logFC, pValue, label } = payload;

    return (
      <div className={styles.tooltip}>
        {label && (
          <p>
            <strong>{label}</strong>
          </p>
        )}
        <p className={styles.tooltipValue}>log FC: {logFC}</p>
        <p className={styles.tooltipValue}>p-value: {pValue}</p>
      </div>
    );
  }
}

class VolcanoPlot extends Component {
  static propTypes = {
    size: PropTypes.shape({
      width: PropTypes.number
    }).isRequired,
    points: PropTypes.arrayOf(
      PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
        logFC: PropTypes.number.isRequired,
        pValue: PropTypes.number.isRequired,
        label: PropTypes.string
      })
    ).isRequired,
    xAxisThreshold: PropTypes.number.isRequired,
    yAxisThreshold: PropTypes.number.isRequired
  };

  render() {
    const { points, xAxisThreshold, yAxisThreshold, size } = this.props;

    const margin = { top: 0, right: 20, bottom: 60, left: 60 };
    const width = size.width || 600;
    const height = width / 2;

    const xAxisHeight = 40;
    const yAxisWidth = 60;

    const yBrushHeight = height - xAxisHeight - margin.top - margin.bottom;
    const yBrushWidth = VerticalBrush.defaultWidth;
    const xBrushHeight = HorizontalBrush.defaultHeight;
    const xBrushWidth = width - yAxisWidth - margin.left - margin.right - 7;
    const xBrushX = margin.left + yAxisWidth;
    const xBrushY = height - xBrushHeight - 7;
    const yBrushX = 0;
    const yBrushY = margin.top;

    const xAxisExtent = max(points, point => Math.abs(point.x));
    const xAxisDomain = getNiceDomain([-xAxisExtent, xAxisExtent]);
    const xAxisScale = scaleLinear().domain(xAxisDomain);
    const yAxisExtent = extent(points, point => point.y);
    const yAxisDomain = getNiceDomain(yAxisExtent);
    const yAxisScale = scaleLinear().domain(yAxisDomain);

    const overexpressedColor = interpolateRdBu(0.1);
    const underexpressedColor = interpolateRdBu(0.9);

    const pointRadius = 3;
    const axisPadding = pointRadius; // Add padding to avoid clipping points

    return (
      <div className={styles.plotRoot}>
        <DualBrushing xDomain={xAxisDomain} yDomain={yAxisDomain}>
          {({ xBrushing, yBrushing }) => {
            const xTicks = getNiceTicks(xBrushing.selectedDomain, 10);
            const yTicks = getNiceTicks(yBrushing.selectedDomain, 10);

            return (
              <RechartsScatterChart
                margin={margin}
                width={width}
                height={height}
              >
                <XAxis
                  dataKey="x"
                  type="number"
                  ticks={xTicks}
                  height={xAxisHeight}
                  allowDataOverflow={true}
                  domain={xBrushing.selectedDomain}
                  label={{ value: "log FC", position: "bottom" }}
                  padding={{ left: axisPadding, right: axisPadding }}
                />
                <YAxis
                  dataKey="y"
                  type="number"
                  ticks={yTicks}
                  width={yAxisWidth}
                  allowDataOverflow={true}
                  domain={yBrushing.selectedDomain}
                  label={{
                    value: "-log10(pValue)",
                    angle: -90,
                    position: "left",
                    dy: -40
                  }}
                  padding={{ top: axisPadding }}
                />
                <ReferenceLine y={yAxisThreshold} strokeDasharray="3 3" />
                <ReferenceLine x={xAxisThreshold} strokeDasharray="3 3" />
                <ReferenceLine x={-xAxisThreshold} strokeDasharray="3 3" />
                <Tooltip content={<VolcanoPlotTooltip />} />
                <RechartsScatter
                  data={points}
                  isAnimationActive={false}
                  shape={props => {
                    const isSignificant =
                      props.payload.y >= yAxisThreshold &&
                      Math.abs(props.payload.x) >= xAxisThreshold;
                    const opacity = isSignificant ? 1.0 : 0.5;
                    const fill = isSignificant
                      ? props.payload.x > 0
                        ? overexpressedColor
                        : underexpressedColor
                      : "#333";
                    return (
                      <circle
                        cx={props.cx}
                        cy={props.cy}
                        r={pointRadius}
                        fill={fill}
                        opacity={opacity}
                      />
                    );
                  }}
                />
                <g>
                  <VerticalBrush
                    x={yBrushX}
                    y={yBrushY}
                    width={yBrushWidth}
                    height={yBrushHeight}
                    onChange={yBrushing.onChange}
                    scale={yAxisScale}
                    throttleMs={100}
                    key={yBrushing.brushKey + width}
                  />
                </g>
                <g>
                  <HorizontalBrush
                    x={xBrushX}
                    y={xBrushY}
                    width={xBrushWidth}
                    height={xBrushHeight}
                    onChange={xBrushing.onChange}
                    scale={xAxisScale}
                    throttleMs={100}
                    key={xBrushing.brushKey + height}
                  />
                </g>
              </RechartsScatterChart>
            );
          }}
        </DualBrushing>
      </div>
    );
  }
}

const noPlaceholder = process.env.NODE_ENV === "test";
const refreshRate = 100;
const SizedVolcanoPlot = sizeMe({ refreshRate, noPlaceholder })(VolcanoPlot);

// Injects the style into sizeMe placeholder, for correct size measurement
export default props => (
  <SizedVolcanoPlot className={styles.plotRoot} {...props} />
);
