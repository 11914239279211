import { createContext } from "react";

const defaultValue = {
  form: {},
  errors: [],
  onFieldChange: (field, value) => null
};

/* Use this as Form.Provider and Form.Consumer */
export default createContext(defaultValue);
