

import * as React from "react";
import * as PublicLayout2 from "./containers/Layout/PublicLayout2/PublicLayout2.bs.js";
import * as PublicActionLayout from "./containers/Layout/PublicActionLayout/PublicActionLayout.bs.js";

function Layout$Public(Props) {
  var profile = Props.profile;
  var loginState = Props.loginState;
  var logout = Props.logout;
  var children = Props.children;
  return React.createElement(PublicLayout2.make, {
              profile: profile,
              loginState: loginState,
              logout: logout,
              children: children
            });
}

var Public = {
  make: Layout$Public
};

function Layout$PublicAction(Props) {
  var children = Props.children;
  return React.createElement(PublicActionLayout.make, {
              children: children
            });
}

var PublicAction = {
  make: Layout$PublicAction
};

var Authenticated = /* alias */0;

export {
  Authenticated ,
  Public ,
  PublicAction ,
  
}
/* react Not a pure module */
