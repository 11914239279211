

import * as OnClickOutsideJs from "./OnClickOutside.js";

var make = OnClickOutsideJs.default;

export {
  make ,
  
}
/* make Not a pure module */
