

import * as BlobsFormReduxJs from "./BlobsFormRedux.js";

var make = BlobsFormReduxJs.default;

export {
  make ,
  
}
/* make Not a pure module */
