

import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as PathUtilsJs from "./pathUtils.js";

function splitPathIntoComponents(prim) {
  return PathUtilsJs.splitPathIntoComponents(prim);
}

function lastEntityName(prim) {
  return PathUtilsJs.lastEntityName(prim);
}

function getParentPath(prim) {
  return PathUtilsJs.getParentPath(prim);
}

function createEvaluationPath(prim) {
  return PathUtilsJs.createEvaluationPath(prim !== undefined ? Caml_option.valFromOption(prim) : undefined);
}

var createEvaluationPathJs = PathUtilsJs.createEvaluationPath;

function isEvaluationPath(prim) {
  return PathUtilsJs.isEvaluationPath(prim);
}

function isJobOutputPath(prim) {
  return PathUtilsJs.isJobOutputPath(prim);
}

function deconstructJobOutputPath(prim) {
  return PathUtilsJs.deconstructJobOutputPath(prim);
}

function createJobOutputPath(prim, prim$1) {
  return PathUtilsJs.createJobOutputPath(prim, prim$1);
}

var createJobOutputPathJs = PathUtilsJs.createJobOutputPath;

function pathToSolvuu(prim) {
  return PathUtilsJs.pathToSolvuu(prim);
}

export {
  splitPathIntoComponents ,
  lastEntityName ,
  getParentPath ,
  createEvaluationPath ,
  createEvaluationPathJs ,
  isEvaluationPath ,
  isJobOutputPath ,
  deconstructJobOutputPath ,
  createJobOutputPath ,
  createJobOutputPathJs ,
  pathToSolvuu ,
  
}
/* createEvaluationPathJs Not a pure module */
