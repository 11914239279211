

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Import from "../../features/import.bs.js";
import * as $$String from "bs-platform/lib/es6/string.js";
import * as StyleCss from "./style.css";

var styles = StyleCss.default;

function Card(Props) {
  var header = Props.header;
  var fill = Props.fill;
  var children = Props.children;
  var className = $$String.concat(" ", Curry._2(Import.List.keepMap, /* :: */[
            styles.content,
            /* :: */[
              fill ? styles.contentFilled : undefined,
              /* [] */0
            ]
          ], (function (x) {
              return x;
            })));
  return React.createElement("div", {
              className: styles.root
            }, header, React.createElement("div", {
                  className: className
                }, children));
}

function Card$Header(Props) {
  var children = Props.children;
  var className = styles.header;
  return React.createElement("div", {
              className: className
            }, children);
}

var Header = {
  make: Card$Header
};

var make = Card;

export {
  styles ,
  make ,
  Header ,
  
}
/* styles Not a pure module */
