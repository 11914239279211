import { createReducer, resettableReducer } from "reduxsauce";
import { groupBy, map, prop, head, compose } from "ramda";

export function createOperation(operationName) {
  const START = `operations/${operationName}/START`;
  const SUCCESS = `operations/${operationName}/SUCCESS`;
  const FAILURE = `operations/${operationName}/FAILURE`;

  const initialState = {
    success: false,
    pending: false,
    failure: false,
    errors: null
  };

  function start(payload) {
    return {
      type: START,
      payload
    };
  }

  function success(payload) {
    return {
      type: SUCCESS,
      payload
    };
  }

  function failure(payload) {
    return {
      type: FAILURE,
      payload
    };
  }

  const actionCreators = { start, success, failure };
  const actionTypes = { START, SUCCESS, FAILURE };

  function reducer(state = initialState, action) {
    switch (action.type) {
      case START:
        return {
          ...state,
          success: false,
          pending: true,
          failure: false,
          errors: null
        };
      case SUCCESS:
        return {
          ...state,
          success: true,
          pending: false,
          failure: false,
          errors: null
        };
      case FAILURE:
        return {
          ...state,
          success: false,
          pending: false,
          failure: true,
          errors: action.payload
        };
      default:
        return state;
    }
  }

  return { reducer, actionCreators, actionTypes, initialState };
}

export { createReducer, resettableReducer };

export function fallbackReducer(reducer, handlers) {
  const initialState = reducer(undefined, {});

  return function(state = initialState, action) {
    if (handlers[action.type]) {
      return handlers[action.type](state, action);
    }

    return reducer(state, action);
  };
}

export function cognitoToPromise(fn, ...args) {
  return new Promise((resolve, reject) => {
    fn(...args, (err, response) => {
      if (err) {
        reject(err);
        return;
      }

      resolve(response);
    });
  });
}

export const getId = prop("id");
export const groupById = compose(
  map(head),
  groupBy(getId)
);
