import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { FormattedMessage } from "react-intl";
import { identity } from "ramda";

import {
  SORT_DESCENDING,
  SORT_ASCENDING,
  SAMPLE_METADATA_ATTRIBUTE,
  TAXONOMIC_ABUNDANCE_ATTRIBUTE
} from "features/biom";

import OptimizedSelect from "../../OptimizedSelect";
import styles from "./style.css";

const sampleMetadataLabel = (
  <FormattedMessage id="biom.taxonomicAnalysis.barChart.sortingControls.sampleMetadataAttributes" />
);
const taxonomicAbundanceLabel = (
  <FormattedMessage id="biom.taxonomicAnalysis.barChart.sortingControls.taxonomicAbundanceAttributes" />
);

export default class SortingControls extends Component {
  static propTypes = {
    criteria: PropTypes.arrayOf(
      PropTypes.shape({
        attribute: PropTypes.string.isRequired,
        direction: PropTypes.string.isRequired
      })
    ).isRequired,
    attributes: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        type: PropTypes.oneOf([
          SAMPLE_METADATA_ATTRIBUTE,
          TAXONOMIC_ABUNDANCE_ATTRIBUTE
        ]).isRequired
      })
    ).isRequired,
    changeSortingCriterion: PropTypes.func.isRequired,
    removeSortingCriterion: PropTypes.func.isRequired,
    addSortingCriterion: PropTypes.func.isRequired
  };

  handleAddCriterion = () => {
    const attributeName = this.props.attributes[0].name;
    this.props.addSortingCriterion(attributeName);
  };

  handleRemoveCriterion = idx => () => {
    this.props.removeSortingCriterion(idx);
  };

  handleChangeDirection = idx => event => {
    const direction = event.currentTarget.value;
    this.props.changeSortingCriterion(idx, { direction });
  };

  handleChangeAttribute = idx => option => {
    const attribute = option.value;
    this.props.changeSortingCriterion(idx, { attribute });
  };

  render() {
    const { criteria, attributes } = this.props;

    if (attributes.length === 0) return null;

    function attributeToOption(attribute) {
      return {
        value: attribute.name,
        label: attribute.name
      };
    }

    function attributesToOptions(attributes, label) {
      if (attributes.length === 0) return [];

      const labelOption = { value: null, label, disabled: true };
      const options = attributes.map(attributeToOption);
      return [labelOption, ...options];
    }

    const sampleMetadataAttributes = attributes.filter(
      a => a.type === SAMPLE_METADATA_ATTRIBUTE
    );
    const taxonomicAbundanceAttributes = attributes.filter(
      a => a.type === TAXONOMIC_ABUNDANCE_ATTRIBUTE
    );
    const sampleMetadataOptions = attributesToOptions(
      sampleMetadataAttributes,
      sampleMetadataLabel
    );
    const taxonomicAbundanceOptions = attributesToOptions(
      taxonomicAbundanceAttributes,
      taxonomicAbundanceLabel
    );
    const options = [...sampleMetadataOptions, ...taxonomicAbundanceOptions];

    return (
      <div className={styles.root}>
        <div>
          <button
            type="button"
            onClick={this.handleAddCriterion}
            className={styles.button}
            data-testid="add-criterion-button"
          >
            <FormattedMessage id="biom.taxonomicAnalysis.barChart.sortingControls.addCriterion" />
          </button>
        </div>
        {criteria.map((criterion, idx) => (
          <div
            key={idx}
            className={styles.criterion}
            data-testid={`criterion-${idx}`}
          >
            <button
              type="button"
              onClick={this.handleRemoveCriterion(idx)}
              className={styles.button}
              data-testid="remove-criterion-button"
            >
              &times;
            </button>
            <button
              type="button"
              value={SORT_ASCENDING}
              onClick={this.handleChangeDirection(idx)}
              className={cx(styles.button, {
                [styles.active]: criterion.direction === SORT_ASCENDING
              })}
            >
              <FormattedMessage id="biom.taxonomicAnalysis.barChart.sortingControls.ascending" />
            </button>
            <button
              type="button"
              value={SORT_DESCENDING}
              onClick={this.handleChangeDirection(idx)}
              className={cx(styles.button, {
                [styles.active]: criterion.direction === SORT_DESCENDING
              })}
            >
              <FormattedMessage id="biom.taxonomicAnalysis.barChart.sortingControls.descending" />
            </button>
            <OptimizedSelect
              value={criterion.attribute}
              onChange={this.handleChangeAttribute(idx)}
              values={options}
              valueToOption={identity}
              className={styles.select}
              clearable={false}
            />
          </div>
        ))}
      </div>
    );
  }
}
