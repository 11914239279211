import { push } from "connected-react-router";
import qs from "qs";
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import { call, put, select, takeLatest } from "redux-saga/effects";

import BroadcastChannel from "polyfills/BroadcastChannel";

import * as Paths from "Paths";
import { locationSelector } from "features/routing";
import { fetchCurrentProfile } from "features/profile";
import {
  getUserPool,
  getAuthenticatedUser,
  saveCognitoCredentials,
  clearCognitoCredentials
} from "features/aws.js";

const LOGIN_INITIATED = "authentication/LOGIN_INITIATED";
const LOGIN_REQUEST = "authentication/LOGIN_REQUEST";
const LOGIN_SUCCESS = "authentication/LOGIN_SUCCESS";
const LOGIN_FAILURE = "authentication/LOGIN_FAILURE";
const MFA_REQUIRED = "authentication/MFA_REQUIRED";
const MFA_SUBMIT = "authentication/MFA_SUBMIT";
const PRELOAD_SUCCESS = "authentication/PRELOAD_SUCCESS";
const PRELOAD_FAILURE = "authentication/PRELOAD_FAILURE";
export const LOGOUT = "authentication/LOGOUT";

export function preloadAuthentication() {
  return async function(dispatch) {
    try {
      const cognitoUser = await getAuthenticatedUser();
      const username = cognitoUser.getUsername();
      const session = cognitoUser.getSignInUserSession();
      const token = session.getAccessToken().getJwtToken();
      const tokenExpiration = session.getAccessToken().getExpiration();
      saveCognitoCredentials(token);
      dispatch(preloadSuccess(username, token, tokenExpiration));
    } catch (e) {
      dispatch(preloadFailure());
    }
  };
}

export function preloadSuccess(username, token, tokenExpiration) {
  return {
    type: PRELOAD_SUCCESS,
    payload: { username, token, tokenExpiration }
  };
}

export function preloadFailure() {
  return {
    type: PRELOAD_FAILURE
  };
}

export function initiateLogin(username, password) {
  return {
    type: LOGIN_INITIATED,
    payload: { username, password }
  };
}

export function* loginSaga() {
  function* login({ payload: { username, password } }) {
    const authenticationData = { Username: username, Password: password };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const userPool = getUserPool();
    const userData = { Username: username, Pool: userPool };
    const cognitoUser = new CognitoUser(userData);

    function* onSuccess(session) {
      const token = session.getAccessToken().getJwtToken();
      const tokenExpiration = session.getAccessToken().getExpiration();
      const customRedirectPath = yield select(postLoginRedirectionPathSelector);
      const shouldCustomRedirect = yield select(
        postLoginRedirectionPathDefinedSelector
      );
      const redirectPath = shouldCustomRedirect
        ? customRedirectPath
        : Paths.dashboard(username);
      saveCognitoCredentials(token);
      yield put(loginSuccess(username, token, tokenExpiration));
      yield put(push(redirectPath));
      yield put(fetchCurrentProfile());
    }

    function* onFailure(err) {
      yield put(loginFailure(err.message));
    }

    function* onMFARequired(name, parameters) {
      yield put(mfaRequired(parameters.CODE_DELIVERY_DESTINATION));
      yield takeLatest(MFA_SUBMIT, mfaSaga);
    }

    function* mfaSaga({ payload: { mfaCode } }) {
      const effect = yield new Promise(resolve => {
        cognitoUser.sendMFACode(mfaCode, {
          onSuccess: (...args) => resolve(call(onSuccess, ...args)),
          onFailure: (...args) => resolve(call(onFailure, ...args))
        });
      });
      yield effect;
    }

    yield put(loginRequest());
    const effect = yield new Promise(resolve => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (...args) => resolve(call(onSuccess, ...args)),
        onFailure: (...args) => resolve(call(onFailure, ...args)),
        mfaRequired: (...args) => resolve(call(onMFARequired, ...args))
      });
    });
    yield effect;
  }

  yield takeLatest(LOGIN_INITIATED, login);
}

export function loginRequest() {
  return {
    type: LOGIN_REQUEST
  };
}

export function loginFailure(error) {
  return {
    type: LOGIN_FAILURE,
    payload: { error }
  };
}

export function loginSuccess(username, token, tokenExpiration) {
  return {
    type: LOGIN_SUCCESS,
    payload: { username, token, tokenExpiration }
  };
}

export function mfaRequired(destination) {
  return {
    type: MFA_REQUIRED,
    payload: { destination }
  };
}

export function mfaSubmit(mfaCode) {
  return {
    type: MFA_SUBMIT,
    payload: { mfaCode }
  };
}

const logoutChannel = new BroadcastChannel("solvuu:logout");
logoutChannel.onmessage = event => {
  if (event.data === LOGOUT) window.location = Paths.root;
};

export function logout() {
  return function(dispatch) {
    clearCognitoCredentials();
    dispatch(logoutSuccess());
    dispatch(push(Paths.root));
    logoutChannel.postMessage(LOGOUT);
  };
}

export function logoutSuccess() {
  return {
    type: LOGOUT
  };
}

export const initialState = {
  username: null,
  isSuperuser: true, // TODO: permission system
  token: null,
  tokenExpiration: 0,
  authenticating: false,
  preloading: false,
  error: null,
  mfaDestination: null
};

export function authenticationSelector(state) {
  const { authentication } = state;
  return {
    ...authentication,
    authenticated: !!authentication.token,
    expired: authentication.tokenExpiration < Date.now() / 1000,
    mfaRequired: !!authentication.mfaDestination,
    isSuperuser: !!authentication.isSuperuser
  };
}

const REDIRECT_PATH_PARAM = "redirect_path";
/**
 * Constructs the location of the login page to redirect the user to.
 */
export function loginLocationSelector(targetLocation) {
  const targetPath = targetLocation.pathname + targetLocation.search;
  const pathname = Paths.login;
  const search = "?" + qs.stringify({ [REDIRECT_PATH_PARAM]: targetPath });
  return { pathname, search };
}

export function postLoginRedirectionPathSelector(state) {
  return locationSelector(state).query[REDIRECT_PATH_PARAM];
}

export function postLoginRedirectionPathDefinedSelector(state) {
  return !!postLoginRedirectionPathSelector(state);
}

export default function authentication(state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...initialState,
        authenticating: true
      };
    case LOGIN_SUCCESS:
    case PRELOAD_SUCCESS:
      return {
        ...initialState,
        username: action.payload.username,
        token: action.payload.token,
        tokenExpiration: action.payload.tokenExpiration
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        authenticating: false,
        error: action.payload.error
      };
    case PRELOAD_FAILURE:
      return initialState;
    case MFA_REQUIRED:
      return {
        ...state,
        authenticating: false,
        mfaDestination: action.payload.destination
      };
    case MFA_SUBMIT:
      return {
        ...state,
        authenticating: true
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
