

import * as React from "react";
import * as Js_dict from "bs-platform/lib/es6/js_dict.js";
import * as BlobHooks from "../BlobHooks.bs.js";
import * as ImageJs from "./Image.js";
import * as S3 from "aws-sdk/clients/s3";
import * as Caml_builtin_exceptions from "bs-platform/lib/es6/caml_builtin_exceptions.js";

var make = ImageJs.default;

var Internal = {
  make: make
};

function $$Image(Props) {
  var name = Props.name;
  var blobId = Props.blobId;
  var apiClient = Props.apiClient;
  var blob = BlobHooks.useBlob(blobId, /* Download */-344447064, apiClient);
  var getDownloadURL = typeof blob === "number" ? ({
        pending: true,
        success: false
      }) : (
      blob[0] >= 737307005 ? ({
            pending: false,
            success: true
          }) : ({
            pending: false,
            success: false
          })
    );
  var meta = {
    getDownloadURL: getDownloadURL
  };
  var downloadURL;
  if (typeof blob === "number" || blob[0] < 737307005) {
    downloadURL = undefined;
  } else {
    var match = blob[1].instructions;
    if (match !== undefined) {
      var instructions = match;
      if (instructions[0] >= 549625211) {
        downloadURL = undefined;
      } else {
        var instructions$1 = instructions[1][1];
        if (instructions$1[0] >= -344447064) {
          var instructions$2 = instructions$1[1];
          var match$1 = instructions$2.credentials;
          var credentials = match$1[1];
          var bucket = instructions$2.location.bucket;
          var key = instructions$2.location.key;
          var s3 = new S3.default({
                accessKeyId: credentials.access_key_id,
                secretAccessKey: credentials.secret_access_key,
                sessionToken: credentials.session_token,
                region: credentials.region,
                maxRetries: undefined,
                s3DisableBodySigning: undefined,
                params: undefined
              });
          downloadURL = s3.getSignedUrl("getObject", Js_dict.fromArray(/* array */[
                    /* tuple */[
                      "Key",
                      key
                    ],
                    /* tuple */[
                      "Bucket",
                      bucket
                    ],
                    /* tuple */[
                      "ResponseContentType",
                      "text/html"
                    ],
                    /* tuple */[
                      "ResponseContentDisposition",
                      "inline"
                    ]
                  ]));
        } else {
          throw [
                Caml_builtin_exceptions.assert_failure,
                /* tuple */[
                  "Image.re",
                  51,
                  28
                ]
              ];
        }
      }
    } else {
      downloadURL = undefined;
    }
  }
  return React.createElement(make, {
              name: name,
              blobId: blobId,
              downloadURL: downloadURL,
              meta: meta
            });
}

var make$1 = $$Image;

export {
  Internal ,
  make$1 as make,
  
}
/* make Not a pure module */
