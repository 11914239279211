import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { path } from "ramda";
import { toPath } from "lodash";

import Form from "components/Form";
import { Input, Checkbox } from "components/FormControls/FormControls.js";

import styles from "./style.css";

// Convenience render functions for common components
const noop = () => null;
export function renderInput(props) {
  return (
    <Input
      {...props}
      value={props.value || ""}
      onChange={
        props.onChange ? event => props.onChange(event.target.value) : noop
      }
    />
  );
}

export function renderCheckbox(props) {
  return (
    <Checkbox
      {...props}
      checked={!!props.value}
      onChange={
        props.onChange ? event => props.onChange(event.target.checked) : noop
      }
    />
  );
}

// Elements of the form group

export class FormGroupElement extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired
  };

  render() {
    const { name, children } = this.props;

    return (
      <div className={styles.root} data-testid={`form-group[${name}]`}>
        {children}
      </div>
    );
  }
}

export class FieldInfoElement extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired
  };

  render() {
    const { children } = this.props;

    return (
      <div className={styles.info} data-testid="field-info">
        {children}
      </div>
    );
  }
}

export class FieldErrorsElement extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired
  };

  render() {
    const { children } = this.props;

    return (
      <div className={styles.errors} data-testid="field-errors">
        {children}
      </div>
    );
  }
}

export class ControlledFormGroup extends Component {
  static propTypes = {
    renderControl: PropTypes.func.isRequired,
    value: PropTypes.any,
    onFieldChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    errors: PropTypes.array,
    required: PropTypes.bool,
    info: PropTypes.node
  };

  static defaultProps = {
    renderControl: renderInput,
    validationState: null
  };

  renderErrors = errors => {
    const errorElements = errors.map((error, idx) =>
      error.message ? (
        <span key={idx}>{error.message}</span>
      ) : (
        <FormattedMessage
          key={idx}
          tagName="span"
          id={error.id}
          values={error.values}
        />
      )
    );
    return <FieldErrorsElement>{errorElements}</FieldErrorsElement>;
  };

  renderFieldInfo = (info, showRequired) => {
    return (
      <FieldInfoElement>
        {showRequired && (
          <>
            {"*"}
            <FormattedMessage id="form.requiredField" />
          </>
        )}
        {showRequired && info && ". "}
        {info}
      </FieldInfoElement>
    );
  };

  render() {
    const {
      value,
      errors = [],
      name,
      type,
      required,
      label,
      info,
      disabled,
      renderControl,
      validationState: propsValidationState,
      onFieldChange,
      inputRef
    } = this.props;

    const showErrors = errors.length > 0;
    const showRequired = required && !showErrors;
    const showInfo = info || showRequired;
    const validationState = showErrors ? "error" : propsValidationState;

    const controlProps = {
      type,
      name,
      label,
      required,
      disabled,
      value,
      onChange: disabled ? null : onFieldChange,
      inputRef,
      validationState
    };

    return (
      <FormGroupElement name={name}>
        {renderControl(controlProps)}
        {showErrors && this.renderErrors(errors)}
        {showInfo && this.renderFieldInfo(info, showRequired)}
      </FormGroupElement>
    );
  }
}

export default class FormGroup extends Component {
  renderFormGroup = ({ form, errors, onFieldChange }) => {
    const name = this.props.name;
    const fieldValue = path(toPath(name), form);
    const fieldErrors = errors.filter(e => e.field === name);

    return (
      <ControlledFormGroup
        value={fieldValue}
        errors={fieldErrors}
        onFieldChange={value => onFieldChange(name, value)}
        {...this.props}
      />
    );
  };

  render() {
    return <Form.Consumer children={this.renderFormGroup} />;
  }
}
