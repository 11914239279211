

import * as React from "react";
import * as Activity from "./Activity/Activity.bs.js";
import * as ReactDOMRe from "reason-react/src/ReactDOMRe.js";
import * as StyleCss from "./style.css";
import * as DashboardJobs from "./Jobs/DashboardJobs.bs.js";
import * as DocumentTitle from "../../components/DocumentTitle.bs.js";
import * as DashboardStorage from "./Storage/DashboardStorage.bs.js";

var styles = StyleCss.default;

function Dashboard(Props) {
  var contents = React.createElement("div", {
        className: styles.fullSpanWrapper
      }, React.createElement("div", {
            className: styles.dashboard
          }, React.createElement("div", {
                className: styles.dashboardSidebar
              }, React.createElement(DashboardStorage.make, { }), React.createElement("div", {
                    className: styles.dashboardSidebarDivider
                  }), React.createElement(DashboardJobs.make, { })), React.createElement("div", {
                className: styles.dashboardMain
              }, React.createElement(Activity.make, { }))));
  return ReactDOMRe.createElementVariadic("div", {
              className: styles.root,
              "data-testid": "dashboard"
            }, /* array */[
              React.createElement(DocumentTitle.make, {
                    id: "dashboard.documentTitle"
                  }),
              contents
            ]);
}

var make = Dashboard;

export {
  make ,
  
}
/* styles Not a pure module */
