

import * as Job$SolvuuApi from "solvuu-api/./job.bs.js";
import * as Job_metrics$SolvuuApi from "solvuu-api/./job_metrics.bs.js";
import * as Solvuu_job_spec_bs$SolvuuApi0 from "solvuu-api0/./solvuu_job_spec_bs.bs.js";

var convertFromJs = Job$SolvuuApi.Json.read_t;

var convertToJs = Job$SolvuuApi.Json.write_t;

var convertMetricsFromJs = Job_metrics$SolvuuApi.Json.read_t;

var convertMetricsToJs = Job_metrics$SolvuuApi.Json.write_t;

var convertCommandSpecFromJs = Solvuu_job_spec_bs$SolvuuApi0.read_command_spec;

var convertCommandSpecToJs = Solvuu_job_spec_bs$SolvuuApi0.write_command_spec;

export {
  convertFromJs ,
  convertToJs ,
  convertCommandSpecFromJs ,
  convertCommandSpecToJs ,
  convertMetricsFromJs ,
  convertMetricsToJs ,
  
}
/* Job-SolvuuApi Not a pure module */
