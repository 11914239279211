

import * as FeaturesUtils from "../../features/utils/FeaturesUtils.bs.js";

var $$Error = { };

function make(x) {
  return /* `Job */[
          3704797,
          x
        ];
}

function make$1(x) {
  return /* `Job */[
          3704797,
          /* `Create */[
            816044828,
            x
          ]
        ];
}

var include = FeaturesUtils.Operation({
      make: make$1
    });

var Create = include;

var Success = { };

function make$2(x) {
  return /* `Job */[
          3704797,
          /* `Fetch */[
            287802394,
            x
          ]
        ];
}

var include$1 = FeaturesUtils.Operation({
      make: make$2
    });

var Fetch_initialState = include$1.initialState;

var Fetch_start = include$1.start;

var Fetch_success = include$1.success;

var Fetch_failure = include$1.failure;

var Fetch_reducer = include$1.reducer;

var Fetch_Status = include$1.Status;

var Fetch = {
  Success: Success,
  initialState: Fetch_initialState,
  start: Fetch_start,
  success: Fetch_success,
  failure: Fetch_failure,
  reducer: Fetch_reducer,
  Status: Fetch_Status
};

var Success$1 = { };

function make$3(x) {
  return /* `Job */[
          3704797,
          /* `EventsFetch */[
            253588033,
            x
          ]
        ];
}

var include$2 = FeaturesUtils.Operation({
      make: make$3
    });

var EventsFetch_initialState = include$2.initialState;

var EventsFetch_start = include$2.start;

var EventsFetch_success = include$2.success;

var EventsFetch_failure = include$2.failure;

var EventsFetch_reducer = include$2.reducer;

var EventsFetch_Status = include$2.Status;

var EventsFetch = {
  Success: Success$1,
  initialState: EventsFetch_initialState,
  start: EventsFetch_start,
  success: EventsFetch_success,
  failure: EventsFetch_failure,
  reducer: EventsFetch_reducer,
  Status: EventsFetch_Status
};

var Success$2 = { };

function make$4(x) {
  return /* `Job */[
          3704797,
          /* `OutputFetch */[
            905840985,
            x
          ]
        ];
}

var include$3 = FeaturesUtils.Operation({
      make: make$4
    });

var OutputFetch_initialState = include$3.initialState;

var OutputFetch_start = include$3.start;

var OutputFetch_success = include$3.success;

var OutputFetch_failure = include$3.failure;

var OutputFetch_reducer = include$3.reducer;

var OutputFetch_Status = include$3.Status;

var OutputFetch = {
  Success: Success$2,
  initialState: OutputFetch_initialState,
  start: OutputFetch_start,
  success: OutputFetch_success,
  failure: OutputFetch_failure,
  reducer: OutputFetch_reducer,
  Status: OutputFetch_Status
};

var Success$3 = { };

function make$5(x) {
  return /* `Job */[
          3704797,
          /* `MetricsFetch */[
            -968680681,
            x
          ]
        ];
}

var include$4 = FeaturesUtils.Operation({
      make: make$5
    });

var MetricsFetch_initialState = include$4.initialState;

var MetricsFetch_start = include$4.start;

var MetricsFetch_success = include$4.success;

var MetricsFetch_failure = include$4.failure;

var MetricsFetch_reducer = include$4.reducer;

var MetricsFetch_Status = include$4.Status;

var MetricsFetch = {
  Success: Success$3,
  initialState: MetricsFetch_initialState,
  start: MetricsFetch_start,
  success: MetricsFetch_success,
  failure: MetricsFetch_failure,
  reducer: MetricsFetch_reducer,
  Status: MetricsFetch_Status
};

var Job = {
  make: make,
  Create: Create,
  Fetch: Fetch,
  EventsFetch: EventsFetch,
  OutputFetch: OutputFetch,
  MetricsFetch: MetricsFetch
};

function make$6(x) {
  return /* `Jobs */[
          826169846,
          x
        ];
}

var Success$4 = { };

function make$7(x) {
  return /* `Jobs */[
          826169846,
          /* `Fetch */[
            287802394,
            x
          ]
        ];
}

var include$5 = FeaturesUtils.Operation({
      make: make$7
    });

var Fetch_initialState$1 = include$5.initialState;

var Fetch_start$1 = include$5.start;

var Fetch_success$1 = include$5.success;

var Fetch_failure$1 = include$5.failure;

var Fetch_reducer$1 = include$5.reducer;

var Fetch_Status$1 = include$5.Status;

var Fetch$1 = {
  Success: Success$4,
  initialState: Fetch_initialState$1,
  start: Fetch_start$1,
  success: Fetch_success$1,
  failure: Fetch_failure$1,
  reducer: Fetch_reducer$1,
  Status: Fetch_Status$1
};

var Jobs = {
  make: make$6,
  Fetch: Fetch$1
};

var Operations = {
  Job: Job,
  Jobs: Jobs
};

export {
  $$Error ,
  Operations ,
  
}
/* include Not a pure module */
