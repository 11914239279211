import React, { Component } from "react";
import PropTypes from "prop-types";
import * as Link from "containers/Link.bs";

import * as PathUtils from "lib/solvuu/pathUtils.bs";
import * as Route from "Route.bs";

import styles from "./style.css";

export default class Breadcrumbs extends Component {
  static propTypes = {
    path: PropTypes.string.isRequired,
    selected: PropTypes.bool.isRequired,
    onSelect: PropTypes.func,
    children: PropTypes.node
  };

  static defaultProps = {
    selected: true
  };

  handleSelection = event => {
    event.preventDefault();
    event.stopPropagation();
    this.props.onSelect();
  };

  renderBreadcrumb({ name, path }, last) {
    const { selected, onSelect } = this.props;

    const link = path ? (
      <Link
        to={Route.Js.entityCreate(path)}
        title={path}
        className={styles.link}
      >
        {name}
      </Link>
    ) : (
      name
    );

    let contents;
    if (!last) contents = link;
    else if (selected)
      contents = <span className={styles.selected}>{name}</span>;
    else if (onSelect)
      contents = React.cloneElement(link, { onClick: this.handleSelection });
    else contents = link;

    return (
      <li key={path} className={styles.breadcrumb}>
        {contents}
      </li>
    );
  }

  render() {
    const { path, children } = this.props;

    const breadcrumbs = PathUtils.splitPathIntoComponents(path);
    if (React.Children.count(children)) {
      breadcrumbs.push({ name: children, path: "" });
    }

    return (
      <ul className={styles.breadcrumbs}>
        {breadcrumbs.map((breadcrumb, idx) =>
          this.renderBreadcrumb(breadcrumb, idx === breadcrumbs.length - 1)
        )}
      </ul>
    );
  }
}
