import React, { Component } from "react";
import { pie, arc } from "d3-shape";

import * as SidebarSection from "containers/Dashboard/SidebarSection/SidebarSection.bs";
import { VerticalLayout, VerticalLayoutItem } from "components/Layout";

import { colorSecondaryGreen } from "variables.css";
import styles from "./style.css";

export default class Storage extends Component {
  handlePieClick = () => {
    this.props.navigateToUploads();
  };

  render() {
    const chartWidth = 260;
    const chartHeight = chartWidth;
    const chartViewBox = `0 0 ${chartWidth} ${chartHeight}`;
    const chartColor = colorSecondaryGreen;
    const radius = chartWidth / 2;
    const outerRadius = radius - 16;
    const innerRadius = radius - 44;
    const centerX = chartWidth / 2;
    const centerY = chartHeight / 2;

    const pieData = [6, 8, 8, 13, 14, 14, 16, 14, 7];
    const pies = pie().padAngle(0.005)(pieData);
    const arcGen = arc()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius);
    const pieParts = pies.map(pie => ({
      path: arcGen(pie),
      centroid: arcGen.centroid(pie),
      data: pie.data
    }));

    const totalValue = 890;
    const totalUnit = "TB";

    return (
      <SidebarSection.make>
        <VerticalLayout gap="4x">
          <VerticalLayoutItem>
            <SidebarSection.SectionHeader.make>
              Storage
            </SidebarSection.SectionHeader.make>
          </VerticalLayoutItem>
          <VerticalLayoutItem>
            <div className={styles.wrapper}>
              <svg
                width={chartWidth}
                height={chartHeight}
                viewBox={chartViewBox}
                className={styles.chart}
              >
                <circle
                  cx={centerX}
                  cy={centerY}
                  r={radius}
                  fill={chartColor}
                  opacity={0.3}
                />
                <circle cx={centerX} cy={centerY} r={innerRadius} fill="#fff" />
                <g
                  transform={`translate(${centerX} ${centerY})`}
                  cursor="pointer"
                >
                  {pieParts.map(({ path, centroid, data }) => (
                    <g
                      key={path}
                      cursor="pointer"
                      onClick={this.handlePieClick}
                    >
                      <path d={path} fill={chartColor} />
                      <text
                        x={centroid[0]}
                        y={centroid[1]}
                        dy={2}
                        fontSize={8}
                        fontWeight={300}
                        fill="#fff"
                        textAnchor="middle"
                      >
                        {data}%
                      </text>
                    </g>
                  ))}
                </g>
              </svg>
              <div className={styles.total}>
                <span className={styles.totalValue}>{totalValue}</span>
                <span className={styles.totalUnit}>{totalUnit}</span>
                <div className={styles.totalLabel}>Total Storage</div>
              </div>
            </div>
          </VerticalLayoutItem>
        </VerticalLayout>
      </SidebarSection.make>
    );
  }
}
