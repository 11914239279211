import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Line,
  LineChart as RechartsLineChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import sizeMe from "react-sizeme";
import { FormattedNumber } from "react-intl";
import { max } from "d3-array";

import { categoricalSimple as categorical } from "lib/colors";
import measureTextWidth from "lib/measureTextWidth";

class LineChart extends Component {
  static propTypes = {
    series: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        data: PropTypes.arrayOf(PropTypes.number).isRequired
      })
    ).isRequired,
    groups: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired
      })
    ).isRequired,
    size: PropTypes.shape({
      width: PropTypes.number
    }).isRequired
  };

  render() {
    const { series, groups, size } = this.props;

    const seriesNames = series.map(series => series.name);
    const groupNames = groups.map(group => group.name);
    const data = groups.map((group, groupIdx) => {
      const groupData = series.reduce(
        (acc, series) =>
          Object.assign(acc, { [series.name]: series.data[groupIdx] }),
        {}
      );
      return { $__name: group.name, ...groupData };
    });

    const groupAxisHeight =
      max(groupNames, name => measureTextWidth(name, 14)) + 20;
    const rotateGroupNames = groupAxisHeight > 30;
    const groupAxisTick = rotateGroupNames
      ? { angle: -90, textAnchor: "end", dx: -5 }
      : undefined;

    const width = size.width || 1000;
    const height = 300 + (groupAxisHeight || 0);
    const margin = { right: 10 };

    function tooltipFormatter(value) {
      return <FormattedNumber value={value} />;
    }

    // Top-level div element helps react-sizeme to measure the width
    return (
      <div>
        <RechartsLineChart
          width={width}
          height={height}
          margin={margin}
          data={data}
        >
          <XAxis
            dataKey="$__name"
            type="category"
            height={groupAxisHeight}
            tick={groupAxisTick}
            interval={0}
          />
          <YAxis
            type="number"
            label={{
              value: "Value",
              angle: -90,
              position: "insideLeft",
              dy: 20
            }}
          />
          <Tooltip formatter={tooltipFormatter} />
          <Legend />
          <CartesianGrid
            horizontal={true}
            vertical={false}
            strokeDasharray="3 3"
          />
          {seriesNames.map((seriesName, seriesIdx) => {
            const color = categorical[seriesIdx];
            return (
              <Line
                key={seriesName}
                dataKey={seriesName}
                fill={color}
                stroke={color}
                isAnimationActive={false}
              />
            );
          })}
        </RechartsLineChart>
      </div>
    );
  }
}

const noPlaceholder = process.env.NODE_ENV === "test";
const refreshRate = 100;
const enhance = sizeMe({ refreshRate, noPlaceholder });

export default enhance(LineChart);
