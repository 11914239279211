

import * as Root from "../../../../Root.bs.js";
import * as React from "react";
import * as Js_dict from "bs-platform/lib/es6/js_dict.js";
import * as Section from "../../Section.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as PageNotFound from "../../../PageNotFound/PageNotFound.bs.js";
import * as ScienceItem0 from "./ScienceItem0.bs.js";
import * as DocumentTitle from "../../../../components/DocumentTitle.bs.js";
import * as GeneRegulation from "./GeneRegulation.bs.js";
import * as Transcriptomics from "./Transcriptomics.bs.js";
import * as VariantDiscovery from "./VariantDiscovery.bs.js";
import * as MicrobialGenomics from "./MicrobialGenomics.bs.js";
import * as SingleCellGenomics from "./SingleCellGenomics.bs.js";

var items = Js_dict.fromArray(/* array */[
      /* tuple */[
        "transcriptomics",
        Transcriptomics
      ],
      /* tuple */[
        "microbial-genomics",
        MicrobialGenomics
      ],
      /* tuple */[
        "gene-regulation",
        GeneRegulation
      ],
      /* tuple */[
        "variant-discovery",
        VariantDiscovery
      ],
      /* tuple */[
        "single-cell-genomics",
        SingleCellGenomics
      ]
    ]);

function ScienceItem(Props) {
  var item = Props.item;
  var match = Js_dict.get(items, item);
  if (match !== undefined) {
    var item$1 = match;
    var tools = React.createElement(Section.make, {
          children: null
        }, React.createElement(Section.BackgroundGradient.make, { }), React.createElement(Section.Header.make, {
              children: "Tools"
            }), React.createElement("table", {
              className: ScienceItem0.styles.tools
            }, React.createElement("tbody", undefined, Belt_Array.map(item$1.tools, (function (param) {
                        var term = param.term;
                        return React.createElement("tr", {
                                    key: Root.id(term)
                                  }, React.createElement("td", undefined, Root.toStringHum(term)), React.createElement("td", undefined, param.description));
                      })))));
    var fileTypes = React.createElement(Section.make, {
          children: null
        }, React.createElement(Section.Header.make, {
              children: "File types"
            }), React.createElement("div", {
              className: ScienceItem0.styles.fileTypes
            }, Belt_Array.map(item$1.fileTypes, (function (format) {
                    return React.createElement("div", {
                                key: Root.id(format),
                                className: ScienceItem0.styles.fileType
                              }, React.createElement(Root.Icon.make, {
                                    icon: format
                                  }));
                  }))));
    var visualAnalytics = React.createElement(Section.make, {
          children: null
        }, React.createElement(Section.Header.make, {
              children: "Visual analytics"
            }), React.createElement("div", {
              className: ScienceItem0.styles.visuals
            }, Belt_Array.map(item$1.visualAnalytics, (function (param) {
                    var icon = param.icon;
                    return React.createElement("div", {
                                key: Root.id(icon),
                                className: ScienceItem0.styles.visual
                              }, React.createElement("div", {
                                    className: ScienceItem0.styles.visualIcon
                                  }, React.createElement(Root.Icon.make, {
                                        icon: icon
                                      })), React.createElement("span", {
                                    className: ScienceItem0.styles.visualHeader
                                  }, Root.toStringHum(icon)), React.createElement("span", {
                                    className: ScienceItem0.styles.visualDescription
                                  }, param.description));
                  }))));
    return React.createElement("div", {
                className: ScienceItem0.styles.root
              }, React.createElement(DocumentTitle.make, {
                    id: "marketing.science.item.documentTitle",
                    values: {
                      item: item$1.title
                    }
                  }), React.createElement("header", {
                    className: ScienceItem0.styles.header
                  }, React.createElement("img", {
                        className: ScienceItem0.styles.headerIcon,
                        alt: "",
                        src: item$1.icon
                      }), React.createElement("h1", undefined, item$1.title)), React.createElement("section", {
                    className: ScienceItem0.styles.content
                  }, React.createElement("div", undefined, React.createElement("div", {
                            className: ScienceItem0.styles.overviewIntro
                          }, item$1.description), React.createElement("div", {
                            className: ScienceItem0.styles.overview
                          }, item$1.overview), tools, fileTypes, visualAnalytics)));
  } else {
    return React.createElement(PageNotFound.make, { });
  }
}

var styles = ScienceItem0.styles;

var make = ScienceItem;

export {
  styles ,
  make ,
  
}
/* items Not a pure module */
